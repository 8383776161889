<template>
  <div class="content">
    <h1 class="heading-h3 text--center content__title">
      {{ title }}
    </h1>

    <!-- SVG -->
    <div
      v-if="showFirstIlustration && graphIlustration === 'draw'"
      v-html="ilustration"
      class="content__picture"
    ></div>
    <!-- URL -->
    <div
      v-else-if="showFirstIlustration && graphIlustration === 'image'"
      class="content__picture"
    >
      <img :src="ilustration" alt="" srcset="" />
    </div>

    <div class="text-content text--center">
      <h6 class="body-b2 text--center">{{ subtitle }}</h6>
      <p class="body-2">
        {{ description }}
      </p>
    </div>

    <!-- SVG -->
    <div
      v-if="!showFirstIlustration && graphIlustration === 'draw'"
      v-html="ilustration"
      class="content__picture"
    ></div>
    <!-- URL -->
    <div
      v-else-if="!showFirstIlustration && graphIlustration === 'image'"
      class="content__picture"
    >
      <img :src="ilustration" alt="" srcset="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasicContent',
  props: {
    title: { type: String, default: 'Titulo' },
    subtitle: { type: String, default: 'Subtítulo' },
    showFirstIlustration: { type: Boolean, default: false },
    description: {
      type: String,
      default:
        'Ipsum culpa non nostrud ex tempor quis ad. Duis aliquip velit reprehenderit eu mollit sunt cupidatat. Occaecat proident laboris Lorem pariatur qui ad cupidatat qui velit Lorem minim. Eu sit culpa laborum nisi fugiat sit. Sunt laboris laboris ea minim Lorem. Irure veniam exercitation consequat pariatur laboris fugiat occaecat enim nostrud do aute qui ipsum cupidatat. Officia voluptate cupidatat fugiat eu nulla sit cupidatat dolor et est do.',
    },
    graphIlustration: {
      type: String,
      enum: ['image', 'draw'],
    },
    ilustration: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="sass" scoped>
.content
  padding: 20px 24px 0px 24px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  &__title
    margin-bottom: 32px
  &__picture
    margin-bottom: 32px
.text-content
  margin: auto 24px 32px 24px
</style>
