<template>
  <div class="redirect"><span>Redireccionando...</span></div>
</template>

<script>
export default {
  name: 'Redirect',
  created() {
    const afterLogin = this.$route.query.al // Identificar que es una redirección exactamente despues de haberse logueado a la plataforma
    this.$store.dispatch('validateSignin', { afterLogin: afterLogin })
  },
}
</script>
