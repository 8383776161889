<template>
  <SectionSchedule />
</template>
<script>
import SectionSchedule from '@/app/schedule/components/organisms/SectionSchedule.vue'
export default {
  name: 'ScheduleTemplate',
  components: {
    SectionSchedule,
  },
}
</script>
