<template>
  <a-modal
    :visible="visible"
    title="Ver respuesta rápida"
    @cancel="$emit('onClose')"
    :body-style="{ height: heightBody, padding: '0px' }"
    centered
  >
    <custom-section-scroll
      :master-styles="{ height: heightBody }"
      :content-styles="{ padding: '24px' }"
    >
      <div v-for="(value, key) in dataSource" :key="key" class="content">
        <template v-if="keysAllowed.includes(key)">
          <h6 class="semibold-14">{{ transformkey(key) }}</h6>
          <p class="text--pre-wrap">{{ transformValue(key, value) }}</p>
        </template>
      </div>
    </custom-section-scroll>
    <div slot="footer">
      <a-button @click="$emit('onClose')">Cerrar visualización</a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'

export default {
  name: 'ModalPreviewFastAnswers',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Object,
      required: true,
      /**
       * Estructura
       * @param {String} dataSource.title
       * @param {String} dataSource.content
       * @param {Object[]} dataSource.assigned_lines
       */
    },
    keysAllowed: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  components: {
    CustomSectionScroll,
  },
  computed: {
    ...mapGetters(['active_breakpoint']),

    /**
     * @return {String} altura del cuerpo del modal
     */
    heightBody() {
      return !this.active_breakpoint.is_mobile ? '45em' : '32em'
    },
  },
  methods: {
    /**
     * Transforma la propiedad de la data source
     * @param {String}
     */
    transformkey(key) {
      switch (key) {
        case 'title':
          return 'Título'
        case 'body':
          return 'Mensaje'
        case 'lines':
          return 'Colas asignadas'
        default:
          return null
      }
    },
    /**
     * Transfroma el valor dependiendo de la propiedad data source
     * @param {Sstring} key
     * @param {*} value
     */
    transformValue(key, value) {
      switch (key) {
        case 'lines': {
          const namesLines = value.map((line) => line.name)
          return namesLines.join(', ')
        }
        default:
          return value
      }
    },
  },
}
</script>

<style scoped></style>
