<template>
  <div class="line-list">
    <a-table
      class="tickets-table table-scroll"
      :data-source="ticketsWithAlias"
      :pagination="pagination"
      :loading="loading"
      rowKey="_id"
      :locale="localeTable"
      :columns="selectedColumns"
      @change="handleTableChange"
      :row-selection="rowSelection"
      :scroll="{ x: '90vw', y: 'calc(100vh - 260px)' }"
      size="middle"
    >
      <template slot="ticket" slot-scope="ticket">
        <span> {{ ticketNumberFormatted(ticket) }} </span>
      </template>

      <template slot="client" slot-scope="client, record">
        <a-row class="client" type="flex">
          <template v-if="client">
            <a-avatar
              v-if="client.avatar"
              :src="client.avatar"
              :size="24"
              class="person__image"
            />
            <a-avatar v-else :size="24" class="person__image" icon="user" />

            <a
              v-if="record.locked"
              class="person__name"
              :disabled="record.locked"
              >Ticket restringido</a
            >
            <template v-else>
              <a
                v-if="(client.names || '') + (client.surnames || '')"
                class="person__name"
                @click="$emit('seeClient', client)"
              >
                {{ (client.names || '') + ' ' + (client.surnames || '') }}
              </a>
              <a
                @click="$emit('seeClient', client)"
                v-else
                class="person__name--alternative"
              >
                Sin nombre
              </a>
            </template>
          </template>
        </a-row>
      </template>
      <template slot="agent" slot-scope="agent">
        <a-row type="flex">
          <template class="agent" v-if="agent">
            <img
              v-if="agent.avatar"
              class="person__image"
              :src="agent.avatar"
            />
            <a-avatar
              v-else-if="agent.subType === 'chatbot'"
              src="/img/bot/bot.png"
              class="person__image"
            />
            <default-icon-letter
              v-else
              class="person__image"
              :names="agent.names"
              :size="24"
            />
            <p class="person__name mrg-bottom-0">
              {{ (agent.names || '') + ' ' + (agent.surnames || '') }}
            </p>
          </template>
          <template class="agent" v-else>
            <!-- Ya no deberia entrar aqui ya que si no hay agente en la mutacion se setea un objeto con el nombre Sin agente -->
            <default-icon-letter class="person__image" names="Sin" :size="24" />
            <span class="person__name--alternative"> Sin agente </span>
          </template>
        </a-row>
      </template>

      <template slot="status" slot-scope="status, ticket">
        <!-- [queued, issued, opened, ended] -->
        <a-row type="flex" align="middle">
          <a-tag color="#C22FB3" v-if="status == 'queued'">
            Cola de espera
          </a-tag>
          <a-tag color="#CE8934" v-else-if="status == 'issued'">
            Entregado
          </a-tag>
          <a-tag color="#2FC25B" v-else-if="status == 'opened'">
            Respondido
          </a-tag>
          <a-tag color="#1890FF" v-else-if="status == 'ended'">
            Finalizado
          </a-tag>

          <a-tag color="green" v-if="ticket.pendings == 0"> Leído </a-tag>
          <a-tag v-else> Sin leer </a-tag>
        </a-row>
      </template>

      <template slot="channel" slot-scope="channel, record">
        <social-icon :type="channel.type" size="24" :disabled="record.locked" />
      </template>

      <template slot="channelCompanyId" slot-scope="channel, record">
        <span>{{
          record.channel.title + record.channel.alias ||
          record.channel.phoneTo ||
          record.channel.apiBusinessId
        }}</span>
      </template>

      <template slot="created" slot-scope="created_at">
        {{ formatDate(created_at) }}
      </template>

      <template slot="favorite" slot-scope="starred">
        <a-row type="flex" justify="center" align="middle">
          <a-icon
            class="star"
            type="star"
            theme="filled"
            :style="`font-size: 20px ;${
              starred ? 'color: #f8e479;' : 'color: #E8E8E8;'
            }`"
          />
        </a-row>
      </template>

      <template slot="mailbox" slot-scope="mailbox">
        <span>{{ getMailbox(mailbox).title }}</span>
      </template>

      <template slot="phone" slot-scope="phone, record">
        <a v-if="record.locked" :aria-disabled="true" :disabled="true">
          Ticket restringido
        </a>
        <span v-else>{{ phone }}</span>
      </template>
      <template slot="productsAssigned" slot-scope="productsAssigned, record">
        <a
          v-if="!record.productsAssigned"
          :aria-disabled="true"
          :disabled="true"
          >{{ record.productsAssigned }} productos</a
        >
        <a v-else @click="handleOpenDrawerProducts(record)">
          {{ record.productsAssigned }}
          {{ record.productsAssigned === 1 ? 'producto' : 'productos' }}</a
        >
      </template>
      <template slot="action" slot-scope="text, record">
        <a-row
          class="actions"
          type="flex"
          align="middle"
          justify="space-around"
        >
          <!-- Contenido del ticket -->
          <a-tooltip title="Ver chat">
            <a :disabled="record.locked">
              <a-icon
                class="action-icon"
                type="eye"
                @click="onSeeTicket(record)"
              />
            </a>
          </a-tooltip>
          <!-- TRansferir ticket -->
          <a-dropdown :trigger="['click']" placement="bottomLeft">
            <a-tooltip>
              <template slot="title"> Transferir </template>
              <a :disabled="record.status == 'ended' || record.locked">
                <a-icon class="action-icon" type="swap" />
              </a>
            </a-tooltip>
            <!-- menú -->
            <a-menu slot="overlay">
              <!-- Transferencia única a agente -->
              <a-menu-item key="0">
                <a @click="onTransferTicketToAgent(record)"
                  >Transferir a un agente</a
                >
              </a-menu-item>
              <!-- Transferencia única a Cola -->
              <a-sub-menu
                style="
                  max-height: 200px;
                  overflow-y: auto;
                  background-clip: initial;
                "
                class="scrollbar_basic"
                key="1"
              >
                <span slot="title"><span>Transferir a cola</span></span>
                <a-menu-item
                  v-for="(line, index) in companyLinesAvailable(record)"
                  :key="`item${index}`"
                  @click="onTransferTicketToLine(record, line)"
                >
                  {{ line.name }}
                </a-menu-item>
              </a-sub-menu>
            </a-menu>
          </a-dropdown>
          <!-- Editar ticket -->
          <a-tooltip title="Editar ticket">
            <a
              :disabled="['ended'].includes(record.subStatus) || record.locked"
            >
              <a-icon
                type="edit"
                class="editable-cell-icon"
                @click="$emit('editTicket', record)"
              />
            </a>
          </a-tooltip>
          <!-- Finalizar ticket -->
          <a-tooltip title="Resolver ticket">
            <a
              :disabled="
                ['ended', 'queued'].includes(record.subStatus) || record.locked
              "
            >
              <a-icon
                class="action-icon"
                type="check"
                @click="onResolveTicket(record)"
              />
            </a>
          </a-tooltip>
        </a-row>
      </template>
    </a-table>
    <!--Modal agentes-->
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapActions, mapGetters } from 'vuex'
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'
import EditableCustomFieldColumn from './EditableCustomFieldColumn.vue'
import SocialIcon from '@/app/shared/components/icons/SocialIcon.vue'
import transformWordGender from '@/app/shared/mixins/transform'
import compareMixin from '@/app/shared/mixins/compare'
import attemptMixin from '@/app/shared/mixins/attempt'
import format from '@/app/shared/mixins/format'
import moment from 'moment'
import support from '@/app/shared/utils/support'
export default {
  name: 'TicketsTable',
  mixins: [transformWordGender, compareMixin, format, attemptMixin],
  props: {
    tickets: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    columnsToShow: {
      type: Array,
      required: true,
    },
  },
  components: {
    DefaultIconLetter,
    EditableCustomFieldColumn,
    SocialIcon,
  },

  data: () => ({
    currentPage: 1,
    localeTable: {
      emptyText: 'No se han encontrado tickets',
    },
    nameLine: '',

    selectedColumns: [],

    columns: {
      ticket: {
        title: 'ID',
        dataIndex: 'ticket',
        key: 'ticket',
        width: '110px',
        scopedSlots: { customRender: 'ticket' },
      },
      client: {
        title: 'Cliente',
        dataIndex: 'client',
        key: 'client',
        width: '280px',
        scopedSlots: { customRender: 'client' },
        ellipsis: true,
      },
      agent: {
        title: 'Agente asignado',
        dataIndex: 'agent',
        key: 'agent',
        width: '280px',
        scopedSlots: { customRender: 'agent' },
        ellipsis: true,
      },
      status: {
        title: 'Estado',
        dataIndex: 'subStatus',
        key: 'status',
        width: '189px',
        scopedSlots: { customRender: 'status' },
      },
      channel: {
        title: 'Canal',
        dataIndex: 'channel',
        key: 'channel',
        width: '85px',
        scopedSlots: { customRender: 'channel' },
      },
      channelCompanyId: {
        title: 'ID de Canal',
        dataIndex: 'channelCompanyId',
        key: 'channelCompanyId',
        width: '200px',
        ellipsis: true,
        scopedSlots: { customRender: 'channelCompanyId' },
      },
      line: {
        title: 'Cola',
        dataIndex: 'company.lineName',
        key: 'line',
        width: '149px',
        scopedSlots: { customRender: 'line' },
      },
      favorite: {
        title: 'Favorito',
        align: 'center',
        dataIndex: 'settings.starred',
        key: 'favorite',
        width: '84px',
        scopedSlots: { customRender: 'favorite' },
      },
      mailbox: {
        title: 'Buzón',
        dataIndex: 'mailboxId',
        key: 'mailbox',
        width: '150px',
        scopedSlots: { customRender: 'mailbox' },
      },
      created: {
        title: 'Fecha de creación',
        dataIndex: 'created_at',
        key: 'created',
        width: '200px',
        scopedSlots: { customRender: 'created' },
        ellipsis: true,
      },
      phone: {
        title: 'Teléfono',
        key: 'phone',
        dataIndex: 'phone',
        width: '150px',
        scopedSlots: { customRender: 'phone' },
      },
      productsAssigned: {
        title: 'Productos asignados',
        key: 'productsAssigned',
        checked: true, // true
        width: '180px',
        scopedSlots: { customRender: 'productsAssigned' },
      },
      strategy: {
        title: 'Campaña',
        key: 'strategy',
        dataIndex: 'strategy.name',
        width: '200px',
        ellipsis: true,
      },
      action: {
        title: 'Acción',
        key: 'action',
        fixed: 'right',
        width: '158px',
        scopedSlots: { customRender: 'action' },
      },
    },
    selectedRows: [],
    customFieldsLoaded: false,
  }),
  created() {
    this.columns['tags'] = {
      title: 'Etiquetas',
      dataIndex: 'settings.tags',
      key: 'tags',
      width: '217px',
      customRender: (value, record) =>
        this.renderCustomField(value, record, {
          tags: this.tagsToSelect,
          update: this.updateTags,
        }),
    }

    this.listCustomFieldColumns()
    this.selectedColumns = []
    this.columnsToShow.forEach((column) => {
      this.selectedColumns.push(this.columns[column.key])
    })
    this.selectedColumns.push(this.columns.action)

    this.rowSelection.onChange = (_, selectedRows) => {
      this.onSelectedChange(selectedRows)
    }
  },
  watch: {
    reloadTableLines() {
      this.handleTableChange({ current: this.currentPage })
    },
    company_custom_fields_tickets() {
      if (this.customFieldsLoaded) return
      this.listCustomFieldColumns()
    },
    columnsToShow() {
      this.selectedColumns = []
      this.columnsToShow.forEach((column) => {
        this.selectedColumns.push(this.columns[column.key])
      })
      this.selectedColumns.push(this.columns.action)
    },
  },
  computed: {
    ...mapGetters([
      'lines',
      'companyLines',
      'accounts',
      'profile',
      'reloadTableLines',
      'agentsAccounts',
      'company',
      'roles',
      'company_custom_fields_tickets',
      'tags',
      'mailboxes',
      'modules_pagination',
      'simple_company_channels',
    ]),

    /**
     * @return {Object} paginacion de la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: this.modules_pagination.manage_tickets,
        size: 'default',
        total: this.tickets.total || 0,
        current: this.page,
        showTotal: (total) => (
          <span>
            <span class="heading-h6">{total}</span>{' '}
            {total > 1 ? 'tickets encontrados' : 'ticket encontrado'}
          </span>
        ),
      }
    },
    tagsToSelect() {
      if (!this.company) return []
      let toSelect = []
      toSelect = this.tags.map((tag) => ({
        title: tag.title,
        key: tag.idTag,
        color: tag.color,
        tagId: tag.idTag,
      }))
      return toSelect
    },
    rowSelection() {
      return {
        columnWidth: '40px',
        fixed: true,
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.locked, // Column configuration not to be checked
          },
        }),
      }
    },
    /**
     * @return {Aray} Array de tickets luego de agregar alias a sus canales
     *
     */
    ticketsWithAlias() {
      const channels = this.simple_company_channels

      this.tickets.data.forEach((data) => {
        const alias = support.channelCompanyAlias(
          data.channelCompanyId,
          channels
        )
        data.channel.alias = alias ? ' ( ' + alias + '  )' : ''
      })
      return this.tickets.data
    },
  },
  methods: {
    ...mapActions([
      'removeSupervisor',
      'removeAgent',
      'getLines',
      'updateAdminTicketTags',
      'updateAdminStarred',
    ]),
    /**
     * Llenar la tabla de Colas segun la paginacion
     * @param {Object} pagination
     * @param {Number} pagination.current página actual
     */
    async handleTableChange(pagination) {
      this.$emit('paginate', pagination.current)
    },
    formatDate(created_at) {
      const formatedDate = moment(created_at).format('MMMM DD YYYY, h:mm:ss a')
      return formatedDate[0].toUpperCase() + formatedDate.slice(1)
    },
    listCustomFieldColumns() {
      if (this.company_custom_fields_tickets.length > 0)
        this.customFieldsLoaded = true

      this.company_custom_fields_tickets.map((customField) => {
        this.columns[customField._id] = {
          dataIndex: 'fields.' + customField._id,
          title: customField.name,
          key: customField._id,
          width: '150px',
          customRender: (value, record) =>
            this.renderCustomField(value, record, {
              ...customField,
            }),
        }
      })
    },
    renderCustomField(value, ticket, config) {
      if (this.loading) return
      else
        return (
          <editable-custom-field-column
            customField={value}
            ticket={ticket}
            config={config}
          />
        )
    },
    onTransferTicketToAgent(ticket) {
      this.$emit('transferAgent', ticket)
    },
    onTransferTicketToLine(ticket, line) {
      this.$confirm({
        title: `¿Está seguro(a) de transferir el ticket a la cola "${line.name}"?`,
        content:
          'Recuerda que los tickets serán derivados y repartidos en la cola seleccionada ',
        okText: 'Transferir',
        cancelText: 'Cancelar',
        onOk: () => {
          this.$emit('transferLine', ticket, line)
        },
      })
    },
    getMailbox(mailboxId) {
      return (
        this.mailboxes.find((mailbox) => mailbox.idMailbox == mailboxId) || {}
      )
    },
    onResolveTicket(ticket) {
      this.$emit('resolveTicket', ticket)
    },
    onSeeTicket(ticket) {
      if (ticket.locked) return
      this.$emit('seeTicket', ticket)
    },
    onSelectedChange(selectedRows) {
      this.$emit('selectedRows', selectedRows)
    },
    /**
     * Colas disponibles de la empresa que cuenten con el canal seleccionado
     * @param {Object} record
     * @param {Object} record.channel
     * @param {String} record.channel.type
     * @return {Object[]}
     */
    companyLinesAvailable(record) {
      if (!this.companyLines.length) return []
      return this.companyLines.filter((line) => {
        if (!line.channels_companies) return false
        else
          return line.channels_companies.some(
            (channels_company) =>
              channels_company._id === record.channelCompanyId
          )
      })
    },
    handleOpenDrawerProducts(record) {
      this.$emit('onShowProducts', record._id)
    },
  },
}
</script>
<style lang="sass" scoped>
.person__image
  border-radius: 50%
  margin-right: 8px
  height: 24px
  width: 24px
  cursor: auto!important
.person__name--alternative
  font-style: italic
.tag
  max-width: 24px
  min-width: 24px
  height: 8px
  border-radius: 8px
  margin-right: 4px
  margin-top: 4px
  flex-grow: 1
.actions
  max-width: 80%
.action-icon
  font-size: 16px
.person__name
  max-width: 200px
  text-overflow: ellipsis
  overflow: hidden
</style>

<style lang="sass">
.ant-dropdown-menu-submenu-content
  max-height: 100vh
  overflow: scroll
  &::-webkit-scrollbar
    width: 10px
  &::-webkit-scrollbar-track
    background: transparent
    border-left: solid 5px transparent
  &::-webkit-scrollbar-thumb
    border-radius: 8px
    box-shadow: inset 0 0 10px 10px $gray_dark_100
    border: solid 3px transparent
  &::-webkit-scrollbar-thumb:hover
    box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
