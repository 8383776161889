var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.title,"centered":""},on:{"cancel":_vm.handleCancel}},[_c('p',[_vm._v(" Para usarlas, desde el módulo de Workspace, selecciona el ícono de "),_c('a-icon',{attrs:{"type":"thunderbolt"}}),_vm._v(" para añadir una respuesta rápida ")],1),_c('a-form',{attrs:{"layout":"vertical","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Título","extra":"Una palabra que mostrará rápidamente esta respuesta"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {
            rules: [
              { required: true, message: 'Este campo es requerido' },
              { max: 34, message: '35 caracteres máximo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'title',\n          {\n            rules: [\n              { required: true, message: 'Este campo es requerido' },\n              { max: 34, message: '35 caracteres máximo' },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"maxLength":35,"placeholder":"Escribe aquí..."}})],1),_c('a-form-item',{attrs:{"label":"Mensaje"}},[_c('counter-text',{attrs:{"text":_vm.message,"maxLength":980}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'body',
            {
              rules: [{ required: true, message: 'Este campo es requerido' }],
            },
          ]),expression:"[\n            'body',\n            {\n              rules: [{ required: true, message: 'Este campo es requerido' }],\n            },\n          ]"}],staticClass:"position-relative",attrs:{"auto-size":{ minRows: 4, maxRows: 4 },"placeholder":"Escribe aquí...","maxLength":980},on:{"input":function($event){return _vm.handleSetMessage($event.target.value)}}})],1),_c('emoji-picker',{attrs:{"icon-styles":{
          fontSize: '16px',
          position: 'absolute',
          top: '8px',
          right: '8px',
        },"show-tooltip":false},on:{"onChangeEmoji":_vm.handleSetEmoji}})],1),_c('a-form-item',{attrs:{"label":"Asignar a colas (Opcional)","extra":"¡RECUERDA! Esta respuesta rápida solo le aparecerá a los agentes asignados a la cola seleccionada"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'linesIds',
          {
            rules: [{ required: false, message: 'Este campo es requerido' }],
          },
        ]),expression:"[\n          'linesIds',\n          {\n            rules: [{ required: false, message: 'Este campo es requerido' }],\n          },\n        ]"}],attrs:{"mode":"multiple","placeholder":"Selecciona aquí..."}},_vm._l((_vm.lines),function(line){return _c('a-select-option',{key:line._id},[_vm._v(" "+_vm._s(line.name)+" ")])}),1)],1)],1),_c('basic-footer',{attrs:{"ok-text":_vm.okText,"ok-loading":_vm.okButton.loading,"cancel-loading":_vm.cancelButton.loading,"ok-disabled":_vm.okButton.disabled,"cancel-disabled":_vm.cancelButton.disabled,"cancelText":"Cancelar"},on:{"onOk":_vm.handleOk,"onCancel":_vm.handleCancel}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }