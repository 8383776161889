import axios from 'axios'
import vari from '@/app/shared/utils/variables'
// import compare from '@/app/shared/utils/compare'
// import getters from './getters';
const downgradeState = 'downgrade'
const actions = {
  async getProducts(context) {
    try {
      const response = await axios.get(`${vari.UHR}/admin/products`)
      context.commit('SET_PRODUCTS', response.data.result)
    } catch (error) {
      console.error('getProducts', error)
    }
  },
  /**
   * Verifica si está cerca de expirar el plan y muestra un modal
   * @param {vuex} context
   */
  async verifyPlanExpired(context) {
    // const res = await context.dispatch('checkDowngrade')
    const company = context.getters.company
    context.commit(
      'SET_EXPIRATION_PLAN_MODAL',
      company.status == downgradeState
    )
  },
  /**
   * Verificar downgrade
   */
  async checkDowngrade() {
    try {
      const response = await axios.post(`${vari.UHR}/license/check`)
      return response.data
    } catch (error) {
      console.error('[marketModule][checkDowngrade]', error)
    }
  },

  /**
   * Verificar downgrade
   */
  async getActualDowngrade() {
    try {
      const response = await axios.get(`${vari.UHR}/license/downgrade`)
      return response.data
    } catch (error) {
      console.error('[marketModule][checkDowngrade]', error)
    }
  },

  /**
   * Verificar downgrade
   */
  async getUsersToSelectInDowngrade() {
    try {
      const response = await axios.get(`${vari.UHR}/license/downgrade/users`)
      return response.data
    } catch (error) {
      console.error('[marketModule][checkDowngrade]', error)
    }
  },
  /**
   * Hacer downgrade
   * @param {vuex} context
   * @param {Object} args
   * @param {String} args.type [downgrade,expired]
   * @param {String} [args.pricingId]
   * @param {Object} args.featuresSelected
   * @param {String[]} args.featuresSelected.lines
   * @param {String} args.featuresSelected.user
   * @param {String} args.featuresSelected.chatbots
   */
  async downgrade(context, { type, pricingId, featuresSelected }) {
    try {
      const url =
        type === 'downgrade' || type == 'payment-rejected'
          ? `${vari.UHR}/license/downgrade`
          : type === 'expired'
          ? `${vari.UHR}/license/downgrade/start`
          : null
      if (!url) return { success: false }
      const response = await axios.post(url, {
        planToId: pricingId,
        featuresSelected,
        type,
      })
      // console.log(response.data)
      // if (response.data.success) {
      //   context.commit('SET_EXPIRATION_PLAN_MODAL', false)
      // }
      return response.data
    } catch (error) {
      console.error('[marketModule][downgrade]', error)
      return { success: false, message: error.message }
    }
  },
  /**
   * Cancelar downgrade
   */
  async cancelDowngrade() {
    try {
      const response = await axios.delete(`${vari.UHR}/license/downgrade`)
      return response.data
    } catch (error) {
      console.error('[marketModule][cancelDowngrade]', error)
      return { success: false, message: error.message }
    }
  },
  /**
   * Obtiene el precio por cada usuario dependiendo del plan
   * @param {*} context
   * @param {*} pricingId
   * @returns
   */
  async pricePerUser(
    context,
    { pricingId, single = false, apportionment = true }
  ) {
    try {
      const response = await axios.post(`${vari.UHR}/license/user/price`, {
        pricingId: pricingId,
        single: single,
        apportionment: apportionment,
      })
      return response.data.result
    } catch (error) {
      console.error('[marketModule][pricePerUser]', error)
    }
  },
  /**
   * Obtener total precio por usuario en plan
   * @param {Object} card - propiedades de un tarjeta de credito
   * @param {Boolean} card.new - si es nuevo o no
   * @param {String} card.name - nombre y apellido juntos
   * @param {Number} card.number - numero de tarjeta
   * @param {String} card.expirationDate - fecha de expiracion de la tarjeta
   * @param {String} card.paymentMethod - metodo de pago
   * @param {Object} purchase - la compra que se va a realizar
   * @param {Object[]} purchase.products los produtos que se estan comprando
   * @param {Number} purchase.products[].amount - cantidad de la bolsa, solo si el producto es de tipo 'pack'
   * @param {String} purchase.products[].idPresentation - id de la opcion elegida, solo si el producto es de tipo 'pack'
   * @param {String} purchase.products[].permission - permiso de cada producto, solo si el producto es de tipo 'pack'
   * @param {Number} purchase.products[].price - el precio x unidad de la opcion elegida
   * @param {Number} purchase.products[].total - el precio total de la opcion elegida
   * @param {String} purchase.products[].type - el tipo de producto, plan o pack
   * @param {Number} purchase.products[].totalUsers - total de usuarios, solo si el producto es de tipo 'plan'
   * @param {Number} purchase.total el total de toda la compra
   */
  async paymentPurchase(context, { card, purchase }) {
    try {
      const response = await axios.post(`${vari.UHR}/admin/purchases`, {
        card,
        purchase,
      })
      await context.dispatch('getPricings')
      await context.dispatch('getCompanyPricing')
      await context.dispatch('getProfile')
      return response.data.result
    } catch (error) {
      console.error('[marketModule][paymentPurchase]', error)
      return error.response.data
    }
  },
  async contactEnterprise(
    context,
    { names, surnames, company, chief, email, code, phone }
  ) {
    try {
      const response = await axios.post(`${vari.UHR}/license/contact`, {
        names: names,
        surnames: surnames,
        company: company,
        charge: chief,
        email: email,
        phone: `${code} ${phone}`,
      })
      return response.data
    } catch (error) {
      console.error('[marketModule][contactEnterprise]', error)
    }
  },
  /**
   *
   * @param {*} context
   * @param {Object} args
   * @param {Number} args.page
   * @param {String} args.search
   * @param {Date}   args.startDate
   * @param {Date}   args.endDate
   * @param {String} args.status
   */
  async fetchPayments(context, { page, search, startDate, endDate, status }) {
    try {
      if (!page) return
      const searchFormatted = search.replace(/#/gm, '')
      const response = await axios.get(
        `${
          vari.UHR
        }/license/billing/list/${page}?date=${new Date()}&startDate=${startDate}&endDate=${endDate}`,
        { params: { search: searchFormatted, status } }
      )
      context.commit('SET_PAYMENTS', response.data.data)
      const pagination = {
        lastPage: response.data.lastPage,
        perPage: response.data.perPage,
        page: response.data.page,
        total: response.data.total,
      }
      context.commit('SET_PAYMENTS_PAGINATION', pagination)
    } catch (error) {
      console.error('[marketModule][fetchPayments]', error)
    }
  },
  /**
   * Realizar un pago manual del plan
   * @param {Object} card - propiedades de un tarjeta de credito
   * @param {Boolean} card.new - si es nuevo o no
   * @param {String} card.name - nombre y apellido juntos
   * @param {Number} card.number - numero de tarjeta
   * @param {String} card.expirationDate - fecha de expiracion de la tarjeta
   */
  async paymentManual(context, { card }) {
    try {
      const response = await axios.post(`${vari.UHR}/admin/payments`, {
        card,
      })
      await context.dispatch('getPricings')
      await context.dispatch('getProfile')
      return response.data.result
    } catch (error) {
      console.error('[marketModule][paymentPurchase]', error)
      return error.data
    }
  },
}

export default actions
