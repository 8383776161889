import Strategies from '@/app/strategies/views/Strategies'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/campaigns',
    name: 'campaigns',
    component: Strategies,
    meta: {
      requiresAuth: true,
      requiresType: ['admin'],
      title: `Campañas | ${VUE_APP_NAME}`,
    },
  },
]
