var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-list-item',[_c('a-row',{staticClass:"cursor-auto",attrs:{"slot":"actions"},slot:"actions"},[_c('a-col',{staticClass:"text--right mrg-bottom-12"},[_c('anchor',{attrs:{"href":_vm.channelStatus.api_status}},[(_vm.channelStatus)?_c('a-tag',{style:(_vm.channelStatus.api_status ? 'cursor:pointer' : ''),attrs:{"color":_vm.channelStatus.color}},[_vm._v(" "+_vm._s(_vm.channelStatus.value)+" ")]):_vm._e()],1)],1),(_vm.channel && _vm.channel.channel === 'whatsapp')?_c('a-col',[_c('a-row',{attrs:{"type":"flex","justify":"end","align":"bottom"}},[(
            _vm.channel.status === 'approved' ||
            (_vm.channel.status === 'unsuscribing' && _vm.channel.active)
          )?_c('router-link',{key:_vm.channel._id,attrs:{"to":{
            name: 'channel',
            params: { channel: _vm.channel.channel },
            query: { channelId: _vm.channel._id },
          }}},[_vm._v(" Ver canal activo ")]):(
            ['pending', 'rejected', 'troubled'].includes(_vm.channel.status)
          )?_c('p',{staticClass:"mrg-bottom-0"},[_c('a',{on:{"click":function($event){return _vm.$emit('onOpenDetails', _vm.channel)}}},[_vm._v("Ver detalles de solicitud")])]):_vm._e()],1)],1):_vm._e(),(
        _vm.channel.active &&
        (_vm.channel.channel === 'messenger' || _vm.channel.channel == 'instagram')
      )?_c('p',{staticClass:"mrg-bottom-0",class:{ 'gray-color-300': !_vm.channel.active },on:{"click":function($event){return _vm.showModal(_vm.channel.channel)}}},[_c('a',[_vm._v("Ver página conectada")])]):_vm._e(),(_vm.channel.channel === 'webchat' && _vm.channel.status === 'approved')?_c('p',{staticClass:"mrg-bottom-0"},[_c('router-link',{attrs:{"to":{
          name: 'channel',
          params: { channel: 'webchat' },
          query: { channelId: _vm.channel._id },
        }}},[_vm._v(" Ver canal activo ")])],1):_vm._e()],1),_c('a-col',{staticClass:"text--left"},[_c('a-list-item-meta',{staticClass:"display-flex align--center"},[_c('SocialIcon',{staticClass:"list-item__icon",attrs:{"slot":"avatar","type":_vm.channel.channel},slot:"avatar"}),_c('template',{slot:"description"},[_c('h6',{staticClass:"semibold-14 mrg-bottom-12"},[_vm._v(" "+_vm._s(_vm.channel.title || 'Canal Solicitado')+" ")]),_c('a-row',{staticClass:"list-item__row",attrs:{"type":"flex"}},[_c('p',{staticClass:"regular-14 text-align-left mrg-bottom-0 list-item__date"},[_vm._v(" "+_vm._s(`Fecha de conexión: ${_vm.approvedSince}`)+" ")]),(_vm.channel.alias)?_c('p',{staticClass:"regular-14 text-align-left mrg-bottom-0"},[_vm._v(" "+_vm._s(`Alias: ${_vm.channel.alias || 'Ninguno'}`)+" ")]):_vm._e()])],1)],2)],1),(
      _vm.channel.active === true &&
      (_vm.channel.channel === 'messenger' || _vm.channel.channel == 'instagram')
    )?_c('a-modal',{attrs:{"title":`Página conectada a tu canal - ${_vm.channel.channel}`,"width":"416px","bodyStyle":_vm.bodyStyle,"centered":""},model:{value:(_vm.modalPageSelected.visible),callback:function ($$v) {_vm.$set(_vm.modalPageSelected, "visible", $$v)},expression:"modalPageSelected.visible"}},[(_vm.channel)?_c('div',{staticClass:"connected-page"},[_c('img',{attrs:{"src":_vm.channel.broadcast.picture,"width":"32px","height":"32px"}}),_c('div',[_c('h6',{staticClass:"heading-h7 connected-page-name"},[_vm._v(" "+_vm._s(_vm.channel.broadcast.name)+" ")]),_c('div',{staticClass:"connected-page-status"},[_c('p',{staticClass:"body-1"},[_vm._v("Conectado")]),_c('a-badge',{attrs:{"status":"success"}})],1)])]):_vm._e(),_c('template',{slot:"footer"},[_c('a-button',{attrs:{"loading":_vm.modalPageSelected.loadingUnlink,"disabled":_vm.modalPageSelected.disabledUnlink},on:{"click":function($event){return _vm.onGuideFacebookUnlink()}}},[_vm._v("Desvincular página")])],1)],2):_vm._e(),(_vm.hasModalInfo && !!Object.keys(_vm.dataModalInfoList).length)?_c('ModalInfoList',{attrs:{"data":_vm.dataModalInfoList,"enumerated":_vm.enumetaredModalInfoList},on:{"handleClose":_vm.closeModalInfo,"handleOk":_vm.okModalInfo}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }