<template>
  <a-form :form="form" layout="vertical">
    <a-form-item label="Seleccione la zona horaria">
      <a-select
        show-search
        placeholder="Seleccione la zona horaria"
        v-decorator="[
          'timezone',
          {
            rules: [
              { required: true, message: 'Por favor rellene el campo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]"
        @change="handleSetTimezone"
      >
        <a-select-option
          v-for="timezone in globalTimezones"
          :key="timezone.timezone"
        >
          {{ timezone.utc }} {{ timezone.timezone }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      label="Seleccione los días"
      :required="true"
      :help="helpStatusDays"
    >
      <GroupSelectedButtons
        ref="groupSelectedButtons"
        :dataButtons="days"
        :allSelecteds="false"
        @buttonSelecteds="setDaysSelected"
        :selecteds="daysSelecteds"
      ></GroupSelectedButtons>
    </a-form-item>
    <a-form-item
      v-if="arrayRangePickers.length > 0"
      label="Seleccione los rangos horarios por día"
      :required="true"
    >
      <list-range-pickers
        ref="arrayRangePickers"
        :data-source="sortDataSource(arrayRangePickers).slice()"
        @onChangeRange="handleChangeRange"
        @onAddRange="handleAddRange"
        @onRemoveRange="handleRemoveRange"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import { mapGetters } from 'vuex'
import GroupSelectedButtons from '@/app/chatbots/components/organisms/buttons/GroupSelectedButtons.vue'
import ListRangePickers from '@/app/chatbots/components/organisms/ListRangePickers'
import transformMixin from '@/app/shared/mixins/transform'
import moment from 'moment'

export default {
  name: 'ScheduleForm',
  mixins: [transformMixin],
  components: {
    GroupSelectedButtons,
    ListRangePickers,
  },
  props: {
    formValues: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    moment,
    days: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    daysSelecteds: [],
    arrayRangePickers: [],
    helpStatusDays: '',
    timezoneSelected: '',
    timezoneOffset: 0,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'schedule-form-chatbot' })
  },
  computed: {
    ...mapGetters(['timezones', 'globalTimezones']),
  },
  methods: {
    setDaysSelected(newDaysSelected) {
      const noDaysSelected = this.daysSelecteds.filter(
        (daySelected) => !newDaysSelected.includes(daySelected)
      )
      noDaysSelected.forEach((day) => {
        this.removeDayToList(day)
      })

      this.daysSelecteds = newDaysSelected
      this.daysSelecteds.forEach((day) => {
        const data = [...this.arrayRangePickers].find(
          (item) => item.order === day
        )
        if (data === undefined) {
          this.addDayToList(day)
        }
      })
      this.handleVerifiedDays()
    },
    addDayToList(day) {
      const itemList = {
        order: day,
        day: day,
        nameDay: this.nameDay(day),
        hours: [
          {
            key: `range-${day}-1`,
            start: null,
            end: null,
          },
        ],
      }
      this.arrayRangePickers.push(itemList)
    },
    removeDayToList(day) {
      this.arrayRangePickers = this.arrayRangePickers.filter(
        (item) => item.order !== day
      )
    },
    nameDay(index) {
      const days = this.transformIndexDays([index])
      return days[0]?.day
    },
    sortDataSource(dataSource) {
      const data = [...dataSource]
      return data.sort((a, b) => a.order - b.order)
    },
    handleChangeRange(order, idRange, newRange) {
      const data = this.arrayRangePickers.find((item) => item.order === order)
      const range = data.hours.find((hour) => hour.key === idRange)
      range.start = newRange.start
      range.end = newRange.end

      console.log('HORA START', range.start.format())
      // const utcZero = moment(range.start).subtract(
      //   this.formValues.timezoneoffset,
      //   'minutes'
      // )
      // console.log('UTC 0', utcZero)
      // const dateTimezoneSelect = utcZero.format('YY-MM-DD HH:mm')
      // const timezoneLocal = Intl.DateTimeFormat().resolvedOptions().timeZone
      // const convertToLocal = moment.tz(dateTimezoneSelect, timezoneLocal)
      // console.log('catar', dateTimezoneSelect)
      // console.log('peru', convertToLocal.format('HH:mm'))
      // const peru = catar.clone().tz('America/Lima')
      // console.log('HORA EN MI ZONA LOCAL', peru.format('HH:mm'))
      // console.log(
      //   moment(range.start, 'HH:mm').tz(this.timezoneSelected).format('HH:mm')
      // )
      // range.start = todayStart.toISOString()
      // range.end = todayEnd.toISOString()
      console.log('Se cambio el range:', order, idRange, newRange)
    },
    handleAddRange(order) {
      const day = this.arrayRangePickers.find((item) => item.order === order)
      const newRange = {
        key: `range-${day.day}-${day.hours.length + 1}`,
        start: null,
        end: null,
      }
      if (day !== undefined) {
        day.hours.push(newRange)
      }
    },
    handleRemoveRange(order, keyRange) {
      const dayObj = this.arrayRangePickers.find((item) => item.order === order)
      dayObj.hours = dayObj.hours.filter((hour) => hour.key !== keyRange)
    },
    setFieldsValues() {
      const timezone = this.formValues.timezone
        ? this.formValues.timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone
      this.form.setFieldsValue({
        timezone: timezone,
      })
      this.timezoneSelected = timezone
      this.timezoneOffset = this.getOffset(timezone)
      this.daysSelecteds = this.formValues.days
        ? this.formValues.days.map((day) => day.day)
        : []
      let scheduleDays = []
      if (this.formValues.days) scheduleDays = [...this.formValues.days]

      this.arrayRangePickers = this.handleCompleteList(scheduleDays)
    },
    handleVerifiedDays() {
      if (this.daysSelecteds.length === 0) {
        this.helpStatusDays = 'Seleccione los días de ejecución.'
      } else {
        this.helpStatusDays = ''
      }
    },
    handleValidateListRanges() {
      this.$refs.arrayRangePickers?.handleSubmitRanges()
    },
    handleSetTimezone(value) {
      this.timezoneSelected = value
      this.timezoneOffset = this.getOffset(value)
    },
    resetFields() {
      this.form.resetFields()
      this.daysSelecteds = []
      this.arrayRangePickers = []
      this.helpStatusDays = ''
      this.timezoneSelected = ''
    },
    handleCompleteList(listRanges) {
      const list = []
      listRanges.forEach((item) => {
        item.order = item.day
        item.nameDay = this.nameDay(item.day)
        list.push(item)
      })
      return list
    },
    getOffset(timezone) {
      const date = moment().tz(timezone)
      console.log(date)
      console.log(date.utcOffset())
      return date.utcOffset()
      // return
    },
  },
}
</script>

<style scoped></style>
