<template>
  <div class="form-enterprise">
    <div class="form-enterprise-content">
      <!-- Modal -->
      <a-modal
        :visible="visible"
        @ok="handleOk"
        width="64em"
        :maskClosable="false"
        :closable="closable"
        centered
        class="form-enterprise-content-modal"
        @cancel="handleCancel"
        :bodyStyle="{ padding: '36px 36px 0px 36px' }"
      >
        <!-- Título -->
        <div class="title">
          <h1 :class="title.tipography">{{ title.text }}</h1>
        </div>
        <!-- Contenido -->
        <div class="body">
          <a-row :gutter="[50]" type="flex" justify="space-between">
            <a-col :xs="0" :sm="0" :md="0" :lg="0" :xl="12" :xxl="12">
              <!-- Información -->
              <div class="body-info">
                <p>
                  Por ser Enterprise gozarás de los máximos beneficios ofrecidos
                  por Ticker.
                </p>
                <!-- Beneficios -->
                <ul>
                  <li
                    v-for="feature in features"
                    :key="feature.key"
                    class="modal_item"
                  >
                    <i class="modal__icon">
                      <component
                        :is="feature.icon"
                        :fill="feature.fill"
                        width="20px"
                        heigth="20px"
                      />
                    </i>
                    <span class="feature body-2" v-if="!feature.url">{{
                      feature.title
                    }}</span>
                    <a
                      class="feature"
                      v-else
                      :href="feature.url"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ feature.title }}</a
                    >
                  </li>
                </ul>
                <p>Y sin aun no te convencemos, que te lo cuenten ellos:</p>
                <div class="testimony">
                  <bubble
                    v-for="(bubble, index) in testimonies"
                    :key="index"
                    :message="bubble.text"
                    :subText="bubble.chief"
                    :avatar="bubble.avatar"
                  />
                </div>
              </div>
            </a-col>
            <!-- Formulario -->
            <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12" :xxl="12">
              <div class="body-form">
                <p>
                  Nos pondremos en contacto contigo, solo necesitas completar
                  tus datos.
                </p>
                <a-form
                  :form="form"
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
                  @submit.prevent="handleSubmit"
                >
                  <!-- Nombres -->
                  <a-form-item label="Nombres">
                    <a-input
                      placeholder="Escribe aquí.."
                      v-decorator="[
                        'names',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Rellene este campo',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                  <!-- Apellidos -->
                  <a-form-item label="Apellidos">
                    <a-input
                      placeholder="Escribe aquí.."
                      v-decorator="[
                        'surnames',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Rellene este campo',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                  <!-- Empresa -->
                  <a-form-item label="Empresa">
                    <a-input
                      placeholder="Escribe aquí.."
                      v-decorator="[
                        'company',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Rellene este campo',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                  <!-- Puesto -->
                  <a-form-item label="Puesto">
                    <a-input
                      placeholder="Escribe aquí.."
                      v-decorator="[
                        'chief',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Rellene este campo',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                  <!-- Correo -->
                  <a-form-item label="Correo corporativo">
                    <a-input
                      placeholder="Escribe aquí.."
                      v-decorator="[
                        'email',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Rellene este campo',
                            },
                            {
                              type: 'email',
                              message: 'No es un email válido.',
                            },
                            {
                              whitespace: true,
                              message: 'No se admiten espacios en blanco.',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                  <!-- Telefono -->
                  <a-form-item
                    label="Teléfono de contacto"
                    :style="{
                      display: 'inline-block',
                      width: '100%',
                    }"
                  >
                    <a-row :gutter="8" type="flex">
                      <!--El orden de las columnas debe estar al reves para que se pueda reconocer el error de telefono-->
                      <a-col :span="16" :order="2">
                        <a-input
                          v-decorator="[
                            'phone',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Por favor rellena el campo',
                                },
                                {
                                  whitespace: true,
                                  message: 'No se admiten espacios en blanco.',
                                },
                                {
                                  pattern: /^([0-9])*$/g,
                                  message: 'Solo puedes ingresar números.',
                                },
                              ],
                            },
                          ]"
                          :maxLenght="20"
                          :disabled="!countrySelected ? true : false"
                          placeholder="Escribe aquí..."
                        >
                        </a-input>
                      </a-col>
                      <a-col :span="8" :order="1">
                        <a-select
                          show-search
                          v-decorator="[
                            'code',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Por favor rellena el campo',
                                },
                              ],
                            },
                          ]"
                          option-filter-prop="children"
                          placeholder="País"
                          @change="handleSelectCountry"
                        >
                          <a-select-option
                            v-for="country in countries"
                            :key="country._id"
                            :title="country.nameSpanish"
                          >
                            {{ country.dialCode }}
                            {{ country.nameSpanish }}
                          </a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>
                  </a-form-item>
                  <!-- Submit -->
                  <a-form-item :wrapper-col="{ span: 24, offset: 0 }">
                    <a-button
                      v-show="false"
                      ref="btnSubmit"
                      type="primary"
                      html-type="submit"
                    >
                      Submit
                    </a-button>
                  </a-form-item>
                </a-form>
              </div>
            </a-col>
          </a-row>
        </div>
        <!-- Footer de botones -->
        <template slot="footer" class="footer">
          <a-button
            key="back"
            class="footer__button"
            :disabled="loading"
            @click="handleCancel"
            :size="buttons_size"
            >Omitir por ahora</a-button
          >
          <a-button
            key="submit"
            class="footer__button"
            type="primary"
            :loading="loading"
            @click="handleOk"
            :size="buttons_size"
            :disabled="disabledOk"
          >
            Quiero ser Enterprise
          </a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import compareMixin from '@/app/shared/mixins/compare'
import Bubble from '@/app/shared/components/molecules/Bubble'
import IconChatbot from '@/app/shop/components/icons/IconChatbot.vue'
import IconCoding from '@/app/shop/components/icons/IconCoding.vue'
import IconTicket from '@/app/shop/components/icons/IconTicket.vue'
import IconKey from '@/app/shop/components/icons/IconKey.vue'
import IconHeadphone from '@/app/shop/components/icons/IconHeadphone.vue'
import IconSetting from '@/app/shop/components/icons/IconSetting.vue'

export default {
  name: 'FormEnterprise',
  components: {
    Bubble,
    IconChatbot,
    IconCoding,
    IconTicket,
    IconKey,
    IconHeadphone,
    IconSetting,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false, // animación de cargando del botón primario
    form: null, // Datos de formulario
    closable: true, // Modal se puede cerrar?
    // Datos informaticos de beneficios y características
    countrySelected: null, // País seleccionado,
    features: [
      {
        key: 'chatbots',
        icon: IconChatbot,
        fill: '#52C41A',
        title: 'Chatbots ilimitados',
      },
      {
        key: 'api',
        icon: IconCoding,
        fill: '#EB2F96',
        title: 'Consumos de ticker API',
      },
      {
        key: 'workspace',
        icon: IconTicket,
        fill: '#9254DE',
        title: 'Tickets ilimitados',
      },
      {
        key: 'key',
        icon: IconKey,
        fill: '#13C2C2',
        title: 'Key account manager',
      },
      {
        key: 'setting',
        icon: IconSetting,
        fill: '#F5222D',
        title: 'Configuración ilimitada',
      },
      {
        key: 'support',
        icon: IconHeadphone,
        fill: '#FAAD14',
        title: 'Soporte Enterprise',
      },
    ],
    testimonies: [
      {
        id: 1,
        avatar: '/img/clients/kontigo-logo.jpg',
        chief: 'Aimee Concha, Jefe de atención al cliente de Kontigo',
        text: `${process.env.VUE_APP_NAME} nos ayuda muchísimo en la gestión de los casos de nuestros clientes, así como la asignación según los tipos. Además, nos ha facilitado la comunicación por WhatsApp y Messenger, mejorando en calidad y tiempos de respuesta.`,
      },
      {
        id: 2,
        avatar: '/img/clients/factora.png',
        chief: 'Anthony Huaman, Experiencia Omnicanal de Factora',
        text: `El API de WhatsApp Business en ${process.env.VUE_APP_NAME} nos ha facilitado la comunicación con nuestros clientes. Tener este canal en una plataforma y no depender de un celular nos ha permitido tener métricas, contabilizar las conversaciones y tiempos de nuestra operación.`,
      },
    ],
  }),
  mounted() {
    this.form = this.$form.createForm(this, { name: 'contact-enterprise' })
  },
  mixins: [compareMixin],
  computed: {
    ...mapGetters([
      'countries',
      'buttons_size',
      'active_breakpoint',
      'profile',
    ]),

    /**
     * @return {String} - titulo del modal
     */
    title() {
      return this.active_breakpoint.is_mobile
        ? { text: 'Necesitas subir a enterprise', tipography: 'heading-h4' }
        : {
            text: '¿Por qué deberías subir a Enterprise?',
            tipography: 'heading-h5',
          }
    },
    /**
     * @return {Boolean} - solo si es admin se deshabilitara y esta en mobile
     */
    disabledOk() {
      return !this.isAllowedFor(this.profile.type, ['admin'])
    },
  },
  methods: {
    ...mapActions(['contactEnterprise']),

    /**
     * Aborta el proceso del modal
     */
    handleCancel() {
      // this.visible = false
      this.$emit('reset')
      this.form.resetFields()
    },
    /**
     * Llama a la función del envío de formulario
     */
    handleOk() {
      this.$refs.btnSubmit.$el.click()
    },
    /**
     * Envía los datos del formulario
     */
    handleSubmit(e) {
      e.preventDefault()
      this.loading = true
      this.closable = false
      this.form.validateFields(async (err, values) => {
        if (!err) {
          values.code = `${this.countrySelected.dialCode}`
          const res = await this.contactEnterprise(values)
          if (res.success === true) this.$emit('done')
          else
            this.$message.error(
              'No pudimos enviar tu solicitud 😓. Por favor vuelve a intentarlo.'
            )
        }
        this.loading = false
        this.closable = true
      })
    },
    /**
     * @param {String} codeId
     */
    handleSelectCountry(codeId) {
      this.countrySelected = this.countries.find((el) => el._id === codeId)
    },
  },
}
</script>

<style lang="sass" scoped>
$margin: 0px

.form-enterprise
  &-content
    &-modal
      .title
        margin: $margin $margin 0 $margin
        h1
          color: $gray_dark_900
          @include xs
            text-align: center
          @include sm
            text-align: center
          @include md
            text-align: center
          @include lg
            text-align: center
      .body
        font-size: 16px
        color: $gray_dark_800
        margin: 0px $margin $margin $margin
        &-info
          ul
            list-style: none
            margin: 0
            padding: 0
            display: grid
            grid-template-columns: repeat(2, 1fr)
            padding: 0 0 20px 0
            li
              display: flex
              align-items: center
              i svg
                margin: 0 8px 0 0
        &-form
          @include xs
            text-align: center
          @include sm
            text-align: center
          @include md
            text-align: center
          @include lg
            text-align: center
.modal__icon
  display: flex
  align-items: center
.modal_item
  margin-bottom: 4px
.footer__button
  @include xs
    flex-grow: 1
  @include sm
    flex-grow: 1
  @include md
    flex-grow: 1
  @include lg
    flex-grow: 1
</style>

<style lang="sass">
.form-enterprise
  &-content
    &-modal
      .ant-modal-footer
        display: flex
        justify-content: center
        @include xl
          justify-content: flex-end
</style>
