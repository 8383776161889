<template>
  <div class="options">
    <template v-if="!active_breakpoint.is_mobile">
      <emoji-picker
        v-if="showEmojiButton"
        @onChangeEmoji="(emoji) => $emit('onAddEmoji', emoji)"
        :disabled="disabled"
      />
      <!-- Boton de adjuntar archivos (Según permisos por BD) -->
      <a-dropdown
        v-if="showAttachButton"
        :trigger="['click']"
        placement="bottomRight"
        :disabled="disabled"
      >
        <a-tooltip title="Adjuntar">
          <a-icon
            type="paper-clip"
            class="options__icon"
            :class="{ 'options__icon--disabled cursor-not-allowed': disabled }"
          />
        </a-tooltip>
        <a-menu slot="overlay">
          <a-menu-item v-if="showTypeFile('contact')">
            Seleccionar Contacto
          </a-menu-item>
          <a-menu-item v-if="showTypeFile('location')">
            Seleccionar Ubicación
          </a-menu-item>
          <a-menu-item v-if="showTypeFile('audio')">
            Seleccionar Audio
          </a-menu-item>
          <a-menu-item v-if="showTypeFile('video')">
            Seleccionar Video
          </a-menu-item>
          <a-menu-item
            v-if="showTypeFile('image')"
            @click="$emit('onAttachPicture')"
          >
            Subir imagen
          </a-menu-item>
          <a-menu-item v-if="showAttachFile" @click="$emit('onAttachFile')">
            Subir documento
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <!-- Icono de plantilla para WhatsApp -->
      <a-tooltip placement="topLeft" v-if="showTemplateButtonWhatsApp">
        <template slot="title">
          <span v-if="!disabledTemplate">Enviar plantillas</span>
          <span v-if="disabledTemplate"
            >Plantillas
            <br />
            No habilitado porque el cliente no ha respondido.
          </span>
        </template>
        <i
          :class="{
            'options__icon--disabled cursor-not-allowed': disabledTemplate,
          }"
          @click="$emit('onShowTemplates', disabledTemplate)"
          class="anticon options__icon"
        >
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.4 5.515v10.07a1.83 1.83 0 001.83-1.831V4.6a1.83 1.83 0 00-1.83-1.831h-9.154a1.83 1.83 0 00-1.83 1.83h10.069c.505 0 .915.41.915.916zm-2.746 12.816V8.26a.915.915 0 00-.915-.915H5.668c0-1.011.82-1.83 1.831-1.83h9.154c1.011 0 1.83.819 1.83 1.83V16.5a1.83 1.83 0 01-1.83 1.83zM13.908 8.26H4.754a1.83 1.83 0 00-1.83 1.831v9.154c0 1.011.819 1.83 1.83 1.83h9.154a1.83 1.83 0 001.83-1.83v-9.154a1.83 1.83 0 00-1.83-1.83zM4.754 19.246v-9.154h9.154v9.154H4.754z" fill="currentColor"/></svg>
        </i>
      </a-tooltip>
      <!--Icono de respuestas rapidas-->
      <a-tooltip placement="topLeft" v-if="showFastAnswersButton">
        <template slot="title">
          <span>Respuestas rápidas</span>
        </template>
        <a-icon
          type="thunderbolt"
          class="options__icon"
          @click="$emit('onShowFastAnswers')"
          :class="{ 'options__icon--disabled cursor-not-allowed': disabled }"
        />
      </a-tooltip>
    </template>
    <!--menu para responsive-->
    <a-dropdown
      v-else
      :trigger="['click']"
      placement="bottomRight"
      :disabled="disabledMoreOptions"
    >
      <div class="options__container-icon--more">
        <i
          class="anticon options__icon options__icon-more"
          :class="{
            'options__icon--disabled cursor-not-allowed': disabledMoreOptions,
          }"
        >
          <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 14a2 2 0 110-4 2 2 0 010 4zm7 0a2 2 0 110-4 2 2 0 010 4zm5-2a2 2 0 104 0 2 2 0 00-4 0z" fill="currentColor"/></svg>
        </i>
      </div>
      <a-menu slot="overlay">
        <!--Mostrar opciones de adjuntar-->
        <template v-if="showAttachButton && !disabled">
          <a-menu-item v-if="showTypeFile('contact')" class="menu-item">
            Seleccionar Contacto
          </a-menu-item>
          <a-menu-item v-if="showTypeFile('location')" class="menu-item">
            Seleccionar Ubicación
          </a-menu-item>
          <a-menu-item v-if="showTypeFile('audio')" class="menu-item">
            Seleccionar Audio
          </a-menu-item>
          <a-menu-item v-if="showTypeFile('video')" class="menu-item">
            Seleccionar Video
          </a-menu-item>
          <a-menu-item
            v-if="showTypeFile('image')"
            class="menu-item"
            @click="$emit('onAttachPicture')"
          >
            Subir imagen
          </a-menu-item>
          <a-menu-item
            v-if="showAttachFile"
            class="menu-item"
            @click="$emit('onAttachFile')"
          >
            Subir documento
          </a-menu-item>
        </template>
        <a-menu-item
          v-if="showTemplateButtonWhatsApp && !disabledTemplate"
          class="menu-item"
          @click="$emit('onShowTemplates')"
        >
          Plantillas de WhatsApp
        </a-menu-item>
        <a-menu-item
          v-if="showFastAnswersButton && !disabled"
          class="menu-item"
          @click="$emit('onShowFastAnswers')"
        >
          Respuestas rápidas
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmojiPicker from '@/app/tickets/components/molecules/EmojiPicker'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'MenuOptionsChat',
  props: {
    disabled: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    EmojiPicker,
  },
  data: () => ({
    channelsWithoutFiles: ['instagram'],
  }),
  mixins: [supportMixin],
  computed: {
    ...mapGetters([
      'ticketSelected',
      'company',
      'company_fast_answers',
      'active_breakpoint',
    ]),
    /**
     * El canal de la empresa que tiene el ticket seleccionado
     * @returns {Object}
     */
    channelCompanyOfTicketSelected() {
      return this.channelCompanyOfTicket(this.ticketSelected)
    },
    /**
     * Verifica si esta habilitado la opcion de selección de emojis
     * @returns {Boolean}
     */
    showEmojiButton() {
      if (
        !this.channelCompanyOfTicketSelected ||
        !this.channelCompanyOfTicketSelected.api
      )
        return false
      return this.channelCompanyOfTicketSelected.api.chat_options?.emojis.active
    },
    /**
     * Verifica si esta habilitado la opcion de adjuntar archivos
     * @returns {Boolean}
     */
    showAttachButton() {
      if (
        !this.channelCompanyOfTicketSelected ||
        !this.channelCompanyOfTicketSelected.api
      )
        return false
      return this.channelCompanyOfTicketSelected.api.chat_options?.attachments
        .active
    },
    /**
     * Verifica si esta habilitado la opcion de selección de plantillas
     * @returns {Boolean}
     */
    showTemplateButton() {
      if (
        !this.channelCompanyOfTicketSelected ||
        !this.channelCompanyOfTicketSelected.api
      )
        return false
      return this.channelCompanyOfTicketSelected.api.chat_options?.templates
        .active
    },
    /**
     * Deshabilita el el botón de enviar plantilla
     */
    disabledTemplate() {
      if (
        this.ticketSelected &&
        this.ticketSelected.abstract.method === 'received'
      )
        return false
      // Para optimizar, busca del abstract
      else {
        // Pero en el abstract tambien puede ser una nota, para ello se busca en el arreglo de mensajes
        const lastMessage = this.ticketSelected?.messages
          .filter(
            (msg) =>
              msg.method !== 'note' &&
              !msg.message.auto &&
              msg.status !== 'failed'
          )
          .pop()
        if (lastMessage && lastMessage.method === 'received') return false
      }
      return true
    },
    // muestra el template o no segun el dato de la bd
    showTemplate() {
      return this.company?.settings.chat.sendMoreTemplates
    },
    /**
     * Mostrar Icono de plantilla para WhatsApp
     * @return {Boolean}
     */
    showTemplateButtonWhatsApp() {
      if (!this.ticketSelected) return false
      return (
        this.showTemplateButton &&
        this.showTemplate &&
        this.ticketSelected &&
        this.ticketSelected.channel.type === 'whatsapp'
      )
    },
    /**
     * Mostrar boton para enviar respuestas rapidas
     * @return {Boolean}
     */
    showFastAnswersButton() {
      if (!this.ticketSelected) return false
      return (
        !this.disabled &&
        this.company_fast_answers &&
        !!this.company_fast_answers.length
      )
    },
    /**
     * Mostrar boton para mas opciones
     * @return {Boolean}
     */
    disabledMoreOptions() {
      if (!this.ticketSelected) return true
      if (this.ticketSelected.channel.type === 'whatsapp')
        return (
          this.disabled && this.disabledTemplate && !this.showFastAnswersButton
        )
      else return this.disabled && !this.showFastAnswersButton
    },
    /**
     * Mostrar la opcion de adjuntar documentos
     * @return {Boolean}
     */
    showAttachFile() {
      return (
        this.ticketSelected &&
        this.showTypeFile('document') &&
        !this.channelsWithoutFiles.includes(this.ticketSelected.channel.type)
      )
    },
  },
  methods: {
    /**
     * Mostrar la opción de adjuntar o no según los permisos del proveedor API en BD
     * @param {String} typeFile ["image", "document", "video", "audio", "location", "contact"]
     * @returns {Boolean}
     */
    showTypeFile(typeFile) {
      if (!this.channelCompanyOfTicketSelected) return false
      return this.channelCompanyOfTicketSelected.api.chat_options.attachments.formats.some(
        (format) => format.type_file === typeFile
      )
    },
  },
}
</script>

<style lang="sass" scoped>
.options
  display: flex
  &__icon
    font-size: 26px
    margin-left: 12px
    outline: none
    cursor: pointer
    color: $gray_dark_700
    &--disabled
      color: $gray_6
    &:not(.options__icon--disabled):hover
      color: $blue_light_900
    &:not(.options__icon--disabled):focus
      color: $blue_light_900
    &-more
      margin: 0px
  &__container-icon--more
    @include xs
      margin: -8px -8px -8px -12px
      padding: 14px 8px 14px 18px
    @include sm
      margin: -8px -8px -8px -12px
      padding: 14px 8px 14px 18px
    @include md
      margin: -8px -8px -8px -12px
      padding: 14px 8px 14px 18px
.menu-item
  padding: 12px
</style>
