<template>
  <div
    class="menu-item-footer"
    :class="{
      'menu-item-footer--collapse': collapse,
      'menu-item-footer--no-collapse': !collapse,
    }"
  >
    <div class="menu-item-footer__content">
      <a-avatar
        v-if="avatar"
        :src="avatar"
        :size="32"
        class="menu-item-footer__avatar"
        alt="Avatar de perfil"
      />
      <DefaultIconLetter
        v-else
        :names="names"
        class="menu-item-footer__avatar"
      />
      <div v-if="collapse" class="menu-item-footer__texts">
        <p
          class="
            body-3
            menu-item-footer__text menu-item-footer__text--username
            mrg-bottom-0
            ellipsis
          "
          style="display: inherit"
        >
          {{ names }}
        </p>
        <p
          class="
            body-1
            menu-item-footer__text menu-item-footer__text--email
            mrg-bottom-0
            ellipsis
          "
          style="display: inherit"
        >
          {{ email }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'

export default {
  name: 'MenuItemFooter',
  components: {
    DefaultIconLetter,
  },
  props: {
    names: { type: String, required: false, default: 'Ticketita' },
    email: { type: String, required: false, default: 'ticketita@ticker.com' },
    collapse: { type: Boolean, required: false, default: false },
    avatar: { type: String, required: false },
  },
}
</script>

<style lang="sass" scoped>
.menu-item-footer
  height: 72px
  position: relative
  &__content
    display: flex
    justify-content: space-between
    align-items: center
  &__avatar
  &__text
    text-align: left
    &--username
      color: $white_000
    &--email
      color: $gray_dark_600
  &__texts
    width: 140px
    margin-right: 20px
  &--collapse
    width: 204px
    margin-left: -3px
  &--no-collapse
    width: inherit
    right: 20px
    height: 100%
    display: flex
    align-items: center
    .menu-item-footer
      &__content
      &__avatar
        left: 17px
</style>
