import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import compare from '@/app/shared/utils/compare'

const actions = {
  /**
   * Emite el evento de agente escribiendo
   * @param {vuex}    context
   * @param {Boolean} typing Escribiendo?
   */
  async emitTyping(context, istyping) {
    try {
      if (!(await compare.isAllowedFor(context, ['agent']))) return
      const payload_socket = {
        idCompany: context.getters.profile.company.companyId,
        idArea: context.getters.ticketSelected.company.lineId,
        userId: context.getters.profile.userId,
        istyping: istyping,
      }
      this._vm.$socket.emit('server:area:gross:mode:agent', payload_socket)
    } catch (error) {
      console.error(error)
    }
  },
  /** Lista las plantillas asignadas al usuario */
  async getAssignedTemplates(context, channelId = null) {
    try {
      // si no se pasa el id del canal, se toma por defecto al primer elemento del array de canales
      let defaultChannelId = null
      if (!channelId) {
        defaultChannelId = context.getters.company.channels.whatsapp
          ? context.getters.company.channels.whatsapp.channelIds[0]
          : null
        if (!defaultChannelId) return
      }
      const response = await axios.get(
        `${vari.UHR}/templates/${defaultChannelId || channelId}`
      )

      if (response.data.success) {
        const templates = response.data.data
        context.commit('SET_ASSIGNED_TEMPLATES', templates)
      }
      context.commit(
        'SET_HAVE_ASSIGNED_TEMPLATES',
        false || !!response.data.totalTemplatesAllChannels
      )
    } catch (error) {
      console.error('[chatModule][getAssignedTemplates]', error)
    }
  },
  /**
   * Trae los mensajes de un ticket apginandolo
   * @param {vuex}    context
   * @param {Object}  args
   * @param {String}  args.target Destino [inbox, history]
   * @param {Boolean} args.fromStart - obtiene los mensajes desde el inicio
   */
  async paginateMessages(context, { target, fromStart = false }) {
    try {
      if (!['inbox', 'history', 'history-manage'].includes(target))
        throw 'target invalid'

      const ticketSelected =
        target === 'inbox'
          ? context.getters.ticketSelected
          : context.getters.oldTicketSelected

      if (!ticketSelected) return
      const idTicket = ticketSelected._id
      if (!ticketSelected.messages) ticketSelected.messages = []

      const skip = fromStart ? 0 : ticketSelected.messages.length

      const url = `${vari.UHR}/agent/messages/ticket/${idTicket}/${skip}`
      const response = await axios.get(url)
      const messages = response.data
      if (target === 'inbox')
        context.commit('ADD_MESSAGES_IN_TICKET', {
          idTicket,
          status: ticketSelected.status,
          newMessages: messages,
        })
      else if (target === 'history')
        context.commit('ADD_MESSAGES_IN_TICKET', {
          idTicket,
          status: 'old',
          newMessages: messages,
        })
      else if (target === 'history-manage') {
        const ticket = context.getters.ticketsToManage.data.find(
          (ticket) => ticket._id == idTicket
        )
        context.commit('ADD_MESSAGES_IN_TICKET', {
          idTicket,
          status: 'old',
          newMessages: messages,
          ticketToManage: ticket,
        })
      }
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Agregar un nuevo mensaje a un ticket
   * @param {*} context
   * @param {Object} message Cuerpo del mensaje
   * @param {String} message.text Texto del mensaje
   * @param {String} message.imageUrl Ruta de la imagen en GCP
   * @param {String} message.pdfUrl Ruta del documento en GCP
   * @param {String} message.wordUrl Ruta del word en GCP
   * @param {String} message.excelUrl Ruta del excel en GCP
   * @param {String} message.pptUrl Ruta del ppt en GCP
   */
  async addMessageInTicket(context, message) {
    try {
      if (context.getters.ticketSelected.status === 'ended') return

      const idTicket = context.getters.ticketSelected._id
      const channel = context.getters.ticketSelected.channel.type
      const channelCompanyId = context.getters.ticketSelected.channelCompanyId
      const userId = context.getters.profile.userId
      const clientId = context.getters.client._id
      /** Id temporal hasta recibir el Id del servidor */
      const tempMessageId = `TEMP${Math.floor(
        Math.random() * (9999999 - 1000000) + 1
      )}`
      /** Mostrar la burbuja de chat */
      const actualUser = context.getters.profileRemote
        ? context.getters.profileRemote
        : context.getters.profile

      let tempParamsMessageTicket = {
        message: message,
        idTicket: idTicket,
        apiMessageId: tempMessageId,
        apiReceivedAt: new Date(),
        method: 'sent',
        status: 'pending',
        type: 'free',
        user: {
          ...actualUser,
        },
      }
      context.commit('ADD_TEXT_MESSAGE_IN_TICKET', tempParamsMessageTicket)
      /** Enviar data al servidor */
      const paramMessage = {
        userId: userId,
        clientId: clientId,
        ticketId: idTicket,
        message: message,
        channel: channel,
        channelCompanyId: channelCompanyId,
        user: {
          ...actualUser,
        },
      }
      switch (channel) {
        case 'whatsapp': {
          Object.assign(paramMessage, {
            phoneTo: context.getters.ticketSelected.channel.phoneFrom,
          })
          break
        }
        default: {
          Object.assign(paramMessage, {
            apiClientId: context.getters.ticketSelected.channel.apiClientId,
          })
        }
      }
      const response = await axios.put(`${vari.UHR}/chat/free`, paramMessage)
      if (response.data.success === false) {
        context.commit('SET_STATUS_CHAT_INPUT', {
          idTicket,
          ticketStatus: 'active',
          inputStatus: false,
        })
        context.commit('QUIT_MESSAGE_FROM_TICKET', {
          ticketId: idTicket,
          apiMessageId: tempMessageId,
          status: 'active',
        })
        // si la respuesta era solo un mensaje por un segundo, despues de 1 seg se vuelve a habilitar
        if (response.data.result === 'only_one_message_per_second') {
          setTimeout(() => {
            context.commit('SET_STATUS_CHAT_INPUT', {
              idTicket,
              ticketStatus: 'active',
              inputStatus: true,
            })
          }, 1000)
        }
        return response.data
      }

      // validar si el canal es webchat y si la sesión ya ha desaparecido
      if (channel === 'webchat') {
        if (response.data?.apiResult?.details === 'CHAT_SESSION_NOT_FOUND') {
          // bloqueo el input
          context.commit('SET_STATUS_CHAT_INPUT', {
            idTicket,
            ticketStatus: 'active',
            inputStatus: false,
          })

          // agrego el placeholder
          context.commit('SET_PLACEHOLDER_CHAT_INPUT', {
            idTicket,
            ticketStatus: 'active',
            placeholder: response.data?.apiResult?.placeholderTextInput,
          })
        }
      }

      paramMessage.idMessage = response.data.apiResult.sid
      paramMessage.message.status = response.data.apiResult.status
      let paramsMessageTicket = {
        idMessage: response.data.apiResult.sid,
        message: paramMessage.message,
        idTicket: paramMessage.ticketId,
        tempMessageId: tempMessageId,
        apiMessageId: response.data.apiResult.sid,
        apiReceivedAt: response.data.apiResult.dateCreated,
        method: 'sent',
        status: response.data.apiResult.status,
        type: 'free',
      }
      context.commit('REPLACE_TEMP_MESSAGEID', {
        ticketId: paramsMessageTicket.idTicket,
        tempMessageId,
        apiMessageId: paramMessage.idMessage,
        apiReceivedAt: paramsMessageTicket.apiReceivedAt,
      })

      //   context.dispatch("sumMultimedia", paramsMessageTicket) // Sumar Contador de archivos multimedia
      //   // console.log('[addMessageInTicket] addMessageInTicket',paramsMessageTicket)
      context.commit('SET_MESSAGE_STATUS', paramsMessageTicket)
      //   context.commit("SET_PEAK_BUBLE", {
      //     idTicket: paramsMessageTicket.idTicket,
      //   }) /** Agregar la punta de la burbuja de chat al primer mensaje con método diferente */
      context.commit('CLEAR_PENDINGS', idTicket)
      context.commit('QUIT_TICKET_UNREAD', idTicket)

      // Emitir al visor del admin
      const payload = {
        ...paramMessage,
        type: 'free',
        method: 'sent',
        apiMessageId: paramsMessageTicket.apiMessageId,
        apiReceivedAt: paramsMessageTicket.apiReceivedAt,
      }
      this._vm.$socket.emit('server:chat:push', payload)
    } catch (error) {
      console.error('[chatModule][addMessageInTicket]', error)
      return error.response?.data
    }
  },
  /**
   * Subir archivo
   * @param {*} context
   * @param {Object} args
   * @param {File} args.file
   * @return {Object} Objeto con resultado exitoso
   */
  async uploadFileMessage(context, { file }) {
    try {
      if (!file) return

      const idTicket = context.getters.ticketSelected._id
      const formData = new FormData()
      formData.append('fileMessage', file)
      formData.append('idTicket', idTicket)

      const response = await axios({
        method: 'post',
        url: `${vari.UHR}/chat/upload`,
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Length': '1024',
          },
        },
      })

      return response.data
    } catch (error) {
      console.error(error)
      return { success: false }
    }
  },
  /**
   * Agrega una nota de mensaje a un ticket
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket para agregar la nota
   * @param {String} args.text Texto del mensaje que irá en la nota
   * @param {String} args.mailboxId // Id del buzón al que se está moviendo - opcional
   * @param {String} args.scheduleId // Id del evento que se está agendando - opcional
   * @param {Boolean} args.removed // Se está quitando el ticket?
   * @param {Boolean} args.emit // Emitirlo al socket?
   */
  async addNoteMessage(
    context,
    { idTicket, text, mailboxId, scheduleId, removed, emit = true }
  ) {
    // Por defecto emitirá el evento al socket
    try {
      const noteMessage = {
        _id: `TEMP${Math.floor(Math.random() * (9999999 - 1000000) + 1)}`,
        method: 'note',
        ticketId: idTicket,
        message: { text },
        updated_at: new Date(),
        created_at: new Date(),
      }
      if (scheduleId) noteMessage.scheduleId = scheduleId
      context.commit('ADD_NOTE_MESSAGE', {
        note: noteMessage,
        idTicket,
        mailboxId,
      })
      if (!emit) return
      const payload = {
        ...noteMessage,
        userId: context.getters.profile.userId,
        removed,
      }
      this._vm.$socket.emit('server:chat:push', payload)
    } catch (error) {
      console.error('[chatModule][addNoteMessage] error', error)
    }
  },
  /**
   * Envía una plantilla y crea un ticket
   * @param {*} context
   * @param {Object} paramsMessage Parametros de la plantilla mensaje
   * @param {String} paramsMessage.phoneTo Número de whatsapp del destinatario de la plantilla
   * @param {Object} paramsMessage.message Objeto del mensaje
   * @param {String} paramsMessage.message.text Texto del mensaje que se enviará, debe cumplir con el formato de una plantilla
   * @param {String} paramsMessage.lineId Id de la cola
   * @param {String} paramsMessage.channelCompanyId - id del canal de la empresa (debe ser de whatsapp)
   * @param {Object} paramsMessage.template Objeto de la plantilla seleccionada
   * @param {String} paramsMessage.template.name Nombre de la plantilla
   * @param {Object[]} paramsMessage.template.parameters Parametros de la plantilla
   * @param {Boolean} paramsMessage.newMessage Para ticket nuevo? sino uno abierto
   * @param {String} [paramsMessage.ticketId] Id del ticket, solo si newMessage=false
   * @return {Object} Devuelve error o éxito dentro del objeto, en success
   */
  async sendTemplate(context, paramsMessage) {
    try {
      const response = await axios.post(
        `${vari.UHR}/chat/template`,
        paramsMessage
      )
      if (response.data.success === false) return response.data

      let paramsMessageTicket = {}
      const actualUser = context.getters.profileRemote
        ? context.getters.profileRemote
        : context.getters.profile

      // si es para un mensaje nuevo
      if (paramsMessage.newMessage) {
        paramsMessageTicket = {
          idMessage: response.data.apiResult.sid,
          message: paramsMessage.message,
          idTicket: response.data.ticketOwner.idTicket,
          ticketNumber: response.data.ticketOwner.ticketNumber,
          clientId: response.data.ticketOwner.client.clientId,
          names: response.data.ticketOwner.client.names,
          apiMessageId: response.data.apiResult.sid,
          apiReceivedAt: response.data.apiResult.dateCreated,
          status: response.data.apiResult.status,
          line: response.data.ticketOwner.line,
          method: 'sent',
          type: 'template',
          user: {
            ...actualUser,
          },
          settings: response.data.ticketOwner.ticket.settings,
        }
      } else {
        // si es para continuar
        paramsMessageTicket = {
          idMessage: response.data.apiResult.sid,
          message: paramsMessage.message,
          idTicket: response.data.ticketOwner.idTicket,
          ticketNumber: context.getters.ticketSelected.ticket,
          clientId: response.data.ticketOwner.clientId,
          names: context.getters.ticketSelected.client.names,
          apiMessageId: response.data.apiResult.sid,
          apiReceivedAt: response.data.apiResult.dateCreated,
          status: response.data.apiResult.status,
          line: {
            lineId: context.getters.ticketSelected.lineId,
            lineName: context.getters.ticketSelected.lineName,
          },
          method: 'sent',
          type: 'template',
          user: {
            ...actualUser,
          },
        }
      }
      /** Comprobar si el ticket se encuentra en el arreglo existente */
      let ticket = context.getters.tickets.find(
        (ticket) => ticket._id === paramsMessageTicket.idTicket
      )
      /** Si no está, se le agrega */
      if (!ticket) {
        const defaultMailbox = context.getters.mailboxes.find(
          (mailbox) => mailbox.default
        )
        const defaultValuesTicket = {
          mailboxId: defaultMailbox.idMailbox,
          mailboxName: defaultMailbox.title,
          multimedia: { images: 0, videos: 0, documents: 0 },
        }

        const actualUser = context.getters.profileRemote
          ? context.getters.profileRemote
          : context.getters.profile
        const messageObject = {
          message: paramsMessageTicket.message,
          method: paramsMessageTicket.method,
          type: paramsMessageTicket.type,
          status: paramsMessageTicket.status,
          ticketId: paramsMessageTicket.idTicket,
          apiReceivedAt: new Date(),
          idMessage: response.data.apiResult.sid,
          apiMessageId: response.data.apiResult.sid,
          user: {
            ...actualUser,
          },
        }
        ticket = {
          _id: paramsMessageTicket.idTicket,
          ticket: paramsMessageTicket.ticketNumber,
          abstract: messageObject,
          channel: response.data.ticketOwner.ticket.channel,
          channelCompanyId: response.data.ticketOwner.ticket.channelCompanyId,
          messages: [messageObject],
          client: {
            clientId: paramsMessageTicket.clientId,
            names: paramsMessageTicket.names || paramsMessage.phoneTo,
          },
          company: {
            companyId: context.getters.profile.company.companyId,
            companyName: context.getters.profile.company.companyName,
            lineId: paramsMessageTicket.line.lineId,
            lineName: paramsMessageTicket.line.lineName,
          },
          lastMessage_at: new Date(),
          ...defaultValuesTicket,
          settings: paramsMessageTicket.settings,
          forceAddTicket: true, // Forzar al otro cliente a aejecutar la mutacion ADD_ROOM
          status: 'active', // Se setea como un ticket nuevo activo
          pendings: 0, // Setea con mensajes sin leer
          inputEnabled: false, // Setea al input para q no se pueda escribir
        }
        await context.commit('INSERT_TICKET', ticket)
        context.commit('REPLACE_TICKET_MESSAGEID', {
          ticketId: paramsMessageTicket.idTicket,
          apiMessageId: paramsMessageTicket.apiMessageId,
          apiReceivedAt: paramsMessageTicket.apiReceivedAt,
        })

        const userId = context.getters.profile.userId
        this._vm.$socket.emit('server:ticket:new', { userId, ticket }) // Emitir como chat nuevo

        return { idTicket: paramsMessageTicket.idTicket }
      }

      context.commit('ADD_TEXT_MESSAGE_IN_TICKET', paramsMessageTicket)
      context.commit('REPLACE_TEMP_MESSAGEID', {
        ticketId: paramsMessageTicket.idTicket,
        tempMessageId: paramsMessageTicket.idTicket,
        apiMessageId: paramsMessageTicket.apiMessageId,
        apiReceivedAt: paramsMessageTicket.apiReceivedAt,
      })
      // Para un ticket nuevo
      if (!paramsMessage.newMessage) {
        context.commit('ENABLE_CURRENT_INPUT_CHAT', false)
      }

      // Emitir al visor del admin
      const payload = {
        userId: context.getters.profile.userId,
        ...paramsMessage,
        ...paramsMessageTicket,
        ticketId: response.data.ticketOwner.idTicket,
        type: 'template',
        method: 'sent',
        apiMessageId: paramsMessageTicket.apiMessageId,
        apiReceivedAt: paramsMessageTicket.apiReceivedAt,
      }
      delete payload.idTicket
      this._vm.$socket.emit('server:chat:push', payload)

      return { success: true, idTicket: paramsMessageTicket.idTicket }
    } catch (error) {
      console.error('[chatModule][sendTemplate]', error)
      if (error.message === 'Request failed with status code 403')
        return { success: false, result: 'denied' }
      else return { success: false, result: 'error' }
    }
  },
  /**
   * Evalúa y Setea burbuja de chat automatico
   * @param {vuex}    context
   * @param {Object}  args
   * @param {Object}  args.ticket Ticket a evaluar
   * @param {Object}  args.line Cola a verificar si tiene el mensaje automático activo
   */
  async evaluateAutoWelcome(context, { ticket, line }) {
    try {
      const autoWelcome = line.settings.automatic.welcome
      if (!autoWelcome.active) return
      let paramsMessage = {
        message: { text: autoWelcome.message, auto: true },
        idTicket: ticket._id,
        apiMessageId: `TEMP${Math.floor(
          Math.random() * (9999999 - 1000000) + 1
        )}`,
        apiReceivedAt: new Date(),
        method: 'sent',
        status: 'delivered', // Se asume como entregado puesto que se manda este mensaje a penas se recibe uno del cliente
      }
      context.commit('ADD_TEXT_MESSAGE_IN_TICKET', paramsMessage)
    } catch (error) {
      console.error('[chatModule][setAutoMessage] error -', error)
    }
  },
}

export default actions
