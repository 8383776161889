var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.oldTicketSelected)?_c('a-modal',{attrs:{"visible":_vm.visible,"bodyStyle":{ ..._vm.styles, height: _vm.heightBody },"footer":null,"wrapClassName":"previewModal","width":_vm.widthModal,"centered":""},on:{"ok":_vm.handleOk,"cancel":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"title"},[_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('span',{staticClass:"mrg-right-8"},[_vm._v(" "+_vm._s(`Ticket ${_vm.ticketNumberFormatted(_vm.oldTicketSelected.ticket)}`))]),_c('span',{staticClass:"mrg-right-8"},[(_vm.oldTicketSelected.settings.starred)?_c('a-rate',{attrs:{"default-value":1,"disabled":"","count":1}}):_vm._e()],1),(_vm.ticketOrigin)?_c('a-tooltip',{attrs:{"placement":"right","title":_vm.ticketOrigin.title}},[_c('a-tag',{attrs:{"color":_vm.ticketOrigin.color}},[_vm._v(_vm._s(_vm.ticketOrigin.origin))])],1):_vm._e()],1)],1),_c('a-row',{staticStyle:{"height":"100%"},attrs:{"justify-content":"space-between"}},[_c('a-col',{style:(!_vm.active_breakpoint.is_mobile ? 'height: 100%' : 'height: auto'),attrs:{"xs":{ span: 24 },"sm":{ span: 24 },"md":{ span: 24 },"lg":{ span: 8 },"xl":{ span: 8 }}},[(_vm.active_breakpoint.is_mobile)?_c('a-collapse',{attrs:{"bordered":false}},[_c('a-collapse-panel',{key:"1",staticStyle:{"padding":"0px"},attrs:{"header":"Detalles del ticket"}},[_c('div',{staticClass:"modal__panel__content"},[_c('SectionTicketDetails',{attrs:{"customFields":_vm.oldTicketSelected.settings.customFields
                  ? _vm.oldTicketSelected.settings.customFields
                  : [],"tags":_vm.oldTicketSelected.settings.tags,"integrations":_vm.oldTicketSelected.integrations
                  ? _vm.oldTicketSelected.integrations
                  : [],"type":"history-manage"}})],1)])],1):_c('SectionTicketDetails',{attrs:{"customFields":_vm.oldTicketSelected.settings.customFields
            ? _vm.oldTicketSelected.settings.customFields
            : [],"tags":_vm.oldTicketSelected.settings.tags,"integrations":_vm.oldTicketSelected.integrations ? _vm.oldTicketSelected.integrations : [],"type":"history-manage"}})],1),(!_vm.active_breakpoint.is_mobile)?_c('a-col',{staticStyle:{"height":"100%"},attrs:{"span":1}},[_c('a-divider',{staticStyle:{"height":"100%","margin":"0px"},attrs:{"type":"vertical"}})],1):_vm._e(),_c('a-col',{style:(!_vm.active_breakpoint.is_mobile
          ? 'height: 100%'
          : 'height: calc(100% - 52px)'),attrs:{"xs":{ span: 24 },"sm":{ span: 24 },"md":{ span: 24 },"lg":{ span: 15 },"xl":{ span: 15 }}},[(_vm.ticket)?_c('SectionBoard',{staticClass:"modal__section-board",attrs:{"data":_vm.oldTicketSelected,"data-client":_vm.ticket.client,"type":"history-manage"}}):_vm._e()],1)],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }