<template>
  <a-row class="banner display-flex align--center">
    <div class="banner__picture">
      <img
        src="@/app/shared/assets/support/support-2.png"
        width="360"
        height="318"
      />
    </div>
    <div class="banner__content">
      <h5 class="semibold-24 text--left">Ticker te acompaña en cada paso</h5>
      <p class="regular-16 text--left">
        Nuestros expertos en Ticker están trabajando desde casa, listos para
        ayudarte a mantener tu negocio conectado. Comunícate con nosotros por
        WhatsApp al
        <a
          :href="`http://wa.me/${formattedAppNumber.replace('+', '')}`"
          target="_blank"
          rel="noopener noreferrer"
          >{{ formattedAppNumber }}</a
        >
      </p>
      <a class="display-flex banner__link" @click="handleGoToSupport"
        >Comunicarme con soporte
        <a-icon type="arrow-right" class="banner__icon"
      /></a>
      <a
        class="display-flex banner__link"
        href="https://www.securitec.pe/docs/ticker/"
        target="_blank"
        rel="noopener noreferrer"
        >Lee nuestra documentación
        <a-icon type="arrow-right" class="banner__icon"
      /></a>
    </div>
  </a-row>
</template>

<script>
export default {
  name: 'BannerSupport',
  computed: {
    /**
     * @return {String}
     */
    formattedAppNumber() {
      if (!process.env.VUE_APP_PHONE_NUMBER) return ''
      return process.env.VUE_APP_PHONE_NUMBER.replace(/\s+/g, '')
    },
  },
  methods: {
    handleGoToSupport() {
      window.open(`${window.location.origin}/support/`, '_blank', 'noopener')
    },
  },
}
</script>

<style lang="sass" scoped>
.banner
  background-color: $gray_2
  padding: 4em 10em
.banner__content
  text-align: left
.banner__picture
  margin-right: 4em
.banner__link
  margin-bottom: 12px
.banner__icon
  font-size: 24px
  margin-left: 12px
</style>
