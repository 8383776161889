<template>
  <div class="tree">
    <message-node
      v-if="dataSource.drawer.question.type === 'message'"
      @onDelete="
        handleShowConfirmToDeleteMessage(
          dataSource._id,
          dataSource.parentNodeId
        )
      "
      @onSelect="handleOpenDrawer(dataSource)"
      class="tree__width"
      :class="{ tree__connection: dataSource.parentNodeId }"
      :description="dataSource.drawer.question.content"
      :action="
        formattedAction(
          dataSource.drawer.action.type,
          dataSource.drawer.action.nodeType,
          dataSource.drawer.action.wait
        )
      "
      :selected="selectedNodeId === dataSource._id ? true : false"
      :showInfo="dataSource.drawer.action.type === 'none' ? true : false"
      :disabled="disabledActions"
    />
    <template v-if="dataSource.drawer.question.type === 'options'">
      <options-node
        @onDelete="
          handleShowConfirmToDeleteOptions(
            dataSource._id,
            dataSource.parentNodeId
          )
        "
        @onAddOption="handleAddOption(dataSource._id)"
        @onSelect="handleOpenDrawer(dataSource)"
        class="tree__width"
        :class="{ tree__connection: dataSource.parentNodeId }"
        :description="dataSource.drawer.question.content"
        :selected="selectedNodeId === dataSource._id ? true : false"
        :disabled="disabledActions"
      />
      <div class="tree__options">
        <div
          v-for="(option, index) in options"
          :key="index"
          :class="{
            'tree__option__connection-brothers': options[index + 1],
            tree__option__first: index === 0 ? true : false,
          }"
        >
          <option-node
            @onDelete="
              handleShowConfirmToDeleteOption(
                option.parentNodeId,
                option.idOption
              )
            "
            @onSelect="handleOpenDrawer(option)"
            @onChangeOrder="handleChangeOrderOption"
            :description="option.text"
            :order="option.order"
            :quantity-orders="options.length"
            :data-source="option"
            class="tree__width tree__option"
            :class="{ tree__option__connection: option.parentNodeId }"
            :selected="selectedNodeId === option.idOption ? true : false"
            :show-delete="option.order === 1 ? false : true"
            :action="
              formattedAction(
                option.action.type,
                option.action.nodeType,
                option.action.wait
              )
            "
            :isAllowedChange="isSavedNode"
            :disabled="disabledActions"
          />
          <div class="tree__child" v-if="option.child">
            <tree
              :data-source="option.child"
              @onOpenDrawer="handleOpenDrawer"
              :disabled-actions="disabledActions"
            />
          </div>
        </div>
      </div>
    </template>
    <div class="tree__child" v-if="dataSource.child">
      <tree
        :data-source="dataSource.child"
        @onOpenDrawer="handleOpenDrawer"
        :disabled-actions="disabledActions"
      />
    </div>
  </div>
</template>
<script>
import MessageNode from './MessageNode'
import OptionNode from './OptionNode'
import OptionsNode from './OptionsNode'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'Tree',
  components: {
    MessageNode,
    OptionNode,
    OptionsNode,
  },
  props: {
    dataSource: {
      type: Object,
      default: () => ({
        drawer: {
          options: [],
        },
      }),
    },
    disabledActions: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['nodes', 'selectedNodeId', 'isSavedNode']),

    options() {
      return this.dataSource.drawer.options
    },
    chatbotId() {
      return this.$route.params.id
    },
  },
  methods: {
    ...mapActions([
      'deleteNode',
      'deleteOption',
      'addOptionToNode',
      'updateNodeMessage',
      'updateOption',
    ]),

    ...mapMutations(['SET_SELECTED_NODE_ID', 'SET_NODE_SELECTED']),
    /**
     * Elimina un nodo mensaje u opciones
     * @param {String} nodeId - id del nodo
     * @param {String} parentNodeId - id de su padre
     */
    async handleDeleteNode(nodeId, parentNodeId) {
      if (parentNodeId !== undefined) {
        const node = this.nodes.find((node) => node._id === parentNodeId)

        /**Si su padre es un nodo */
        if (node !== undefined) {
          const nodeMessage = {
            message: node.drawer.question.content,
            wait: node.drawer.action.wait ? node.drawer.action.wait : false,
            type_action: 'none',
            nodeId_action: node.drawer.action.nodeId,
            lineId_action: node.drawer.action.lineId
              ? node.drawer.action.lineId
              : null,
            message_action: node.drawer.action.message
              ? node.drawer.action.message
              : null,
            withMessage: node.drawer.action.withMessage,
          }
          await this.updateNodeMessage({
            chatbotId: this.chatbotId,
            nodeId: parentNodeId,
            nodeMessage: nodeMessage,
          })
        } else {
          const nodesOptions = this.nodes.filter((node) => node.drawer.options)
          const nodeOption = nodesOptions.find((node) =>
            node.drawer.options.find(
              (option) => option.idOption === parentNodeId
            )
          )
          const option = nodeOption.drawer.options.find(
            (option) => option.idOption === parentNodeId
          )
          const optionEdited = {
            order: option.order,
            text: option.text,
            type_action: 'none',
            message_action: 'Gracias por tu atención',
          }
          await this.updateOption({
            chatbotId: this.chatbotId,
            nodeId: option.parentNodeId,
            idOption: option.idOption,
            optionEdited,
          })
        }
      }
      await this.deleteNode({ chatbotId: this.chatbotId, nodeId })
    },
    /**
     * Elimina una opcion
     * @param {String} nodeId - id de su nodo padre
     * @param {String} idOption - id de la opcion
     */
    async handleDeleteNodeOption(nodeId, idOption) {
      await this.deleteOption({ chatbotId: this.chatbotId, nodeId, idOption })
    },
    /**
     * Añade una opcion a las opciones
     * @param {String} nodeId - id del nodo
     */
    async handleAddOption(nodeId) {
      if (this.options.length === 20)
        return this.$message.error('Se alcanzó el máximo de opciones 😥')
      const newOption = {
        text: 'Otra opción',
        type_action: 'none',
        message_action: 'Actualmente no atendemos por este medio, gracias.',
      }
      await this.addOptionToNode({
        chatbotId: this.chatbotId,
        nodeId,
        newOption: newOption,
      })
    },
    /**
     * Emite la funcion de abrir el drawer de configuracion
     * @param {Object} node - puede ser el nodo de opciones, mensaje u opcion
     * @param {String} node._id - id del nodo
     * @param {String} node.idOption - id de la opcion
     */
    handleOpenDrawer(node) {
      if (this.isSavedNode) {
        this.$emit('onOpenDrawer', node)
        this.SET_SELECTED_NODE_ID(node._id || node.idOption)
        this.SET_NODE_SELECTED(node)
      } else this.$message.warning('Es necesario guardar los cambios')
    },
    /**
     * Cambia el orden de las opciones
     * @param {Object} option - la opcion a cambiar de posicion
     * @param {Number} newOrder - el orden al cual se quiere cambiar
     */
    async handleChangeOrderOption(option, newOrder) {
      if (!this.isSavedNode)
        this.$message.warning('Es necesario guardar los cambios')
      const node = this.nodes.find((node) => node._id === option.parentNodeId)
      if (node === undefined) return
      /**La opcion que entrara en en el nuevo ordern */
      const incomingOption = {
        order: newOrder,
        text: option.text,
        type_action: option.action.type,
        message_action: option.action.message,
        nodeId_action: option.action.nodeId,
        lineId_action: option.action.lineId,
        nodeType: option.action.nodeType,
      }
      /**La opción que saldra para ser colocado en el antiguo orden de la posicion entrante */
      const outgoingOption = {
        order: option.order,
        text: node.drawer.options[newOrder - 1].text,
        type_action: node.drawer.options[newOrder - 1].action.type,
        message_action: node.drawer.options[newOrder - 1].action.message,
        nodeId_action: node.drawer.options[newOrder - 1].action.nodeId,
        lineId_action: node.drawer.options[newOrder - 1].action.lineId,
        nodeType: node.drawer.options[newOrder - 1].action.nodeType,
      }

      const rptaIncoming = await this.updateOption({
        chatbotId: this.chatbotId,
        nodeId: option.parentNodeId,
        idOption: option.idOption,
        optionEdited: incomingOption,
      })
      await this.updateOption({
        chatbotId: this.chatbotId,
        nodeId: node._id,
        idOption: node.drawer.options[newOrder - 1].idOption,
        optionEdited: outgoingOption,
      })
      this.SET_SELECTED_NODE_ID(rptaIncoming._id || rptaIncoming.idOption)
      this.SET_NODE_SELECTED(rptaIncoming)
    },
    /**
     * Formatea una accion
     * @param {String} action - none, continue, transfer, rollback
     * @param {String} nodeType - message, options
     * @param {Boolean} wait - mensaje con espera o  sin espera
     */
    formattedAction(action, nodeType, wait) {
      if (wait && action !== 'none') return 'Esperar una respuesta'
      switch (action) {
        case 'continue': {
          if (nodeType === 'message') return 'Continuar al siguiente mensaje'
          else return 'Continuar a las opciones'
        }
        case 'none':
          return 'Finalizar chatbot'
        case 'transfer':
          return 'Transferir a cola'
        case 'rollback':
          return 'Regresar al menú anterior'
        default:
          return 'Siguiente'
      }
    },
    /**
     * Modal de confirmacion
     * @param {String} title
     * @param {String} content
     * @param {String} okText
     * @param {String} cancelText
     * @param {Function} onOkey
     * @param {Function} onCancel
     */
    handleShowConfirm(title, content, okText, fnOk) {
      this.$confirm({
        title: title,
        content: content,
        okText: okText,
        cancelText: 'Cancelar',
        onOk() {
          fnOk()
        },
      })
    },
    handleShowConfirmToDeleteMessage(nodeId, parentNodeId) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar este Mensaje?',
        'Recuerda que se eliminarán todas las opciones pertenecientes así como sus  configuraciones.',
        'Eliminar',
        () => this.handleDeleteNode(nodeId, parentNodeId)
      )
    },
    handleShowConfirmToDeleteOptions(nodeId, parentNodeId) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar esta Opción múltiple?',
        'Recuerda que se elimanarán todas las opciones pertenecientes así como sus  configuraciones.',
        'Eliminar',
        () => this.handleDeleteNode(nodeId, parentNodeId)
      )
    },
    handleShowConfirmToDeleteOption(nodeId, idOption) {
      this.handleShowConfirm(
        '¿Estás segur@ de eliminar esta Opción?',
        'Recuerda que se eliminará su configuración y se removerá del listado de opciones múltiples.',
        'Eliminar',
        () => this.handleDeleteNodeOption(nodeId, idOption)
      )
    },
  },
}
</script>
<style lang="sass" scoped>
.tree
  &__options
    margin-left: 20px
  &__child
    margin-left: 20px
  &__width
    width: 468px
  &__connection
    position: relative
    &::before
      content: ""
      display: block
      position: absolute
      width: 8px
      height: calc( 100% - 26px )
      border-bottom-left-radius: 6px
      border-left: 1px solid $blue_3
      border-bottom: 1px solid $blue_3
      margin-left: -20px
      margin-top: -8px
      bottom: 50%
      pointer-events: none
      transform: translateZ(0)
      background: transparent
  &__option
    &__first
      >.tree__option__connection
        &::before
          height: 62%
    &__connection
      position: relative
      &::before
        content: ""
        display: block
        position: absolute
        width: 8px
        height: calc(100% + 12px)
        border-bottom-left-radius: 6px
        border-left: 1px solid $blue_3
        border-bottom: 1px solid $blue_3
        margin-left: -20px
        margin-top: -8px
        bottom: 50%
        pointer-events: none
        transform: translateZ(0)
        background: transparent
      &-brothers
        >.tree__child
          position: relative
          &::before
            content: ""
            display: block
            position: absolute
            width: 8px
            height: calc( 100% + 50px)
            border-left: 1px solid $blue_3
            margin-left: -27px
            margin-top: -50px
            pointer-events: none
            transform: translateZ(0)
            background: transparent
</style>
