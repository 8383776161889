<template>
  <a-menu
    mode="inline"
    :inlineIndent="40"
    :default-selected-keys="[defaultSelected]"
  >
    <template v-for="item in menuItems">
      <a-menu-item
        :key="item.key"
        style="text-align: left"
        @click="handleSelectMenu(item.key)"
      >
        {{ item.title }}
      </a-menu-item>
    </template>
  </a-menu>
</template>

<script>
export default {
  name: 'MenuSettings',
  props: {
    itemSelected: {
      type: String,
    },
  },
  data: () => ({
    menuItems: [
      { order: 1, title: 'General', key: 'settings-general' },
      { order: 2, title: 'Workspace', key: 'settings-workspace' },
      { order: 3, title: 'Alertas SMS', key: 'settings-alerts' },
    ],
  }),
  computed: {
    // menu por default seleccionado
    defaultSelected() {
      if (!this.itemSelected) {
        const menuItem = this.menuItems.find((item) => item.order === 1)
        this.handleSelectMenu(menuItem.key)
        return menuItem.key
      }
      return this.itemSelected
    },
  },
  methods: {
    handleSelectMenu(menuItem) {
      this.$router.push({ name: menuItem })
      this.$store.dispatch('setAnalytics', {
        eventName: menuItem.replace('-', '_'), // Se debe enviar con el formato: 'submenu_settings'f
      })
    },
  },
}
</script>
s
<style lang="sass" scoped></style>
