<template>
  <div class="supervision-template">
    <header class="template__header">
      <h5 class="heading-h5 mrg-bottom-0">SUPERVISIÓN</h5>
      <a-tooltip placement="bottom">
        <template slot="title">
          Monitorea en tiempo real las acciones de tus agentes con tus clientes.
          <a
            href="https://www.securitec.pe/docs/ticker/caracteristicas/supervision/"
            target="_blank"
            rel="noopener noreferrer"
            >Click aquí</a
          >
          si deseas conocer más.
        </template>
        <a-icon type="info-circle" class="template__icon" />
      </a-tooltip>
    </header>
    <div class="first-section" ref="firstSection">
      <a-input-search
        placeholder="Buscar usuario"
        style="width: 312px; margin-right: 12px"
        @input="onInput"
        @search="onSearch"
      />
      <!--filtro por cola-->
      <a-select
        default-value="allLines"
        style="width: 204px; margin-right: 12px"
        @change="handleChange"
      >
        <a-select-option v-for="line in allLines" :key="line._id">
          {{ line.name }}
        </a-select-option>
      </a-select>
      <!-- filtro por estados -->
      <a-select
        v-model="state"
        style="width: 204px; margin-right: 12px"
        @change="handleState"
      >
        <a-select-option v-for="select in nameSelects" :key="select._id">
          {{ select.name }}
        </a-select-option>
      </a-select>
    </div>
    <!-- lista de supervision -->
    <a-row :gutter="12">
      <a-col class="gutter-row" :span="sizeGrid">
        <SectionListSupervision
          :sectionSupervision="sectionSupervision"
          :typeState="typeState"
          @handleOpenDrawer="handleOpenDrawer"
        />
      </a-col>
      <!-- drawer de resumen-->
      <transition name="slide-fade" v-on:after-leave="afterLeave">
        <a-col class="gutter-row" :span="6" v-if="visibleDrawer">
          <SectionDrawer
            :userId="userId"
            @handleCloseDrawer="handleCloseDrawer"
          />
        </a-col>
      </transition>
    </a-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import SectionListSupervision from '@/app/supervision/components/organisms/SectionListSupervision.vue'
import SectionDrawer from '@/app/supervision/components/organisms/SectionDrawer.vue'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'SupervisionTemplate',
  components: {
    SectionListSupervision,
    SectionDrawer,
  },
  data: () => ({
    nameSelects: [
      { name: 'General', _id: '1' },
      { name: 'Conectado', _id: '2' },
      { name: 'Servicios', _id: '3' },
      { name: 'Capacitación', _id: '4' },
      { name: 'Ocupado', _id: '5' },
      { name: 'Desconectado', _id: '6' },
    ],
    visibleDrawer: false,
    userId: null,
    state: '1',
    typeState: 'General',
    sectionSupervision: {
      list: {
        search: null,
        line: null,
        state: null,
        data: [],
        loading: {
          table: false,
        },
      },
    },
    sizeGrid: 24,
  }),
  mixins: [attemptMixin],
  created() {
    this.intervalAttempt(() => {
      if (!this.company) throw 'No company'
      this.getAssignedLines()
    })
  },
  computed: {
    ...mapGetters(['lines']),
    allLines() {
      let arrLines = []
      arrLines = this.lines.slice()
      const allLine = {
        _id: 'allLines',
        name: 'Todas las colas',
        default: true,
      }
      arrLines.splice(0, 0, allLine)
      return arrLines
    },
  },
  methods: {
    ...mapActions(['listSupervision', 'getAssignedLines']),
    /** Cuando termina de escriir en el buscador */
    onInput(e) {
      let value = e.target.value
      this.endWaitTyping(() => {
        this.onSearch(value)
      }, 500)
    },
    /** Cuando se presiona enter en el buscador */
    onSearch(value) {
      this.sectionSupervision.list.search = value
      this.listSupervision({ self: this })
    },
    handleState(value) {
      const selectFiltered = this.nameSelects.filter(
        (select) => select._id === value
      )
      this.typeState = selectFiltered[0].name
    },
    handleOpenDrawer(value) {
      this.userId = value
      this.visibleDrawer = true
      this.sizeGrid = 18
    },
    handleCloseDrawer() {
      this.visibleDrawer = false
    },
    handleChange(value) {
      this.sectionSupervision.list.line = value
      this.listSupervision({ self: this })
    },
    afterLeave() {
      this.sizeGrid = 24
    },
  },
}
</script>

<style lang="sass" scoped>
.slide-fade-enter, .slide-fade-leave-to
  transform: translateX(10px)
  opacity: 0
.slide-fade-enter-active
  transition: all .8s ease
.slide-fade-leave-active
  transition: all .3s linear

.supervision-template
  padding: 20px $padding-heigth-inner $padding-heigth-inner
  h5
    text-align: left
    color: $gray_dark_900
    margin-bottom: 12px
  .first-section
    margin-bottom: 12px
    display: flex
    flex-direction: row
    width: 100%
.template
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
</style>
