<template>
  <div class="text--left">
    <slot name="title">
      <h4 class="semibold-20 section__title">
        Instalación del código de Webchat
      </h4>
    </slot>
    <p class="regular-14 mrg-bottom-8">
      {{ description }}
    </p>
    <p class="regular-14 mrg-bottom-20">
      Para instalar el webchat a landings internas, se debe agregar el código de
      instalación en cada página. Más info
      <a
        href="https://www.securitec.pe/docs/ticker/webchat/integrar-webchat/"
        target="_blank"
        rel="noopener noreferrer"
        >aquí.</a
      >
    </p>
    <a-textarea
      placeholder="Escribe aquí..."
      :auto-size="{ minRows: 5, maxRows: 5 }"
      v-model="scriptIntegration"
      readOnly
    />
    <div
      class="display-flex align--end justify--between mrg-top-12 mrg-bottom-40"
    >
      <p class="regular-14 mrg-bottom-0">
        ¿Ayuda? Sigue los pasos
        <a
          href="https://www.securitec.pe/docs/ticker/webchat/configurar-webchat/"
          target="_blank"
          rel="noopener noreferrer"
          >aquí</a
        >
      </p>
      <a-button
        type="primary"
        v-clipboard:copy="scriptIntegration"
        v-clipboard:success="onCopyScript"
        v-clipboard:error="onError"
        >Copiar código</a-button
      >
    </div>
    <h5 class="semibold-14">Envía el código de instalación</h5>
    <p class="regular-14">
      Enviaselo a tu desarrollador o al administrador de tu web
    </p>
    <a-form :form="form">
      <a-form-item>
        <div class="display-flex">
          <a-input
            v-model="email"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellena el campo.',
                  },
                  {
                    type: 'email',
                    message: 'Ingresa un correo válido.',
                  },
                  {
                    whitespace: true,
                    message: 'No se aceptan espacios en blanco.',
                  },
                  {
                    min: 6,
                    message: '6 caracteres como mínimo.',
                  },
                  {
                    max: 50,
                    message: '50 caracteres como máximo.',
                  },
                ],
              },
            ]"
            placeholder="Escribe aquí un correo electrónico"
            :disabled="disabled"
          />
          <a-button
            type="primary"
            ghost
            class="mrg-left-12"
            @click="handleSendScript"
            :disabled="disabled"
            :loading="loading"
            >Enviar</a-button
          >
        </div>
      </a-form-item>
    </a-form>
    <p v-if="visibleMessage" class="regular-14 mrg-top-12 form__message">
      <i>Podrás volver a intentar en {{ timerSeconds }} segundos</i>
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'WebchatInstallation',
  data: () => ({
    email: '',
    scriptIntegration: null,
    description:
      'Copia el código generado e insértalo en tu sitio web, justo antes del final de la etiqueta </body>.',
    disabled: false,
    loading: false,
    timerSeconds: 0,
    timer: null,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-send-script' })
  },
  destroyed() {
    /** Detiene el contandor si la vista es destruida*/
    this.stopTimer(this.timer)
  },
  computed: {
    /**
     * Visibilidad del mensaje de timer
     * @return {Boolean}
     */
    visibleMessage() {
      if (this.timerSeconds === 0 || this.timerSeconds === 30) return false
      else return true
    },
  },
  methods: {
    ...mapActions(['sendScriptWebchat']),
    /**Enviar script */
    async handleSendScript() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.disabled = true
          this.loading = true
          const response = await this.sendScriptWebchat(values.email)
          this.loading = false
          if (response.success) this.$message.success(response.details)
          else this.$message.error(response.details)
          this.startTimer()
        }
      })
    },
    /**Setea valores, comunmente usado desde el padre del componente */
    handleSetValues(newVals) {
      if (!newVals) return
      this.scriptIntegration = newVals.script
    },
    /**Script copiado correctamente */
    onCopyScript() {
      this.$message.success('Script copiado')
    },
    /**Evento de error para el copiado */
    onError() {
      this.$message.error('No se pudo copiar 😥')
    },
    /**Resetea valores, comunmente usado desde el padre del componente */
    handleResetValues() {
      this.email = ''
      this.scriptIntegration = null
    },
    /**
     * Comienza el timer desde 30 seg hacia 0seg cada 1seg
     */
    startTimer() {
      this.timerSeconds = 30
      this.timer = setInterval(() => {
        if (this.timerSeconds === 0) return this.stopTimer(this.timer)
        this.timerSeconds = this.timerSeconds - 1
      }, 1000)
    },
    /**
     * Detiene el intervalo y deshabilita el boton
     * @param {Function} timer - intervalo a limpiar
     */
    stopTimer(timer) {
      clearInterval(timer)
      this.timerSeconds = 30
      this.disabled = false
    },
  },
}
</script>

<style lang="sass" scoped>
.mrg-bottom-40
  margin-bottom: 40px
.mrg-bottom-20
  margin-bottom: 20px
.section__title
  margin-bottom: 12px
</style>
