<template>
  <div class="section-form-content">
    <h6 class="heading-h6 section__title">{{ title }}</h6>
    <a-form
      :form="form"
      layout="vertical"
      class="form"
      @submit.prevent="handleSubmit"
    >
      <custom-section-scroll
        :masterStyles="{ height: `calc(100vh - 14.5em)` }"
        :contentStyles="{ padding: '0px 24px' }"
      >
        <p class="body-2 form__description mrg-bottom-8">{{ description }}</p>
        <a-alert v-if="error.show" type="error" show-icon class="form__alert">
          <p slot="message" class="mrg-bottom-0 text--left">
            {{ error.message }}. Si necesitas ayuda,
            <router-link
              :to="{ name: 'support' }"
              target="_blank"
              rel="noopener noreferrer"
              >contáctate con nuestro soporte</router-link
            >.
          </p>
        </a-alert>
        <a-form-item label="Nombres">
          <a-input
            placeholder="Escribe aquí..."
            v-decorator="[
              'names',
              {
                rules: [
                  { required: true, message: 'Por favor rellene el campo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            :disabled="type === 'edit'"
          />
        </a-form-item>
        <a-form-item label="Apellidos">
          <a-input
            placeholder="Escribe aquí..."
            v-decorator="[
              'surnames',
              {
                rules: [
                  { required: true, message: 'Por favor rellene el campo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            :disabled="type === 'edit'"
          />
        </a-form-item>
        <a-form-item label="Número de tarjeta" :has-feedback="type !== 'edit'">
          <a-input
            placeholder="Escribe aquí..."
            v-decorator="[
              'number',
              {
                rules: [
                  {
                    required: type === 'add' ? true : false,
                    message: 'Por favor rellene el campo',
                  },
                  {
                    whitespace: type === 'add' ? true : false,
                    message: 'No se admiten espacios en blanco.',
                  },
                  { validator: type !== 'edit' ? checkTypeCard : null },
                ],
              },
            ]"
            :disabled="type === 'edit'"
          />
        </a-form-item>
        <a-row :gutter="[12]">
          <a-col :span="12">
            <a-form-item label="Fecha de vencimiento">
              <a-month-picker
                :disabled-date="disabledDate"
                placeholder="Selecciona la fecha"
                v-decorator="[
                  'expirationDate',
                  {
                    rules: [
                      { required: true, message: 'Por favor rellene el campo' },
                    ],
                  },
                ]"
                style="width: 100%"
                :disabled="type === 'edit'"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Código de seguridad (CVV)">
              <a-input-password
                placeholder="Escribe aquí..."
                v-decorator="[
                  'securityCode',
                  {
                    rules: [
                      {
                        required: type === 'add' ? true : false,
                        message: 'Por favor rellene el campo',
                      },
                      {
                        whitespace: true,
                        message: 'No se admiten espacios en blanco.',
                      },
                    ],
                  },
                ]"
                :disabled="type === 'edit'"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="true">
          <a-col
            :span="24"
            style="display: flex; align-items: center; margin-bottom: 4px"
          >
            <a-switch
              size="small"
              v-model="forSuscription"
              :disabled="disabledSwitch"
            />
            <h6 class="heading-h6" style="margin-left: 8px; margin-bottom: 0px">
              Guardar como tarjeta predeterminada para suscripción
            </h6>
          </a-col>
          <a-col :span="24">
            <p class="body-2">
              Tomaremos esta tarjeta para debitar los pagos de tus planes
              mensuales.
            </p>
          </a-col>
        </a-row>
        <div
          v-if="type === 'add'"
          class="form__extra-container display-flex align--center"
        >
          <a-icon type="info-circle" class="form__icon-info mrg-right-8" />
          <p class="mrg-bottom-0 regular-12">
            Para validar tu tarjeta, Ticker realizará un cobro de $3 que luego
            será reembolsado.
            <span v-if="false"> Más información <a href="">aquí</a></span>
          </p>
        </div>
      </custom-section-scroll>

      <div class="content-footer justify-content--end">
        <template v-if="type === 'edit'">
          <a-button
            @click="handleDelete(values.idCard)"
            style="margin-right: 12px"
            icon="delete"
            type="danger"
            :loading="btnsEdit.cancel.loading"
            :disabled="disabledSwitch"
            ghost
            >Eliminar tarjeta</a-button
          >
          <a-button
            html-type="submit"
            type="primary"
            :loading="btnsEdit.loading"
            :disabled="disabledBtnSaveSetting"
            >Guardar configuración</a-button
          >
        </template>
        <template v-else>
          <a-button @click="handleCancel" style="margin-right: 12px"
            >Limpiar campos</a-button
          >
          <a-button
            html-type="submit"
            type="primary"
            :loading="btnsAdds.ok.loading"
            >Agregar nueva tarjeta</a-button
          >
        </template>
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import moment from 'moment'

export default {
  name: 'SectionFormCard',
  props: {
    type: {
      type: String,
      default: 'add', // add, edit
      require: false,
    },
    title: {
      type: String,
      default: 'Crear nueva tarjeta',
      require: false,
    },
    description: {
      type: String,
      default: 'Completa todos los campos',
      require: false,
    },
    hasSubscription: {
      type: Boolean,
      default: false,
      require: false,
    },
    values: {
      type: Object,
      default: () => {},
      require: false,
    },
  },
  components: {
    CustomSectionScroll,
  },
  data: () => ({
    checkPending: null,
    paymentMethod: '', // metodo de pago
    error: {
      show: false,
      message: '',
    },
    btnsAdds: {
      ok: {
        loading: false,
        disabled: true,
      },
      cancel: {
        loading: false,
        disabled: true,
      },
    },
    btnsEdit: {
      ok: {
        loading: false,
        disabled: true,
      },
      cancel: {
        loading: false,
        disabled: true,
      },
    },
    empty: false,
    forSuscription: false,
    disabledSwitch: false, // disabled switch
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-settings-credit' })
  },
  mounted() {
    this.handleSetForSuscription()
  },
  watch: {
    values() {
      this.empty = false
      this.resetValues()
      if (this.type === 'edit') this.handleSetValues()
      else {
        this.handleSetForSuscription()
      }
    },
  },
  computed: {
    ...mapGetters(['profile']),

    /**
     * Deshabilita el boton si no ha cambiado el valor de switch
     */
    disabledBtnSaveSetting() {
      const oldForSuscription = this.values.forSuscription
        ? this.values.forSuscription
        : false
      if (this.forSuscription !== oldForSuscription) return false
      else return true
    },
  },
  methods: {
    ...mapActions([
      'registerCard',
      'updateCard',
      'deleteCard',
      'updateMethodSuscription',
    ]),

    ...mapMutations([
      'ADD_CARD_PROFILE',
      'REMOVE_CARD_PROFILE',
      'UPDATE_CARD_PROFILE',
      'UPDATE_SUSCRIPTION_CARD',
    ]),
    /**
     * Comprueba si hay nuevos valores en el formulario
     * @param {Object} values // objeto de valores de todo el formulario
     */
    hasNewValues(values) {
      const fields = Object.keys(values).some((field) => {
        return values[field] !== this.values[field]
      })
      return !fields
    },
    async handleEdit(idCard) {
      await this.updateCard({
        name: 'Ricarda Rosa',
        surnames: 'de la crosa',
        paymentMethod: 'mastercard',
        idCard: idCard,
      })
    },

    async handleDelete(idCard) {
      this.btnsEdit.cancel.loading = true
      const response = await this.deleteCard(idCard)
      this.btnsEdit.cancel.loading = false
      if (response.success) {
        this.REMOVE_CARD_PROFILE(idCard)
        this.empty = true
        this.type = 'add'
        this.$message.success('Se elimino exitosamente')
        this.resetValues()
      } else this.$message.error('No pudimos eliminar tu tarjeta 😓')
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day').subtract(0, 'month')
    },
    checkTypeCard(rule, value, callback) {
      if (!value) callback()
      clearTimeout(this.checkPending)
      this.checkPending = setTimeout(() => {
        /*eslint no-control-regex: "error"*/
        const regexDiners =
          /(^[35](?:0[0-5]|[68][0-9])[0-9]{11}$)|(^30[0-5]{11}$)|(^3095(\\d{10})$)|(^36{12}$)|(^3[89](\\d{12})$)/gm
        const regexVisa = /^(4)(\d{12}|\d{15})$|^(606374\d{10}$)/gm
        const regexMastercard =
          /^(5[1-5]\d{14}$)|^(2(?:2(?:2[1-9]|[3-9]\d)[3-6]\d\d|7(?:[01]\d|20))\d{12}$)/gm
        if (regexDiners.test(value)) {
          this.paymentMethod = 'diners'
          callback()
        } else if (regexVisa.test(value)) {
          this.paymentMethod = 'visa'
          callback()
        } else if (regexMastercard.test(value)) {
          this.paymentMethod = 'mastercard'
          callback()
        } else {
          callback(new Error('No es una tarjeta valida'))
        }
      }, 500)
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (this.type === 'add') {
            this.btnsAdds.ok.loading = true
            const response = await this.registerCard({
              name: values.names,
              surnames: values.surnames,
              expirationDate: values.expirationDate,
              paymentMethod: this.paymentMethod,
              number: values.number,
              securityCode: values.securityCode,
            })
            this.btnsAdds.ok.loading = false
            if (response.success) {
              let card = response.result
              card.isNew = false
              if (this.forSuscription) {
                const responseUpdate = await this.updateMethodSuscription(card)
                if (responseUpdate.success) {
                  response.result.forSuscription = true
                  this.disabledSwitch = true
                  // Si hay una tarjeta que ya tenia la suscripcion
                  const cardSuscription = this.profile.cards.find(
                    (card) => card.forSuscription
                  )
                  if (cardSuscription !== undefined) {
                    this.UPDATE_SUSCRIPTION_CARD({
                      idCard: cardSuscription.idCard,
                      forSuscription: false,
                    })
                  }
                }
              }
              this.ADD_CARD_PROFILE(response.result)
              this.$message.success('Se agregó una tarjeta exitosamente')
              this.resetValues()
            } else {
              this.$message.error('No pudimos agregar tu tarjeta 😓')
              this.error = {
                show: true,
                message: response.result
                  ? `No pudimos registrar tu tarjeta 😓. ${response.result.rejectionDetail}, por favor revise los datos ingresados o intente con una nueva tarjeta`
                  : 'No pudimos registrar tu tarjeta 😓, por favor revise los datos ingresados o intente con una nueva tarjeta',
              }
            }
          } else {
            this.btnsEdit.ok.loading = true
            const response = await this.updateCard({
              name: values.names,
              surnames: values.surnames,
              expirationDate: values.expirationDate,
              paymentMethod: this.paymentMethod,
              number: values.number,
              idCard: this.values.idCard,
            })
            this.btnsEdit.ok.loading = false
            if (response.success) {
              let card = response.result
              card.isNew = false
              if (
                this.forSuscription !== this.values.forSuscription &&
                this.forSuscription
              ) {
                // Si se cambio el valor que tenia y se volvio como verdadero
                const responseUpdate = await this.updateMethodSuscription(card)
                if (responseUpdate.success) {
                  response.result.forSuscription = true
                  this.disabledSwitch = true
                  // Si hay una tarjeta que ya tenia la suscripcion
                  const cardSuscription = this.profile.cards.find(
                    (card) => card.forSuscription
                  )
                  if (cardSuscription !== undefined) {
                    this.UPDATE_SUSCRIPTION_CARD({
                      idCard: cardSuscription.idCard,
                      forSuscription: false,
                    })
                  }
                }
              }
              this.UPDATE_CARD_PROFILE({
                idCard: response.result.idCard,
                newCard: response.result,
              })
              this.$message.success('Se actualizó su tarjeta exitosamente')
            } else {
              this.$message.error('No pudimos actualizar tu tarjeta 😓')
            }
          }
        }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.forSuscription = false
    },
    handleSetValues() {
      this.form.setFieldsValue({
        names: this.values.name,
        surnames: this.values.surnames,
        expirationDate: this.values.expirationDate,
        number: `**** **** **** **${this.values.lastDigits.slice(-2)}`,
        securityCode: '***',
      })
      this.paymentMethod = this.values.type
      this.forSuscription = this.values.forSuscription
        ? this.values.forSuscription
        : false
      // }
      if (this.values.forSuscription) this.disabledSwitch = true
      else this.disabledSwitch = false
    },
    resetValues() {
      this.form.resetFields()
      this.error = {
        show: false,
        message: '',
      }
    },
    handleSetForSuscription() {
      const hasSuscription = this.profile.cards.find(
        (card) => card.forSuscription
      )
      if (hasSuscription !== undefined) this.forSuscription = false
      else if (this.profile.cards.length < 1) {
        this.forSuscription = true
      } else this.forSuscription = false

      if (this.values.forSuscription) this.disabledSwitch = true
    },
  },
}
</script>

<style lang="sass" scoped>
.section-form-content
  height: 100%
  border: 1px solid $gray_6
  border-radius: 8px
  text-align: left
  // padding: 24px
  position: relative
  .content-footer
    width: 100%
    display: flex
    bottom: 24px
    right: 24px
    padding: 1rem
  .empty
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    height: 100%
    .title
      margin-top: 12px
.section__title
  margin: 16px auto 12px 24px
.form__extra-container
  margin-top: 16px
.form__alert
  margin-bottom: 20px
  width: 100%
</style>
