const modals = {
  LOW_SMS_QUANTITY: {
    visible: true,
    title: 'Te quedan 20 alertas SMS disponibles',
    icon: {
      type: 'evericon', // Puede ser anticon, socialicon, evericon
      path_or_name: 'sad-face.svg',
    },
    routeNameButtonOk: 'menu-market', // Nombre de la ruta (vuerouter) hacia donde redirigira el botón principal del modal
    subtitle: 'Recarga para continuar notificándote por SMS',
    nameButton: 'Recargar SMS',
    description: `Recuerda que, si te quedas sin saldo SMS, Ticker dejará de notificarte cuando haya un cliente en espera o mensajes nuevos, por lo que te aconsejamos que recargues saldo para seguir notificándote cada que un cliente necesita de ti.
      Puedes recargar alertas SMS desde nuestro Market 🛒`,
  },
  WITHOUT_BALANCE_WHATSAPP: {
    visible: true,
    title: '¡Te quedaste sin saldo! 🆘',
    icon: {
      type: 'socialicon',
      path_or_name: 'whatsapp',
    },
    routeNameButtonOk: 'menu-market',
    subtitle: 'Recarga para continuar chateando por WhatsApp',
    nameButton: 'Recargar saldo',
    description:
      'Recuerda que mientras tu empresa no cuente con saldo, tus usuarios no podrán enviar o recibir mensajes de WhatsApp, por lo que te aconsejamos que recargues lo antes posible para que no pierdas comunicación con tus clientes por este canal',
  },
  CREDIT_CARD_PAYMENT_ERROR: {
    visible: true,
    title: '¡No podemos procesar tu pago!',
    icon: {
      type: 'evericon',
      path_or_name: 'sad-face.svg',
    },
    subtitle: 'Actualiza tus métodos de pago',
    nameButton: 'Ver métodos de pago',
    routeNameButtonOk: 'menu-market',
    description:
      'Actualmente no podemos procesar ningún pago de tu cuenta. Esto se debe a problemas con tu actual tarjeta de crédito o débito. Pero no te preocupes, puedes solucionar este inconveniente ingresando a tus métodos de pago 💳',
  },
  LOW_WHATSAPP_BALANCE: {
    visible: true,
    title: '¡Te queda $5 de saldo disponible! 💰',
    icon: {
      type: 'socialicon',
      path_or_name: 'whatsapp',
    },
    subtitle: 'Recarga para continuar chateando por WhatsApp',
    nameButton: 'Recargar saldo',
    routeNameButtonOk: 'menu-market',
    description:
      'Recuerda que mientras tu empresa no cuenta son saldo, tus usuarios no podrán enviar o recibir mensajes de WhatsApp, por lo que te aconsejamos que recargues lo antes posible para que no pierdas comunicación con tus clientes por este canal.',
  },
}

export default modals
