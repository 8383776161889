var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner_header"},[_c('p',{staticClass:"heading-h8 mrg-bottom-0 header_title"},[_vm._v(" Información de número conectado ")]),_c('span',[(_vm.channel.api_version !== 'twilio_v3')?[_c('a',{on:{"click":function($event){return _vm.$emit('onOpenDetails')}}},[_c('a-icon',{attrs:{"type":"edit"}}),_vm._v(" Ver más detalles y solicitar editar datos")],1),_c('a-divider',{staticClass:"mrg-right-8 mrg-left-8",attrs:{"type":"vertical"}})]:[_c('a',{on:{"click":function($event){return _vm.$emit('onOpenDetails')}}},[_c('a-icon',{staticClass:"mrg-right-8",attrs:{"type":"eye"}}),_vm._v(" Ver más detalles")],1),_c('a-divider',{staticClass:"mrg-right-8 mrg-left-8",attrs:{"type":"vertical"}})],[(!_vm.hasDown)?_c('a',{staticClass:"red_6",on:{"click":function($event){return _vm.$emit('onOpenUnsubscribe')}}},[_c('a-icon',{staticClass:"mrg-right-8",attrs:{"type":"delete"}}),_c('span',[_vm._v("Eliminar WhatsApp")])],1):_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" Ya hay una solicitud pendiente. Nos contactaremos contigo. ")]),_c('a',{staticClass:"red_6"},[_c('a-icon',{staticClass:"mrg-right-8",attrs:{"type":"delete"}}),_c('span',[_vm._v("Eliminar WhatsApp")])],1)],2)]],2)]),_c('div',{staticClass:"banner_content"},[_c('div',{staticClass:"col"},[_c('a-badge',{attrs:{"dot":"","status":_vm.status,"numberStyle":{
          height: '12px',
          width: '12px',
          right: '6px',
          top: '6px',
        }}},[_c('a-avatar',{style:({ border: `1px solid ${_vm.gray_5}` }),attrs:{"size":52,"src":_vm.imgSupplier(_vm.channel.supplier, _vm.channel.request)}})],1),_c('span',{staticClass:"text"},[(_vm.channel.credentials)?_c('p',{staticClass:"heading-h7 mrg-bottom-0"},[_vm._v(" "+_vm._s(_vm.channel.credentials.wspbusiness)+" ")]):_vm._e(),_c('a-row',{staticClass:"mrg-top-8",attrs:{"type":"flex"}},[_c('p',{staticClass:"body-1 mrg-bottom-0 description",class:{
              info_i2: !_vm.modalUpdateAlias.alias,
            }},[_vm._v(" Alias: "+_vm._s(_vm.modalUpdateAlias.alias || 'Sin alias')+" ")]),_c('a',{staticClass:"mrg-left-12 namm",on:{"click":function($event){_vm.modalUpdateAlias.visible = true}}},[_c('a-icon',{attrs:{"type":"edit"}})],1),_c('modal-update-alias',{attrs:{"visible":_vm.modalUpdateAlias.visible,"channelCompanyId":_vm.channel._id,"defaultAlias":_vm.channel.alias || null},on:{"onCancel":function($event){_vm.modalUpdateAlias.visible = false},"onUpdate":(alias) => (_vm.modalUpdateAlias.alias = alias)}})],1)],1)],1),_c('div',{staticClass:"items"},[_c('div',{staticClass:"item"},[_c('p',{staticClass:"heading-h8 mrg-bottom-0 item_title"},[_vm._v("Proveedor")]),_c('p',{staticClass:"body-1 mrg-bottom-0"},[_vm._v(_vm._s(_vm.channel.supplier))])]),(_vm.channel.form.company && _vm.channel.form.company.country)?[_c('a-divider',{staticClass:"item_divider",attrs:{"type":"vertical"}}),_c('div',{staticClass:"item"},[_c('p',{staticClass:"heading-h8 mrg-bottom-0 item_title"},[_vm._v("País")]),_c('p',{staticClass:"body-1 mrg-bottom-0"},[_vm._v(" "+_vm._s(_vm.channel.form.company.country)+" ")])])]:_vm._e(),_c('a-divider',{staticClass:"item_divider",attrs:{"type":"vertical"}}),_c('div',{staticClass:"item"},[_c('p',{staticClass:"heading-h8 mrg-bottom-0 item_title"},[_vm._v("Fecha de activación")]),_c('p',{staticClass:"body-1 mrg-bottom-0"},[_vm._v(" "+_vm._s(_vm.dateWasApproved)+" ")])])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }