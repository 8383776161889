<template>
  <div class="section-board">
    <div class="section-board__header">
      <h1 class="heading-h6 section-board__title">Configura tu chatbot</h1>
      <div class="section-board__actions">
        <a
          class="section-board__action"
          @click="handleAddNodeMessage"
          :disabled="treeNodes !== undefined ? true : false"
          >Agregar nuevo mensaje</a
        >
        <a
          class="section-board__action"
          @click="handleAddNodeOptions"
          :disabled="treeNodes !== undefined ? true : false"
          >Agregar opciones múltiples</a
        >
      </div>
    </div>
    <custom-section-scroll :master-styles="{ height: '70vh' }">
      <section
        class="section-board__body"
        :class="{ 'section-board__body--empty': treeNodes === undefined }"
      >
        <container-tree
          :chatbotId="$route.params.id"
          @onOpenDrawer="handleOpenDrawer"
        />
      </section>
    </custom-section-scroll>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import ContainerTree from '@/app/chatbots/components/organisms/tree/ContainerTree'

export default {
  name: 'SectionBoard',
  components: {
    CustomSectionScroll,
    ContainerTree,
  },
  data: () => ({
    count: 0,
  }),
  computed: {
    ...mapGetters(['nodes', 'treeNodes']),
  },
  watch: {
    nodes: {
      deep: true,
      handler: function () {
        this.$nextTick(() => this.count++)
      },
    },
  },
  methods: {
    ...mapActions(['addNodeOptions', 'addNodeMessage']),

    handleOpenDrawer(settings) {
      this.$emit('onOpenDrawer', settings)
    },
    async handleAddNodeOptions() {
      const newNodeOptions = {
        message: 'Hola, bienvenid@ a tu primer chatbot',
        parentNodeId: null,
        options: [
          {
            order: 1,
            text: 'Una opción',
            action: {
              type: 'none',
              message: 'Actualmente no atendemos por este medio, gracias.',
            },
          },
        ],
        errorMessage:
          'Disculpa, no he entendido tu respuesta. Escribe nuevamente',
      }
      const response = await this.addNodeOptions({
        chatbotId: this.$route.params.id,
        nodeOptions: newNodeOptions,
      })
      if (response.chatbotId) {
        this.$message.success('Se añadio correctamente')
      } else {
        this.$message.error('No pudimos agregar las opciones múltiples 😓')
      }
    },
    async handleAddNodeMessage() {
      const newNodeMessage = {
        message: 'Hola que onda',
        action: 'none',
        wait: false,
        parentNodeId: null,
      }
      const response = await this.addNodeMessage({
        chatbotId: this.$route.params.id,
        nodeMessage: newNodeMessage,
      })
      if (response.chatbotId) {
        this.$message.success('Se añadio correctamente')
      } else {
        this.$message.error('No pudimos agregar el mensaje 😓')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.section-board
  height: 100%
  border: 1px solid $gray_dark_100
  width: 100%
  background-color: $white_000
  border-radius: 8px

  &__header
    padding: 12px 20px
    border-bottom: 1px solid $gray_dark_100
  &__title
    width: 100%
    text-align: left
  &__actions
    width: 100%
    display: flex
  &__action
    margin-right: 12px
  &__body
    padding: 12px 20px
    &--empty
      height: 500px
      display: flex
      align-items: center
      justify-content: center
</style>
