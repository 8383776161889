<template>
  <div class="login-form">
    <div class="content">
      <h5 class="heading-h5 gray-dark-900">
        ¡Bienvenid@! Ingresa a tu cuenta.
      </h5>
      <!-- Formulario de login -->
      <a-alert
        class="form-model-alert"
        :message="messageAlert"
        :type="typeAlert"
        show-icon
        v-if="showAlert"
      />
      <a-form
        id="components-form-demo-normal-login"
        :form="form"
        class="form"
        layout="vertical"
        @submit="handleSubmit"
      >
        <a-form-item label="Correo electrónico">
          <a-input
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor ingresa tu correo electrónico.',
                  },
                  {
                    type: 'email',
                    message: 'Ingresa un correo válido.',
                  },
                  {
                    whitespace: true,
                    message: 'No se aceptan espacios en blanco.',
                  },
                  {
                    min: 6,
                    message: '6 caracteres como mínimo.',
                  },
                  {
                    max: 50,
                    message: '50 caracteres como máximo.',
                  },
                ],
              },
            ]"
            placeholder="Escriba aquí..."
            :maxLength="50"
            :size="inputs_size"
          >
            <a-icon
              slot="prefix"
              type="user"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item label="Contraseña">
          <a-input-password
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor ingresa tu contraseña',
                  },
                ],
              },
            ]"
            type="password"
            placeholder="Escriba aquí..."
            :size="inputs_size"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input-password>
        </a-form-item>
        <!--Link button para recuperar contraseña-->
        <p v-if="!active_breakpoint.is_mobile" class="body-2">
          ¿Olvidaste tu contraseña? Haz
          <router-link :to="{ name: 'restore-password' }"
            >click aquí</router-link
          >
        </p>
        <!--Recuperar contraseña solo mobile-->
        <p v-else class="mrg-bottom-16">
          <router-link :to="{ name: 'restore-password' }" class="regular-14">
            Olvidaste tu contraseña</router-link
          >
        </p>
        <a-button
          type="primary"
          html-type="submit"
          class="form-button form-button-submit"
          :disabled="disabled"
          :loading="loadingLogin"
          :size="buttons_size"
        >
          Iniciar sesión
        </a-button>
        <a-divider class="form-divider"
          ><span class="body-2 mrg-bottom-0">o</span>
        </a-divider>
        <!-- Login con Google. TODO: Oculto hasta resolver problemas con Google -->
        <!-- <a-button class="form-button" @click="googleSignIn">
          <span style="margin-right: 8px">
            <img src="img/icon_login/google.png" /> </span
          >Inicia sesión con Google</a-button
        > -->
        <!-- Login con Facebook -->
        <a-button
          class="form-button"
          @click="facebookSignIn"
          :size="buttons_size"
        >
          <span style="margin-right: 8px">
            <img src="img/icon_login/facebook.png" />
          </span>
          Inicia sesión con Facebook</a-button
        >
      </a-form>

      <ModalLogin
        ref="modalLogin"
        :visible="visibleModal"
        :typeModal="typeModal"
        @handleNoVisible="handleNoVisible"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ModalLogin from '@/app/shared/components/modals/ModalLogin'
import { GoogleService, FacebookService } from 'social-login-oauth'

export default {
  name: 'LoginForm',
  components: {
    ModalLogin,
  },
  data: () => ({
    disabled: false,
    path: '',
    inputValue: '',
    showAlert: false,
    messageAlert: '',
    typeAlert: '',
    visibleModal: false,
    typeModal: '', // nombre del modal
    submit: false,
    loadingLogin: false,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_login' })
  },
  computed: {
    ...mapGetters(['buttons_size', 'inputs_size', 'active_breakpoint']),
  },
  methods: {
    ...mapActions(['signin']),
    async googleSignIn() {
      const googleLoginOptions = {
        scope: 'profile email',
        prompt: 'select_account',
      } // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

      const gClientid = process.env.VUE_APP_GOOGLE_CLIENT_ID
      const resultOauth = await GoogleService.signIn(
        gClientid,
        googleLoginOptions
      )
      const response = await this.signin({
        user: resultOauth,
        socialLogin: true,
      })
      if (response.result === 'not-register') {
        this.showAlert = true
        this.typeAlert = 'error'
        this.messageAlert = 'No se encuentra registrado'
      }
    },
    async facebookSignIn() {
      const fbLoginOptions = {
        scope: 'public_profile,email',
        return_scopes: true,
        enable_profile_selector: true,
      } // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

      const facebookID = process.env.VUE_APP_META_MESSENGER_CLIENT_ID
      const resultOauth = await FacebookService.signIn(
        facebookID,
        fbLoginOptions
      )
      const response = await this.signin({
        user: resultOauth,
        socialLogin: true,
      })
      if (response.result === 'not-register') {
        this.showAlert = true
        this.typeAlert = 'error'
        this.messageAlert = 'No se encuentra registrado'
      } else if (response.result === 'inactive') {
        this.showAlert = true
        this.messageAlert = 'El usuario se encuentra inactivo'
        this.typeAlert = 'warning'
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(this.validateFields)
    },
    async validateFields(err, values) {
      if (!err) {
        if (!this.submit) {
          this.submit = true
          this.loadingLogin = true
          const credentials = {
            email: values.email,
            password: values.password,
          }
          const response = await this.signin(credentials)
          this.loadingLogin = false
          if (!response.success) {
            this.resetForm()
            if (response.result === 'inactive') {
              this.showAlert = true
              ;(this.messageAlert = 'El usuario se encuentra inactivo'),
                (this.typeAlert = 'warning')
            } else {
              this.showAlert = true
              ;(this.messageAlert = 'El usuario o contraseña son incorrectos'),
                (this.typeAlert = 'error')
            }
          }
          return (this.submit = false)
        } else {
          return
        }
      }
    },
    resetForm() {
      this.disabled = false
      this.form.resetFields(['password'])
    },
    handleNoVisible() {
      this.visibleModal = false
    },
  },
}
</script>

<style lang="sass" scoped>
.text-center
  text-align: center
.gray-dark-900
  color: $gray_dark_900
.login-form
  height: fit-content
  width: 100%
  border: 1px solid $gray_5
  border-radius: 8px
  display: flex
  flex-direction: column
  justify-content: center
  .content
    padding: 24px
    h5
      margin-bottom: 16px
    .form-model-alert
      width: 100%
      margin-bottom: 2.5%
    .form
      width: 100%
      &-divider
        margin: 16px auto 4px
        display: flex
        align-items: center
        height: auto
      &-button
        width: 100%
        margin-top: 8px
        &:first-of-type
          margin-top: 0px
        &-submit
          margin-top: 40px
</style>
