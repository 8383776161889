const initialState = () => ({
  ticketSelected: null,
  tickets: [], // Lista de tickets activos, dentro tiene un arreglo de mensajes
  ticketsIdUnread: [], // Lista de ids de tickets sin leer
  ticketsEnded: [], // Lista de tickets finalizados, dentro tiene un arreglo de mensajes
  ticketsIdSelected: [], // Lista de id de tickets seleccionados
  oldTickets: [], // Lista de tickets antiguos de un cliente
  oldTicketSelected: null, // Ticket Seleccionado de la lista de tickets antiguos del cliente
  agentsAssignedToTicket: [], // lista de agentes asignados a un ticket
  currentIdMailbox: null,
})

export default initialState
