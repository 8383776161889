var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-tag"},[_c('div',{staticClass:"section-tag-card"},[_c('h6',{staticClass:"heading-h6 title"},[_c('a-icon',{staticClass:"mrg-right-8",attrs:{"type":"tag"}}),_vm._v(" Etiquetas ")],1),_c('p',{staticClass:"body-2 description",staticStyle:{"margin-bottom":"20px"}},[_vm._v(" Crea etiquetas, podrán ser asignadas a las conversaciones y así poder realizar reportes. ")]),_c('a-row',[_c('a-form',{staticClass:"modal-form",attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Nuevo nombre de etiqueta","validate-status":_vm.tagsNameError() ? 'error' : '',"help":_vm.tagsNameError() || ''}},[_c('div',{staticClass:"inline"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'tagName',
                {
                  rules: [
                    { required: true, message: 'Por favor rellena el campo' },
                  ],
                },
              ]),expression:"[\n                'tagName',\n                {\n                  rules: [\n                    { required: true, message: 'Por favor rellena el campo' },\n                  ],\n                },\n              ]"}],attrs:{"maxLength":50,"placeholder":"Ingrese nombre","size":"default"}}),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'tagColor',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor seleccione un color',
                    },
                  ],
                },
              ]),expression:"[\n                'tagColor',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Por favor seleccione un color',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Color"}},_vm._l((_vm.colors),function(color){return _c('a-select-option',{key:`${color}`},[_c('ColorPick',{attrs:{"color":color,"size":"bold"}})],1)}),1),_c('a-button',{attrs:{"type":"primary","html-type":"submit","disabled":_vm.hasErrors(_vm.form.getFieldsError())}},[_vm._v(" Agregar etiqueta ")])],1)])],1)],1),_c('a-row',[_c('div',[_c('a-table',{staticClass:"scrollbar_basic table-tags",attrs:{"columns":_vm.columns,"data-source":_vm.rows,"pagination":false,"scroll":{ y: 'calc(90.2vh - 19em - 250px)' },"size":"small"},scopedSlots:_vm._u([_vm._l((['order', 'tagName', 'color']),function(col){return {key:col,fn:function(attr, record){return [_c('div',{key:col},[(record.editable && attr.type === 'input')?_c('a-input',{staticStyle:{"margin":"-5px 0"},attrs:{"defaultValue":attr.value,"maxLength":50},on:{"change":(e) => _vm.handleChange(e.target.value, record.key, col)}}):(record.editable && attr.type === 'picker')?_c('a-form',{attrs:{"form":_vm.formEdit},on:{"submit":(e) => e.preventDefault()}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'tagColorEdited',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Por favor seleccione un color',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'tagColorEdited',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Por favor seleccione un color',\n                          },\n                        ],\n                      },\n                    ]"}],staticStyle:{"width":"74%"},attrs:{"placeholder":"Color"},on:{"change":(e) => _vm.handleColorTag(e, record.key, col)}},_vm._l((_vm.colors),function(color){return _c('a-select-option',{key:`${color}`},[_c('ColorPick',{attrs:{"color":color}})],1)}),1)],1)],1):[(attr.type === 'picker')?_c('span',[_c('ColorPick',{attrs:{"color":attr.value}})],1):_c('span',[_vm._v(" "+_vm._s(attr.value)+" ")])]],2)]}}}),{key:"action",fn:function(text, record){return [_c('div',{staticClass:"editable-row-operations"},[(record.editable)?_c('span',[_c('a',{on:{"click":() => _vm.handleSave(record)}},[_vm._v("Guardar")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.handleCancel(record)}}},[_vm._v("Cancelar")])],1):_c('span',[_c('a',{attrs:{"disabled":record.editableByBack.value === false || _vm.editingKey !== ''},on:{"click":function($event){return _vm.handleEdit(record)}}},[_vm._v("Editar")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{attrs:{"disabled":record.editableByBack.value === false || _vm.editingKey !== ''},on:{"click":function($event){return _vm.handleDelete(record)}}},[_vm._v("Eliminar")])],1)])]}}],null,true)})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }