<template>
  <a :href="href" target="_blank" rel="noopener noreferrer">
    <slot>
      {{ title }}
    </slot>
  </a>
</template>

<script>
export default {
  name: 'Anchor',
  props: {
    href: { type: String, default: null, required: false },
    title: { type: String, default: '', required: false },
  },
}
</script>

<style scoped></style>
