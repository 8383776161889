<template>
  <header class="header">
    <h5
      class="align-left mrg-bottom-0"
      :class="[
        {
          'align-left': position === 'left',
          'align-center': position === 'center',
          'align-right': position === 'right',
        },
        tipography,
      ]"
    >
      {{ title }}
    </h5>
    <a-tooltip :placement="positionTooltip">
      <template slot="title">
        {{ textBeforeLink }}
        <a :href="link" target="_blank" rel="noopener noreferrer">
          {{ textLink }} </a
        >{{ textAfterLink }}
      </template>
      <a-icon type="info-circle" class="header__icon" />
    </a-tooltip>
  </header>
</template>

<script>
export default {
  name: 'HeaderModule',
  props: {
    title: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: false,
      default: 'left', // left, right, center
    },
    textBeforeLink: {
      type: String,
      required: false,
      default: '',
    },
    textAfterLink: {
      type: String,
      required: false,
      default: '',
    },
    textLink: {
      type: String,
      required: false,
      default: 'Click aquí',
    },
    link: {
      type: String,
      required: false,
      default: '#',
    },
    positionTooltip: {
      type: String,
      required: false,
      default: 'bottom', // top, left, right, topLeft,topRight, bottomLeft, bottomRight, leftTop, leftBottom, rightTop, rightBottom
    },
    tipography: {
      type: String,
      required: false,
      default: 'semibold-16',
    },
  },
}
</script>

<style lang="sass" scoped>
.header
  display: flex
  align-items: center
  margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
</style>
