<template>
  <card-base class="text--left card">
    <h6 class="heading-h6 card__title">
      Transferencia automática de tickets del chatbot
    </h6>
    <p>
      Permite a los chatbots transferir sus tickets automáticamente a la cola
      general cuando no haya interacción entre el ticket y el chatbot en el
      tiempo establecido.
    </p>

    <a-row type="flex" align="bottom">
      <a-row>
        <p class="regular-14 mrg-bottom-2 card__label">
          Selecciona el tiempo de transferencia
        </p>
        <a-select
          class="card__selector"
          v-model="timeout"
          :disabled="!isEditing"
        >
          <a-select-option :value="0">Ninguno</a-select-option>
          <a-select-option v-for="item in 12" :key="item * 10"
            >{{ item * 10 }} minutos</a-select-option
          >
        </a-select>
      </a-row>
      <a-row>
        <a-tooltip
          v-if="!isEditing"
          title="Edita el tiempo de transferencia"
          placement="right"
        >
          <a-icon
            type="edit"
            class="card__action-icon"
            @click="isEditing = true"
          />
        </a-tooltip>
        <template v-else>
          <a-button
            class="mrg-right-12"
            :disabled="disabled.actions"
            @click="handleCancel"
            >Cancelar</a-button
          >
          <a-button
            type="primary"
            :disabled="disabled.actions"
            :loading="loadingOk"
            @click="handleOk"
            >{{ okText }}</a-button
          >
        </template>
      </a-row>
    </a-row>
  </card-base>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CardBase from '@/app/shared/components/cards/CardBase'

export default {
  name: 'CardTimeoutChatbot',
  components: {
    CardBase,
  },
  data: () => ({
    timeout: 0,
    disabled: {
      actions: false,
      select: false,
    },
    loadingOk: false,
    isEditing: false,
  }),
  mounted() {
    this.$nextTick(
      () =>
        (this.timeout = this.company.settings.chatbot.minutesTransferTickets)
    )
  },
  computed: {
    ...mapGetters(['company']),

    /**
     * @return {String}
     */
    okText() {
      return this.loadingOk ? 'Guardando' : 'Guardar'
    },
  },
  methods: {
    ...mapActions(['updatedTimeTransferTickets']),

    /**
     * Cancela la acción y regresa los cambios que se hicieron
     */
    handleCancel() {
      this.isEditing = false
      this.timeout = this.company.settings.chatbot.minutesTransferTickets
    },
    /**
     * Ejecuta la actualización del tiempo de transferencia
     */
    async handleOk() {
      this.loadingOk = true
      this.disabled.actions = true
      const response = await this.updatedTimeTransferTickets(this.timeout)
      if (response.success) {
        this.isEditing = false
        this.$message.success('Se actualizó con éxito')
      } else {
        this.isEditing = true
        this.$message.error('Ocurrió un error 😥')
      }
      this.loadingOk = false
      this.disabled.actions = false
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  color: $gray_dark_800
  &__title
    color: $gray_dark_800
  &__selector
    width: 20em
    margin-right: 16px
  &__action-icon
    margin-bottom: 6px
    font-size: 16px
    color: $blue-6
</style>
