<template>
  <div class="section-custom-fields" :class="{ 'section--border': border }">
    <div class="section-custom-fields-card">
      <slot name="title">
        <h6 class="heading-h6 title">
          <a-icon v-if="icon" :type="icon" class="mrg-right-8" />
          {{ title }}
        </h6>
      </slot>
      <p class="body-2 description" style="margin-bottom: 20px">
        {{ description }}
      </p>
      <div class="first-contain">
        <a-input-search
          v-model="search"
          placeholder="Buscar campos por nombre"
          style="width: 256px"
        />
        <a-button type="primary" @click="handleCreateField" icon="plus"
          >Crear campo</a-button
        >
      </div>
      <a-table
        class="scrollbar_basic"
        :columns="columns"
        :data-source="dataList"
        :pagination="pagination"
        :size="sizeTable"
        :scroll="scroll"
        @change="handleChangeTable"
        :loading="loadTable"
      >
        <span slot="type" slot-scope="text, record">
          {{ convertType(record.type) }}
          {{ record.multiple ? 'Múltiple' : '' }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleShowEdit(record)">Editar</a>
          <a-divider type="vertical" />
          <a @click="handleShowDelete(record)">Eliminar</a>
        </span>
      </a-table>
    </div>

    <!---------PRIMER MODAL DE ELECCION DE CAMPO---------->
    <a-modal
      v-model="modalFirstCreate.visible"
      title="Selecciona un tipo de campo"
      :bodyStyle="modalFirstCreate.bodyStyle"
      width="504px"
      :afterClose="afterCloseCreate"
      centered
      @cancel="handleFirstCancel"
    >
      <CardField
        v-for="cardField in modalFirstCreate.cardFields"
        :key="cardField.type"
        :id="cardField.id"
        :title="cardField.title"
        :description="cardField.description"
        @onSelect="handleSelectCard(cardField.id)"
        :selected="cardFieldSelected.id === cardField.id"
      >
        <template v-slot:icon>
          <a-icon
            :type="cardField.typeIcon"
            style="color: #1890ff; font-size: 24px"
          />
        </template>
      </CardField>
      <template slot="footer">
        <div style="display: flex; justify-content: flex-end">
          <a-button @click="handleFirstCancel" style="height: 32px; width: 90px"
            >Cancelar</a-button
          >
          <a-button
            type="primary"
            @click="handleOk"
            style="height: 32px; width: 90px"
            >Continuar</a-button
          >
        </div>
      </template>
    </a-modal>
    <!---------SEGUNDO MODAL DE ELECCION DE CAMPO---------->
    <a-modal
      v-model="modalSecondCreate.visible"
      :title="`Crear campo ${
        cardFieldSelected.title ? cardFieldSelected.title.toLowerCase() : ''
      }`"
      width="480px"
      :bodyStyle="modalSecondCreate.bodyStyle"
      :afterClose="afterCloseCreate"
      centered
      @cancel="handleSecondCancel"
    >
      <a-form-model
        :model="form"
        :rules="rules"
        ref="formCreateField"
        layout="vertical"
      >
        <p class="body-2">
          Recuerda ser
          {{ firstWord }}
          y
          {{ secondWord }}
          con el nombre del campo
          {{
            cardFieldSelected.type === 'dropdown'
              ? 'y de las opciones para evitar confuciones con tus agentes.'
              : ''
          }}
        </p>
        <template v-if="cardFieldSelected.type === 'dropdown'">
          <h6 class="heading-h7">Activar dropdown múltiple</h6>
          <a-checkbox v-model="form.multiple" style="margin-bottom: 16px"
            >Podrás seleccionar varias opciones de la lista</a-checkbox
          >
        </template>

        <a-form-model-item label="Nombre de campo" prop="name">
          <a-input
            placeholder="Escribe aquí..."
            v-model="form.name"
            :maxLength="20"
          />
        </a-form-model-item>
        <template v-if="cardFieldSelected.type === 'dropdown'">
          <p class="body-2 modal__subtitle">Opciones de dropdown</p>
          <a-form-model-item
            v-for="(item, index) in form.options"
            :key="`option${index + 1}`"
            :label="`Opción ${index + 1}`"
            style="margin-bottom: 4px"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              "
            >
              <a-input
                placeholder="Escribe aquí..."
                v-model="item.value"
                :maxLength="20"
                style="width: 412px; margin-right: 8px"
              />
              <a-icon
                v-if="index + 1 > 1"
                type="delete"
                style="font-size: 20px"
                @click="handleDeleteOption(item.id, 'create')"
              />
            </div>
          </a-form-model-item>
          <!-- </div> -->
        </template>
      </a-form-model>
      <a
        v-if="cardFieldSelected.type === 'dropdown'"
        @click="handleAddField('create')"
        style="margin-top: 8px"
        ><a-icon type="plus" /> Agregar opción</a
      >
      <template slot="footer">
        <div style="display: flex; justify-content: flex-end">
          <a-button
            @click="handleSecondCancel"
            style="height: 32px; width: 90px"
            >Cancelar</a-button
          >
          <a-button
            type="primary"
            @click="handleCreateCustom"
            style="height: 32px; width: 90px"
            >Crear</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-----------MODAL DE EDITAR CAMPO-------------->
    <a-modal
      v-model="modalEdit.visible"
      :title="`Editar campo ${formEdit.name}`"
      width="480px"
      :bodyStyle="modalEdit.bodyStyle"
      :afterClose="afterCloseEdit"
      centered
    >
      <a-form-model
        :model="formEdit"
        :rules="rulesEdit"
        ref="formEditField"
        layout="vertical"
      >
        <p class="body-2">
          Recuerda ser
          {{ firstWord }}
          y
          {{ secondWord }}
          con el nombre del campo
          {{
            formEdit.type === 'dropdown'
              ? 'y de las opciones para evitar confuciones con tus agentes.'
              : ''
          }}
        </p>
        <template v-if="formEdit.type === 'dropdown'">
          <h6 class="heading-h7">Activar dropdown múltiple</h6>
          <a-checkbox v-model="formEdit.multiple"
            >Podrás seleccionar varias opciones de la lista</a-checkbox
          >
        </template>

        <a-form-model-item label="Nombre de campo" prop="name">
          <a-input
            placeholder="Escribe aquí..."
            v-model="formEdit.name"
            :maxLength="20"
          />
        </a-form-model-item>
        <template v-if="formEdit.type === 'dropdown'">
          <p class="heading-h7" style="margin-bottom: 4px">
            Opciones de dropdown
          </p>
          <a-form-model-item
            v-for="(item, index) in formEdit.options"
            :key="`option-${index + 1}`"
            :label="`Opción ${index + 1}`"
            style="margin-bottom: 4px"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              "
            >
              <a-input
                placeholder="Escribe aquí..."
                v-model="item.name"
                :maxLength="20"
                style="width: 412px; margin-right: 8px"
              />
              <a-icon
                v-if="index + 1 > 1"
                type="delete"
                style="font-size: 20px"
                @click="handleDeleteOption(item.idOption, 'edit')"
              />
            </div>
          </a-form-model-item>
        </template>
      </a-form-model>
      <a
        v-if="formEdit.type === 'dropdown'"
        @click="handleAddField('edit')"
        style="margin-top: 8px"
        ><a-icon type="plus" /> Agregar opción</a
      >
      <template slot="footer">
        <div style="display: flex; justify-content: flex-end">
          <a-button @click="handleCancelEdit" style="height: 32px; width: 100px"
            >Cancelar</a-button
          >
          <a-button
            type="primary"
            @click="handleEditCustom"
            style="height: 32px; width: 100px"
            :loading="loadBtnEdit"
            >Editar</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import CardField from '@/app/settings/components/organisms/workspaceMenu/CardField.vue'
import { mapActions, mapGetters } from 'vuex'
import transformWordGender from '@/app/shared/mixins/transform'

export default {
  name: 'SectionCustomFields',
  props: {
    listCustomFields: {
      type: Array,
    },
    border: {
      type: Boolean,
      required: false,
      default: true,
    },
    module: {
      type: String,
      required: false,
      default: 'workspace',
    },
    description: { type: String, required: false, default: '' },
    pagination: {
      type: Object,
      required: false,
      default: () => ({
        current: 1,
        hideOnSinglePage: true,
      }),
    },
    sizeTable: {
      type: String,
      required: false,
      default: 'small',
    },
    scroll: {
      type: Object,
      required: false,
      default: () => ({
        y: 'calc(90.2vh - 15em - 250px)',
      }),
    },
    title: {
      type: String,
      required: false,
      default: 'Campos personalizados',
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    CardField,
  },
  data: () => ({
    columns: [
      {
        title: 'N°',
        dataIndex: 'order',
        key: 'order',
        width: 42,
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type',
        width: 200,
        scopedSlots: { customRender: 'type' },
      },
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        // width: 80,
      },
      {
        title: 'Acciones',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        width: 160,
      },
    ],
    modalFirstCreate: {
      visible: false,
      cardFields: [
        {
          id: 'cardfield-text',
          type: 'text',
          title: 'Texto',
          typeIcon: 'font-size',
          description: 'Úsalo para capturar valores en una sola línea.',
        },
        {
          id: 'cardfield-dropdown',
          type: 'dropdown',
          title: 'Dropdown',
          typeIcon: 'down-square',
          description: 'Úsalo para dar opciones.',
        },
        {
          id: 'cardfield-date',
          type: 'date',
          title: 'Date',
          typeIcon: 'calendar',
          description: 'Úsalo para capturar fechas.',
        },
      ],
      bodyStyle: {
        padding: '32px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    modalSecondCreate: {
      visible: false,
      bodyStyle: {
        padding: '12px 20px 24px 20px',
        overflowY: 'auto',
        maxHeight: '460px',
      },
    },
    cardFieldSelected: 'nombre',
    form: {
      // valor del formulario
      name: '',
      options: [{ id: 'option-1', value: '' }],
      multiple: false,
    },
    rules: {
      // reglas del formulario
      name: [
        { required: true, message: 'Por favor completa este campo' },
        { whitespace: true, message: 'No se admiten espacios en blanco.' },
      ],
    },
    formEdit: {},
    modalEdit: {
      visible: false,
      bodyStyle: {
        padding: '12px 20px 24px 20px',
        overflowY: 'auto',
        maxHeight: '460px',
      },
    },
    rulesEdit: {
      // reglas del formulario
      name: [
        { required: true, message: 'Por favor completa este campo' },
        { whitespace: true, message: 'No se admiten espacios en blanco.' },
      ],
    },
    search: '',
    loadBtnEdit: false,
    checkMultiple: false,
    loadTable: false,
  }),
  computed: {
    ...mapGetters(['profile']),

    dataList() {
      const data = []
      // let number = 0
      this.listCustomFields.map((elem, index) => {
        // number++
        let field = {
          key: `${elem._id}`,
          _id: `${elem._id}`,
          order: this.formattedNumber(index + 1),
          active: elem.active,
          created_at: elem.created_at,
          name: elem.name,
          options: elem.options,
          type: elem.type,
          updated_a: elem.type,
          multiple: elem.multiple,
        }
        data.push(field)
      })
      return data.filter((field) => field.name.includes(this.search))
    },
    firstWord() {
      return this.profile && this.profile.gender
        ? this.transformWordGender('preciso', this.profile.gender)
        : 'precis@'
    },
    secondWord() {
      return this.profile && this.profile.gender
        ? this.transformWordGender('consiso', this.profile.gender)
        : 'consis@'
    },
  },
  mixins: [transformWordGender],
  methods: {
    ...mapActions([
      'createCustomField',
      'updateCustomField',
      'deleteCustomField',
    ]),
    handleCreateField() {
      this.modalFirstCreate.visible = true
    },
    handleShowEdit(record) {
      this.formEdit = { ...record }
      if (this.formEdit.options)
        this.formEdit.option = this.formEdit.options.filter((opt) => !opt.new)
      this.modalEdit.visible = true
    },
    handleCancel() {
      this.modalFirstCreate.visible = false
    },
    handleOk() {
      if (typeof this.cardFieldSelected !== 'string') {
        this.modalFirstCreate.visible = false
        this.modalSecondCreate.visible = true
      } else {
        this.$message.error('Selecciona un tipo de campo')
      }
    },
    /**
     * Seleccion de card de campo personalizado
     * @param {String} idSelected - id de la card seleccionada
     */
    handleSelectCard(idSelected) {
      const selected = this.modalFirstCreate.cardFields.filter(
        (element) => element.id === idSelected
      )
      this.cardFieldSelected = selected[0]
    },
    handleAddField(action) {
      const idOption = new Date()

      if (action === 'create')
        this.form.options.push({ id: idOption, value: '', new: true })
      if (action === 'edit')
        this.formEdit.options.push({
          idOption: idOption,
          value: '',
          new: true,
        })
    },
    handleCreateCustom(e) {
      e.preventDefault()
      this.$refs.formCreateField.validate(async (valid) => {
        if (valid) {
          const options = []
          let isNoFilledOptions = false
          if (this.cardFieldSelected.type === 'dropdown') {
            this.form.options.map((elem) => {
              options.push({ name: elem.value })
            })
            isNoFilledOptions = this.form.options.some(
              (elem) => !elem.value.length
            )
          }
          if (isNoFilledOptions)
            return this.$message.error('Completa todas las opciones')
          this.loadTable = true
          const response = await this.createCustomField({
            name: this.form.name,
            type: this.cardFieldSelected.type,
            options: options,
            multiple: this.form.multiple,
            module: this.module,
          })
          if (response.success) {
            this.$message.success('Campo agregado correctamente')
            this.modalSecondCreate.visible = false
            this.handleSecondCancel()
          } else if (response.result !== 'overflow') {
            this.$message.error(response.details)
          }
          this.loadTable = false
        }
      })
    },
    handleDeleteOption(id, action) {
      // this.form.options = this.form.options.filter(option => option.id !== id)
      if (action === 'create')
        this.form.options = this.form.options.filter(
          (option) => option.id !== id
        )
      if (action === 'edit')
        this.formEdit.options = this.formEdit.options.filter(
          (option) => option.idOption !== id
        )
    },
    handleEditCustom(e) {
      e.preventDefault()
      this.$refs.formEditField.validate(async (valid) => {
        if (valid) {
          let isNoFilledOptions = false
          if (this.formEdit.type === 'dropdown')
            isNoFilledOptions = this.formEdit.options.some(
              (elem) => !elem.name.length
            )
          if (isNoFilledOptions)
            return this.$message.error('Completa todas las opciones')
          this.loadBtnEdit = true
          this.loadTable = true
          const response = await this.updateCustomField({
            idCustomField: this.formEdit._id,
            name: this.formEdit.name,
            type: this.formEdit.type,
            options: this.formEdit.options,
            multiple: this.formEdit.multiple,
            module: this.module,
          })
          console.log(response)
          if (response.success) {
            this.$message.success('Campo actualizado correctamente')
            this.modalEdit.visible = false
          } else this.$message.error(response.details)
          this.loadBtnEdit = false
          this.loadTable = false
        }
      })
    },
    handleShowDelete(record) {
      let self = this
      this.$confirm({
        title: `¿Estás segur@ de eliminar el campo "${record.name}"?`,
        content: 'Recuerda que este campo se eliminará de todos los tickets.',
        okText: 'Eliminar',
        okType: 'danger',
        cancelText: 'Cancelar',
        centered: true,
        async onOk() {
          self.loadTable = true
          const response = await self.deleteCustomField({
            idCustomField: record._id,
            module: self.module,
          })
          if (response.success) {
            self.$message.success('El campo se eliminó correctamente')
            self.modalEdit.visible = false
          } else self.$message.error(response.details)
          self.loadTable = false
        },
      })
    },
    handleFirstCancel() {
      this.modalFirstCreate.visible = false
      this.cardFieldSelected = 'nombre'
      this.afterCloseCreate()
    },
    handleSecondCancel() {
      this.modalSecondCreate.visible = false
      this.cardFieldSelected = 'nombre'
      this.afterCloseCreate()
    },
    afterCloseCreate() {
      this.form.name = ''
      this.form.multiple = false
      this.$refs.formCreateField.resetFields()
      this.loadTable = false
      const findOption = this.form.options.find(
        (elem) => elem.id === 'option-1'
      )
      if (findOption) {
        this.form.options = this.form.options.filter(
          (elem) => elem.id === 'option-1'
        )
        this.form.options[0].value = ''
      } else this.form.options.push({ id: 'option-1', value: '' })
    },
    handleCancelEdit() {
      this.modalEdit.visible = false
    },
    afterCloseEdit() {
      if (this.$refs.formEditField) {
        this.checkMultiple = false
        this.$refs.formEditField.resetFields()
      }
    },
    convertType(type) {
      if (type === 'text') return 'Texto'
      if (type === 'dropdown') return 'Dropdown'
      if (type === 'date') return 'Date'
    },
    /**
     * Llenar la tabla de Colas segun la paginacion
     * @param {Object} pagination
     * @param {Number} pagination.current página actual
     */
    async handleChangeTable(pagination) {
      this.$emit('onPaginate', pagination.current)
    },
    /**
     * Formateando el orden segun la pagina
     * @param {Number} number
     */
    formattedNumber(number) {
      const currentPage = 1
      const aditional = this.pagination.defaultPageSize
        ? (currentPage - 1) * this.pagination.defaultPageSize
        : 0
      return number + aditional
    },
  },
}
</script>

<style lang="sass" scoped>
.modal__subtitle
  color: $gray_dark_900
  margin-bottom: 4px
.section-custom-fields
  padding: 12px 20px
  &-card
    height: 100%
    .title
      text-align: left
      color: $gray_dark_800
    .description
      text-align: left
      color: $gray_light_900
  .first-contain
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-bottom: 12px
.section--border
  border: 1px solid #D9D9D9
  border-radius: 8px
</style>

<style lang="sass">
.section-custom-fields
  table
    tbody
      td
        padding: 8px !important
  .scrollbar_basic
    .ant-table-body
      &::-webkit-scrollbar
        width: 10px
      &::-webkit-scrollbar-track
        background: transparent
        border-left: solid 5px transparent
      &::-webkit-scrollbar-thumb
        border-radius: 8px
        box-shadow: inset 0 0 10px 10px $gray_dark_100
        border: solid 3px transparent
      &::-webkit-scrollbar-thumb:hover
        box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
