import chatModule from './chatModule'
import ticketModule from './ticketModule'
import clientModule from './clientModule'
import ticketSettingsModule from './ticketSettingsModule'
import multimediaModule from './multimediaModule'
import productsTicketModule from './productsTicketModule'

const actions = {
  /** Resetear Todos los Submodulos del Módulo Ticket */
  resetMoTicket(context) {
    context.commit('RESET_TICKET_MODULE')
    context.commit('RESET_CLIENT_MODULE')
    context.commit('RESET_CHAT_MODULE')
    context.commit('RESET_MULTIMEDIA_MODULE')
  },
}

const modules = {
  chatModule,
  ticketModule,
  clientModule,
  ticketSettingsModule,
  multimediaModule,
  productsTicketModule,
}

export default {
  actions,
  modules,
}
