<template>
  <div class="section-templates">
    <!-- <h6 class="heading-h7 align-text-left">Plantillas</h6> -->
    <header class="template__header">
      <h5 class="heading-h7 mrg-bottom-0">Plantillas</h5>
      <a-tooltip
        v-if="dataChannel.api_version !== 'twilio_v3'"
        placement="bottom"
      >
        <template slot="title">
          Solicita nuevas plantillas predefinidas de WhatsApp.
          <a
            href="https://www.securitec.pe/docs/ticker/whatsapp/plantillas-whatsapp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click aquí </a
          >si deseas conocer más.
        </template>
        <a-icon type="info-circle" class="template__icon" />
      </a-tooltip>
    </header>
    <div class="options">
      <div class="filters">
        <a-input-search
          placeholder="Buscar por nombre"
          v-model="search"
          @keyup="onSearch"
          style="width: 256px; margin-right: 12px"
        />
        <a-select
          placeholder="Selecciona aquí"
          v-model="category"
          @change="onSearch"
          style="width: 200px; margin-right: 12px"
        >
          <a-select-option v-for="category in categories" :key="category">
            {{
              category === 'allCategories' ? 'Todas las categorías' : category
            }}
          </a-select-option>
        </a-select>
        <a-select
          placeholder="Selecciona aquí"
          v-model="requestState"
          @change="onSearch"
          style="width: 200px; margin-right: 12px"
        >
          <a-select-option value="all"> Todos los estados </a-select-option>
          <a-select-option value="approved"> Aprobados </a-select-option>
          <a-select-option value="pending"> Pendientes </a-select-option>
          <a-select-option value="rejected"> Rechazados </a-select-option>
        </a-select>
        <a-select
          v-if="showFilterActives"
          placeholder="Selecciona aquí"
          v-model="state"
          @change="onSearch"
          style="width: 200px; margin-right: 12px"
        >
          <a-select-option value="all"> Activos / Inactivos </a-select-option>
          <a-select-option value="active"> Activos </a-select-option>
          <a-select-option value="inactive"> Inactivos </a-select-option>
        </a-select>
      </div>
      <!-- ##-> Botón de Nueva plantilla oculta -->
      <a-button type="primary" icon="plus" @click="handleOpenModal"
        >Nueva plantilla</a-button
      >
    </div>
    <TemplatesList
      ref="templateList"
      :dataTemplates="dataTemplates"
      :load="loadListTemplates"
      @handleEdit="handleOpenModalEdit"
      @onEditState="onSearch"
      @onBottomReached="searchNextPage"
      @handleAssign="handleOpenModalAssign"
      :requestState="requestState"
    />
    <!--Modales-->
    <a-modal
      v-model="modalCreateTemplate.visible"
      :afterClose="afterCloseCreate"
      :footer="null"
      width="640px"
      :maskClosable="false"
      centered
    >
      <template v-if="!profile.code && !profile.phone && !dataChannel.request">
        <div>
          <div class="heading-h6">Número de teléfono</div>
          <p style="padding: 0.5rem 0">
            Para crear una nueva plantilla es necesario que configures tu número
            de teléfono, dirígete a
            <router-link :to="{ name: 'profile' }">menu > perfil</router-link>
          </p>
        </div>
      </template>
      <template v-else>
        <StepsNewTemplate
          ref="formCreateTemplate"
          @onCancel="modalCreateTemplate.visible = false"
          @onCreate="handleCreate"
          :data-channel="dataChannel"
        />
      </template>
    </a-modal>
    <a-modal
      v-if="dataTemplate"
      v-model="modalEditTemplate.visible"
      title="Editar plantilla"
      :footer="null"
      width="568px"
      :bodyStyle="modalEditTemplate.bodyStyle"
      :afterClose="afterCloseEdit"
      :maskClosable="false"
      centered
    >
      <FormEditTemplate
        ref="formEditTemplate"
        @onEdit="handleEdit"
        :template="dataTemplate"
        @closeModal="handleCloseEdit"
      />
    </a-modal>
    <a-modal
      v-if="dataTemplate"
      v-model="modalAssignTemplate.visible"
      title="Asignar a plantilla"
      :footer="null"
      width="444px"
      :bodyStyle="modalEditTemplate.bodyStyle"
      :afterClose="afterCloseAssign"
      :maskClosable="false"
      centered
    >
      <FormAssignTemplate
        ref="formAssignTemplate"
        @onSubmit="handleCloseAssign"
        :template="dataTemplate"
        @closeModal="handleCloseAssign"
      />
    </a-modal>
  </div>
</template>

<script>
import StepsNewTemplate from '@/app/channels/components/whatsapp/StepsNewTemplate.vue'
import FormEditTemplate from '@/app/channels/components/organisms/forms/FormEditTemplate.vue'
import { mapActions, mapGetters } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import TemplatesList from '@/app/channels/components/whatsapp/TemplatesList.vue'
import FormAssignTemplate from '@/app/channels/components/organisms/forms/FormAssignTemplate.vue'

export default {
  name: 'SectionTemplates',
  components: {
    StepsNewTemplate,
    FormEditTemplate,
    TemplatesList,
    FormAssignTemplate,
  },
  props: {
    dataTemplates: {
      type: Object,
      required: true,
    },
    dataChannel: {
      type: Object,
      required: true,
    },
  },
  mixins: [attemptMixin],

  data: () => ({
    modalCreateTemplate: {
      visible: false,
      bodyStyle: {},
    },
    modalEditTemplate: {
      visible: false,
      bodyStyle: { padding: '0' },
    },
    search: '',
    state: 'all',
    requestState: 'all',
    lang: 'allLangs',
    category: 'allCategories',
    dataTemplate: null,
    skip: 0,
    loadListTemplates: false, //
    categories: [
      'allCategories',
      'TRANSACTIONAL',
      'MARKETING',
      'OTP',
      'ACCOUNT_UPDATE',
      'ALERT',
      'APPOINTMENT_UPDATE',
      'ISSUE_RESOLUTION',
      'PAYMENT_UPDATE',
      'PERSONAL_FINANCE_UPDATE',
      'RESERVATION_UPDATE',
      'SHIPPING_UPDATE',
      'TICKET_UPDATE',
      'TRANSPORTATION_UPDATE',
    ],
    modalAssignTemplate: {
      visible: false,
    },
  }),
  computed: {
    ...mapGetters(['profile', 'company']),
    /**
     * Mostrar Filtro de activos/inactivos ?
     * @returns {Boolean}
     */
    showFilterActives() {
      return this.requestState == 'approved' || this.requestState == 'all'
    },
  },
  mounted() {
    this.intervalAttempt(async () => {
      await this.onSearch()
    })
  },
  methods: {
    ...mapActions(['getAllTemplates', 'getAllNextTemplates']),
    /**
     * Abre el modal para crear plantilla
     */
    handleOpenModal() {
      this.modalCreateTemplate.visible = true
    },
    /**
     * Busca una plantilla
     */
    async onSearch() {
      this.loadListTemplates = true
      this.endWaitTyping(async () => {
        let search = this.search.trim()
        await this.getAllTemplates({
          channelId: this.$route.query.channelId,
          state: !this.showFilterActives ? 'all' : this.state,
          requestState: this.requestState,
          name: search,
          skip: 0,
          category: this.category,
          lang: this.lang,
        })
        this.skip = this.dataTemplates.data.length
        this.loadListTemplates = false
        this.$refs.templateList.morePaginate = true
        this.$refs.templateList.setLastChild()
      })
    },
    handleOpenModalEdit(value) {
      this.modalEditTemplate.visible = true
      this.dataTemplate = value
      if (this.$refs.formEditTemplate) this.$refs.formEditTemplate.setValue()
    },
    handleCloseEdit() {
      this.modalEditTemplate.visible = false
    },
    handleEdit() {
      this.modalEditTemplate.visible = false
      this.onSearch()
    },
    handleCreate() {
      setTimeout(() => {
        this.modalCreateTemplate.visible = false
      }, 10000)

      this.onSearch()
    },
    afterCloseEdit() {
      this.$refs.formEditTemplate.resetForm()
    },
    afterCloseCreate() {
      this.$refs.formCreateTemplate.resetForm()
    },
    async searchNextPage() {
      let search = this.search.trim()
      // let skip = this.dataFilter.length
      await this.getAllNextTemplates({
        channelId: this.$route.query.channelId,
        state: !this.showFilterActives ? 'all' : this.state,
        requestState: this.requestState,
        name: search,
        skip: this.skip,
        category: this.category,
        lang: this.lang,
      })
      if (this.skip == this.dataTemplates.data.length)
        this.$refs.templateList.morePaginate = false
      else this.$refs.templateList.morePaginate = true
      this.skip = this.dataTemplates.data.length
      this.loadListTemplates = false
      this.$refs.templateList.setLastChild()
      this.$refs.templateList.isLoading = false
    },
    // abre el modal de asignacion
    handleOpenModalAssign(value) {
      this.modalAssignTemplate.visible = true
      this.dataTemplate = value
      // chequear el seteo de valor sin usar esto
      if (this.$refs.formAssignTemplate)
        this.$refs.formAssignTemplate.setValue()
    },
    afterCloseAssign() {
      this.$refs.formAssignTemplate.resetForm()
    },
    handleCloseAssign() {
      this.modalAssignTemplate.visible = false
      this.onSearch()
    },
  },
}
</script>

<style lang="sass" scoped>
.align-text-left
  text-align: left

.section-templates
  // height: calc( 100% -  )
  .options
    margin-bottom: 12px
    display: flex
    flex-direction: row
    justify-content: space-between
    flex-wrap: wrap
    .filters
      display: flex
      flex-direction: row
      justify-content: flex-start
  .master-container
    position: relative
    padding-top: 4px
    padding-bottom: 4px
    height: calc(100% - 84px)
    border: 1px solid $gray_dark_300
    border-radius: 8px
    .list-container
      overflow-y: auto
      height: 100%
.template
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 12px
    cursor: pointer
    display: flex
</style>
