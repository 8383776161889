<template>
  <div class="chatbot-template">
    <HeaderChatbot
      v-if="chatbot"
      @onOpenSettings="handleShowModalUpdateChatbot"
      :chatbot="chatbot"
      :updated_at="updated_at"
      :updatedName="updatedName"
    />
    <p class="body-2 text-align-left description">
      Selecciona una opción y agrega acciones que comuniquen mejor con tus
      clientes. Go!
    </p>
    <a-row :gutter="12" class="row-content">
      <a-col class="gutter-row" :span="sizeGrid">
        <section-board @onOpenDrawer="handleOpenDrawer" />
      </a-col>

      <transition name="slide-fade" v-on:after-leave="afterLeave">
        <a-col
          class="gutter-row"
          :span="8"
          v-if="visibleDrawer && treeNodes !== undefined"
        >
          <DrawerSettings
            :settings="nodeSelected"
            :chatbotId="chatbotId"
            @replaceItem="onReplaceItem"
            :channels="
              chatbot.channels_companies ? chatbot.channels_companies : []
            "
            @update="updated_at = new Date()"
            @isChanges="setIsChanges"
            @handleCloseDrawer="handleCloseDrawer"
            @onChangeSettings="handleChangeSettings"
          />
        </a-col>
      </transition>
    </a-row>
    <!--Modal editar chatbot-->
    <modal-update-chatbot
      :visible="modalUpdateChatbot.visible"
      @onClose="modalUpdateChatbot.visible = false"
      @onSuccess="handleSuccessUpdated"
    />
  </div>
</template>
<script>
import HeaderChatbot from '@/app/chatbots/components/organisms/HeaderChatbot.vue'
import DrawerSettings from '@/app/chatbots/components/organisms/DrawerSettings.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import transformMixin from '@/app/shared/mixins/transform'
import SectionBoard from '@/app/chatbots/components/organisms/SectionBoard'
import ModalUpdateChatbot from '@/app/chatbots/components/organisms/modals/ModalUpdateChatbot'

export default {
  name: 'ChatbotTemplate',
  components: {
    DrawerSettings,
    HeaderChatbot,
    SectionBoard,
    ModalUpdateChatbot,
  },

  data: () => ({
    visibleDrawer: false,
    sizeGrid: 24,
    modalUpdateChatbot: {
      visible: false,
      bodyStyle: { padding: 0 },
    },
    settings: null,
    loadingForm: false,
    replaceItem: null,
    updated_at: new Date(),
    isChanges: false,
    chatbot: {},
    updatedName: null,
  }),
  created() {
    this.resetTreeNodes()
    this.intervalAttempt(() => {
      if (!this.company) throw 'Not company found'
      this.getAssignedLines()
      this.loadData()
    })
  },
  beforeDestroy() {
    this.RESET_CHATBOT_MODULE()
  },
  mixins: [transformMixin, attemptMixin],
  computed: {
    ...mapGetters([
      'listChatbots',
      'listNodesChatbot',
      'treeNodes',
      'company',
      'changes',
      'profile',
      'nodeSelected',
    ]),

    chatbotId() {
      return this.$route.params.id
    },
  },
  methods: {
    ...mapActions([
      'generateTreeNodes',
      'resetTreeNodes',
      'updatedChatbot',
      'getChatbot',
      'getAssignedLines',
      'getNodes',
    ]),
    ...mapMutations(['RESET_CHATBOT_MODULE']),

    async loadData() {
      this.chatbot = await this.getChatbot(this.$route.params.id)
      this.updated_at = new Date(this.chatbot.updated_at)
    },
    loadTreeNodes() {
      this.generateTreeNodes(this.$route.params.id)
    },
    afterLeave() {
      this.sizeGrid = 24
    },
    handleOpenDrawer(settings) {
      this.settings = settings
      this.visibleDrawer = true
      this.sizeGrid = 16
    },
    handleCloseDrawer() {
      this.visibleDrawer = false
    },
    handleShowModalUpdateChatbot() {
      this.modalUpdateChatbot.visible = true
    },
    onReplaceItem(response) {
      this.replaceItem = response
    },
    setIsChanges(isChanges) {
      this.isChanges = isChanges
    },
    handleCloseSettings() {
      this.modalUpdateChatbot.visible = false
    },
    afterCloseEdit() {
      this.$refs.formEditChatbot.handleReset()
    },
    handleChangeSettings(settings) {
      this.settings = settings
    },
    handleSuccessUpdated(chatbot) {
      this.chatbot = chatbot
      this.modalUpdateChatbot.visible = false
    },
  },
}
</script>

<style lang="sass" scoped>
.text-align-left
  text-align: left
.chatbot-template
  margin: 0px 0px 0px 4px
  height: 100%
  .description
    color: $gray_dark_800
    margin: 12px
  .row-content
    height: calc( 100% - 120px)
    padding: 0 12px
    .gutter-row
      height: 100%
</style>
