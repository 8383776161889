<template>
  <a-row
    type="flex"
    align="middle"
    class="navbar flex-no-wrap"
    :class="`navbar--${positionNavbar}`"
  >
    <!--Logo o icono-->
    <template v-if="!currentRoute.empty">
      <router-link v-if="currentRoute.isBack" :to="currentRoute.routeBack">
        <div class="navbar__container-icon">
          <a-icon type="arrow-left" class="navbar__icon" />
        </div>
      </router-link>
      <logo
        v-else
        type="light"
        size="small"
        :size-mobile="28"
        :app="app"
        class="mrg-right-8"
      />
    </template>
    <!--Nombre del modulo-->
    <a-dropdown
      v-if="currentRoute.name"
      :trigger="['click']"
      :overlayStyle="{ width: '100%' }"
    >
      <div
        class="navbar__item"
        @click="handleSelectTitle(currentRoute.routeTo)"
      >
        <h4
          class="semibold-16 navbar__title mrg-bottom-0 ellipsis"
          :class="{ 'navbar__title--underline': currentRoute.isChat }"
        >
          {{ currentRoute.name }}
          <a-icon
            v-if="currentRoute.showFavorite"
            type="star"
            theme="filled"
            class="navbar__icon--star"
          />
        </h4>
        <span v-if="currentRoute.isChat" class="regular-12"
          >Ver información</span
        >
      </div>
      <!--Menu del ticket-->
      <template v-if="currentRoute.visibleSubmenu">
        <!--TODO: Esto debería mejorar para cuando sea reactivo-->
        <menu-ticket
          slot="overlay"
          :show-default="false"
          class="ant-dropdown-menu"
          style="box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15)"
        />
      </template>
    </a-dropdown>
    <div style="flex: 1 1 0%"></div>
    <!--Boton para resolver ticket-->
    <a-button
      v-if="currentRoute.showResolveTicket"
      size="large"
      type="primary"
      class="navbar__button"
      @click="resolveTicketVisible = true"
      >Resolver</a-button
    >
    <!--Avatar del usuario-->
    <avatar-user
      v-else
      :src="profile && profile.avatar ? profile.avatar : null"
      :names="profile ? profile.names : ''"
      :size="40"
      @onClick="handleShowDrawerProfile"
    />

    <profile-menu-drawer
      :visible="drawerProfile.visible"
      @onClose="drawerProfile.visible = false"
    />
    <!--Resolver ticket-->
    <resolve-ticket
      :visible="resolveTicketVisible"
      :onOk="resolveTicket"
      @onClose="resolveTicketVisible = false"
    />
  </a-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Logo from '@/app/shared/components/logos/Logo'
import AvatarUser from '@/app/shared/components/avatars/AvatarUser'
import MenuTicket from '@/app/tickets/components/organisms/MenuTicket'
import ProfileMenuDrawer from '@/app/commons/components/organisms/ProfileMenuDrawer.vue'
import ResolveTicket from '@/app/manageTickets/components/organisms/ResolveTicket'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'Navbar',
  components: {
    Logo,
    MenuTicket,
    ProfileMenuDrawer,
    AvatarUser,
    ResolveTicket,
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    drawerProfile: {
      visible: false,
    },
    resolveTicketVisible: false,
  }),
  mixins: [supportMixin],
  computed: {
    ...mapGetters([
      'ticketSelected',
      'active_breakpoint',
      'profile',
      'mailboxes',
    ]),

    /**
     * @return {Boolean} mostrar submenu
     */
    visibleSubmenu() {
      if (!this.active_breakpoint) return false
      else {
        const navbar = this.active_breakpoint.components.navbar

        if (navbar && navbar.visible) return navbar.submenu
        else return false
      }
    },
    /**
     * Posicion del navbar
     */
    positionNavbar() {
      if (this.viewBreakpoint && this.viewBreakpoint.navbar)
        return this.viewBreakpoint.navbar.position
      else return 'normal'
    },
    /**
     * @return {Object} currentRoute
     * @return {String} currentRoute.name
     * @return {Boolean} currentRoute.isBack
     * @return {Boolean} currentRoute.visibleSubmenu
     * @return {Object} currentRoute.routeBack
     * @return {Object} currentRoute.routeTo
     * @return {Boolean} currentRoute.isChat
     * @return {Boolean} currentRoute.showResolveTicket
     */
    currentRoute() {
      const route = this.$route

      if (route.name === 'default') return { name: 'Workspace', isBack: false }
      else {
        if (this.$route.meta.is_workspace || this.$route.meta.is_monitor) {
          if (route.query.main === 'chat') {
            return {
              name: this.ticketSelected?.client.names,
              isBack: true,
              routeBack: { name: 'default' },
              visibleSubmenu: this.visibleSubmenu,
              routeTo: this.visibleSubmenu
                ? null
                : {
                    name: 'drawer-ticket',
                    params: { drawerId: 'client' },
                    query: {
                      mailbox: route.query.mailbox,
                      status: route.query.status,
                      main: 'chat,drawer',
                    },
                  },
              showResolveTicket: this.ticketSelected?.status === 'active', // mostrara el boton para resolver ticket
              isChat: true,
              showFavorite: this.ticketSelected?.settings.starred,
            }
          } else {
            const newRoute = {
              isBack: true,
              routeBack: {
                name: this.$route.meta.is_workspace
                  ? 'drawer-ticket'
                  : 'drawer-remote-ticket',
                params: { drawerId: 'client' },
                query: { main: 'chat' },
              },
            }

            switch (route.params.drawerId) {
              case 'client':
                newRoute.name = 'Perfil del cliente'
                break
              case 'old-tickets':
                newRoute.name = 'Tickets anteriores'
                break
              case 'custom-fields':
                newRoute.name = 'Campos customizados'
                break
              case 'files':
                newRoute.name = 'Archivos'
                break
              case 'products':
                newRoute.name = 'Productos'
                break
            }

            return newRoute
          }
        } else return { empty: true }
      }
    },
  },
  methods: {
    ...mapActions(['setTicketMailbox']),
    /**
     * Selecciona el menu
     * @param {Object} route
     * @param {String} route.name
     * @param {String} route.params
     * @param {String} route.query
     */
    handleSelectTitle(route) {
      if (!route) return
      else this.$router.push(route)
    },
    /**
     * Muestra el perfil del usuario
     */
    handleShowDrawerProfile() {
      this.drawerProfile.visible = true
    },
    /**
     * Resuelve el ticket actual
     * @param {*} args
     * @param {String} args.comment
     */
    resolveTicket({ comment }) {
      const idTicket = this.ticketSelected._id
      const mailbox = this.mailboxes.find((box) => box.end)
      this.setTicketMailbox({
        mailbox,
        idTicket,
        end: true,
        comment,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
$height-navbar: 56px
$padding-right: 12px
$padding-left: 20px
.navbar
  background-color: $gray_dark_900
  color: $white_000
  padding: 0px $padding-right 0px $padding-left
  height: $height-navbar
  z-index: 1000
  &__title
    color: $white_000
    &--underline
      text-decoration: underline
  &__item
    height: 100%
    color: $white_000
    flex-grow: 1
    @include flex(column, center, flex-start)
  &--fixed
    position: fixed
    width: 100%
  &__container-icon
    height: $height-navbar
    margin-left: -$padding-left
    width: 52px
    +flex(row, center, center)
  &__icon
    font-size: 24px
    color: $white_000
    &--star
      color: $yellow_6
  &__button
    min-width: 120px
    @include xs
      min-width: 112px
    @include sm
      min-width: 120px
    @include md
      min-width: 176px
</style>
