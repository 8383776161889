<template>
  <div
    class="node-options"
    :class="{
      'node-options--selected': selected,
      'cursor-auto': disabled,
      'cursor-pointer': !disabled,
    }"
    @click="handleSelect"
  >
    <div class="node-options__header">
      <div class="node-options__title">
        <a-icon type="profile" class="node-options__icon" />
        <h5 class="heading-h7">{{ title }}</h5>
      </div>
      <div class="node-options__actions">
        <a
          @click.stop="() => !disabled && $emit('onAddOption')"
          :disabled="disabled"
          >Agregar opción</a
        >
        <a-divider type="vertical" />
        <a
          :class="{ 'node-options__action--danger': !disabled }"
          @click.stop="() => !disabled && $emit('onDelete')"
          :disabled="disabled"
          >Eliminar</a
        >
      </div>
    </div>
    <p class="body-2 node-options__description mrg-bottom-0">
      {{ description }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'OptionsNode',
  props: {
    title: { type: String, default: 'Opciones múltiples' },
    description: { type: String, default: 'Descripcion' },
    selected: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    handleSelect() {
      !this.disabled && this.$emit('onSelect')
    },
  },
}
</script>
<style lang="sass" scoped>
.node-options
  border: 1px solid $gray_dark_100
  border-radius: 4px
  margin-bottom: 8px
  padding: 12px
  &__header
    display: flex
    justify-content: space-between
  &__title
    display: flex
  &__description
    text-align: left
    color: $gray_dark_800
  &__icon
    font-size: 20px
    color: $blue_6
    margin-right: 8px
  &__action
    &--danger
      color: $red_6
  &--selected
    border: 1px solid $blue_6
    background-color: $blue_1
  &:hover
    border: 1px solid $blue_6
</style>
