<template>
  <div>
    <!-- Formulario -->
    <a-form layout="vertical" :form="form" @submit.prevent="handleSubmit">
      <div class="form__content">
        <a-tabs
          size="small"
          v-model="tabDefault"
          :tabBarGutter="20"
          :tabBarStyle="{ margin: '12px 24px' }"
        >
          <a-tab-pane tab="General" key="general">
            <div style="padding: 0px 24px 12px 24px">
              <a-alert
                v-if="error.visible"
                class="mrg-bottom-8"
                type="error"
                :message="error.message"
                show-icon
              />
              <!-- Nombre de la Cola -->
              <a-form-item>
                <h6 class="semibold-14 text--left mrg-bottom-4">
                  Nombre de cola
                </h6>
                <p class="regular-14 mrg-bottom-8">
                  El nombre de una cola debe ser identificativo y único
                </p>
                <a-input
                  :maxLength="70"
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Por favor rellene el campo',
                        },
                        {
                          whitespace: true,
                          message: 'No se admiten espacios en blanco.',
                        },
                      ],
                    },
                  ]"
                  placeholder="Escribe aquí..."
                  :disabled="record.default"
                />
              </a-form-item>
              <!-- Asignación de agentes -->
              <a-form-item>
                <h6 class="semibold-14 text--left mrg-bottom-4">
                  Agentes (Opcional)
                </h6>
                <p class="regular-14 mrg-bottom-8">
                  Recuerda que los agentes serán el contacto directo con tus
                  clientes.
                </p>
                <a-select
                  v-decorator="['agents']"
                  mode="multiple"
                  placeholder="Seleccione los agentes..."
                  style="width: 100%"
                  @change="handleChangeAgents"
                  class="select-multiple"
                >
                  <!--Debería considerarse el userId - MEJORAR-->
                  <a-select-option
                    v-for="agent in agents"
                    :key="`${agent.names}-${agent._id}`"
                  >
                    {{ agent.names }} {{ agent.surnames }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <!-- Mensaje de Bienvenida -->
              <a-switch
                v-if="record.default"
                v-model="switchValue"
                class="switch-form"
                size="small"
              />
              <a-form-item
                v-if="record.default"
                label="Mensaje predeterminado de cola"
              >
                <a-input
                  v-decorator="['message']"
                  type="textarea"
                  placeholder="Escribe aquí..."
                  :disabled="!switchValue"
                  :auto-size="{ minRows: 3, maxRows: 3 }"
                />
              </a-form-item>
            </div>
          </a-tab-pane>
          <a-tab-pane tab="Canales" key="channels">
            <div style="padding: 0px 0px 12px 24px">
              <a-alert
                v-if="error.visible"
                class="mrg-bottom-8"
                type="error"
                :message="error.message"
                show-icon
              />
              <list-picker-channels
                ref="list_picker_channels"
                :default-values="defaultChannelsToPicker"
                :disabled="disabledSelectChannels"
              />
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
      <!-- Botones de acción -->
      <div class="modal__footer display-flex justify-content--end">
        <a-button style="width: 112px; margin-right: 8px" @click="handleCancel">
          Cancelar
        </a-button>
        <a-button
          type="primary"
          html-type="submit"
          :loading="loadingBotonCreate"
          :disabled="disabledBtnCreate"
          style="min-width: 112px"
          >{{ textBtn }}</a-button
        >
      </div>
    </a-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ListPickerChannels from '@/app/shared/components/organisms/ListPickerChannels'
import compareMixin from '@/app/shared/mixins/compare'

export default {
  name: 'FormEdit',
  props: {
    record: {
      type: Object,
      required: true,
    },
    sectionLine: {
      type: Object,
    },
    tabDefault: {
      type: String,
      default: 'general',
    },
  },
  components: {
    ListPickerChannels,
  },
  data: () => ({
    lastValueSupervisors: [], // Asigancion final de los supervisores
    lastValueAgents: [], // Asignación final de los agentes
    switchValue: true,
    loadingBotonCreate: false, // valor de loading en boton de crear
    disabledBtnCreate: false, // desabilita el boton de crear,
    textBtn: 'Actualizar',
    usersDesassigned: [], // Usuarios (agentes y supervisores) que se están desasignando
    channelsSelected: [], // canales seleccioandos
    channelsDesasigned: [], // canales desasignados
    usersAssigned: { agents: [], supervisors: [] }, // usuarios nuevos asignados
    error: { visible: false, message: '' },
    defaultChannelsToPicker: [], // canales por defecto para la lista de canales
  }),
  mixins: [compareMixin],
  watch: {
    record() {
      // observa cuando record de cola cambia
      this.setValue() // se ejecuta cda vez q record cambia
    },
    lastValueSupervisors(newVal) {
      // observa si el valor de supervisores en form cambia
      if (this.isAllowedFor(this.profile.type, ['admin']))
        this.prepareDesaignedUsers(newVal ? newVal : [], 'supervisor')
      else return
    },
    lastValueAgents(newVal) {
      // observa si el valor de agetes en form cambia
      this.prepareDesaignedUsers(newVal ? newVal : [], 'agent')
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal' })
  },
  mounted() {
    // se ejecuta para la primera vez
    this.setValue()
    this.getFieldValueForm() // obtiene el valor del form
  },
  computed: {
    ...mapGetters([
      'accounts',
      'lines',
      'profile',
      'agentsAccounts',
      'supervisorsAccounts',
      'company',
      'roles',
      'simple_company_channels',
    ]),
    agents() {
      return this.agentsAccounts
    },
    supervisors() {
      return this.supervisorsAccounts
    },

    agentsPerLine() {
      const result = this.record.agents
      // Debería considerarse el userId - MEJORAR
      const joinData = result.map((obj) => `${obj.names}-${obj._id}`)
      return joinData
    },
    /**
     * Deshabilita eliminar canales
     * @return {Boolean}
     */
    disabledSelectChannels() {
      return this.record.default
    },
  },
  methods: {
    ...mapActions(['updateLine', 'updateLineWelcome', 'getProfileRemote']), // ##-> getProfileRemote(userId), trae todo el objeto completo de un perfil, usarlo para las cuentas que no se encuentran por la paginación

    handleChangeSupervisors(value) {
      this.lastValueSupervisors = value
    },
    handleChangeAgents(value) {
      this.lastValueAgents = value
    },
    /**
     * Envía los datos al formulario de editar
     */
    setValue() {
      setTimeout(() => {
        this.switchValue = this.record.settings.automatic.welcome.active
        this.form.setFieldsValue({
          name: this.record.name,
          agents: this.agentsPerLine,
          message: this.record.settings.automatic.welcome.message,
        })
        this.setDefaultChannelsToPicker()
      }, 100)
    },
    /**
     * Envía los datos del formulario
     */
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.disabledBtnCreate = true
          this.loadingBotonCreate = true
          this.loadingBotonCreate
            ? (this.textBtn = 'Actualizando')
            : 'Actualizar'
          // Obtener agentes y suspervisores
          const mapAgentsId = values.agents.map((ele) =>
            ele.slice(ele.indexOf('-') + 1)
          )
          const listChannels = this.$refs.list_picker_channels
            ?.handleGetList()
            .map((channel) => channel.channelCompanyId)
          // si no hay listChannels, entonces tomara en cuenta a los canales de record
          this.setChannelSelected(
            listChannels ||
              [...this.record.channels_companies].map(
                (channelCompany) => channelCompany._id
              )
          )
          this.sectionLine.createoredit.agentsId = mapAgentsId
          this.sectionLine.createoredit.lineId = this.record._id
          this.sectionLine.createoredit._id = this.record._id
          this.sectionLine.createoredit.name = values.name
          this.sectionLine.createoredit.channels = this.channelsSelected

          // Actualiza la cola general
          if (this.record.default) {
            const welcome = {
              active: this.switchValue,
              message: values.message,
            }
            this.sectionLine.createoredit.welcome = welcome
            if (
              this.usersDesassigned.length === 0 &&
              this.channelsDesasigned.length === 0
            ) {
              this.updateLineWelcome({ self: this })
            } else this.handleModal(this.record.editable)
          }
          // Actualiza cualquier otra cola
          else {
            if (
              this.usersDesassigned.length === 0 &&
              this.channelsDesasigned.length === 0
            )
              this.updateLine({ self: this })
            else this.handleModal(this.record.editable)
          }
        }
      })
    },
    /**
     * Alerta para el cierre del modal
     */
    handleCancel() {
      this.$emit('cancelModal')
    },
    /**
     * Obtiene los datos del formulario
     */
    getFieldValueForm() {
      setTimeout(() => {
        this.lastValueSupervisors = this.form.getFieldValue('supervisors')
        this.lastValueAgents = this.form.getFieldValue('agents')
      }, 110)
    },
    /**
     * Resetea los valores despues de cerrar el modal
     */
    handleClose() {
      this.switchValue = this.record.active
      this.usersDesassigned = []
      this.channelsDesasigned = []
      this.usersAssigned = { agents: [], supervisors: [] } // usuarios nuevos asignados
      this.setValue()
      this.error = { visible: false, message: '' }
      this.tabDefault = 'general'
    },
    /**
     * Prepara la lista de usuarios que se desasignarán
     * @param {Array} newVals Lista de usuarios actualizados del select multiple
     * @param {String} type Tipo de usuario que se agregará a la lista
     */
    prepareDesaignedUsers(newVals, type) {
      let desassignedUser = [...this.usersDesassigned]
      let originalUsers = [...this.record[`${type}s`]]

      // Debería considerarse el userId - MEJORAR
      let usersAssigned = newVals
      // Formatea para guardar los datos correctos
      usersAssigned = usersAssigned.map((assigned) => {
        const assignedId = assigned.slice(assigned.indexOf('-') + 1)
        let accountFound = this.agentsAccounts.find(
          (ac) => ac._id === assignedId
        )
        if (!accountFound) {
          accountFound = this.supervisorsAccounts.find(
            (ac) => ac._id === assignedId
          )
        }
        assigned = accountFound
        return assigned
      })
      this.usersAssigned[`${type}s`] = usersAssigned
      // Solo recupera a los usuarios que se están desasignando para guardarlos en un arreglo
      desassignedUser = originalUsers.filter((original) => {
        return !newVals.some(
          (newVal) => newVal === `${original.names}-${original._id}`
        )
      })
      this.usersDesassigned = desassignedUser

      // Preparación de desasignación cargo de agentes
      if (this.isAllowedFor(type, ['agent'])) {
        let agentsDessasigned = this.usersDesassigned.filter(
          (desasigned) =>
            this.isAllowedFor(desasigned.type, ['supervisor', 'admin']) // en caso esté con el modo agente
        )
        desassignedUser = agentsDessasigned.concat(desassignedUser)
      }
      // Preparación de desasignación cargo de supervisores
      if (this.isAllowedFor(type, ['supervisor'])) {
        let supervisorsDessasigned = this.usersDesassigned.filter(
          (desasigned) => this.isAllowedFor(desasigned.type, ['agent'])
        )
        desassignedUser = supervisorsDessasigned.concat(desassignedUser)
      }

      // Elimina elementos repetidos
      for (var i = 0; i < desassignedUser.length; ++i) {
        for (var j = i + 1; j < desassignedUser.length; ++j) {
          if (desassignedUser[i].key === desassignedUser[j].key)
            desassignedUser.splice(j--, 1)
        }
      }

      // Formatea para guardar los datos correctos
      desassignedUser = desassignedUser.map((desasigned) => {
        desasigned.key = `${desasigned.names}-${desasigned._id}`
        let accountFound = this.agentsAccounts.find(
          (ac) => ac._id === desasigned._id
        )
        if (!accountFound) {
          accountFound = this.supervisorsAccounts.find(
            (ac) => ac._id === desasigned._id
          )
        }
        /* if (accountFound)  */
        desasigned.type = accountFound.type
        return desasigned
      })
      this.usersDesassigned = desassignedUser
    },

    /**
     * Modal de warning que sale cuando edita
     * @param {Object} line
     */
    handleModal(line) {
      let self = this
      // pinta el texto warning
      setTimeout(() => {
        const containTextWarning = document.getElementById(
          'text-warning-desasigned'
        )
        const arrAgents = this.usersDesassigned.filter(
          (obj) => obj.type === 'agent'
        )
        const arrAdminAgents = this.usersDesassigned.filter(
          (obj) => obj.type === 'admin_agent'
        )
        // si solo hay canales desasignados
        if (
          this.channelsDesasigned.length &&
          !arrAgents.length &&
          !arrAdminAgents.length
        )
          containTextWarning.innerText =
            'Recuerda que tus tickets activos en toda la plataforma asociados a estos canales serán transferidos a la cola general.'
        // si solo hay canales desasignados y administradores agentes desasignados
        else if (
          this.channelsDesasigned.length &&
          arrAdminAgents.length &&
          !arrAgents.length
        )
          containTextWarning.innerText =
            'Recuerda que tus tickets activos en toda la plataforma asociados a estos canales serán transferidos a la cola general y los tickets pendientes en los agentes serán repartidos.'
        // si solo hay adminitradores agentes desasignados y agentes
        else if (
          (!this.channelsDesasigned.length &&
            arrAdminAgents.length &&
            arrAgents.length) ||
          (!this.channelsDesasigned.length &&
            arrAdminAgents.length &&
            !arrAgents.length)
        )
          containTextWarning.innerText =
            'Recuerda que los tickets pendientes en tus agentes serán repartidos'
        // si solo hay agentes desasignados
        else if (
          !this.channelsDesasigned.length &&
          !arrAdminAgents.length &&
          arrAgents.length
        )
          containTextWarning.innerText =
            'Recuerda que tus agentes no podrán tener acceso a esta cola y sus tickets pendientes serán repartidos.'
        else
          containTextWarning.innerText =
            'Recuerda que tus tickets activos en toda la plataforma asociados a estos canales serán transferidos a la cola general, los agentes no podrán tener acceso a esta cola y sus tickets pendientes serán repartidos.'
      }, 300)

      setTimeout(() => {
        const containListChannels = document.getElementById(
          'contain-list-channels-desasigned'
        )
        if (this.channelsDesasigned.length === 0)
          containListChannels.innerHTML = ''
        else {
          let html = `<div><h5 class="heading-h7" style="color: #262A31">Canales</h5>`
          this.channelsDesasigned.forEach((channelCompanyId) => {
            const channel = this.simple_company_channels.find(
              (simpleChannel) => simpleChannel._id == channelCompanyId
            )
            if (!channel) return console.error('Este canal no existe')
            html += `<p class="form-edit-name-channel" style="margin-bottom: 6px">${channel.channel} - ${channel.title}</p>`
          })
          html += `</div>`
          containListChannels.innerHTML = html
        }
      }, 300)
      setTimeout(() => {
        const containListAgents = document.getElementById('contain-list-agents')
        const arrAgents = this.usersDesassigned.filter((obj) =>
          this.isAllowedFor(obj.type, ['agent'])
        )
        if (arrAgents.length === 0) containListAgents.innerHTML = ''
        else {
          let html = `<div><h5 class="heading-h7" style="color: #262A31">Agentes</h5>`
          arrAgents.forEach((elem) => {
            html += `<p style="margin-bottom: 6px">- ${elem.names}</p>`
          })
          html += `</div>`
          containListAgents.innerHTML = html
        }
      }, 300)

      this.$confirm({
        title: `¿Estás segur@ de aplicar estos cambios en  esta cola?`,
        content: (
          <div>
            <p id="text-warning-desasigned"></p>
            <div id="contain-list-channels-desasigned"></div>
            <div id="contain-list-agents">
              <div style="text-align: center">
                <a-spin />
              </div>
            </div>
          </div>
        ),
        okText: 'Retirar',
        cancelText: 'Cancelar',
        centered: true,
        onOk() {
          if (line === true) {
            self.updateLine({ self: self })
          } else {
            self.updateLineWelcome({ self: self })
          }
        },
        onCancel() {
          self.disabledBtnCreate = false
          self.loadingBotonCreate = false
          self.textBtn = 'Actualizar'
          self.setDefaultChannelsToPicker()
        },
        class: 'test',
      })
    },
    // evento de click en card small channel
    setChannelSelected(idChannels) {
      const channelsCompanies = [...this.record.channels_companies].map(
        (channelCompany) => channelCompany._id
      )

      const channelsDesasigned = channelsCompanies.filter(
        (channelCompany) => !idChannels.includes(channelCompany)
      )

      const channelsAssigned = idChannels

      this.channelsSelected = channelsAssigned
      this.channelsDesasigned = channelsDesasigned
    },
    /**
     * Setea canales por defecto
     */
    setDefaultChannelsToPicker() {
      if (!this.record || !this.record.channels_companies)
        this.defaultChannelsToPicker = []
      this.defaultChannelsToPicker = [...this.record.channels_companies].map(
        (channel) => {
          const newKey = `${new Date().getTime()}-${channel._id}`
          return {
            channel: channel.channel,
            channelCompanyId: channel._id,
            key: newKey,
          }
        }
      )
    },
  },
}
</script>

<style lang="sass" scoped>
.gray-dark-900
  color: $gray_dark_900
.switch-form
  position: absolute
  left: 16.6em
  transform: translateY(3px)
  z-index: 1
.contain-channels
  margin-bottom: 12px
  display: flex
  flex-direction: row
  flex-wrap: wrap

.modal__footer
  border-top: 1px solid $gray_light_200
  padding: 12px
.form__content
  height: 36em
</style>
<style lang="sass">
.form-edit-name-channel
  &::first-letter
    text-transform: uppercase
</style>
