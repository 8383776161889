const initialState = () => ({
  company: null,
  companies: [],
  maxMailboxes: 5, // Máxima cantidad de Buzones a aceptar
  sandboxChannels: [], // Canales de la sandbox disponibles para usar
  notificationsPhones: {},
  industries: [
    'Banca',
    'Comidas y bebidas',
    'Contact Center',
    'Ecommerce',
    'Educación',
    'Entretenimiento',
    'Fintech',
    'Gobierno',
    'Hoteles',
    'Informática',
    'Mobiliario',
    'Retail',
    'Ropa y moda',
    'Seguros',
    'Servicios Financieros',
    'Supermercados',
    'Software',
    'Turismo',
    'Otros',
  ],
  languages: ['Español', 'Portugués', 'Francés', 'Inglés'],
  company_wallet: null,
})

export default initialState
