import Channels from '@/app/channels/views/Channels'
import Channel from '@/app/channels/views/Channel'
import SectionAllChannels from '@/app/channels/components/organisms/SectionAllChannels'
import SectionMyChannels from '@/app/channels/components/organisms/SectionMyChannels'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/channels',
    name: 'channels',
    component: Channels,
    meta: {
      requiresAuth: true,
      requiresType: ['admin'],
      title: `Canales | ${VUE_APP_NAME}`,
    },
    children: [
      {
        path: 'default',
        name: 'all-channels',
        component: SectionAllChannels,
        meta: {
          requiresAuth: true,
          requiresType: ['admin'],
        },
      },
      {
        path: 'my-channels',
        name: 'my-channels',
        component: SectionMyChannels,
        meta: {
          requiresAuth: true,
          requiresType: ['admin'],
        },
      },
    ],
    redirect: {
      name: 'all-channels',
    },
  },
  {
    path: '/channels/:channel',
    name: 'channel',
    component: Channel,
    meta: {
      requiresAuth: true,
      requiresType: ['admin'],
      title: `Gestor del Canal | ${VUE_APP_NAME}`,
    },
  },
]
