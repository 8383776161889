<template>
  <div class="contain">
    <a-modal
      :visible="visible"
      :title="`Editar cuenta de ${account.names}`"
      width="50em"
      class="contain_modal"
      :afterClose="afterClose"
      :maskClosable="false"
      centered
      :footer="null"
      :bodyStyle="{ padding: '0px' }"
      @cancel="$emit('onClose')"
    >
      <FormBaseAccount
        width="100%"
        @onSubmit="handleUpdateAccount"
        :countries="countries"
        okText="Guardar cambios"
        cancelText="Cancelar"
        :requirePassword="false"
        :showFieldStatusUser="true"
        :lines="lines"
        :account="account"
        :disabledFieldUserType="true"
        :disabledFieldStatusUser="false"
        :assignedLines="assignedLines"
        :payment="account.payment"
        :active="account.active"
        passwordLabel="Nueva contraseña"
        @onCancel="handleCloseModal"
        ref="form_base"
        :loading="loadingForm"
        @onChangeActive="handleChangeActive"
        @onDelete="
          handleDeleteAccount(
            account.typeUser,
            account.userId,
            `${account.names} ${account.surnames}`,
            account.active
          )
        "
        :show-delete="true"
      />
    </a-modal>
  </div>
</template>

<script>
import FormBaseAccount from '@/app/accounts/components/forms/FormBaseAccount'
import compareMixin from '@/app/shared/mixins/compare'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ModalUpdateAccount',
  mixins: [compareMixin],
  components: {
    FormBaseAccount,
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    account: {
      type: Object,
      required: false,
      default: () => {},
    },
    loadingForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    assignedLines: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['profile', 'countries', 'roles', 'lines']),
  },
  methods: {
    ...mapActions(['deleteAccount']),

    /**Cierra el modal */
    handleCloseModal() {
      this.$emit('onClose')
    },
    /**Crea una cuenta */
    handleUpdateAccount(values) {
      this.$emit('onSubmit', values, 'update')
    },
    // se ejecuta despues q el modal es cerrado
    afterClose() {
      this.visible = false
      this.$emit('onClose')
      this.$refs.form_base.handleResetForm()
    },
    handleChangeActive(active, fullname) {
      this.$emit('onChangeActive', active, fullname, this.account._id)
    },
    handleDeleteAccount(typeUser, userId, fullname, active) {
      this.$emit('onDelete', typeUser, userId, fullname, active)
    },
  },
}
</script>
