import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_TICKETS_TO_MANAGE_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },
  /**
   * Setea los tickets
   * @param {*} state
   * @param {Object} tickets
   */
  SET_TICKETS_TO_MANAGE(state, tickets) {
    if (tickets.data) {
      if (Array.isArray(tickets.data)) {
        tickets.data.forEach((ticket) => {
          if (!ticket.agent)
            ticket.agent = { names: 'Sin agente', surnames: '' }
          if (!ticket.messages) ticket.messages = []
        })
      }
    }

    state.tickets = tickets
  },
  SET_AGENT_IN_TICKET_TO_MANAGE(state, { agent, index }) {
    state.tickets.data[index].agent = agent
  },
  SET_USERS_IN_TICKET_TO_MANAGE(state, { users, index }) {
    state.tickets.data[index].users = users
  },
  SET_STATUS_IN_TICKET_TO_MANAGE(
    state,
    { status, subStatus, index, users, pendings }
  ) {
    state.tickets.data[index].users = users
    state.tickets.data[index].status = status
    state.tickets.data[index].pendings = pendings
    state.tickets.data[index].subStatus = subStatus || status
  },

  SET_TAGS_IN_TICKET_TO_MANAGE(state, { tags, index }) {
    state.tickets.data[index].settings.tags = tags
  },

  SET_LINE_IN_TICKET_TO_MANAGE(state, { line, index }) {
    state.tickets.data[index].company.lineName = line.name
    state.tickets.data[index].company.lineId = line._id
  },

  /**
   * Actualiza el ticket en el store
   */
  UPDATE_STORED_TICKET(state, { ticket, index }) {
    state.tickets.data[index].settings.starred = ticket.settings.starred
    state.tickets.data[index].settings.tags = ticket.settings.tags
    state.tickets.data[index].fields = ticket.fields
  },

  REMOVE_STORED_TICKET(state, index) {
    state.tickets.data.splice(index, 1)
    state.tickets.total--
  },

  /**
   * @param {*} state
   * @param {Boolean} fetchedFromOtherModule
   */
  SET_FETCHED_FROM_OTHER_MODULE(state, fetchedFromOtherModule) {
    state.fetchedFromOtherModule = fetchedFromOtherModule
  },
  /**
   * @param {*} state
   * @param {String[]} clientsIdFilterTicket
   */
  SET_CLIENTSID_FILTER_TICKET(state, clientsIdFilterTicket) {
    state.clientsIdFilterTicket = clientsIdFilterTicket
  },
  /**
   * Setea el rango de fecha para los tickets
   * @param {*} state
   * @param {Object[]} rangeFilterTickets
   */
  SET_RANGE_FILTER_TICKETS(state, rangeFilterTickets) {
    state.rangeFilterTickets = rangeFilterTickets
  },
}

export default mutations
