var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tree"},[(_vm.dataSource.drawer.question.type === 'message')?_c('message-node',{staticClass:"tree__width",class:{ tree__connection: _vm.dataSource.parentNodeId },attrs:{"description":_vm.dataSource.drawer.question.content,"action":_vm.formattedAction(
        _vm.dataSource.drawer.action.type,
        _vm.dataSource.drawer.action.nodeType,
        _vm.dataSource.drawer.action.wait
      ),"selected":_vm.selectedNodeId === _vm.dataSource._id ? true : false,"showInfo":_vm.dataSource.drawer.action.type === 'none' ? true : false,"disabled":_vm.disabledActions},on:{"onDelete":function($event){return _vm.handleShowConfirmToDeleteMessage(
        _vm.dataSource._id,
        _vm.dataSource.parentNodeId
      )},"onSelect":function($event){return _vm.handleOpenDrawer(_vm.dataSource)}}}):_vm._e(),(_vm.dataSource.drawer.question.type === 'options')?[_c('options-node',{staticClass:"tree__width",class:{ tree__connection: _vm.dataSource.parentNodeId },attrs:{"description":_vm.dataSource.drawer.question.content,"selected":_vm.selectedNodeId === _vm.dataSource._id ? true : false,"disabled":_vm.disabledActions},on:{"onDelete":function($event){return _vm.handleShowConfirmToDeleteOptions(
          _vm.dataSource._id,
          _vm.dataSource.parentNodeId
        )},"onAddOption":function($event){return _vm.handleAddOption(_vm.dataSource._id)},"onSelect":function($event){return _vm.handleOpenDrawer(_vm.dataSource)}}}),_c('div',{staticClass:"tree__options"},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,class:{
          'tree__option__connection-brothers': _vm.options[index + 1],
          tree__option__first: index === 0 ? true : false,
        }},[_c('option-node',{staticClass:"tree__width tree__option",class:{ tree__option__connection: option.parentNodeId },attrs:{"description":option.text,"order":option.order,"quantity-orders":_vm.options.length,"data-source":option,"selected":_vm.selectedNodeId === option.idOption ? true : false,"show-delete":option.order === 1 ? false : true,"action":_vm.formattedAction(
              option.action.type,
              option.action.nodeType,
              option.action.wait
            ),"isAllowedChange":_vm.isSavedNode,"disabled":_vm.disabledActions},on:{"onDelete":function($event){return _vm.handleShowConfirmToDeleteOption(
              option.parentNodeId,
              option.idOption
            )},"onSelect":function($event){return _vm.handleOpenDrawer(option)},"onChangeOrder":_vm.handleChangeOrderOption}}),(option.child)?_c('div',{staticClass:"tree__child"},[_c('tree',{attrs:{"data-source":option.child,"disabled-actions":_vm.disabledActions},on:{"onOpenDrawer":_vm.handleOpenDrawer}})],1):_vm._e()],1)}),0)]:_vm._e(),(_vm.dataSource.child)?_c('div',{staticClass:"tree__child"},[_c('tree',{attrs:{"data-source":_vm.dataSource.child,"disabled-actions":_vm.disabledActions},on:{"onOpenDrawer":_vm.handleOpenDrawer}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }