<template>
  <a-modal
    :visible="visible"
    :bodyStyle="styles"
    @cancel="$emit('onClose')"
    width="min-content"
    centered
  >
    <h5 class="heading-h3">{{ title }}</h5>
    <p class="body-2">{{ description }}</p>
    <video-player
      class="modal__video"
      :picture="videoPicture"
      :video="video"
      @onOpenVideo="visible = false"
      @onCloseVideo="visible = true"
    />
    <p class="body-2 modal__paragraph--bottom mrg-bottom-0">
      <modal-sandbox
        v-if="actionBottom && actionBottom === 'openSandbox'"
        :isLink="true"
        nameButton="Click aquí"
        :underline="false"
        style="display: inline-block"
      />
      <a v-if="paragraphBottom" @click="handleAction"> Click aquí</a>
      {{ paragraphBottom }}
    </p>
    <div slot="footer" class="modal__footer">
      <p class="body-2 modal__paragraph--redirection mrg-bottom-0">
        {{ paragraphFooter }}, ingresa
        <a :href="linkFooter" target="_blank" rel="noopener noreferrer">
          aquí
        </a>
        para leer nuestro docs.
      </p>

      <!--Boton de comenzar-->
      <modal-sandbox
        v-if="actionBottom && actionBottom === 'openSandbox'"
        nameButton="Comenzar"
        :underline="false"
        :isButton="true"
      />
      <a-button v-else class="" type="primary" @click="handleAction"
        >Comenzar</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import VideoPlayer from '@/app/shared/components/VideoPlayer'
import ModalSandbox from '@/app/channels/components/whatsapp/modals/ModalSandbox.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'TutoModal',
  props: {
    visible: { type: Boolean, default: false },
    title: { type: String, default: 'Route TIcker' },
    description: { type: String, default: 'This is description Ticker' },
    video: { type: String, default: null },
    videoPicture: { type: String, default: null },
    paragraphBottom: {
      type: String,
      default: 'Para saber mas revisa nuestro blog haciendo click',
    },
    paragraphFooter: {
      type: String,
      default: 'Para saber mas revisa nuestro blog haciendo click',
    },
    actionBottom: {
      type: String,
      default: null,
    },
    linkFooter: {
      type: String,
      default: null,
    },
  },
  components: { VideoPlayer, ModalSandbox },
  data: () => ({
    styles: {
      padding: '40px',
    },
  }),
  methods: {
    ...mapMutations(['SET_STEPS_WBA_MODAL', 'SET_MODAL_CREATE_CHATBOT']),

    handleAction() {
      switch (this.actionBottom) {
        case 'openStepsWBA':
          {
            this.SET_STEPS_WBA_MODAL(true)
          }
          break
        case 'openCreateChatbot':
          {
            this.SET_MODAL_CREATE_CHATBOT(true)
          }
          break
      }
      this.$emit('onClose')
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  &__video
    margin-top: 24px
  &__paragraph--bottom
    margin-top: 16px
    text-align: center
  &__footer
    display: flex
    justify-content: space-between
</style>
