<template>
  <auth-template :show-social-network="true">
    <a-row slot="body" type="flex" justify="center" class="full-width">
      <a-col
        class="container__form"
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 12 }"
        :lg="{ span: 8 }"
        :xl="{ span: 8 }"
        :xxl="{ span: 7 }"
      >
        <LoginForm />
        <template v-if="enableSignup">
          <div
            v-if="!active_breakpoint.is_mobile"
            class="login-form-to-register"
          >
            <p class="body-2 mrg-right-8 mrg-bottom-0">
              ¿No tienes una cuenta?
            </p>
            <router-link :to="{ name: 'signup', query: { license: 'free' } }"
              >Regístrate</router-link
            >
          </div>
          <div v-else class="login-form-to-register">
            <router-link :to="{ name: 'signup', query: { license: 'free' } }"
              >Crea una nueva cuenta</router-link
            >
          </div>
        </template>
      </a-col>
    </a-row>
  </auth-template>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginForm from '@/app/auth/components/forms/LoginForm'
import AuthTemplate from '@/app/shared/components/templates/AuthTemplate'

export default {
  name: 'LoginTemplate',
  components: {
    LoginForm,
    AuthTemplate,
  },
  data: () => ({
    enableSignup: process.env.VUE_APP_ALLOW_SIGNUP === 'true' ? true : false,
  }),
  computed: {
    ...mapGetters(['active_breakpoint']),
  },
}
</script>

<style lang="sass" scoped>

.login-form-to-register
  margin-top: 20px
  display: flex
  flex-direction: row
  justify-content: center
  color: $gray_dark_800
.container__form
  z-index: 2
</style>
