<template>
  <div class="section-menu-billing">
    <a-row>
      <header class="template__header">
        <h5 class="heading-h5 mrg-bottom-0">{{ title }}</h5>
        <a-tooltip placement="right">
          <template slot="title">
            <a
              href="https://www.securitec.pe/docs/ticker/precios-y-pagos/esquema-costos-ticker/"
              target="_blank"
              rel="noopener noreferrer"
              >Click aquí </a
            >para conocer mejor nuestro esquema de costos fijos y variables.
          </template>
          <a-icon type="info-circle" class="template__icon" />
        </a-tooltip>
      </header>
    </a-row>
    <a-row>
      <div class="details">
        <div class="cards">
          <CardBilling
            class="card-billing"
            v-for="billingCard in billingCards"
            :key="billingCard.key"
            :type="billingCard.type"
            :secondaryText="billingCard.secondaryText"
            :primaryText="billingCard.primaryText"
            :description="billingCard.description"
            :secondaryDescription="billingCard.secondaryDescription"
            :badge="billingCard.badge"
            :price="`${billingCard.price}`"
            :priceDescription="billingCard.priceDescription"
            :note="billingCard.note"
            :textButton="billingCard.textButton"
            :action="billingCard.action"
          />
        </div>
        <template>
          <svg
            width="252"
            height="136"
            viewBox="0 0 252 136"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M93.5771 131.025C93.7703 130.823 94.0902 130.817 94.2917 131.01C96.2766 132.913 98.8915 134.006 101.678 134.036L101.792 134.036H128.982C129.261 134.036 129.488 134.263 129.488 134.542C129.488 134.811 129.277 135.031 129.012 135.046L128.982 135.047H101.792C98.6992 135.047 95.7901 133.846 93.5922 131.739C93.3907 131.546 93.3839 131.226 93.5771 131.025Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M101.809 0.646484L150.081 0.646607L150.15 0.648648C150.174 0.649795 150.201 0.651447 150.234 0.653715L150.339 0.66085C150.351 0.66158 150.362 0.662123 150.372 0.662512L150.394 0.663162L150.474 0.657848C150.613 0.649755 150.754 0.647159 150.986 0.646607L151.101 0.646484C166.744 0.646484 179.437 13.3391 179.437 28.9821C179.437 40.4512 172.568 50.6385 162.235 55.048L161.917 55.1815V79.2516C161.917 79.5208 161.706 79.7408 161.441 79.7561L161.411 79.757C161.142 79.757 160.922 79.5466 160.907 79.2813L160.906 79.2516V54.8412C160.906 54.6343 161.032 54.4483 161.224 54.3717C171.529 50.2667 178.426 40.2682 178.426 28.9821C178.426 13.8973 166.186 1.65727 151.101 1.65727L150.818 1.65843C150.661 1.66016 150.559 1.66416 150.449 1.67262C150.436 1.67362 150.424 1.67411 150.411 1.67411L150.341 1.67293C150.326 1.67238 150.31 1.67163 150.292 1.67063L150.128 1.65975C150.097 1.65792 150.077 1.65727 150.057 1.65727H101.809C95.8868 1.65727 91.0737 6.41604 90.9781 12.3105L90.9766 12.4895V98.3217C90.9766 98.6008 90.7503 98.8271 90.4712 98.8271C90.2021 98.8271 89.982 98.6167 89.9667 98.3514L89.9658 98.3217V12.4895C89.9658 6.02 95.163 0.75277 101.613 0.648073L101.809 0.646484Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M148.658 79.1502L161.377 79.4871V55.161C161.377 55.161 180.195 49.5006 178.662 25.6124C177.786 14.7634 168.133 3.91433 161.899 6.76136C156.424 7.85638 150.174 12.7924 148.86 36.3604C147.513 59.8948 148.658 79.1502 148.658 79.1502Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M135.451 19.0586H101.658C98.8516 19.0586 96.5869 21.3233 96.5869 24.1293V52.1617C96.5869 54.9678 98.8516 57.2325 101.658 57.2325H135.451C138.258 57.2325 140.522 54.9678 140.522 52.1617V24.1293C140.522 21.3233 138.258 19.0586 135.451 19.0586ZM101.658 20.0694H135.451C137.699 20.0694 139.511 21.8815 139.511 24.1294V52.1617C139.511 54.4095 137.699 56.2217 135.451 56.2217H101.658C99.4099 56.2217 97.5977 54.4095 97.5977 52.1617V24.1294C97.5977 21.8815 99.4099 20.0694 101.658 20.0694Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M107.604 77.3808H98.2876C97.6306 77.3808 97.1084 76.8586 97.1084 76.2016V67.273C97.1084 66.616 97.6306 66.0938 98.2876 66.0938H107.604C108.261 66.0938 108.783 66.616 108.783 67.273V76.2016C108.783 76.8586 108.261 77.3808 107.604 77.3808Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M138.837 77.3808H129.521C128.864 77.3808 128.342 76.8586 128.342 76.2016V67.273C128.342 66.616 128.864 66.0938 129.521 66.0938H138.837C139.494 66.0938 140.016 66.616 140.016 67.273V76.2016C140.016 76.8586 139.494 77.3808 138.837 77.3808Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M123.22 77.3808H113.904C113.247 77.3808 112.725 76.8586 112.725 76.2016V67.273C112.725 66.616 113.247 66.0938 113.904 66.0938H123.22C123.877 66.0938 124.399 66.616 124.399 67.273V76.2016C124.399 76.8586 123.877 77.3808 123.22 77.3808Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M107.604 91.8007H98.2876C97.6306 91.8007 97.1084 91.2785 97.1084 90.6215V81.6929C97.1084 81.0359 97.6306 80.5137 98.2876 80.5137H107.604C108.261 80.5137 108.783 81.0359 108.783 81.6929V90.6215C108.783 91.2616 108.261 91.8007 107.604 91.8007Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M123.22 91.8007H113.904C113.247 91.8007 112.725 91.2785 112.725 90.6215V81.6929C112.725 81.0359 113.247 80.5137 113.904 80.5137H123.22C123.877 80.5137 124.399 81.0359 124.399 81.6929V90.6215C124.399 91.2616 123.877 91.8007 123.22 91.8007Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M107.603 106.844H106.424C106.424 106.844 108.311 100.847 106.913 99.2299C105.514 97.6126 97.125 99.0614 97.125 98.4213V96.7535C97.125 96.0965 97.6472 95.5742 98.3042 95.5742H107.62C108.277 95.5742 108.8 96.0965 108.8 96.7535V105.682C108.783 106.305 108.26 106.844 107.603 106.844Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M123.22 106.846H113.904C113.247 106.846 112.725 106.323 112.725 105.666V96.7378C112.725 96.0808 113.247 95.5586 113.904 95.5586H123.22C123.877 95.5586 124.399 96.0808 124.399 96.7378V105.666C124.399 106.307 123.877 106.846 123.22 106.846Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M107.503 113.297C108.507 113.297 109.329 114.103 109.355 115.102L109.356 115.15V120.861C109.356 121.865 108.549 122.688 107.551 122.713L107.503 122.714H104.184C103.198 122.714 102.238 122.509 101.323 122.117C101.066 122.007 100.948 121.71 101.058 121.454C101.168 121.197 101.465 121.078 101.721 121.188C102.478 121.512 103.266 121.688 104.074 121.702L104.184 121.703H107.503C107.953 121.703 108.325 121.343 108.344 120.897L108.345 120.861V115.15C108.345 114.7 107.984 114.328 107.539 114.308L107.503 114.308H104.689C104.41 114.308 104.184 114.081 104.184 113.802C104.184 113.533 104.394 113.313 104.66 113.298L104.689 113.297H107.503Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M123.22 113.264H113.904C112.968 113.264 112.22 114.012 112.22 114.948V121.316C112.22 122.252 112.968 123.001 113.904 123.001H123.22C124.157 123.001 124.905 122.252 124.905 121.316V114.948C124.905 114.012 124.157 113.264 123.22 113.264ZM113.904 114.274H123.22C123.598 114.274 123.894 114.57 123.894 114.948V121.316C123.894 121.694 123.598 121.99 123.22 121.99H113.904C113.527 121.99 113.231 121.694 113.231 121.316V114.948C113.231 114.57 113.527 114.274 113.904 114.274Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M216.886 78.8301H131.02C128.27 78.8301 126.051 81.0555 126.051 83.7998V130.43C126.051 133.181 128.276 135.4 131.02 135.4H216.87C219.605 135.4 221.837 133.173 221.856 130.434L221.856 83.7998C221.856 81.0497 219.631 78.8301 216.886 78.8301ZM131.02 79.8409H216.886C219.073 79.8409 220.845 81.6086 220.845 83.7998V130.43C220.83 132.612 219.048 134.389 216.87 134.389H131.02C128.834 134.389 127.062 132.622 127.062 130.43V83.7998C127.062 81.6132 128.829 79.8409 131.02 79.8409Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M147.277 110.518H135.535C134.986 110.518 134.541 110.963 134.541 111.512V116.245C134.541 116.794 134.986 117.239 135.535 117.239H147.277L147.314 117.239C147.843 117.219 148.284 116.787 148.271 116.251L148.271 116.233L148.271 111.512C148.271 110.963 147.826 110.518 147.277 110.518ZM147.26 111.528V116.228H135.552V111.528H147.26Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M163.618 110.518H151.876C151.327 110.518 150.882 110.963 150.882 111.512V116.245C150.882 116.794 151.327 117.239 151.876 117.239H163.618C164.166 117.239 164.612 116.794 164.612 116.245V111.512C164.612 110.963 164.166 110.518 163.618 110.518ZM163.601 111.528V116.228H151.892V111.528H163.601Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M147.26 120.338H135.518C134.97 120.338 134.524 120.783 134.524 121.332V126.066C134.524 126.614 134.97 127.06 135.518 127.06H147.26C147.809 127.06 148.254 126.614 148.254 126.066V121.332C148.254 120.783 147.809 120.338 147.26 120.338ZM147.243 121.349V126.049H135.535V121.349H147.243Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M163.668 120.338H151.927C151.378 120.338 150.933 120.783 150.933 121.332V126.066C150.933 126.614 151.378 127.06 151.927 127.06H163.668L163.706 127.059C164.247 127.04 164.676 126.61 164.663 126.072L164.662 126.054L164.662 121.332C164.662 120.783 164.217 120.338 163.668 120.338ZM163.651 121.349V126.049H151.943V121.349H163.651Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M180.111 110.518H168.369C167.82 110.518 167.375 110.963 167.375 111.512V116.245C167.375 116.794 167.82 117.239 168.369 117.239H180.111L180.149 117.239C180.689 117.219 181.119 116.789 181.105 116.252L181.105 116.233L181.105 111.512C181.105 110.963 180.66 110.518 180.111 110.518ZM180.094 111.528V116.228H168.385V111.528H180.094Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M196.368 110.518H184.626C184.077 110.518 183.632 110.963 183.632 111.512V116.245C183.632 116.794 184.077 117.239 184.626 117.239H196.368L196.406 117.239C196.946 117.219 197.375 116.789 197.362 116.252L197.361 116.233L197.362 111.512C197.362 110.963 196.916 110.518 196.368 110.518ZM196.351 111.528V116.228H184.642V111.528H196.351Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M212.271 134.742C214.73 134.742 216.735 132.755 216.735 130.295V83.799C216.735 81.3394 214.747 79.3516 212.271 79.3516H216.954C219.413 79.3516 221.418 81.3394 221.418 83.799V130.295C221.418 132.755 219.43 134.742 216.954 134.742H212.271Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M198.76 91.4343C198.991 91.2765 199.305 91.3351 199.463 91.5653C200.393 92.9223 200.272 95.1932 199.207 96.1949C199.004 96.3862 198.684 96.3765 198.493 96.1733C198.309 95.9778 198.311 95.6746 198.492 95.4815L198.514 95.4589C199.198 94.8155 199.29 93.1005 198.629 92.137C198.472 91.9068 198.53 91.5922 198.76 91.4343Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M201.185 90.4415C201.414 90.2826 201.729 90.3398 201.888 90.5693C203.22 92.4928 203.041 95.7615 201.531 97.1729C201.327 97.3636 201.008 97.3528 200.817 97.149C200.626 96.9451 200.637 96.6253 200.841 96.4346C201.967 95.3818 202.115 92.6731 201.057 91.1447C200.898 90.9152 200.955 90.6003 201.185 90.4415Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M203.19 88.6712C203.42 88.5129 203.735 88.5709 203.893 88.8008C205.938 91.7697 205.664 96.794 203.367 98.9411C203.163 99.1318 202.843 99.121 202.653 98.9171C202.462 98.7132 202.473 98.3934 202.677 98.2028C204.59 96.4141 204.833 91.9484 203.061 89.3741C202.902 89.1442 202.96 88.8295 203.19 88.6712Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M205.111 85.9937C205.34 85.8352 205.655 85.893 205.814 86.1228C208.935 90.6488 208.515 98.3584 205.036 101.62C204.832 101.811 204.512 101.801 204.321 101.597C204.13 101.393 204.141 101.074 204.344 100.883C207.441 97.9795 207.831 90.8282 204.981 86.6967C204.823 86.4669 204.881 86.1522 205.111 85.9937Z"
              fill="#FFB039"
            />
            <rect
              x="139.781"
              y="91.127"
              width="4.61595"
              height="2.94811"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M136.075 91.127C135.569 91.127 135.165 91.5313 135.165 92.0367V102.532C135.165 103.037 135.569 103.442 136.075 103.442H138.77V91.127H136.075Z"
              fill="#FFB039"
            />
            <rect
              x="139.781"
              y="100.51"
              width="4.61595"
              height="2.94811"
              fill="#FFB039"
            />
            <rect
              x="139.831"
              y="95.0859"
              width="4.41377"
              height="4.41374"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M148.002 91.127H145.408V103.458H148.002C148.508 103.458 148.912 103.054 148.912 102.549V92.0535C148.912 91.5313 148.508 91.127 148.002 91.127Z"
              fill="#FFB039"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M70.4372 97.9004L104.975 98.0862C106.696 98.0862 108.019 99.59 107.808 101.285L107.801 101.338C107.37 104.498 106.876 107.301 106.142 110.315C105.136 114.442 103.826 118.243 102.138 121.613C100.103 125.678 97.5899 128.965 94.5362 131.314C91.0753 133.977 86.9901 135.378 82.2485 135.352L82.0953 135.35L72.8972 135.3C72.6181 135.298 72.3931 135.071 72.3946 134.792C72.3961 134.522 72.6077 134.304 72.8731 134.29L72.9028 134.289L82.1041 134.34C90.7598 134.443 96.98 129.657 101.234 121.161C102.884 117.865 104.171 114.134 105.16 110.076C105.885 107.1 106.373 104.33 106.8 101.199C106.956 100.103 106.124 99.1253 105.021 99.0975L104.973 99.0969L70.4838 98.9113C69.5602 98.8983 68.7693 99.586 68.6502 100.512C68.1182 104.481 67.4652 107.902 66.3511 111.652C64.7729 116.966 62.5678 121.656 59.5858 125.486C59.4143 125.706 59.0968 125.745 58.8766 125.574C58.6563 125.402 58.6168 125.085 58.7883 124.865C61.6842 121.146 63.8372 116.566 65.3822 111.365C66.4519 107.763 67.0911 104.464 67.6094 100.666L67.6481 100.38C67.8298 98.9668 69.028 97.9086 70.4372 97.9004Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M93.1155 102.617C93.3946 102.617 93.6209 102.843 93.6209 103.123C93.6209 103.392 93.4105 103.612 93.1452 103.627L93.1155 103.628H81.104C80.8249 103.628 80.5986 103.402 80.5986 103.123C80.5986 102.853 80.809 102.633 81.0743 102.618L81.104 102.617H93.1155Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M93.9072 106.189C94.1863 106.189 94.4126 106.416 94.4126 106.695C94.4126 106.964 94.2022 107.184 93.9369 107.199L93.9072 107.2H76.9429C76.6638 107.2 76.4375 106.974 76.4375 106.695C76.4375 106.426 76.6479 106.206 76.9132 106.19L76.9429 106.189H93.9072Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M99.5174 104.402C99.7965 104.402 100.023 104.629 100.023 104.908C100.023 105.177 99.8124 105.397 99.5471 105.412L99.5174 105.413H74.1636C73.8845 105.413 73.6582 105.187 73.6582 104.908C73.6582 104.639 73.8686 104.419 74.1339 104.403L74.1636 104.402H99.5174Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M95.8444 107.873C96.1235 107.873 96.3498 108.099 96.3498 108.378C96.3498 108.648 96.1394 108.868 95.8741 108.883L95.8444 108.884H73.4556C73.1765 108.884 72.9502 108.658 72.9502 108.378C72.9502 108.109 73.1606 107.889 73.4259 107.874L73.4556 107.873H95.8444Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M79.8904 116.582C80.1695 116.582 80.3958 116.808 80.3958 117.087C80.3958 117.357 80.1854 117.577 79.9201 117.592L79.8904 117.593H67.7779C67.4987 117.593 67.2725 117.367 67.2725 117.087C67.2725 116.818 67.4829 116.598 67.7482 116.583L67.7779 116.582H79.8904Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M84.5737 120.559C84.8529 120.559 85.0791 120.785 85.0791 121.064C85.0791 121.333 84.8687 121.553 84.6034 121.569L84.5737 121.569H66.0259C65.7468 121.569 65.5205 121.343 65.5205 121.064C65.5205 120.795 65.7309 120.575 65.9962 120.559L66.0259 120.559H84.5737Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M98.6074 116.684C98.8865 116.684 99.1128 116.91 99.1128 117.189C99.1128 117.458 98.9024 117.678 98.6371 117.694L98.6074 117.694H93.3345C93.0554 117.694 92.8291 117.468 92.8291 117.189C92.8291 116.92 93.0395 116.7 93.3048 116.684L93.3345 116.684H98.6074Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M96.5185 120.289C96.7977 120.289 97.0239 120.515 97.0239 120.794C97.0239 121.064 96.8135 121.284 96.5482 121.299L96.5185 121.3H91.2456C90.9665 121.3 90.7402 121.074 90.7402 120.794C90.7402 120.525 90.9506 120.305 91.2159 120.29L91.2456 120.289H96.5185Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M93.6035 124.518C93.8826 124.518 94.1089 124.744 94.1089 125.023C94.1089 125.292 93.8985 125.512 93.6332 125.528L93.6035 125.528H88.3306C88.0515 125.528 87.8252 125.302 87.8252 125.023C87.8252 124.754 88.0356 124.534 88.3009 124.518L88.3306 124.518H93.6035Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M79.5541 124.619C79.8332 124.619 80.0595 124.845 80.0595 125.125C80.0595 125.394 79.8491 125.614 79.5838 125.629L79.5541 125.63H63.2974C63.0183 125.63 62.792 125.404 62.792 125.125C62.792 124.855 63.0024 124.635 63.2677 124.62L63.2974 124.619H79.5541Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M90.2849 128.459C90.564 128.459 90.7903 128.685 90.7903 128.964C90.7903 129.234 90.5799 129.454 90.3146 129.469L90.2849 129.47H85.0288C84.7497 129.47 84.5234 129.243 84.5234 128.964C84.5234 128.695 84.7338 128.475 84.9991 128.46L85.0288 128.459H90.2849Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M73.0175 128.578C73.2967 128.578 73.5229 128.804 73.5229 129.084C73.5229 129.353 73.3125 129.573 73.0472 129.588L73.0175 129.589H62.2359C61.9567 129.589 61.7305 129.363 61.7305 129.084C61.7305 128.814 61.9409 128.594 62.2062 128.579L62.2359 128.578H73.0175Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M55.5988 120.273L26.1008 120.29C24.1241 120.29 22.6815 122.147 23.1684 124.054C23.8589 126.783 25.0916 129.279 27.0217 131.255C29.5483 133.841 33.0515 135.284 37.6406 135.284L40.4871 135.287C42.302 135.29 44.2909 135.297 46.463 135.307L52.879 135.341L72.6731 135.473L74.4313 135.48C74.7621 135.481 75.0305 135.48 75.2373 135.48L75.624 135.476L75.7217 135.473C75.7357 135.472 75.7482 135.472 75.7595 135.471C75.779 135.47 75.7961 135.468 75.8157 135.465L75.966 135.442C76.2514 135.393 76.2524 135.343 76.2524 134.963C76.2524 134.704 76.0557 134.486 75.7974 134.461C70.1211 133.892 65.8739 132.042 62.8431 129.232C60.6693 127.216 59.2929 124.881 58.5289 122.518L58.49 122.396C58.1048 121.139 56.929 120.273 55.5988 120.273ZM26.101 121.301L55.599 121.284C56.4685 121.284 57.2385 121.839 57.5092 122.648L57.5245 122.696C58.3308 125.261 59.8051 127.793 62.1557 129.973L62.252 130.061C64.3456 131.974 66.9716 133.454 70.179 134.409L70.3126 134.448L53.8254 134.336L47.6072 134.301C43.7517 134.283 40.4449 134.273 37.6405 134.273C33.3208 134.273 30.079 132.937 27.7447 130.548C25.9538 128.715 24.7984 126.375 24.148 123.805C23.824 122.536 24.7837 121.301 26.101 121.301Z"
              fill="#273067"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M250.916 134.508C251.195 134.508 251.421 134.734 251.421 135.013C251.421 135.282 251.211 135.502 250.945 135.518L250.916 135.519H1.05031C0.771193 135.519 0.544922 135.292 0.544922 135.013C0.544922 134.744 0.755319 134.524 1.02062 134.509L1.05031 134.508H250.916Z"
              fill="#273067"
            />
          </svg>
        </template>
      </div>
    </a-row>
    <a-row>
      <FilterMenuBilling @onFilter="filterPayments" />
    </a-row>
    <a-row>
      <TableMenuBilling
        class="table-menu-billing"
        :filters="filters"
        @onChangePagination="filterPayments"
      />
    </a-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vari from '@/app/shared/utils/variables'
import CardBilling from '@/app/shop/components/cards/CardBilling.vue'
import FilterMenuBilling from '@/app/shop/components/billing/FilterMenuBilling.vue'
import TableMenuBilling from '@/app/shop/components/billing/TableMenuBilling.vue'
import attemptMixin from '@/app/shared/mixins/attempt'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'SectionMenuBilling',
  mixins: [attemptMixin],
  components: {
    CardBilling,
    FilterMenuBilling,
    TableMenuBilling,
  },
  data: () => ({
    title: 'Facturación',
    secondaryDescription: {
      text: '',
      url: '',
    },
    pricePlanDetails: null,
    filters: {},
  }),
  async created() {
    this.intervalAttempt(async () => {
      if (!this.companyPricing) throw 'companyPricing'
      const result = await this.pricePerUser({
        pricingId: this.companyPricing?.plan._id,
        apportionment: false,
      })
      this.pricePlanDetails = result
    })
  },
  computed: {
    ...mapGetters(['companyPricing', 'pricings']),
    /**
     * @returns {Object[]}
     */
    billingCards() {
      if (!this.companyPricing || !this.pricePlanDetails) return []
      const nextMonthDay = moment(this.pricePlanDetails.nextPaymentAt).format(
        'LL'
      )
      const nextPayment = {
        action: 'redirect',
        type: 'primary',
        secondaryText: 'Siguiente pago',
        primaryText: nextMonthDay,
        description:
          'Esta fecha puede variar entre los últimos 3 días de facturación',
        badge: '$',
        price: `${this.pricePlanDetails.total}`,
        priceDescription: 'Pago total del mes',
        note: `${this.pricePlanDetails.quantity} usuarios actuales`,
        textButton: 'Ver método de pago',
      }

      const currentPayment = {
        action: 'upgrade',
        type: 'secondary',
        secondaryText: 'Plan actual',
        primaryText: this.companyPricing.plan.name,
        description: this.companyPricing.plan.description.es,
        secondaryDescription: {
          text: 'Conoce más',
          url: vari.WEB_PRICING,
        },
        badge: '$',
        price: this.companyPricing.plan.price || 0,
        priceDescription: 'Mensual por usuario',
        note:
          this.companyPricing.plan.key == 'free'
            ? `Reinicio de beneficios: ${nextMonthDay}`
            : null,
        textButton: this.recommendedPlan
          ? `Subir a ${this.recommendedPlan?.name}`
          : null,
      }
      if (
        this.companyPricing.plan.free ||
        this.companyPricing.plan.key == 'enterprise'
      )
        return [currentPayment]
      else return [nextPayment, currentPayment]
    },
    /**Plan recomendado para hacer upgrade */
    recommendedPlan() {
      if (!this.pricings || this.pricings.length === 0) return []
      const indexCurrentPlan = this.pricings.findIndex(
        (princing) => princing._id === this.companyPricing.pricingId
      )
      return this.pricings[indexCurrentPlan + 1]
    },
  },
  methods: {
    ...mapActions(['pricePerUser', 'fetchPayments']),
    /**
     * Filtrar todos los pagos
     */
    filterPayments(filters) {
      this.filters = { ...this.filters, ...filters }

      this.fetchPayments({
        ...this.filters,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
$padding: 32px
$space-cards: 20px

.section-menu-billing
  padding: $padding
  .heading-h5
    text-align: left
    margin-bottom: 16px
  .details
    display: flex
    justify-content: space-between
    align-items: flex-end
    .cards
      display: flex
      .card-billing
        margin: 0 $space-cards 0 0
  .table-menu-billing
    margin-top: 12px
.template
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
</style>
