<template>
  <div class="section-menu-billing">
    <a-row>
      <header class="template__header">
        <h5 class="heading-h5 mrg-bottom-0">{{ title }}</h5>
        <a-tooltip placement="bottom">
          <template slot="title">
            ❗️Importante: Nuestros procesos de cobros en Ticker están bajo
            horario internacional UTC, por lo que los consumos que se realicen
            aparecerán en este apartado según este formato.
          </template>
          <a-icon type="info-circle" class="template__icon" />
        </a-tooltip>
      </header>
    </a-row>
    <a-row v-if="companyPricing">
      <div class="details">
        <div class="cards">
          <CurrentPackDetail
            v-for="item in companyPricing.packs"
            :key="item.description"
            :pack="item"
            :withAction="true"
            :showCountries="item.image === 'SMS'"
            class="current-card"
          />
        </div>
      </div>
    </a-row>
    <a-tabs
      :default-active-key="defaultActiveKey"
      :tabBarStyle="{
        margin: '16px auto 20px auto',
        display: 'flex',
        justifyContent: 'flex-start',
      }"
    >
      <a-tab-pane v-if="hasRequestedWsp" key="1" tab="WhatsApp">
        <CustomSectionScroll
          id-master="section-all-products"
          :masterStyles="{
            height: 'calc(100vh - 280px)',
            width: '100%',
            marginTop: '-12px',
          }"
        >
          <TableMenuSpendings
            title="Mis gastos de saldo"
            type="spending"
            :columns="spendingColumns"
            :fetch="getWspSpendings"
            tableKey="table_1"
            @onCollapse="handleCollapseTable"
            :isSelected="true"
          />
          <TableMenuSpendings
            title="Mis Recargas"
            type="recharge"
            :columns="rechargeColumns"
            :fetch="getWspRecharges"
            tableKey="table_2"
            @onCollapse="handleCollapseTable"
            :isSelected="true"
          />
        </CustomSectionScroll>
      </a-tab-pane>
      <a-tab-pane key="2" tab="SMS">
        <TableMenuSpendings title="Mis gastos de saldo" type="slot">
          <p>
            Los gastos de los SMS se pueden ver a detalle desde el dashboard
            <router-link to="/dashboards#dashboard-sms">
              Resumen de envíos SMS.
            </router-link>
          </p>
        </TableMenuSpendings>
        <TableMenuSpendings
          title="Mis Recargas"
          type="recharge"
          :columns="rechargeSmsColumns"
          :fetch="getSmsRecharges"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TableMenuSpendings from '@/app/shop/components/spendings/TableMenuSpendings.vue'
import attemptMixin from '@/app/shared/mixins/attempt'
import CurrentPackDetail from '@/app/shop/components/market/CurrentPackDetail.vue'
import SpendingServices from '@/app/shop/services/spendings_services.js'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll.vue'

import moment from 'moment'
moment.locale('es')

export default {
  name: 'SectionMenuBilling',
  mixins: [attemptMixin],
  components: {
    TableMenuSpendings,
    CurrentPackDetail,
    CustomSectionScroll,
  },
  data: () => ({
    moment,
    title: 'Recargas y gastos',
    secondaryDescription: {
      text: '',
      url: '',
    },
    pricePlanDetails: null,
    defaultActiveKey: '1',
    hasRequestedWsp: false,
    spendingColumns: [
      {
        title: 'Fecha (UTC)',
        key: 'date',
        dataIndex: 'created_at',
        width: 240,
        customRender: (value) => moment.utc(value).format('D MMMM, YYYY'),
      },
      {
        title: 'Cantidad',
        key: 'totalItems',
        dataIndex: 'totalItems',
        width: 223,
        type: 'number',
      },
      {
        title: 'Costo total',
        key: 'totalPrice',
        dataIndex: 'totalPrice',
        width: 317,
        type: 'number',
      },
      {
        title: 'ID de Canal',
        key: 'apiBusinessId',
        dataIndex: 'apiBusinessId',
        type: 'string',
      },
      // -----comentado porque aun no se tomara la descarga de los datos
      // {
      //   title: 'Acción',
      //   key: 'action',
      //   scopedSlots: { customRender: 'action' },
      //   width: 200,
      // },
    ],
    rechargeColumns: [
      {
        title: 'Fecha (UTC)',
        key: 'date',
        dataIndex: 'created_at',
        width: 240,
        customRender: (value) => moment.utc(value).format('D MMMM, YYYY'),
      },
      {
        title: 'Nro de pago',
        dataIndex: 'paycode',
        key: 'paycode',
      },
      {
        title: 'Método',
        dataIndex: 'method',
        key: 'method',
      },
      {
        title: 'Monto',
        dataIndex: 'total',
        key: 'total',
        type: 'number',
      },
      {
        title: 'Estado',
        key: 'state',
        dataIndex: 'state',
        scopedSlots: { customRender: 'state' },
      },
      {
        title: 'Acción',
        key: 'action',
        scopedSlots: { customRender: 'action' },
      },
    ],
    rechargeSmsColumns: [
      {
        title: 'Fecha',
        key: 'date',
        dataIndex: 'created_at',
        width: 240,
        customRender: (value) => moment.utc(value).format('D MMMM, YYYY'),
      },
      {
        title: 'Nro de pago',
        dataIndex: 'paycode',
        key: 'paycode',
      },
      {
        title: 'Método',
        dataIndex: 'method',
        key: 'method',
      },
      {
        title: 'Cantidad',
        dataIndex: 'amount',
        key: 'amount',
        type: 'number',
      },
      {
        title: 'Monto',
        dataIndex: 'total',
        key: 'total',
        type: 'number',
      },
      {
        title: 'Estado',
        key: 'state',
        dataIndex: 'state',
        scopedSlots: { customRender: 'state' },
      },
      {
        title: 'Acción',
        key: 'action',
        scopedSlots: { customRender: 'action' },
      },
    ],
    tableKeyCollapsed: 'table_1',
  }),
  async created() {
    this.intervalAttempt(async () => {
      if (!this.companyPricing) throw 'companyPricing'
      this.getCompanyPricing()
      const result = await this.pricePerUser({
        pricingId: this.companyPricing?.plan._id,
      })
      this.pricePlanDetails = result
      this.hasRequestedWsp = this.companyPricing.packs.some(
        (pack) => pack.image === 'whatsapp'
      )
      if (!this.hasRequestedWsp) this.defaultActiveKey = '2'
    })
    this.spendingColumns = this.alignNumberColumns(this.spendingColumns)
    this.rechargeColumns = this.alignNumberColumns(this.rechargeColumns)
    this.rechargeSmsColumns = this.alignNumberColumns(this.rechargeSmsColumns)
  },
  computed: {
    ...mapGetters(['companyPricing']),
  },
  methods: {
    ...mapActions(['pricePerUser', 'getCompanyPricing']),
    async getWspSpendings(page, settings) {
      const result = await SpendingServices.getWhatsappSpendings(page, settings)
      return result
    },
    async getWspRecharges(page, settings) {
      const result = await SpendingServices.getRecharges(
        'wallet',
        page,
        settings
      )
      return result
    },
    async getSmsRecharges(page, settings) {
      const result = await SpendingServices.getRecharges(
        'outbound_sms_channel',
        page,
        settings
      )
      return result
    },
    alignNumberColumns(array) {
      return array.map((column) => {
        if (column.type == 'number') {
          // column.customRender = (value) => <div class="number-cell">{value}</div>
        }
        return column
      })
    },
    handleCollapseTable(tableKey, hide) {
      if (!hide) this.tableKeyCollapsed = tableKey
      else {
        const key = tableKey === 'table_1' ? 'table_2' : 'table_1'
        this.tableKeyCollapsed = key
      }
    },
  },
}
</script>

<style lang="sass" scoped>
$padding: 32px
$space-cards: 20px

.section-menu-billing
  padding: $padding
  .heading-h5
    text-align: left
    margin-bottom: 16px
  .details
    display: flex
    justify-content: space-between
    align-items: flex-end
    .cards
      display: flex
      .card-billing
        margin: 0 $space-cards 0 0
      .current-card
        margin-right: 12px
  .table-menu-billing
    margin-top: 12px
  .number-cell
    text-align: right
</style>
