<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="$emit('onClose')"
    width="34em"
    :bodyStyle="bodyStyle"
    class="modal"
    :afterClose="afterClose"
    centered
  >
    <a-icon :type="icon.type" class="modal__icon" :style="icon.styles" />
    <h6 class="heading-h6 modal__title text--center">{{ title }}</h6>
    <a-alert
      v-if="showAlertError && error.visible"
      class="mrg-bottom-8"
      type="error"
      :message="error.message"
      show-icon
    />
    <slot name="description">
      <template v-for="(description, index) in descriptions">
        <p :key="index" class="mrg-bottom-0">{{ description }}</p>
      </template>
    </slot>
    <h6 class="heading-h7 modal__subtitle">
      {{ subtitle }}
    </h6>
    <a-radio-group class="modal__radios" v-model="modalActions.action">
      <!-- OPCIÓNJ COLA GENERAL -->
      <a-radio value="default" :style="radioStyle"
        >Transferir todos los tickets a la “Cola general”</a-radio
      >
      <!-- OPCIÓN segun canal -->
      <a-radio
        v-if="
          itemSource.channels_companies &&
          itemSource.channels_companies.length > 0
        "
        value="specific"
        :style="
          !showActionFinish
            ? { ...radioStyle, ...lastRadioStyle }
            : { ...radioStyle }
        "
      >
        <span>Selecciona a cuál cola transferir según el canal</span>
        <a-form-model
          v-if="modalActions.action === 'specific'"
          ref="formSpecificOptions"
          :model="modalActions.channels"
          layout="vertical"
          class="mrg-top-8"
        >
          <a-form-model-item
            v-for="channel in assignedChannels"
            :key="channel._id"
            class="modal__selects"
            :prop="channel._id"
            :rules="[
              {
                required: true,
                message: 'Completa este campo',
                trigger: 'change',
              },
            ]"
          >
            <div slot="label" class="display-inline-block">
              <a-row type="flex">
                <social-icon
                  :type="channel.channel"
                  :size="14"
                  class="mrg-right-4"
                />
                Tickets de {{ channel.title }}
              </a-row>
            </div>
            <a-select
              v-model="modalActions.channels[channel._id]"
              placeholder="Selecciona una cola"
              style="width: 100%"
            >
              <a-select-option
                v-for="item in filterDataSource(channel._id)"
                :key="item._id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-radio>
      <!-- OPCION FINALIZAR TICKETS -->
      <a-radio
        v-if="showActionFinish"
        value="finish"
        :style="{ ...radioStyle, ...lastRadioStyle }"
      >
        <span>Finalizar todos los ticket</span>
        <div
          v-if="modalActions.action === 'finish'"
          class="modal__selects mrg-top-8"
        >
          <p class="regular-14 cursor-default mrg-bottom-4 modal__label-finish">
            Escribe un breve comentario sobre la gestión de estos tickets para
            poder resolverlos.
          </p>
          <counter-text :text="modalActions.comment" :maxLength="250">
            <a-textarea
              v-model="modalActions.comment"
              class="position-relative"
              placeholder="Escribe aquí..."
              :auto-size="{ minRows: 3, maxRows: 4 }"
              :maxLength="250"
            />
          </counter-text>
        </div>
      </a-radio>
    </a-radio-group>
    <template v-if="showKeyWord">
      <p class="body-2 modal__label modal__label--main">
        Escribe la palabra <strong>{{ keyWord }}</strong>
      </p>
      <a-input v-model="valueInput" placeholder="Escribe la palabra aquí" />
    </template>
    <a-divider class="modal__divider" />
    <footer>
      <a-button class="modal__button" @click="$emit('onClose')"
        >Cancelar</a-button
      >
      <a-button
        class="modal__button"
        :type="typeButton"
        @click="handleSubmit"
        :disabled="disabledButton"
        :loading="loadingBtn"
        >{{ nameButton }}</a-button
      >
    </footer>
  </a-modal>
</template>

<script>
import { mapActions } from 'vuex'
import CounterText from '@/app/shared/components/molecules/CounterText'
import supportMixin from '@/app/shared/mixins/support'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'ModalActions',
  props: {
    icon: {
      type: Object,
      required: false,
      default: () => ({
        type: 'question-circle',
        styles: {
          color: '#FAAD14',
        },
      }),
    },
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'OHHH noo',
    },
    descriptions: { type: Array, required: false, default: () => ['OHHH noo'] },
    keyWord: { type: String, required: false, default: 'OHHH noo' },
    itemSource: {
      type: Object,
      required: false,
      default: () => ({
        _id: '1',
        channelsCompanies: {},
        active: false,
      }),
    },
    dataSourceSelects: {
      type: Array,
      required: false,
      default: () => [{ title: 'Primer item', key: '1', disabled: false }],
    },
    typeButton: {
      type: String,
      required: false,
      default: 'primary',
    },
    nameButton: {
      type: String,
      required: false,
      default: 'Aceptar',
    },
    subtitle: {
      type: String,
      required: false,
      default: 'Acciones para los tickets de esta cola',
    },
    showActionFinish: {
      type: Boolean,
      required: false,
      default: false,
    },
    onOk: {
      type: Function,
      required: true,
    },
    showKeyWord: {
      type: Boolean,
      required: false,
      default: true,
    },
    showAlertError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    CounterText,
    SocialIcon,
  },
  data: () => ({
    valueAction: 1,
    bodyStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '32px 36px',
    },
    radioStyle: {
      marginTop: '12px',
      width: '100%',
    },
    lastRadioStyle: {
      marginBottom: '0px',
    },
    valueInput: '',
    loadingBtn: false,
    modalActions: {
      action: 'default',
      channels: {},
      comment: '',
    },
    error: { visible: false, message: '' },
  }),
  mixins: [supportMixin],
  computed: {
    /**
     * @return {Boolean}
     */
    disabledButton() {
      if (!this.showKeyWord) return false
      return this.valueInput === this.keyWord ? false : true
    },
    /**
     * @return {Object[]} - canales asignados
     */
    assignedChannels() {
      if (!this.itemSource.channels_companies) return []
      return [...this.itemSource.channels_companies].map((companyChannel) => {
        const alias = this.channelCompanyAlias(companyChannel._id)
        let newObj = {}
        const title = alias
          ? `${companyChannel.title} ( ${alias} )`
          : companyChannel.title
        newObj = { ...companyChannel }
        newObj.title = title
        return newObj
      })
    },
  },
  methods: {
    ...mapActions(['changeActiveLine', 'deleteLine', 'addNotification']),
    /**
     * Submmit del formulario
     */
    async handleSubmit() {
      // si la accion es la de Escoger colas especificas
      let isValid = true
      if (this.modalActions.action === 'specific')
        this.$refs.formSpecificOptions.validate((valid) => {
          isValid = valid
        })
      if (!isValid) return
      this.loadingBtn = true
      let action = {
        type: this.modalActions.action,
        channels: this.modalActions.channels,
        comment: this.modalActions.comment,
      }
      const response = await this.onOk({
        id: this.itemSource._id,
        active: !this.itemSource.active,
        action: action,
      })
      this.loadingBtn = false
      this.valueInput = ''
      // si se seteo que se muestra la alerta y si hay un error
      if (this.showAlertError && response && !response.success) {
        this.error = {
          visible: true,
          message: response.details || 'Ocurrió un error',
        }
        return
      }
      this.$emit('onClose', 'load')
    },
    /**
     * Filtra la data source(data para los selects) según el canal y el id de la cola seleccionada
     * @param {String} channelCompanyId
     */
    filterDataSource(channelCompanyId) {
      return this.dataSourceSelects.filter((select) =>
        select.channels_companies.some(
          (channelCompany) =>
            channelCompany._id === channelCompanyId &&
            select._id !== this.itemSource._id
        )
      )
    },
    /**
     * Funcion que se ejecuta despues de cerrarse
     */
    afterClose() {
      this.modalActions.action = 'default'
      for (let channel in this.modalActions.channels) {
        this.modalActions.channels[channel] = undefined
      }
      this.valueInput = ''
      this.loadingBtn = false
      this.error = {
        visible: false,
        message: '',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  &__title
    margin: 16px auto 12px
  &__subtitle
    text-align: left
    width: 100%
    margin: 20px 0px 8px
  &__label
    margin-bottom: 2px
    text-align: left
    width: 100%
    &--capitalize
      text-transform: capitalize
    &:first-child
      margin-top: 8px
    &--main
      margin-top: 12px
  &__selects
    width: 100%
    padding: 0px 24px 2px 24px !important
    .ant-col .ant-form-item-label
      padding: 0 0 2px !important
  &__button
    &:last-child
      margin-left: 12px
  &__radios
    width: 100%
  &__icon
    font-size: 64px
  &__divider
    margin: 32px 0px 20px
.modal__label-finish
  white-space: pre-line
</style>
