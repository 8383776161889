import Settings from '@/app/settings/views/Settings'
// componentes del menu de settings
import SectionMenuGeneral from '@/app/settings/components/organisms/generalMenu/SectionMenuGeneral.vue'
import SectionMenuWorkspace from '@/app/settings/components/organisms/workspaceMenu/SectionMenuWorkspace.vue'
import SectionMenuNotifications from '@/app/settings/components/organisms/alertsMenu/SectionMenuNotifications.vue'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    children: [
      {
        path: 'general',
        name: 'settings-general',
        component: SectionMenuGeneral,
        meta: {
          requiresAuth: true,
          requiresType: ['admin'],
          title: `Ajuste General | ${VUE_APP_NAME}`,
        },
      },
      {
        path: 'workspace',
        name: 'settings-workspace',
        component: SectionMenuWorkspace,
        meta: {
          requiresAuth: true,
          requiresType: ['admin'],
          title: `Ajuste Workspace | ${VUE_APP_NAME}`,
        },
      },
      {
        path: 'alerts',
        name: 'settings-alerts',
        component: SectionMenuNotifications,
        meta: {
          requiresAuth: true,
          requiresType: ['admin'],
          title: `Ajuste Alertas SMS | ${VUE_APP_NAME}`,
        },
      },
    ],
    meta: {
      requiresAuth: true,
      requiresType: ['admin'],
    },
  },
]
