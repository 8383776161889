var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-plan"},[_c('div',{staticClass:"content",class:{
      default: !_vm.currentPlan && !_vm.recommend,
      current: _vm.currentPlan,
      recommend: _vm.recommend,
      'display-flex': _vm.twoColumns,
      'align--center': _vm.twoColumns,
    }},[_c('a-tag',{staticClass:"tag",attrs:{"color":"#108ee9"}},[_vm._v(" Recomendado ")]),_c('a-row',{staticClass:"full-width full-height",class:{
        'display-flex': _vm.twoColumns,
        'align--center': _vm.twoColumns,
      },attrs:{"gutter":60}},[(_vm.twoColumns)?_c('a-col',{attrs:{"span":12}},[_c('img',{staticClass:"card__picture-full",attrs:{"svg-inline":"","width":"360","src":require(`@/app/shared/assets/whoooa_graphics/solid/gaming.svg`)}})]):_vm._e(),_c('a-col',{staticClass:"display-flex direction-column full-height",attrs:{"span":_vm.spanColumn}},[(!_vm.twoColumns)?_c('img',{staticClass:"card__picture",attrs:{"svg-inline":"","src":require(`@/app/commons/assets/${_vm.picture}.svg`),"height":"44"}}):_vm._e(),_c('h6',{staticClass:"heading-h6 card__title",class:{ 'text--left': _vm.twoColumns, 'mrg-bottom-12': _vm.twoColumns }},[_vm._v(" "+_vm._s(_vm.namePlan)+" ")]),(!_vm.twoColumns)?_c('h4',{staticClass:"semibold-20 margin-bottom-0 card__price"},[(_vm.price.custom)?[_vm._v(_vm._s(_vm.price.content))]:[(_vm.showCoinSymbol)?[_vm._v(" "+_vm._s(_vm.coinSymbol)+" ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.price)+" "),_c('span',{staticClass:"regular-12"},[_vm._v(" por usuario")])]],2):_vm._e(),_c('ul',{staticClass:"list-benefits"},_vm._l((_vm.benefits),function(benefit,index){return _c('li',{key:index,staticClass:"benefit"},[_c('a-icon',{staticClass:"yellow_6",staticStyle:{"font-size":"12px","margin-right":"12px"},attrs:{"type":"check"}}),_c('p',{staticClass:"body-2"},[_vm._v(_vm._s(benefit))])],1)}),0),_c('div',{staticClass:"content-footer"},[(!_vm.isDowngrade)?_c('a-tooltip',[(!this.isAllowBuyPacks)?_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Actualmente no se pueden comprar planes y paquetes a la vez 😥 ")]):_vm._e(),_c('a-button',{staticClass:"footer_button",attrs:{"type":_vm.recommend ? 'primary' : '',"disabled":_vm.disabledAdd,"loading":_vm.btnActions.loading},on:{"click":function($event){return _vm.emitAction(_vm.isDowngrade, _vm.nameButton.type)}}},[_vm._v(" "+_vm._s(_vm.nameButton.name)+" ")])],1):_c('a-button',{class:{
              'link-inactive': !_vm.isActiveDowngrade && !_vm.isCancelDowngrade,
              'link-active': _vm.isActiveDowngrade || _vm.isCancelDowngrade,
            },attrs:{"type":"link"},on:{"click":function($event){return _vm.emitAction(_vm.isDowngrade, _vm.nameButton.type)}}},[_vm._v(_vm._s(_vm.nameButton.name))])],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }