<template>
  <div class="section-menu-notifications">
    <div class="section__header">
      <h5 class="heading-h5 mrg-bottom-0 title">Alertas SMS</h5>
      <a-tooltip placement="right">
        <template slot="title">
          Administra alertas SMS que te avisen cuando un cliente te necesita.
          <a
            href="https://www.securitec.pe/docs/ticker/caracteristicas/configuracion-alertas/"
            target="_blank"
            rel="noopener noreferrer"
            >Click aquí</a
          >
          si deseas conocer más.
        </template>
        <a-icon type="info-circle" class="section__icon" />
      </a-tooltip>
    </div>
    <custom-section-scroll
      :master-styles="{
        height: `calc(100% - (${sizeTitle} + ${marginBottomTitle} + ${paddingTopHeader} + 12px))`,
      }"
      :contentStyles="{ margin: '0 20px' }"
    >
      <p class="body-2 text-align-left section__description">
        Envía alertas SMS a los usuarios y números que registres cuando un
        cliente responda a un ticket activo y no haya alguien conectado. También
        serán alertados cuando hayan tickets en la cola de espera sin atender.
        Recuerda que debes comprar bolsas SMS desde
        <router-link :to="{ name: 'menu-market' }">Market</router-link> y
        actualmente funcionan para Perú, México, Colombia y Argentina.
      </p>
      <section-alerts
        v-if="company && notificationsPhones"
        :notifications="company.settings.notifications"
        :notificationsPhones="notificationsPhones"
      />
    </custom-section-scroll>
  </div>
</template>

<script>
import SectionAlerts from '@/app/settings/components/organisms/alertsMenu/SectionAlerts'
import { mapActions, mapGetters } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'

export default {
  name: 'SectionMenuNotifications',
  mixins: [attemptMixin],

  components: {
    SectionAlerts,
    CustomSectionScroll,
  },
  data: () => ({
    sizeTitle: '24px',
    marginBottomTitle: '12px',
    paddingTopHeader: '20px',
  }),
  computed: {
    ...mapGetters(['company', 'notificationsPhones']),
  },
  created() {
    this.intervalAttempt(() => {
      console.log('cargando numeros', this.notificationsPhones)
      if (!this.company) throw 'No company'
      this.getNotificationsPhones()
    })
  },
  methods: {
    ...mapActions(['getNotificationsPhones']),
  },
}
</script>

<style lang="sass" scoped>
$size-title: 24px
$margin-bottom-title: 12px
$padding-top-header: 20px

.text-align-left
  text-align: left
.section-menu-notifications
  height: 100%
  .section
    &__header
      padding: $padding-top-header 20px 0
      display: flex
      align-items: center
      margin-bottom: $margin-bottom-title
      .title
        text-align: left
    &__icon
      margin-left: 8px
      font-size: 18px
      cursor: pointer
    &__description
      color: $gray_dark_800
</style>
