<template>
  <div id="app">
    <a-layout :style="mainStyles">
      <!--Menu-->
      <component v-if="showMenu" :is="currentMenu.component" />
      <!--Contenido de toda la app-->
      <a-layout-content
        :class="{
          'margin--no-collapsed': showMenu && currentMenu.name === 'sidebar',
          'margin--fixed': hasNavbarFixed,
        }"
        :style="contentStyles"
      >
        <!-- Conexion de Internet -->
        <NoConnection
          v-if="visibleNoConnection"
          text="Se perdió tu conexión a internet"
          icon="internet"
          :class="{
            'width--collapsed': menuCollapse,
            'width--no-collapsed': !menuCollapse,
          }"
          @handleClose="handleCloseConnection"
        />
        <!-- Integracion de API -->
        <div v-if="visibleNoAPI">
          <NoConnection
            v-for="errorChannelConnection in errorChannelsConnection"
            :key="errorChannelConnection.channel"
            :text="
              textErrorChannelConnection(
                errorChannelConnection.channel,
                errorChannelConnection.api_status
              )
            "
            :url="errorChannelConnection.api_status"
            icon="api"
            :class="{
              'width-collapsed': menuCollapse,
              'width-no-collapsed': !menuCollapse,
            }"
            @handleClose="handleCloseAPI"
          />
        </div>
        <!--Vista dinámica de todos los módulos-->
        <router-view />
      </a-layout-content>
    </a-layout>
    <div id="sounds" v-show="false">
      <audio
        ref="sound_message"
        src="@/app/commons/assets/sounds/message.ogg"
      ></audio>
    </div>
    <div id="sounds_notification" v-show="false">
      <audio
        ref="sound_notification"
        src="@/app/commons/assets/sounds/notification.ogg"
      ></audio>
    </div>
    <!-- Todos los Modales Globales (Para cualquier vista) -->
    <AppModals />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import AppModals from '@/app/commons/components/AppModals.vue'
import Sidebar from '@/app/commons/components/organisms/Sidebar'
import Navbar from '@/app/commons/components/molecules/Navbar'
import transformMixin from '@/app/shared/mixins/transform'
import NoConnection from '@/app/commons/components/NoConnection.vue'
import supportMixin from '@/app/shared/mixins/support'

export default {
  name: 'Ticker',
  mixins: [transformMixin, supportMixin],
  components: {
    Sidebar,
    NoConnection,
    AppModals,
    Navbar,
  },
  data: () => ({
    visibleNoConnection: true,
    visibleNoAPI: true,
  }),
  watch: {
    internet() {
      this.visibleNoConnection = !this.internet
    },
    isRouteAuthenticated(val) {
      if (val) this.validateSignin({}) // Valida la sesion, solo cuando la ruta esta autenticada
    },
    'active_breakpoint.is_mobile'() {
      this.validateCurrentRouteForMobile()
    },
  },
  created() {
    this.interceptor()
    this.initBreakpoints()
    if (this.isRouteAuthenticated) this.validateSignin({}) // Valida la sesion, solo cuando la ruta esta autenticada
  },
  mounted() {
    this.SET_SOUND_MESSAGE(this.$refs.sound_message)
    this.SET_SOUND_NOTIFICATION(this.$refs.sound_notification)
    this.loadModalSessionExpired()
    this.loadModalRemoteLogout()
    this.visibleNoConnection = !this.internet
    this.loadModalRemoteLogout()
  },
  computed: {
    ...mapGetters([
      'menuCollapse',
      'internet',
      'actionDesasigned',
      'actionAsigned',
      'actionChannelRemoved',
      'actionChatPreferencesUpdated',
      'modalRemoteLogout',
      'modalSessions',
      'profile',
      'loggedIn',
      'news',
      'modalUpgrade',
      'companyPricing',
      'pricings',
      'minimalQuantityDay',
      'expirationPlanModal',
      'company',
      'simple_company_channels',
      'active_breakpoint',
    ]),
    /**
     * Lista de los canales activos de la empresa que tienen incidencias
     * @returns {Object[]} { channel, down, api_status }
     */
    errorChannelsConnection() {
      if (!this.simple_company_channels || !this.simple_company_channels.length)
        return []
      let channelsConnection = []
      this.simple_company_channels.forEach((simpleChannel) => {
        if (simpleChannel.active && simpleChannel.api?.down)
          channelsConnection.push({
            channel: simpleChannel.channel,
            down: simpleChannel.api.down,
            api_status: simpleChannel.api_status,
          })
      })
      return channelsConnection
    },
    showMenu() {
      if (this.$route.name === 'password' || this.$route.name === 'support')
        return false
      else return this.$route.meta.requiresAuth ? true : false
    },
    currentMenu() {
      const sidebar = this.active_breakpoint.components.sidebar.visible
      const navbar = this.active_breakpoint.components.navbar.visible

      if (sidebar)
        return {
          name: 'sidebar',
          component: Sidebar,
        }
      else if (navbar)
        return {
          name: 'navbar',
          component: Navbar,
        }
      else return null
    },
    /**
     * @param {Boolean} - si tiene una navbar fixeado
     */
    hasNavbarFixed() {
      const hasNavbar = this.active_breakpoint.components.navbar.visible

      return (
        hasNavbar &&
        this.viewBreakpoint &&
        this.viewBreakpoint.navbar &&
        this.viewBreakpoint.navbar.position === 'fixed'
      )
    },
    /**
     * @return {Boolean} si la ruta actual esta autenticada
     */
    isRouteAuthenticated() {
      return (this.$route.meta && this.$route.meta.requiresAuth) || false
    },
    /**
     * @return {String} estilos principales solo para las vistas autenticadas
     */
    mainStyles() {
      return this.active_breakpoint.is_mobile && this.isRouteAuthenticated
        ? 'height: 100%; position: fixed; width: 100%'
        : 'min-height: 100vh'
    },
    /**
     * @return {String} estilos para el contenido solo para vistas autenticadas
     */
    contentStyles() {
      return this.active_breakpoint.is_mobile && this.isRouteAuthenticated
        ? 'height: calc(100% - 56px)'
        : ''
    },
  },
  methods: {
    ...mapActions([
      'readNews',
      'interceptor',
      'getNotifications',
      'initBreakpoints',
      'validateSignin',
    ]),
    ...mapMutations([
      'SET_ACTION_DESASIGNED',
      'SET_MODAL_REMOTE_LOGOUT',
      'SET_MODAL_SESSION_EXPIRED',
      'ADD_PRODUCT_TO_PURCHASE',
      'SET_TOTAL_TO_PURCHASE',
      'SET_IS_ALLOW_BUY_PLANS',
      'SET_SOUND_MESSAGE',
      'SET_SOUND_NOTIFICATION',
      'SET_PROFILE_NOTIFICATIONS',
    ]),
    /**
     * Valida y mantiene el modal de sesion expirado aun despues de refrescar
     */
    loadModalSessionExpired() {
      setTimeout(() => {
        if (
          localStorage.getItem('session_expired') &&
          this.$route.name === 'login'
        ) {
          this.SET_MODAL_SESSION_EXPIRED({
            statusModal: true,
          })
        }
      }, 1000)
    },
    /**
     * Valida y mantiene el modal de logout remoto aun despues de refrescar
     */
    loadModalRemoteLogout() {
      setTimeout(() => {
        if (
          localStorage.getItem('logout_remote') &&
          this.$route.name === 'login'
        ) {
          this.SET_MODAL_REMOTE_LOGOUT({
            statusModal: true,
            typeUser: localStorage.getItem('logout_remote'),
          })
        }
      }, 1000)
    },

    // cerrar el componente de no conexion
    handleCloseConnection() {
      this.visibleNoConnection = !this.visibleNoConnection
    },
    // cerrar el componente de no conexion
    handleCloseAPI() {
      this.visibleNoAPI = !this.visibleNoAPI
    },
    /**
     * cerrar el modal 0 de news
     * @returns {String} newsId
     */
    textErrorChannelConnection(channel, api_status) {
      let channel_str =
        channel === 'messenger' ? 'Facebook' : this.transformApiChannel(channel)
      let text = `Hubo un problema con el proveedor de ${channel_str}.`
      if (api_status) text += ` Para más información ver aquí.`
      return text
    },
    handleGetNotifications() {
      this.getNotifications()
      this.SET_PROFILE_NOTIFICATIONS(1)
    },
    /**
     * Valida si la ruta actual tiene permitido verse para mobiles
     */
    validateCurrentRouteForMobile() {
      if (!this.isRouteAuthenticated) return

      const device = this.active_breakpoint.is_mobile ? 'mobile' : 'desktop'
      // si no existe requiresDevice en la ruta se asume que solo está disponible para desktop
      const requiresDevice = this.$route.meta.requiresDevice
        ? this.$route.meta.requiresDevice
        : ['desktop']
      // valida si la ruta puede entrar a una vista de acuerdo al breakpoint actual
      // si la ruta coincide con el breakpoint, le dejará pasar a la ruta
      if (requiresDevice.includes(device))
        return console.info('current route is allowed for desktop and mobile')
      // si no coincide redirigira a la ruta de sin soporte
      else this.$router.push({ name: 'unsupport' })
    },
  },
}
</script>

<style lang="sass">
#app
  overflow: hidden
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-align: center
  color: #262A31
.margin-0
  margin: 0
.margin--no-collapsed
  margin-left: 58px
  transition: margin-left 0.4s
.margin--collapsed
  margin-left: 224px
  transition: margin-left 0.25s
.width--collapsed
  width: calc( 100% - 224px )
  // transition: width 0.3s
.width--no-collapsed
  width: calc( 100% - 58px )
  // transition: width 18s
.margin--fixed
  margin-top: 56px
.ant-notification
  z-index: 1052!important
// clase que modifica a todos los modales, el cambio del index es necesatio para tapar al sidebar
.ant-modal-wrap
  z-index: 1002!important

// modifica a los labels de todos los formularios
.ant-form-vertical .ant-form-item-label
  padding: 0 0 2px!important
.ant-form-vertical .ant-form-item
  padding-bottom: 2px!important
</style>
