<template>
  <div class="list">
    <template v-for="(data, index) in dataSource">
      <div
        :key="data.nameDay"
        class="display-flex justify-content--start list__item"
      >
        <p class="semibold-12 list__name">
          {{ data.nameDay }}
        </p>
        <div class="display-flex direction-column align--start">
          <range-picker
            v-for="(range, index) in data.hours"
            :key="range.key"
            ref="rangePicker"
            :id-picker="range.key"
            @onChangePicker="handleChangePicker"
            :order-day="data.order"
            :show-icon-remove="index !== 0"
            @onRemove="$emit('onRemoveRange', data.order, range.key)"
            :hour-start="range.start"
            :hour-end="range.end"
            :disabled-hours="disabledHours(data.hours)"
            :disabled-minutes="disabledMinutes"
          />
          <a @click="$emit('onAddRange', data.order)">
            <a-icon type="plus" />
            Agregar nuevo horario
          </a>
        </div>
      </div>
      <a-divider
        v-if="index < dataSource.length - 1"
        :key="`divider-${data.nameDay}`"
        class="list__divider"
      />
    </template>
  </div>
</template>

<script>
import RangePicker from '@/app/chatbots/components/organisms/RangePicker'
import moment from 'moment'

export default {
  name: 'ListRangePickers',
  components: { RangePicker },
  props: {
    dataSource: {
      type: Array,
      default: () => [
        {
          day: 'Lunes',
          order: 0,
          hours: [
            {
              key: 'key-lunes-1',
              start: '04/02/2021 12:00:00',
              end: '04/02/2021 13:00:00',
            },
            {
              key: 'key-lunes-2',
              start: '04/02/2021 12:00:00',
              end: '04/02/2021 13:00:00',
            },
          ],
        },
        {
          day: 'Martes',
          order: 1,
          hours: [
            {
              key: 'key-martes-1',
              start: '04/02/2021 12:00:00',
              end: '04/02/2021 13:00:00',
            },
          ],
        },
      ],
    },
  },
  data: () => ({
    moment,
    disabledMinutes: [],
  }),
  computed: {},
  methods: {
    range(start, end) {
      const result = []
      for (let i = start; i <= end; i++) {
        result.push(i)
      }
      return result
    },
    handleChangePicker(idRange, range, order, type) {
      let hour = null
      // console.log(type === 'start' ? 'HORA START' : 'HORA END')
      // console.log(
      //   type === 'start'
      //     ? moment(range.start).format('HH:mm')
      //     : moment(range.end).format('HH:mm')
      // )

      if (type === 'start') hour = moment(range.start).hours()
      if (type === 'end') hour = moment(range.end).hours()
      this.$emit('onChangeRange', order, idRange, range)
      // mantener esta función después de que se emita el nuevo cambio, ya que si no no considerara lo nuevo
      this.handleSearchDisabledMinutes(order, hour, type)
    },
    handleSubmitRanges() {
      this.$refs.rangePicker.forEach((range) => {
        range.handleSubmit()
      })
    },
    disabledHours(ranges) {
      const disabledHours = []

      ranges.forEach((range) => {
        const timeStart = {
          hour: moment(range.start).hours(),
          minute: moment(range.start).minutes(),
        }
        const timeEnd = {
          hour: moment(range.end).hours(),
          minute: moment(range.end).minutes(),
        }
        disabledHours.push(
          ...this.range(
            timeStart.minute === 59 ? timeStart.hour : timeStart.hour + 1,
            timeEnd.minute === 59 ? timeEnd.hour : timeEnd.hour - 1
          )
        )
      })
      return disabledHours
    },
    handleSearchDisabledMinutes(orderDay, hour) {
      // console.log('order del día:', orderDay)
      // console.log('Hora a consultar', hour)
      let formattedRanges = []
      const day = this.dataSource.find((data) => data.order === orderDay)
      if (day === undefined) return
      const ranges = day.hours
      formattedRanges = ranges.map((range) => {
        let newRange = {}
        newRange.start = {
          hour: moment(range.start).hours(),
          minute: moment(range.start).minutes(),
        }
        newRange.end = {
          hour: moment(range.end).hours(),
          minute: moment(range.end).minutes(),
        }
        return newRange
      })
      // console.log('Rangos formateados:', formattedRanges)
      const rangesByHour = formattedRanges.filter(
        (formattedRange) =>
          formattedRange.start.hour === hour || formattedRange.end.hour === hour
      )
      // console.log('Rangos que tienen la hora:', hour)
      const disabledMinutes = []
      rangesByHour.forEach((range) => {
        if (range.start.hour < range.end.hour) {
          // si el start es igual a la hora tomara del min q tenga hasta el min 59
          if (range.start.hour === hour)
            disabledMinutes.push(...this.range(range.start.minute, 59))
          else if (range.end.hour === hour)
            disabledMinutes.push(...this.range(0, range.end.minute))
        } else {
          disabledMinutes.push(
            ...this.range(range.start.minute, range.end.minute)
          )
        }
      })
      // console.log('MINUTOS DESHABILITADOS:', disabledMinutes)
      this.disabledMinutes = disabledMinutes
    },
  },
}
</script>

<style lang="sass" scoped>
.list__item
  padding: 8px
.list__divider
  margin-top: 12px
  margin-bottom: 12px
.list__name
  width: 80px
</style>
