const initialState = () => ({
  chatbots: [], // Lista de Chatbots
  paginate_chatbots: null, // Datos de la paginación de los chatbots
  listNodesChatbot: [], // lista de los nodos de un chatbot
  // treeNodes: null, // arbol de nodos
  changes: 0, // cambios de opciones y opcion, se suma cuando se agrega una opcion, se resta cuando se guarda una opcion
  isSavedNode: true, // se ha guardado los cambios de un item
  nodes: [],
  selectedNodeId: null,
  chatbot: {},
  filters: { page: '1', status: 'all', name: '', channel: '' },
  nodeSelected: null,
})

export default initialState
