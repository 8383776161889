<template>
  <a-modal
    :visible="visible"
    title="Páginas asociadas a tu cuenta"
    width="min-content"
    centered
    :maskClosable="false"
    :bodyStyle="styleModalSelected"
    :afterClose="afterCloseModalSelected"
    @cancel="handleCancelSelected"
  >
    <custom-section-scroll
      :master-styles="{
        height: '385px',
      }"
      :content-styles="{ padding: '12px 24px' }"
    >
      <p class="body-2">Selecciona una de tus páginas para poder continuar.</p>
      <a-radio-group v-model="pageSelected.idFacebookPage" style="width: 100%">
        <a-radio
          v-for="page in listPages"
          :key="page.id"
          :style="radioStyle"
          :value="page.id"
        >
          <span
            class="card-channel-page"
            :class="{
              'card-channel-page-selected':
                page.id === pageSelected.idFacebookPage,
              'card-channel-page-no-selected':
                page.id !== pageSelected.idFacebookPage,
            }"
          >
            <img
              :src="page.picture.data.url"
              :alt="page.name"
              height="32px"
              width="32px"
              style="border-radius: 4px"
            />
            <h6 class="heading-h6 name-page" style="">
              {{ page.name }}
            </h6>
          </span>
        </a-radio>
      </a-radio-group>
    </custom-section-scroll>
    <template slot="footer">
      <a-button @click="handleCancelSelected">Cancelar</a-button>
      <a-button
        type="primary"
        @click="handleSelected"
        :disabled="pageSelected.idFacebookPage ? false : true"
        :loading="loadBtnConected"
        >Conectar</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import { mapActions } from 'vuex'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'

export default {
  name: 'ModalSelectPage',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    listPages: {
      type: Array,
      required: true,
    },
    loginType: {
      type: String,
      required: true,
    },
  },
  components: {
    CustomSectionScroll,
  },
  data: () => ({
    styleModalSelected: {
      padding: '0',
    },
    pageSelected: {
      // objeto de pagina seleccionada
      channel: '',
      idFacebookPage: null,
      pageAccessToken: '',
      name: '',
      picture: '', // url de la imagen de la pagina
    },
    loadBtnConected: false,
    radioStyle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    },
  }),
  methods: {
    ...mapActions([
      'fbUnlinkPage',
      'fbSubscribeApps',
      'getInstagramBusiness',
      'saveFacebookPage',
      'getSimpleCompanyChannels',
    ]),

    // accion despues de que el modal se cierra
    afterCloseModalSelected() {
      this.pageSelected = []
    },
    // cancela la pagina de seleccionar paginas
    handleCancelSelected() {
      this.$emit('onCancel')
      /**Desvincula a las páginas no seleccionadas de facebook */
      this.listPages.forEach(async (elem) => {
        await this.fbUnlinkPage({
          pageId: elem.id,
          pageAccessToken: elem.access_token,
        })
      })
      this.pageSelected = []
      this.$message.warning('No se terminó de vincular una página')
    },
    /**
     * Setea a la data local una pagina
     * @param {Object} page
     * @param {String} page.channel
     * @param {String} page.access_token
     * @param {String} page.name
     * @param {Object} page.picture
     * @param {Object} page.picture.data
     * @param {String} page.picture.data.url
     */
    setListPages(page) {
      this.pageSelected.channel = page.channel
      this.pageSelected.pageAccessToken = page.access_token
      this.pageSelected.name = page.name
      this.pageSelected.picture = page.picture.data.url
    },
    /**
     * Seleccionar una página de un listado de varios
     */
    async handleSelected() {
      this.loadBtnConected = true
      const findPage = this.listPages.find(
        (page) => page.id === this.pageSelected.idFacebookPage
      )
      findPage.channel = this.loginType
      try {
        await this.fbSubscribeApps({
          pageId: findPage.id,
          pageAccessToken: findPage.access_token,
        })
        let pageId = findPage.id
        let igId = null
        if (findPage.channel == 'instagram') {
          const pageBusinessResponse = await this.getInstagramBusiness({
            pageId: findPage.id,
            pageAccessToken: findPage.access_token,
          })
          if (!pageBusinessResponse.instagram_business_account?.id) {
            this.$confirm({
              title: 'No se pudo vincular tu página',
              icon: () => (
                <a-icon type="close-circle" style={{ color: '#F5222D' }} />
              ),
              content: `Asegúrate que tu página esté correctamente asociada a tu cuenta de Instagram Business seleccionada.`,
              okText: 'Aceptar',
            })
            this.loadBtnConected = false
            return
          }
          igId = pageBusinessResponse.instagram_business_account.id
        }
        /**Guarda la página seleccionado en base */
        const response = await this.saveFacebookPage({
          channel: findPage.channel,
          idFacebookPage: pageId,
          idBusinessIg: igId,
          pageAccessToken: findPage.access_token,
          name: findPage.name,
          picture: findPage.picture.data.url,
        })

        this.setListPages(findPage)
        if (response.data === 'Credentials saved') {
          this.$message.success('Página vinculada correctamente')
          this.pageSelected = []
          this.visibleModalSelect = false
          this.loadBtnConected = false
          this.$router.push({ name: 'my-channels' }).catch(() => {})
          this.$emit('onCancel')
        } else {
          let message = ''
          if (response.result == 'duplicated')
            message = `La pagina '${findPage.name}'  ya se encuentra conectada.`
          else
            message = response.details
              ? response.details
              : 'No pudimos vincular tu página de Facebook 😓.'

          this.$message.error(message)
          this.loadBtnConected = false
        }
      } catch (error) {
        console.error(error)
        this.$message.warning(
          'No existen los permisos suficientes para conectar tu página'
        )
        this.loadBtnConected = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
$color-selected: #1890FF
$color-no-selected: #A8AAAD

.card-channel-page
  padding: 12px
  width: 340px
  display: flex
  flex-direction: row
  border-radius: 8px
  align-items: center
  margin-right: -8px
  .name-page
    padding-left: 12px
    margin-bottom: 0
  &-selected
    border: 1px solid $color-selected
    background-color: #E6F7FF
    .name-page
      color: $color-selected
  &-no-selected
    border: 1px solid $color-no-selected
    background-color: #FFFFFF
    .name-page
      color: #51555A
  &:hover
    border: 1px solid $color-selected
</style>
