<template>
  <div class="company-form">
    <a-form layout="vertical" :form="form" @submit="handleSubmit" class="form">
      <!-- Nombre empresa -->
      <a-form-item
        label="Nombre de empresa"
        class="section-form-item"
        style="padding-bottom: 0px"
        hasFeedback
      >
        <a-input
          v-decorator="[
            'name',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
          :size="inputs_size"
        />
      </a-form-item>
      <!-- País -->
      <a-form-item
        label="País"
        class="section-form-item"
        style="padding-bottom: 0px"
        hasFeedback
      >
        <a-select
          show-search
          v-decorator="[
            'country',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
              ],
            },
          ]"
          placeholder="Selecciona aquí"
          :size="inputs_size"
        >
          <a-select-option
            v-for="country in countriesNotRepeatByName"
            :key="country.nameSpanish"
          >
            {{ country.nameSpanish }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Tipo de operación -->
      <a-form-item
        label="Tipo de operación"
        class="section-form-item"
        hasFeedback
      >
        <a-select
          v-decorator="[
            'operation',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
              ],
            },
          ]"
          mode="multiple"
          placeholder="Selecciona aquí"
          :size="inputs_size"
        >
          <a-select-option v-for="operation in operations" :key="operation.key">
            {{ operation.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Via -->
      <a-form-item
        :label="`¿Cómo llegaste a ${app}?`"
        class="section-form-item"
        hasFeedback
      >
        <a-select
          v-decorator="['vias']"
          placeholder="Selecciona aquí"
          @change="handleChange"
          :size="inputs_size"
        >
          <a-select-option v-for="via in vias" :key="via.key">
            {{ via.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Aceptar -->
      <div class="checkboxes">
        <a-checkbox
          class="form__checkbox display-inline text--left"
          @change="onChangePrivacity"
        >
          Acepto los términos y políticas de seguridad de Securitec.
        </a-checkbox>
        <a-checkbox
          class="form__checkbox display-inline text--left"
          @change="onChangeSuscribe"
        >
          Deseo suscribirme al blog de Securitec.
        </a-checkbox>
      </div>
      <!-- Continuar -->
      <a-button
        html-type="submit"
        type="primary"
        style="width: 100%"
        :loading="loading"
        :size="buttons_size"
        >Continuar</a-button
      >
    </a-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyForm',
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    values: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    operations: [
      { value: 'Atención al cliente', key: 'ATC' },
      { value: 'Ventas', key: 'tele_ventas' },
      { value: 'Marketing', key: 'tele_marketing' },
      { value: 'Cobranzas', key: 'Cobranzas' },
    ],
    collaborators: ['1-10', '11-20', '21-50', '51-100', '100-200', '200+'],
    isChanges: false,
    firstChecked: false,
    secondChecked: false,
    // TODO cambiar esto a backend
    vias: [
      { value: 'Google', key: 'google' },
      { value: 'Youtube', key: 'youtube' },
      { value: 'Facebook', key: 'Facebook' },
      { value: 'Instagram', key: 'instagram' },
      { value: 'Linkedin', key: 'linkedin' },
      { value: 'Email', key: 'email_marketing' },
      { value: 'Nota de prensa', key: 'notaprensa' },
      { value: 'Recomendacion', key: 'recomendacion' },
      { value: 'Otros', key: 'otros' },
    ],
  }),

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-signup-company' })
  },
  created() {
    if (this.values) {
      this.setValues(this.values)
    }
  },
  watch: {
    values(value) {
      if (value) {
        this.setValues(value)
      }
    },
  },
  computed: {
    ...mapGetters(['countriesNotRepeatByName', 'buttons_size', 'inputs_size']),
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.firstChecked)
            return this.$message.error(
              'Debe de aceptar los términos y condiciones'
            )
          values.acceptSubscribe = this.secondChecked
          this.$emit('submitForm', values)
        }
      })
    },
    onInput() {
      this.isChanges = true
      this.$emit('isChanges', this.isChanges)
    },
    handleChange() {
      this.isChanges = true
      this.$emit('isChanges', this.isChanges)
    },
    onChangePrivacity() {
      this.firstChecked = !this.firstChecked
    },
    onChangeSuscribe() {
      this.secondChecked = !this.secondChecked
    },
    setValues(value) {
      setTimeout(() => {
        this.form.setFieldsValue({
          name: value.name,
          country: value.country,
          operation: value.operation,
          vias: value.vias,
        })
      }, 100)
    },
  },
}
</script>

<style lang="sass" scoped>
.company-form
  width: 100%
.checkboxes
  margin: 6px auto 3%
  display: flex
  flex-direction: column
  align-items: flex-start
.text
  margin: 8px auto 16px
.form__checkbox
  margin-bottom: 4px
  margin-left: 0px!important
  @include xs
    margin-bottom: 16px
  @include sm
    margin-bottom: 16px
  @include md
    margin-bottom: 16px
</style>
