const getters = {
  client: (state) => state.client,
  clients: (state) => state.clients,
  showOldTickets: (state) => state.showOldTickets,
  isLoadingOldTickets: (state) => state.isLoadingOldTickets,
  showChatSelectedTicket: (state) => state.showChatSelectedTicket,
  isLoadingSelectedTicket: (state) => state.isLoadingSelectedTicket,
}

export default getters
