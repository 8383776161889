<template>
  <a-modal
    :visible="visible"
    width="54%"
    @cancel="$emit('onClose')"
    :bodyStyle="bodyStyle"
    :footer="null"
    centered
  >
    <h5 class="heading-h5">¡Completemos los primeros pasos en Ticker!</h5>
    <p class="regular-14 modal__description">
      Completa estos pasos para que cuentes con canales conectados y chatbots
      implementados. ⚡️
    </p>
    <a-row :gutter="[16, 16]" v-if="!cardRoutes.includes(undefined)">
      <a-col
        v-for="(cardRoute, index) in cardRoutes"
        :key="cardRoute._id"
        :span="spanColumn(index)"
      >
        <card-route
          :key="cardRoute._id"
          :title="cardRoute.title"
          :description="cardRoute.abstract.description"
          :tags="cardRoute.tags"
          :picture="cardRoute.assets.picture"
          :check="cardRoute.completed"
          :show-rocket="false"
          @onSelect="$emit('onSelectRoute', cardRoute)"
          :redirectTo="
            cardRoute._id === 'integrateWsp' ? appPhoneNumberWsp : null
          "
        />
      </a-col>
    </a-row>
    <template slot="footer">
      <div class="modal__footer">
        <a-button @click="$emit('onClose')">Omitir por ahora</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import CardRoute from '@/app/shared/components/cards/CardRoute'
import tutos from '@/app/commons/utils/variables'
import { mapGetters } from 'vuex'

export default {
  name: 'RoutesStartModal',
  props: {
    visible: { type: Boolean, default: false },
  },
  components: {
    CardRoute,
  },
  data: () => ({
    bodyStyle: {
      padding: '32px',
    },
    appPhoneNumber: process.env.VUE_APP_PHONE_NUMBER,
  }),
  computed: {
    ...mapGetters(['profile']),

    cardRoutes() {
      const mainTutos = tutos.filter((tuto) => tuto.main)
      return mainTutos.map((tuto) => {
        tuto.completed = this.profile?.routes[tuto._id].completed
        tuto.tags = this.profile?.routes[tuto._id].categories
        return tuto
      })
    },
    /**
     * @returns {String}
     */
    appPhoneNumberWsp() {
      const formattedNumber = this.appPhoneNumber.replace(/\s+/g, '')
      return `https://wa.me/${formattedNumber}?text=Hola%2C%20quiero%20solicitar%20y%20activar%20mi%20WhatsApp%20Business%20API%20en%20Ticker`
    },
  },
  methods: {
    spanColumn(order) {
      switch (order) {
        case 0:
          return 24
        case 1:
          return 24
        case 2:
          return 24
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.modal
  &__footer
    display: flex
    align-items: center
    justify-content: flex-end
  &__description
</style>
