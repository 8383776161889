var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{staticClass:"text--left",attrs:{"form":_vm.form,"layout":"vertical"}},[_c('h4',{staticClass:"semibold-20 mrg-bottom-8"},[_vm._v(" Crea una nueva campaña para WhatsApp ")]),_c('p',{staticClass:"regular-14",class:{
      form__description: !_vm.error.visible,
      'mrg-bottom-0': _vm.error.visible,
    }},[_vm._v(" Completa todos los campos e importa tu campaña. Recuerda enviar plantillas a contactos que han "),_c('strong',[_vm._v(" aceptado recibir información ")]),_vm._v(" de tu negocio. Si tienes dudas de como hacerlo "),_c('a',{attrs:{"href":"https://www.securitec.pe/docs/ticker/campanas/campana-masiva-whatsapp/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" revisa nuestros docs")]),_vm._v(". ")]),(_vm.error.visible)?_c('a-alert',{staticClass:"form__alert",attrs:{"type":"error","message":_vm.error.message,"show-icon":""}}):_vm._e(),_c('a-row',[_c('a-form-item',{attrs:{"label":"Nombre de campaña"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              { required: true, message: 'Por favor rellena el campo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'name',\n          {\n            rules: [\n              { required: true, message: 'Por favor rellena el campo' },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí..."}})],1)],1),_c('a-row',[_c('a-form-item',{attrs:{"label":"Número"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'channelCompanyId',
          {
            rules: [
              { required: true, message: 'Por favor rellena el campo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]),expression:"[\n          'channelCompanyId',\n          {\n            rules: [\n              { required: true, message: 'Por favor rellena el campo' },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Selecciona un número"},on:{"change":_vm.handleChannelCompany}},_vm._l((_vm.filterSimpleChannelWhatsAppByType({
            type: 'gupshup',
          })),function(channel){return _c('a-select-option',{key:channel._id},[_vm._v(" "+_vm._s(channel.title)+" ( "+_vm._s(channel.alias ? channel.alias : 'Sin alias')+" ) ")])}),1)],1)],1),_c('a-row',{attrs:{"gutter":12}},[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Cola de destino  "),_c('a-tooltip',{attrs:{"placement":"right","overlayStyle":_vm.tooltipStyles}},[_c('template',{slot:"title"},[_vm._v(" Los tickets respondidos serán asignados a esta cola. ")]),_c('a-icon',{staticClass:"form__icon-info",attrs:{"type":"info-circle"}})],2)],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'lineId',
            {
              initialValue: !_vm.linesFilteredByChannel.length
                ? undefined
                : _vm.linesFilteredByChannel[0]._id,
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]),expression:"[\n            'lineId',\n            {\n              initialValue: !linesFilteredByChannel.length\n                ? undefined\n                : linesFilteredByChannel[0]._id,\n              rules: [\n                { required: true, message: 'Por favor rellena el campo' },\n                {\n                  whitespace: true,\n                  message: 'No se admiten espacios en blanco.',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Selecciona una cola"}},_vm._l((_vm.linesFilteredByChannel),function(line){return _c('a-select-option',{key:line._id},[_vm._v(" "+_vm._s(line.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":"Selecciona una plantilla"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'templateId',
            {
              initialValue: !_vm.simple_templates.length
                ? undefined
                : _vm.simple_templates[0]._id,
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]),expression:"[\n            'templateId',\n            {\n              initialValue: !simple_templates.length\n                ? undefined\n                : simple_templates[0]._id,\n              rules: [\n                { required: true, message: 'Por favor rellena el campo' },\n                {\n                  whitespace: true,\n                  message: 'No se admiten espacios en blanco.',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Selecciona una plantilla"},on:{"change":_vm.handleSelectTemplate}},_vm._l((_vm.simple_templates),function(template){return _c('a-select-option',{key:template._id},[_vm._v(" "+_vm._s(template.friendlyName)+" ")])}),1)],1)],1)],1),_c('a-row',[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Selecciona el tiempo máximo de respuesta  "),_c('a-tooltip',{attrs:{"placement":"right","overlayStyle":_vm.tooltipStyles}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.app)+" usará este tiempo para verificar y notificar que las respuestas a los envíos son de esta campaña o no. ")]),_c('a-icon',{staticClass:"form__icon-info",attrs:{"type":"info-circle"}})],2)],1),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'hoursOfLife',
          {
            initialValue: _vm.timeOptions[0],
            rules: [
              { required: true, message: 'Por favor rellena el campo' },
            ],
          },
        ]),expression:"[\n          'hoursOfLife',\n          {\n            initialValue: timeOptions[0],\n            rules: [\n              { required: true, message: 'Por favor rellena el campo' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Selecciona una cola"}},_vm._l((_vm.timeOptions),function(time){return _c('a-select-option',{key:time},[_vm._v(" "+_vm._s(time)+" horas ")])}),1)],1)],1),_c('a-row',{staticClass:"mrg-bottom-8",attrs:{"type":"flex","align":"middle"}},[_c('p',{staticClass:"semibold-14 mrg-bottom-0 mrg-right-8 form__label--black"},[_vm._v(" Formato para campaña ")]),_c('a-tooltip',{attrs:{"placement":"right","overlayStyle":_vm.tooltipStyles}},[_c('template',{slot:"title"},[_c('a',{attrs:{"href":"https://www.securitec.pe/docs/ticker/campanas/plantilla-predefinida-whatsapp/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Click aquí")]),_vm._v(" si deseas más info de cómo completar la plantilla predefinida. ")]),_c('a-icon',{staticClass:"form__icon-info",attrs:{"type":"info-circle"}})],2)],1),_c('div',{staticClass:"form__container-download"},[_c('a',{attrs:{"disabled":_vm.hasNewValues({
          valuesForm: _vm.form.getFieldsValue(),
          except: 'fileMessage',
        })},on:{"click":function($event){_vm.handleGenerateAndDownloadFileCsv(
          _vm.hasNewValues({
            valuesForm: _vm.form.getFieldsValue(),
            except: 'fileMessage',
          })
        )}}},[_vm._v("Click aquí para descargar archivo .CSV")])]),(false)?_c('div',{staticClass:"display-flex direction-column form__container-download align--end"},[_c('div',{staticClass:"display-flex align--center mrg-bottom-12"},[_c('a-icon',{staticClass:"form__icon mrg-right-12",attrs:{"type":"file-excel"}}),_c('p',{staticClass:"mrg-bottom-0"},[_vm._v(" Después de completar todos los campos, descarga el archivos csv y completa todos los campos ")])],1),_c('a-button',{attrs:{"ghost":"","type":"primary","icon":"download","disabled":_vm.hasNewValues({
          valuesForm: _vm.form.getFieldsValue(),
          except: 'fileMessage',
        }),"loading":_vm.isWasChanged || _vm.loadingDownload},on:{"click":_vm.handleGenerateAndDownloadFileCsv}},[_vm._v(_vm._s(_vm.nameDownload))])],1):_vm._e(),_c('p',{staticClass:"semibold-14 mrg-bottom-8 form__label--black"},[_vm._v(" Sube tu formato con los datos completos ")]),_c('p',{staticClass:"mrg-bottom-8"},[_vm._v(" Tienes un envío "),_c('strong',[_vm._v(" máximo a "+_vm._s(_vm.tier.tier)+" contactos en tu "),_c('anchor',{attrs:{"href":"https://developers.facebook.com/docs/whatsapp/messaging-limits#quality"}},[_vm._v(_vm._s(_vm.tier.type))])],1),_vm._v(" y recuerda que el tiempo de envío es de 5 segundos por contacto. ")]),_c('a-tooltip',[(
        _vm.hasNewValues({
          valuesForm: _vm.form.getFieldsValue(),
          except: 'fileMessage',
        })
      )?_c('template',{attrs:{"placement":"right"},slot:"title"},[_vm._v(" Completa todos los campos para poder subir tu archivo.")]):_vm._e(),_c('a-form-item',[_c('a-upload-dragger',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'fileMessage',
          {
            valuePropName: 'fileList',
            getValueFromEvent: _vm.normFile,
            rules: [{ required: true, message: 'Por favor sube el archivo' }],
          },
        ]),expression:"[\n          'fileMessage',\n          {\n            valuePropName: 'fileList',\n            getValueFromEvent: normFile,\n            rules: [{ required: true, message: 'Por favor sube el archivo' }],\n          },\n        ]"}],attrs:{"name":"file","multiple":false,"customRequest":_vm.uploadFile,"file-list":_vm.fileList,"before-upload":_vm.beforeUpload,"disabled":_vm.hasNewValues({
            valuesForm: _vm.form.getFieldsValue(),
            except: 'fileMessage',
          })}},[_c('p',[_c('a-icon',{staticClass:"form__icon-inbox",attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v("Sube aquí tu archivo")]),_c('p',{staticClass:"ant-upload-hint"},[_vm._v("Solo se aceptan archivos .csv")])])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }