<template>
  <authTemplate>
    <template slot="body">
      <div class="section">
        <h5 class="heading-h5 gray_10">Bienvenido a soporte al cliente 👩🏻‍💻</h5>
        <p class="body-2 mrg-bottom-0 gray_9">
          Soluciona tus dudas y consultas de manera rápida y eficiente.
        </p>
        <p class="body-2 mrg-bottom-0 gray_9">
          Escríbenos en la burbuja de chat y te atenderemos lo más pronto
          posible.
        </p>
        <h5 class="heading-h5 section__subtitle gray_10">
          Aquí tienes algunas preguntas frecuentes 📚
        </h5>
        <ul class="list__items">
          <template v-for="(item, index) in items">
            <a
              class="list__item gray_9"
              :href="item.href"
              target="_blank"
              rel="noopener noreferrer"
              :key="index"
            >
              {{ item.text }}
            </a>
            <a-divider
              :key="`divider-${index}`"
              class="section__divider"
            ></a-divider>
          </template>
        </ul>
        <a
          class="section__link"
          href="https://www.securitec.pe/docs/ticker"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visita nuestros Docs y conoce más del funcionamiento de Ticker
        </a>
        <div class="section__link--advanced">
          <social-icon type="whatsapp" :size="16" style="margin-right: 8px" />
          <a :href="appPhoneNumberWsp" target="_blank" rel="noopener noreferrer"
            >Escríbenos por WhatsApp al {{ appPhoneNumber }} para ayudarte por
            este canal</a
          >
        </div>
      </div>
    </template>
  </authTemplate>
</template>

<script>
import AuthTemplate from '@/app/shared/components/templates/AuthTemplate'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  name: 'Support',
  components: {
    AuthTemplate,
    SocialIcon,
  },
  data: () => ({
    appPhoneNumber: process.env.VUE_APP_PHONE_NUMBER,
    items: [
      {
        text: '¿Cómo solicitar el API de WhatsApp Business?',
        href: 'https://www.securitec.pe/docs/ticker/whatsapp/acceder-api-whatsapp-business/',
      },
      {
        text: '¿Cómo converso con mis clientes en Workspace?',
        href: 'https://www.securitec.pe/docs/ticker/primeros-pasos/workspace/',
      },
      {
        text: '¿Cómo creo un chatbot en Ticker?',
        href: 'https://www.securitec.pe/docs/ticker/chatbots/creacion-chatbot/',
      },
      {
        text: '¿Cómo funcionan las plantillas de WhatsApp?',
        href: 'https://www.securitec.pe/docs/ticker/whatsapp/plantillas-whatsapp/',
      },
    ],
  }),
  mounted() {
    // Script de webchat ticker
    if (document.getElementById('widget-securitec')) return // was already loaded
    const scriptTag = document.createElement('script')
    scriptTag.src = 'https://webchat.securitec.pe/widget-loader.js'
    scriptTag.setAttribute('data-id', 'widget-securitec')
    scriptTag.setAttribute('data-token', '353829c2-f6d6-4825-8f4e-dbb29316f25f')
    const body = document.body
    body.appendChild(scriptTag)
  },
  computed: {
    appPhoneNumberWsp() {
      const formattedNumber = this.appPhoneNumber.replace(/\s+/g, '')
      return `https://wa.me/${formattedNumber}`
    },
  },
}
</script>
<style lang="sass" scoped>

.gray_10
  color: $gray_10
.gray_9
  color: $gray_9
.section
  width: 500px
  text-align: center
.section__subtitle
  margin: 32px auto
.section__divider
  margin: 20px auto
.list__item
  list-style: none
  cursor: pointer
.list__items
  padding-left: 0px
.section__link
  display: block
  margin-bottom: 12px
  &--advanced
    display: flex
    align-items: center
    justify-content: center
</style>
