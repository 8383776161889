import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import format from '@/app/shared/utils/format'
// import SimpleCrypto from 'simple-crypto-js'

// const crypt_key = process.env.VUE_APP_CRYPT_KEY
// const simpleCrypto = new SimpleCrypto(crypt_key)

const actions = {
  /**Regitra una tarjeta
   * @param {vuex} context
   * @param {String} name - nombres
   * @param {String} surnames - apellidos
   * @param {Date} expirationDate - fecha que vence la tarjeta
   * @param {String} paymentMethod - metodo de pago, visa, diners, mastercard
   * @param {String} number - numero  de la tarjeta de credito
   * @param {String} securityCode
   */
  async registerCard(
    context,
    { name, surnames, expirationDate, paymentMethod, number, securityCode }
  ) {
    try {
      const response = await axios.post(`${vari.UHR}/admin/payment-methods`, {
        name,
        surnames,
        expirationDate,
        paymentMethod,
        number,
        securityCode,
      })
      return response.data
    } catch (error) {
      console.error(error)
      return error.response.data
    }
  },

  /**Editar una tarjeta
   * @param {vuex} context
   * @param {String} name - nombres
   * @param {String} surnames - apellidos
   * @param {Date} expirationDate - fecha que vence la tarjeta
   * @param {String} paymentMethod - metodo de pago, visa, diners, mastercard
   * @param {String} number - numero  de la tarjeta de credito
   * @param {String} idCard - id de la tarjeta
   */
  async updateCard(
    context,
    { name, surnames, expirationDate, paymentMethod, number, idCard }
  ) {
    console.log(context)
    try {
      const response = await axios.put(
        `${vari.UHR}/admin/payment-methods/${idCard}`,
        {
          name,
          surnames,
          expirationDate,
          paymentMethod,
          number,
        }
      )
      console.log(response)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },

  /**Eliminar una tarjeta
   * @param {vuex} context
   * @param {String} idCard - id de la tarjeta
   */
  async deleteCard(context, idCard) {
    console.log(context)
    try {
      const response = await axios.delete(
        `${vari.UHR}/admin/payment-methods/${idCard}`
      )
      console.log(response)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  /**Actualiza el metodo de pago de la suscripcion */
  async updateMethodSuscription(context, card) {
    console.log(context)
    try {
      const response = await axios.put(
        `${vari.UHR}/admin/payments/methods/subscription`,
        { card }
      )
      console.log(response)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  /**
   * Exporta los pagos de la empresa
   * @param {*}       context
   * @param {String}  invoiceId id de la cola
   */
  exportPayment(context, invoiceId) {
    let response = null
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${vari.UHM}/export/invoice?invoiceId=${invoiceId}&token=${token}`
    response = window.open(url)
    return response
  },
}

export default actions
