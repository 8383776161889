<template>
  <div class="template">
    <header-module
      title="CAMPAÑAS"
      positionTooltip="right"
      tipography="heading-h5"
      link="https://www.securitec.pe/docs/ticker/campanas/gestion-campanas/"
      textBeforeLink="Crea y lanza campañas de envío masivo, como WhatsApp."
      textAfterLink="si deseas conocer más."
    />
    <table-filters
      :columns="columns"
      :onOk="handleGoToAdd"
      :onMoreFilters="() => (filters.visible = !filters.visible)"
      okText="Crear campaña"
      @onChangeCol="handleChangeColumn"
    >
      <template slot="filters">
        <a-input-search
          class="filters__item"
          v-model="filters.search"
          @search="handleFilterStrategies"
          placeholder="Buscar por nombre de campaña"
        />

        <a-select
          class="filters__item mrg-left-12"
          v-model="filters.status"
          placeholder="Selecciona un estado"
          @change="handleFilterStrategies"
        >
          <a-select-option value="all"> Todos los estados </a-select-option>
          <a-select-option
            v-for="(value, key) in statuses_strategies"
            :key="key"
          >
            {{ value.value }}
          </a-select-option>
        </a-select>
        <!-- Rango de fecha de creación -->
        <a-range-picker
          class="filters__item mrg-left-12"
          :disabled-date="disabledDate"
          :show-time="{
            format: localePicker.lang.dateTimeFormat,
            defaultValue: [
              moment('00:00:00', 'HH:mm:ss'),
              moment('23:59:59', 'HH:mm:ss'),
            ],
          }"
          :placeholder="['Fecha de inicio', 'Fecha de fin']"
          :format="localePicker.lang.dateTimeFormat"
          :locale="localePicker"
          @ok="handleFilterStrategies"
          v-model="filters.dateRange"
        />
      </template>
    </table-filters>
    <table-strategies
      :data-source="company_strategies"
      :columns="showColumns"
      :page="filters.page"
      :total="paginate_company_strategies.total"
      :loading="loadingTable"
      @onShowDashboard="handleShowDashboard"
      @onPaginate="handleSetPaginate"
    />
    <drawer-filter-strategies
      :visible="filters.visible"
      :filters="{ ...filters }"
      @onClose="handleCloseFilters"
      @onSetFilters="handleSetFilters"
    />
    <modal-dashboard
      :visible="modalDashboard.visible"
      :dataSource="selected_strategy"
      @onClose="handleCloseDashboard"
      :wallet="walletPerStrategy"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'
import attemptMixin from '@/app/shared/mixins/attempt'
import TableStrategies from '@/app/strategies/components/TableStrategies'
import TableFilters from '@/app/shared/components/molecules/TableFilters'
import DrawerFilterStrategies from '@/app/strategies/components/DrawerFilterStrategies'
import ModalDashboard from '@/app/strategies/components/organisms/ModalDashboard'
import formatMixin from '@/app/shared/mixins/format'
import moment from 'moment'

export default {
  name: 'StrategiesTemplate',
  components: {
    HeaderModule,
    TableStrategies,
    TableFilters,
    DrawerFilterStrategies,
    ModalDashboard,
  },
  data: () => ({
    moment,
    columns: [
      {
        title: 'N°',
        dataIndex: 'order',
        key: 'order',
        fixed: 'left',
        default: true,
        width: '4em',
      },
      {
        title: 'Nombre de campaña',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        checked: true,
        disabled: true,
        width: '15em',
      },
      {
        title: 'Creado por',
        dataIndex: 'createdBy',
        key: 'createdBy',
        checked: true,
        disabled: true,
        scopedSlots: { customRender: 'createdBy' },
        width: '14em',
      },
      {
        title: 'ID de WhatsApp',
        dataIndex: 'channel_company.title',
        key: 'channel_company.title',
        scopedSlots: { customRender: 'channelTitle' },
        checked: true,
        disabled: false,
        width: '14em',
      },
      {
        title: 'Cola de destino',
        dataIndex: 'line.name',
        key: 'line.name',
        checked: true,
        disabled: false,
        width: '12em',
      },
      {
        title: 'Plantilla',
        dataIndex: 'template.friendlyName',
        key: 'template.friendlyName',
        checked: true,
        disabled: false,
        width: '12em',
      },
      {
        title: 'Progreso de campaña',
        dataIndex: 'stats_import',
        key: 'stats_import',
        scopedSlots: { customRender: 'stats_import' },
        checked: true,
        disabled: true,
        width: '18em',
      },
      {
        title: 'Reg.Válidos',
        dataIndex: 'stats_import.successes',
        key: 'stats_import.successes',
        checked: true,
        disabled: false,
        width: '8em',
      },
      {
        title: 'Reg.Inválidos',
        dataIndex: 'stats_import.total_failed',
        key: 'stats_import.total_failed',
        scopedSlots: { customRender: 'total_failed' },
        checked: true,
        disabled: false,
        width: '9em',
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'created_at',
        key: 'created_at',
        ellipsis: true,
        width: '12em',
        scopedSlots: { customRender: 'created_at' },
        checked: true,
        disabled: false,
      },
      {
        title: 'Fecha de lanzamiento',
        dataIndex: 'firstPlayAt',
        key: 'firstPlayAt',
        ellipsis: true,
        width: '12em',
        scopedSlots: { customRender: 'firstPlayAt' },
        checked: false,
        disabled: false,
      },
      {
        title: 'Acciones',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        fixed: 'right',
        default: true,
        width: '13em',
      },
    ],
    filters: {
      page: 1,
      status: 'all',
      lineId: '',
      templateId: '',
      search: '',
      userId: '',
      dateRange: [
        moment().subtract(2, 'months').startOf('day'),
        moment().endOf('day'),
      ],
      visible: false, // para el drawer
    },
    loadingTable: false,
    modalDashboard: {
      dataSource: {},
      visible: false,
    },
    // walletPerStrategy: {},
  }),
  created() {
    this.intervalAttempt(async () => {
      if (!this.company) throw 'no company'
      this.handleFilterStrategies()
    })
  },
  mixins: [attemptMixin, formatMixin],
  computed: {
    ...mapGetters([
      'company_strategies',
      'companyLines',
      'assigned_templates',
      'company',
      'paginate_company_strategies',
      'statuses_strategies',
      'companyPricing',
      'selected_strategy',
      'company_wallet',
    ]),
    /**
     * @returns {Array} columns
     */
    showColumns() {
      return this.columns.filter((column) => column.checked || column.default)
    },
    /**
     * Retorna el saldo actual y el saldo gastado por campaña
     * @returns {Object} wallet
     * @returns {String} channel - canal de la campaña
     * @returns {Object} available - saldo disponible
     * @returns {Object} spent - saldo gastado
     */
    walletPerStrategy() {
      if (!this.company_wallet || !this.selected_strategy) {
        return {}
      }
      const channel = 'whatsapp'
      return {
        channel,
        available: this.company_wallet.total,
        spent: this.selected_strategy.stats_wallet
          ? this.selected_strategy.stats_wallet.total_spent
          : '$0',
      }
    },
  },
  methods: {
    ...mapActions([
      'getCompanyStrategies',
      'deleteCompanyStrategy',
      'updateStatusStrategy',
    ]),
    ...mapMutations(['SET_SELECTED_STRATEGY']),
    /**
     * Va hacia la vista de creacion
     */
    handleGoToAdd() {
      this.$router.push({ name: 'campaigns', query: { create: true } })
    },
    /**
     * Filtra las campañas
     */
    async handleFilterStrategies() {
      this.loadingTable = true
      const response = await this.getCompanyStrategies(this.filters)
      this.loadingTable = false
      if (!response.success) return this.$message.error('Ocurrío un error')
    },
    /**
     * Deshabilita los dias a partir de mañana
     * @param {Date} current - dia actual
     */
    disabledDate(current) {
      // Can not select days after today and today
      return current && current > moment().endOf('day')
    },
    /**
     * Cierra el drawer de filtros
     */
    handleCloseFilters() {
      this.filters.visible = false
    },
    /**
     * Ejecuta el cambio en Columnas
     * @param {Boolean} checked
     * @param {String} key - id de la columna
     */
    handleChangeColumn(checked, key) {
      const column = this.columns.find((col) => col.key === key)
      if (column === undefined) return
      column.checked = checked
    },
    /**
     * Abre el dashboard
     * @param {Object} strategie
     */
    handleShowDashboard(strategie) {
      this.SET_SELECTED_STRATEGY(strategie)
      this.modalDashboard = {
        visible: true,
      }
    },
    // Cierra el dashboard
    handleCloseDashboard() {
      this.modalDashboard.visible = false
    },
    /**
     * Setea nuevos filtros
     * @param {Object} filters
     */
    handleSetFilters(filters) {
      this.filters = filters
    },
    /**
     * Setear paginacion de los productos
     * @param {Number} page
     */
    handleSetPaginate(page) {
      this.filters.page = page
      this.handleFilterStrategies()
    },
  },
}
</script>

<style lang="sass" scoped>
.template
  padding: $padding-heigth-inner
.filters__item
  width: 19em
</style>
