import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**
   *
   * @param {*} context
   * @param {Object} args
   * @param {String} args.agentUserId userId del agente
   */
  async listTicketsAgent(
    context,
    {
      agentUserId,
      status,
      idMailbox,
      seek,
      skip,
      order,
      tag,
      line,
      read,
      channel,
    }
  ) {
    try {
      if (!status) return
      return await axios.post(
        `${vari.UHR}/admin/rooms/${agentUserId}/${status}`,
        {
          idMailbox,
          search: seek,
          status,
          skip,
          order,
          tag,
          line,
          read,
          channel,
        }
      )
      // const tickets = response.data.reverse()

      // /** Busca entre los mensajes para completar el abstract de cada ticket */
      // for (let ticket of tickets) {
      //   ticket.messages.reverse()
      //   ticket.mailboxName = null // Se le agrega el campo del nombre del buzón para no perder reactividad
      //   /** Setea el ultimo mensaje al abstract de un ticket, siempre y cuando, este no sea mensaje automatico ni una nota */
      //   for (let index = ticket.messages.length - 1; index >= 0; index--) {
      //     ticket.abstract = ticket.messages[index]
      //     if (
      //       !ticket.messages[index].message.auto &&
      //       ticket.messages[index].method !== 'note'
      //     )
      //       break
      //   }
      //   /** Guarda id de tickets sin leer */
      //   if (status === 'active' && ticket.pendings) {
      //     context.commit('ADD_TICKET_UNREAD', ticket._id)
      //     context.commit('SUM_NOTIFICATIONS_MAILBOX', {
      //       idMailbox: ticket.mailboxId,
      //       quantity: 1,
      //     })
      //   }
      // }
      // await context.commit('SET_TICKETS', { status, tickets })

      // if (status === 'ended')
      //   context.commit(
      //     'UPDATE_MAILBOXNAMES_IN_TICKETS_ENDED',
      //     context.getters.mailboxes
      //   )
      // else
      //   context.commit(
      //     'UPDATE_MAILBOXNAMES_IN_TICKETS',
      //     context.getters.mailboxes
      //   )
    } catch (error) {
      console.error('[ticketModule][listStatusTickets]', error)
    }
  },
}

export default actions
