<template>
  <a-upload
    class="upload uploader-avatar"
    name="avatar"
    :multiple="false"
    :show-upload-list="false"
    :before-upload="beforeUpload"
    :customRequest="selfUpload"
  >
    <div
      class="upload__content display-flex align--center justify-content--center"
      :style="styles"
    >
      <img
        v-if="imageUrl"
        :src="imageUrl"
        style="object-fit: cover; border-radius: 50px"
        alt="Avatar de perfil"
        class="upload__picture"
      />
      <a-icon v-else type="user" class="upload__icon" />
      <div class="overlay" :class="{ 'opacity-1': loading }">
        <a-icon
          v-if="!loading"
          type="edit"
          style="font-size: 20px; color: white"
        />
        <a-icon v-else type="loading" style="font-size: 20px; color: white" />
      </div>
    </div>
  </a-upload>
</template>

<script>
export default {
  name: 'UploaderAvatar',
  props: {
    height: {
      type: String,
      default: '80px',
    },
    width: { type: String, default: '80px' },
    defaultAvatar: { type: String, required: false },
  },
  data: () => ({
    imageUrl: '', // url de imagen
    loading: false,
  }),
  watch: {
    defaultAvatar(newVal) {
      this.imageUrl = newVal
    },
  },
  computed: {
    styles() {
      const styles = {}
      styles.height = this.height
      styles.width = this.width

      return styles
    },
  },
  methods: {
    /**
     * Checkea el peso de los archivos antes de subirse
     * @param {Object} file - archivo que se subirá
     * @param {Number} file.size
     * @param {String} file.type
     */
    beforeUpload(file) {
      const typeFiles = ['image/jpeg', 'image/png', 'image/jpg']
      const isJpgOrPng = typeFiles.includes(file.type)
      if (!isJpgOrPng) {
        this.$message.error('Solo puedes subir imágenes png o jpg')
      }
      const isLt3M = file.size / 1024 / 1024 <= 3
      if (!isLt3M) {
        this.$message.error('El tamaño máximo permitido es de 3MB')
      }
      return isJpgOrPng && isLt3M
    },
    /**
     * Subida local
     * @param {Object} args
     * @param {String} args.action
     * @param {String} args.file
     * @param {Function} args.onSuccess
     * @param {Function} args.onError
     * @param {Function} args.onProgress
     */
    selfUpload({ file }) {
      this.loading = true
      new Promise((resolve) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          this.fileImage = file // binary image
          this.$emit('onChange', {
            file: this.fileImage,
            imageUrl: fileReader.result,
          })
          this.imageUrl = fileReader.result
          resolve(fileReader.result)
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.upload__content
  position: relative
  cursor: pointer
  background-color: $gray_3
  border-radius: 50px
  border: 1px solid $gray_4
  .overlay
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100%
    width: 100%
    opacity: 0
    transition: .3s ease
    background: rgba(0, 0, 0, 0.4)
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50px
  &:hover
    .overlay
      opacity: 1
.upload__picture
  object-fit: cover
  border-radius: 50px
  height: 100%
  width: 100%
.upload__icon
  font-size: 24px
  color: $gray_7
</style>
