import chatbotModule from './chatbotModule'

const actions = {
  /** Resetear Todos los Submodulos del Módulo Chatbots */
  resetMoChatbot(context) {
    context.commit('RESET_CHATBOT_MODULE')
  },
}

const modules = {
  chatbotModule,
}

export default {
  modules,
  actions,
}
