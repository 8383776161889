<template>
  <box-template>
    <custom-section-scroll
      :master-styles="{
        height: `calc(100vh - (${heightFooter} + ${margin}))`,
        width: '100%',
        padding: '8px 0px',
      }"
      :content-styles="{
        margin: '3em auto 0px',
        maxWidth: '1000px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }"
    >
      <a-row :gutter="[100]" type="flex" justify="center">
        <a-col :xs="10" :sm="8" :md="12" :lg="11" :xl="12" :xxl="12">
          <add-strategy-form
            ref="add_strategy_form"
            @onChange="handleChangeForm"
            @onLoading="handleLoadingForm"
            @onHasNewValues="(hasNew) => (isDisabledOk = hasNew)"
          />
        </a-col>
        <a-col :xs="4" :sm="6" :md="11" :lg="11" :xl="10" :xxl="10">
          <a-row
            type="flex"
            justify="center"
            align="middle"
            class="full-height"
          >
            <mobile-wsp :message="mobileMessage" />
          </a-row>
        </a-col>
      </a-row>
    </custom-section-scroll>
    <basic-footer
      okText="Crear campaña"
      cancelText="Cancelar"
      :okLoading="isLoadingOk"
      :okDisabled="isDisabledOk"
      :cancelDisabled="isDisabledCancel"
      @onOk="handleSubmit"
      @onCancel="handleCancel"
    >
      <p slot="extra" class="mrg-bottom-0">
        <a-icon type="info-circle" /> Las respuestas a estos envíos serán
        transferidas como tickets directamente a tus agentes, sin pasar por un
        chatbot.
      </p>
    </basic-footer>
  </box-template>
</template>

<script>
import BoxTemplate from '@/app/shared/components/templates/BoxTemplate'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import AddStrategyForm from '@/app/strategies/components/forms/AddStrategyForm'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import MobileWsp from '@/app/strategies/components/MobileWsp'
import { mapGetters } from 'vuex'

export default {
  name: 'AddStrategyTemplate',
  components: {
    BoxTemplate,
    BasicFooter,
    AddStrategyForm,
    CustomSectionScroll,
    MobileWsp,
  },
  data: () => ({
    heightFooter: '53px',
    margin: '24px',
    isLoadingOk: false,
    isDisabledOk: false,
    isDisabledCancel: false,
    mobileMessage: null,
  }),
  computed: {
    ...mapGetters(['simple_templates']),
  },
  methods: {
    async handleSubmit() {
      await this.$refs.add_strategy_form.handleSubmit()
    },
    handleCancel() {
      this.$router.push({ name: 'campaigns' })
    },
    handleChangeForm(key, value) {
      if (key === 'templateId')
        this.mobileMessage = this.simple_templates.find(
          (template) => template._id === value
        ).body
    },
    handleLoadingForm(loading) {
      this.isLoadingOk = loading
      this.isDisabledOk = loading
      this.isDisabledCancel = loading
    },
  },
}
</script>

<style lang="sass" scoped>
.form__icon
  font-size: 32px
</style>
