<template>
  <a-form :form="form" layout="vertical">
    <a-form-item label="Nombre de chatbot">
      <a-input
        placeholder="Escribe aquí..."
        v-decorator="[
          'name',
          {
            rules: [
              { required: true, message: 'Por favor rellene el campo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]"
        :maxLength="70"
      />
    </a-form-item>
    <a-form-item label="Descripción de chatbot">
      <a-textarea
        placeholder="Escribe aquí..."
        v-decorator="[
          'description',
          {
            rules: [
              { required: true, message: 'Por favor rellene el campo' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]"
        :auto-size="{ minRows: 3, maxRows: 5 }"
        :maxLength="maxDescription"
        @input="handleInput"
      />
      <span class="counter"
        >{{ currentDescription ? currentDescription.length : 0 }}/{{
          maxDescription
        }}</span
      >
    </a-form-item>
    <a-row>
      <a-col class="form__info-extra">
        <p class="semibold-14">Recuerda</p>
        <p class="info_i2">
          El chatbot no atenderá tickets de WhatsApp si es que no cuentas con
          saldo disponible para este canal.
        </p>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GeneralForm',
  props: {
    formValues: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'create',
    },
  },
  data: () => ({
    currentDescription: '',
    maxDescription: 150,
    channelsSelected: [], // canales seleccionados
    channelsDesasigned: [],
    newChannels: [], // canales nuevos
    channelsAsigned: [], // canales asignados
    appPhoneNumber: process.env.VUE_APP_PHONE_NUMBER,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'general-form-chatbot' })
  },
  computed: {
    ...mapGetters(['company']),
    channelsCompany() {
      const array = Object.keys(this.company.channels)
        .map((channel) => {
          let api = this.company.channels[channel]
          api.name = channel
          if (channel === 'messenger') api.order = 2
          if (channel === 'whatsapp') api.order = 1
          return api
        })
        .filter((channel) => channel.active)
      return array.sort((a, b) =>
        a.order > b.order ? 1 : a.order < b.order ? -1 : 0
      )
    },
    /**
     * @returns {String}
     */
    appPhoneNumberWsp() {
      const formattedNumber = this.appPhoneNumber.replace(/\s+/g, '')
      return `https://wa.me/${formattedNumber}?text=Hola%2C%20quiero%20solicitar%20asesoria%20de%20un%20experto%20en%20Ticker`
    },
  },
  methods: {
    handleInput(e) {
      this.currentDescription = e.target.value
    },
    // evento de click en card small channel
    handleSelectChannel(id) {
      if (this.type === 'create') {
        const includeChannel = this.channelsSelected.includes(id)
        // si esta dentro de los canales seleccionados
        if (includeChannel)
          this.channelsSelected = this.channelsSelected.filter(
            (channel) => channel !== id
          )
        else this.channelsSelected.push(id)
        this.$store.dispatch('setAnalytics', {
          eventName: 'chatbot_channel_selected',
          extraData: { channel: id, modal: 'create_chatbot' },
        })
      } else {
        const includeChannel = this.channelsSelected.includes(id)
        // si esta dentro de los canales seleccionados
        if (includeChannel) {
          // si esta detro de canales selecciondos dentro de record
          if (this.channelsAsigned.includes(id)) {
            if (!this.channelsDesasigned.includes(id))
              this.channelsDesasigned.push(id)
          } else {
            this.newChannels.includes(id)
              ? (this.newChannels = this.newChannels.filter(
                  (channel) => channel !== id
                ))
              : this.newChannels.push(id)
          }
          this.channelsSelected = this.channelsSelected.filter(
            (channel) => channel !== id
          )
        }
        // si no esta incluido dentro de los canales seleccionados
        else {
          this.channelsSelected.push(id)
          this.channelsDesasigned = this.channelsDesasigned.filter(
            (ele) => ele !== id
          )
          if (!this.channelsAsigned.includes(id)) {
            this.newChannels.includes(id)
              ? (this.newChannels = this.newChannels.filter(
                  (channel) => channel !== id
                ))
              : this.newChannels.push(id)
          }
        }
      }
    },
    setFieldsValues() {
      this.form.setFieldsValue({
        name: this.formValues.name,
        description: this.formValues.description,
      })
      this.channelsSelected = this.formValues.channels
      this.channelsAsigned = [...this.formValues.channels]
    },
    resetFields() {
      this.form.resetFields()
      this.channelsSelected = [] // canales seleccionados
      this.channelsDesasigned = []
      this.newChannels = [] // canales nuevos
      this.channelsAsigned = [] // canales asignados
    },
  },
}
</script>

<style lang="sass" scoped>
.counter
  position: absolute !important
  bottom: 5px
  right: 12px
  color: $gray_light_300
  font-size: 10px
.form
  &__label
    display: flex
    align-items: center
    margin-bottom: 4px
    color: $gray_dark_900
  &__icon
    margin-left: 4px
  &__info-extra
  max-width: 80%
</style>
