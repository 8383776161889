<template>
  <div class="container-counter">
    <slot></slot>
    <span class="counter" :style="stylesCounter"
      >{{ text ? text.length : 0 }}/{{ maxLength }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'CounterText',
  props: {
    maxLength: { type: Number, default: 0 },
    text: { type: String, required: true },
    stylesCounter: { type: Object, required: false },
  },
}
</script>

<style lang="sass" scoped>
.container-counter
  position: relative
.counter
  position: absolute
  bottom: 5px
  right: 12px
  color: $gray_light_300
  font-size: 10px
</style>
