<template>
  <a-drawer
    :visible="visible"
    placement="right"
    :closable="true"
    :title="title"
    :zIndex="1000"
    :width="drawer_filters.width"
    class="modal-details-contact"
    centered
    :footer="null"
    @close="handleHide"
    :bodyStyle="{ padding: '0px' }"
    :mask="false"
  >
    <CustomSectionScroll
      :masterStyles="{ height: 'calc(100vh - 108px)' }"
      idMaster="drawer-details-contact"
      :contentStyles="{ padding: '20px' }"
    >
      <!-- Nombres -->
      <a-row>
        <div class="avatar">
          <a-avatar
            v-if="dataSourceClient.avatar"
            :src="dataSourceClient.avatar"
            :size="40"
            style="margin: 0 8px 0 0"
            alt="Avatar de perfil"
          />
          <a-avatar
            v-else
            :size="40"
            style="margin: 0 8px 0 0"
            icon="user"
            alt="Avatar de perfil"
          />
          <span :class="{ info_i2: fullNames.empty }">{{
            fullNames.empty ? fullNames.text : fullNames
          }}</span>
        </div>
      </a-row>
      <!-- Canales -->
      <a-row>
        <div>
          <div class="subtitle">
            <span>Canales de comunicación</span>
            <a-divider class="divider-container" />
          </div>
          <div class="channels display-flex">
            <span
              v-for="channel in channelsAssignedToClient"
              :key="channel.channel"
              class="mrg-right-12"
            >
              <popover-channel
                :channel="channel.channel"
                :list-titles="channel.titles"
              />
            </span>
          </div>
        </div>
      </a-row>
      <!-- Agentes asignados -->
      <a-row>
        <div class="list">
          <div class="subtitle">
            <span>Agentes asignados</span>
            <a-divider class="divider-container" />
          </div>
          <ul>
            <li v-for="profile in usersAsigned" :key="profile._id">
              {{ profile.names }} {{ profile.surnames }}
            </li>
          </ul>
        </div>
      </a-row>
      <!-- Datos personales -->
      <a-row>
        <div>
          <div class="subtitle">
            <span>Datos personales</span>
            <a-divider class="divider-container" />
          </div>
          <client-fields
            :data-source-client="dataSourceClient"
            @update="onUpdateClient"
          />
        </div>
      </a-row>
    </CustomSectionScroll>
    <!-- Botones -->
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button type="link" @click="handleTickets">
        Ver todos los tickets
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import transformMixin from '@/app/shared/mixins/transform'
import supportMixin from '@/app/shared/mixins/support'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import PopoverChannel from '@/app/shared/components/organisms/PopoverChannel'
import ClientFields from '@/app/shared/components/organisms/ClientFields'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'DrawerDetailsContact',
  components: {
    CustomSectionScroll,
    PopoverChannel,
    ClientFields,
  },
  props: {
    visible: { type: Boolean },
    dataSourceClient: { type: Object, required: true },
    dateRange: { type: Array, required: false },
  },
  data: () => ({
    moment,
    title: 'Detalles del cliente',
    usersAsigned: [],
  }),
  created() {
    this.loadData()
  },
  mixins: [transformMixin, supportMixin],
  computed: {
    ...mapGetters(['max_months_to_get', 'drawer_filters']),

    /**
     * Nombre del cliente
     * @returns {String}
     */
    fullNames() {
      if (!this.dataSourceClient) return ''
      let fullname = ''
      if (!this.dataSourceClient.names && !this.dataSourceClient.surnames)
        fullname = { empty: true, text: 'Sin Nombre' }
      else
        fullname = `${
          this.dataSourceClient.names ? this.dataSourceClient.names : ''
        } ${
          this.dataSourceClient.surnames ? this.dataSourceClient.surnames : ''
        }`
      return fullname
    },
    /**
     * Canales asignado a los chatbots
     * @return {Object}
     */
    channelsAssignedToClient() {
      const channels = [...this.dataSourceClient.channels_companies].reduce(
        (acc, current) => {
          const isIncludeInAcc = acc[current.channel]
          const alias = this.channelCompanyAlias(current._id)
          if (!isIncludeInAcc)
            acc[current.channel] = {
              _id: current.channel, // id del canal global
              channel: current.channel,
              titles: [alias ? `${current.title} ( ${alias} )` : current.title], // ids de los canales conectados que tienen este canal
              name: this.transformWord(current.channel, 'capitalize'),
            }
          else
            acc[current.channel].titles.push(
              alias ? `${current.title} ( ${alias} )` : current.title
            )
          return acc
        },
        {}
      )
      return channels
    },
  },
  methods: {
    ...mapActions(['fetchUsersOfContact', 'getAllTicketsToManage']),
    ...mapMutations([
      'SET_FETCHED_FROM_OTHER_MODULE',
      'SET_CLIENTSID_FILTER_TICKET',
      'SET_RANGE_FILTER_TICKETS',
    ]),
    /**
     * Actualizar datos del cliente
     * @param {Object} client
     */
    onUpdateClient(client) {
      this.$emit('update', client)
    },
    async handleTickets() {
      const clientsId = this.dataSourceClient.merges
        .map((cl) => cl.clientId)
        .concat(this.dataSourceClient._id)
      this.SET_FETCHED_FROM_OTHER_MODULE(true)
      this.$router.push({ name: 'tickets' })
      this.SET_CLIENTSID_FILTER_TICKET(clientsId)

      this.SET_RANGE_FILTER_TICKETS([])
      await this.getAllTicketsToManage({
        clientsId: clientsId,
        tags: [],
        customFields: [],
        idMailbox: 'all',
        page: 1,
        dateRange: [],
        status: 'all',
        read: 'all',
        line: 'all',
        channel: 'all',
        favorite: 'all',
        ticketStatuses: ['queued', 'issued', 'opened', 'ended'],
        order: 'new',
      })
      setTimeout(() => {
        this.SET_FETCHED_FROM_OTHER_MODULE(false)
      }, 2000)
    },
    handleHide() {
      this.$emit('hide')
    },
    async loadData() {
      this.usersAsigned = await this.fetchUsersOfContact({
        clientId: this.dataSourceClient._id,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.modal-details-contact
  .avatar
    display: flex
    align-items: center
  .subtitle
    margin-top: 20px
    span
      font-weight: 600
    .divider-container
      margin: 2px 0 8px 0
  .channels
    display: flex
    .channel
      margin-right: 8px
  .list
    >ul
      padding: 0 1.5em
      margin: 0
  .content
    >ul
      list-style: none
      margin: 0
      padding: 0
      li
        margin: 0 0 8px 0
        p
          font-weight: 600
          margin: 0 0 4px 0
  .buttons
    width: 100%
    display: flex
    justify-content: center
</style>

<style lang="sass">
.modal-details-contact
  z-index: 1000
</style>
