/**
 * Identifica la raiz de todos los nodos
 * @param {Array} nodes
 */
const identifyNodeRoot = (nodes) => {
  return nodes.find((elem) => !elem.connection.sourceId)
}

/**
 * Asigna hijos a un padre
 * @param {Array} father el padre de nodos
 * @param {Array} nodes los nodos
 */
const assignChild = (father, nodes) => {
  const typeFather = father.drawer.question.type
  // Si el padre es de tipo mensaje
  if (typeFather === 'message') {
    if (father.drawer.action.type === 'continue') {
      const child = nodes.find(
        (node) => node._id === father.drawer.action.nodeId
      )
      if (child !== undefined) {
        child.parentNodeId = father._id
        father.child = child
        if (
          child.drawer.options ||
          (child.drawer.action && child.drawer.action.type === 'continue')
        ) {
          assignChild(child, nodes)
        }
      } else {
        delete father.child
      }
    }
  }
  if (typeFather === 'options') {
    /**Ordenar las opciones */
    const options = father.drawer.options.sort((a, b) => a.order - b.order)
    options.map((option) => {
      option.parentNodeId = father._id
      if (option.action.type === 'continue') {
        const child = nodes.find((node) => node._id === option.action.nodeId)
        if (child !== undefined) {
          child.parentNodeId = option.idOption
          option.child = child
          if (
            child.drawer.options ||
            (child.drawer.action && child.drawer.action.type === 'continue')
          ) {
            assignChild(child, nodes)
          }
        } else {
          delete option.child
        }
      }
    })
  }
}

/**
 * Genera el arbol de nodos
 * @param {Array} nodes
 */
const generateTreeNodes = (nodes) => {
  let tree = {}
  tree = identifyNodeRoot(nodes)
  if (tree !== undefined) {
    assignChild(tree, nodes)
    tree.default = true
  }
  return tree
}

export default generateTreeNodes
