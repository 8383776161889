<template>
  <div class="modal-sandbox">
    <a v-if="isLink" class="body-2 link-text-channels" @click="openFirst">
      <u v-if="underline">{{ nameButton }}</u>
      <template v-else>{{ nameButton }}</template>
    </a>
    <a-button v-else-if="isButton" type="primary" @click="openFirst">
      {{ nameButton }}
    </a-button>
    <a v-else @click="openFirst">{{ nameButton }}</a>

    <a-modal
      v-model="visible"
      :footer="null"
      :bodyStyle="bodyStyle"
      :afterClose="afterClose"
      @cancel="handleClose"
      centered
    >
      <div
        v-if="currentStep === 'one'"
        class="first-step"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="@/app/shared/assets/channels/sandbox-1.png"
          style="margin-bottom: 32px"
          class="modal__picture"
        />
        <h5 class="heading-h5 text-align-center" style="margin-bottom: 8px">
          Prueba nuestro canal de WhatsApp con nuestra Sandbox
        </h5>
        <p class="body-2 text-align-center" style="margin-bottom: 20px">
          Sandbox es un número virtual que te servirá para enviar y recibir
          mensajes de WhatsApp, como si fueras un cliente. Simula la experiencia
          de gestionar un ticket de WhatsApp en
          {{ app }}.
        </p>
        <a-button type="primary" @click="handleNext" style="width: 200px"
          >Continuar</a-button
        >
        <a
          href="https://www.securitec.pe/docs/ticker/primeros-pasos/sandbox-whatsapp-business-api/"
          target="_blank"
          rel="noopener noreferrer"
          style="margin-top: 8px; margin-bottom: -15px"
          >Ver más información
        </a>
      </div>
      <div
        v-else
        class="second-step"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="@/app/shared/assets/channels/sandbox-2.png"
          style="margin-bottom: 32px"
          class="modal__picture"
        />
        <h5 class="heading-h5 text-align-center">
          Prueba WhatsApp con nuestra Sandbox en un click
        </h5>
        <p class="body-2 text-align-center">
          Envía el mensaje <strong>"join {{ company.sufix }}"</strong> al número
          <strong>{{ sandboxNumber }}</strong
          >. El mensaje llegará como un ticket en Workspace y podrás responder
          desde la plataforma para que simules el uso del canal.
        </p>
        <div style="display: flex; flex-direction: column; align-items: center">
          <a
            @click="
              setAnalytics({
                eventName: 'sandbox_connect',
                extraData: { channel: 'whatsapp' },
              })
            "
            :href="sandboxWsp"
            target="_blank"
            rel="noopener noreferrer"
            class="ant-btn ant-btn-primary"
            >Enviar mensaje</a
          >
          <a style="margin-top: 8px; margin-bottom: -15px" @click="handleClose"
            >No, por el momento</a
          >
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import { getSandboxIdentifier } from '@/app/channels/services/channel_sandbox_services'
export default {
  name: 'ModalSandbox',
  props: {
    nameButton: {
      type: String,
    },
    step: {
      type: String,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    underline: {
      type: Boolean,
      default: true,
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    visible: false,
    currentStep: 'one',
    bodyStyle: {
      padding: '52px 40px',
    },
    sandboxNumber: '',
  }),
  async created() {
    // SE HABILITARÁ PROXIMAMENTE, ES NECESARIO OPTIMIZAR
    // this.sandboxNumber = await getSandboxIdentifier('whatsapp')
  },
  mounted() {
    this.currentStep = this.step
  },
  computed: {
    ...mapGetters(['company']),
    /**
     * @returns {String}
     */
    sandboxWsp() {
      if (!this.sandboxNumber) return '#'
      const formattedNumber = this.sandboxNumber.replace(/\+/g, '')
      return `https://wa.me/${formattedNumber}?text=join%20${this.company.sufix}`
    },
  },
  methods: {
    ...mapActions(['setAnalytics']),
    ...mapMutations(['SET_TUTO_MODAL']),

    openFirst() {
      this.visible = true
    },
    handleNext() {
      this.currentStep = 'two'
      this.setAnalytics({
        eventName: 'sandbox_continue',
        extraData: { channel: 'whatsapp' },
      })
    },
    afterClose() {
      this.currentStep = this.step
    },
    handleClose() {
      this.visible = false
      this.SET_TUTO_MODAL({ visible: false })
      this.setAnalytics({
        eventName: 'sandbox_modal_close',
        extraData: { channel: 'whatsapp' },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.text-align-center
  text-align: center
.modal-sandbox
  .first-step
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
.link-text-channels
    text-align: right
    font-size: 12px
    line-height: 20px
    margin: 0px
    position: relative
.modal__picture
  height: 300px
  width: 100%
  object-fit: cover
  cursor: auto
</style>
