const initialState = () => ({
  profile: null,
  profileRemote: null,
  modalRemoteLogout: {
    statusModal: false,
    typeUser: null,
  },
  modalSessionExpired: {
    statusModal: false,
    typeUser: null,
  },
  internet: true,
  news: [],
  tutoModal: { visible: false },
  modalStepsWBA: { visible: false },
  modalCreateChatbot: { visible: false },
  reloadNotification: { visible: true },
  notification_with_type: { visible: false }, // notificaciones con tipo especifico
})

export default initialState
