<template>
  <a-modal :visible="visible" :title="title" @cancel="handleCancel" centered>
    <div>
      <a-form :form="form" layout="vertical">
        <p class="regular-14 form__description">
          {{ description }}
        </p>
        <a-form-item label="Selecciona el rango de fecha">
          <!-- Rango de fecha de creación -->
          <a-range-picker
            class="form__item"
            v-decorator="[
              'dateRange',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellena el campo',
                  },
                  { validator: validateMaxMonths },
                ],
              },
            ]"
            :disabled-date="disabledDateAfterCurrent"
            :show-time="{
              format: localePicker.lang.dateTimeFormat,
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('23:59:59', 'HH:mm:ss'),
              ],
            }"
            :placeholder="['Fecha de inicio', 'Fecha de fin']"
            :format="localePicker.lang.dateTimeFormat"
            :locale="localePicker"
            style="width: 100%"
          />
        </a-form-item>
      </a-form>
      <basic-footer
        slot="footer"
        cancelText="Cancelar"
        :okText="okText"
        :okLoading="okLoading"
        :cancelDisabled="disabledActions"
        :okDisabled="disabledActions"
        @onOk="handleOk"
        @onCancel="handleCancel"
      />
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import globalMixin from '@/app/commons/mixins/global'
import formatMixin from '@/app/shared/mixins/format'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import moment from 'moment'

export default {
  name: 'ModalDownloadCrm',
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    title: { type: String, require: true },
    description: { type: String, require: true },
    onOk: {
      type: Function,
      require: true,
    },
  },
  components: {
    BasicFooter,
  },
  data: () => ({
    okLoading: false,
    disabledActions: false,
    moment,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'FormDownloadCrm' })
  },
  mixins: [globalMixin, formatMixin],
  computed: {
    ...mapGetters(['max_months_to_get']),

    /**
     * @return {String} texto del boton principal
     */
    okText() {
      return this.okLoading ? 'Descargando' : 'Descargar'
    },
  },
  methods: {
    /**
     * Ejecuta la descarga
     */
    handleOk() {
      this.okLoading = true
      this.disabledActions = true
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await this.onOk(values.dateRange)
          this.handleCancel()
        }
      })
      this.okLoading = false
      this.disabledActions = false
    },
    /**
     * Cancela la accion
     */
    handleCancel() {
      this.$emit('onClose')
      this.form.resetFields()
    },
    /**
     * Compara si los dominios se repiten
     * @param {Object} rule - regla para el formulario
     * @param {Array} value - valor del campo
     * @param {Function} callback - función que ejecuta el llamado de errores
     */
    validateMaxMonths(rule, value, callback) {
      if (!value) return callback()
      const last2Months = [
        moment().subtract(this.max_months_to_get, 'months').startOf('day'),
        moment().endOf('day'),
      ]
      // diferencia de los 2 ultimos meses
      const diffLast2Months = last2Months[1].diff(
        last2Months[0],
        'months',
        true
      )
      const diff = value[1].diff(value[0], 'months', true)
      // Si es mayor a 2 meses
      if (diff > diffLast2Months)
        callback(
          new Error(
            `No permite un rango mayor a ${this.max_months_to_get} meses`
          )
        )
      else {
        callback()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.form
  &__description
    margin-bottom: 20px
</style>
