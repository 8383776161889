<template>
  <div class="contain">
    <a-modal
      :visible="visible"
      title="Crear nuevo usuario"
      width="50em"
      class="contain_modal"
      :afterClose="afterClose"
      :maskClosable="false"
      centered
      :footer="null"
      :bodyStyle="{ padding: '0px' }"
      @cancel="handleCloseModal"
    >
      <FormBaseAccount
        ref="form_base_account"
        width="100%"
        :disabledFieldUserType="disabledFieldUserType"
        @onSubmit="handleCreateAccount"
        :countries="countries"
        okText="Crear nuevo usuario"
        cancelText="Cancelar"
        :requirePassword="true"
        :showFieldStatusUser="false"
        :lines="lines"
        @onCancel="handleCloseModal"
        :loading="loadingForm"
        :assigned-lines="defaultLinesSelected"
      />
    </a-modal>
    <slot name="button">
      <a-button type="primary" @click="handleOpenModal" icon="plus"
        >Crear nuevo usuario</a-button
      >
    </slot>
  </div>
</template>

<script>
import FormBaseAccount from '@/app/accounts/components/forms/FormBaseAccount'
import compareMixin from '@/app/shared/mixins/compare'
import { mapGetters } from 'vuex'

export default {
  name: 'ModalCreateAccount',
  mixins: [compareMixin],
  components: {
    FormBaseAccount,
  },
  props: {
    loadingForm: {
      type: Boolean,
      require: false,
      default: false,
    },
    success: {
      type: Boolean,
      require: false,
      default: false,
    },
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data: () => ({
    disabledFieldUserType: false,
    defaultLinesSelected: [],
  }),
  watch: {
    success(val) {
      if (val === true) {
        this.handleCloseModal()
      }
    },
    visible(val) {
      if (val) this.setDefaultLines()
    },
  },
  computed: {
    ...mapGetters(['profile', 'countries', 'roles', 'lines']),
    /** Retorna el tipo de usuario
     * @returns {string | null}
     **/
    typeUser() {
      if (this.isAllowedFor(this.profile.type, ['supervisor', 'admin_agent']))
        return 'agent'
      else return null
    },
  },
  methods: {
    /**Abre el modal de crear nuevo usuario */
    handleOpenModal() {
      this.$emit('open')
    },
    /**Cerrar modal */
    handleCloseModal() {
      this.$emit('cancel')
      this.$refs.form_base_account.handleResetForm()
      this.$store.dispatch('setAnalytics', {
        eventName: 'modal_close_create_user',
      })
    },
    /**Crea una cuenta */
    handleCreateAccount(values) {
      this.$emit('onSubmit', values, 'create')
    },
    // se ejecuta despues q el modal es cerrado
    afterClose() {
      this.visible = false
      this.$refs.form_base_account.handleResetForm()
      this.disabledFieldUserType = false
      this.defaultLinesSelected = []
    },
    /**
     * Setea las colas que por defecto tendra el formulario de crear
     */
    setDefaultLines() {
      const defaultLines = this.lines.filter((line) => line.default)
      this.defaultLinesSelected = defaultLines.map((line) => {
        const obj = { key: line._id, title: line.name, disabled: false }
        return obj
      })
    },
  },
}
</script>
