<template>
  <div class="header-chatbot flex-row">
    <div class="first-section flex-row">
      <a-button
        :data-test="DATA_TEST.CHATBOT.EDIT.RETURN_TO_LIST_BUTTON"
        icon="arrow-left"
        @click="showConfirm"
        >Volver a los chatbots</a-button
      >
      <div class="summary-info">
        <div class="flex-row first">
          <p
            class="heading-h7 name"
            style="margin-bottom: 0px; margin-right: 8px"
          >
            {{ updatedName ? updatedName : chatbot.name }}
          </p>
          <a-tag :color="status.color">
            {{ status.status }}
          </a-tag>
        </div>
        <p class="body-1 update" style="margin-bottom: 0px; text-align: left">
          Guardado por última vez {{ moment(updated_at).calendar() }}
        </p>
      </div>
    </div>
    <a-button @click="handleOpenSettings">Configurar mi chatbot</a-button>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapMutations } from 'vuex'
import transformMixin from '@/app/shared/mixins/transform'
import { DATA_TEST } from '@/app/shared/utils/dataTest'

export default {
  name: 'HeaderChatbot',
  props: {
    chatbot: {
      type: Object,
      required: true,
    },
    updated_at: {
      type: [Date, String],
      required: true,
    },
    updatedName: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    DATA_TEST,
    moment,
  }),
  mixins: [transformMixin],
  computed: {
    ...mapGetters(['changes', 'isSavedNode', 'profile']),

    /**Estado del chatbot, retorna un objeto con el color del tag y el estado */
    status() {
      if (this.active) return { color: 'green', status: 'Activo' }
      else return { color: '', status: 'Inactivo' }
    },
  },
  methods: {
    ...mapMutations(['SUM_CHANGES', 'SET_IS_SAVED_NODE']),

    handleOpenSettings() {
      this.$emit('onOpenSettings')
    },
    showConfirm() {
      if (this.changes > 0 || !this.isSavedNode) {
        let self = this
        this.$confirm({
          title: `¿Estás  ${this.transformWordGender(
            'seguro',
            this.profile.gender
          )} de “Volver a los chatbots”?`,
          content:
            'Recuerda que tu configuración y todos los cambios no guardados  se perderán.',
          okText: 'Volver',
          cancelText: 'Cancelar',
          onOk() {
            self.$router.push({ name: 'chatbots' })
            self.SUM_CHANGES('reset')
            self.SET_IS_SAVED_NODE(true)
          },
        })
      } else {
        this.$router.push({ name: 'chatbots' })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.flex-row
  display: flex
  flex-direction: row
  align-items: center
.header-chatbot
  width: 100%
  background-color: $white_000
  padding: 12px
  justify-content: space-between
  .first-section
    .summary-info
      margin-left: 12px
      .first
        margin-bottom: 2px
      .update
        color: $gray_dark_800
</style>
