<template>
  <a-menu
    class="menu-notifications"
    :class="{
      'menu-notifications--collapse': menuCollapse,
      'menu-notifications--no-collapse': !menuCollapse,
    }"
    mode="vertical"
    @openChange="handleOpenChangePopup"
  >
    <a-sub-menu
      key="submenu-notifications"
      class="menu-notifications__submenu"
      placement="rightBottom"
      :popupClassName="`popup-menu-notifications ${transformPopupClassName}`"
      :class="{
        'menu-notifications--selected': $route.name === 'notifications',
      }"
      @titleClick="handleGoToNotifications"
      :disabled="disabled"
    >
      <span slot="title" class="menu-notifications__item">
        <a-badge
          :dot="
            profile.notifications && profile.notifications > 0 ? true : false
          "
          style="display: flex; align-items: center"
          :class="{ 'mrg-right-12': menuCollapse }"
        >
          <a-icon type="bell" class="menu-notifications__icon" />
        </a-badge>
        <span v-if="menuCollapse" class="body-3">Notificaciones</span>
      </span>
      <template v-if="showPopup">
        <a-menu-item
          style="
            width: 332px;
            max-height: 328px;
            height: max-content;
            min-height: 200px;
            cursor: default;
          "
          class="item menu-item"
          disabled
        >
          <div class="header">
            <h6 class="heading-h7">Notificaciones</h6>
          </div>
          <a-list
            item-layout="horizontal"
            :data-source="notifications.slice(0, 3)"
            style="padding: 0px 12px"
            :locale="{ emptyText: 'No hay notificaciones' }"
          >
            <a-list-item slot="renderItem" slot-scope="notification">
              <a-list-item-meta>
                <template slot="description">
                  <p class="body-1">
                    <strong class="heading-h8">
                      {{ notification.user.fullName }}
                    </strong>
                    {{
                      notification.message
                        ? notification.message.es
                        : 'Sin comentarios'
                    }}
                  </p>
                  <p class="body-1 date">
                    {{ moment(notification.updated_at).startOf().fromNow() }}
                  </p>
                </template>
                <a-avatar
                  v-if="notification.user.avatar"
                  slot="avatar"
                  :src="notification.user.avatar"
                />
                <default-icon-letter
                  v-else
                  slot="avatar"
                  :names="notification.user.fullName"
                />
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item
          key="2"
          @click="handleGoToNotifications"
          class="menu-notifications--last"
        >
          Ver todas las notificaciones
        </a-menu-item>
      </template>
    </a-sub-menu>
    <!--menu item para q no aparezca el menu inline-->
    <a-menu-item key="" style="height: 0px; margin: 0">
      <a-icon type="" />
      <span></span>
    </a-menu-item>
  </a-menu>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import moment from 'moment'
import compareMixin from '@/app/shared/mixins/compare'
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'

export default {
  name: 'MenuNotifications',
  props: {
    disabled: { type: Boolean, default: false },
    showPopup: { type: Boolean, default: false },
  },
  components: {
    DefaultIconLetter,
  },
  data: () => ({
    moment,
  }),
  mixins: [compareMixin],

  computed: {
    ...mapGetters(['notifications', 'profile', 'menuCollapse']),
    transformPopupClassName() {
      if (this.notifications.length <= 2)
        return 'popup-menu-notifications--218px'
      else return 'popup-menu-notifications--312px'
    },
  },
  methods: {
    ...mapActions(['selectMenu', 'setAnalytics']),
    ...mapMutations(['SET_IS_OPEN_SOME_POPUP_FOOTER']),

    /**
     * Va hacia la vista de notificaciones
     */
    handleGoToNotifications() {
      const routeName = 'notifications'
      this.$router.push({ name: routeName })
      const menuItem = { routeSelected: routeName }
      this.selectMenu(menuItem)
      this.setAnalytics({ eventName: 'link_notifications' })
    },
    /**
     * Cambia según si el popup se encuentra abierto o no
     * @param {Array} val - es el submenu
     */
    handleOpenChangePopup(val) {
      if (val.includes('submenu-notifications')) {
        this.SET_IS_OPEN_SOME_POPUP_FOOTER(true)
      } else {
        this.SET_IS_OPEN_SOME_POPUP_FOOTER(false)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.mrg-right-12
  margin-right: 12px

.menu-notifications
  background-color: $gray_dark_900
  border-right: 0px
  height: 64px
  display: flex
  align-items: center
  border-top: 1px solid $white_000
  &__submenu
    width: 100%
  &__item
    display: flex
    justify-content: flex-start
    align-items: center
    height: 100%
    width: 224px
    color: $gray_dark_600
    margin-left: 1px // por defecto tiene 16px, y se desea q tenga 17
    &:hover
      color: $white_000
  &--last
    text-align: center
  &--selected
    position: relative
    display: flex
    align-items: center
    &::before
      content: ''
      position: absolute
      left: 0
      width: 4px
      height: 24px
      background-color: $white_000
      border-radius: 0 4px 4px 0
    .menu-notifications__item
      color: $white_000
  &__icon
    width: 24px
    height: 24px
    line-height: 24px
    font-size: 24px !important
    margin-right: 0px !important
    display: flex
    align-items: center
  &--collapse
    width: inherit
  &--no-collapse
    width: inherit
    .menu-notifications__item
      width: 58px
      // margin-left: -16px
      // margin-top: -4px
.popup-menu-notifications
  // position: fixed!important
  ul
    // margin-top: -220px
    .item
      padding: 0px
      .header
        padding: 5px 12px
        border-bottom: 1px solid $gray_5
      .ant-list
        .ant-spin-nested-loading
          .ant-spin-container
            .ant-list-items
              li
                padding: 8px 0px
                .ant-list-item-meta
                  .ant-list-item-meta-content
                    .ant-list-item-meta-description
                      margin-bottom: 0px
                      white-space: normal
                      color: $gray_dark_900
                      p
                        margin-bottom: 0px
                      .date
                        color: $gray_dark_700
</style>
<style lang="sass">
.popup-menu-notifications
  position: fixed!important
.popup-menu-notifications--312px
  >ul
    margin-top: -312px
.popup-menu-notifications--218px
  >ul
    margin-top: -218px
.ant-list-item
  white-space: pre-wrap
.menu-notifications--no-collapse
  .menu-notifications__submenu
    .ant-menu-submenu-title
      .ant-menu-submenu-arrow
        display: none
</style>
