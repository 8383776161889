import channelModule from './channelModule'
import whatsAppModule from './whatsAppModule'
import messengerModule from './messengerModule'
import webchatModule from './webchatModule'

const modules = {
  channelModule,
  whatsAppModule,
  messengerModule,
  webchatModule,
}

export default {
  modules,
}
