<template>
  <a-modal
    :visible="visible"
    :title="title"
    @cancel="$emit('onClose')"
    :bodyStyle="bodyStyle"
    width="42.6em"
    :afterClose="handleReset"
    centered
    :maskClosable="false"
  >
    <a-tabs
      default-active-key="general"
      @change="handleChangeTabs"
      size="small"
      class="modal__tabs"
      :tabBarGutter="12"
      :tabBarStyle="{ margin: '12px 24px' }"
    >
      <a-tab-pane key="general" tab="General" force-render>
        <custom-section-scroll
          :master-styles="{ height: '32em' }"
          :content-styles="{ padding: '0px 24px 12px 24px' }"
        >
          <general-form ref="generalForm" :form-values="chatbot" type="edit" />
        </custom-section-scroll>
      </a-tab-pane>
      <a-tab-pane key="schedule" tab="Horario">
        <custom-section-scroll
          :master-styles="{ height: '32em' }"
          :content-styles="{ padding: '0px 24px 12px 24px' }"
        >
          <schedule-form
            ref="scheduleForm"
            :form-values="scheduleFormValues(chatbot)"
          />
        </custom-section-scroll>
      </a-tab-pane>
      <a-tab-pane key="channels" tab="Canales">
        <custom-section-scroll
          :master-styles="{ height: '32em' }"
          :content-styles="{ padding: '0px 0px 12px 24px' }"
        >
          <list-picker-channels
            ref="list_picker_channels"
            :default-values="channelsFormValues(chatbot)"
          />
        </custom-section-scroll>
      </a-tab-pane>
    </a-tabs>
    <div slot="footer" class="modal__footer display-flex justify-content--end">
      <a-button @click="$emit('onClose')">Cancelar</a-button>
      <a-button type="primary" @click="handleSubmit" :loading="loading">{{
        okButton
      }}</a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GeneralForm from '@/app/chatbots/components/organisms/forms/GeneralForm'
import ScheduleForm from '@/app/chatbots/components/organisms/forms/ScheduleForm'
import ListPickerChannels from '@/app/shared/components/organisms/ListPickerChannels'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import attemptMixin from '@/app/shared/mixins/attempt'
import transformMixin from '@/app/shared/mixins/transform'
import moment from 'moment'

export default {
  name: 'ModalUpdateChatbot',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GeneralForm,
    ScheduleForm,
    ListPickerChannels,
    CustomSectionScroll,
  },
  mixins: [attemptMixin, transformMixin],
  data: () => ({
    moment,
    formValues: {},
    keyTab: 'general',
    bodyStyle: {
      padding: '0px',
    },
    copyDataSource: {},
    chatbot: {},
    loading: false,
  }),
  created() {
    this.loadChatbot()
  },
  watch: {
    visible(val) {
      if (val) {
        const days = this.formValues.schedule?.days
        days.forEach((day) =>
          day.hours.map((hour, index) => {
            hour.start = this.convertToTimezone(
              hour.start,
              this.formValues.schedule.timezone
            )
            hour.end = this.convertToTimezone(
              hour.end,
              this.formValues.schedule.timezone
            )
            hour.key = `range-${day.day}-${index}`
            return hour
          })
        )
        this.handleChangeTabs(this.keyTab)
      }
    },
  },
  computed: {
    ...mapGetters(['company', 'profile', 'appVersion']),

    okButton() {
      switch (this.keyTab) {
        case 'general':
          return 'Actualizar información general'
        case 'schedule':
          return 'Actualizar horario'
        case 'channels':
          return 'Actualizar canales'
        default:
          return 'okButton'
      }
    },
    title() {
      return `Configurar chatbot “${this.chatbot.name}"`
    },
  },
  methods: {
    ...mapActions(['updatedChatbot', 'getChatbot']),

    handleSubmit() {
      if (this.keyTab === 'general') this.handleUpdateGeneral()
      else if (this.keyTab === 'schedule') this.handleUpdateSchedule()
      else if (this.keyTab === 'channels') this.handleUpdateChannels()
    },
    handleUpdateGeneral() {
      const generalForm = this.$refs.generalForm
      const form = generalForm.form
      form.validateFields(async (err, values) => {
        if (!err) {
          this.formValues = { ...this.formValues, ...values }
          this.formValues.schedule = this.chatbot.schedule
          console.log(this.formValues)
          const response = await this.updatedChatbot({
            chatbotId: this.$route.params.id,
            chatbotEdited: this.formValues,
          })
          if (response.type === 'tree') {
            this.$message.success('Tu chatbot se actualizó con éxito')
            this.$emit('onSuccess', response)
          } else {
            this.$message.error(response.details ?? 'Ocurrió un error')
          }
        }
      })
    },
    handleUpdateSchedule() {
      const scheduleForm = this.$refs.scheduleForm
      const form = scheduleForm.form
      scheduleForm.handleValidateListRanges()
      scheduleForm.handleVerifiedDays()
      form.validateFields(async (err, values) => {
        const isFilledRanges = scheduleForm.arrayRangePickers.some((day) =>
          day.hours.some((range) => !range.start || !range.end)
        )
        if (!err && !isFilledRanges) {
          scheduleForm.arrayRangePickers.forEach((range) =>
            range.hours.map((hour) => {
              hour.start = this.converToLocal(
                hour.start,
                scheduleForm.timezoneOffset
              )
              hour.end = this.converToLocal(
                hour.end,
                scheduleForm.timezoneOffset
              )
              return hour
            })
          )

          this.loading = true
          this.formValues.schedule = {
            timezone: values.timezone,
            days: scheduleForm.arrayRangePickers,
          }
          const response = await this.updatedChatbot({
            chatbotId: this.$route.params.id,
            chatbotEdited: this.formValues,
          })
          if (response.success !== false) {
            this.$message.success('Tu chatbot se actualizó con éxito')

            this.$emit('onSuccess', response)
          } else {
            this.$message.error(response.details ?? 'Ocurrió un error')
          }
          this.loading = false
        }
      })
    },

    async handleUpdateChannels() {
      const listPicker = this.$refs.list_picker_channels

      const listChannels = listPicker
        .handleGetList()
        .map((picker) => picker.channelCompanyId)

      const { channelsAssigned, channelsDesasigned, newChannels } =
        this.typeOfChannels(listChannels)

      this.formValues.channels = channelsAssigned
      this.formValues.schedule = this.chatbot.schedule

      if (channelsDesasigned || newChannels)
        return this.showConfirmChannels({
          ...this.formValues,
          channelsDesasigned: channelsDesasigned,
          newChannels: newChannels,
        })

      const response = await this.updatedChatbot({
        chatbotId: this.$route.params.id,
        chatbotEdited: this.formValues,
      })
      if (response.type === 'tree') {
        this.$message.success('Tu chatbot se actualizó con éxito')
        this.$emit('onSuccess', response)
      }
    },
    // evento de click en card small channel
    typeOfChannels(idChannels) {
      const channelsCompanies = this.chatbot.channels_companies.map(
        (channelCompany) => channelCompany._id
      )

      const channelsDesasigned = channelsCompanies.filter(
        (channelCompany) => !idChannels.includes(channelCompany)
      )

      const channelsAssigned = idChannels

      const newChannels = idChannels.filter(
        (idChannel) => !channelsCompanies.includes(idChannel)
      )
      return {
        channelsAssigned,
        channelsDesasigned,
        newChannels,
      }
    },
    /**
     * Setea los valores dependiendo del keytab
     * @param {String} keyTab - general | schedule
     */
    async handleChangeTabs(keyTab) {
      this.keyTab = keyTab

      this.$nextTick(() => this.handleSetFields(keyTab))
    },
    /**
     * Setea los valores dependiendo del keytab
     * @param {String} keyTab - general | schedule
     */
    handleSetFields(keyTab) {
      const generalForm = this.$refs.generalForm
      const scheduleForm = this.$refs.scheduleForm
      this.formValues.channels = this.chatbot.channels_companies.map(
        (channel) => channel._id
      )
      this.formValues.name = this.chatbot.name
      this.formValues.description = this.chatbot.description

      if (keyTab === 'general') {
        generalForm.setFieldsValues()
      } else if (keyTab === 'schedule') {
        scheduleForm.setFieldsValues()
      }
    },
    handleReset() {
      this.loadChatbot()
      this.formValues = { channels: [] }
      this.$refs.generalForm?.resetFields()
      this.$refs.scheduleForm?.resetFields()
      this.idChatbot = null
      this.loading = false
    },
    /**
     * Retorna solo el schedule del chatbot
     * @param {Object} chatbot
     * @param {Object} chatbot.schedule
     * @param {String} chatbot.schedule.timezone
     * @param {Array} chatbot.schedule.days
     */
    scheduleFormValues(chatbot) {
      if (this.keyTab !== 'schedule') return
      const days = this.formValues.schedule?.days
      if (!days) return []
      days.forEach((day) =>
        day.hours.map((hour, index) => {
          hour.key = `range-${day.day}-${index}`
          return hour
        })
      )
      let schedule = {
        timezone: chatbot.schedule?.timezone,
        timezoneoffset: chatbot.schedule?.timezoneoffset,
        days: days,
      }
      this.formValues.schedule = schedule
      return schedule
    },
    loadChatbot() {
      this.intervalAttempt(async () => {
        const chatbotId = this.$route.params.id
        if (!this.company || !chatbotId) throw 'Not company found'
        this.chatbot = await this.getChatbot(chatbotId)
        this.formValues.schedule = this.chatbot.schedule
      })
    },
    channelsFormValues(chatbot) {
      if (this.keyTab !== 'channels') return
      return [
        ...chatbot.channels_companies.map((channel) => {
          const newKey = `${new Date().getTime()}-${channel._id}`
          return {
            channel: channel.channel,
            channelCompanyId: channel._id,
            key: newKey,
          }
        }),
      ]
    },
    /**
     * Muestra la confirmación cuando se desasigna canales
     * @param {values} form - valores del chatbot
     */
    showConfirmChannels(values) {
      let self = this
      this.$confirm({
        title: `¿Estás ${this.transformWordGender(
          'segura',
          this.profile.gender
        )} de cambiar los canales de tu chatbot?`,
        content:
          'Recuerda que si tu chatbot transfiere a una cola que no tiene el canal que estás actualizando, realizará la transferencia a la cola general.',
        okText: 'Cambiar',
        cancelText: 'Cancelar',
        centered: true,
        async onOk() {
          self.loadingForm = true
          const response = await self.updatedChatbot({
            chatbotId: self.$route.params.id,
            chatbotEdited: values,
          })
          if (response.type === 'tree') {
            self.$message.success('Tu chatbot se actualizó con éxito')
            self.$emit('onSuccess', response)
          } else
            self.$message.error(
              response.details ? response.details : 'Ocurrió un error'
            )
          self.loadingForm = false
        },
      })
    },
    /**
     * Convierte la hora a tu zona horaria local
     */
    converToLocal(hour, timezoneOffset) {
      const formatted = new Date(moment(hour).format())
      formatted.setFullYear(2000, 0, 1)
      const isoDate = formatted.toISOString()
      const utcZero = moment(isoDate).subtract(timezoneOffset, 'minutes')
      const utcZeroFormatted = utcZero.format('YY-MM-DD HH:mm')
      const timezoneLocal = Intl.DateTimeFormat().resolvedOptions().timeZone
      const dateLocal = moment.tz(utcZeroFormatted, timezoneLocal)
      return moment(dateLocal).format()
    },
    convertToTimezone(date, timezone) {
      const dateLocal = moment.tz(date, timezone)
      const hours = moment(dateLocal).format('HH')
      const minutes = moment(dateLocal).format('mm')

      const newDate = new Date()
      newDate.setFullYear(2000, 0, 1)
      newDate.setHours(hours, minutes)
      return moment(newDate.toISOString())
    },
  },
}
</script>

<style lang="sass" scoped>
.modal__tabs
  // margin: 12px 24px 0px 24px
.modal__picture
  position: absolute
  right: 24px
  bottom: 0px
</style>
