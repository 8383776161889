<template>
  <div
    class="menu-item"
    :class="{ 'menu-item__mark': showMark, 'menu-item--disabled': disabled }"
    :style="{ width: width }"
    @click="$emit('onSelected')"
  >
    <router-link
      :to="{ name: route }"
      class="menu-item__content"
      :disabled="disabled"
    >
      <a-badge :dot="badge" :class="{ 'mrg-right-12': showTitle }">
        <div class="menu-item__icon" v-html="icon"></div>
      </a-badge>
      <slot name="title" v-if="showTitle">
        <p class="menu-item__title body-3 mrg-bottom-0 ellipsis">
          {{ title }}
        </p>
      </slot>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    title: { type: String, default: 'item' },
    showIcon: { type: Boolean, default: true },
    showTitle: { type: Boolean, default: true },
    width: { type: String, default: 'max-content' },
    icon: { type: String, default: '' },
    route: { type: String, default: '#' },
    showMark: { type: Boolean, default: true },
    badge: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({}),
}
</script>

<style lang="sass" scoped>
.mrg-right-12
  margin-right: 12px
.menu-item
  &--disabled
    pointer-events: none
    opacity: 0.6
  &__content
    display: flex
    flex-direction: row
    align-items: center
    background-color: $gray_dark_900
    padding: 10px 17px
    color: $gray_light_600
    cursor: pointer
  &__icon
    height: 24px
    width: 24px
    fill: $gray_light_600
  &__title
  &:hover
    .menu-item__content
      color: $white_000
    .menu-item__icon
      fill: $white_000
  .router-link-active
    position: relative
    color: $white_000
    .menu-item__icon
      fill: $white_000
.menu-item__mark
  .router-link-active
    &::before
      content: ''
      position: absolute
      left: 0
      width: 4px
      height: 24px
      background-color: $white_000
      border-radius: 0 4px 4px 0
</style>
