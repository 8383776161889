import router from '@/router'

const actions = {
  async listenNotifications(context) {
    try {
      this._vm.$socket.on('client:user:notification', (payload) => {
        const newNotification = {
          ...payload,
        }
        // newNotification.new = true
        /**Añadir notificacion a las notificaciones */
        context.commit('ADD_NOTIFICATION', newNotification)
        context.dispatch('playSound', 'notification') // Reproducir sonido
        /**Añadir el badge al sidebar */
        if (router.currentRoute.name !== 'notifications')
          context.commit('SET_PROFILE_NOTIFICATIONS', 1)
        else context.dispatch('cleanNotifications')

        if (newNotification.activity.type === 'ticket-unlocked-notification') {
          context.commit('SET_RELOAD_NOTIFICATION', {
            visible: true,
            title: 'Se liberaron tus tickets',
            description:
              'Tenías tickets retenidos, actualiza para que puedas verlos.',
          })
        }
        if (newNotification.activity.type === 'wallet-unlocked-notification') {
          context.commit('SET_RELOAD_NOTIFICATION', {
            visible: true,
            title: 'Se liberaron tus mensajes',
            description:
              'Tenías mensajes retenidos, actualiza para que puedas verlos.',
          })
        }
        if (newNotification.activity.type === 'pause-strategy-for-limit') {
          context.commit('SET_NOTIFICATION_WITH_TYPE', {
            visible: true,
            type: 'warning',
            title: 'Tu campaña ha sido pausada',
            description: newNotification.message.es,
          })
        }
      })
    } catch (err) {
      console.log(err)
    }
  },
}

export default { actions }
