const initialState = () => ({
  client: null, // Objeto de un cliente seleccionado
  clients: [],
  showOldTickets: false, // Muestra la sección del perfil o de tickets anteriores
  isLoadingOldTickets: false, //  valor si se esta cargando los tickets anteriores
  showChatSelectedTicket: false, // Muestra la seccion de chat del ticket seleccionado
  isLoadingSelectedTicket: false, // Valor de carga de los ticket
})

export default initialState
