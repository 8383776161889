<template>
  <base-custom-drawer title="Perfil del cliente">
    <!-- Datos generales del cliente -->
    <div ref="avatar" class="section__summary display-flex align--center">
      <template v-if="!loading && client">
        <avatar-client
          :src="client.avatar"
          :xs="70"
          :sm="70"
          :md="70"
          :lg="64"
          :xl="64"
        />
        <div class="sub-info">
          <p class="name body-3">
            {{ nameClient }}
          </p>
          <p class="number regular-16">
            {{ client.phone ? client.phone : 'Número' }}
          </p>
        </div>
      </template>
      <template v-else>
        <a-skeleton
          :loading="true"
          active
          :avatar="{ size: 64 }"
          :paragraph="{ rows: 1 }"
          class="skeleton"
        >
        </a-skeleton>
      </template>
    </div>
    <a-divider class="section__divider" />
    <!-- Se muestran los íconos solo si el cliente tiene más de un canal -->
    <template v-if="showChannels">
      <div
        class="
          section__channels
          display-flex
          align--center
          justify--between
          flex-wrap
        "
      >
        <p class="semibold-14 mrg-bottom-8 channels">Canales contactados</p>
        <div class="display-flex">
          <popover-channel
            v-for="clientChannel in formatClienthannels(client)"
            :key="clientChannel.channel"
            :channel="clientChannel.channel"
            :list-titles="clientChannel.channelTitles"
            class="mrg-right-8 mrg-bottom-8"
          />
        </div>
      </div>
      <a-divider class="section__divider" />
    </template>
    <!-- FORMULARIO DEL PERFIL -->
    <custom-section-scroll
      :master-styles="{ height: heightFormContainer }"
      :content-styles="{ padding: '12px 20px 16px 20px' }"
    >
      <FormClient v-if="false" :client="client" />
      <p class="semibold-14 mrg-bottom-0 text--left">Información del cliente</p>
      <client-fields :data-source-client="client" />
    </custom-section-scroll>
    <!-- </div> -->
  </base-custom-drawer>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
import FormClient from '@/app/shared/components/forms/FormClient.vue'
import BaseCustomDrawer from '@/app/tickets/components/organisms/sections/BaseCustomDrawer'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import PopoverChannel from '@/app/shared/components/organisms/PopoverChannel'
import AvatarClient from '@/app/shared/components/avatars/AvatarClient'
import ClientFields from '@/app/shared/components/organisms/ClientFields'

export default {
  name: 'SectionClientProfile',
  props: {
    loading: {
      type: Boolean,
      default: true,
      require: false,
    },
  },
  components: {
    FormClient,
    BaseCustomDrawer,
    CustomSectionScroll,
    PopoverChannel,
    AvatarClient,
    ClientFields,
  },
  data: () => ({
    timeoutTyping: {
      // Temporizador de escribiendo
      callback: null, // setTimeout
    },
    allowSave: false, // permite guardar los cambios
    rules: {
      names: [{ max: 29, message: '30 caracteres máximo', trigger: 'change' }],
      surnames: [
        { max: 29, message: '30 caracteres máximo', trigger: 'change' },
      ],
      email: [
        { type: 'email', message: 'No es un email válido', trigger: 'change' },
      ],
      address: [
        { max: 49, message: '50 caracteres máximo', trigger: 'change' },
      ],
    },
    selectedFields: {},
  }),
  mounted() {
    this.setCurrentClient()
  },
  mixins: [attemptMixin, supportMixin],
  watch: {
    client: {
      // Observa la estructura de todo el getter cliente y su contenido
      handler() {
        this.setCurrentClient()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'client',
      'profileRemote',
      'company',
      'ticketSelected',
      'profile',
      'active_breakpoint',
    ]),
    /**
     * Mostrar los canales conectados
     * @param {Boolean}
     */
    showChannels() {
      return (
        this.client &&
        this.client.channels_companies &&
        this.client.channels_companies.length > 1
      )
    },
    /**
     * Nombre del cliente
     */
    nameClient() {
      if (!this.client) return ''
      let fullname = ''
      if (!this.client.names && !this.client.surnames) fullname = 'Nombres'
      else
        fullname = `${this.client.names ? this.client.names : ''} ${
          this.client.surnames ? this.client.surnames : ''
        }`
      return fullname
    },
    clientChannels() {
      return [...this.client.channels_companies].reduce((acc, current) => {
        const isIncludeInAcc = acc[current.channel]
        if (!isIncludeInAcc)
          acc[current.channel] = {
            channel: current.channel,
            channelTitles: [current.title], // ids de los canales conectados que tienen este canal
          }
        else acc[current.channel].channelTitles.push(current.title)
        return acc
      }, {})
    },
    /**
     * @return {String} - altura del contenido del formulario
     */
    heightFormContainer() {
      if (this.active_breakpoint.is_mobile)
        return this.showChannels ? 'calc(100% - 130px)' : 'calc(100% - 116px)'
      else
        return this.showChannels ? 'calc(100% - 190px)' : 'calc(100% - 155px)'
    },
  },
  methods: {
    ...mapActions(['updateClient']),
    ...mapMutations(['SET_SHOW_OLD_TICKETS', 'SET_IS_LOADING_OLD_TICKETS']),

    /**
     * Setea las propiedades del cliente a current client
     * @returns {null} - si el cliente no existe
     */
    setCurrentClient() {
      if (!this.client) return null
      this.currentClient = this.client
      /** ##-> ID Score - Temporal */
      this.currentIDLead = !this.client.integrations[0]
        ? { key: 'idlead', value: '', platform: 'Score', integrationId: '' }
        : this.client.integrations[0]
    },
    grantSave() {
      this.allowSave = true
    },
    /**
     * Guarda los cambios al dar click fuera del input
     * @param {String} attr Nombre del atributo del objeto
     * @param {String} attr_show Nombre del atributo que se mostrará en el modal
     */
    async saveChanges(attr, attr_show) {
      try {
        if (!this.allowSave) return
        this.$message.loading({ content: 'Cargando...', key: 'updatable' })
        if (attr === 'phone') {
          this.currentClient.phones[0] = {
            number: `${this.currentClient.phone}`,
          }
        }
        if (attr === 'idlead')
          this.currentClient.integrations[0] = this.currentIDLead

        const response = await this.updateClient({
          ...this.currentClient,
          channel: this.ticketSelected.channel.type,
        })
        if (response.success) {
          this.$message.success({
            content: `Se agregó ${attr_show} correctamente`,
            key: 'updatable',
            duration: 2,
          })
        } else {
          this.$message.error({
            content: `Ocurrió un error, no se pudo guardar el ${attr_show}`,
            key: 'updatable',
          })
        }

        this.allowSave = false
      } catch (error) {
        this.$message.error(
          `Ocurrió un error, no se pudo guardar el ${attr_show}`
        )
      }
    },
    saveChangesDate(attr, attr_show) {
      this.allowSave = true
      this.saveChanges(attr, attr_show)
    },
  },
}
</script>

<style lang="sass" scoped>
$top-hader: 400px
$width-avatar: 64px

.height-without-stripe
  height: calc(100vh - #{$padding-heigth-inner * 2})
.height-with-stripe
  height: calc((100vh - 52px) - #{$padding-heigth-inner * 2})
.section-profile
  // height: calc(100vh - #{$padding-heigth-inner * 2})
  background-color: $white_000
  border-radius: 8px
  border: 1px solid #EBEBEB
  padding: 8px
  .header
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 12px
    .title
      text-align: left
      margin-bottom: 0em
      color: $gray_dark_900
.section__summary
  margin: 0px 20px 12px 20px
  .avatar
  .sub-info
    color: $gray_dark_800
    flex-grow: 1
    .name
      margin-bottom: 4px
      text-align: left
    .number
      color: $gray_dark_600
      margin-bottom: 4px
      text-align: left
    .channels
      text-align: left
      margin-bottom: 4px
      color: $gray_8
    .contain-channels
      display: flex
      flex-direction: row
      justify-content: flex-start
  .item-select-document
    display: flex
    flex-direction: row
  .form-info
    .ant-form-item
      margin-bottom: 0px
    // .textarea
    //   height: 54px
    //   min-height: 54px
    //   max-height: 54px
    //   position: relative
    // .counter
    //   position: absolute !important
    //   bottom: 5px
    //   right: 12px
    //   color: $gray_light_300
    //   font-size: 10px
  .tab
    &-container
      .master-container
        position: relative
        padding-top: 2px
        padding-bottom: 2px
        height: 100%
        .form-container, .list-container
          overflow-y: auto
          height: 100%
          .form-content
            .title
              text-align: left
              color: $gray_dark_900
            .doc
              margin-left: 4px
            .date
              width: 100%
.section
  &__channels
    padding: 8px 20px 0px 20px
  &__divider
    width: calc(100% - 40px) // 40 por la suma de los margenes
    min-width: calc(100% - 40px)
    margin: 0px 20px
</style>
<style lang="sass">
.form-client
  .ant-form-item-control-wrapper
    .has-error
      .ant-form-explain
        text-align: left
    .ant-form-item-control
      .ant-form-explain, .show-help-leave, .show-help-active
        text-align: left!important
.client-info
  .skeleton
    .ant-skeleton-content
      .ant-skeleton-title
        margin-top: 0px
      .ant-skeleton-paragraph
        margin-top: 12px!important
</style>
