var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-menu-billing"},[_c('a-row',[_c('header',{staticClass:"template__header"},[_c('h5',{staticClass:"heading-h5 mrg-bottom-0"},[_vm._v(_vm._s(_vm.title))]),_c('a-tooltip',{attrs:{"placement":"bottom"}},[_c('template',{slot:"title"},[_vm._v(" ❗️Importante: Nuestros procesos de cobros en Ticker están bajo horario internacional UTC, por lo que los consumos que se realicen aparecerán en este apartado según este formato. ")]),_c('a-icon',{staticClass:"template__icon",attrs:{"type":"info-circle"}})],2)],1)]),(_vm.companyPricing)?_c('a-row',[_c('div',{staticClass:"details"},[_c('div',{staticClass:"cards"},_vm._l((_vm.companyPricing.packs),function(item){return _c('CurrentPackDetail',{key:item.description,staticClass:"current-card",attrs:{"pack":item,"withAction":true,"showCountries":item.image === 'SMS'}})}),1)])]):_vm._e(),_c('a-tabs',{attrs:{"default-active-key":_vm.defaultActiveKey,"tabBarStyle":{
      margin: '16px auto 20px auto',
      display: 'flex',
      justifyContent: 'flex-start',
    }}},[(_vm.hasRequestedWsp)?_c('a-tab-pane',{key:"1",attrs:{"tab":"WhatsApp"}},[_c('CustomSectionScroll',{attrs:{"id-master":"section-all-products","masterStyles":{
          height: 'calc(100vh - 280px)',
          width: '100%',
          marginTop: '-12px',
        }}},[_c('TableMenuSpendings',{attrs:{"title":"Mis gastos de saldo","type":"spending","columns":_vm.spendingColumns,"fetch":_vm.getWspSpendings,"tableKey":"table_1","isSelected":true},on:{"onCollapse":_vm.handleCollapseTable}}),_c('TableMenuSpendings',{attrs:{"title":"Mis Recargas","type":"recharge","columns":_vm.rechargeColumns,"fetch":_vm.getWspRecharges,"tableKey":"table_2","isSelected":true},on:{"onCollapse":_vm.handleCollapseTable}})],1)],1):_vm._e(),_c('a-tab-pane',{key:"2",attrs:{"tab":"SMS"}},[_c('TableMenuSpendings',{attrs:{"title":"Mis gastos de saldo","type":"slot"}},[_c('p',[_vm._v(" Los gastos de los SMS se pueden ver a detalle desde el dashboard "),_c('router-link',{attrs:{"to":"/dashboards#dashboard-sms"}},[_vm._v(" Resumen de envíos SMS. ")])],1)]),_c('TableMenuSpendings',{attrs:{"title":"Mis Recargas","type":"recharge","columns":_vm.rechargeSmsColumns,"fetch":_vm.getSmsRecharges}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }