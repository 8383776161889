<template>
  <a-sub-menu
    v-if="type === 'submenu'"
    title="Cambiar mi estado a:"
    id="states"
    class="menu-footer__submenu--states"
    :popupClassName="popupClassNameStates"
    key="3"
  >
    <a-menu-item
      v-for="status in states"
      :key="status._id"
      :id="status.state"
      class="fix-center"
      style="display: flex; flex-direction: row; align-items: center"
      @click="showModalState(status.state)"
    >
      <a-badge
        :color="status.color"
        :text="`${
          status.value === 'bussy' ? status.value : `En ${status.value}`
        }`"
      />
    </a-menu-item>
    <modal-state
      :type="modalState.type"
      :visible="modalState.visible"
      :timer="timerFormatted"
      @onReconnect="handleReconnect"
    />
  </a-sub-menu>
  <a-menu v-else id="states" class="menu-footer__submenu--states">
    <a-menu-item
      v-for="status in states"
      :key="status._id"
      :id="status.state"
      class="fix-center"
      style="display: flex; flex-direction: row; align-items: center"
      @click="showModalState(status.state)"
    >
      <a-badge
        :color="status.color"
        :text="`${
          status.value === 'bussy' ? status.value : `En ${status.value}`
        }`"
      />
    </a-menu-item>
    <modal-state
      :type="modalState.type"
      :visible="modalState.visible"
      :timer="timerFormatted"
      @onReconnect="handleReconnect"
    />
  </a-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalState from '@/app/commons/components/organisms/ModalState'
import moment from 'moment'
import formatMixin from '@/app/shared/mixins/format'
import compareMixin from '@/app/shared/mixins/compare'

export default {
  name: 'MenuItemStatus',
  props: {
    type: {
      type: String,
      default: 'submenu',
      required: false,
    },
  },
  components: {
    ModalState,
  },
  data: () => ({
    states: [
      { state: 'bussy', value: 'Ocupado', color: '#F5222D', _id: '1' },
      { state: 'services', value: 'Servicios', color: '#1890FF', _id: '2' },
      { state: 'training', value: 'Capacitación', color: '#FA8C16', _id: '3' },
    ],
    idStatus: null,
    timerSeconds: 0,
    timer: null, // Funcion del timer
    modalState: {
      type: null,
      visible: false,
      timer: null,
    },
  }),
  mounted() {
    this.counter()
    this.handleSetModalState(this.profile)
  },
  mixins: [formatMixin, compareMixin],
  watch: {
    profile: {
      deep: true,
      handler(nv) {
        // nv: nuevo valor
        this.handleSetModalState(nv)
      },
    },
    loggedIn() {
      if (!this.loggedIn) this.cleanData()
    },
  },
  computed: {
    ...mapGetters(['profile', 'loggedIn']),

    componentParent() {
      return this.type === 'submenu' ? 'a-sub-menu' : 'a-menu'
    },

    /**
     * Devuelve el tiempo que ha pasado en un formato de texto
     * @return {String} tiempo formateado
     */
    timerFormatted() {
      if (this.timerSeconds < 60) return `${this.timerSeconds} segundos`
      else if (this.timerSeconds < 3600)
        return `${this.timeFormatted(
          this.timerSeconds,
          'seconds',
          'toMinutes'
        )} minutos`
      else
        return `${this.timeFormatted(
          this.timerSeconds,
          'seconds',
          'toHours'
        )} horas`
    },
    /**
     * Clase del popup de estados
     */
    popupClassNameStates() {
      const className = this.isAllowedFor(this.profile.type, ['admin'])
        ? 'menu-footer__popup-states--admin'
        : 'menu-footer__popup-states--agent'
      return `menu-footer__popup-states ${className}`
    },
  },
  methods: {
    ...mapActions(['saveStatusUser']),

    /** Evalúa el estado y si activar el modal de otro estado tras cargar el sidebar */
    async counter() {
      let statusObj = JSON.parse(localStorage.getItem('status'))
      switch (statusObj.status) {
        case 'bussy':
        case 'services':
        case 'training': {
          /** Setea contador de la supervision del estado y menu de la fila seleccionada */
          let diff_ms = moment(new Date()).diff(statusObj.startedIn)
          this.startTimer(Math.round(diff_ms / 1000))
        }
      }
    },
    /**
     * @param {String} status Modal que se mostrará según su estado
     */
    showModalState(status) {
      const startedIn = new Date()
      this.startTimer()
      this.saveStatusUser({ status, startedIn })
      this.idStatus = status

      // emite que se ha hecho visible al modal de estado
      this.$emit('onShowModalState')
    },

    /**
     * Inicia la cuenta del contador
     */
    startTimer(initialTime = 0) {
      this.timerSeconds = initialTime
      this.timer = setInterval(() => {
        this.timerSeconds++
      }, 1000)
    },

    /**
     * Limpia la data si no esta logueado
     */
    cleanData() {
      this.timer = null
      this.timerSeconds = 0
    },
    /**
     * Finaliza la cuenta del contador
     */
    stopTimer() {
      clearInterval(this.timer)
      this.timerSeconds = 0
    },
    /**
     * Reconecta el estado
     */
    handleReconnect() {
      this.saveStatusUser({ status: 'connected' })
      this.stopTimer()

      const divElementStatus = document.getElementById(this.idStatus)
      if (divElementStatus) {
        divElementStatus.classList.remove('ant-menu-item-selected')
      }

      const divElement = document.getElementById('statuses')
      if (divElement) {
        divElement.classList.remove('ant-menu-submenu-selected')
      }

      const divElementLogout = document.getElementById('logout')
      if (divElementLogout) {
        divElementLogout.classList.remove('ant-menu-item-selected')
      }
    },
    /**
     * Setea el estado del perfil del usuario al modal
     * @param {Object} profile
     * @param {String} profile.status
     */
    handleSetModalState(profile) {
      if (!profile) return
      const statusAllowed = ['bussy', 'services', 'training']
      // si el estado del usuario se encuentra dentro de los estados
      // permitidos, se mostrará el modal
      if (statusAllowed.includes(profile.status)) {
        this.modalState.visible = true
        this.modalState.type = profile.status
      } else {
        this.modalState.visible = false
        this.modalState.type = profile.status
      }
    },
  },
}
</script>

<style scoped></style>
