var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-form-item',{attrs:{"label":"Nombre de chatbot"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name',
        {
          rules: [
            { required: true, message: 'Por favor rellene el campo' },
            {
              whitespace: true,
              message: 'No se admiten espacios en blanco.',
            },
          ],
        },
      ]),expression:"[\n        'name',\n        {\n          rules: [\n            { required: true, message: 'Por favor rellene el campo' },\n            {\n              whitespace: true,\n              message: 'No se admiten espacios en blanco.',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Escribe aquí...","maxLength":70}})],1),_c('a-form-item',{attrs:{"label":"Descripción de chatbot"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'description',
        {
          rules: [
            { required: true, message: 'Por favor rellene el campo' },
            {
              whitespace: true,
              message: 'No se admiten espacios en blanco.',
            },
          ],
        },
      ]),expression:"[\n        'description',\n        {\n          rules: [\n            { required: true, message: 'Por favor rellene el campo' },\n            {\n              whitespace: true,\n              message: 'No se admiten espacios en blanco.',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Escribe aquí...","auto-size":{ minRows: 3, maxRows: 5 },"maxLength":_vm.maxDescription},on:{"input":_vm.handleInput}}),_c('span',{staticClass:"counter"},[_vm._v(_vm._s(_vm.currentDescription ? _vm.currentDescription.length : 0)+"/"+_vm._s(_vm.maxDescription))])],1),_c('a-row',[_c('a-col',{staticClass:"form__info-extra"},[_c('p',{staticClass:"semibold-14"},[_vm._v("Recuerda")]),_c('p',{staticClass:"info_i2"},[_vm._v(" El chatbot no atenderá tickets de WhatsApp si es que no cuentas con saldo disponible para este canal. ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }