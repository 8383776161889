<template>
  <div class="section-state-tickets">
    <div class="header">
      <p class="title heading-h6">Resumen de estados de tickets</p>
      <div>
        <a-tooltip
          placement="bottomRight"
          :arrowPointAtCenter="true"
          overlayClassName="tooltip-dash2"
        >
          <template slot="title">
            Visualiza la cantidad de tickets según sus estados en el sistema.<br />
            <strong>Cola de espera: </strong>Tickets que aun no son transferidos
            a un agente y están a la espera.<br />
            <strong>Entregados: </strong>Tickets que han sido entregados a los
            agentes.<br />
            <strong>Respondidos: </strong>Tickets que han sido respondidos por
            un agente por primera vez.<br />
            <strong>Finalizados: </strong>Tickets que han sido resueltos por los
            agentes.
            <br />
            Se mostrarán según el rango de fecha y hora que decidas en el
            filtro.
          </template>
          <a-icon
            type="info-circle"
            style="font-size: 20px; color: #595959; margin-right: 8px"
          />
        </a-tooltip>
        <!-- pop confirm con icon-->
        <a-popconfirm
          v-if="visibleConfirm"
          title="¿Estás segur@ de descargar los datos de este dashboard?"
          ok-text="Descargar"
          cancel-text="Cancelar"
          placement="bottomRight"
          @confirm="confirm"
          :arrowPointAtCenter="true"
          :overlayStyle="popStyles"
        >
          <a-tooltip
            placement="bottomRight"
            :arrowPointAtCenter="true"
            overlayClassName="tooltip-dash1"
            trigger="hover"
          >
            <template slot="title">
              Al descargar este dashboard en un excel solo se respetarán los
              filtros superiores.
            </template>
            <a-icon
              type="download"
              style="font-size: 20px; color: #595959"
              class="btn-dowload"
            />
          </a-tooltip>
        </a-popconfirm>
        <!--popover con icon-->
        <a-popover
          v-if="visiblePopOver"
          placement="bottomRight"
          :arrowPointAtCenter="true"
          :visible="visiblePopOver"
          :overlayStyle="popStyles"
        >
          <template slot="content">
            <p style="text-align: center" class="gray-dark-900">
              {{ textProgress }}
            </p>
            <a-progress :percent="percent" :status="status" />
            <div v-if="status === 'exception'" class="contain-btns">
              <a-button type="link" size="small" @click="handleCancel"
                >Cancelar</a-button
              >
              <a-button type="link" size="small" @click="handleOk"
                >Volver a intentar</a-button
              >
            </div>
          </template>
          <a-icon
            type="download"
            style="font-size: 20px; color: #595959"
            class="btn-dowload"
            :class="{ 'btn-focus': visiblePopOver }"
          />
        </a-popover>
      </div>
    </div>
    <div class="body">
      <p class="text--left body__text">
        Recueda que puedes seleccionar los estados que deseas visualizar.
      </p>
      <LineChart :styles="myStyles" :chartdata="chartdata" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import LineChart from '@/app/dashboard/components/organisms/charts/LineChart.vue'
import moment from 'moment'

export default {
  name: 'SectionStateTickets',
  components: {
    LineChart,
  },
  props: {
    date: { type: Array, required: true },
    lineId: { type: String, default: 'allLines' },
  },
  data: () => ({
    moment,
    myStyles: {
      height: '310px',
      margin: '12px 20px',
      position: 'relative',
    },
    visibleConfirm: true,
    visiblePopOver: false,
    percent: 0, // porcentaje del progress
    status: null, // status del progress
    popStyles: {
      width: '290px',
      zIndex: '999',
    },
  }),
  computed: {
    ...mapGetters(['stateTickets']),
    chartdata() {
      const data = {
        labels: this.stateTickets.pendings.map((item) => item.date),
        datasets: [
          {
            label: 'Cola de espera',
            data: this.stateTickets.pendings.map((item) => item.quantity),
            borderColor: '#C22FB3',
            backgroundColor: '#C22FB3',
            fill: false,
            borderWidth: 2,
          },
          {
            label: 'Entregados',
            data: this.stateTickets.receiveds.map((item) => item.quantity),
            borderColor: '#CE8934',
            backgroundColor: '#CE8934',
            fill: false,
            borderWidth: 2,
          },
          {
            label: 'Respondidos',
            data: this.stateTickets.opens.map((item) => item.quantity),
            borderColor: '#2FC25B',
            backgroundColor: '#2FC25B',
            fill: false,
            borderWidth: 2,
          },
          {
            label: 'Finalizados',
            data: this.stateTickets.finished.map((item) => item.quantity),
            borderColor: '#1890FF',
            backgroundColor: '#1890FF',
            fill: false,
            borderWidth: 2,
          },
        ],
      }
      return data
    },
    textProgress() {
      switch (this.status) {
        case 'exception':
          return 'Lo sentimos, ocurrió un error'
        case 'active':
          return 'Descargando datos del dashboard...'
        case 'success':
          return 'Datos descargados'
        default:
          return null
      }
    },
  },
  methods: {
    ...mapActions(['exportStatusesTickets', 'setAnalytics']),
    // confirmar pop confirm
    confirm() {
      this.visibleConfirm = false
      this.visiblePopOver = true
      this.exportDashboard()
      this.setAnalytics({
        eventName: 'descarga_excel',
        extraData: { dashboard: 'status_tickets' },
      })
    },
    // cancela la descarga
    handleCancel() {
      this.visiblePopOver = false
      this.visibleConfirm = true
    },
    // vuelve a descargar
    handleOk() {
      this.visiblePopOver = true
      this.exportDashboard()
    },
    exportDashboard() {
      this.percent = 70
      this.status = 'active'
      const date = `${moment(this.date[0]).toISOString()}/${moment(
        this.date[1]
      ).toISOString()}`
      const lineId = this.lineId
      this.exportStatusesTickets({ date, lineId })
      setTimeout(() => {
        this.percent = 100
        this.status = 'success'
      }, 2000)
      setTimeout(() => {
        this.visiblePopOver = false
        this.visibleConfirm = true
      }, 3000)
    },
  },
}
</script>

<style lang="sass" scoped>
.gray-dark-900
  color: $gray_dark_900
.btn-focus
  color: #40A9FF!important
.section-state-tickets
  background-color: $white_000
  height: 424px
  border: 1px solid #D9D9D9
  border-radius: 2px
  .header
    border-bottom: 1px solid #E8E8E8
    padding: 12px 24px
    display: flex
    justify-content: space-between
    align-items: center
    .title
      color: #262626
      margin-bottom: 0px !important
.contain-btns
  margin-top: 8px
  display: flex
  flex-direction: row
  justify-content: center
.btn-dowload
  &:hover
    color: #1890FF!important
  &:active
    color: #096DD9!important
  &:focus
    color: #40A9FF!important
.body__text
  margin: 12px 20px
  color: $gray_8
</style>
