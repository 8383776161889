<template>
  <a-drawer
    :visible="visible"
    :closable="false"
    :bodyStyle="bodyStyle"
    :width="widthDrawer"
    @close="$emit('onClose')"
  >
    <summary-account
      v-if="profile && company && companyPricing"
      :names="fullname"
      :email="profile.email"
      :phone-number="phoneNumber"
      :avatar="profile.avatar"
      :banner="company.banner"
      :type-user="transformTypeUser(profile.type)"
      :company-name="company.name"
      :plan-name="planName"
      class="drawer__summary-account"
    />
    <a-divider class="drawer__divider" />
    <div class="drawer__title-menu">
      <p class="mrg-bottom-0">Cambiar mi estado a:</p>
    </div>
    <menu-states type="menu" @onShowModalState="$emit('onClose')" />
    <a-divider class="drawer__divider" />
    <a-menu>
      <a-menu-item @click="logout"> Cerrar mi sesión 👋 </a-menu-item>
    </a-menu>
  </a-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SummaryAccount from '@/app/commons/components/molecules/SummaryAccount'
import MenuStates from '@/app/commons/components/molecules/MenuStates'
import transformMixin from '@/app/shared/mixins/transform'

export default {
  name: 'ProfileMenuDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    bodyStyle: {
      padding: 0,
    },
  }),
  components: {
    SummaryAccount,
    MenuStates,
  },
  mixins: [transformMixin],
  computed: {
    ...mapGetters([
      'companyPricing',
      'profile',
      'company',
      'active_breakpoint',
    ]),

    /**
     * Retorna el nombre y apellidos juntos
     * @returns {String} - nombre y apellidos juntos
     */
    fullname() {
      return this.profile
        ? `${this.profile.names} ${this.profile.surnames}`
        : ''
    },
    /**
     * Retorna el número de telefono y el codigo juntos
     * @returns {String} - número concatenado
     */
    phoneNumber() {
      let phoneNumber = ''
      if (this.profile) {
        if (this.profile.code) phoneNumber += `${this.profile.code} `
        if (this.profile.phone) phoneNumber += this.profile.phone
      }
      return phoneNumber
    },
    /**
     * Retorna el nombre del plan
     * @returns {String} - nombre del plan
     */
    planName() {
      if (!this.companyPricing) return ''
      return `Plan ${this.transformNamePlan(
        this.companyPricing.plan.key,
        'capitalize'
      )}`
    },
    /**
     * @returns {String} - ancho del drawer
     */
    widthDrawer() {
      if (!this.active_breakpoint) return '332px'
      else return this.active_breakpoint.components.drawer_profile.width
    },
  },
  methods: {
    ...mapActions(['logout']),
  },
}
</script>

<style lang="sass" scoped>
.drawer__summary-account
  height: 292px
.drawer__title-menu
  padding: 8px 12px
.drawer__divider
  margin: 8px 0px 0px 0px
</style>
