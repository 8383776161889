<template>
  <div
    ref="section_board"
    class="section-board scrollbar_basic"
    v-chat-scroll="{
      always: false,
      smooth: true,
      scrollonremoved: true,
      smoothonremoved: false,
    }"
    @v-chat-scroll-top-reached="loadMoreMessages"
  >
    <div
      class="content"
      ref="section_board_content"
      :class="{ space: typingRemote }"
    >
      <template v-if="loading">
        <BubleChat
          v-for="(message, index) in messagesSkeleton"
          :key="index"
          :message="message"
          :loading="loading"
        />
      </template>
      <template v-else-if="!loading && firstLoadExecuted">
        <BubleChat
          v-for="(message, index) in messages"
          :key="index"
          :message="message"
          :client="dataClient"
          :formats="enabledTextFormats"
          :blocked-actions="type === 'inbox' ? [] : ['reply-with-templates']"
        />
      </template>
    </div>
    <!-- Escribiendo -->
    <transition name="slide-fade">
      <div
        v-if="profile && profile.type !== 'agent'"
        class="typing"
        v-show="typingRemote"
      >
        <WaitPointsAnimation>
          <template>
            <span>Agente escribiendo</span>
          </template>
        </WaitPointsAnimation>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import BubleChat from '@/app/tickets/components/organisms/BubleChat.vue'
import WaitPointsAnimation from '@/app/shared/components/animations/WaitPointsAnimation.vue'
import moment from 'moment'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
moment.locale('es')

export default {
  name: 'SectionBoard',
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    dataClient: {
      type: Object,
      required: false,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
      require: false,
    },
  },
  components: {
    BubleChat,
    WaitPointsAnimation,
  },
  data: () => ({
    moment: moment,
    usersAsigned: [], // usuarios asignados
    messagesSkeleton: [
      { method: 'received', styles: { marginTop: 24 } },
      { method: 'received', styles: { marginTop: 16 } },
      { method: 'sent', styles: { marginTop: 24 } },
      { method: 'received', styles: { marginTop: 24 } },
      { method: 'received', styles: { marginTop: 16 } },
    ],
    usersWithRanges: [],
    firstLoadExecuted: false,
  }),
  mixins: [attemptMixin, supportMixin],
  computed: {
    ...mapGetters([
      'profile',
      'ticketSelected',
      'typingRemote',
      'profileRemote',
      'agents',
      'channelsCompany',
    ]),
    /**
     * Agrega propiedad marginTop diferente si hay dos mensajes seguidos con diferente metodo
     * @returns {Object[]} Message
     */
    messagesStyle() {
      if (!this.data) return []
      let lastMethod = null
      return this.data.messages.map((message) => {
        if (message.method !== lastMethod) {
          lastMethod = message.method
          message.styles = { marginTop: 24 }
        } else {
          message.styles = { marginTop: 16 }
        }
        return message
      })
    },
    /**
     * Agrega propiedad marginTop diferente si hay dos mensajes seguidos con diferente metodo
     * @returns {Object[]} Message
     */
    messages() {
      let messages = this.messagesStyle.map((message) => {
        if (
          message.method === 'sent' &&
          !message.message.auto &&
          !message.user
        ) {
          let user = this.usersAsigned.find(
            (userAssigned) => userAssigned.userId === message.userId
          )
          if (user !== undefined) message.user = { ...user }
          else if (message.strategy) {
            message.user = {
              names: `Campaña: ${message.strategy.name}`,
              surnames: '',
              type: 'strategy',
            }
          } else message.user = { names: 'ERROR_NOT_FOUND' }
          return message
        } else {
          if (!message.apiReceivedAt) {
            message.apiReceivedAt = message.created_at
          }
          return message
        }
      })
      // evitar que los mensajes se repitan
      let hash = {}
      messages = messages.filter((o) =>
        hash[o._id] && o._id ? false : (hash[o._id] = true)
      )
      // ordenar los mensajes
      const orderMessages = messages.sort(
        (a, b) => new Date(a.apiReceivedAt) - new Date(b.apiReceivedAt)
      )
      return orderMessages
    },
    /**Agente actual
     * @returns {Object}
     */
    currentAgent() {
      const agent = this.profileRemote
        ? {
            names: this.profileRemote.names,
            surnames: this.profileRemote.surnames,
            avatar: this.profileRemote.avatar
              ? this.profileRemote.avatar
              : null,
          }
        : {
            names: this.profile.names,
            surnames: this.profile.surnames,
            avatar: this.profile.avatar ? this.profile.avatar : null,
          }
      return agent
    },
    enabledTextFormats() {
      const channel = this.channelCompanyOfTicket(this.data)
      if (!channel || !channel.api) return false
      return channel.api.chat_options?.textFormats
    },
  },
  watch: {
    ticketSelected: {
      handler() {
        this.loadFirstData()
      },
    },
    data: {
      handler() {
        if (!this.data) return
        this.scrollToBottom()
      },
    },
  },
  created() {
    this.loadFirstData()
  },
  methods: {
    ...mapActions(['paginateMessages', 'getSimplyAgents']),

    /** Mandar el scroll hasta el finañ */
    async scrollToBottom() {
      const sectionBoard = this.$refs.section_board
      if (!sectionBoard) return
      let heightBoard = sectionBoard.scrollHeight
      this.$refs.section_board.scrollTo(0, heightBoard)
    },
    loadMoreMessages() {
      // this.$refs.section_board.scrollTo(0,1)
      this.scrollSamePosition(async () => {
        await this.paginateMessages({ target: this.type })
      })
    },
    /**
     * @param {Function} callback
     */
    async scrollSamePosition(callback) {
      const sectionBoard = this.$refs.section_board
      if (!sectionBoard) return
      let heightBoardSaved = sectionBoard.scrollHeight
      await callback()
      let heightBoardNew = this.$refs.section_board.scrollHeight
      this.$refs.section_board.scrollTo(0, heightBoardNew - heightBoardSaved)
    },
    /**
     * Completa los datos de los usuarios asignados a un ticket
     * @param {Array} - usuarios asignados al ticket
     */
    async completeUsersAsigned(usersAsigned) {
      if (usersAsigned.length === 0) {
        return
      }
      const usersId = []
      usersAsigned.map((user) => usersId.push(user.userId))
      const response = await this.getSimplyAgents({
        usersId: usersId,
        extraFields: ['avatar'],
      })
      if (response.length > 0) {
        usersAsigned.forEach((user) => {
          response.forEach((elem) => {
            if (elem.userId === user.userId) {
              const obj = Object.assign({}, user, elem)
              this.usersAsigned.push(obj)
            }
          })
        })
      } else return (this.usersAsigned = [])
    },
    /**
     * Pone rango a partir de la fecha en que fue asigndo un usuario
     * @param {Array} usersAsigned usuarios asigndos a un ticket
     */
    putRangesOfUsersAsigned(usersAsigned) {
      const ranges = [] // array de rangos
      let range = {} //rango
      // recorre los usuarios asignados
      usersAsigned.map((user) => {
        // console.log(user, `usuario ${index}`)
        // si existe rango A pero no existe rangoB, asigna el valor al rango B
        if (range.rangeA && !range.rangeB) {
          range.rangeB = user.asigned_at
          ranges.push(range)
          range = {}
          range = { ...user }
          range.rangeA = user.asigned_at
        }
        // si no existe rango A, asigna el valor al rango A
        else if (!range.rangeA) {
          range = { ...user }
          range.rangeA = user.asigned_at
        }
      })
      // si despues de recorrido solo existe el rango A, completa hasta la fecha actual
      if (range.rangeA && !range.rangeB) {
        range.rangeB = new Date()
        ranges.push(range)
      }
      // limpia el rango
      range = {}
      return ranges
    },
    /**
     * Cargar los ranges de los usuarios asignados
     */
    loadFirstData() {
      this.firstLoadExecuted = false
      this.intervalAttempt(async () => {
        if (!this.usersAsigned.length && !this.data) throw 'no users assigned'
        this.scrollToBottom()
        this.usersAsigned = []
        await this.completeUsersAsigned(this.data.users ? this.data.users : [])
        this.firstLoadExecuted = true
      })
    },
  },
}
</script>
<style lang="sass" scoped>
.slide-fade-enter
  transform: translate(0, 20px)
  transition: all .6s ease-in
.slide-fade-enter-to
  transform: translate(0,-20px)
  transition: all .6s ease-in-out
.slide-fade-leave-active
  transform: translate(0,20px)
  transition: all .6s ease

.section-board
  height: inherit
  padding: 20px
  overflow-y: auto
  .typing
    background: $blue_light_000
    width: fit-content
    padding: 8px 20px
    border-radius: 16px
    margin: 0 auto
    cursor: default
    position: absolute
    bottom: 20px
    left: 0
    right: 0
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
  .space
    margin-bottom: 60px
</style>
