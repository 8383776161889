<template>
  <div class="templates-list">
    <a-table
      class="table-supervision"
      :data-source="dataTemplates.data"
      :pagination="false"
      :loading="load"
      rowKey="_id"
      size="middle"
      ref="table"
      @scroll="handleBottomReached"
      :scroll="{ y: 'calc(100vh - 435px)' }"
    >
      <!--primera columna-->
      <a-table-column key="order" title="N°" data-index="order" :width="60">
        <template slot-scope="order">
          <span>{{ order }}</span>
        </template>
      </a-table-column>
      <!--segunda columna-->
      <a-table-column
        key="friendlyName"
        title="Nombre de visualización"
        data-index="friendlyName"
        :ellipsis="true"
        :width="230"
      >
        <template slot-scope="friendlyName, record">
          <span>
            <i v-if="record.sandbox">{{ friendlyName }} (sandbox) </i>
            <span v-else>{{ friendlyName }}</span>
          </span>
        </template>
      </a-table-column>
      <!--tercera columna-->
      <a-table-column
        key="category"
        title="Categoria"
        data-index="category"
        :ellipsis="true"
        :width="180"
      >
        <template slot-scope="category">
          <span>{{ category }}</span>
        </template>
      </a-table-column>
      <!--cuarta columna-->
      <a-table-column key="tracking" title="Estado" data-index="tracking">
        <template slot-scope="tracking">
          <template v-if="tracking[tracking.length - 1].status === 'approved'">
            <a-tag color="green">Aprobado</a-tag>
          </template>
          <template v-if="tracking[tracking.length - 1].status === 'pending'">
            <a-tag color="orange">Pendiente</a-tag>
          </template>
          <template v-if="tracking[tracking.length - 1].status === 'rejected'">
            <a-tag color="red">Rechazado</a-tag>
          </template>
        </template>
      </a-table-column>
      <!--quinta columna-->
      <a-table-column
        v-if="showAllColumns"
        key="active"
        title="Activo"
        data-index="active"
      >
        <template slot-scope="active, record">
          <template v-if="disabledCell(record.tracking)">
            <span>-</span>
          </template>
          <template v-else-if="active">
            <a-switch
              size="small"
              default-checked
              @change="changeState(record)"
            />
          </template>
          <template v-else>
            <a-switch size="small" @change="changeState(record)" />
          </template>
        </template>
      </a-table-column>
      <!--sexta columna-->
      <a-table-column
        v-if="showAllColumns"
        key="lines"
        title="Colas asignadas"
        data-index="lines"
      >
        <template slot-scope="lines, record"
          >{{ disabledCell(record.tracking) ? '-' : lines.length }}
        </template>
      </a-table-column>
      <!--setima columna-->
      <a-table-column
        v-if="showAllColumns"
        key="users"
        title="Agentes asignados"
        data-index="users"
      >
        <template slot-scope="users, record"
          >{{ disabledCell(record.tracking) ? '-' : users.length }}
        </template>
      </a-table-column>
      <!--octava columna acciones-->
      <a-table-column key="action" title="Acciones">
        <template slot-scope="text, record">
          <span v-if="!disabledCell(record.tracking)">
            <a @click="handleEdit(record)">Editar</a>
            <a-divider type="vertical" />
            <a @click="handleAssign(record)">Asignar</a>
          </span>
          <span v-else>
            <a @click="handleViewDetails(record)">Ver detalles</a>
          </span>
        </template>
      </a-table-column>
    </a-table>
    <!--MODALES-->
    <a-modal
      v-model="visibleDetails"
      title="Detalles de la plantilla"
      :footer="null"
    >
      <template v-for="(item, index) in details">
        <template v-if="item.name !== 'Parámetros'">
          <div class="item" :key="index">
            <h6 class="heading-h7 label" style="margin-bottom: 4px">
              {{ item.name }}
            </h6>
            <p class="body-2 value">
              {{ item.value }}
            </p>
          </div>
        </template>
        <template v-else>
          <div :key="index" v-if="item.value.length">
            <h6 class="heading-h6">Parámetros</h6>
            <div v-for="(param, index) in item.value" :key="`param${index}`">
              <h6 class="heading-h7">{{ `Parámetro ${index + 1}` }}</h6>
              <p class="body-1">{{ param.title }}</p>
            </div>
          </div>
        </template>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import moment from 'moment'
moment.locale('es')
export default {
  name: 'TemplatesList',
  props: {
    dataTemplates: {
      type: Object,
      required: true,
    },
    load: {
      type: Boolean,
      required: false,
    },
    requestState: {
      type: String,
      required: false,
    },
  },
  mixins: [attemptMixin],

  data: () => ({
    loadLocal: false,
    details: [],
    visibleDetails: false,
    lastChild: null,
    observer: null,
    morePaginate: true,
    isLoading: false,
    moment: moment,
  }),
  mounted() {
    this.setLastChild()
  },
  computed: {
    /**
     * Mostrar todas las columnas
     * @returns {Boolean}
     */
    showAllColumns() {
      return this.requestState == 'approved' || this.requestState == 'all'
    },
  },
  methods: {
    ...mapActions(['changeTempateState']),
    handleEdit(record) {
      this.$emit('handleEdit', record)
    },
    handleAssign(record) {
      this.$emit('handleAssign', record)
    },
    async changeState(record) {
      const newSatus = !record.active
      const result = await this.changeTempateState({
        templateId: record._id,
      })
      if (result.success) {
        if (newSatus === false) {
          this.$message.warning({
            content: `Recuerda que esta plantilla ya no podrá ser usada por los agentes`,
            duration: 5,
          })
        }
        this.$message.success({
          content: `Se cambió el estado de la plantilla exitosamente`,
          duration: 3,
        })
        this.$emit('onEditState')
      } else {
        this.$message.error({
          content: `Ocurrió un error al actualizar el estado`,
          duration: 2,
        })
      }
    },
    handleViewDetails(record) {
      this.visibleDetails = true
      this.details = [
        { name: 'Nombre de plantilla', value: record.name },
        { name: 'Nombre de visualización', value: record.friendlyName },
        { name: 'Categoría', value: record.category },
        {
          name: 'Lenguaje',
          value: record.lang === 'es' ? 'Español' : 'Inglés',
        },
        {
          name: 'Estado de plantilla',
          value: this.transformState(
            record.tracking[record.tracking.length - 1].status
          ),
        },
        {
          name: 'Última fecha de estado',
          value: moment(
            record.tracking[record.tracking.length - 1].created_at
          ).format('LLL'),
        },
        { name: 'Contenido', value: record.body },
        { name: 'Parámetros', value: record.parameters },
      ]
    },
    setLastChild() {
      this.intervalAttempt(() => {
        const childs = document.getElementsByClassName('ant-table-row')
        this.lastChild = childs[childs.length - 1]
        this.observer = new IntersectionObserver(this.handleBottomReached, {
          threshold: 1.0,
        })
        this.observer.observe(this.lastChild)
      })
    },
    onPaginate() {
      this.morePaginate = true
    },
    offPaginate() {
      this.morePaginate = false
    },
    handleBottomReached(entries) {
      const entry = entries[0]
      if (
        entry.intersectionRatio >= 1 &&
        this.morePaginate &&
        !this.isLoading
      ) {
        this.$emit('onBottomReached')
        this.isLoading = true
      }
    },
    transformState(state) {
      switch (state) {
        case 'approved':
          return 'Aprobado'
        case 'pending':
          return 'Pendiente'
        case 'rejected':
          return 'Rechazado'
      }
    },
    /**
     * Deshabilitar la celda
     * @param {Object[]} tracking
     * @returns {Boolean}
     */
    disabledCell(tracking) {
      return tracking[tracking.length - 1]?.status !== 'approved'
    },
  },
}
</script>

<style lang="sass" scoped>
.item
  margin-bottom: 8px
  .label
    color: $gray_dark_900
  .value
    color: $gray_dark_800
.body
  &-2
    margin-bottom: 0px
    white-space: pre-wrap
    word-wrap: break-word
    font-family: inherit
</style>
