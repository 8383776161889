import generalModule from './generalModule'
import responsiveModule from './responsiveModule'

const modules = {
  generalModule,
  responsiveModule,
}

export default {
  modules,
}
