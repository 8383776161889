import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import format from '@/app/shared/utils/format'
import SimpleCrypto from 'simple-crypto-js'

const crypt_key = process.env.VUE_APP_CRYPT_KEY
const simpleCrypto = new SimpleCrypto(crypt_key)

const actions = {
  /**
   * Obtiene las campañas de acuerdo a los parametros que se pasen
   * @param {} context
   * @param {Number} page
   * @param {Number} limit
   * @param {String} status
   * @param {String} lineId
   * @param {String} templateId
   * @param {String} search
   * @param {String} userId
   * @returns
   */
  async getCompanyStrategies(
    context,
    { page, limit = 12, status, lineId, templateId, search, userId, dateRange }
  ) {
    try {
      const params = {
        page,
        limit,
        status,
        lineId,
        templateId,
        search,
        userId,
        startCreatedAt: JSON.parse(JSON.stringify(dateRange[0])),
        endCreatedAt: JSON.parse(JSON.stringify(dateRange[1])),
      }
      const response = await axios.get(`${vari.UHR}/strategies`, { params })
      context.commit('SET_COMPANY_STRATEGIES', response.data.data.data)
      delete response.data.data.data
      context.commit('SET_PAGINATE_COMPANY_STRATEGIES', response.data.data)
      return response.data
    } catch (error) {
      console.error(error, ['getStrategies'])
      return error.response
    }
  },
  /**
   * Obtiene una campaña
   * @param {Vuex} context
   * @param {String} strategyId - id de la campaña
   */
  async getCompanyStrategy(context, strategyId) {
    try {
      const response = await axios.get(`${vari.UHR}/strategies/${strategyId}`)
      const strategy = response.data.data
      // actualizar la campaña seleccionada
      context.commit('SET_SELECTED_STRATEGY', strategy)
      // actualizar la campaña en las campañas
      context.commit('UPDATE_STRATEGY', { strategyId, ...strategy })
      return response.data
    } catch (error) {
      console.error(error, ['getStrategy'])
    }
  },
  /**
   * Agrega una campaña nueva
   * @param {*} context
   * @param {String} name
   * @param {String} lineId
   * @param {String} templateId
   * @param {String} [dataSource='csv']
   * @param {String} filenameCsv
   * @param {File} urlFileCsv
   * @param {String} delimiterCsv
   * @param {Number} hoursOfLife
   * @param {String} channelCompanyId
   * @returns
   */
  async addCompanyStrategy(
    context,
    {
      name,
      lineId,
      templateId,
      dataSource = 'csv',
      filenameCsv,
      urlFileCsv,
      delimiterCsv,
      hoursOfLife,
      channelCompanyId,
    }
  ) {
    try {
      const params = {
        name,
        lineId,
        templateId,
        dataSource,
        filenameCsv,
        urlFileCsv,
        delimiterCsv,
        hoursOfLife,
        channelCompanyId,
      }
      const response = await axios.post(`${vari.UHR}/strategies`, params)
      if (response.data.success)
        context.commit('ADD_COMPANY_STRATEGY', response.data.data)
      return response.data
    } catch (error) {
      console.error(error, ['addCompanyStrategy'])
      return error.response.data
    }
  },
  /**
   * Elimina una campaña de la empresa
   * @param {*} context
   * @param {*} strategyId
   * @returns
   */
  async deleteCompanyStrategy(context, strategyId) {
    try {
      const response = await axios.delete(
        `${vari.UHR}/strategies/${strategyId}`
      )
      if (response.data.success)
        context.commit('REMOVE_COMPANY_STRATEGY', strategyId)
      return response.data
    } catch (error) {
      console.error(error, ['deleteCompanyStrategy'])
      return error.response
    }
  },
  /**
   * Actualiza el estado de una campaña
   * @param {*} context
   * @param {String} strategyId
   * @param {String} status - play, pause, stop
   * @returns
   */
  async updateStatusStrategy(context, { strategyId, status }) {
    try {
      const response = await axios.put(
        `${vari.UHR}/strategies/${strategyId}/${status}`
      )
      if (response.data.success)
        context.commit('UPDATE_STRATEGY', { strategyId, status })
      return response.data
    } catch (error) {
      console.error(error, ['updateStatusStrategy'])
      return error.response
    }
  },
  /**
   * Sube el archivo y genera una url
   * @param {*} context
   * @param {string} fileMessage
   */
  async uploadFileStrategy(context, { fileCsv, templateId }) {
    try {
      const formData = new FormData()
      formData.append('fileCsv', fileCsv)
      const response = await axios({
        method: 'post',
        url: `${vari.UHR}/strategies/upload-file?templateId=${templateId}`,
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Length': '1024',
          },
        },
      })
      return response.data
    } catch (error) {
      console.error(error, ['updateStatusStrategy'])
      return error.response
    }
  },
  /**
   * genera un archivo csv
   * @param {*} context
   * @param {string} templateId
   */
  async generateFileCsv(context, templateId) {
    try {
      const templateIdCrypto = format.fixedEncodeURIComp(
        simpleCrypto.encrypt(templateId)
      )
      const token = format.fixedEncodeURIComp(context.getters.token)

      const url = `${vari.UHM}/export/strategies/csv-by-template/${templateIdCrypto}?token=${token}`
      window.open(url)
      return { success: true }
    } catch (error) {
      console.error(error, ['generateFileCsv'])
      return { success: false }
    }
  },
  /**
   * Obtiene un listado de plantillas que no tienen paginacion
   * @param {*} context
   * @returns
   */
  async getSimpleTemplates(context, channelCompanyId) {
    try {
      const response = await axios.get(
        `${vari.UHR}/strategies/templates/${channelCompanyId}/list`
      )
      context.commit('SET_SIMPLE_TEMPLATES', response.data.data)
      return response.data
    } catch (error) {
      console.error(error, ['getSimpleTemplates'])
      return error.response
    }
  },
  /**
   * Exportar los registro invalidos de la campaña
   * @param {*} context
   * @param {String} strategyId
   */
  exportInvalidStrategyRecords(context, strategyId) {
    let response = null
    const strategyIdCrypto = format.fixedEncodeURIComp(
      simpleCrypto.encrypt(strategyId)
    )
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${vari.UHM}/export/strategies/${strategyIdCrypto}/invalids?token=${token}`
    response = window.open(url)
    return response
  },
  /**
   * Exportar los registros validos de la campaña
   * @param {*} context
   * @param {String} strategyId
   */
  exportValidStrategyRecords(context, strategyId) {
    let response = null
    const strategyIdCrypto = format.fixedEncodeURIComp(
      simpleCrypto.encrypt(strategyId)
    )
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${vari.UHM}/export/strategies/${strategyIdCrypto}/results?token=${token}`
    response = window.open(url)
    return response
  },
}
export default actions
