import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**
   * Obtener lista de chatbots
   * @param {vuex} context
   * @param {Object} payload
   * @param {Number} payload.page Número de página
   * @param {String} payload.status Estado del chatbot
   * @param {String} payload.name Nombre del chatbot
   * @param {String} payload.channel Canal del chatbot
   * @param {String} payload.channelCompanyId
   * @param {Boolean} [payload.restart=true] Limpia la lista de chatbots e inserta os nuevos datos
   * @returns {Object} {data,pagination}
   */
  async getListChatbots(
    context,
    {
      page,
      status,
      name,
      channel = 'all',
      channelCompanyId = 'all',
      restart = true,
      all = false,
    }
  ) {
    try {
      const url = `${
        vari.UHR
      }/bot/trees/${page}?all=${all}&status=${status}&name=${name}&channel=${channel}&channelCompanyId=${channelCompanyId}&date=${new Date().getTime()}`
      const response = await axios.get(url)
      if (restart) context.commit('SET_CHATBOTS', response.data.data)
      else context.commit('ADD_CHATBOTS', response.data.data)
      const paginate_chatbots = { ...response.data }
      delete paginate_chatbots.data
      context.commit('SET_PAGINATE_CHATBOTS', paginate_chatbots)
      return response.data
    } catch (error) {
      console.error('error', error)
    }
  },

  /**
   * Añade un nuevo chatbot
   * @param {*} context
   * @param {Object} chatbot
   */
  async addChatbot(context, chatbot) {
    try {
      const url = `${vari.UHR}/bot/tree`
      const response = await axios.post(url, chatbot)
      context.commit('ADD_CHATBOT', chatbot)
      return response.data
    } catch (error) {
      console.error('error', error)
      return error.response.data
    }
  },

  /**
   * Elimina un chatbot
   * @param {*} context
   * @param {String} chatbotId -id del chatbot
   */
  async deleteChatbot(context, chatbotId) {
    try {
      const url = `${vari.UHR}/bot/tree/${chatbotId}`
      const response = await axios.delete(url)
      return response.data
    } catch (error) {
      console.error('error', error)
      return { success: false }
    }
  },

  /**
   * Activa o inactiva un chatbot
   * @param {*} context
   * @param {Boolean} active - estado del chatbot
   * @param {String} chatbotId
   * @param {String} action - transfer, finish
   * @param {Object} actionsTransferLine - solo si la accion es de transferencia
   * @param {String} actionsTransferLine.action
   * @param {Object} actionsTransferLine.channels
   * @param {String} commentFinishTicket
   */
  async activeChatbot(
    context,
    { active, chatbotId, action, actionsTransferLine, commentFinishTicket }
  ) {
    try {
      const url = `${vari.UHR}/bot/tree/active/${chatbotId}`
      const response = await axios.patch(url, {
        active,
        action,
        actionsTransferLine,
        commentFinishTicket,
      })
      return response.data
    } catch (error) {
      console.error('error', error)
      return error
    }
  },

  /**
   * Actualiza los datos de un chatbot
   * @param {*} context
   * @param {String} chatbotId - id del chatbot
   * @param {Object} chatbotEdited - chatbot editado
   */
  async updatedChatbot(context, { chatbotId, chatbotEdited }) {
    try {
      const url = `${vari.UHR}/bot/tree/${chatbotId}`
      const response = await axios.put(url, chatbotEdited)
      return response.data
    } catch (error) {
      console.error('error', error)
      return error?.response?.data
    }
  },

  /**
   * Obtiene toda la lista de nodos un chatbot
   * @param {*} context
   * @param {String} chatbotId id de chatbot
   */
  async getNodes(context, chatbotId) {
    try {
      const url = `${
        vari.UHR
      }/bot/tree/${chatbotId}/questions?date=${new Date().getTime()}`
      const response = await axios.get(url)
      context.commit('SET_NODES', response.data)
      return response.data
    } catch (error) {
      console.error('error', error)
      return error.response.data
    }
  },

  /**
   * Obtiene un chatbot
   * @param {*} context
   * @param {String} chatbotId id de chatbot
   */
  async getChatbot(context, chatbotId) {
    try {
      const url = `${
        vari.UHR
      }/bot/tree/${chatbotId}?date=${new Date().getTime()}`
      const response = await axios.get(url)
      context.commit('SET_CHATBOT', response.data)
      return response.data
    } catch (error) {
      console.error('error', error)
    }
  },
  /**Limpia tree Nodes */
  resetTreeNodes(context) {
    context.commit('SET_TREE_NODES', null)
  },
  /**Limpia los Nodes */
  resetNodes(context) {
    context.commit('SET_NODES', [])
  },
  /**
   * Agrega un nodo de opciones al chatbot
   * @param {*} context
   * @param {String} chatbotId
   * @param {Object} nodeOptions
   * @param {String} nodeOptions.message
   * @param {String} nodeOptions.parentNodeId
   * @param {String} nodeOptions.optionNodeId - si proviene de una opcion multiple
   * @param {Array} nodeOptions.options
   * @param {Number} nodeOptions.options[].order - numero de orden de la opcion
   * @param {String} nodeOptions.options[].text
   * @param {Object} nodeOptions.action
   * @param {String} nodeOptions.action.type - continue, transfer, none
   * @param {String} nodeOptions.action.nodeId
   * @param {String} nodeOptions.errorMessage
   */
  async addNodeOptions(context, { chatbotId, nodeOptions }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/multiple/${chatbotId}`
      const response = await axios.post(url, { ...nodeOptions })
      // await context.dispatch('getListNodesChatbot', chatbotId)
      context.commit('ADD_NODE', response.data)
      return response.data
    } catch (error) {
      console.error('error addNodeOptions', error)
      return error.response.data
    }
  },
  /**
   * Agrega un nodo mensaje
   * @param {*} context
   * @param {String} chatbotId
   * @param {Object} nodeMessage
   * @param {String} nodeMessage.message
   * @param {String} nodeMessage.parentNodeId - id del nodo padre que lo contiene
   * @param {String} nodeMessage.optionNodeId - si proviene de una opcion
   * @param {String} nodeMessage.action - continue, transfer, none
   * @param {String} nodeOptions.action.nodeId
   */
  async addNodeMessage(context, { chatbotId, nodeMessage }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/message/${chatbotId}`
      const response = await axios.post(url, { ...nodeMessage })
      context.commit('ADD_NODE', response.data)
      // await context.dispatch('getListNodesChatbot', chatbotId)
      return response.data
    } catch (error) {
      console.error('error addNodeMessage', error)
      return error.response.data
    }
  },
  /**
   * Agregar opcion a opciones multiples
   * @param {*} context
   * @param {String} chatbotId
   * @param {String} nodeId
   * @param {Object} newOption
   */
  async addOptionToNode(context, { chatbotId, nodeId, newOption }) {
    const node = context.getters.nodes.find((node) => node._id === nodeId)
    if (node !== undefined) newOption.order = node.drawer.options.length + 1
    else return
    try {
      const url = `${vari.UHR}/bot/tree/question/multiple/${chatbotId}/${nodeId}`
      const response = await axios.post(url, { ...newOption })
      context.commit('ADD_OPTION', { nodeId, option: response.data })
      return response.data
      // context.dispatch('generateTreeNodes', chatbotId)
    } catch (error) {
      console.error('error', error)
      return error.response.data
    }
  },
  /**
   * Actualiza una opcion
   * @param {*} context
   * @param {String} chatbotId
   * @param {String} nodeId - id del nodo que lo contien
   * @param {String} idOption - id de la opcion
   * @param {Object} optionEdited - opcion editada
   */
  async updateOption(context, { chatbotId, nodeId, idOption, optionEdited }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/multiple/${chatbotId}/${nodeId}/${idOption}`
      const response = await axios.put(url, { ...optionEdited })
      if (response.data.idOption) {
        context.commit('UPDATE_OPTION', { nodeId, newOption: response.data })
        return { success: true, ...response.data }
      }
    } catch (error) {
      console.error('error', error)
    }
  },
  /**
   * Elimina una opcion
   * @param {*} context
   * @param {String} chatbotId id del chatbot
   * @param {String} nodeId - id del nodo padre
   * @param {String} idOption - id de la opcion
   */
  async deleteOption(context, { chatbotId, nodeId, idOption }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/multiple/${chatbotId}/${nodeId}/${idOption}`
      const response = await axios.delete(url)
      context.commit('DELETE_OPTION', { nodeId, idOption })
      if (
        response.data &&
        response.data.drawer &&
        response.data.drawer.options
      ) {
        response.data.drawer.options.forEach((option) => {
          context.commit('UPDATE_OPTION', {
            nodeId: response.data._id,
            idOption: option.idOption,
            replaceAll: false,
            specificProperties: { order: option.order },
          })
        })
      }

      return response.data
    } catch (error) {
      console.error('error', error)
      return { success: false }
    }
  },
  /**
   * Actualiza el nodo de tipo opciones
   * @param {*} context
   * @param {String} chatbotId id del chatbot
   * @param {String} nodeId - id del nodo
   * @param {Object} nodeOptionsEdited - nodo editado
   */
  async updateNodeOptions(context, { chatbotId, nodeId, nodeOptionsEdited }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/multiple/${chatbotId}/${nodeId}`
      const response = await axios.put(url, { ...nodeOptionsEdited })
      context.commit('UPDATE_NODE', { nodeId, node: response.data })
      return response.data
    } catch (error) {
      console.error('error', error)
      return { success: false }
    }
  },
  /**
   * Actualiza el nodo de tipo mensaje
   * @param {*} context
   * @param {String} chatbotId id del chatbot
   * @param {String} nodeId - id del nodo
   * @param {Object} nodeMessage - nodo editado
   */
  async updateNodeMessage(context, { chatbotId, nodeId, nodeMessage }) {
    try {
      const url = `${vari.UHR}/bot/tree/question/message/${chatbotId}/${nodeId}`
      const response = await axios.put(url, { ...nodeMessage })
      if (response.data._id)
        context.commit('UPDATE_NODE', { nodeId, node: response.data })

      return response.data
    } catch (error) {
      console.error('error updateNodeMessage', error)
      return error.response.data
    }
  },
  /**
   * Elimina un nodo mensaje u opciones
   * @param {*} context
   * @param {String} chatbotId
   * @param {String} nodeId
   */
  async deleteNode(context, { chatbotId, nodeId }) {
    try {
      const response = await axios.delete(
        `${vari.UHR}/bot/tree/question/message/${chatbotId}/${nodeId}`
      )
      if (response.data.success) {
        context.commit('DELETE_NODE', nodeId)
      }
      return response.data
    } catch (err) {
      console.error('[deleteNodeOptions]', err)
      return err.response.data
    }
  },
  /**
   * Obtiene los tickets activos del chatbot
   * @param {*} context
   * @param {String} chatbotId
   */
  async getTotalTicketsOfChatbot(context, chatbotId) {
    try {
      const response = await axios.delete(
        `${vari.UHR}/bot/tree/pre-inactive/${chatbotId}`
      )
      return response.data
    } catch (err) {
      console.error('[getTotalTicketsOfChatbot]', err)
      return err.response.data
    }
  },
  /**
   * Duplicar chatbot
   * @param {*} context
   * @param {String} chatbotId
   */
  async duplicateChatbot(context, chatbotId) {
    try {
      const response = await axios.post(
        `${vari.UHR}/bot/tree/clon/${chatbotId}`
      )
      if (response.data.success) {
        const chatbot = response.data.chatbot
        chatbot.isCopy = true
        context.commit('ADD_CHATBOT', response.data.chatbot)
      }
      return response.data
    } catch (err) {
      console.error('[duplicateChatbot]', err)
      return err.response.data
    }
  },
}

export default actions
