<template>
  <section class="section">
    <h5 class="heading-h5 section__title">Guía de usuario</h5>
    <custom-section-scroll
      :master-styles="{ height: 'calc( 100% - 50px )' }"
      :content-styles="{ padding: '0px 40px' }"
    >
      <p class="body-1 section__description">
        Estos pasos te guiarán para realizar ciertas acciones en Ticker, las
        cuales te ayudarán a explorar sus características y enseñarán a usar la
        plataforma.
      </p>
      <a-row :gutter="[16, 16]" v-if="!cardRoutes.includes(undefined)">
        <a-col v-for="cardRoute in cardRoutes" :key="cardRoute._id" :span="24">
          <card-route
            :key="cardRoute._id"
            :title="cardRoute.title"
            :description="cardRoute.abstract.description"
            :tags="cardRoute.tags"
            :picture="cardRoute.assets.picture"
            :check="cardRoute.completed"
            :show-rocket="false"
            @onSelect="handleSelectTuto(cardRoute)"
          />
        </a-col>
      </a-row>
      <tutos-collapse
        class="section__collapse"
        :data-source="otherTutos"
        @onSelectTuto="handleSelectTuto"
      />
    </custom-section-scroll>
    <tuto-modal
      :visible="tutoModal.visible"
      :title="tutoModal.title"
      :description="tutoModal.description"
      :video="tutoModal.video"
      :video-picture="tutoModal.videoPicture"
      :paragraph-bottom="tutoModal.paragraphBottom"
      :paragraph-footer="tutoModal.paragraphFooter"
      :actionBottom="tutoModal.actionBottom"
      @onClose="handleCloseTuto"
      :link-footer="tutoModal.linkFooter"
    />
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import CardRoute from '@/app/shared/components/cards/CardRoute'
import TutosCollapse from '@/app/profile/components/organisms/TutosCollapse'
import TutoModal from '@/app/shared/components/modals/TutoModal'
import tutos from '@/app/commons/utils/variables'

export default {
  name: 'SectionMenuGuide',
  components: {
    CustomSectionScroll,
    CardRoute,
    TutosCollapse,
    TutoModal,
  },
  data: () => ({
    tutoModal: {
      visible: false,
    },
  }),
  computed: {
    ...mapGetters(['profile', 'company']),

    cardRoutes() {
      const mainTutos = tutos.filter((tuto) => tuto.main)
      return mainTutos.map((tuto) => {
        tuto.completed = this.profile?.routes[tuto._id]?.completed
        tuto.tags = this.profile?.routes[tuto._id]?.categories
        return tuto
      })
    },
    otherTutos() {
      return tutos.filter((tuto) => !tuto.main)
    },
  },
  methods: {
    ...mapMutations(['SET_TUTO_MODAL']),
    ...mapActions(['setAnalytics', 'getRouteLocal']),
    /**
     * @param {Object} tuto
     * @param {String} tuto._id
     * @param {Object} tuto.abstract
     * @param {Object} tuto.assets
     * @param {Boolean} tuto.completed
     * @param {String} tuto.description
     * @param {Object} tuto.extras
     * @param {Boolean} tuto.main El card se mostrará en el modal de guía principal?
     * @param {Object} tuto.route
     * @param {Object} tuto.tags
     * @param {String} tuto.title
     */
    handleSelectTuto(tuto) {
      const newTuto = {
        title: tuto.title,
        description: tuto.description,
        video: tuto.assets.videos[0].iframe,
        videoPicture: tuto.assets.videos[0].picture,
        id: tuto._id,
        paragraphBottom: tuto.extras.paragraphs.first,
        paragraphFooter: tuto.extras.paragraphs.second,
        actionBottom: tuto.extras.actions.paragraph_first,
        linkFooter: tuto.extras.linkMoreInformation,
        visible: true,
      }

      const hasWsp = false
      let tutoRoute = { name: tuto.route.name }
      if (tuto.main) {
        if (hasWsp) {
          tutoRoute = { name: 'channel', params: { channel: 'whatsapp' } }
          this.$router.push({
            name: 'channel',
            params: { channel: 'whatsapp' },
          })
        } else {
          this.SET_TUTO_MODAL(newTuto)
          this.$router.push({ name: tuto.route.name })
        }
      }
      this.getRouteLocal(tutoRoute).then((route) => {
        console.log('route', route)
        this.setAnalytics({
          eventName: 'onboarding_select_route',
          extraData: { guia_route: route.path },
        })
      })
    },
    handleCloseTuto() {
      // this.tutoModal.visible = false
      this.SET_TUTO_MODAL({ visible: false })
    },
  },
}
</script>

<style lang="sass" scoped>
.section
  height: 100%
  background-color: $white_000
  border-radius: 4px
  text-align: left
  padding-top: 40px
  color: $gray_dark_900
  border: 1px solid $gray_dark_000
  &__title
    margin-left: 40px
  &__description
  &__collapse
    margin-top: 16px
</style>
