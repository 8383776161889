<template>
  <a-form :form="form" layout="vertical" class="text--left">
    <h4 class="semibold-20 mrg-bottom-8">
      Crea una nueva campaña para WhatsApp
    </h4>
    <p
      class="regular-14"
      :class="{
        form__description: !error.visible,
        'mrg-bottom-0': error.visible,
      }"
    >
      Completa todos los campos e importa tu campaña. Recuerda enviar plantillas
      a contactos que han
      <strong> aceptado recibir información </strong> de tu negocio. Si tienes
      dudas de como hacerlo
      <a
        href="https://www.securitec.pe/docs/ticker/campanas/campana-masiva-whatsapp/"
        target="_blank"
        rel="noopener noreferrer"
      >
        revisa nuestros docs</a
      >.
    </p>
    <a-alert
      v-if="error.visible"
      class="form__alert"
      type="error"
      :message="error.message"
      show-icon
    />
    <a-row>
      <a-form-item label="Nombre de campaña">
        <a-input
          v-decorator="[
            'name',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          placeholder="Escribe aquí..."
        />
      </a-form-item>
    </a-row>
    <a-row>
      <a-form-item label="Número">
        <a-select
          v-decorator="[
            'channelCompanyId',
            {
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          placeholder="Selecciona un número"
          @change="handleChannelCompany"
        >
          <a-select-option
            v-for="channel in filterSimpleChannelWhatsAppByType({
              type: 'gupshup',
            })"
            :key="channel._id"
          >
            {{ channel.title }}
            ( {{ channel.alias ? channel.alias : 'Sin alias' }} )
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-row>
    <a-row :gutter="12">
      <a-col span="12">
        <a-form-item>
          <span slot="label">
            Cola de destino&nbsp;
            <a-tooltip placement="right" :overlayStyle="tooltipStyles">
              <template slot="title">
                Los tickets respondidos serán asignados a esta cola.
              </template>
              <a-icon type="info-circle" class="form__icon-info" />
            </a-tooltip>
          </span>
          <a-select
            v-decorator="[
              'lineId',
              {
                initialValue: !linesFilteredByChannel.length
                  ? undefined
                  : linesFilteredByChannel[0]._id,
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            placeholder="Selecciona una cola"
          >
            <a-select-option
              v-for="line in linesFilteredByChannel"
              :key="line._id"
            >
              {{ line.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="Selecciona una plantilla">
          <a-select
            v-decorator="[
              'templateId',
              {
                initialValue: !simple_templates.length
                  ? undefined
                  : simple_templates[0]._id,
                rules: [
                  { required: true, message: 'Por favor rellena el campo' },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]"
            placeholder="Selecciona una plantilla"
            @change="handleSelectTemplate"
          >
            <a-select-option
              v-for="template in simple_templates"
              :key="template._id"
            >
              {{ template.friendlyName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-form-item>
        <span slot="label">
          Selecciona el tiempo máximo de respuesta&nbsp;
          <a-tooltip placement="right" :overlayStyle="tooltipStyles">
            <template slot="title">
              {{ app }} usará este tiempo para verificar y notificar que las
              respuestas a los envíos son de esta campaña o no.
            </template>
            <a-icon type="info-circle" class="form__icon-info" />
          </a-tooltip>
        </span>
        <a-select
          v-decorator="[
            'hoursOfLife',
            {
              initialValue: timeOptions[0],
              rules: [
                { required: true, message: 'Por favor rellena el campo' },
              ],
            },
          ]"
          placeholder="Selecciona una cola"
        >
          <a-select-option v-for="time in timeOptions" :key="time">
            {{ time }} horas
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-row>
    <!--Generar formato-->
    <a-row type="flex" align="middle" class="mrg-bottom-8">
      <p class="semibold-14 mrg-bottom-0 mrg-right-8 form__label--black">
        Formato para campaña
      </p>
      <a-tooltip placement="right" :overlayStyle="tooltipStyles">
        <template slot="title">
          <a
            href="https://www.securitec.pe/docs/ticker/campanas/plantilla-predefinida-whatsapp/"
            target="_blank"
            rel="noopener noreferrer"
            >Click aquí</a
          >
          si deseas más info de cómo completar la plantilla predefinida.
        </template>
        <a-icon type="info-circle" class="form__icon-info" />
      </a-tooltip>
    </a-row>
    <div class="form__container-download">
      <a
        :disabled="
          hasNewValues({
            valuesForm: form.getFieldsValue(),
            except: 'fileMessage',
          })
        "
        @click="
          handleGenerateAndDownloadFileCsv(
            hasNewValues({
              valuesForm: form.getFieldsValue(),
              except: 'fileMessage',
            })
          )
        "
        >Click aquí para descargar archivo .CSV</a
      >
    </div>
    <div
      v-if="false"
      class="display-flex direction-column form__container-download align--end"
    >
      <div class="display-flex align--center mrg-bottom-12">
        <a-icon type="file-excel" class="form__icon mrg-right-12" />

        <p class="mrg-bottom-0">
          Después de completar todos los campos, descarga el archivos csv y
          completa todos los campos
        </p>
      </div>
      <a-button
        ghost
        type="primary"
        icon="download"
        :disabled="
          hasNewValues({
            valuesForm: form.getFieldsValue(),
            except: 'fileMessage',
          })
        "
        :loading="isWasChanged || loadingDownload"
        @click="handleGenerateAndDownloadFileCsv"
        >{{ nameDownload }}</a-button
      >
    </div>
    <!--Subir archivo-->
    <p class="semibold-14 mrg-bottom-8 form__label--black">
      Sube tu formato con los datos completos
    </p>
    <p class="mrg-bottom-8">
      Tienes un envío
      <strong>
        máximo a {{ tier.tier }} contactos en tu
        <anchor
          href="https://developers.facebook.com/docs/whatsapp/messaging-limits#quality"
          >{{ tier.type }}</anchor
        >
      </strong>
      y recuerda que el tiempo de envío es de 5 segundos por contacto.
    </p>
    <a-tooltip>
      <template
        v-if="
          hasNewValues({
            valuesForm: form.getFieldsValue(),
            except: 'fileMessage',
          })
        "
        slot="title"
        placement="right"
      >
        Completa todos los campos para poder subir tu archivo.</template
      >
      <a-form-item>
        <a-upload-dragger
          v-decorator="[
            'fileMessage',
            {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              rules: [{ required: true, message: 'Por favor sube el archivo' }],
            },
          ]"
          name="file"
          :multiple="false"
          :customRequest="uploadFile"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :disabled="
            hasNewValues({
              valuesForm: form.getFieldsValue(),
              except: 'fileMessage',
            })
          "
        >
          <p>
            <a-icon type="inbox" class="form__icon-inbox" />
          </p>
          <p class="ant-upload-text">Sube aquí tu archivo</p>
          <p class="ant-upload-hint">Solo se aceptan archivos .csv</p>
        </a-upload-dragger>
      </a-form-item>
    </a-tooltip>
  </a-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import supportMixin from '@/app/shared/mixins/support'
import Anchor from '@/app/shared/components/atoms/Anchor'

export default {
  name: 'AddStrategyForm',
  data: () => ({
    app: process.env.VUE_APP_NAME,
    linesAssigned: [],
    disabledDownload: false,
    loadingDownload: false,
    templateId: null,
    fileUploaded: null,
    fileList: [],
    error: {
      visible: false,
      message: '',
    },
    timeOptions: [24, 48, 72],
    isWasChanged: false,
    errorInFile: false,
    tooltipStyles: {
      width: '200px',
    },
    wasSetValueInTemplate: false, // usado para validar si el id del template ya fue seteado
    linesFilteredByChannel: [],
    tier: {
      type: 'Tier 1', // valor por defecto
      tier: 1000,
    },
  }),
  components: {
    Anchor,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form_strategy' })
  },
  created() {
    this.intervalAttempt(async () => {
      if (!this.profile) throw 'no profile'
      this.loadLinesAssigned()
    })
  },
  mounted() {
    this.$nextTick(() => {
      // si ya fue seteado o si las plantillas no existen
      if (
        this.wasSetValueInTemplate ||
        !this.simple_templates ||
        !this.simple_templates.length
      )
        return
      const templateId = this.simple_templates[0]._id
      this.wasSetValueInTemplate = true
      // seleccionar la plantillas por defecto
      this.handleSelectTemplate(templateId)
    })
  },
  mixins: [attemptMixin, supportMixin],
  watch: {
    simple_templates(val) {
      if (this.wasSetValueInTemplate) return
      if (val && val[0]) {
        // seleccionar la plantillas por defecto
        this.handleSelectTemplate(val[0]._id)
        this.wasSetValueInTemplate = true
      }
    },
  },
  computed: {
    ...mapGetters([
      'profile',
      'companyLines',
      'simple_templates',
      'isPlanAdvanced',
      'isPlanFree',
      'simple_company_channels',
    ]),
    /**
     * @return {String} nombre para el boton de descarga
     */
    nameDownload() {
      if (this.isWasChanged) return 'Generando arhivo'
      return this.loadingDownload ? 'Descargando archivo' : 'Descargar archivo'
    },
  },
  methods: {
    ...mapActions([
      'uploadFileStrategy',
      'addCompanyStrategy',
      'getLine',
      'generateFileCsv',
      'getSimpleTemplates',
      'getTierWhatsAppChannel',
    ]),
    ...mapMutations(['SET_MODAL_UPGRADE']),

    /**
     * Subida local
     * @param {Object} args
     * @param {String} args.action
     * @param {String} args.file
     * @param {Function} args.onSuccess
     * @param {Function} args.onError
     * @param {Function} args.onProgress
     */
    async uploadFile({ file, onSuccess, onError }) {
      if (this.isPlanAdvanced || this.isPlanFree)
        return this.SET_MODAL_UPGRADE({ visible: true, type: 'business' })

      this.handleResetError()
      this.handleRemoveFile()

      await this.uploadFileStrategy({
        fileCsv: file,
        templateId: this.templateId,
      })
        .then((res) => {
          if (!res.success) {
            this.error = {
              visible: true,
              message:
                res.details ||
                'Ocurrió un error mientras se subía el archivo 😥.',
            }
            this.errorInFile = true
            this.handleRemoveFile()
            return this.$message.error(
              'Ocurrió un error mientras se subía el archivo 😥.'
            )
          }
          onSuccess(res.urlFile)
          this.fileUploaded = res.urlFile
          this.fileList = [file]
          this.errorInFile = false
          this.hasNewValues({
            valuesForm: this.form.getFieldsValue(),
          })
        })
        .catch((err) => {
          console.error('[uploadFile]', err)
          this.error = {
            visible: true,
            message: 'Ocurrió un error mientras se subía el archivo 😥.',
          }
          onError(err)
        })
    },
    /**
     * Submit de formulario
     */
    handleSubmit() {
      if (this.isPlanAdvanced || this.isPlanFree)
        return this.SET_MODAL_UPGRADE({ visible: true, type: 'business' })
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err && !this.errorInFile) {
          this.handleResetError()

          values.filenameCsv = this.fileUploaded?.filename
          values.urlFileCsv = this.fileUploaded?.gcp
          values.delimiterCsv = this.fileUploaded?.delimiter
          this.$emit('onLoading', true)
          const response = await this.addCompanyStrategy(values)
          this.$emit('onLoading', false)

          if (response.success) {
            this.handleGoToStrategies()
            // si la respuesta tiene title quiere decir que se debería mostrar una notificacion
            if (response.title)
              this.showWarningNotification(response.title, response.details)
            else this.showSuccessNotification()
          } else {
            this.error.visible = true
            this.error.message = response.details || 'Ocurrió un error 😥'
          }
          this.isWasChanged = false
        }
      })
    },
    /**
     * Genera el archivo csv de acuerdo a la plantilla
     */
    async handleGenerateAndDownloadFileCsv(disabled) {
      if (disabled) return
      if (this.isPlanAdvanced || this.isPlanFree)
        return this.SET_MODAL_UPGRADE({ visible: true, type: 'business' })
      this.loadingDownload = true
      const response = await this.generateFileCsv(this.templateId)
      this.loadingDownload = false
      if (!response.success)
        this.$message.error('Ocurrió un error en la descarga')
    },
    /**
     * Seleccion de un template
     * @param {String} templateId
     */
    handleSelectTemplate(templateId) {
      this.templateId = templateId
      this.$emit('onChange', 'templateId', templateId)
      this.isWasChanged = true
      this.handleRemoveFile()
      setTimeout(() => {
        this.isWasChanged = false
      }, 1000)
    },
    /**
     * Verifica si hay un nuevos Valores
     * @param {Object} args
     * @param {Object} args.valuesForm
     * @param {String} args.except
     */
    hasNewValues({ valuesForm, except = null }) {
      const fields = { ...valuesForm }
      // elimina la palabra que no se validara
      delete fields[except]
      // verifica que ningun valor este vacio
      const values = Object.values(fields)

      // si es que hay un valor que se esta exceptuando, se emite que no esta completo
      const copyValuesForm = Object.values({ ...valuesForm })
      // si tiene todos los datos completados
      const disabled =
        !copyValuesForm.length ||
        copyValuesForm.some((val) => {
          if (Array.isArray(val)) return !val || !val.length
          else return !val
        })
      this.$emit('onHasNewValues', disabled)
      return !values.length || values.some((val) => !val)
    },
    /**
     * Verifica si el archivos cumple con las reglas antes de subirlo
     * @param {Object} file
     */
    beforeUpload(file) {
      this.handleRemoveFile()
      let isAllowed = true

      const extension = file.name.split('.').pop()
      const isCsv = extension === 'csv'
      if (!isCsv) {
        this.$message.error('Solo puedes subir archivos csv')
        isAllowed = false
      }
      const isLt5M = file.size / 1024 / 1024 <= 5
      if (!isLt5M) {
        this.$message.error('El tamaño máximo permitido es de 5MB')
        isAllowed = false
      }
      if (this.isPlanAdvanced || this.isPlanFree) {
        this.SET_MODAL_UPGRADE({ visible: true, type: 'business' })
        isAllowed = false
      }
      if (!isAllowed)
        setTimeout(() => {
          this.handleRemoveFile()
        }, 1500)
      return isAllowed
    },
    /**
     * Va hacia campañas
     */
    handleGoToStrategies() {
      this.$router.push({ name: 'campaigns' })
    },
    /**
     * Muestra la notifiacion de exitoso
     */
    showSuccessNotification() {
      this.$notification.success({
        message: 'Tu campaña se creo con éxito',
        description:
          'Recuerda que los tickets generados a partir de esta campaña serán atendidos por tus agentes.',
        duration: 0, // cuando la duracion es 0, nunca se cerrara automaticamente
      })
    },
    /**
     * Muestra la notifiacion de warning
     * @param {String} title
     * @param {String} description
     */
    showWarningNotification(title, description) {
      this.$notification.warning({
        message: title,
        description: description,
        duration: 0, // cuando la duracion es 0, nunca se cerrara automaticamente
      })
    },
    /**
     * Resetea los errores
     */
    handleResetError() {
      this.error = {
        visible: false,
        message: '',
      }
      this.errorInFile = false
    },
    /**
     * Evento de la subida del archivo
     * @param {Object} e - evento
     */
    normFile(e) {
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList
    },
    /**
     * Remueve el archivo subido
     */
    handleRemoveFile() {
      this.fileList = []
      this.form.resetFields(['fileMessage'])
    },
    /**
     * Escucha el evento de cambio de canal
     * @param {String} channelCompanyId
     */
    handleChannelCompany(channelCompanyId) {
      this.getSimpleTemplates(channelCompanyId)
      this.filterLinesByChannelCompany(channelCompanyId)
      this.handleSetTier(channelCompanyId)
    },
    /**
     * @param {String} channelCompanyId
     * @return {Object[]} colas con el canal de whatsapp
     */
    filterLinesByChannelCompany(channelCompanyId) {
      this.linesFilteredByChannel = this.companyLines.filter((line) => {
        if (!line.channels_companies) return false
        else
          return line.channels_companies.some(
            (channelCompany) => channelCompany._id === channelCompanyId
          )
      })
    },
    /**
     * Obtiene y setea el tier a la data local
     * @param {String} channelCompanyId
     */
    async handleSetTier(channelCompanyId) {
      const response = await this.getTierWhatsAppChannel(channelCompanyId)
      if (response.success) {
        const tier = response.data
        this.tier = {
          type: tier.tier_type,
          tier: tier.tier,
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.form__icon
  font-size: 32px
  &-inbox
    font-size: 24px
    color: $gray_8
  &-spin
    font-size: 16px
    color: $gray_8
  &-info
    font-size: 14px
.form
  &__container-download
    margin-bottom: 24px
  &__label--black
    color: $gray_9
  &__description
    margin-bottom: 20px
  &__alert
    margin: 12px 0px
</style>
