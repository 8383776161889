import Accounts from '@/app/accounts/views/Accounts'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/accounts',
    name: 'accounts',
    component: Accounts,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'supervisor'],
      title: `Usuarios | ${VUE_APP_NAME}`,
    },
  },
]
