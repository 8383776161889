<template>
  <div class="filters display-flex justify--between">
    <div class="filters__container display-flex align--start">
      <slot name="filters"></slot>
      <!--Otros filtros, abre el filtro de drawer-->
      <a-tooltip
        v-if="showMoreFilters"
        placement="bottomRight"
        :overlayStyle="{ width: '240px' }"
      >
        <span v-if="showMoreTooltip" slot="title">
          {{ moreTooltipText }}
        </span>
        <a-button class="mrg-left-12" @click="onMoreFilters">
          <span> {{ moreFiltersText }}</span>
          <i class="anticon anticon-right">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'16'" v-bind:width="'16'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 11a4.002 4.002 0 01-3.874-3H2V6h7.126A4.002 4.002 0 0117 7a4 4 0 01-4 4zm6-5h3v2h-3V6zM8 21a4.002 4.002 0 01-3.874-3H2v-2h2.126A4.002 4.002 0 0112 17a4 4 0 01-4 4zm6-3h8v-2h-8v2zm-4-1a2 2 0 11-4 0 2 2 0 014 0zm5-10a2 2 0 11-4 0 2 2 0 014 0z" fill="curretColor"/></svg>
          </i>
        </a-button>
      </a-tooltip>
    </div>
    <div v-if="showButtons" class="filters__buttons">
      <!--Escoger las columnas para la tabla-->
      <slot name="list-columns">
        <a-popover v-if="showOrderCols" placement="bottomRight" trigger="click">
          <template slot="content">
            <list-checkboxes
              :data-source="removeDefault(columns)"
              @onChange="(checked, key) => $emit('onChangeCol', checked, key)"
            >
              <!-- <template v-slot:title="slotsProps">
                <p>{{ slotsProps.title }}</p>
              </template> -->
            </list-checkboxes>
          </template>
          <a-button> Escoger columnas</a-button>
        </a-popover>
      </slot>
      <a-tooltip placement="bottomRight" :overlayStyle="{ width: '240px' }">
        <span v-if="showOkTooltip" slot="title">
          {{ okTooltipText }}
        </span>
        <a-button
          class="mrg-left-12"
          type="primary"
          :icon="okIcon"
          @click="onOk"
          >{{ okText }}</a-button
        >
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import ListCheckboxes from '@/app/shared/components/molecules/ListCheckboxes'

export default {
  name: 'TableFilters',
  props: {
    showOrderCols: {
      type: Boolean,
      required: false,
      default: true,
    },
    onOk: {
      type: Function,
      required: false,
    },
    okText: {
      type: String,
      default: 'Ok',
      required: false,
    },
    okIcon: {
      type: String,
      default: 'plus',
      required: false,
    },
    columns: {
      type: Array,
    },
    onMoreFilters: {
      type: Function,
      required: false,
    },
    moreFiltersText: {
      type: String,
      default: 'Más filtros',
      required: false,
    },
    showOkTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    okTooltipText: {
      type: String,
      default: 'This is a tooltip',
      required: false,
    },
    showMoreTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    moreTooltipText: {
      type: String,
      default: 'This is a tooltip',
      required: false,
    },
    showMoreFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    ListCheckboxes,
  },
  methods: {
    /**
     * Remueve las columnas que vienen por defecto
     * @param {Object[]} columns
     * @return {Object[]} columnas que no son por defecto
     */
    removeDefault(columns) {
      return columns.filter((column) => !column.default)
    },
  },
}
</script>

<style scoped></style>
