/**Evalua canales, usuarios, etc soportados por ticker */
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'company',
      'ticketSelected',
      'channels',
      'simple_company_channels',
      'client',
      'active_breakpoint',
      'profile',
      'lines',
    ]),

    /**
     * Retorna todos los canales habilitados para los filtros
     * @returns {Object[]}
     */
    channelsForFilters() {
      let channelsToSelect = []
      if (this.channels && !!this.channels.length)
        channelsToSelect = this.channels.filter(
          (channel) => channel.channel != 'sms' && channel.channel != 'voip'
        )

      channelsToSelect.unshift({
        _id: 'all',
        channel: 'all',
        name: 'Todos los canales',
        default: true,
      })
      return channelsToSelect
    },
    /**
     * Si la empresa tiene permitida la recarga para whatsapp
     * @returns {Boolean}
     */
    allowRechargeWba() {
      return (
        this.simple_company_channels &&
        !!this.simple_company_channels.length &&
        !!this.filterSimpleChannelWhatsAppByType({ type: 'gupshup_v1' }).length
      )
    },
    /**
     * Ruta dinamica del ticker
     * @returns {String} - drawer-ticket: para la ruta de workspace, drawer-remote-ticket: para la ruta de workspace-monitor
     */
    routeTicket() {
      return this.$route.meta.is_workspace
        ? 'drawer-ticket'
        : 'drawer-remote-ticket'
    },
    /**
     * @returns {Object} item del menu
     * @returns {Object} item.route
     * @returns {String} item.route.name
     * @returns {Object} item.query
     * @returns {String} item.query.mailbox
     * @returns {Object} item.query.status
     * @returns {Object} item.avatar
     * @returns {String} item.avatar.src
     * @returns {String} item.title
     */
    menuItemsTicket() {
      return [
        {
          route: {
            name: this.routeTicket,
            params: { drawerId: 'client' },
            query: {
              mailbox: this.$route.query.mailbox,
              status: this.$route.query.status,
              main:
                this.$route.query.main === 'chat'
                  ? 'drawer'
                  : this.$route.query.main,
            },
          },
          icon: null,
          avatar: {
            src: this.client?.avatar,
            icon: !this.client || !this.client.avatar ? 'user' : null,
          },
          title: 'Perfil de cliente',
        },
        {
          route: {
            name: this.routeTicket,
            params: { drawerId: 'old-tickets' },
            query: {
              mailbox: this.$route.query.mailbox,
              status: this.$route.query.status,
              main:
                this.$route.query.main === 'chat'
                  ? 'drawer'
                  : this.$route.query.main,
            },
          },
          icon: null,
          component: 'IconShoppingCoupon',
          title: 'Tickets anteriores',
        },
        {
          route: {
            name: this.routeTicket,
            params: { drawerId: 'custom-fields' },
            query: {
              mailbox: this.$route.query.mailbox,
              status: this.$route.query.status,
              main:
                this.$route.query.main === 'chat'
                  ? 'drawer'
                  : this.$route.query.main,
            },
          },
          icon: 'form',
          title: 'Campos customizados',
        },
        {
          route: {
            name: this.routeTicket,
            params: { drawerId: 'files' },
            query: {
              mailbox: this.$route.query.mailbox,
              status: this.$route.query.status,
              main:
                this.$route.query.main === 'chat'
                  ? 'drawer'
                  : this.$route.query.main,
            },
          },
          icon: 'folder-open',
          title: 'Archivos',
        },
        {
          route: {
            name: this.routeTicket,
            params: { drawerId: 'products' },
            query: {
              mailbox: this.$route.query.mailbox,
              status: this.$route.query.status,
              main:
                this.$route.query.main === 'chat'
                  ? 'drawer'
                  : this.$route.query.main,
            },
          },
          icon: null,
          component: 'IconShoppingBag',
          title: 'Productos',
        },
      ]
    },
    /**
     * Vista actual del breakpoint
     * @returns {Object} view
     * @returns {String} view.type_menu
     * @returns {String} view.filters
     * @returns {Object} view.styles
     */
    viewBreakpoint() {
      const name = this.$route.name
      const view = this.active_breakpoint.views[name]
      if (!view) return {}
      else return view
    },
  },
  methods: {
    ...mapActions(['getLine']),

    /**
     * @param {Object} ticket
     * @param {Object}  ticket.channel
     * @param {String} ticket.settings.type
     * @param {Object} ticket.settings
     * @param {Boolean} ticket.settings.sandbox
     * @returns {Object} el canal de la empresa que le pertenece al ticket
     */
    channelCompanyOfTicket(ticket) {
      if (!this.channels || !ticket) return null

      // encontrar el canal en la data de los canales de la empresa
      const channelCompany = this.simple_company_channels.find(
        (simple_channel) =>
          simple_channel._id === ticket.channelCompanyId &&
          !!simple_channel.isSandbox === !!ticket.settings.sandbox
      )
      if (!channelCompany) return
      // encontrar el canal en la data de los canales generales
      const generalChannel = this.channels.find(
        (chc) => chc._id === channelCompany.channelId
      )
      if (!generalChannel) return

      generalChannel.api = generalChannel.apis[channelCompany.api_version]

      return generalChannel
    },
    /**
     * Filtra los canales simples de WhatsApp por el tipo
     * @param {String} type - gupshup, twilio
     * @param {Object} defaultChannel
     * @param {String} defaultChannel._id
     * @param {String} defaultChannel.title
     * @param {Object[]} dataSource
     */
    filterSimpleChannelWhatsAppByType({
      type,
      dataSource = null,
      defaultChannel = null,
    }) {
      const arrChannels = dataSource || this.simple_company_channels
      let channelsFiltered = [...arrChannels].filter(
        (simpleChannel) =>
          simpleChannel.channel === 'whatsapp' &&
          simpleChannel.api_version.includes(type)
      )
      if (defaultChannel) return [defaultChannel, ...channelsFiltered]
      else return [...channelsFiltered]
    },
    /**
     * Filtra los canales simples de la empresa por el canal
     * @param {String}  channel - all, whatsapp, messenger, instagram, webchat
     * @param {Object}  defaultChannel
     * @param {String}  [defaultChannel._id]
     * @param {String}  [defaultChannel.title]
     * @param {Boolean} [defaultChannel.request]
     * @param {Object[]} dataSource
     */
    filterSimpleByChannel({
      channel,
      dataSource = null,
      defaultChannel = null,
      request = null,
    }) {
      const arrChannels = dataSource || this.simple_company_channels
      let channelsFiltered =
        channel === 'all'
          ? [...arrChannels]
          : [...arrChannels].filter(
              (simpleChannel) =>
                simpleChannel.channel === channel &&
                (request === null ? true : simpleChannel.request === request)
            )

      if (defaultChannel) return [defaultChannel, ...channelsFiltered]
      else return [...channelsFiltered]
    },
    /**
     * Filtra los canales de un cliente
     * @param {} client
     * @returns {Object[]}
     */
    formatClienthannels(client) {
      if (!client || !client.channels_companies) return []
      return [...client.channels_companies].reduce((acc, current) => {
        const isIncludeInAcc = acc[current.channel]
        if (!isIncludeInAcc)
          acc[current.channel] = {
            channel: current.channel,
            channelTitles: [current.title], // ids de los canales conectados que tienen este canal
          }
        else acc[current.channel].channelTitles.push(current.title)
        return acc
      }, {})
    },
    /**
     * Retorna el alias de un canal de la empresa
     */
    channelCompanyAlias(channelCompanyId) {
      if (!this.simple_company_channels) return null
      const channelFound = this.simple_company_channels.find(
        (simpleChannel) => simpleChannel._id === channelCompanyId
      )
      return channelFound && channelFound.alias ? channelFound.alias : null
    },
  },
  /**
   * Carga la lista de colas a la cual el usuario esta asignado
   */
  async completeAssignedLines() {
    const arrLines = []
    const idAssignedLines = this.profile.company.linesId

    idAssignedLines.forEach(async (idAssignedLine) => {
      let lineFound = this.lines.find((line) => line._id === idAssignedLine)
      if (!lineFound) {
        lineFound = await this.getLine(idAssignedLine)
      }
      arrLines.push(lineFound)
    })

    return arrLines
  },
}
