import initialState from './initialState'

const mutations = {
  /** RESET MODULE */
  RESET_CHATBOT_MODULE(state) {
    const st = initialState()
    Object.keys(st).forEach((key) => {
      state[key] = st[key]
    })
  },
  /**Setea valores a chatbots */
  SET_CHATBOTS(state, chatbots) {
    state.chatbots = chatbots
  },
  /**Setea valores a chatbots */
  ADD_CHATBOTS(state, chatbots) {
    state.chatbots = state.chatbots.concat(chatbots)
  },
  /**Setea valores a chatbots */
  SET_PAGINATE_CHATBOTS(state, paginate_chatbots) {
    state.paginate_chatbots = paginate_chatbots
  },
  /**Añade un chatbot */
  ADD_CHATBOT(state, chatbot) {
    state.chatbots.push(chatbot)
  },
  SET_CHATBOT(state, chatbot) {
    state.chatbot = chatbot
  },
  /**Setea los nodoa a un chatbot */
  SET_NODES(state, nodes) {
    state.nodes = nodes
  },
  ADD_NODE(state, node) {
    state.nodes.push(node)
  },

  SET_TREE_NODES(state, treeNodes) {
    state.treeNodes = treeNodes
  },
  SUM_CHANGES(state, action) {
    if (action !== 'reset' && state.changes >= 0) {
      if (action === 'add') state.changes++
      else {
        if (state.changes >= 1) state.changes--
      }
    } else {
      state.changes = 0
    }
  },
  SET_IS_SAVED_NODE(state, saved) {
    state.isSavedNode = saved
  },
  DELETE_NODE(state, nodeId) {
    const index = state.nodes.findIndex((node) => node._id === nodeId)
    state.nodes.splice(index, 1)
  },
  DELETE_OPTION(state, { nodeId, idOption }) {
    const index = state.nodes.findIndex((node) => node._id === nodeId)
    state.nodes[index].drawer.options = state.nodes[
      index
    ].drawer.options.filter((option) => option.idOption !== idOption)
  },
  ADD_OPTION(state, { nodeId, option }) {
    const index = state.nodes.findIndex((node) => node._id === nodeId)
    if (index === -1) return
    state.nodes[index].drawer.options.push(option)
  },
  /**
   * Actualiza una opcion
   * @param {*} state
   * @param {String} nodeId - obligatorio
   * @param {Object} newOption
   * @param {String} idOption
   * @param {Object} specificProperties
   * @param {String} specificProperties.order
   */
  UPDATE_OPTION(
    state,
    { nodeId, newOption = {}, replaceAll = true, idOption, specificProperties }
  ) {
    const indexNode = state.nodes.findIndex((node) => node._id === nodeId)

    if (indexNode === -1) return
    const indexOption = state.nodes[indexNode].drawer.options.findIndex(
      (option) =>
        option.idOption === newOption?.idOption || option.idOption === idOption
    )
    if (indexOption === -1) return
    // reemplaza toda la opcion
    if (replaceAll)
      return state.nodes[indexNode].drawer.options.splice(
        indexOption,
        1,
        newOption
      )
    else if (!replaceAll && specificProperties) {
      // reemplazara solo algunas propiedades
      for (let property in specificProperties) {
        state.nodes[indexNode].drawer.options[indexOption][property] =
          specificProperties[property]
      }
    }
  },
  UPDATE_NODE(state, { nodeId, node }) {
    const indexNode = state.nodes.findIndex((node) => node._id === nodeId)
    if (indexNode === -1) return
    state.nodes.splice(indexNode, 1, node)
  },
  SET_SELECTED_NODE_ID(state, nodeId) {
    state.selectedNodeId = nodeId
  },
  /**
   * Setear los filtros
   * @param {} state
   * @param {Number} page - número de página
   * @param {String} status - 'all' | 'active' | 'inactive'
   * @param {String} name - filtro por nombre
   * @param {String} channel - '' si es para todos o whatsapp, messenger, instagram
   */
  SET_FILTERS(state, { page, status, name, channel }) {
    if (page) state.filters.page = page
    else if (status) state.filters.status = status
    else if (name) state.filters.name = name
    else if (channel) state.filters.channel = channel
  },
  /**
   * Setea el nodo seleccionado
   * @param {*} state
   * @param {*} node
   */
  SET_NODE_SELECTED(state, node) {
    state.nodeSelected = node
  },
}

export default mutations
