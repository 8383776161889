<template>
  <a-form
    :form="form"
    layout="vertical"
    @submit.prevent="handleSubmit"
    class="text--left"
  >
    <slot name="title">
      <h4 class="semibold-20">Perfil de webchat</h4>
    </slot>
    <p class="regular-14">
      Configura el nombre, estiliza y crea la mejor experiencia para tu webchat.
    </p>
    <a-form-item>
      <h6 class="semibold-14 text--left mrg-bottom-4">Nombre</h6>
      <p class="regular-14 mrg-bottom-12 text--left">
        Escribe un nombre que identifique a tu empresa.
      </p>
      <a-input
        v-decorator="[
          'title',
          {
            rules: [
              {
                required: true,
                message: 'Por favor rellena este campo',
              },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              },
            ],
          },
        ]"
        placeholder="Escribe aquí..."
        :maxLength="30"
        @input="handleInputTitle({ title: $event.target.value })"
      />
    </a-form-item>
    <div>
      <h6 class="semibold-14 text--left mrg-bottom-4">Color</h6>
      <p class="regular-14 mrg-bottom-12 text--left">
        Elige un color que constraste con la marca de tu empresa
      </p>
      <color-picker
        v-decorator="['color']"
        @onChange="handleChangeColor"
        :default-color="defaultColor"
        @onError="(err) => (errorColor = err)"
      />
    </div>
    <a-form-item>
      <div class="display-flex">
        <uploader-avatar
          v-decorator="['avatarUrl']"
          @onChange="handleChangeAvatar"
          :default-avatar="defaultAvatar"
        />
        <div class="form-item__text text--left">
          <h6 class="semibold-14 text--left mrg-bottom-4">
            Sube aquí el avatar
          </h6>
          <p class="regular-14 mrg-bottom-0">
            El tipo de imagen ideal es jpg o png
          </p>
          <p class="regular-14 mrg-bottom-0">
            Las dimensiones ideales son 500px x 500px
          </p>
        </div>
      </div>
    </a-form-item>
  </a-form>
</template>

<script>
import UploaderAvatar from '@/app/shared/components/uploaders/UploaderAvatar'
import ColorPicker from '@/app/channels/components/webchat/pickers/ColorPicker'
import { mapActions, mapMutations } from 'vuex'
import { isEqual } from '@/app/channels/utils/compare'

export default {
  name: 'WebchatProfile',
  components: { UploaderAvatar, ColorPicker },
  data: () => ({
    newFormValues: {
      // se almecenan los nuevos valores del formulario
      title: '',
      color: '#1890FF',
      avatarUrl: null,
    },
    defaultColor: '#1890FF',
    defaultAvatar: null,
    originalValues: {}, // valores del perfil de webchat
    errorColor: false,
    formValues: null, // usado solo para validar si hay algun cambio
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'normal',
    })
  },
  computed: {},
  methods: {
    ...mapActions(['updatedWebchat']),
    ...mapMutations(['SET_WEBCHAT_DEMO']),

    /**Evento de cambio de color
     * @param {String} color
     */
    handleChangeColor(color) {
      this.newFormValues.color = color
      this.defaultColor = color
      this.hasNewValue({ color })
      this.handleUpdateDemo({ color: color })
    },
    /**Submit del perfil*/
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        this.newFormValues.title = values.title
        if (!err && !this.errorColor) {
          this.$emit('onLoading', true)
          this.newFormValues['avatar'] = this.newFormValues.avatarUrl // se necesita hacer esto para guardar el avatar
          const response = await this.updatedWebchat({
            newValues: this.newFormValues,
          })
          console.log(response)

          response.success
            ? this.$emit('onSuccessSubmit', {
                title: response.result.title,
                color: response.result.color,
                avatarUrl: response.result.avatarUrl,
              })
            : this.$message.error(response.details)
          this.$emit('onLoading', false)
        }
      })
    },
    /**
     * Cambia de avatar
     * @param {Object} avatar
     * @param {String} avatar.file
     * @param {String} avatar.imageUrl
     */
    handleChangeAvatar(avatar) {
      this.newFormValues.avatarUrl = avatar.file
      this.defaultAvatar = avatar.imageUrl
      this.hasNewValue({ avatarUrl: avatar.imageUrl })
      this.handleUpdateDemo({ avatarUrl: avatar.imageUrl })
    },
    /**
     * Actualizar los valores de la demo
     * @param {Object} newVal
     * @param {String} newVal[fielName] - el nombre del campo
     * @param {String} newVal.fielName - el nuevo valor del campo
     */
    handleUpdateDemo(newVal) {
      this.SET_WEBCHAT_DEMO(newVal)
    },
    /**
     * Setea valores al formulario
     * @param {Object} newVals
     * @param {String} newVals.title
     * @param {String} newVals.color
     * @param {String} newVals.avatar
     */
    handleSetValues(newVals) {
      if (!newVals) return
      this.originalValues = newVals
      if (newVals.color) this.defaultColor = newVals.color
      if (newVals.avatarUrl) this.defaultAvatar = newVals.avatarUrl
      this.form.setFieldsValue(newVals)
      for (let property in this.newFormValues) {
        this.newFormValues[property] = newVals[property]
      }
    },
    /**Resetea los valores */
    handleResetValues() {
      this.newFormValues = {
        title: '',
        color: '#1890FF',
        avatar: null,
      }
      this.defaultColor = '#1890FF'
      this.defaultAvatar = null
    },
    /**
     * Checka si el valor de un campo ha cambiado con referencia al valor original
     * {title: 'this is my title'}
     * @param {Object} field
     * @param {String} field[fielName] - el nombre del campo
     * @param {String} field.fielName - el nuevo valor del campo
     */
    hasNewValue(field) {
      if (!this.originalValues) return
      if (!this.formValues) this.formValues = this.form.getFieldsValue()
      this.formValues = { ...this.formValues, ...field }
      const hasNew = !isEqual(
        { ...this.formValues },
        { ...this.originalValues }
      )
      this.$emit('onChangeValue', hasNew)
      return hasNew
    },
    /**
     * Evento input del titulo
     * @param {Object} field
     * @param {String} field[fielName] - el nombre del campo
     * @param {String} field.fielName - el nuevo valor del campo
     */
    handleInputTitle(field) {
      this.hasNewValue(field)
      this.handleUpdateDemo(field)
    },
  },
}
</script>

<style lang="sass" scoped>
.mrg-bottom-12
  margin-bottom: 12px
.form-item__text
  margin-left: 16px
</style>
