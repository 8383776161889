var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drawer-settings"},[_c('div',{staticClass:"drawer-settings-header"},[_c('h6',{staticClass:"heading-h7 title"},[_vm._v(_vm._s(_vm.title))]),_c('a-icon',{staticStyle:{"margin-right":"8px"},attrs:{"type":"close"},on:{"click":_vm.handleClose}})],1),_c('a-form',{staticClass:"drawer-settings-body",attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('custom-section-scroll',{attrs:{"master-styles":{
        height: 'calc( 100% - 57px)',
        padding: '12px 0px 12px 12px',
      },"content-styles":{ paddingRight: '12px', position: 'relative' }}},[_c('p',{staticClass:"body-2",staticStyle:{"text-align":"left","margin-bottom":"8px"}},[_vm._v(" Completa los campos requeridos ")]),(_vm.type === 'options')?[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje de opción múltiple"}},[_c('p',{staticClass:"help",staticStyle:{"margin-bottom":"8px","text-align":"left"}},[_vm._v(" Recuerda que sera un mensaje de introducción para tus usuarios. ")]),_c('counter-text',{attrs:{"maxLength":200,"text":_vm.form.getFieldValue('optionsContent')
                ? _vm.form.getFieldValue('optionsContent')
                : ''}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'optionsContent',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellene el campo',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]),expression:"[\n                'optionsContent',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Por favor rellene el campo',\n                    },\n                    {\n                      whitespace: true,\n                      message: 'No se admiten espacios en blanco.',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Escribe aquí","auto-size":{ minRows: 3, maxRows: 5 },"maxLength":200},on:{"input":_vm.onInput}})],1)],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje por respuesta incorrecta"}},[_c('p',{staticClass:"help",staticStyle:{"margin-bottom":"8px","text-align":"left"}},[_vm._v(" Recuerda este mensaje se mostrará al cliente cuando se ingrese una opción diferente a las mostradas ")]),_c('counter-text',{attrs:{"maxLength":350,"text":_vm.form.getFieldValue('errorMessage')
                ? _vm.form.getFieldValue('errorMessage')
                : ''}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'errorMessage',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellene el campo',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]),expression:"[\n                'errorMessage',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Por favor rellene el campo',\n                    },\n                    {\n                      whitespace: true,\n                      message: 'No se admiten espacios en blanco.',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"auto-size":{ minRows: 3, maxRows: 5 },"placeholder":"Escribe aquí","maxLength":350},on:{"input":_vm.onInput}})],1)],1)]:(_vm.type === 'message')?[_c('a-form-item',{staticClass:"section-form-item section-form-item--message",attrs:{"label":"Mensaje"}},[_c('p',{staticClass:"help",staticStyle:{"margin-bottom":"8px","text-align":"left"}},[_vm._v(" Recuerda que será un mensaje de tipo texto para tus usuarios. ")]),_c('counter-text',{attrs:{"maxLength":350,"text":_vm.form.getFieldValue('text') ? _vm.form.getFieldValue('text') : ''}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'text',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellene el campo',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]),expression:"[\n                'text',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Por favor rellene el campo',\n                    },\n                    {\n                      whitespace: true,\n                      message: 'No se admiten espacios en blanco.',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Escribe aquí","auto-size":{ minRows: 3, maxRows: 5 }},on:{"input":_vm.onInput}})],1)],1),_c('a-form-item',[_c('a-row',{attrs:{"type":"flex"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['wait', { valuePropName: 'checked' }]),expression:"['wait', { valuePropName: 'checked' }]"}],attrs:{"disabled":_vm.action === 'none'},on:{"change":_vm.handleChangeWait,"input":_vm.onInput}},[_vm._v(" Esperar una respuesta ")]),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" Tu chatbot esperará una respuesta de tu cliente para continuar. ")]),_c('a-icon',{staticClass:"radio-message__icon",staticStyle:{"height":"fit-content","margin-top":"2px"},attrs:{"type":"info-circle"}})],2)],1)],1),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Selecciona una de las acciones"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'action',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellene el campo',
                  },
                ],
              },
            ]),expression:"[\n              'action',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Por favor rellene el campo',\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},on:{"change":_vm.handleChangeAction}},[_c('div',{staticClass:"radio-message"},[_c('a-radio',{style:(_vm.radioStyle),attrs:{"value":"continue_message","disabled":_vm.disabledAddMessage}},[_vm._v(" Agregar un nuevo mensaje ")]),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" Recuerda que solo puedes tener 2 mensajes seguidos sin esperar respuesta. ")]),_c('a-icon',{staticClass:"radio-message__icon",staticStyle:{"height":"fit-content","margin-left":"-8px"},attrs:{"type":"info-circle"}})],2)],1),_c('a-radio',{style:(_vm.radioStyle),attrs:{"data-test":_vm.DATA_TEST.CHATBOT.EDIT.ADD_MULTIPLE_OPTIONS_RADIO_BUTTON,"value":"continue_options"}},[_vm._v(" Continuar a opciones múltiples ")]),_c('a-radio',{style:(_vm.radioStyle),attrs:{"value":"transfer"}},[_vm._v(" Transferir a cola ")]),_c('a-radio',{style:(_vm.radioStyle),attrs:{"value":"none"},on:{"click":_vm.uncheckWaitAnswer}},[_vm._v(" Finalizar chatbot ")]),(_vm.allowedRollback)?_c('a-radio',{style:(_vm.radioStyle),attrs:{"value":"rollback"}},[_vm._v(" Regresar al nodo anterior ")]):_vm._e()],1)],1)]:[(_vm.type === 'option')?_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":`Opción ${_vm.settings.order}`}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'nameOption',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellene el campo',
                  },
                  {
                    whitespace: true,
                    message: 'No se admiten espacios en blanco.',
                  },
                ],
              },
            ]),expression:"[\n              'nameOption',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Por favor rellene el campo',\n                  },\n                  {\n                    whitespace: true,\n                    message: 'No se admiten espacios en blanco.',\n                  },\n                ],\n              },\n            ]"}],attrs:{"data-test":_vm.DATA_TEST.CHATBOT.EDIT.OPTION_CONTENT_INPUT,"placeholder":"Escribir aquí","maxLength":40},on:{"input":_vm.onInput}})],1):_vm._e(),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Selecciona una de las acciones"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'action',
              {
                rules: [
                  {
                    required: true,
                    message: 'Por favor rellene el campo',
                  },
                ],
              },
            ]),expression:"[\n              'action',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Por favor rellene el campo',\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},on:{"change":_vm.handleChangeAction}},[_c('a-radio',{style:(_vm.radioStyle),attrs:{"value":"continue_message"}},[_vm._v("Agregar mensaje")]),_c('a-radio',{style:(_vm.radioStyle),attrs:{"data-test":_vm.DATA_TEST.CHATBOT.EDIT.ADD_MULTIPLE_OPTIONS_RADIO_BUTTON,"value":"continue_options"}},[_vm._v(" Agregar opciones múltiples ")]),_c('a-radio',{style:(_vm.radioStyle),attrs:{"value":"transfer"}},[_vm._v(" Transferir a cola ")]),_c('a-radio',{style:(_vm.radioStyle),attrs:{"value":"none"}},[_vm._v(" Finalizar chatbot ")]),(_vm.allowedRollback)?_c('a-radio',{style:(_vm.radioStyle),attrs:{"value":"rollback"}},[_vm._v(" Regresar al menú anterior ")]):_vm._e()],1)],1)],(_vm.action === 'transfer')?[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Selecciona una cola"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'lineId',
              {
                rules: [{ required: true, message: 'Completa el campo' }],
              },
            ]),expression:"[\n              'lineId',\n              {\n                rules: [{ required: true, message: 'Completa el campo' }],\n              },\n            ]"}],attrs:{"placeholder":"Selecciona"},on:{"change":_vm.handleChangeLines}},_vm._l((_vm.chatbotLines),function(line){return _c('a-select-option',{key:line.key,attrs:{"value":line.key}},[_vm._v(" "+_vm._s(line.title)+" ")])}),1)],1),(_vm.type === 'option')?[_c('a-switch',{staticClass:"switch-form",attrs:{"size":"small"},on:{"change":function($event){return _vm.setIsSavedItem(false)}},model:{value:(_vm.activeMessageTransfer),callback:function ($$v) {_vm.activeMessageTransfer=$$v},expression:"activeMessageTransfer"}}),_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje de transferencia"}},[_c('counter-text',{attrs:{"maxLength":350,"text":_vm.form.getFieldValue('message')
                  ? _vm.form.getFieldValue('message')
                  : ''}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'message',
                  {
                    rules: [
                      {
                        required: _vm.activeMessageTransfer,
                        message: 'Por favor rellene el campo',
                      },
                      {
                        whitespace: true,
                        message: 'No se admiten espacios en blanco.',
                      },
                    ],
                  },
                ]),expression:"[\n                  'message',\n                  {\n                    rules: [\n                      {\n                        required: activeMessageTransfer,\n                        message: 'Por favor rellene el campo',\n                      },\n                      {\n                        whitespace: true,\n                        message: 'No se admiten espacios en blanco.',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Escribe aquí","disabled":!_vm.activeMessageTransfer,"maxLength":350},on:{"input":_vm.onInput}})],1)],1)]:_vm._e()]:(_vm.action === 'none' && _vm.type === 'option')?[_c('a-form-item',{staticClass:"section-form-item",attrs:{"label":"Mensaje final"}},[_c('p',{staticClass:"help mrg-bottom-8 text--left",staticStyle:{"margin-top":"4px"}},[_vm._v(" Recuerda que este mensaje se mostrará al cliente cuando elija esta opción. ")]),_c('counter-text',{attrs:{"maxLength":350,"text":_vm.form.getFieldValue('message')
                ? _vm.form.getFieldValue('message')
                : ''}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'message',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Por favor rellene el campo',
                    },
                    {
                      whitespace: false,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]),expression:"[\n                'message',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Por favor rellene el campo',\n                    },\n                    {\n                      whitespace: false,\n                      message: 'No se admiten espacios en blanco.',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Escribe aquí"},on:{"input":_vm.onInput}})],1)],1)]:_vm._e(),(_vm.action === 'rollback' && _vm.type === 'option')?[_c('p',{staticClass:"body-2 text-align-left"},[_vm._v(" Recuerda que se volverán a mostrar las opciones del menú anterior. ")])]:(_vm.description)?[_c('p',{staticClass:"body-2 text-align-left"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]:_vm._e()],2),_c('div',{staticClass:"drawer-settings-footer"},[_c('a-button',{staticStyle:{"width":"50%"},attrs:{"disabled":_vm.isSavedNode},on:{"click":_vm.handleCancel}},[_vm._v("Cancelar")]),_c('a-button',{staticStyle:{"margin-left":"12px","width":"50%"},attrs:{"type":"primary","html-type":"submit","disabled":_vm.isSavedNode,"loading":_vm.loading,"data-test":_vm.DATA_TEST.CHATBOT.EDIT.SAVE_OPTION_CHANGES_BUTTON}},[_vm._v("Guardar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }