<template>
  <a-modal :visible="visible" :title="title" @cancel="handleCancel" centered>
    <p>
      Para usarlas, desde el módulo de Workspace, selecciona el ícono de
      <a-icon type="thunderbolt" /> para añadir una respuesta rápida
    </p>
    <a-form layout="vertical" :form="form">
      <a-form-item
        label="Título"
        extra="Una palabra que mostrará rápidamente esta respuesta"
      >
        <a-input
          v-decorator="[
            'title',
            {
              rules: [
                { required: true, message: 'Este campo es requerido' },
                { max: 34, message: '35 caracteres máximo' },
                {
                  whitespace: true,
                  message: 'No se admiten espacios en blanco.',
                },
              ],
            },
          ]"
          :maxLength="35"
          placeholder="Escribe aquí..."
        />
      </a-form-item>
      <a-form-item label="Mensaje">
        <counter-text :text="message" :maxLength="980">
          <a-textarea
            v-decorator="[
              'body',
              {
                rules: [{ required: true, message: 'Este campo es requerido' }],
              },
            ]"
            :auto-size="{ minRows: 4, maxRows: 4 }"
            placeholder="Escribe aquí..."
            @input="handleSetMessage($event.target.value)"
            class="position-relative"
            :maxLength="980"
          >
          </a-textarea>
        </counter-text>
        <emoji-picker
          :icon-styles="{
            fontSize: '16px',
            position: 'absolute',
            top: '8px',
            right: '8px',
          }"
          :show-tooltip="false"
          @onChangeEmoji="handleSetEmoji"
        />
      </a-form-item>
      <a-form-item
        label="Asignar a colas (Opcional)"
        extra="¡RECUERDA! Esta respuesta rápida solo le aparecerá a los agentes asignados a la cola seleccionada"
      >
        <a-select
          mode="multiple"
          placeholder="Selecciona aquí..."
          v-decorator="[
            'linesIds',
            {
              rules: [{ required: false, message: 'Este campo es requerido' }],
            },
          ]"
        >
          <a-select-option v-for="line in lines" :key="line._id">
            {{ line.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <basic-footer
      :ok-text="okText"
      :ok-loading="okButton.loading"
      :cancel-loading="cancelButton.loading"
      :ok-disabled="okButton.disabled"
      :cancel-disabled="cancelButton.disabled"
      cancelText="Cancelar"
      @onOk="handleOk"
      @onCancel="handleCancel"
    />
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicFooter from '@/app/shared/components/molecules/BasicFooter'
import CounterText from '@/app/shared/components/molecules/CounterText'
import EmojiPicker from '@/app/tickets/components/molecules/EmojiPicker'

export default {
  name: 'ModalFormFastAnswers',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataSource: {
      type: Object,
      required: false,
      /**
       * Estructura
       * @param {String} dataSource.title
       * @param {String} dataSource.content
       * @param {Object[]} dataSource.assigned_lines
       */
    },
    okText: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    onOk: {
      type: Function,
      required: true,
    },
  },
  components: { BasicFooter, CounterText, EmojiPicker },
  data: () => ({
    okButton: {
      loading: false,
      disabled: false,
    },
    cancelButton: {
      loading: false,
      disabled: false,
    },
    message: '',
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form_fast_answers' })
  },
  watch: {
    /**
     * Verifica que camnbia la data source
     */
    dataSource() {
      this.$nextTick(() => {
        this.setValues()
      })
    },
  },
  computed: {
    ...mapGetters(['lines']),
  },
  methods: {
    /**
     * Setea valores al formulario
     */
    setValues() {
      if (!this.dataSource || !Object.keys(this.dataSource).length) return
      this.form.setFieldsValue({
        title: this.dataSource.title,
        body: this.dataSource.body,
        linesIds: this.dataSource.lines.map((line) => line._id),
      })
    },
    /**
     * Evento para cancelar
     */
    handleCancel() {
      this.$emit('onClose')
    },
    /**
     * Evento ok para el modal
     */
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (this.dataSource._id) values._id = this.dataSource._id
          this.okButton.loading = true
          this.okButton.disabled = true
          this.cancelButton.disabled = true
          await this.onOk(values)
          this.okButton.loading = false
          this.okButton.disabled = false
          this.cancelButton.disabled = false
        }
      })
    },
    /**
     * Setear el valor al mensaje
     * @param {String}
     */
    handleSetMessage(value) {
      this.message = value
    },
    /**
     * Setear emoji al mensaje
     * @param {String} emoji
     */
    handleSetEmoji(emoji) {
      this.message = this.message + emoji
      this.form.setFieldsValue({
        body: this.message,
      })
    },
  },
}
</script>

<style scoped></style>
