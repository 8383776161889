<template>
  <div class="table-menu-billing-container">
    <div class="table-menu-billing-header">
      <h6 class="table-menu-billing-title heading-h6">{{ title }}</h6>
      <a-icon
        :type="collapse ? 'down' : 'up'"
        @click="handleChangeHide(!collapse)"
      />
    </div>
    <a-divider style="margin-top: 4px; margin-bottom: 12px; color: #c4c4c4" />
    <div v-if="!collapse" class="table-menu-billing-content">
      <div v-if="type === 'slot'">
        <slot></slot>
      </div>
      <div v-else>
        <FilterMenuSpendings :type="type" @search="search" :loading="loading" />
        <a-table
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          class="scrollbar_basic table-menu__table"
          @change="handlePagination"
          size="middle"
          :locale="{ emptyText: 'No hay datos' }"
          :scroll="{ y: 'calc(100vh - 35em)' }"
        >
          <!-- Tag del estado de pago -->
          <span slot="state" slot-scope="state, record" class="state">
            <a-tag
              :color="
                ['Aprobado', 'Reposición'].includes(state) ? 'green' : 'red'
              "
            >
              {{ state }}
            </a-tag>
            <a-popover
              v-if="record.rejectionDetail"
              title="Detalle de pago rechazado"
              :overlayStyle="popStyles"
            >
              <template slot="content">
                <p class="mrg-bottom-0">
                  Este pago no se efectúo porque
                  {{ record.rejectionDetail.toLowerCase() }}. Si tienes dudas
                  <router-link :to="{ name: 'support' }" target="_blank">
                    comunícate con nosotros </router-link
                  >.
                </p>
              </template>
              <a-icon type="info-circle" />
            </a-popover>
          </span>
          <!-- Boton descarga Invoice -->
          <span slot="action" slot-scope="record">
            <a
              @click="handleAction('download', record)"
              :disabled="record.status === 'rejected'"
            >
              Descargar
            </a>
          </span>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import FilterMenuSpendings from '@/app/shop/components/spendings/FilterMenuSpendings.vue'

import { mapMutations, mapActions } from 'vuex'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'TableMenuSpendings',
  components: { FilterMenuSpendings },
  props: {
    fetch: {
      type: Function,
      required: false,
    },
    columns: {
      type: Array,
      default: () => [],
      required: false,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    type: {
      type: String,
      default: '',
      required: true,
      enum: ['spending', 'recharge', 'slot'],
    },
    tableKey: {
      type: String,
      required: false,
    },
    isSelected: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data: () => ({
    // Columnas de la tabla
    collapse: false,
    loading: false,
    transacctions: {},
    page: 1,
    settings: {},
    localColumns: [
      {
        title: 'Fecha',
        key: 'date',
        dataIndex: 'date',
      },
      {
        title: 'Nro de pago',
        dataIndex: 'paycode',
        key: 'paycode',
      },
      {
        title: 'Método',
        dataIndex: 'method',
        key: 'method',
      },
      {
        title: 'Cantidad',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Estado',
        key: 'state',
        dataIndex: 'state',
        scopedSlots: { customRender: 'state' },
      },
      {
        title: 'Acción',
        key: 'action',
        scopedSlots: { customRender: 'action' },
      },
    ],
    popStyles: {
      width: '16.5em',
    },
  }),
  watch: {
    isSelected(val) {
      this.collapse = !val
    },
  },
  mounted() {
    this.collapse = !this.isSelected
  },
  computed: {
    /**
     * @returns {Object}
     */
    pagination() {
      return {
        collapseOnSinglePage: true,
        defaultPageSize: 7,
        size: 'default',
        total: this.transacctions.total || 0,
        current: this.page,
      }
    },
    /**
     * @returns {Object[]}
     */
    data() {
      if (!this.transacctions.data) return []
      return this.transacctions.data
    },
  },
  methods: {
    ...mapMutations(['SET_PAYMENTS_PAGINATION']),
    ...mapActions(['exportPayment']),

    handlePagination(pagination) {
      this.page = pagination.current
      this.nextPage()
    },
    async search(settings) {
      this.loading = true
      this.settings = settings
      this.page = 1
      this.transacctions = await this.fetch(this.page, settings)
      this.loading = false
    },
    async nextPage() {
      this.loading = true
      this.transacctions = await this.fetch(this.page, this.settings)
      this.loading = false
    },
    /**
     * Click en algún botón de acciones
     * @param {String} type [details]
     * @param {Object} record
     */
    async handleAction(type, record) {
      const statusNotAllowed = ['rejected', 'annulled']
      if (statusNotAllowed.includes(record.status)) return
      if (type === 'download') {
        await this.exportPayment(record._id)
      }
    },
    /**
     * Cambia el valor del colapsado
     */
    handleChangeHide(collapse) {
      this.$emit('onCollapse', this.tableKey, collapse)
      this.collapse = collapse
    },
  },
}
</script>

<style lang="sass" scoped>
.table-menu-billing-container
  // width: 80%
.table-menu-billing-header
  display: flex
  justify-content: space-between
  align-items: center
.table-menu-billing-title
  margin-bottom: 0px
.table-menu-billing
  &-container
    &:last-child
      margin-top: 20px
  &-content
.table-menu
  &__table
    margin-top: 12px
</style>
<style lang="sass">
.table-menu__table
  .ant-table-body, .ant-table-body-inner
    &::-webkit-scrollbar
      width: 10px
      height: 12px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px $gray_dark_100
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
