const getters = {
  profile: (store) => store.profile,
  profileRemote: (store) => store.profileRemote,
  modalRemoteLogout: (store) => store.modalRemoteLogout,
  modalSessionExpired: (store) => store.modalSessionExpired,
  internet: (store) => store.internet,
  news: (store) => store.news,
  tutoModal: (store) => store.tutoModal,
  modalStepsWBA: (store) => store.modalStepsWBA,
  modalCreateChatbot: (store) => store.modalCreateChatbot,
  reloadNotification: (store) => store.reloadNotification,
  notification_with_type: (state) => state.notification_with_type,
}

export default getters
