import support from '@/app/shared/utils/support.js'

const getters = {
  companyPricing: (state) => state.companyPricing,
  pricings: (state) => state.pricings,
  isPlanFree: (state) => {
    if (!state.companyPricing) return true
    return state.companyPricing.plan.key === 'free'
  },
  isPlanAdvanced: (state) => {
    if (!state.companyPricing) return false
    return state.companyPricing.plan.key === 'advanced'
  },
  // gastos por canal
  spent_channels: (state) => {
    if (!state.companyPricing) return {}

    const channelsAllowed = support.channelsAllowed
    // aqui se almacenara todos los gastos de los canales
    const spent_channels = {}

    channelsAllowed.forEach((channelAllowed) => {
      // el formato está de acuerdo a los permisos de back
      const permission = `connect_${channelAllowed}_channels`
      // gasto del canal de acuerdo al permiso
      const spent = state.companyPricing.spent.find(
        (item) => item.permission === permission && item.active
      )
      if (spent) {
        // si el los usados son iguales al limite
        const isFull = spent.limit === spent.used
        spent.isFull = isFull
        spent_channels[channelAllowed] = spent
      }
    })
    return spent_channels
  },
}

export default getters
