<template>
  <div class="section-mode-agent" style="margin-bottom: 20px">
    <div class="section-mode-agent-header"></div>
    <!-- Mensajes -->
    <div class="description">
      <div style="width: 100%; display: flex">
        <!-- Ilustración -->
        <div class="ilustration">
          <div>
            <!-- Ilustración de una persona con su laptop -->
            <svg
              :class="{ inactive: !activeAnySwitch }"
              width="206"
              height="95"
              viewBox="0 0 206 95"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M86.3288 35.0103C86.5673 35.0103 86.7607 35.2024 86.7607 35.4395C86.7607 35.6681 86.5809 35.8549 86.3542 35.868L86.3288 35.8687H49.4879C38.778 35.8687 30.0698 44.4165 29.8967 55.0192L29.8941 55.3412V93.7567C29.8941 93.9937 29.7007 94.1859 29.4622 94.1859C29.2322 94.1859 29.0441 94.0072 29.031 93.7819L29.0303 93.7567V55.3412C29.0303 44.2282 38.0104 35.1926 49.1497 35.013L49.4879 35.0103H86.3288Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M54.8001 35.0244C55.0387 35.0244 55.232 35.2166 55.232 35.4536C55.232 35.6907 55.0387 35.8829 54.8001 35.8829C45.3587 35.8829 37.6933 43.407 37.5409 52.7537L37.5386 53.0375V93.7565C37.5386 93.9936 37.3452 94.1858 37.1067 94.1858C36.8767 94.1858 36.6887 94.0071 36.6755 93.7818L36.6748 93.7565V53.0375C36.6748 43.0857 44.7863 35.0244 54.8001 35.0244Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M104.629 41.7205C104.652 41.4931 104.849 41.3249 105.076 41.3344L105.102 41.3361L105.246 41.3505C105.28 41.3539 105.314 41.3614 105.347 41.3729L105.379 41.3857L123.023 49.2298C125.383 50.3364 127.219 52.3038 128.148 54.7181L128.181 54.8056L129.952 59.5557C130.035 59.778 129.921 60.0249 129.697 60.1073C129.482 60.1867 129.243 60.084 129.152 59.8773L129.142 59.8539L127.372 55.1043C126.543 52.8901 124.883 51.0781 122.747 50.05L122.662 50.0098L105.09 42.1978L105.016 42.1903C104.787 42.1676 104.618 41.9712 104.627 41.7457L104.629 41.7205Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M82.23 39.6796C82.4496 39.5872 82.7031 39.6891 82.7962 39.9074C82.8859 40.1179 82.7936 40.3597 82.59 40.4596L82.5669 40.4701L65.2327 47.7671C60.5641 49.7237 56.7933 53.346 54.6445 57.9259C54.636 57.9441 54.6262 57.9616 54.6153 57.9783L54.5938 58.0084L54.4588 58.1895L54.2948 58.4128C54.2365 58.4927 54.1747 58.5778 54.1095 58.6682L54.06 58.7369C53.6548 59.3004 53.2058 59.9437 52.7232 60.6584L52.6323 60.7932C51.1543 62.9906 49.6762 65.3871 48.297 67.9015C46.0428 72.011 44.2799 76.0243 43.1963 79.7835C41.6524 85.1399 41.5724 89.6948 43.2276 93.2022C43.3289 93.4168 43.2359 93.6724 43.02 93.773C42.804 93.8737 42.5468 93.7813 42.4455 93.5667C40.6863 89.8389 40.7698 85.0845 42.3659 79.5471C43.469 75.7199 45.2563 71.6513 47.5385 67.4906C48.931 64.952 50.4226 62.5338 51.9142 60.3162L52.097 60.0455C52.5198 59.4217 52.9163 58.8532 53.2799 58.3456L53.3572 58.2378C53.476 58.0726 53.5845 57.9234 53.682 57.7908L53.8811 57.5221L53.9293 57.4203C56.1566 52.763 60.0007 49.0704 64.7508 47.0381L64.8965 46.9764L82.23 39.6796Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M69.4882 45.3544C69.7029 45.2724 69.9429 45.3721 70.0365 45.5777L70.0463 45.601L73.7134 55.0819L80.9502 56.1469C81.3586 56.207 81.4539 56.7433 81.1042 56.9426L81.0812 56.9548L75.6225 59.6871L75.9833 60.245C76.3171 60.7626 76.6819 61.3322 77.0707 61.944C78.3526 63.9612 79.6345 66.0254 80.8313 68.0184C81.0533 68.3881 81.2706 68.7525 81.4828 69.111L81.6411 69.3788C82.9194 71.5462 83.9805 73.4466 84.7711 75.0067C84.8784 75.2184 84.7927 75.4765 84.5796 75.5831C84.3666 75.6898 84.1069 75.6046 83.9996 75.3928L83.8914 75.1805C83.159 73.7511 82.2041 72.0399 81.0677 70.1048L80.8959 69.8129C80.6349 69.3704 80.3659 68.9186 80.0895 68.4584C78.982 66.6141 77.8008 64.7076 76.6142 62.8341L76.3403 62.4024C75.9526 61.7923 75.5888 61.2243 75.2561 60.7082L74.9396 60.2186L74.6367 59.7529C74.5012 59.5459 74.5695 59.2703 74.7812 59.148L74.8045 59.1355L79.4749 56.7975L73.3375 55.8944C73.193 55.8731 73.0698 55.7809 73.0089 55.6504L72.9976 55.6238L69.2401 45.909C69.1545 45.6878 69.2656 45.4394 69.4882 45.3544Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M119.188 48.4881C119.227 48.2544 119.45 48.0966 119.685 48.1359C119.912 48.1737 120.068 48.3808 120.043 48.6052L120.04 48.6302L118.6 57.1574C118.576 57.3007 118.48 57.4214 118.348 57.4793L118.321 57.4901L112.471 59.5921L115.237 60.3496C115.458 60.4104 115.592 60.6323 115.544 60.853L115.538 60.8775C115.477 61.0979 115.254 61.2305 115.032 61.1832L115.007 61.1772L110.933 60.0613C110.536 59.9525 110.506 59.4104 110.876 59.2537L110.901 59.2438L117.79 56.7679L119.188 48.4881Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M57.4372 73.9322C59.5465 73.5904 64.8234 73.6738 72.362 74.071L72.5911 74.0831C74.5086 74.1851 76.5234 74.3051 78.6021 74.4392L79.817 74.5188C81.4312 74.626 83.0207 74.7373 84.5547 74.8494L85.9871 74.9556C86.3726 74.9846 86.7393 75.0124 87.0854 75.039L88.6097 75.1585C88.9165 75.1833 89.0966 75.5084 88.9614 75.7772L88.9494 75.7995L87.7545 77.8741C87.636 78.0798 87.3721 78.1511 87.1651 78.0334C86.9657 77.92 86.8918 77.6726 86.9924 77.4707L87.0048 77.4476L87.8614 75.9603L86.8882 75.8849C86.4936 75.8547 86.0733 75.8228 85.6296 75.7897L84.4913 75.7056C82.7038 75.5749 80.841 75.4453 78.9514 75.3222L78.5461 75.2959C76.73 75.1786 74.9629 75.0723 73.2672 74.9793L72.0928 74.9165C64.7519 74.5336 59.5765 74.4553 57.5762 74.7795C57.3407 74.8176 57.1188 74.6589 57.0804 74.4249C57.042 74.191 57.2017 73.9704 57.4372 73.9322Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M71.4311 66.4098C71.4976 66.1821 71.7372 66.0511 71.9663 66.1172C72.1872 66.181 72.3176 66.4047 72.267 66.6247L72.2606 66.6491L70.058 74.1891C69.9915 74.4168 69.7518 74.5478 69.5228 74.4817C69.3019 74.4179 69.1715 74.1942 69.222 73.9742L69.2284 73.9498L71.4311 66.4098Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M76.0992 74.3465C76.3031 74.2235 76.5688 74.2882 76.6925 74.4909C76.8161 74.6936 76.7511 74.9575 76.5471 75.0805C73.4203 76.9651 71.1574 79.6076 69.637 82.8205C68.6183 84.9733 67.9807 87.2795 67.6528 89.6171C67.4459 91.0925 67.386 92.3791 67.4112 93.3732L67.4178 93.5805L67.4251 93.7277C67.4412 93.9642 67.2613 94.1689 67.0233 94.1849C66.7853 94.2009 66.5794 94.0222 66.5632 93.7857L66.5568 93.664C66.5556 93.6367 66.5543 93.6058 66.5531 93.5714L66.5477 93.3963C66.5213 92.3574 66.5834 91.0241 66.7973 89.4986C67.1365 87.0805 67.7968 84.6922 68.8554 82.4552C70.4449 79.0961 72.8194 76.3233 76.0992 74.3465Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M106.945 76.2441C103.993 76.7162 101.215 76.7591 98.8826 76.6304C95.9744 76.473 93.4406 76.0152 91.5547 75.5859C91.6267 76.2298 91.6987 76.8879 91.7418 77.5317C93.6134 77.9324 96.0032 78.3187 98.7098 78.476C99.5304 78.519 100.351 78.5476 101.157 78.5476C103.101 78.5476 104.987 78.4045 106.815 78.1327L106.945 76.2441Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M101.013 74.7274C103.072 74.7274 105.102 74.5986 107.074 74.3554L107.204 72.4525C102.914 73.0391 98.9115 72.939 95.6579 72.5956C93.9735 72.4239 92.4043 72.1807 90.979 71.8945C91.0942 72.5384 91.2094 73.1965 91.3101 73.8546C92.577 74.0836 93.9447 74.2839 95.3988 74.4412C97.2991 74.6272 99.1707 74.7274 101.013 74.7274Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M94.0309 67.0445C92.4905 66.9014 91.0508 66.7011 89.7119 66.4722C89.8991 67.116 90.0718 67.7741 90.2302 68.4466C91.3531 68.6183 92.5336 68.7757 93.7862 68.8901C95.6001 69.0618 97.3997 69.1477 99.1561 69.1477C101.891 69.1477 104.555 68.933 107.132 68.5181C107.247 68.5038 107.348 68.4752 107.463 68.4609L107.592 66.5294C102.554 67.4451 97.8028 67.4022 94.0309 67.0445Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M99.4441 64.3263C102.064 64.3263 104.9 64.083 107.823 63.4678L107.952 61.5363C102.381 62.8097 97.0974 62.5951 93.0664 61.9942C91.1948 61.7223 89.4672 61.3503 87.9412 60.9497C88.1859 61.6365 88.445 62.3375 88.6754 63.0529C89.9279 63.3391 91.2812 63.6109 92.7208 63.8255C94.722 64.1117 96.9822 64.3263 99.4441 64.3263Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.327 56.1423C102.251 57.6303 96.2481 57.3871 91.67 56.7432C89.5105 56.4428 87.5381 56.0279 85.7817 55.5986C86.0841 56.2854 86.3864 57.0008 86.6887 57.7161C88.1428 58.0452 89.7264 58.3313 91.3964 58.5746C93.9158 58.9323 96.3776 59.104 98.7675 59.104C100.61 59.104 102.41 59.0038 104.152 58.7892C105.534 58.6175 106.873 58.3886 108.197 58.0881L108.327 56.1423Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.644 51.3491C102.165 52.8657 95.7442 52.5224 90.8925 51.7641C87.8981 51.2919 85.2203 50.6481 83.0608 50.0186C83.4351 50.7339 83.8238 51.4779 84.2125 52.2648C85.9689 52.7227 88.0132 53.1805 90.2303 53.5382C93.268 54.0246 96.2337 54.2822 99.0986 54.2822C100.653 54.2822 102.179 54.2106 103.662 54.0676C105.332 53.9102 106.945 53.6527 108.514 53.3093L108.644 51.3491Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M87.6678 46.5846C84.5005 45.9837 81.8371 45.2111 79.9368 44.5815C80.3687 45.2683 80.8438 46.0695 81.3764 46.9709C83.4783 47.5861 86.3001 48.2872 89.5249 48.7593C90.0288 48.8309 90.5615 48.9024 91.1085 48.9739C89.856 48.2729 88.7043 47.443 87.6678 46.5846Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M103.72 47.1142C103.547 47.5434 103.36 47.944 103.144 48.3303C102.971 48.6165 102.77 48.8883 102.525 49.1172C104.612 48.9026 106.743 48.5163 108.888 47.944L109.018 45.9839C107.247 46.499 105.476 46.8709 103.72 47.1142Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M86.7661 29.9639C86.8035 29.7298 87.0249 29.5702 87.2604 29.6074C87.4876 29.6433 87.645 29.8492 87.6224 30.0737L87.6191 30.0988L86.4674 37.2954C86.4523 37.3896 86.4062 37.4758 86.3367 37.5407L86.3127 37.5615L82.6089 40.5432L82.6965 40.6633C82.9574 41.0192 83.2636 41.4163 83.6124 41.8444C84.6099 43.0686 85.7476 44.2929 87.0042 45.4346C89.6403 47.8295 92.4344 49.5325 95.3024 50.2464C98.0319 50.9284 101.668 50.0087 102.77 48.1166C103.822 46.2985 104.323 43.8241 104.616 39.7043L104.64 39.3444L104.633 39.3277C104.547 39.1285 104.625 38.8963 104.814 38.788L104.837 38.7756C105.05 38.6696 105.31 38.7555 105.416 38.9675C105.468 39.0705 105.498 39.1898 105.504 39.3033L105.505 39.3455L105.504 39.3737L105.477 39.7719C105.176 44.0114 104.654 46.5828 103.518 48.5455C102.191 50.8245 98.157 51.8448 95.0922 51.0791C92.066 50.3258 89.1544 48.5511 86.4214 46.0682C85.1307 44.8955 83.9642 43.6402 82.9411 42.3847L82.7788 42.184C82.5134 41.8532 82.2739 41.5418 82.0613 41.2544L81.9983 41.1689C81.9443 41.0951 81.8942 41.026 81.8483 40.9617L81.7518 40.8255C81.7095 40.7651 81.6805 40.7228 81.665 40.6999C81.5463 40.5231 81.5761 40.2881 81.7314 40.1459L81.7524 40.1279L85.6403 36.9979L86.7661 29.9639Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M100.811 6.042C102.038 6.04337 103.245 6.16827 104.38 6.45274C107.525 7.24112 109.662 9.12471 110.249 12.3568C110.976 16.3719 111.114 20.9726 110.79 25.4804L110.774 25.7041L110.766 25.9555C110.751 26.337 110.729 26.737 110.692 27.2073L110.674 27.4296C110.586 28.4508 110.45 29.4994 110.254 30.5475C109.715 33.4301 108.812 35.881 107.444 37.6342C105.858 39.6611 103.277 40.685 100.254 40.7239C97.6124 40.758 94.8415 40.012 92.974 38.8029L92.7823 38.6776C89.7288 36.6722 88.8848 35.5973 87.8823 32.7949L87.8512 32.7077C87.7707 32.4836 87.6484 31.6327 87.369 29.4851C87.3384 29.25 87.5053 29.0347 87.7419 29.0043C87.9785 28.9739 88.195 29.1399 88.2256 29.375L88.3682 30.4574C88.5313 31.6759 88.6282 32.3173 88.6653 32.4207L88.7235 32.5835C89.6696 35.2066 90.4236 36.1191 93.4462 38.0841C95.1648 39.1968 97.7674 39.8975 100.243 39.8656C103.024 39.8297 105.355 38.9047 106.762 37.1077C108.029 35.4832 108.889 33.1509 109.405 30.3907C109.595 29.3723 109.728 28.3513 109.813 27.3569L109.831 27.1405C109.867 26.6828 109.889 26.2942 109.902 25.9235L109.911 25.6692L109.912 25.6494C110.252 21.1342 110.124 16.5139 109.399 12.509C108.879 9.64402 107.011 7.9975 104.169 7.28512C103.109 7.01952 101.971 6.90175 100.81 6.90045C100.066 6.89962 99.4248 6.94379 98.8693 7.01002L98.7775 7.02125C94.1903 7.58717 91.0924 9.48921 89.2115 12.3949C87.8415 14.5114 87.2249 16.9704 87.1348 19.4304C87.1261 19.6673 86.9258 19.8524 86.6874 19.8437C86.449 19.8351 86.2628 19.6361 86.2715 19.3992C86.3669 16.798 87.0197 14.1941 88.485 11.9305C90.5059 8.80848 93.8321 6.76633 98.6701 6.16947C99.2792 6.09293 99.9853 6.04108 100.811 6.042Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M114.964 23.4496C114.863 21.1604 113.409 19.3291 111.465 18.3848C111.292 18.6423 111.091 18.8712 110.904 19.0429C110.947 22.2049 110.702 27.9421 110.429 29.6161C110.573 29.5732 110.717 29.5303 110.846 29.4874C110.731 30.5032 110.414 31.6764 109.651 32.5205C108.989 33.2502 108.082 33.6508 106.887 33.7653C106.628 33.5364 106.282 33.3933 105.908 33.3933C105.088 33.3933 104.44 34.0514 104.44 34.8527C104.44 35.6539 105.102 36.312 105.908 36.312C106.311 36.312 106.671 36.1546 106.93 35.8971C108.744 35.7683 110.184 35.1102 111.235 33.9513C112.862 32.1485 113.078 29.5875 113.049 28.1281C114.301 26.9549 115.05 25.281 114.964 23.4496Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M104.516 22.1387C104.743 22.1028 104.957 22.2499 105.005 22.4703L105.01 22.4951L106.075 29.1481C106.122 29.4368 105.871 29.6833 105.585 29.6401L105.56 29.6357L103.156 29.1349C102.923 29.0863 102.773 28.8588 102.822 28.6267C102.869 28.403 103.084 28.2568 103.308 28.2902L103.333 28.2947L105.124 28.6676L104.157 22.63C104.121 22.4043 104.269 22.1917 104.491 22.1434L104.516 22.1387Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M107.575 23.8759C107.957 23.8593 108.245 23.3723 108.219 22.7882C108.193 22.204 107.863 21.7439 107.482 21.7605C107.101 21.7771 106.813 22.2641 106.838 22.8482C106.864 23.4324 107.194 23.8925 107.575 23.8759Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M100.824 23.8471C101.205 23.8305 101.493 23.3435 101.468 22.7594C101.442 22.1752 101.112 21.7151 100.731 21.7317C100.349 21.7483 100.061 22.2353 100.087 22.8194C100.113 23.4036 100.443 23.8637 100.824 23.8471Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M96.7128 18.1044C97.5226 17.6048 98.4891 17.4096 99.5706 17.6984C99.801 17.7599 99.9376 17.9954 99.8757 18.2243C99.8138 18.4532 99.5769 18.589 99.3465 18.5275C98.5138 18.3051 97.7875 18.4518 97.1685 18.8338C97.0228 18.9236 96.8957 19.0197 96.7887 19.1147L96.7513 19.1486C96.7173 19.1801 96.6935 19.2041 96.6801 19.2185L96.6744 19.2249C96.5176 19.4036 96.2447 19.422 96.065 19.2662C95.8852 19.1104 95.8666 18.8392 96.0234 18.6606L96.0521 18.6291C96.0901 18.5888 96.1438 18.5362 96.213 18.4748C96.3559 18.3478 96.5226 18.2218 96.7128 18.1044Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M105.48 16.9806C105.884 16.9057 106.307 16.8927 106.717 16.984C107.394 17.1349 107.916 17.5536 108.172 18.2698C108.252 18.4932 108.135 18.7386 107.91 18.8179C107.685 18.8973 107.438 18.7805 107.358 18.5571C107.207 18.1341 106.929 17.911 106.528 17.8216C106.249 17.7595 105.937 17.7691 105.639 17.8244L105.584 17.8353C105.549 17.8423 105.521 17.8489 105.498 17.8546L105.459 17.8654C105.23 17.9346 104.989 17.8069 104.919 17.5802C104.85 17.3535 104.978 17.1136 105.206 17.0444L105.25 17.0319C105.307 17.0164 105.385 16.9983 105.48 16.9806Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M103.245 31.8483C101.157 34.1232 99.9911 32.0915 98.9978 30.6035C98.7386 30.2315 99.0841 29.7308 99.5304 29.8452C101.402 30.3317 103.95 31.0757 103.245 31.8483Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M112.128 10.1438C111.466 8.71301 110.645 8.3124 109.911 8.3124C109.882 8.05487 109.839 7.79734 109.781 7.5398C109.277 5.26492 107.823 3.33341 105.837 2.14589V2.14589C105.837 2.14589 103.389 -0.443759 95.7013 0.0999238C87.9991 0.643607 87.7975 5.036 87.7975 5.036V5.036C84.2992 7.38242 81.7654 10.3298 81.5782 12.8336C81.2615 16.9827 82.1685 19.2719 83.2194 20.5453C85.8252 20.3021 87.5816 21.7042 88.2438 21.1748C90.792 19.1288 91.1951 14.3215 92.4189 11.7319C92.5772 11.3885 93.0091 11.2454 93.3402 11.4457C95.4997 12.6619 98.4366 13.0482 101.906 12.576C105.664 12.061 107.348 10.3154 108.097 8.94193C108.126 8.97055 108.14 8.99916 108.169 9.01347C108.198 9.05639 108.241 9.08501 108.27 9.12793C108.284 9.14224 108.298 9.15654 108.313 9.18516C108.342 9.21377 108.37 9.2567 108.399 9.28531C108.413 9.31392 108.442 9.32823 108.457 9.35685C108.485 9.38546 108.5 9.41408 108.529 9.457C108.557 9.48562 108.572 9.51423 108.601 9.54284C108.629 9.57146 108.644 9.60007 108.673 9.62869C108.701 9.6573 108.716 9.70023 108.745 9.72884C108.759 9.75746 108.788 9.78607 108.802 9.81469C108.831 9.85761 108.845 9.88622 108.874 9.92915C108.889 9.95776 108.903 9.98638 108.917 10.015C108.946 10.0579 108.975 10.1008 108.989 10.1438C109.004 10.1724 109.018 10.1867 109.033 10.2153C109.061 10.2582 109.09 10.3154 109.105 10.3584C109.119 10.387 109.133 10.4013 109.133 10.4299C109.162 10.4871 109.191 10.5444 109.22 10.6016C109.479 11.1453 109.666 11.7462 109.796 12.433C110.199 14.6792 110.4 17.0113 110.472 19.2433C110.559 19.2576 110.659 19.229 110.746 19.1575C112.315 17.9413 113.711 13.5346 112.128 10.1438Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M92.4359 18.972C92.1833 18.9621 91.9761 19.1689 91.9872 19.4199C92.0802 21.5114 92.4202 23.1281 93.1202 24.3413C93.9738 25.8205 95.3411 26.6255 97.2638 26.6547C100.721 26.6224 102.723 25.352 103.506 23.2452C103.782 22.5023 103.884 21.7098 103.855 20.9132C103.845 20.6286 103.819 20.3606 103.781 20.1147L103.766 20.0221C103.749 19.9189 103.732 19.8327 103.717 19.7655L103.708 19.7288C103.662 19.5425 103.498 19.4089 103.305 19.4012L92.4359 18.972ZM92.8758 19.8485L102.927 20.2454L102.927 20.2434C102.96 20.4579 102.983 20.6933 102.992 20.9436C103.017 21.6362 102.929 22.3199 102.696 22.9477C102.042 24.705 100.369 25.7673 97.2663 25.7964C94.3959 25.7526 93.1397 23.7728 92.8778 19.8788L92.8758 19.8485Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M92.2388 19.0249C92.4557 18.9262 92.712 19.0209 92.8114 19.2364C92.9072 19.4442 92.822 19.6885 92.6214 19.7942L92.5986 19.8054L89.6761 21.136C89.4592 21.2347 89.2029 21.1401 89.1036 20.9245C89.0078 20.7167 89.0929 20.4724 89.2936 20.3667L89.3163 20.3555L92.2388 19.0249Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M104.907 21.9337C105.146 21.9292 105.343 22.1177 105.347 22.3547C105.351 22.5833 105.175 22.7735 104.949 22.7908L104.923 22.792L103.397 22.8206C103.159 22.8251 102.962 22.6365 102.957 22.3995C102.953 22.171 103.129 21.9808 103.356 21.9635L103.381 21.9623L104.907 21.9337Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M114.316 19.1438L104.641 19.4729C104.393 19.4813 104.204 19.6947 104.226 19.9401L104.234 20.0222C104.24 20.0725 104.249 20.1362 104.26 20.2123C104.29 20.4199 104.332 20.6503 104.387 20.898C104.545 21.6062 104.777 22.3149 105.102 22.9803C106.076 24.9795 107.662 26.1801 109.952 26.1111C111.879 26.059 113.055 25.6351 113.728 24.7611C114.247 24.0869 114.407 23.3922 114.542 21.7737L114.614 20.8879C114.661 20.3393 114.695 20.0521 114.757 19.6364C114.797 19.371 114.585 19.1346 114.316 19.1438ZM105.151 20.3144L113.833 20.0191L113.816 20.1647C113.784 20.4471 113.756 20.7689 113.716 21.2656L113.682 21.6947C113.561 23.1475 113.425 23.7419 113.042 24.2393C112.557 24.8697 111.62 25.2072 109.927 25.253C108.017 25.3105 106.717 24.3264 105.879 22.6061C105.587 22.0065 105.374 21.3599 105.23 20.7128L105.212 20.6286C105.194 20.5452 105.178 20.4642 105.164 20.386L105.151 20.3144Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M84.2701 21.9619C84.2269 21.9619 84.1837 21.9619 84.1405 21.9619C82.4849 22.0478 81.2036 23.6359 81.2756 25.5102C81.3476 27.3415 82.6721 28.7723 84.2701 28.7723C84.3133 28.7723 84.3565 28.7723 84.3997 28.7723C86.0553 28.6864 87.3366 27.0983 87.2646 25.224C87.1926 23.3927 85.8681 21.9619 84.2701 21.9619Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M91.6844 25.3098C91.598 23.2352 90.4607 21.4468 88.8195 20.4023L88.0997 4.13477L87.5814 4.16338C86.1993 4.22061 85.1195 5.39382 85.1771 6.76734L85.7386 19.4724C85.5802 19.4724 85.4219 19.4724 85.2635 19.4724C81.8659 19.6154 79.2457 22.4769 79.3897 25.8392C79.5337 29.2157 82.413 31.8197 85.7962 31.6766C89.1938 31.5336 91.8284 28.6721 91.6844 25.3098ZM84.443 29.6163C84.3854 29.6163 84.3278 29.6163 84.2702 29.6163C82.1971 29.6163 80.4983 27.8279 80.4119 25.5387C80.3255 23.1923 81.9667 21.2035 84.0974 21.1034C84.155 21.1034 84.2126 21.1034 84.2702 21.1034C86.3433 21.1034 88.0421 22.8918 88.1285 25.181C88.2149 27.5274 86.5593 29.5162 84.443 29.6163Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M87.425 77.1603L88.5125 77.2023L89.9942 77.261C91.5269 77.3224 93.1079 77.3882 94.7375 77.458L96.3831 77.5292C98.2614 77.6112 100.16 77.6966 102.126 77.787L107.475 78.0373L110.694 78.1906C110.932 78.202 111.116 78.4031 111.105 78.6399C111.094 78.8682 110.905 79.0463 110.678 79.0486L110.61 79.046L104.481 78.7556L102.481 78.6627C100.769 78.5838 99.1106 78.5087 97.4746 78.4365L95.1085 78.3332C93.7445 78.2745 92.4141 78.2186 91.1174 78.1656L89.2145 78.0891L87.6373 78.0275C86.3471 80.0201 85.1266 82.1904 84.1257 84.4183L84.023 84.649C82.7014 87.6416 81.9224 90.4318 81.8315 92.8909L81.8299 92.941H82.0982L89.6174 90.9949C89.84 90.9372 90.0673 91.0631 90.1371 91.2778L90.1442 91.302C90.2022 91.5232 90.0756 91.7491 89.8595 91.8185L89.8351 91.8256L82.2625 93.7857C82.2359 93.7926 82.2086 93.797 82.1812 93.7987L82.1537 93.7996H81.3906C81.1521 93.7996 80.9587 93.6074 80.9587 93.3703C80.9587 90.6593 81.7799 87.5922 83.232 84.304C84.3065 81.8709 85.6389 79.5071 87.0463 77.3553C87.124 77.2365 87.2553 77.164 87.3966 77.1602L87.425 77.1603Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M158.715 36.7558C154.525 35.3537 151.185 32.1059 149.688 27.9854C144.779 28.0283 140.805 31.9914 140.805 36.8846C140.805 37.0134 140.805 37.1421 140.82 37.2566L140.805 37.2423V45.7409H148.983C149.242 45.7695 149.501 45.7695 149.76 45.7695C154.713 45.7695 158.715 41.7777 158.715 36.8703C158.715 36.8417 158.715 36.7987 158.715 36.7558ZM146.233 38.5585C145.715 38.5585 145.355 38.1579 145.355 37.6285C145.355 37.0848 145.729 36.6985 146.247 36.6985C146.766 36.6985 147.126 37.0848 147.126 37.6285C147.111 38.1579 146.78 38.5585 146.233 38.5585ZM149.285 38.5585C148.767 38.5585 148.407 38.1579 148.407 37.6285C148.407 37.0848 148.781 36.6985 149.299 36.6985C149.818 36.6985 150.178 37.0848 150.178 37.6285C150.178 38.1579 149.832 38.5585 149.285 38.5585ZM152.351 38.5585C151.833 38.5585 151.473 38.1579 151.473 37.6285C151.473 37.0848 151.848 36.6985 152.366 36.6985C152.884 36.6985 153.244 37.0848 153.244 37.6285C153.244 38.1579 152.898 38.5585 152.351 38.5585Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M177.2 22.8633C177.114 15.3519 170.952 9.2998 163.379 9.2998C155.749 9.2998 149.559 15.452 149.559 23.035C149.559 30.6179 155.749 36.7701 163.379 36.7701H177.229V22.8347L177.2 22.8633ZM163.005 31.0758H162.947C162.314 31.0758 161.81 30.5608 161.81 29.9455C161.81 29.316 162.328 28.8152 162.947 28.8152H163.005C163.638 28.8152 164.142 29.3303 164.142 29.9455C164.157 30.5608 163.638 31.0758 163.005 31.0758ZM165.625 23.6072C164.761 24.0651 164.229 24.4227 164.027 24.6803C163.84 24.9378 163.739 25.3384 163.739 25.8821V26.3256C163.739 26.7835 163.365 27.1555 162.904 27.1555C162.444 27.1555 162.069 26.7835 162.069 26.3256C162.084 25.6103 162.098 25.1381 162.112 24.9092C162.141 24.6087 162.228 24.3226 162.357 24.0221C162.501 23.7217 162.66 23.4928 162.832 23.3354C163.005 23.1637 163.423 22.9062 164.07 22.5485C164.776 22.1622 165.265 21.8474 165.524 21.6328C165.798 21.4182 166.028 21.1034 166.215 20.7171C166.417 20.3308 166.518 19.9016 166.518 19.4438C166.518 18.571 166.201 17.8842 165.553 17.3835C164.92 16.8827 164.157 16.6395 163.293 16.6395C161.565 16.6395 160.428 17.5122 159.895 19.2578C159.751 19.7013 159.305 19.9731 158.859 19.873C158.355 19.7585 158.053 19.2434 158.197 18.7427C158.945 16.2675 160.687 15.0227 163.394 15.0227C164.949 15.0227 166.187 15.4377 167.151 16.2818C168.116 17.1259 168.577 18.199 168.577 19.5153C168.605 21.2036 167.612 22.5771 165.625 23.6072Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M109.565 93.2843L117.814 59.5473C117.958 58.9464 118.39 58.4742 118.951 58.2739H115.77C115.122 58.2739 114.56 58.7175 114.402 59.347L107.708 87.4898H87.8836C87.8836 87.4898 86.7319 93.3844 93.4263 93.3844H109.55C109.55 93.3558 109.565 93.3129 109.565 93.2843Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M165.251 58.2739C165.251 58.2739 120.161 58.3598 120.161 58.3884C120.161 58.6316 119.83 59.0179 119.585 59.0179C119.139 59.0179 118.75 59.3184 118.649 59.7476L110.429 93.3844H157.563C158.211 93.3844 158.773 92.9409 158.931 92.3114L166.619 59.9908C166.82 59.1181 166.158 58.2739 165.251 58.2739ZM139.423 79.8067C137.509 79.8067 135.954 78.2615 135.954 76.3586C135.954 74.4557 137.509 72.9105 139.423 72.9105C141.338 72.9105 142.893 74.4557 142.893 76.3586C142.893 78.2615 141.338 79.8067 139.423 79.8067ZM153.806 80.4648C153.532 80.3933 153.23 80.3504 152.927 80.3504C151.07 80.3504 149.559 81.8527 149.559 83.6983C149.559 85.544 151.07 87.0463 152.927 87.0463C153.23 87.0463 153.518 87.0034 153.806 86.9318V87.8189C153.518 87.8761 153.23 87.9047 152.927 87.9047C150.595 87.9047 148.695 86.0161 148.695 83.6983C148.695 81.3805 150.595 79.4919 152.927 79.4919C153.23 79.4919 153.518 79.5206 153.806 79.5778V80.4648Z"
                fill="#273067"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M163.552 93.7993H158.11C155.72 93.7993 153.791 91.8821 153.791 89.507V79.1484H167.871V89.507C167.871 91.8678 165.942 93.7993 163.552 93.7993Z"
                fill="#FFB039"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M204.971 93.2559C205.289 93.2559 205.547 93.5121 205.547 93.8282C205.547 94.1347 205.305 94.3849 205 94.3998L204.971 94.4005H1.08636C0.768321 94.4005 0.510498 94.1442 0.510498 93.8282C0.510498 93.5217 0.752932 93.2714 1.05762 93.2566L1.08636 93.2559H204.971Z"
                fill="#273067"
              />
            </svg>
          </div>
        </div>
        <!-- Descripción -->
        <div class="details" :class="activeAnySwitch ? 'active' : 'inactive'">
          <h6 class="heading-h6 title">Modo agente</h6>
          <p class="body-2">
            Elige si quieres que tus usuarios administradores cuenten con
            funcionalidades de agentes. Si está activada, tus administradores
            tendrán acceso total al Workspace de Ticker para conversar con tus
            clientes y agendar recordatorios.
          </p>
        </div>
      </div>
    </div>
    <!-- Botones -->
    <SectionModeAdminAgent
      v-if="roles && isAllowedFor('admin', roles)"
      :company="company"
    />
    <SectionModeSupervisorAgent
      v-if="roles && isAllowedFor('supervisor', roles)"
      :company="company"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SectionModeAdminAgent from '@/app/settings/components/organisms/generalMenu/SectionModeAdminAgent.vue'
import SectionModeSupervisorAgent from '@/app/settings/components/organisms/generalMenu/SectionModeSupervisorAgent.vue'
import compareMixin from '@/app/shared/mixins/compare'

export default {
  name: 'SectionModeAgent',
  mixins: [compareMixin],
  components: {
    SectionModeAdminAgent,
    SectionModeSupervisorAgent,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    switchAdmin: false,
    switchSupervisor: false,
  }),
  computed: {
    ...mapGetters(['roles']),
    /**
     * Hay algun switch de modo agente activo
     * @returns {Boolean}
     */
    activeAnySwitch() {
      return (
        this.company.settings.userMode.adminAgent ||
        this.company.settings.userMode.supervisorAgent
      )
    },
  },
  methods: {
    ...mapActions(['updateActiveTemplates']),
  },
}
</script>

<style lang="sass" scoped>
$width-switch: 44px

.section-mode-agent
  border: 1px solid #D9D9D9
  border-radius: 8px
  padding: 12px 20px
  &-header
    display: flex
    flex-direction: row
    justify-content: space-between
    .title
      text-align: left
      color: $gray_dark_800
  .description
    text-align: left
    margin-bottom: 0
    height: 100%
    .ilustration
      display: flex
      align-items: center
      .inactive
        filter: grayscale(1) opacity(0.7)
    .details
      margin: 0 0 0 1rem
      p, h6
        margin: 0
      ul
        padding: 0 1rem
.active
  color: $gray_dark_800
  .title, ul, li
    color: $gray_light_800
.inactive
  color: $gray_dark_700
  .title, ul, li
    color: $gray_dark_700
</style>
