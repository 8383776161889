import Chatbots from '@/app/chatbots/views/Chatbots'
import Chatbot from '@/app/chatbots/views/Chatbot'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/chatbots',
    name: 'chatbots',
    component: Chatbots,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'supervisor'],
      title: `Chatbots | ${VUE_APP_NAME}`,
    },
  },
  {
    path: '/tree/:id',
    name: 'id',
    component: Chatbot,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'supervisor'],
      title: `Editor de Chatbot | ${VUE_APP_NAME}`,
    },
  },
]
