<template>
  <div class="bubble-webchat">
    <div class="bubble-webchat__content" :class="method">
      <div
        class="chat"
        :class="`${typeMessage} ${load ? 'bubble--load' : ''} }`"
      >
        <a-skeleton
          v-if="load"
          active
          :title="false"
          :paragraph="{ rows: 2 }"
          class="bubble-webchat__skeleton"
        />
        <template v-else>
          <span v-if="typeMessage === 'text'" class="bubble-text">
            {{ text }}
          </span>
          <div v-else-if="typeMessage === 'file'" class="box">
            <div class="box__content">
              <div>
                <a-icon type="file-pdf" />
                <span>
                  {{ text }}
                </span>
              </div>
              <div>
                <a-icon type="download" />
              </div>
            </div>
          </div>
        </template>
      </div>
      <p class="sub">{{ time }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BubbleWebchat',
  props: {
    typeMessage: { type: String, required: true, enum: ['text', 'file'] },
    text: { type: String },
    time: { type: String },
    method: { type: String, required: true, enum: ['sent', 'received'] },
    load: { type: Boolean, required: false, default: false },
  },
}
</script>

<style lang="sass" scoped>
.bubble-webchat
  width: 100%
  display: flex
  font-size: 12px
  &__content
    width: 85%
    .chat
      background-color: $blue-magenta
      padding: 6px 12px
      color: #262626
      line-height: 20px;
      &.file
        .box
          width: 100%
          background-color: $white_000
          border: 1px solid $gray_7
          padding: 4px 8px
          color: $gray_7
          fill: $gray_7
          &__content
            display: flex
            justify-content: space-between
    .sub
      color: $gray_7
      font-size: 10px
      line-height: 20px
  .received
    text-align: left
    .chat
      border-radius: 8px 8px 8px 0px
  .sent
    margin-left: auto
    text-align: right
    .chat
      border-radius: 8px 8px 0px 8px
.bubble--load
  background-color: $gray_2 !important
.bubble-webchat__skeleton
  .ant-skeleton-content .ant-skeleton-paragraph > li + li
    margin-top: 8px
.bubble-text
  word-break: break-word
</style>
