import compare from '@/app/shared/utils/compare'
import support from '@/app/shared/utils/support'
import vari from '@/app/shared/utils/variables'
import format from '@/app/shared/utils/format'
import router from '@/router'
import axios from 'axios'

const actions = {
  // Unirse a las Salas de las Colas
  async joinLinesThread(context) {
    try {
      //Obtener array de colas
      let arrLines = context.getters.profile.company.linesId
      arrLines.forEach((lineId) => {
        //Crear canal socket.io por cola
        context.dispatch('joinLineThread', lineId)
      })
    } catch (error) {
      console.error('[lineThread][joinLinesThread]', error)
    }
  },
  /**
   * Unirse a la Sala de una Cola
   * @param {vuex}    context
   * @param {String}  lineId Id de la cola a conectar
   */
  async joinLineThread(context, lineId) {
    try {
      this._vm.$socket.emit('server-create-channel-line', { idArea: lineId }) // Se conecta a una nueva cola
    } catch (error) {
      console.error('[lineThread][joinLineThread]', error)
    }
  },
  /**
   * Salir a la Sala de una Cola
   * @param {vuex}    context
   * @param {String}  lineId Id de la cola a desconectar
   */
  async leaveLineThread(context, lineId) {
    try {
      this._vm.$socket.emit('server-leave-channel-line', { idArea: lineId }) // Se desconecta de una cola
    } catch (error) {
      console.error('[lineThread][leaveLineThread]', error)
    }
  },
  /** Escucha los cambios del mensaje automatico de la cola por defecto */
  async listenWelcomeMessageThread(context) {
    try {
      this._vm.$socket.on('client:line:settings', async (payload) => {
        const idLine = payload.idArea
        if (payload.settings.welcome) {
          const welcome = payload.settings.welcome
          await context.commit('SET_WELCOME_IN_LINE', { idLine, welcome })
        }
      })
    } catch (error) {
      console.error('[lineThread][listenWelcomeMessageThread]', error)
    }
  },
  // Escucha asignación de un agente de una cola mediante la sala socket de un usuario (Agentes y Supervision)
  async listenLineSettedThread(context) {
    try {
      this._vm.$socket.on('client:user:lineId', async (payload) => {
        let action_asigned = {
          typeUser: payload.chief.type,
          nameLine: context.getters.lines.find(
            (line) => line._id === payload.newLineId
          ).name,
        }
        // Asignado
        if (payload.asigned === true) {
          action_asigned.asigned = true
          context.commit('SET_ACTION_ASIGNED', action_asigned)

          context.dispatch('joinLineThread', payload.newLineId)
        }
        // Desasignado
        else {
          action_asigned.asigned = false
          context.commit('SET_ACTION_DESASIGNED', action_asigned)
          await context.dispatch('leaveLineThread', payload.newLineId)
          context.dispatch('cleanTicketsLine', payload.newLineId)
          if (context.getters.ticketSelected) {
            // Si se había seleccionado  un ticket que pertenecía a esa cola, entonces se deselecciona
            if (
              context.getters.ticketSelected.company.lineId ===
              payload.newLineId
            ) {
              context.dispatch('cleanTicketSelected')
              if (
                await compare.isAllowedFor(context, ['admin', 'supervisor'])
              ) {
                // Para el Visor
                context.commit('SET_TICKET_REMOTE_SELECTED', null)
              }
            }
          }
        }

        if (await compare.isAllowedFor(context, ['supervisor'])) {
          const token = context.getters.token
          axios.post(
            `${vari.UHM}/auth/reload?token=${format.fixedEncodeURIComp(token)}`
          ) // Recarga la sesion del masivo
        }

        // Carga los datos del perfil
        if (await compare.isAllowedFor(context, ['agent'])) {
          context.dispatch('saveEventTicket')
          context.dispatch('getProfile')
        } else if (router.currentRoute.meta.is_monitor) {
          if (router.currentRoute.meta.is_monitor) {
            context.dispatch('getProfileRemote', payload.idUser)
          } else if (await compare.isAllowedFor(context, ['supervisor'])) {
            if (router.currentRoute.name === 'queues') {
              let reloadTableLines = context.getters.reloadTableLines
              reloadTableLines++
              context.commit('SET_RELOAD_TABLE_LINES', reloadTableLines) // Para actualizar la tabla de Colas
            } else if (router.currentRoute.name === 'accounts') {
              let reloadTableAccounts = context.getters.reloadTableAccounts
              reloadTableAccounts++
              context.commit('SET_RELOAD_TABLE_ACCOUNTS', reloadTableAccounts) // Para actualizar la tabla de Cuentas
            } else if (router.currentRoute.name === 'supervision') {
              let reloadTableSupervision =
                context.getters.reloadTableSupervision
              reloadTableSupervision++
              context.commit(
                'SET_RELOAD_TABLE_SUPERVISIONS',
                reloadTableSupervision
              ) // Para actualizar la tabla de Supervision
            }
            context.dispatch('getProfile')
            context.dispatch('getAssignedLines')
            context.dispatch('listCompanyLines')
            context.dispatch('listAgents')
          }
        }
      })
    } catch (error) {
      console.error('[lineThread][listenLineSettedThread]', error)
    }
  },
  async listenLineChannel(context) {
    try {
      this._vm.$socket.on('client:user:lineId:channel', async (payload) => {
        console.log('[lineThread][listenLineSettedThread] payload', payload)
        if (await compare.isAllowedFor(context, ['agent'])) {
          const lineName = context.getters.lines.find(
            (line) => line._id === payload.lineId
          ).name
          const lineFound = context.getters.profile.company.linesId.find(
            (lineId) => lineId === payload.lineId
          )
          // Recorre todos los canales que estan siendo desasignados
          payload.channel.forEach((channelDesasigned) => {
            const alias = support.channelCompanyAlias(
              channelDesasigned._id,
              context.getters.simple_company_channels
            )
            let title = `Canal "${channelDesasigned.title}" ${
              alias ? `( ${alias} )` : ''
            } removido`
            let description = `Un administrador ha retirado el canal de "${channelDesasigned.title}" de la cola "${lineName}", usted ya no podrá atender tickets de ese canal en esa cola. Si esta acción es incorrecta consúltelo con sus encargados.`
            let notification = {
              title,
              description,
            }
            if (lineName != 'Cola General' && lineFound) {
              context.commit('SET_ACTION_CHANNEL_REMOVED', notification)
            }
          })
        }
        if (payload.removed) {
          context.dispatch('cleanTicketsLineChannel', {
            lineId: payload.lineId,
            channel: payload.channel,
          })
          if (context.getters.ticketSelected) {
            // Si se había seleccionado  un ticket que pertenecía a esa cola, entonces se deselecciona
            if (
              context.getters.ticketSelected.company.lineId ===
                payload.lineId &&
              context.getters.ticketSelected.channel.type == payload.channel
            ) {
              context.dispatch('cleanTicketSelected')
              if (
                await compare.isAllowedFor(context, ['admin', 'supervisor'])
              ) {
                // Para el Visor
                context.commit('SET_TICKET_REMOTE_SELECTED', null)
              }
            }
          }
        }
        context.dispatch('getAssignedLines')
        context.dispatch('listCompanyLines')
      })
    } catch (error) {
      console.error('[lineThread][listenLineChannel]', error)
    }
  },
  /**
   * Escucha asignación de un agente a una cola mediante la sala socket de la cola (Solo para admin y supervisores)
   * @param {vuex} context
   * @returns {Promise<void>}
   */
  async listenLineAgentAssigned(context) {
    try {
      this._vm.$socket.on('client:line:asign:agent', async () => {
        context.dispatch('listAgents')
        if (router.currentRoute.name === 'queues') {
          let reloadTableLines = context.getters.reloadTableLines
          context.commit('SET_RELOAD_TABLE_LINES', reloadTableLines++)
          // window.location.reload()
        }
      })
    } catch (e) {
      console.error('[lineThread][listenLineAgentAssigned]', e)
    }
  },

  /**
   * Actualizar cola al asignar canales
   * @param context
   * @returns {Promise<void>}
   */
  async listenAssignChannel(context) {
    try {
      this._vm.$socket.on(
        'client:user:lineId:assign:channel',
        async (payload) => {
          let reloadTableLines = context.getters.reloadTableLines
          reloadTableLines++
          context.commit('SET_RELOAD_TABLE_LINES', reloadTableLines) // Para actualizar la tabla de Colas
          context.commit('SET_CHANNELS_IN_LINE', {
            idLine: payload.lineId,
            channels: payload.channels,
          }) // Para actualizar la tabla de Colas
          context.dispatch('listCompanyLines')
        }
      )
    } catch (error) {
      console.error('[lineThread][listenAssignChannel]', error)
    }
  },
}

export default {
  actions,
}
