import router from '@/router'
import instanceJson from '@/app/shared/utils/instance'
import Notifications from '@/app/shared/utils/notification'
// import sort from '@/app/shared/utils/sort'

const actions = {
  /** Escucha y agrega mensajes nuevos */
  async listenNewReceivedMessagesThread(context) {
    try {
      this._vm.$socket.on('client-receive-message', async (payload) => {
        let paramsMessage = null
        let ticketFound = context.getters.tickets.find(
          (ticket) => ticket._id === payload.ticketId
        )
        if (!ticketFound) return context.dispatch('getTicket', payload.ticketId)
        if (
          router.currentRoute.meta.is_workspace ||
          router.currentRoute.meta.is_monitor
        ) {
          paramsMessage = {
            idMessage: payload._id,
            message: payload.message,
            idTicket: payload.ticketId,
            apiMessageId: payload.messageId,
            apiReceivedAt: payload.apiReceivedAt,
            method: 'received',
            locked: payload.locked,
            channel: { type: payload.channel },
          }
          context.commit('ADD_TEXT_MESSAGE_IN_TICKET', paramsMessage)
          context.commit('SUM_CHAT_PENDINGS_IN_TICKET', {
            idTicket: paramsMessage.idTicket,
            method: paramsMessage.method,
            userType: context.getters.profile.type,
          })

          /** Comprobar si el ticket del mensaje entrante coincide con el ticket seleccionada */
          /** Si ya está seleccionada no lo agrega a la lista de salas NO leidas - SOLO PARA AGENTE  */
          // console.log('...................... 1')
          if (router.currentRoute.meta.is_workspace) {
            // console.log('...................... 2 ..agent')
            if (
              context.getters.ticketSelected &&
              context.getters.ticketSelected._id == paramsMessage.idTicket
            ) {
              // console.log('...................... 3 ..agent')
              context.dispatch('clearPendings', {
                ticketId: paramsMessage.idTicket,
                pendings: true,
              })
              return
            }
          } else if (router.currentRoute.meta.is_monitor) {
            // console.log('...................... 2 ..supervisor')
            if (
              context.getters.ticketIdRemoteSelected &&
              context.getters.ticketIdRemoteSelected == paramsMessage.idTicket
            ) {
              // console.log('...................... 3 ..supervisor')
              context.dispatch('clearPendings', {
                ticketId: paramsMessage.idTicket,
                pendings: true,
              })
              return
            }
          }
        }

        context.dispatch('playSound', 'message') // Reproducir sonido

        Notifications.notify(
          ticketFound.client.names,
          payload.message.text ? payload.message.text : '🧷 Archivo',
          {
            onClick: Notifications.notificationTicketOnClick(payload.ticketId),
          }
        )

        /** Comprobar si el ticket actual se encuentra en la lista de tickets NO leidas */
        // console.log('...................... 4')

        const ticketIncomming = context.getters.tickets.find(
          (ticket) => ticket._id === payload.ticketId
        )
        const mailbox = context.getters.company.settings.mailboxes.find(
          (box) => box.idMailbox === ticketIncomming.mailboxId
        )
        context.dispatch('addTicketUnread', {
          idTicket: payload.ticketId,
          mailboxId: mailbox.idMailbox,
        })
      })
    } catch (error) {
      console.error(
        '[ticketThread][listenNewReceivedMessagesThread] error >>',
        error
      )
    }
  },
  /** Escucha y agrega salas nuevas */
  async listenNewTicketsThread(context) {
    try {
      await this._vm.$socket.on('client:ticket:new', async (payload) => {
        console.log('listenNewTicketsThread', payload)
        if (router.currentRoute.meta.is_monitor) {
          if (payload.userId !== context.getters.profileRemote?.userId) return
        }

        let ticket = { ...payload.ticket }
        let reasigned = payload.reasigned
        // Se aborta si la sala ya existe en el arreglo
        if (
          context.getters.tickets.some(
            (ticketFound) => ticketFound._id === ticket._id
          )
        )
          return
        // encontrar el buzon del ticket o el por defecto
        const mailboxTicket = context.getters.mailboxes.find((mailbox) =>
          ticket.mailboxId
            ? ticket.mailboxId === mailbox.idMailbox
            : mailbox.default
        )

        ticket.mailboxId = mailboxTicket.idMailbox
        ticket.mailboxName = mailboxTicket.title
        if (ticket.forceAddTicket === true)
          context.commit('INSERT_TICKET', ticket)
        // Inserta tal cual a la lista
        else if (reasigned) {
          // Recibe el objeto del ticket reasignado para agregarle el abstractact y agregarlo a la lista
          /** Busca entre los mensajes para completar el abstract deL ticket */
          const mailbox = context.getters.mailboxes.find(
            (mailbox) => mailbox.idMailbox === ticket.mailboxId
          )
          ticket.mailboxName = mailbox.title
          // ticket.messages = sort.sortMessages({ messages: ticket.messages })
          ticket.abstract = { ...ticket.lastMessage }
          context.commit('INSERT_TICKET', ticket)
        } // Agrega a la lista como pendiente
        else context.commit('ADD_TICKET', ticket)
        // Cuando es una plantilla se detiene los comandos siguientes
        if (!reasigned && ticket.messages[0].type === 'template') return

        /** Sumar notificaciones de conversaciones */
        if (!reasigned || (reasigned && ticket.pendings)) {
          await context.dispatch('addTicketUnread', {
            idTicket: ticket._id,
            mailboxId: ticket.mailboxId,
            quantity: ticket.pendings,
          })
        }

        // if (ticket.messages[0].method === 'received') return
        context.dispatch('playSound', 'message') // Reproducir sonido
        Notifications.notify(
          ticket.client.names,
          ticket.messages[0].message.text
            ? ticket.messages[0].message.text
            : '🧷 Archivo',
          { onClick: Notifications.notificationTicketOnClick(ticket._id) }
        )

        /** Llama a la función que setea el mensaje automatico en el front, solamente si el ticket nuevo aún no lo tiene */
        if (
          ticket.hasWelcome &&
          !ticket.messages.some((msg) => msg.message.auto === true)
        ) {
          let line = context.getters.lines.find((line) => line.default)
          if (line)
            await context.dispatch('evaluateAutoWelcome', { ticket, line })
        }
      })
    } catch (error) {
      console.error(error)
    }
  },
  /** Escuchar y setear tickets transferidos */
  async listenTransferedTicketThread(context) {
    try {
      const removeTicketFromVisor = (payload) => {
        const idTicket = payload.ticket._id
        // Quita los Ids de los tickets de la lista de tickets no leídos
        if (router.history.current.meta.is_monitor) {
          if (context.getters.ticketsIdUnread.includes(idTicket)) {
            context.commit('QUIT_TICKET_UNREAD', idTicket)
            let ticketsRemote = context.getters.tickets.find(
              (ticket) => ticket._id === idTicket
            )
            context.commit('SUM_NOTIFICATIONS_MAILBOX', {
              idMailbox: ticketsRemote.mailboxId,
              quantity: -1,
            })
          }
        }
        // Quita el ticket de la lista
        context.commit('QUIT_TICKET', idTicket)
        if (context.getters.ticketSelected) {
          if (context.getters.ticketSelected._id === idTicket) {
            context.dispatch('cleanTicketSelected')
          }
        }
      }

      this._vm.$socket.on('client:ticket:transfer', async (payload) => {
        console.log('listenTransferedTicketThread', payload)
        if (router.currentRoute.meta.is_monitor) {
          if (['line', 'agent'].includes(payload.typeTransfer)) {
            if (context.getters.profileRemote.userId !== payload.other_agentId)
              return removeTicketFromVisor(payload)
          }
        }
        let ticket = payload.ticket
        // si el agentes asignado para la transferencia no es el usuario de sesion, entonces lo cierra
        if (
          context.getters.profile.userId == payload.userId &&
          payload.other_agentId !== context.getters.profile.userId
        ) {
          const idTicket = payload.ticket._id
          // Actualiza los datos del buzón
          if (context.getters.ticketsIdUnread.includes(idTicket)) {
            context.commit('SUM_NOTIFICATIONS_MAILBOX', {
              idMailbox: payload.ticket.mailboxId,
              quantity: -1,
            })
          }
          // Quita el ticket del workspace
          if (
            context.getters.ticketSelected &&
            context.getters.ticketSelected._id === idTicket
          ) {
            context.dispatch('cleanTicketSelected')
          }
          context.commit('QUIT_TICKET_UNREAD', idTicket)
          context.commit('QUIT_TICKETID_SELECTED', idTicket)
          context.commit('QUIT_TICKET', idTicket)
          context.commit('QUIT_SCHEDULES_IN_TICKET', idTicket)
          return
        }
        /** Si existe algun ticket en el arreglo Global actual de ticket que coincide con el entrante, se aborta la función */
        if (context.getters.tickets.some((tkt) => tkt._id === ticket._id))
          return
        /** Insertar el ticket en arreglo proncipal */
        ticket.lastMessage_at = new Date(ticket.lastMessage_at)
        ticket.abstract = ticket.lastMessage
        if (!ticket.messages) ticket.messages = []
        ticket.pendings =
          ticket.pendings > 0
            ? ticket.pendings
            : instanceJson.transference_re_unread // Si cuenta con la configuración, dará como "no leido", sino como "leido"
            ? 1
            : 0
        /**Añadiendo un usuario activo a los usuario asignado del ticket */
        const temporalActiveUser = {
          active: true,
          asigned_at: new Date(),
          name: context.getters.profile.names,
          surnames: context.getters.profile,
          userId: context.getters.profile.userId,
        }
        /**Si el usuario tiene un avatar */
        if (context.getters.profile.avatar)
          temporalActiveUser.avatar = context.getters.profile.avatar
        // es necesario hacer esta validacion porque cuando es un ticket del agente aun no tiene usuarios asignados hasta que recargue
        ticket['users'] = ticket.users ? ticket.users : []
        ticket.users.push(temporalActiveUser)
        context.commit('INSERT_TICKET', ticket)
        /** Sumar notificaciones de conversaciones */
        if (ticket.pendings) {
          await context.dispatch('addTicketUnread', {
            idTicket: ticket._id,
            mailboxId: ticket.mailboxId,
            quantity: ticket.pendings,
          })
        }
        // el ticket debe tener la propiedad mensajes, tener elementos en su array
        // si el mensaje es mio ya no debe sonar el sonido de la notificacion
        if (ticket.lastMessage && ticket.lastMessage === 'sent') return
        /** Reproducir sonido (Al final porque a veces los navegadores provocan error) */
        context.dispatch('playSound', 'message')
        Notifications.notify(
          ticket.client.names,
          ticket.lastMessage.message.text,
          { onClick: Notifications.notificationTicketOnClick(ticket._id) }
        )
      })
    } catch (error) {
      console.error('[ticketThread][listenTransferedTicketThread]', error)
    }
  },
  /**
   * Escuchar cliente actualizado en ticket
   * @param context
   * @returns {Promise<void>}
   */
  async listenClientTicket(context) {
    try {
      this._vm.$socket.on('client:ticket:client', async (iodata) => {
        const clientCurrent = context.getters.client
        //Validar que exista
        if (clientCurrent) {
          //Validar si es igual al cliente en sesion
          if (clientCurrent._id === iodata.client._id) {
            //Actualizar
            const clientId = iodata.client._id
            context.dispatch('getClientById', {
              clientId,
              channel: iodata.client.channelOrigin.channel,
            })
          }
        }
        const clientId = iodata.client._id
        const clientNames = `${iodata.client.names || ''} ${
          iodata.client.surnames || ''
        }`.trim()
        context.commit('SET_CLIENT_NAME_IN_TICKETS', { clientId, clientNames })
      })
    } catch (error) {
      console.error('[ticketThread][listenTransferedTicketThread]', error)
    }
  },
  /** Escuchar y setear actualizacion de buzones de un ticket */
  async listenMailboxTicketThread(context) {
    try {
      this._vm.$socket.on(
        'client:ticket:mailbox',
        ({ userIdFrom, ticketId, mailbox }) => {
          // si el id del usuario que ejecuto es diferente al del usuario actual y
          // si el ticket esta como no leido, entonces descontara de su buzon anterior una notificacion
          const profile = context.getters.profile
          const isIncludeInUnread =
            context.getters.ticketsIdUnread.includes(ticketId)
          if (profile.userId !== userIdFrom && isIncludeInUnread) {
            const ticket = context.getters.tickets.find(
              (ticket) => ticket._id === ticketId
            )
            const oldMailbox = ticket.mailboxId

            context.commit('SUM_NOTIFICATIONS_MAILBOX', {
              idMailbox: oldMailbox,
              quantity: -1,
            })
          }
          context.commit('SET_MAILBOX_IN_TICKET', {
            mailbox,
            idTicket: ticketId,
            end: mailbox.end,
          })
          context.commit('SET_NOTIFICATIONS_MAILBOX', {
            idMailbox: mailbox.idMailbox,
            quantity: mailbox.notifications,
          })
        }
      )
    } catch (error) {
      console.error('[ticketThread][listenMailboxTicketThread]', error)
    }
  },

  /** Escuchar y setear mensajes nuevos */
  async listenNewSentMessages(context) {
    try {
      this._vm.$socket.on('client-send-message', async (payload) => {
        // Las notas se insertan aparte por el formato de este
        if (payload.method === 'note') {
          const ticketSelected = context.getters.tickets.find(
            (ticket) => ticket._id === payload.ticketId
          )
          if (payload.chat_options) {
            // NO cambiar el !== undefined por la forma reducida de validad, inputEnabled es un valor Boolean
            if (payload.chat_options['inputEnabled'] !== undefined) {
              context.commit('SET_STATUS_CHAT_INPUT', {
                idTicket: payload.ticketId,
                ticketStatus: 'active',
                inputStatus: payload.chat_options.inputEnabled,
              })
            }

            if (payload.chat_options.placeholderTextInput) {
              // agrego el placeholder
              context.commit('SET_PLACEHOLDER_CHAT_INPUT', {
                idTicket: payload.ticketId,
                ticketStatus: 'active',
                placeholder: payload.chat_options.placeholderTextInput,
              })
            }

            if (payload.chat_options.tooltipTextInput) {
              // agrego el tooltipTextInput
              context.commit('SET_TOOLTIP_TEXT', {
                idTicket: payload.ticketId,
                ticketStatus: 'active',
                tooltipTextInput: payload.chat_options.tooltipTextInput,
              })
            }
          }
          ticketSelected.messages.push(payload)
          return
        }
        let paramsMessage = {
          idMessage: payload.idMessage,
          message: payload.message,
          idTicket: payload.ticketId,
          apiMessageId: payload.apiMessageId,
          apiReceivedAt: payload.apiReceivedAt,
          method: 'sent',
          status: payload.message.status,
          type: payload.type,
          user: payload.user,
        }
        context.commit('ADD_TEXT_MESSAGE_IN_TICKET', paramsMessage)
      })
    } catch (error) {
      console.error('[ticketThread][listenNewSentMessages]', error)
    }
  },

  async listenStatusMessages(context) {
    try {
      this._vm.$socket.on('client-receive-message-status', async (payload) => {
        // Las notas se insertan aparte por el formato de este
        const ticket = context.getters.tickets.find(
          (tk) => tk._id === payload.idTicket
        )
        if (ticket && ticket.messages) {
          const message = ticket.messages.find(
            (msg) => msg.apiMessageId === payload.idMessage
          )
          if (message) {
            context.commit('SET_MESSAGE_STATUS', {
              idTicket: payload.idTicket,
              apiMessageId: payload.idMessage,
              status: payload.status,
            })
          }
        }
      })
    } catch (error) {
      console.error('[ticketThread][listenStatusMessages]', error)
    }
  },
}

export default {
  actions,
}
