import axios from 'axios'
import moment from 'moment'
import vari from '@/app/shared/utils/variables'

const actions = {
  /** Lista las agendas
   * @param {*} context
   * @param {Object} payload
   * @param {Object} payload.month
   * @param {Object} payload.year
   */

  async listSchedules(context, payload) {
    try {
      const response = await axios.get(
        `${vari.UHR}/agent/room/schedules?month=${payload.month}&year=${payload.year}&mode=${payload.mode}`
      )
      let schedules = response.data
      schedules = schedules.map((sh) => {
        if (!sh.completed_at) sh.completed_at = null
        return sh
      })
      await context.commit('SET_SCHEDULES', schedules)
    } catch (error) {
      console.error('[scheduleModule][listSchedules]', error)
    }
  },
  /**
   * Actualiza los datos de una agenda
   * @param {*} context
   * @param {Object} schedule Objeto de la agenda editada
   */
  async updateSchedule(context, schedule) {
    try {
      const beforeSchedule = {
        ...context.state.schedules.find((sch) => sch._id === schedule._id),
      }
      if (!beforeSchedule) return

      await axios.put(`${vari.UHR}/agent/room/schedule/update`, { schedule })
      if (schedule.deleted) context.commit('QUIT_SCHEDULE', schedule)
      else context.commit('UPDATE_SCHEDULE', schedule)
    } catch (error) {
      console.error('[scheduleModule][updateSchedule]', error)
    }
  },
  /**
   * Crea un evento para la agenda
   * @param {*} context
   * @param {Object} args
   * @param {String} args.idTicket Id del ticket donde se crea la agenda
   * @param {Object} args.schedule Objeto del evento a agendar
   * @param {String} args.schedule.title Título del evento a agendar
   * @param {Date} args.schedule.scheduled_for Fecha del evento a agendar
   * @param {String} args.schedule.description Descripcion breve del evento a agendar
   */
  async addSchedule(context, { idTicket, schedule }) {
    try {
      schedule.clientNames = context.getters.ticketSelected.client.names
      const nameCurrentAgent = context.getters.profile.names
      const stringScheduledFor = moment(schedule.scheduled_for).format('LLLL')
      const text = `${nameCurrentAgent} ha creado una agenda para ${stringScheduledFor},`

      const response = await axios.put(`${vari.UHR}/agent/room/schedule/add`, {
        idTicket,
        schedule,
        text,
      })
      schedule._id = response.data.idSchedule
      schedule.ticketId = context.getters.ticketSelected._id
      schedule.completed_at = null
      let hasSchedule = true // Explicitamente  tiene una agenda
      await context.commit('SET_SCHEDULE_IN_TICKET', { idTicket, hasSchedule })
      await context.commit('ADD_SCHEDULE', schedule)

      // Suma a las notificaciones si coincide con el día actual
      // context.dispatch('verifySchedulesNotifications', { schedule, time: 5, unitTime: 'minutes', sumIfLess: 1, sumIfMore: 0 })
      context.dispatch('addNoteMessage', {
        idTicket,
        text,
        scheduleId: schedule._id,
      })

      return response.data
    } catch (error) {
      console.error('[scheduleModule][addSchedule]', error)
    }
  },
}

export default actions
