<template>
  <auth-template>
    <a-row slot="body" type="flex" justify="center" class="full-width">
      <a-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 12 }"
        :lg="{ span: goToPayment ? 16 : 8 }"
        :xl="{ span: goToPayment ? 16 : 8 }"
        :xxl="{ span: goToPayment ? 16 : 7 }"
      >
        <!-- Vista de pago -->
        <pay-plan
          v-if="goToPayment"
          :name-plan="payPlan.pricing.name"
          :price="payPlan.pricing.price"
          :fromSocialLogin="true"
          :id-plan="payPlan.pricing._id"
          :token="tokenToPay"
          :testimonies="testimonies"
          @onSuccess="saveSesion"
        />
        <div v-else v-show="!showVerification" class="section-forms">
          <template v-if="current === 0">
            <h5 class="heading-h5 title">Regístrate{{ titleLicense }} 🤗 🏢</h5>
            <p class="body-2">
              Crea tu cuenta ahora de manera rápida y sencilla
            </p>
            <PersonalForm
              @onSetValues="setPersonalValues"
              class="full-width form"
              :loading="loading"
              @isChanges="isChanges"
              :values="formPersonalValues"
              @googleSignUp="googleSignUp"
              @facebookSignUp="facebookSignUp"
              :disabledOk="disabledOkPersonal"
              @onChangeOkDisabled="handleChangeOkPersonal"
              @onNext="next"
            />
          </template>
          <template v-if="current === 1">
            <a @click="prev" class="full-width link-go-to-back">
              <a-icon type="arrow-left" style="font-size: 18px" />
              Volver
            </a>
            <h5 class="heading-h5">Cuéntanos un poco más</h5>
            <p class="body-2">
              Bríndanos información sobre tu empresa, así te conoceremos mejor y
              te brindaremos una genial experiencia.
            </p>
            <CompanyForm
              @submitForm="submitCompanyForm"
              class="full-width"
              :loading="loading"
              @isChanges="isChanges"
              :values="formCompanyValues"
            />
          </template>
          <div class="login-form-to-login">
            <p class="body-2 mrg-right-8 mrg-bottom-0">
              ¿Ya tienes una cuenta?
              <router-link :to="{ name: 'login' }">Inicia sesión</router-link>
            </p>
          </div>
        </div>
        <!--Vista de envio de verificacion-->
        <a-row
          v-show="showVerification && !goToPayment"
          class="content-verification"
        >
          <a-alert
            v-if="showAlert.visible"
            :message="showAlert.message"
            :type="showAlert.type"
            show-icon
            style="width: 393px; margin: 2% auto -24px"
          />
          <VerificationEmail
            :email="formPersonalValues ? formPersonalValues.email : 'correo'"
            @handleBackToForm="handleBackToPersonalForm"
            :loading="loadingBtnSendMail"
            :success="successVerification"
          />
        </a-row>
      </a-col>
    </a-row>
  </auth-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CompanyForm from '@/app/signup/components/organisms/CompanyForm'
import PersonalForm from '@/app/signup/components/organisms/PersonalForm'
import PayPlan from '@/app/signup/components/organisms/activeAccount/PayPlan'
import VerificationEmail from '@/app/signup/components/organisms/VerificationEmail'
import { GoogleService, FacebookService } from 'social-login-oauth'
import AuthTemplate from '@/app/shared/components/templates/AuthTemplate'
// import IconGoogle from '@/components/atoms/icons/IconGoogle.vue'
export default {
  name: 'SignupTemplate',
  components: {
    PersonalForm,
    CompanyForm,
    VerificationEmail,
    PayPlan,
    AuthTemplate,
    // IconGoogle,
  },
  data: () => ({
    app: process.env.VUE_APP_NAME,
    features: [
      {
        icon: 'message',
        iconColor: '#3800D6',
        title: 'Chatea por los canales favoritos ',
        description:
          'Conversa con tus clientes por sus aplicaciones de mensajería favoritas, como WhatsApp, Facebook.',
      },
      {
        icon: 'robot',
        iconColor: '#FA541C',
        title: 'Chatea por los canales favoritos ',
        description:
          'Conversa con tus clientes por sus aplicaciones de mensajería favoritas, como WhatsApp, Facebook.',
      },
      {
        icon: 'message',
        iconColor: '#25D366',
        title: 'Chatea por los canales favoritos ',
        description:
          'Conversa con tus clientes por sus aplicaciones de mensajería favoritas, como WhatsApp, Facebook.',
      },
    ],
    current: 0,
    steps: [{ title: 'Sobre ti' }, { title: 'Sobre tu empresa' }],
    formPersonalValues: null, // los valores del formulario de usuario
    formCompanyValues: null, // los valores del formulario de empresa
    showVerification: false, // muestra la vista de verificacion
    loading: false, // loading de los botones de formularios
    changes: true, // si hay cambios
    loadingBtnSendMail: false, // loading de boton de envio de mail
    showAlert: {
      visible: false,
      type: 'info',
      message:
        'Se ha enviado el correo de verificación, por favor revisa tu bandeja de entrada o spam.',
    }, // muestrar alerta de verificacion
    successVerification: false, // correcta verifiacion
    isSocialNetworkSignUp: false, // esta logeado por una red social
    oauth: null,
    payPlan: {
      pricing: { name: 'Business', price: '30', _id: 'nan' },
    },
    goToPayment: false,
    tokenToPay: '',
    userId: null,
    disabledOkPersonal: false,
    customBackground: process.env.VUE_APP_NAME_CUSTOM,
  }),
  async created() {
    this.validateLicense()
    await this.getCountries()
  },
  computed: {
    ...mapGetters(['countries']),
    /**
     * Testimonios segun plan
     */
    testimonies() {
      const license = this.$route.query.license
      switch (license) {
        case 'advanced':
          return [
            {
              id: 1,
              avatar: '/img/clients/kontigo-logo.jpg',
              chief: 'Aimee Concha, Jefe de atención al cliente de Kontigo',
              text: `El API de WhatsApp ha mejorado nuestra atención al cliente, ya que podemos comunicarnos con nuestros clientes por su App preferida. Las acciones personalizadas por este canal nos ha permitido mejorar nuestros tiempos de respuesta.`,
            },
            {
              id: 2,
              avatar: '/img/clients/impruneta.png',
              chief: 'Anthony Huaman, Experiencia Omnicanal de Impruneta',
              text: `Con ${process.env.VUE_APP_NAME}, hemos logrado centralizar la totalidad de nuestros mensajes en una sola vía. Esto nos ha dado más dinamización en la gestión y, gracias a su intuitividad, monitoreamos fácilmente nuestra producción en tiempo real.`,
            },
          ]

        case 'business':
          return [
            {
              id: 1,
              avatar: '/img/clients/kontigo-logo.jpg',
              chief: 'Aimee Concha, Jefe de atención al cliente de Kontigo',
              text: `${process.env.VUE_APP_NAME} nos ayuda muchísimo en la gestión de los casos de nuestros clientes, así como la asignación según los tipos. Además, nos ha facilitado la comunicación por WhatsApp y Messenger, mejorando en calidad y tiempos de respuesta.`,
            },
            {
              id: 2,
              avatar: '/img/clients/factora.png',
              chief: 'Anthony Huaman, Experiencia Omnicanal de Factora',
              text: `El API de WhatsApp Business en ${process.env.VUE_APP_NAME} nos ha facilitado la comunicación con nuestros clientes. Tener este canal en una plataforma y no depender de un celular nos ha permitido tener métricas, contabilizar las conversaciones y tiempos de nuestra operación.`,
            },
          ]

        default:
          return []
      }
    },
    /**
     * @returns {String}
     */
    titleLicense() {
      const license = this.$route.query.license
      const defaultTitle = (complement) => {
        document.title = `Regístrate Gratis - ${process.env.VUE_APP_NAME}`
        return complement
      }
      switch (license) {
        case 'advanced': {
          const complement = ' como Advanced'
          document.title = `Regístrate${complement} - ${process.env.VUE_APP_NAME}`
          return complement
        }
        case 'business': {
          const complement = ' como Business'
          document.title = `Regístrate${complement} - ${process.env.VUE_APP_NAME}`
          return complement
        }
        case 'enterprise': {
          const planId = this.$route.query.plan
          if (planId === process.env.VUE_APP_ENTERPRISE_OID) {
            const complement = ' como Enterprise'
            document.title = `Regístrate${complement} - ${process.env.VUE_APP_NAME}`
            return complement
          } else return defaultTitle('')
        }
        default:
          return defaultTitle('')
      }
    },
  },
  methods: {
    ...mapActions([
      'getExistCompany',
      'registerUser',
      'getCountries',
      'initSession',
    ]),

    async setPersonalValues(values) {
      /** Setea los valores del formulario personal */
      this.formPersonalValues = values.form
      this.isSocialNetworkSignUp = values.isSocial
    },
    async submitCompanyForm(values) {
      /** Setea los valores del formulario personal */
      values.email = this.formPersonalValues?.email
      this.formCompanyValues = values

      this.loading = true
      /**Comprueba si el nombre de la empresa ya existe */
      let response
      if (!this.userId) {
        response = await this.getExistCompany({
          user: values.email,
          company: values.name,
        })
        if (response.success && response.result === 'duplicated') {
          this.loading = false
          if (response.fields.company)
            this.$message.error('El nombre de esta empresa ya existe')
          return
        }
      }
      if (this.isSocialNetworkSignUp) {
        /** Si se esta logueando por alguna red social como facebook y google */
        response = await this.registerUser({
          user: { ...this.oauth, plan: this.$route.query.plan },
          company: this.formCompanyValues,
          socialLogin: true,
        })
        if (response.goToPayment) {
          this.payPlan = response
          this.tokenToPay = response.tokenToPay
          this.goToPayment = true
          return
        }
        // inicia sesion en Ticker
        this.initSession(response.token.token)
        this.$message.success('Ingresaste a la plataforma')
        this.formPersonalValues = null
        this.formCompanyValues = null
        this.oauth = null
        this.isSocialNetworkSignUp = false
        return
      }
      this.formPersonalValues.plan = this.$route.query.plan
      this.formPersonalValues._id = this.userId
      const code = this.formPersonalValues.code
        ? this.countries.find(
            (country) => country._id === this.formPersonalValues.code
          ).dialCode
        : ''
      this.formPersonalValues.email =
        this.formPersonalValues.email.toLowerCase()
      response = await this.registerUser({
        user: { ...this.formPersonalValues, code },
        company: this.formCompanyValues,
      })
      this.loading = false
      if (response.success) {
        this.showVerification = true
        this.userId = response.user.idUser
        // this.showAlert = {
        //   visible: true,
        //   type: 'info',
        //   message:
        //     'Se ha enviado el correo de verificación, por favor revisa tu bandeja de entrada o spam.',
        // }
        return
      }
      this.showAlert = {
        visible: true,
        type: 'error',
        message:
          'No hemos podido registrarte 😓, por favor vuelte a intentarlo o comunicate con soporte',
      }
    },
    next() {
      // if (!this.changes) this.current++
      this.current++
    },
    prev() {
      // if (!this.changes) this.current--
      this.current--
    },
    onChangeStep() {
      if (this.current === 0 && this.formPersonalValues) this.next()
      else if (this.current === 1 && this.formPersonalValues) this.prev()
    },
    handleBackToPersonalForm() {
      this.current = 0
      this.showVerification = false
    },
    isChanges(value) {
      this.changes = value
    },
    saveSesion(token) {
      this.$message.loading('Iniciando sesión...')
      this.initSession(token.token)
      this.$message.success('Ingresaste a la plataforma')
      this.formPersonalValues = null
      this.formCompanyValues = null
      this.oauth = null
      this.isSocialNetworkSignUp = false
    },
    async googleSignUp() {
      // this.isSocialNetworkSignUp = true
      const googleLoginOptions = {
        scope: 'profile email',
        prompt: 'select_account',
      } // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

      const gClientid = process.env.VUE_APP_GOOGLE_CLIENT_ID
      const resultOauth = await GoogleService.signIn(
        gClientid,
        googleLoginOptions
      )
      console.log({ resultOauth })
      this.oauth = resultOauth
      /**Verificar si la empresa si el mail existe */
      const response = await this.getExistCompany({ user: resultOauth.email })
      if (response.success && response.result === 'duplicated') {
        // si el campo que se esta duplicando es el mail
        if (response.fields.email) this.$message.error('Este correo ya existe')
      }
      // si la respuesta es found significa que ya existe pero que esta inactivo
      else if (!response.success && response.result === 'found') {
        this.$message.info(
          'Tu cuenta está inactiva, se ha vuelto a reenviar el correo de verificación'
        )
      } else {
        this.isSocialNetworkSignUp = true

        /**Si la respuesta fue exitosa */
        this.next()
      }
      this.formPersonalValues = null
      this.formCompanyValues = null
    },
    async facebookSignUp() {
      // this.isSocialNetworkSignUp = true
      const fbLoginOptions = {
        scope: 'public_profile,email',
        return_scopes: true,
        enable_profile_selector: true,
      } // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

      const facebookID = process.env.VUE_APP_META_MESSENGER_CLIENT_ID
      const resultOauth = await FacebookService.signIn(
        facebookID,
        fbLoginOptions
      )
      console.log({ resultOauth })
      this.oauth = resultOauth
      /**Verificar si la empresa si el mail existe */
      const response = await this.getExistCompany({ user: resultOauth.email })
      if (response.success && response.result === 'duplicated') {
        // si el campo que se esta duplicando es el mail
        if (response.fields.email) this.$message.error('Este correo ya existe')
      }
      // si la respuesta es found significa que ya existe pero que esta inactivo
      else if (!response.success && response.result === 'found') {
        this.$message.info(
          'Tu cuenta está inactiva, se ha vuelto a reenviar el correo de verificación'
        )
      } else {
        this.isSocialNetworkSignUp = true
        /**Si la respuesta fue exitosa */
        this.next()
      }
      this.formPersonalValues = null
      this.formCompanyValues = null
    },
    validateLicense() {
      const planId = this.$route.query.plan
      const license = this.$route.query.license
      if (
        license === 'enterprise' ||
        planId === process.env.VUE_APP_ENTERPRISE_OID
      ) {
        if (process.env.VUE_APP_SIGNUP_ENTERPRISE !== 'true') {
          this.$router.push({ name: 'signup', query: { license: 'free' } })
        }
      }
    },
    /**
     * Cambia el disabled del boton ok del formulario de datos personales
     * @param {Boolean} disabled
     */
    handleChangeOkPersonal(disabled) {
      this.disabledOkPersonal = disabled
    },
  },
}
</script>

<style lang="sass" scoped>
//max-width: 90%; margin: 2% 12% 0
$height_header: 100px

.text-align-left
  text-align: left

.section-forms
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  border: 1px solid $gray_5
  padding: 24px
  border-radius: 8px
  margin-bottom: 40px
  @include sm
    padding: 20px 32px
  @include md
    padding: 20px 60px
  .title
    margin-bottom: 16px
  .button
    width: 100%
    margin-bottom: 8px
  .form
    // margin-top: 12px
  .link-go-to-back
    width: 100%
    text-align: left
    margin-bottom: 16px
  .login-form-to-login
    margin-top: 8px
    color: $gray_dark_900
.content-verification
  margin: 40px auto
  &-footer
    padding: 12px
    color: $gray_light_600
    display: flex
    justify-content: center
    flex-grow: 1
    align-items: flex-end
    width: 100%
    z-index: 2
    p
      margin-bottom: 0px
</style>
<style lang="sass">
.section-forms
  .steps
    .step
      .ant-steps-item-container
        width: max-content
</style>
