<template>
  <a-form-model
    v-if="currentClient"
    id="form-content"
    ref="formClient"
    layout="vertical"
    :model="currentClient"
    :rules="rules"
    class="form-client form-content"
  >
    <a-form-model-item label="Nombres" prop="names">
      <a-input
        v-model="currentClient.names"
        :maxLength="30"
        @input="grantSave($event, 'names')"
        @blur="saveChanges('names', 'el nombre')"
        placeholder="Escribir nombres..."
        :disabled="profileRemote ? true : false"
        :size="inputs_size"
      />
    </a-form-model-item>
    <a-form-model-item label="Apellidos" prop="surnames">
      <a-input
        v-model="currentClient.surnames"
        :maxLength="30"
        @input="grantSave($event, 'surnames')"
        @blur="saveChanges('surnames', 'el apellido')"
        placeholder="Escribir apellidos..."
        :disabled="profileRemote ? true : false"
        :size="inputs_size"
      />
    </a-form-model-item>
    <a-form-model-item label="Número de teléfono" prop="phone">
      <!-- <div class="item-select-document">
                  <a-select
                    @change="grantSave"
                    @blur="saveChanges('code', 'el código de teléfono')"
                    v-model="currentClient.code"
                    style="width: 80px"
                    :disabled="profileRemote ? true : false"
                  >
                    <a-select-option value="code">
                      +51
                    </a-select-option>
                  </a-select> -->
      <a-input
        :disabled="phoneDisabled"
        @input="grantSave($event, 'phone')"
        @blur="saveChanges('phone', 'el telefono')"
        v-model="currentClient.phone"
        placeholder="Escribir numero de teléfono..."
        :size="inputs_size"
      />
      <!-- </div> -->
    </a-form-model-item>
    <a-form-model-item label="Correo electrónico" prop="email">
      <a-input
        @input="grantSave($event, 'email')"
        @blur="saveChanges('email', 'el correo')"
        v-model="currentClient.email"
        placeholder="Escribir correo electrónico..."
        :disabled="profileRemote ? true : false"
        :size="inputs_size"
      />
    </a-form-model-item>
    <p class="text--left mrg-bottom-2">Documento de identidad</p>
    <a-row type="flex" class="flex-no-wrap">
      <a-form-model-item prop="documentType">
        <a-select
          @change="grantSave($event, 'documentType')"
          @blur="saveChanges('documentType', 'el tipo de documento')"
          v-model="currentClient.documentType"
          style="width: 80px"
          :disabled="profileRemote ? true : false"
          placeholder="DNI"
          :size="inputs_size"
        >
          <a-select-option value="dni" title="Documento nacional de identidad">
            DNI
          </a-select-option>
          <a-select-option value="ce" title="Carnet de extranjería">
            C.E
          </a-select-option>
          <a-select-option value="ruc" title="Registro único del contribuyente">
            RUC
          </a-select-option>
          <a-select-option value="passport" title="Pasaporte">
            Pasaporte
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="documentNumber" class="flex-grow">
        <a-input
          v-model="currentClient.documentNumber"
          class="doc"
          :maxLength="12"
          @input="grantSave($event, 'documentNumber')"
          @blur="saveChanges('documentNumber', 'el número de documento')"
          placeholder="Escribir número..."
          :disabled="profileRemote ? true : false"
          :size="inputs_size"
        />
      </a-form-model-item>
    </a-row>
    <!-- <a-form-model-item label="Documento de identidad" prop="documentType">
      <div class="item-select-document">
        <a-select
          @change="grantSave($event, 'documentType')"
          @blur="saveChanges('documentType', 'el tipo de documento')"
          v-model="currentClient.documentType"
          style="width: 80px"
          :disabled="profileRemote ? true : false"
          placeholder="DNI"
        >
          <a-select-option value="dni" title="Documento nacional de identidad">
            DNI
          </a-select-option>
          <a-select-option value="ce" title="Carnet de extranjería">
            C.E
          </a-select-option>
          <a-select-option value="ruc" title="Registro único del contribuyente">
            RUC
          </a-select-option>
          <a-select-option value="passport" title="Pasaporte">
            Pasaporte
          </a-select-option>
        </a-select>
        <a-input
          v-model="currentClient.documentNumber"
          class="doc"
          :maxLength="12"
          @input="grantSave($event, 'documentNumber')"
          @blur="saveChanges('documentNumber', 'el número de documento')"
          placeholder="Escribir número..."
          :disabled="profileRemote ? true : false"
        />
      </div>
    </a-form-model-item> -->
    <a-form-model-item label="País" prop="country">
      <a-select
        show-search
        @change="grantSave($event, 'country')"
        @blur="saveChanges('country', 'el país')"
        v-model="currentClient.country"
        :disabled="profileRemote ? true : false"
        option-filter-prop="children"
        placeholder="Selecciona aquí..."
        :size="inputs_size"
      >
        <a-select-option
          v-for="country in countries"
          :key="country.nameSpanish"
        >
          {{ country.nameSpanish }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="Ciudad" prop="city">
      <a-input
        @input="grantSave($event, 'city')"
        @blur="saveChanges('city', 'la ciudad')"
        v-model="currentClient.city"
        placeholder="Escribir la ciudad..."
        :disabled="profileRemote ? true : false"
        :size="inputs_size"
      />
    </a-form-model-item>
    <a-form-model-item label="Dirección" prop="address">
      <a-input
        v-model="currentClient.address"
        :maxLength="50"
        @input="grantSave($event, 'address')"
        @blur="saveChanges('address', 'la dirección')"
        placeholder="Escribir dirección... "
        :disabled="profileRemote ? true : false"
        :size="inputs_size"
      />
    </a-form-model-item>
    <a-form-model-item label="Fecha de nacimiento" prop="birth">
      <a-date-picker
        @change="saveChangesDate('birth', 'el nacimiento')"
        v-model="currentClient.birth"
        placeholder="2020-05-25"
        class="date"
        :disabled="profileRemote ? true : false"
        :show-today="false"
        :disabledDate="disabledDate"
        :size="inputs_size"
      />
    </a-form-model-item>
    <a-form-model-item label="Sexo" prop="gender">
      <a-select
        @change="grantSave($event, 'gender')"
        @blur="saveChanges('gender', 'el sexo')"
        v-model="currentClient.gender"
        :disabled="profileRemote ? true : false"
        placeholder="Selecciona aquí..."
        :size="inputs_size"
      >
        <a-select-option value="female"> Femenino </a-select-option>
        <a-select-option value="male"> Masculino </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="Observación" prop="remark">
      <a-textarea
        @input="grantSave($event, 'remark')"
        @blur="saveChanges('remark', 'la observación')"
        class="textarea"
        style="max-heigth: 54px"
        v-model="currentClient.remark"
        placeholder="Escribir una observación..."
        auto-size
        :maxLength="maxObservation"
        :disabled="profileRemote ? true : false"
      />
      <span class="counter"
        >{{ currentClient.remark ? currentClient.remark.length : 0 }}/{{
          maxObservation
        }}</span
      >
    </a-form-model-item>

    <!-- ##-> ID Score temporal -->
    <!-- <a-form-model-item label="ID-Lead" prop="idlead" v-if="showIDLeadScore">
      <a-input
        v-model="currentIDLead.value"
        :maxLength="11"
        @input="grantSave($event, 'idLead')"
        @blur="saveChanges('idlead', 'el ID-Lead')"
        placeholder="Escribir ID-Lead..."
        :disabled="profileRemote ? true : false"
      />
    </a-form-model-item> -->
    <!-- FIN ID Score temporal -->
  </a-form-model>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import compareMixin from '@/app/shared/mixins/compare'
import moment from 'moment'

export default {
  name: 'FormClient',
  props: {
    client: {
      type: Object,
      require: false,
      default: null,
    },
  },
  data: () => ({
    moment,
    maxObservation: 80, // Máxima cantidad de caracteres en el campo de "Observación del cliente"
    currentClient: {}, // Datos del cliente actual, heredado del store
    currentIDLead: null, // Id de la integración de Score
    allowSave: false, // permite guardar los cambios
    rules: {
      names: [
        { max: 29, message: '30 caracteres máximo', trigger: 'change' },
        { whitespace: true, message: 'No se admiten espacios en blanco.' },
      ],
      surnames: [
        { max: 29, message: '30 caracteres máximo', trigger: 'change' },
        { whitespace: true, message: 'No se admiten espacios en blanco.' },
      ],
      email: [
        { type: 'email', message: 'No es un email válido', trigger: 'change' },
        { whitespace: true, message: 'No se admiten espacios en blanco.' },
      ],
      city: [
        { max: 49, message: '50 caracteres máximo', trigger: 'change' },
        { whitespace: true, message: 'No se admiten espacios en blanco.' },
      ],
      address: [
        { max: 49, message: '50 caracteres máximo', trigger: 'change' },
        { whitespace: true, message: 'No se admiten espacios en blanco.' },
      ],
      documentType: [
        { required: false, message: 'Es requerido', trigger: 'change' },
      ],
      documentNumber: [{ whitespace: true, message: 'No espacios en blanco.' }],
    },
    evaludatedField: null, // campo evaluado
  }),
  mounted() {
    this.setCurrentClient()
  },
  mixins: [compareMixin],

  computed: {
    ...mapGetters([
      'profileRemote',
      'company',
      'profile',
      'ticketSelected',
      'countries',
      'inputs_size',
    ]),

    showIDLeadScore() {
      // ##-> Campo temporal
      if (this.company) {
        const IDLead = this.company.integrations.find(
          (int) => int.platform === 'Score' && int.active === true
        )
        return !!IDLead && this.currentIDLead ? true : false
      } else return false
    },
    phoneDisabled() {
      // Campo del telefono deshabilitado?
      if (!this.profile || !this.client || this.profileRemote) return true
      // si no existe profile remote y el canal es whatsapp
      const channels = this.client.channels_companies.map(
        (channelCompany) => channelCompany.channel
      )
      if (
        this.client.channels_companies &&
        !!this.client.channels_companies.length &&
        channels.includes('whatsapp')
      )
        return true
      else return false
    },
  },
  watch: {
    client: {
      // Observa la estructura de todo el getter cliente y su contenido
      handler() {
        this.$refs.formClient.resetFields()
        this.setCurrentClient()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['updateClient', 'addNotification']),

    setCurrentClient() {
      if (!this.client) return null
      this.currentClient = { ...this.client }
      /** ##-> ID Score - Temporal */
      this.currentIDLead = !this.client.integrations[0]
        ? { key: 'idlead', value: '', platform: 'Score', integrationId: '' }
        : this.client.integrations[0]
    },
    grantSave(event, attr) {
      const form = this.$refs.formClient
      // si el campo evaluado y el atributo evaluado son diferentes
      if (this.evaludatedField !== attr) {
        form.clearValidate([this.evaludatedField])
        this.evaludatedField = attr
      }

      if (attr === 'documentNumber' && event.target.value) {
        this.rules.documentType[0].required = true
        form.validateField(['documentType'])
      }

      // valida que se cumplas las reglas
      let isNoValid = false
      form.validateField([attr], (error) => {
        isNoValid = !error
      })
      this.allowSave = isNoValid
    },
    /**
     * Guarda los cambios al dar click fuera del input
     * @param {String} attr Nombre del atributo del objeto
     * @param {String} attr_show Nombre del atributo que se mostrará en el modal
     */
    async saveChanges(attr, attr_show) {
      try {
        if (!this.allowSave) return

        let isValid = null
        // verifica si el campo que esta siendo evaludado esta dentro de los errores
        this.$refs.formClient.validate((valid, errValues) => {
          // se valida que sea documentNumber porque esta incluido dentro de documentType
          if (attr === 'documentNumber') {
            if (errValues['documentType']) isValid = valid
          } else if (errValues[attr]) isValid = valid
          else isValid = true
          isValid = valid
        })
        if (!isValid) return

        this.$message.loading({ content: 'Cargando...', key: 'updatable' })
        if (attr === 'phone') {
          this.currentClient.phones = []
          this.currentClient.phones[0] = {
            number: `${this.currentClient.phone}`,
          }
        }
        if (attr === 'idlead')
          this.currentClient.integrations[0] = this.currentIDLead

        const response = await this.updateClient({
          ...this.currentClient,
          channelOriginId: this.client.channelOrigin._id,
          channel: this.client.channelOrigin.channel,
        })
        if (response.success) {
          this.$message.success({
            content: `Se agregó ${attr_show} correctamente`,
            key: 'updatable',
            duration: 2,
          })
          /**Crear una notficacion */
          this.createNotification(
            attr,
            this.currentClient[attr],
            this.client._id
          )
          this.$emit('update', this.currentClient)
        } else {
          this.$message.error({
            content: `Ocurrió un error, no se pudo guardar el ${attr_show}`,
            key: 'updatable',
          })
        }

        this.allowSave = false
      } catch (error) {
        console.error('[saveChanges][FormClient]', error)
        this.$message.error(
          `Ocurrió un error, no se pudo guardar el ${attr_show}`
        )
      }
    },
    saveChangesDate(attr, attr_show) {
      this.allowSave = true
      this.saveChanges(attr, attr_show)
    },
    /**
     * Crear una notificacion
     * @param {String} attr - atributo editado, segun form
     * @param {String} valueOld - valor antiguo del atributo, antes de ser editado
     * @param {String} valueUpdate - valor nuevo del atributo editado
     * @param {String} idClient - id del cliente ediatdo
     */
    createNotification(attr, valueUpdate, idClient) {
      const activity = {
        type: 'update-client',
        client: {
          _id: idClient,
          new: valueUpdate,
          field: attr,
        },
      }
      switch (attr) {
        case 'names':
          activity.client.field = 'Nombre'
          break
        case 'surnames':
          activity.client.field = 'Apellido'
          break
        case 'phone':
          activity.client.field = 'Teléfono'
          break
        case 'email':
          activity.client.field = 'Email'
          break
        case 'documentType':
          activity.client.field = 'Documento de identidad'
          break
        case 'documentNumber':
          activity.client.field = 'Número de documento'
          break
        case 'country':
          activity.client.field = 'País'
          break
        case 'gender':
          {
            activity.client.field = 'Género'
            activity.client.new =
              valueUpdate === 'female' ? 'Femenino' : 'Masculino'
          }
          break
        case 'city':
          activity.client.field = 'Ciudad'
          break
        case 'address':
          activity.client.field = 'Dirección'
          break
        case 'birth':
          activity.client.field = 'Fecha de nacimiento'
          break
        case 'remark':
          activity.client.field = 'Observación'
          break
        case 'idlead':
          activity.client.field = 'Id-Lead'
          break
      }

      this.addNotification(activity)
    },
    disabledDate(current) {
      // Can not select days after today and today
      return current && current > moment().endOf('day')
    },
  },
}
</script>
<style lang="sass" scoped>
.form-client
  .ant-form-item
    margin-bottom: 0px
  .textarea
    height: 54px
    min-height: 54px
    max-height: 54px
    position: relative
  .counter
    position: absolute !important
    bottom: 5px
    right: 12px
    color: $gray_light_300
    font-size: 10px
  .date
    width: 100%
  .item-select-document
    display: flex
    flex-direction: row
  .doc
    margin-left: 4px
</style>
