<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="$emit('onChangeVisible', false)"
    :maskClosable="false"
    width="440px"
    :bodyStyle="bodyStyle"
  >
    <slot name="icon">
      <a-icon
        :type="typeIcon"
        :style="`color: ${colorIcon}; font-size: 64px`"
      />
    </slot>
    <h6 class="heading-h6 text-align-center title">{{ title }}</h6>
    <p v-if="subTitle" class="body-2 mrg-bottom-0">{{ subTitle }}</p>
    <template v-if="isList">
      <ul class="body-2">
        <li v-for="(description, index) in descriptions" :key="index">
          {{ description }}
        </li>
      </ul>
    </template>
    <template v-else v-for="(description, index) in descriptions">
      <p :key="index" class="body-2 text-align-center description">
        {{ description }}
      </p>
    </template>
    <a-row
      v-if="newPrice != '$0' && regularPrice"
      type="flex"
      class="prices-section"
    >
      <span>De {{ regularPrice }} a </span>
      <h5 class="heading-h5 text-align-center price">{{ newPrice }}</h5>
    </a-row>
    <p v-if="!notConfirm" class="body-2 text-align-left label">
      Escribe la palabra <strong>{{ strongWord }}</strong>
    </p>
    <a-input
      v-if="!notConfirm"
      v-model="valueInput"
      placeholder="Escribe aquí..."
    />
    <a-button
      class="button"
      :type="typeButton"
      :disabled="disabledButton"
      :loading="loadingButton"
      @click="handleClick"
      >{{ nameButton }}</a-button
    >
  </a-modal>
</template>

<script>
export default {
  name: 'ModalConfirm',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    notConfirm: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Titulo ant',
    },
    descriptions: {
      type: Array,
      required: false,
      default: () => ['Lorem ipsum'],
    },
    newPrice: {
      type: String,
      required: false,
      default: null,
    },
    regularPrice: {
      type: String,
      required: false,
      default: null,
    },
    nameButton: {
      type: String,
      required: false,
      default: 'Aceptar',
    },
    strongWord: {
      type: String,
      required: false,
      default: 'Escribe aquí',
    },
    typeButton: {
      type: String,
      required: false,
      default: 'primary',
    },
    typeIcon: {
      type: String,
      required: false,
      default: 'question-circle',
    },
    colorIcon: {
      type: String,
      required: false,
      default: '#FAAD14',
    },
    isList: {
      type: Boolean,
      required: false,
      default: false,
    },
    subTitle: {
      type: String,
      required: false,
      default: null,
    },
    loadingButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    gold_6: '#FAAD14',
    valueInput: '',
    bodyStyle: {
      display: 'flex',
      flexDirection: 'column',
      padding: '32px 36px',
    },
  }),
  computed: {
    disabledButton() {
      if (this.notConfirm) return false
      return this.valueInput === this.strongWord ? false : true
    },
  },
  methods: {
    handleClick() {
      this.valueInput = ''
      this.$emit('actionExecuted')
    },
  },
}
</script>

<style lang="sass" scoped>
.text-align-center
  text-align: center
.text-align-left
  text-align: left
.title
  margin: 16px auto 12px
.description
  margin-bottom: 12px
.price
  margin-bottom: 0
  margin-left: 3px
.prices-section
  justify-content: center
  align-items: center
  margin-bottom: 20px
.label
  margin-top: 16px
  margin-bottom: 2px
.button
  margin-top: 32px
</style>
