<template>
  <div class="notifications-template">
    <h5 class="heading-h5">NOTIFICACIONES</h5>
    <ListNotifications
      @onBottomReached="searchNextPage"
      :isLoading="isLoading"
      :morePaginate="morePaginate"
      :notifications="notifications"
      ref="list_notifications"
    />
  </div>
</template>

<script>
import ListNotifications from '@/app/notifications/components/organisms/ListNotifications.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'NotificationsTemplate',
  mixins: [attemptMixin],
  components: {
    ListNotifications,
  },
  data: () => ({
    page: 1,
    isLoading: false,
    morePaginate: true,
  }),
  created() {
    this.intervalAttempt(async () => {
      this.cleanBadgeNotifications()
    })
  },
  beforeDestroy() {
    this.notifications.forEach((notification) => {
      if (notification.new) this.readNewNotification(notification._id)
    })
  },
  computed: {
    ...mapGetters(['profile', 'notifications']),
  },
  methods: {
    ...mapActions([
      'cleanNotifications',
      'getNextPageNotifications',
      'readNewNotification',
    ]),
    ...mapMutations(['SET_PROFILE_NOTIFICATIONS']),

    async cleanBadgeNotifications() {
      const response = await this.cleanNotifications()
      if (response.success) this.SET_PROFILE_NOTIFICATIONS(0)
    },
    async searchNextPage() {
      this.page = this.page + 1
      this.isLoading = true
      const response = await this.getNextPageNotifications(this.page)
      this.isLoading = false

      if (response.result.length === 0) {
        this.morePaginate = false
      }
      this.$refs.list_notifications.setLastChild()
    },
  },
}
</script>

<style lang="sass" scoped>
.notifications-template
  height: 100%
  h5
    text-align: left
    margin: 20px auto
</style>
