<template>
  <div
    class="node-option"
    :class="{
      'node-option--selected': selected,
      'cursor-auto': disabled,
      'cursor-pointer': !disabled,
    }"
    @click="handleSelect"
  >
    <div class="node-option__header">
      <h5 class="heading-h7 node-option__order">
        {{ order }}. Opción {{ order }}
      </h5>
      <i class="info-2 node-option__action">{{ action }}</i>
    </div>
    <div class="node-option__content">
      <p class="body-2 node-option__description mrg-bottom-0 text--left">
        {{ description }}
      </p>
      <div class="node-option__actions">
        <a-dropdown
          :trigger="['click']"
          placement="topRight"
          :disabled="disabled"
        >
          <a href="#" :disabled="disabled">Cambiar de posición</a>

          <a-menu v-if="quantityOrders !== 0" slot="overlay">
            <a-menu-item
              v-for="item in quantityOrders"
              :key="item"
              @click="$emit('onChangeOrder', dataSource, item)"
              :disabled="item === order || !isAllowedChange"
            >
              {{
                item === order
                  ? `Posición ${item} (actual)`
                  : `Posición ${item}`
              }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <template v-if="showDelete">
          <a-divider type="vertical" />
          <a
            :class="{ 'node-option__action--danger': !disabled }"
            @click.stop="$emit('onDelete')"
            :disabled="disabled"
            >Eliminar</a
          >
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OptionNode',
  props: {
    order: { type: [String, Number], default: '1' },
    description: { type: String, default: 'Descripcion' },
    quantityOrders: { type: Number, required: false, default: 0 },
    dataSource: { type: Object, required: false },
    selected: { type: Boolean, required: false, default: false },
    showDelete: { type: Boolean, required: false, default: true },
    action: { type: String, default: 'Finalizar chatbot' },
    isAllowedChange: { type: Boolean, required: false, default: true },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    handleSelect() {
      !this.disabled && this.$emit('onSelect')
    },
  },
}
</script>
<style lang="sass" scoped>
.node-option
  margin-bottom: 8px
  border: 1px solid $gray_dark_100
  border-radius: 4px
  padding: 10px 12px
  &__header
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-bottom: 4px
  &__order
    text-align: left
    margin-bottom: 2px
  &__content
    display: flex
    justify-content: space-between
  &__description
    color: $gray_dark_800
  &--selected
    border: 1px solid $blue_6
    background-color: $blue_1
  &:hover
    border: 1px solid $blue_6
  &__action
    color: $gray_7
    &--danger
      color: $red_6
</style>
