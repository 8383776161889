import compare from '@/app/shared/utils/compare'
import axios from 'axios'
import router from '@/router'
import vari from '@/app/shared/utils/variables'
import { notification } from 'ant-design-vue'

const actions = {
  /**
   * Conecta el hilo de la empresa con el socket
   * @param context
   * @returns {Promise<void>}
   */
  async joinCompanyThread(context) {
    try {
      const idCompany = context.getters.profile.company.companyId
      this._vm.$socket.emit('server-create-channel-company', {
        idCompany,
      })
    } catch (error) {
      console.error('[companyThread][joinCompanyThread]', error)
    }
  },
  /**
   * Escuchar una cola creada
   * @param {vuex}      context
   * @param {Component} self
   * @returns {Promise<void>}
   */
  async listenLineInserted(context, self) {
    try {
      this._vm.$socket.on('client:company:new:line', async () => {
        // console.log('[companyThread][listenLineInserted]', iodata)
        if (self !== undefined) {
          context.dispatch('getLines', { self: self.self })
        } else {
          context.dispatch('getAssignedLines')
        }
        context.dispatch('listCompanyLines')
      })
    } catch (error) {
      console.error('[companyThread][listenLineInserted]', error)
    }
  },
  /**
   * Escuchar los cambios de las preferencias del chat
   * @param {vuex}      context
   * @param {Component} self
   * @returns {Promise<void>}
   */
  async listenChatPreferencesUpdated(context) {
    try {
      this._vm.$socket.on(
        'client:company:updated:chatPreferences',
        async (payload) => {
          // console.log('[companyThread][listenLineInserted]', iodata)
          if (payload.idCompany == context.getters.profile.company.companyId) {
            switch (payload.config) {
              case 'sendWithIntro': {
                context.commit('SET_CHAT_PREFERENCES', payload)
                let title
                let description
                if (payload.value) {
                  title = `Envia mensajes con enter`
                  description = `Ahora puedes enviar mensajes a tus cliente con enter (INTRO). Recuerda que enviar muchos mensajes puede ocasionar spam.`
                } else {
                  title = `Envia mensajes con clicks`
                  description = `Un administrador ha habilitado la opción de enviar mensajes solo con clicks, ya no podrás enviar mensajes con enter (INTRO).`
                }
                let notification = {
                  title,
                  description,
                }
                if (await compare.isAllowedFor(context, ['agent'])) {
                  context.commit(
                    'SET_ACTION_CHAT_PREFERENCES_UPDATED',
                    notification
                  )
                }
                break
              }
              case 'sendMoreTemplates': {
                context.commit('SET_CHAT_PREFERENCES', payload)
                let title
                let description
                if (payload.value) {
                  title = `Envía más de una plantilla`
                  description = `Ahora puedes enviar más plantillas de WhatsaApp, recuerda que puedes enviar un recordatorio tras haber pasado 24hrs, siempre y cuando el ticket sea respondido.`
                } else {
                  title = `Envía solo una plantilla`
                  description = `Puedes enviar plantillas para iniciar un nuevo ticket.`
                }
                let notification = {
                  title,
                  description,
                }
                if (await compare.isAllowedFor(context, ['agent'])) {
                  context.commit(
                    'SET_ACTION_CHAT_PREFERENCES_UPDATED',
                    notification
                  )
                }
                break
              }
            }
          }
        }
      )
    } catch (error) {
      console.error('[companyThread][listenLineInserted]', error)
    }
  },
  /**
   * Escuchar una cuenta creada
   * @param context
   * @param self: Vue component
   * @returns {Promise<void>}
   */
  async listenAccountCreated(context, self) {
    try {
      this._vm.$socket.on('client:company:new:user', async () => {
        // console.log("listenAccountCreated", iodata)
        context.dispatch('getAccounts', { self: self.self })
      })
    } catch (error) {
      console.error('[companyThread][listenAccountCreated]', error)
    }
  },

  /**
   * Escucha cambios en empresa
   * @param context
   * @returns {Promise<void>}
   */
  async listenCompanySettings(context) {
    try {
      this._vm.$socket.on('client:company:settings', async () => {
        await context.dispatch('getCompany')
      })
    } catch (error) {
      console.error('[companyThread][listenCompanySettings]', error)
    }
  },
  /** Escucha cuando un buzon ha sido eliminado y se ejecuta una acción */
  async listenRemovedMailbox(context) {
    try {
      this._vm.$socket.on(
        'client:company:reasigned:mailbox',
        async ({ fromMailbox, toMailbox, action }) => {
          // console.log("[listenRemovedMailbox]{fromMailbox,toMailbox,action}", {fromMailbox,toMailbox,action})
          let tickets = context.getters.tickets.filter(
            (ticket) => ticket.mailboxId === fromMailbox.idMailbox
          )
          for (let ticket of tickets) {
            let mailbox = null
            let end = action === 'finish' ? true : false
            let idTicket = ticket._id

            if (!end) mailbox = toMailbox
            else mailbox = context.getters.mailboxes.find((box) => box.end)

            context.commit('SET_MAILBOX_IN_TICKET', {
              mailbox,
              idTicket,
              end,
            })
            if (action === 'finish')
              context.commit('QUIT_TICKET_UNREAD', idTicket)
          }
          if (await compare.isAllowedFor(context, ['agent'])) {
            context.dispatch('saveEventTicket')
          }
        }
      )
    } catch (error) {
      console.error('[companyThread][listenRemovedMailbox]', error)
    }
  },
  /** Escucha cuando un buzon ha sido eliminado y se ejecuta una acción */
  async listenTicketsEnded(context) {
    try {
      this._vm.$socket.on(
        'client:company:ended:ticket',
        async ({ channel }) => {
          const tickets = context.getters.tickets.filter(
            (ticket) => ticket.channel.type === channel
          )
          const mailbox = context.getters.mailboxes.find((box) => box.end)
          for (let ticket of tickets) {
            const end = true
            const idTicket = ticket._id
            if (ticket.pendings > 0) {
              context.commit('QUIT_TICKET_UNREAD', ticket._id)
              context.commit('SUM_NOTIFICATIONS_MAILBOX', {
                idMailbox: ticket.mailboxId,
                quantity: -1,
              })
            }
            context.commit('SET_MAILBOX_IN_TICKET', {
              mailbox,
              idTicket,
              end,
            })
          }
          let title = `Canal "${channel}" removido`
          let description = `Un administrador ha retirado el canal "${channel}" de la empresa, usted ya no podrá atender tickets de ese canal en ninguna cola. Si esta acción es incorrecta consúltelo con sus encargados.`
          let notification = {
            title,
            description,
          }
          if (await compare.isAllowedFor(context, ['agent'])) {
            context.commit('SET_ACTION_CHANNEL_REMOVED', notification)
          }
          if (context.getters.ticketSelected) {
            // Si se había seleccionado  un ticket que pertenecía a esa cola, entonces se deselecciona
            if (context.getters.ticketSelected.channel.type == channel) {
              context.dispatch('cleanTicketSelected')
              if (
                await compare.isAllowedFor(context, ['admin', 'supervisor'])
              ) {
                // Para el Visor
                context.commit('SET_TICKET_REMOTE_SELECTED', null)
              }
            }
          }
          if (await compare.isAllowedFor(context, ['agent'])) {
            const ticketsPending = context.getters.ticketsIdUnread.length
            const idCompany = context.getters.profile.company.companyId
            const userId = context.getters.profile.userId
            this._vm.$socket.emit('server:area:gross:mode:agent', {
              idCompany,
              userId,
              ticketsPending,
            })
            axios.patch(`${vari.UHR}/agent/tickets/pending/sync`) // Sincroniza la cantidad de tickets no leídos desde el servidor para supervision
          }
          //context.dispatch("getAgentData")
        }
      )
    } catch (error) {
      console.error('[companyThread][listenRemovedMailbox]', error)
    }
  },
  /**
   * Actualizar configuración
   * @param context
   * @returns {Promise<void>}
   */
  async listenUpdateSettings(context) {
    try {
      this._vm.$socket.on(
        'client:company:settings:channel:updated',
        async () => {
          // console.log('payload listenUpdateSettings ->', payload)

          // Solo usaurio tipo admin
          if (await compare.isAllowedFor(context, ['admin'])) {
            context.dispatch('getChannels')
          }
          if (await compare.isAllowedFor(context, ['admin', 'supervisor'])) {
            let reloadTableLines = context.getters.reloadTableLines
            reloadTableLines++
            context.commit('SET_RELOAD_TABLE_LINES', reloadTableLines) // Para actualizar la tabla de Colas
          }
          context.dispatch('getAssignedLines') // trae las Colas asignadas
          context.dispatch('listCompanyLines') // Trae las colas de la empresa actualizadas
          context.dispatch('getCompany')
        }
      )
    } catch (error) {
      console.error('[companyThread][listenUpdateSettings]', error)
    }
  },
  /**
   * Escucha la activación del modo agente
   * @param {vuex} context
   * @returns {<void>}
   */
  async listenAgentMode(context) {
    try {
      this._vm.$socket.on(
        'client:company:mode:agent',
        /**
         * @param {Object} payload
         * @param {String} payload.idCompany
         * @param {Object} payload.userMode
         * @param {Boolean} payload.userMode.adminAgent
         * @param {Boolean} payload.userMode.supervisorAgent
         */
        async (payload) => {
          let incommingUserAgent = null
          let currentUserAgent = null
          // Solo usaurio tipo admin
          if (compare.isAllowedFor(context, ['admin'])) {
            incommingUserAgent = payload.userMode.adminAgent
            currentUserAgent =
              context.getters.company.settings.userMode.adminAgent
          } else if (compare.isAllowedFor(context, ['supervisor'])) {
            incommingUserAgent = payload.userMode.supervisorAgent
            currentUserAgent =
              context.getters.company.settings.userMode.supervisorAgent
          }
          if (incommingUserAgent !== currentUserAgent) {
            let title = null
            let description = null
            if (incommingUserAgent) {
              title = 'Se ha activado el modo agente'
              description =
                'Un administrador ha activado la opción de "Modo agente” usted ahora podrá atender tickets en sus colas asignadas.\nSi esta acción es incorrecta consúltelo con sus encargados.'
            } else {
              title = 'Se ha inactivado el modo agente'
              description =
                'Un administrador ha inactivado la opción de "Modo agente” usted ahora ya no podrá atender tickets en sus colas asignadas.\nSi esta acción es incorrecta consúltelo con sus encargados.'
              context.commit('REMOVE_MENU_AGENT')
            }
            if (
              !context.getters.menuItems.some(
                (item) => item.route === router.currentRoute.name
              )
            ) {
              context.commit('RESET_TICKET_MODULE')
              context.commit('RESET_SCHEDULE_MODULE')
            }
            const key = `open${Date.now()}`
            notification.open({
              message: title,
              description: description,
              duration: 0,
              btn: (h) => {
                return h(
                  'a-button',
                  {
                    props: {
                      type: 'primary',
                      size: 'small',
                    },
                    on: {
                      click: () => {
                        if (
                          !context.getters.menuItems.some(
                            (item) => item.route === router.currentRoute.name
                          )
                        ) {
                          window.location.assign('/redirect')
                        } else {
                          window.location.reload()
                        }
                      },
                    },
                  },
                  'Actualizar'
                )
              },
              onClose: () => {
                if (
                  !context.getters.menuItems.some(
                    (item) => item.route === router.currentRoute.name
                  )
                ) {
                  window.location.assign('/redirect')
                } else {
                  window.location.reload()
                }
              },
              key,
            })
          }
        }
      )
    } catch (error) {
      console.error('[companyThread][listenAgentMode]', error)
    }
  },
  /**
   * Escucha algún cambio del chatbot
   * @param {vuex} context
   * @returns {<void>}
   */
  async listenUpdateChatbot(context) {
    try {
      this._vm.$socket.on(
        'client:update:chatbot:tree',
        /**
         * @param {Object} payload
         * @param {String} payload.idCompany
         * @param {String} payload.action
         */
        async () => {
          context.dispatch('getListChatbots', {
            ...context.getters.filters,
            restart: true,
          })
        }
      )
    } catch (error) {
      console.error('[companyThread][listenAgentMode]', error)
    }
  },
  /**
   * Escucha algún cambio del company Pricing
   * @param {vuex} context
   * @returns {<void>}
   */
  async listenUpdateCompanyPricing(context) {
    try {
      this._vm.$socket.on(
        'client:company:pricing:update',
        /**
         * @param {Object} payload
         * @param {String} payload.idCompany
         * @param {String} payload.plan
         * @param {String} payload.plan.title
         */
        async () => {
          context.dispatch('getCompanyPricing')
        }
      )
    } catch (error) {
      console.error('[companyThread][listenAgentMode]', error)
    }
  },
}

export default {
  actions,
}
