<template>
  <a-drawer
    :title="drawer_filters.title"
    :width="drawer_filters.width"
    :visible="visible"
    class="filter-tickets"
    :mask="false"
    :headerStyle="{
      position: 'absolute',
      'z-index': 99999,
      top: 0,
      left: 0,
      right: 0,
      'border-bottom': '1px solid #e8e8e8',
    }"
    :bodyStyle="{
      'margin-top': '3rem',
    }"
    @close="onClose"
  >
    <a-form :form="form" class="filters" layout="vertical" hide-required-mark>
      <!-- Buzón -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Buzones</label>
        </template>
        <a-select v-model="localFilters.mailbox">
          <a-select-option value="all"> Todos los buzones </a-select-option>
          <a-select-option
            v-for="mailbox in mailboxes"
            :key="mailbox.idMailbox"
            :value="mailbox.idMailbox"
            :title="`Buzón ${mailbox.title}`"
          >
            Buzón {{ mailbox.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Colas -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Colas</label>
        </template>
        <a-select v-model="localFilters.line">
          <a-select-option
            v-for="line in linesToSelect"
            :key="`${line._id}`"
            :value="line._id"
            :title="line.default ? line.name : `Cola ${line.name}`"
          >
            {{ line.default ? line.name : `Cola ${line.name}` }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Canales -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Canales</label>
        </template>
        <a-select v-model="localFilters.channel" @change="handleChangeChannel">
          <a-select-option
            v-for="channel in channelsForFilters"
            :key="channel.channel"
            :title="channel.default ? channel.name : `${channel.name}`"
          >
            {{ channel.default ? channel.name : `${channel.name}` }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Ids de canales -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">ID de canal</label>
        </template>
        <a-select v-model="localFilters.channelCompanyId">
          <a-select-option
            v-for="simpleChannel in simpleChannelsFiltered"
            :key="simpleChannel._id"
            :title="simpleChannel.title"
          >
            <a-row type="flex">
              <social-icon
                v-if="simpleChannel.channel"
                :type="simpleChannel.channel"
                :size="14"
                :disabled="true"
                class="mrg-right-4"
              />
              <span :class="{ 'mrg-right-4': simpleChannel.alias }"
                >{{ simpleChannel.title }}
              </span>
              <span>{{
                simpleChannel.alias ? `(${simpleChannel.alias})` : ''
              }}</span>
            </a-row>
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Estados del ticket -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Estados de tickets</label>
        </template>
        <a-select
          v-model="ticketStatusesSelected"
          mode="multiple"
          placeholder="Seleccione los estados"
          label="name"
        >
          <a-select-option
            v-for="ticketStatus in ticketStatusesToSelect"
            :key="`${ticketStatus._id}`"
            :value="ticketStatus._id"
            :title="ticketStatus.title"
          >
            {{ ticketStatus.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Leído -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Leído</label>
        </template>
        <a-select class="filter-item__select" v-model="localFilters.status">
          <a-select-option
            v-for="status in ticketStatuses"
            :key="`Status${status.key}`"
            :value="status.key"
            :title="status.title"
          >
            {{ status.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Etiquetas -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Etiquetas</label>
        </template>
        <a-select
          class="filter-item__select"
          mode="multiple"
          v-model="localFilters.tags"
          placeholder="Selecciona"
        >
          <a-select-option
            v-for="tag in tagsToSelect"
            :key="`Tags${tag.key}`"
            :value="tag.key"
            :title="tag.title"
          >
            {{ tag.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Agente -->
      <a-form-item v-if="showFieldAgents" class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Agente</label>
        </template>
        <a-select
          class="filter-item__select"
          v-model="localFilters.agent"
          show-search
          option-filter-prop="children"
        >
          <a-select-option value="all"> Todos los agentes </a-select-option>
          <a-select-option
            v-for="agent in simpleAgents"
            :key="agent.userId"
            :title="agent.names"
          >
            {{ agent.names }} {{ agent.surnames }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Favoritos -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Favoritos</label>
        </template>
        <a-select class="filter-item__select" v-model="localFilters.favorite">
          <a-select-option
            v-for="favorite in favoriteOptions"
            :key="`Status${favorite.key}`"
            :value="favorite.key"
            :title="favorite.title"
          >
            {{ favorite.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- ¨Productos -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Productos</label>
        </template>
        <a-select
          class="filter-item__select"
          mode="multiple"
          v-model="localFilters.products"
          placeholder="Selecciona"
        >
          <a-select-option
            v-for="product in company_products"
            :key="product._id"
            :title="product.name"
          >
            {{ product.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Campañas -->
      <a-form-item class="filters__item">
        <template v-slot:label>
          <label class="heading-h7">Campañas</label>
        </template>
        <a-select
          class="filter-item__select"
          mode="multiple"
          v-model="localFilters.strategies"
          placeholder="Selecciona"
          show-search
          option-filter-prop="children"
        >
          <a-select-option
            v-for="strategy in strategiesNoPaged"
            :key="strategy._id"
            :title="strategy.name"
          >
            {{ strategy.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- Campos personalizados -->
      <a-form-item class="filters__item">
        <h6 class="heading-h7">Campos personalizados</h6>
        <template v-if="company_custom_fields_tickets.length === 0">
          <p class="empty">No hay campos personalizados</p>
        </template>
        <template v-if="localFilters.customFields.length > 0">
          <template v-for="customField in localFilters.customFields">
            <custom-field-option
              :key="customField.key"
              :field="customField"
              @onRemove="removeCustomFilter(customField.key)"
              @onChangeValue="updateCustomFilterValue"
              @onChangeField="updateCustomFilter"
              :dataSource="disabledFields(company_custom_fields_tickets)"
            />
          </template>
        </template>
        <template v-if="company_custom_fields_tickets.length > 0">
          <a-button
            @click="addCustomFieldFilter"
            class="add body-1"
            type="link"
          >
            + Agregar campo customizado
          </a-button>
        </template>
      </a-form-item>
    </a-form>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="onClose">
        Cancelar
      </a-button>
      <a-button type="primary" @click="onSetFilters" :loading="okLoading">
        Aplicar
      </a-button>
    </div>
  </a-drawer>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import CustomFieldOption from '@/app/shared/components/molecules/CustomFieldOption'
import supportMixin from '@/app/shared/mixins/support'
import formatMixin from '@/app/shared/mixins/format'
import attemptMixin from '@/app/shared/mixins/attempt'
import compareMixin from '@/app/shared/mixins/compare'
import moment from 'moment'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'

export default {
  components: { CustomFieldOption, SocialIcon },
  mixins: [supportMixin, formatMixin, attemptMixin, compareMixin],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    ticketFilters: {
      type: Object,
      required: true,
    },
    onOk: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      localFilters: {},
      originalFilters: {},
      ticketStatuses: [
        { title: 'Todos', key: 'all' },
        { title: 'Leído', key: 'read' },
        { title: 'Sin leer', key: 'unread' },
      ],
      favoriteOptions: [
        { title: 'Todos', key: 'all' },
        { title: 'Favoritos', key: 'true' },
        { title: 'No favoritos', key: 'false' },
      ],
      ticketStatusesSelected: [],
      ticketStatusesToSelect: [
        {
          _id: 'queued',
          name: 'Cola de espera',
          title: 'Tickets que aun no son transferidos a un agente.',
        },
        {
          _id: 'issued',
          name: 'Entregado',
          title: 'Tickets que han sido entregados a los agentes.',
        },
        {
          _id: 'opened',
          name: 'Respondido',
          title:
            'Tickets que han sido respondidos por un agente por primera vez.',
        },
        {
          _id: 'ended',
          name: 'Finalizado',
          title: 'Tickets que han sido resueltos por los agentes.',
        },
      ],
      defaultValue: [
        moment(`${moment().format('L')} 00:00`, 'D/M/YYYY HH:mm'),
        moment(
          `${moment().format('L')}${moment().format('LT')}`,
          'D/M/YYYY HH:mm'
        ),
      ],
      okLoading: false,
      strategiesNoPaged: [],
      simpleChannelsFiltered: [],
    }
  },
  watch: {
    visible: {
      handler() {
        this.localFilters = JSON.parse(JSON.stringify(this.ticketFilters))
        this.ticketStatusesSelected = this.localFilters.ticketStatuses.map(
          (el) => el._id
        )
        this.handleChangeChannel(this.localFilters.channel, false)
      },
      deep: true,
    },
  },
  created() {
    this.intervalAttempt(async () => {
      if (!this.profile && !this.company) throw 'No company'
      // si tiene el rol de administrador
      if (this.isAllowedFor(this.profile.type, ['admin'])) {
        this.getSimpleAgents()
      }
      this.setStrategiesNoPaged()
    })
    this.localFilters = JSON.parse(JSON.stringify(this.ticketFilters))
    this.originalFilters = JSON.parse(JSON.stringify(this.ticketFilters))
    this.ticketStatusesSelected = this.localFilters.ticketStatuses.map(
      (el) => el._id
    )
  },
  computed: {
    ...mapGetters([
      'company',
      'mailboxes',
      'profile',
      'lines',
      'tags',
      'agents',
      'company_custom_fields_tickets',
      'simpleAgents',
      'company_products',
      'drawer_filters',
      'channels',
    ]),
    /**
     * Las colas a filtrar
     * @return {Object[]}
     */
    linesToSelect() {
      let linesToSelect = this.lines.slice()
      linesToSelect.unshift({
        _id: 'all',
        name: 'Todas las colas',
        default: true,
      })
      return linesToSelect
    },
    /**
     * Los tags a filtrar
     * @return {Object[]}
     */
    tagsToSelect() {
      if (!this.company) return []
      let toSelect = []
      toSelect = this.tags.map((tag) => ({
        title: tag.title,
        key: tag.idTag,
      }))
      return toSelect
    },
    /**
     * Mostrar o no el campo de agentes
     * @returns {Boolean}
     */
    showFieldAgents() {
      if (this.profile && this.isAllowedFor(this.profile.type, ['admin']))
        return true
      else return false
    },
  },
  methods: {
    ...mapMutations(['SET_CLIENTSID_FILTER_TICKET']),
    ...mapActions(['getSimpleAgents', 'getStrategiesNoPaged']),

    /**
     * Deshabilitar días siguientes a hoy
     * @param {Date}
     */
    disabledDate(current) {
      // Can not select days after today and today
      return current && current > moment().endOf('day')
    },

    onClose() {
      this.$emit('close')
    },
    /**
     * Setea y ejecuta los filtros
     */
    async onSetFilters() {
      this.okLoading = true
      this.SET_CLIENTSID_FILTER_TICKET([])
      const localFilters = {
        ...this.localFilters,
        ticketStatuses: this.ticketStatusesSelected,
      }
      await this.onOk(localFilters)
      this.okLoading = false
    },
    addCustomFieldFilter() {
      const idsLocalFields = this.localFilters.customFields.map(
        (localField) => localField.customFieldId
      )
      const defaultField = this.company_custom_fields_tickets.filter(
        (field) => !idsLocalFields.includes(field._id)
      )[0]
      let value = this.setDefaultValueField(defaultField)
      this.localFilters.customFields.push({
        customFieldId: defaultField._id,
        value: value,
        key: Date.now(),
        type: defaultField.type,
      })
    },
    /**
     * Remueve el filtro de campo
     * @param {String} key - key del campo
     */
    removeCustomFilter(key) {
      this.localFilters.customFields = this.localFilters.customFields.filter(
        (customField) => customField.key != key
      )
    },
    /**
     * Actualiza el valor del filtro
     * @param {String} key - key del campo
     * @param {String} value - valor del campo
     */
    updateCustomFilterValue(key, value) {
      const index = this.localFilters.customFields.findIndex(
        (customField) => customField.key == key
      )
      this.localFilters.customFields[index].value = value
    },
    /**
     * Actualiza el campo filtro
     * @param {String} key - id del campo que se cambiara
     * @param {Object} newField
     * @param {String} newField.key
     * @param {String} newField.type - text, dropdown, date
     * @param {String} newField._id - id del campo customizado
     * @param {Object} oldField
     */
    updateCustomFilter(key, newField, oldField) {
      const index = this.localFilters.customFields.findIndex(
        (customField) => customField.key == oldField.key
      )
      this.localFilters.customFields[index].value =
        newField.type == 'text' ? '' : []
      this.localFilters.customFields[index].type = newField.type
      this.localFilters.customFields[index].customFieldId = newField._id
    },
    /**
     * Devuelve el valor por defecto de un campo
     * @param {Object} field
     * @param {String} field.type - text, dropdown, date
     * @param {String} field.multiple - solo si el tipo es dropdown
     * @returns {(String, Object, Array)} - string si el type es text, Object si el type es date o Array si el type es Dropwdown
     */
    setDefaultValueField(field) {
      let value = ''
      switch (field.type) {
        case 'text':
          value = ''
          break
        case 'dropdown':
          value = field.multiple ? [] : ''
          break
        case 'date':
          value = {}
      }
      return value
    },
    /**
     * Deshabilitar campos personalizados
     */
    disabledFields(fields) {
      const arrFields = JSON.parse(JSON.stringify(fields))
      const idsLocalFields = this.localFilters.customFields.map(
        (localField) => localField.customFieldId
      )
      arrFields.forEach((field) => {
        if (idsLocalFields.includes(field._id)) field.disabled = true
        else field.disabled = false
      })
      return arrFields
    },
    /**
     * Setea las estrategias no paginadas
     */
    async setStrategiesNoPaged() {
      const response = await this.getStrategiesNoPaged()
      if (response.success) this.strategiesNoPaged = response.result
    },
    /**
     * Escucha el cambio de canales
     * @param {String} channel
     */
    handleChangeChannel(channel, setDefault = true) {
      const simpleChannels = this.filterSimpleByChannel({
        channel,
        defaultChannel: { _id: 'all', title: 'Todos los Ids' },
      })
      if (setDefault) this.localFilters.channelCompanyId = simpleChannels[0]._id
      this.simpleChannelsFiltered = simpleChannels
    },
  },
}
</script>

<style lang="sass" scoped>
.filters__item
  margin-bottom: 12px
  .add
    padding: 0
    margin-bottom: 10px
</style>
<style lang="sass">
.filter-tickets
  .ant-drawer-wrapper-body
    &::-webkit-scrollbar
      width: 10px
      height: 12px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px $gray_dark_100
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px $gray_dark_100
</style>
