<template>
  <div
    class="card-ticket"
    @click="handleSelected"
    ref="card_ticket"
    :class="{
      'card-ticket-click': selected && !profileRemote,
      'card-ticket-remote-selected':
        ticket &&
        ticketIdRemoteSelected &&
        ticket._id === ticketIdRemoteSelected,
      'card-ticket-click-supervisor':
        ticketSelected &&
        ticket &&
        ticket._id === ticketSelected._id &&
        profileRemote,
      'card--disabled': disabled,
      'card--default': !disabled,
      'card-ticket--mobile': active_breakpoint.is_mobile && !loading,
    }"
  >
    <!--Skeleton de ticket-->
    <template v-if="loading">
      <a-skeleton
        :loading="loading"
        active
        :avatar="{ size: 32 }"
        :paragraph="{ rows: 1 }"
        class="card-ticket-skeleton"
      >
      </a-skeleton>
    </template>
    <div
      v-else-if="!loading && ticket"
      class="contain"
      :class="{ 'card__contain--mobile': active_breakpoint.is_mobile }"
    >
      <div
        v-if="ticket.status === 'active' && !profileRemote"
        class="contain-checkbox"
        @click.stop="handleChangeCheckbox({ target: { checked: !checked } })"
      >
        <a-checkbox
          @click="(e) => e.stopPropagation()"
          @change="handleChangeCheckbox"
          :checked="checked"
          :data-test="DATA_TEST.TICKET.LIST.CHECK_CARD_ITEM"
          :disabled="disabled"
        ></a-checkbox>
      </div>
      <!--icono del canal-->
      <div v-if="!active_breakpoint.is_mobile" class="card__icon-channel">
        <social-icon
          :type="ticket.channel.type"
          size="32"
          :disabled="disabled"
        />
      </div>
      <!--nombres, favorito, ultimo mensaje, tags-->
      <div
        class="container-1"
        :class="{ 'card__container-one--mobile': active_breakpoint.is_mobile }"
      >
        <a-tooltip>
          <span v-if="ticketOrigin.title" slot="title">{{
            ticketOrigin.title
          }}</span>
          <!--Identificador del canal-->
          <a-row
            type="flex"
            align="middle"
            class="flex-no-wrap card__id-channel"
          >
            <social-icon
              v-if="active_breakpoint.is_mobile"
              :type="ticket.channel.type"
              size="16"
              :disabled="disabled"
              class="mrg-right-4"
            />
            <span class="regular-12 text--left ellipsis gray_7">
              {{ ticketOrigin.origin }}
            </span>
          </a-row>
        </a-tooltip>
        <div class="sub-container">
          <!--Nombre o numero del cliente-->
          <h6
            class="heading-h6 names ellipsis"
            :data-test="DATA_TEST.TICKET.LIST.CARD_ITEM"
            :title="ticket.client.names"
          >
            {{ nameClient }}
          </h6>
          <!--icono de favorito-->
          <a-icon
            v-if="ticket.settings.starred"
            class="star"
            type="star"
            theme="filled"
            style="color: #f8e479; font-size: 17px"
          />
        </div>
        <!--Mensaje-->
        <p
          class="body-2 last-message ellipsis"
          :style="isItalic ? 'font-style:italic' : ''"
        >
          <span v-if="person" style="margin-right: 4px">{{ person }}</span>
          <span
            v-html="
              !enabledTextFormats.active
                ? lastMessage
                : textFormatter(lastMessage, nameFormats, ['formats'])
            "
          ></span>
        </p>
        <!--Etiquetas-->
        <div v-if="showBoxTags" class="container-tags">
          <a-tooltip
            placement="bottom"
            v-for="tag in sortTags({ tags: ticket.settings.tags })"
            :key="`tag${tag.tagId}`"
          >
            <template slot="title">
              <span>{{ tag.title }}</span>
            </template>
            <div class="tag" :style="`background-color: ${tag.color}`"></div>
          </a-tooltip>
        </div>
      </div>
      <!--fecha y tickets pendientes-->
      <div
        class="container-2"
        :class="{
          'card__container-second--mobile': active_breakpoint.is_mobile,
        }"
      >
        <div class="display-flex direction-column align--end">
          <a-icon
            v-if="iconTags.visible"
            :type="iconTags.icon"
            class="card__icon-tag"
          />
          <p
            class="body-2 date mrg-bottom-4"
            :class="{
              'date-mrg-top-22': !active_breakpoint.is_mobile,
              'date-mrg-top-28':
                active_breakpoint.is_mobile && !iconTags.visible,
              'date-mrg-top-10':
                active_breakpoint.is_mobile && iconTags.visible,
            }"
          >
            {{ date }}
          </p>
        </div>
        <a-badge :count="ticket.pendings" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import { DATA_TEST } from '@/app/shared/utils/dataTest'
import textFormatter from '@/app/shared/mixins/format'
import supportMixin from '@/app/shared/mixins/support'
import sortMixin from '@/app/shared/mixins/sort'
moment.locale('es')

export default {
  name: 'CardTicket',
  props: {
    ticket: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      default: true,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    mailboxSelected: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    order: {
      type: Number,
      required: false,
    },
  },
  components: {
    SocialIcon,
  },
  data: () => ({
    DATA_TEST,
    checked: false,
    moment: moment,
  }),
  mixins: [textFormatter, supportMixin, sortMixin],
  computed: {
    ...mapGetters([
      'ticketSelected',
      'profileRemote',
      'ticketIdRemoteSelected',
      'active_breakpoint',
      'main_layout',
    ]),
    /**
     * @returns {Boolean}
     */
    selected() {
      return this.ticket && this.$route.params.ticketId == this.ticket.ticket
        ? true
        : false
    },
    /** Fecha del ultimo mensaje*/
    date() {
      let lastMessage_at = null
      const lastMessage = { ...this.ticket.lastMessage }
      switch (lastMessage.method) {
        case 'note':
          lastMessage_at = lastMessage.created_at
          break
        case 'auto':
          lastMessage_at = lastMessage.created_at
          break
        default:
          lastMessage_at = this.ticket.lastMessage_at
      }
      const iscurrentDate = moment(new Date()).isSame(lastMessage_at, 'days')
      if (iscurrentDate) {
        return moment(lastMessage_at).format('LT')
      } else {
        return moment(lastMessage_at).format('L')
      }
    },
    /** Devuelve "tu" si el ultimo mensaje fue enviado por el agente
     * @return {String|null} - pronombre segun el metodo de envio
     */
    person() {
      return this.ticket.abstract.method === 'sent' ? 'Tú:' : null
    },
    /**
     * Ultimo mensaje del ticket
     * @return {String} - ultimo mensaje de acuerdo al metodo de envio y el multimedia
     */
    lastMessage() {
      const message = this.ticket.abstract.message
      const method = this.ticket.abstract.method
      let lastMessage = ''
      if (!message) return 'Mensaje no compatible'
      else if (Object.keys(message).length === 0)
        lastMessage = 'Mensaje no compatible'
      else if (message.imageUrl)
        lastMessage =
          method === 'sent' ? '📸 Enviaste una imagen' : '📸 Envió una imagen'
      else if (message.audioUrl)
        lastMessage =
          method === 'sent' ? '🔉 Enviaste un audio' : '🔉 Envió un audio'
      else if (message.videoUrl)
        lastMessage =
          method === 'sent' ? '🎬 Enviaste un video' : '🎬 Envió un video'
      else if (message.location)
        lastMessage =
          method === 'sent'
            ? '🎬 Enviaste una ubicación'
            : '🎬 Envió una ubicación'
      else if (message.stickerUrl) {
        lastMessage =
          method === 'sent' ? '📸 Enviaste un sticker' : '📸 Envió un sticker'
      } else if (
        message.pdfUrl ||
        message.pptUrl ||
        message.excelUrl ||
        message.wordUrl
      )
        lastMessage =
          method === 'sent' ? '📄 Enviaste un archivo' : '📄 Envió un archivo'
      else if (message.contacts) {
        if (message.contacts.length === 1)
          lastMessage =
            method === 'sent'
              ? '👤 Enviaste un contacto'
              : '👤 Envió un contacto'
        else
          lastMessage =
            method === 'sent' ? '👥 Enviaste contactos' : '👥 Envió contactos'
      } else lastMessage = message.text
      return lastMessage
    },
    /**
     * Valida si el ultimo mensaje deberia ser italico o no
     * @return {Boolean}
     */
    isItalic() {
      if (!this.ticket.abstract || !this.ticket.abstract.message) return false
      return (
        this.ticket.abstract.message.auto ||
        this.ticket.abstract.method === 'note' ||
        Object.keys(this.ticket.abstract.message).length === 0
      )
    },
    /**
     * Nombre del cliente
     */
    nameClient() {
      if (!this.ticket.client.names) {
        switch (this.ticket.channel.type) {
          case 'webchat':
            return `${this.ticket.code} Webchat`
          case 'whatsapp':
            return this.ticket.channel.phoneFrom
          default:
            return ''
        }
      } else return this.ticket.client.names
    },
    enabledTextFormats() {
      const channel = this.channelCompanyOfTicket(this.ticket)
      if (!channel || !channel.api) return false
      return channel.api.chat_options?.textFormats
    },
    /**
     * Nombre de todos los formatos
     * @return {String[]}
     */
    nameFormats() {
      if (!this.enabledTextFormats.active) return false
      const nameFormats = this.enabledTextFormats.formats.map(
        (item) => item.format
      )
      return nameFormats
    },
    /**
     * Origen del ticket
     * @return {Object | null}
     * @return {String} origin
     * @return {String} title - titulo para el tooltip
     */
    ticketOrigin() {
      if (!this.ticket || !this.ticket.channel) return null
      else if (this.ticket.settings && this.ticket.settings.sandbox) {
        return {
          origin: 'Ticket de Sandbox',
        }
      } else {
        const channelOrigin = { channel: this.ticket.channel.type }
        const alias = this.channelCompanyAlias(this.ticket.channelCompanyId)
        const channelCompany =
          this.ticket.channel.title ||
          this.ticket.channel.phoneTo ||
          this.ticket.channel.apiBusinessId

        // Si el canal no tiene alias
        if (!alias) {
          channelOrigin.origin = !this.active_breakpoint.is_mobile
            ? `ID de canal: ${channelCompany}`
            : `${channelCompany}`
        } else {
          channelOrigin.origin = !this.active_breakpoint.is_mobile
            ? `ID de canal: ${alias}`
            : `${alias}`
        }

        const title = alias ? ` ${channelCompany} ( ${alias} )` : channelCompany
        channelOrigin.title = `Identificador de canal: ${title}`

        return channelOrigin
      }
    },
    /**
     * Mostrar los tags en su forma normal
     */
    showBoxTags() {
      return (
        !this.active_breakpoint.is_mobile &&
        this.ticket.settings &&
        this.ticket.settings.tags.length > 0
      )
    },
    /**
     * Mostra icono de tags
     */
    iconTags() {
      const iconTags = {
        visible: false,
        icon: 'tag',
      }

      iconTags.visible =
        this.active_breakpoint.is_mobile &&
        this.ticket.settings &&
        this.ticket.settings.tags.length > 0

      iconTags.icon =
        this.ticket.settings && this.ticket.settings.tags.length > 1
          ? 'tags'
          : 'tag'

      return iconTags
    },
    routeTicket() {
      const route = { name: null, params: {}, query: {} }
      // si el layout es multivista
      route.name = this.$route.meta.is_workspace
        ? 'drawer-ticket'
        : 'drawer-remote-ticket'
      route.params = {
        drawerId: 'client',
      }
      route.query = {
        main: 'chat',
      }
      // si el layout es solo una vista
      if (this.main_layout.type === 'one-view') {
        route.query = {
          main: 'chat',
        }
      }

      return route
    },
  },
  methods: {
    ...mapMutations(['SET_MODAL_UPGRADE']),

    /**
     * Emite el evento check al al clickear sobre el checkbox
     * @param {event} e
     */
    handleChangeCheckbox(e) {
      this.checked = e.target.checked
      this.$emit('check', e, this.ticket)
    },
    /**
     * Recibe un valor y lo setea en el checkbox
     * @param {Boolean} checked Valor que se le asignará al checkbox del card
     */
    checkTicket(checked) {
      this.checked = checked
    },
    /**
     * Escucha la selección de un ticket
     */
    handleSelected() {
      this.$emit('click')
      // si esta cargando
      if (this.loading) return
      // si el ticket esta bloqueado
      if (this.ticket.locked) return this.SET_MODAL_UPGRADE({ visible: true })
      this.$router.push({
        name: this.routeTicket.name,
        params: {
          ticketId: this.ticket.ticket,
          ...this.routeTicket.params,
        },
        query: {
          mailbox: this.mailboxSelected.key,
          status: this.mailboxSelected.end ? 'ended' : 'active',
          order: this.order,
          ...this.routeTicket.query,
        },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.gray_7
  color: $gray_7
.date-mrg-top-22
  margin-top: 22px
.date-mrg-top-28
  margin-top: 28px
.date-mrg-top-10
  margin-top: 10px
.padding-mobile
  padding: 16px 12px 16px 0px
.card-ticket
  margin-top: 8px
  background-color: $white_000
  border: 1px solid #EBEBEB
  border-radius: 4px
  display: flex
  flex-direction: column
  padding: 12px
  &--mobile
    padding: 16px 12px 16px 0px
    &:hover
      background-color: $white_000!important
  &:first-child
    margin-top: 12px
  .contain
    display: flex
    flex-direction: row
    &-checkbox
      padding-right: 12px
      +flex(row, flex-start, center)
    .container-1
      margin-right: 12px
      margin-left: 12px
      flex-grow: 1
      .sub-container
        display: flex
        flex-direction: row
        align-items: center
        margin-bottom: 4px
        .names
          text-align: left
          margin-bottom: 0
        .star
          margin-left: 8px
      .container-tags
        display: flex
        flex-direction: row
        flex-wrap: wrap
        margin-right: -4px
        .tag
          max-width: 24px
          min-width: 24px
          height: 8px
          border-radius: 8px
          margin-right: 4px
          margin-top: 4px
          flex-grow: 1
      .last-message
        text-align: left
        margin-bottom: 0
    .container-2
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: flex-end
      .date
        text-align: right
        margin-bottom
  &:hover
    background-color: #E6F7FF
  &-click
    background-color: #E6F7FF
    .contain
      .container-1
        .sub-container
          .names
            color: $blue_6
  &-remote-selected
    background: #E6F7FF
    .contain
      .container-1
        .sub-container
          .names
            color: $blue_6
  &-click-supervisor
    .contain
      .container-1
        .sub-container
          .names
            color: $blue_6
.card--default
  .names
    color: $gray_dark_900
  .message
    color: $gray_dark_800
  .date
    color: $gray_dark_800
  .last-message
    color: $gray_dark_800
.card--disabled
  color: $gray_dark_500
  .names
    color: $gray_dark_500

.card__id-channel
  margin-bottom: 2px
.card__icon-tag
  font-size: 18px
  color: $gray_8
.card__icon-channel
  +flex(row, center, center)

/**RESPONSIVE */
.card__contain--mobile
  .contain-checkbox
    width: 40px
    margin-top: -16px
    margin-bottom: -16px
    padding-left: 12px
    margin-right: -4px
.card__container-one--mobile
  .card__id-channel
    margin-bottom: 8px
</style>
<style lang="sass">
.card-ticket
  &-skeleton
    // background-color: pink
    .ant-skeleton-content
      .ant-skeleton-title
        margin-top: 0px!important
    .ant-skeleton-content
      .ant-skeleton-paragraph
        margin-top: 16px!important
        margin-bottom: 0px!important
</style>
