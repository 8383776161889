<template>
  <div class="table-contact">
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :locale="localeTable"
      rowKey="_id"
      class="table-contact__table table-scroll"
      @change="handlePagination"
      size="middle"
      :loading="loading"
      :scroll="{ y: 'calc(100vh - 206px)' }"
    >
      <span slot="names" slot-scope="names">
        <a-avatar
          v-if="names.avatar"
          :src="names.avatar"
          :size="24"
          style="margin: 0 8px 0 0"
          alt="Avatar de perfil"
        />
        <a-avatar
          v-else
          :size="24"
          style="margin: 0 8px 0 0"
          icon="user"
          alt="Avatar de perfil"
        />
        <span :class="{ italic: names.empty }">{{ names.fullNames }}</span>
      </span>
      <!--Columa de teléfono del cliente-->
      <span slot="phone" slot-scope="phone">
        <span :class="{ italic: !phone }">
          {{ !phone ? 'Sin teléfono' : phone }}</span
        >
      </span>
      <!--Columa de teléfono del cliente-->
      <span slot="email" slot-scope="email">
        <span :class="{ italic: !email }">
          {{ !email ? 'Sin correo' : email }}</span
        >
      </span>
      <!--Columa de etiquetas-->
      <span slot="tags" slot-scope="tags">
        <a-tag
          v-for="tag in tags"
          :key="tag"
          :color="
            tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'
          "
        >
          {{ tag.toUpperCase() }}
        </a-tag>
      </span>
      <!--Columa de canales-->
      <span
        slot="channels_companies"
        slot-scope="channels_companies"
        class="channels"
      >
        <span
          v-for="channel in channels"
          :key="channel.channel"
          :class="{
            'mrg-right-12':
              filterByChannel(channels_companies, channel.channel).length > 0,
          }"
        >
          <popover-channel
            v-if="!!filterByChannel(channels_companies, channel.channel).length"
            :channel="channel.channel"
            :list-titles="filterByChannel(channels_companies, channel.channel)"
            @handleModal="handleEditOnChannels(record)"
          />
        </span>
      </span>
      <span slot="productsAssigned" slot-scope="productsAssigned, record">
        <a v-if="!productsAssigned" :aria-disabled="true" :disabled="true"
          >0 productos</a
        >
        <a v-else @click="handleOpenDrawerProducts(record)">
          {{ productsAssigned }}
          {{ productsAssigned === 1 ? 'producto' : 'productos' }}</a
        >
      </span>
      <span slot="action" slot-scope="record">
        <!-- <span slot="action" slot-scope="text, record"> -->
        <a @click="handleAction('details', record)">Ver detalles y editar</a>
        <!-- <a-divider type="vertical" />
        <a>Delete</a>
        <a-divider type="vertical" />
        <a class="ant-dropdown-link"> More actions <a-icon type="down" /> </a> 
        -->
      </span>
    </a-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PopoverChannel from '@/app/shared/components/organisms/PopoverChannel'
import supportMixin from '@/app/shared/mixins/support'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'TableContactModule',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    PopoverChannel,
  },
  data: () => ({
    // Columnas de la tabla
    columns: [
      {
        title: 'N°',
        key: 'order',
        dataIndex: 'order',
        width: 60,
        fixed: 'left',
      },
      {
        title: 'Nombres y Apellidos',
        dataIndex: 'names',
        key: 'names',
        scopedSlots: { customRender: 'names' },
        ellipsis: true,
      },
      {
        title: 'Teléfono',
        dataIndex: 'phone',
        key: 'phone',
        scopedSlots: { customRender: 'phone' },
        ellipsis: true,
      },
      {
        title: 'Correo',
        dataIndex: 'email',
        key: 'email',
        scopedSlots: { customRender: 'email' },
        ellipsis: true,
      },
      {
        title: 'Canales',
        key: 'channels_companies',
        dataIndex: 'channels_companies',
        scopedSlots: { customRender: 'channels_companies' },
      },
      {
        title: 'Agentes asignados',
        dataIndex: 'assigned',
        key: 'assigned',
      },
      {
        title: 'Productos asignados',
        dataIndex: 'productsAssigned',
        key: 'productsAssigned',
        scopedSlots: { customRender: 'productsAssigned' },
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'createdAt',
        key: 'createdAt',
        ellipsis: true,
      },
      {
        title: 'Acción',
        key: 'action',
        scopedSlots: { customRender: 'action' },
      },
    ],
    localeTable: {
      emptyText: 'No se han encontrado contactos',
    },
  }),
  mixins: [supportMixin],
  computed: {
    ...mapGetters([
      'contacts',
      'contacts_page',
      'contacts_pages_total',
      'modules_pagination',
      'company',
      'channels',
    ]),
    /**
     * @returns {Object}
     */
    pagination() {
      let pg = {
        hideOnSinglePage: true,
        defaultPageSize: this.modules_pagination.manage_contacts,
        size: 'default',
        total: this.contacts_pages_total || 0,
      }
      return pg
    },
    /**
     * Datos para mostrar en la tabla
     * @returns {Object[]}
     */
    data() {
      if (!this.contacts) return []
      let startOrder =
        1 + (this.contacts_page - 1) * this.pagination.defaultPageSize
      return this.contacts.map((lead) => {
        const dataTable = {
          key: lead._id,
          _id: lead._id,
          order: startOrder++,
          names: { avatar: lead.avatar, fullNames: '', empty: false },
          phone: '', // Esto se rellena líneas más abajo
          email: lead.email || '',
          assigned: `${lead.attended ? lead.attended : '0'} agentes`,
          createdAt: moment(lead.created_at).format('MMMM DD YYYY, h:mm:ss a'),
          channels: lead.channels,
          productsAssigned: lead.productsAssigned,
          channels_companies: lead.channels_companies,
        }
        if (lead.names || lead.surnames) {
          if (lead.names) dataTable.names.fullNames = `${lead.names} `
          if (lead.surnames) dataTable.names.fullNames += `${lead.surnames}`
        } else {
          dataTable.names.fullNames = `Sin nombre`
          dataTable.names.empty = true
        }

        if (lead.phones) {
          if (lead.phones.length > 0) {
            dataTable.phone = lead.phones[lead.phones.length - 1].code
              ? `(${lead.phones[lead.phones.length - 1].code}) `
              : ''
            dataTable.phone += lead.phones[lead.phones.length - 1].number
              ? `${lead.phones[lead.phones.length - 1].number}`
              : ''
          }
        }
        return dataTable
      })
    },
  },
  methods: {
    ...mapMutations(['SET_CONTACTS_PAGE']),
    ...mapActions(['fetchContacs']),
    handlePagination(pagination) {
      this.SET_CONTACTS_PAGE(pagination.current)
    },
    /**
     * Click en algún botón de acciones
     * @param {String} type [details]
     * @param {Object} record
     */
    handleAction(type, record) {
      const client = this.contacts.find((lead) => lead._id === record._id)
      if (client.phones && client.phones.length != 0) {
        client.phone = client.phones[client.phones.length - 1].number
      } else client.phone = null

      this.$emit('handleAction', type, client)
    },
    /**
     * Emite el evento para mostrar productos
     * @param {Object} record - objeto del cliente
     */
    handleOpenDrawerProducts(record) {
      this.$emit('onShowProducts', record._id)
    },
    /**
     * Filtrar por canal
     */
    filterByChannel(channelsCompanyAssigned, channel) {
      if (!channelsCompanyAssigned || !channel) return []
      const channelsFiltered = this.filterSimpleByChannel({
        channel,
        dataSource: channelsCompanyAssigned,
      })
      return channelsFiltered.map((channelFiltered) => channelFiltered.title)
    },
  },
}
</script>

<style lang="sass" scoped>
.table-contact
  &__table
    .channels
      display: flex
    .italic
      font-style: italic
      color: $gray_dark_500
</style>

<style lang="sass">
.table-contact
  &__table
    .channels
      svg
        margin: 0 4px
</style>
