<template>
  <div class="content display-flex direction-column align--center">
    <img
      src="img/graphics/outlined/chatbot-greet.png"
      class="content__picture"
    />
    <h5 class="semibold-20 text--center content__title">
      Tu chatbot “{{ nameChatbot }}" está listo para trabajar 🎉🎉
    </h5>

    <ul class="content__list">
      <li
        v-for="(benefit, index) in benefits"
        :key="index"
        class="content__item"
      >
        ✅ {{ benefit }}
      </li>
    </ul>
    <div>
      <a-button @click="$emit('onOther')">Crear otro chatbot</a-button>
      <a-button
        type="primary"
        @click="$emit('onGoToChatbot')"
        style="margin-left: 12px"
        >Ir a mi chatbot</a-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessCreate',
  props: {
    nameChatbot: { type: String, default: 'Ticker' },
  },
  data: () => ({
    benefits: [
      'Atiende y vende las 24 horas del día.',
      'Respuestas más rápidas',
      'Crea flujos de atención personalizados',
      'Múltiples y flexibles opciones secuenciales',
      'Enrutamiento dinámico',
    ],
  }),
}
</script>

<style lang="sass" scoped>
.content
  padding: 4em 6em
  &__title
    margin: 24px auto
  &__list
    color: $gray_9
    margin-bottom: 2em
  &__item
    list-style: none
    margin-bottom: 8px
</style>
