import instanceJson from '@/app/shared/utils/instance'

const initialState = () => ({
  max_months_to_get: 2, // maximo de meses para obtener data
  drawer_filters: {
    // para los drawers de filtros
    title: 'Aplicar filtros',
    width: '26vw',
  },
  modules_pagination: instanceJson.modules_pagination,
  submenu_chat_atc: instanceJson.submenu_chat_atc,
  enable_twilio_provider: instanceJson.enable_twilio_provider, // proveedor twilio habilitado o no
  enable_register_facebook: instanceJson.enable_register_facebook,
})

const state = initialState

const getters = {
  max_months_to_get: (state) => state.max_months_to_get,
  drawer_filters: (state) => state.drawer_filters,
  modules_pagination: (state) => state.modules_pagination,
  submenu_chat_atc: (state) => state.submenu_chat_atc,
  enable_twilio_provider: (state) => state.enable_twilio_provider,
  enable_register_facebook: (state) => state.enable_register_facebook,
}

export default {
  state,
  getters,
}
