<template>
  <div
    class="node-message"
    :class="{
      'node-message--selected': selected,
      'cursor-auto': disabled,
      'cursor-pointer': !disabled,
    }"
    @click="handleSelect"
  >
    <div class="node-message__header">
      <div class="node-message__title">
        <a-icon type="profile" class="node-message__icon--profile" />
        <h5 class="heading-h7">{{ title }}</h5>
      </div>
      <div>
        <a-tooltip v-if="showInfo" placement="bottom">
          <template slot="title">
            <span
              >Recuerda que no se podrán agregar más mensajes en este y se
              resolverá el ticket.</span
            >
          </template>
          <a-icon type="info-circle" class="node-message__icon--info" />
        </a-tooltip>
        <i class="info-2 node-message__action">{{ action }}</i>
      </div>
    </div>
    <div class="node-message__body">
      <p
        class="
          body-2
          node-message__description
          mrg-bottom-0
          ellipsis
          text--left
        "
      >
        {{ description }}
      </p>
      <div class="node-message__actions">
        <a
          :class="{ 'node-message__action--danger': !disabled }"
          @click.stop="() => !disabled && $emit('onDelete')"
          :disabled="disabled"
          >Eliminar</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MessageNode',
  props: {
    title: { type: String, default: 'Mensaje' },
    description: { type: String, default: 'Descripcion' },
    action: { type: String, default: 'Finalizar chatbot' },
    selected: { type: Boolean, required: false, default: false },
    showInfo: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    handleSelect() {
      !this.disabled && this.$emit('onSelect')
    },
  },
}
</script>
<style lang="sass" scoped>
.node-message
  border: 1px solid $gray_dark_100
  border-radius: 4px
  margin-bottom: 8px
  padding: 12px
  &__header
    display: flex
    justify-content: space-between
  &__title
    display: flex
  &__description
    text-align: left
    color: $gray_dark_800
    margin-right: 20px
  &__icon--profile
    font-size: 20px
    color: $blue_6
    margin-right: 8px
  &__icon--info
    font-size: 20px
    color: $gray_7
    margin-right: 8px
  &__action
    color: $gray_7
    &--danger
      color: $red_6
  &__body
    display: flex
    justify-content: space-between
  &--selected
    border: 1px solid $blue_6
    background-color: $blue_1
  &:hover
    border: 1px solid $blue_6
</style>
