<template>
  <div class="section scrollbar_basic">
    <h6 v-if="currentNotifications.length > 0" class="heading-h6">Recientes</h6>
    <a-list
      v-if="currentNotifications.length > 0"
      item-layout="horizontal"
      :data-source="currentNotifications"
      class="list"
      :locale="{ emptyText: 'No hay notificaciones' }"
    >
      <a-list-item
        slot="renderItem"
        slot-scope="notification"
        class="list-item"
        :class="{
          'last-list-item': oldNotification._id === notification._id,
          'list-item__new': notification.new,
        }"
        @mouseenter="handleExecuteTimeout"
        @mouseleave="handleClearTimeout(notification)"
      >
        <a-list-item-meta>
          <template slot="description">
            <p class="body-1 text-align-left">
              <strong class="heading-h8 text-name">
                {{ notification.user.fullName }}
              </strong>
              {{
                notification.message
                  ? notification.message.es
                  : 'Sin comentarios'
              }}
            </p>
            <p class="body-1 text-align-left date">
              {{ notification.date }}
            </p>
          </template>
          <a-avatar
            v-if="notification.user.avatar"
            slot="avatar"
            :src="notification.user.avatar"
            :alt="`Avatar del usuario ${notification.user.fullName}`"
          />
          <DefaultIconLetter
            v-else
            slot="avatar"
            :names="notification.user.fullName"
            :alt="`Avatar con las iniciales de  ${notification.user.fullName}`"
          />
        </a-list-item-meta>
        <a-tag v-if="notification.new" color="volcano">Nuevo</a-tag>
      </a-list-item>
    </a-list>
    <h6 class="heading-h6">Pasados</h6>
    <a-list
      item-layout="horizontal"
      :data-source="pastNotifications"
      class="list"
      :loading="isLoading"
      :locale="{ emptyText: 'No hay notificaciones' }"
    >
      <a-list-item
        slot="renderItem"
        slot-scope="notification"
        class="list-item"
        :class="{ 'last-list-item': oldNotification._id === notification._id }"
      >
        <a-list-item-meta>
          <template slot="description">
            <p class="body-1 text-align-left">
              <strong class="heading-h8 text-name">
                {{ notification.user.fullName }}
              </strong>
              {{
                notification.message
                  ? notification.message.es
                  : 'Sin comentarios'
              }}
            </p>
            <p class="body-1 text-align-left date">
              {{ notification.date }}
            </p>
          </template>
          <a-avatar
            v-if="notification.user.avatar"
            slot="avatar"
            :src="notification.user.avatar"
          />
          <DefaultIconLetter
            v-else
            slot="avatar"
            :names="notification.user.fullName"
          />
        </a-list-item-meta>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'
import moment from 'moment'
import attemptMixin from '@/app/shared/mixins/attempt'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'ListNotifications',
  mixins: [attemptMixin],
  components: {
    DefaultIconLetter,
  },
  props: {
    isLoading: {
      type: Boolean,
      require: false,
      default: false,
    },
    morePaginate: {
      type: Boolean,
      require: false,
      default: false,
    },
    notifications: {
      type: Array,
      require: false,
      default: () => [],
    },
  },
  data: () => ({
    moment,
    lastChild: null,
    observer: null,
    oldNotification: null,
    readyToRead: false,
  }),
  mounted() {
    this.setLastChild()
  },
  computed: {
    /**Notificaciones de las ultimas 24 horas */
    currentNotifications() {
      const newNotifications = []
      this.notifications.map((notification) => {
        this.isMoreOld(notification)
        const diffTime = moment(new Date()).diff(
          notification.updated_at,
          'days'
        )
        if (diffTime === 0) {
          notification.date = moment(notification.updated_at)
            .startOf()
            .fromNow()
          notification.isCurrent = true
          newNotifications.push(notification)
        }
      })
      // evitar que se repitan
      const hash = {}

      return newNotifications.filter((current) => {
        const exists = !hash[current._id]
        hash[current._id] = true
        return exists
      })
    },
    /**Notificaciones pasadas */
    pastNotifications() {
      const newNotifications = []
      this.notifications.map((notification) => {
        this.isMoreOld(notification)
        const diffTime = moment(new Date()).diff(
          notification.updated_at,
          'days'
        )
        if (diffTime > 0) {
          notification.date = moment(notification.updated_at).calendar()
          notification.isCurrent = false
          newNotifications.push(notification)
          if (diffTime > 7)
            notification.date = moment(notification.updated_at).format('LLL')
        }
      })
      // evitar que se repitan
      const hash = {}

      return newNotifications.filter((current) => {
        const exists = !hash[current._id]
        hash[current._id] = true
        return exists
      })
    },
  },
  methods: {
    ...mapActions(['readNewNotification']),
    ...mapMutations(['REMOVE_NEW_NOTIFICATIONS']),

    setLastChild() {
      this.intervalAttempt(() => {
        const childs = document.getElementsByClassName('last-list-item')
        this.lastChild = childs[0]
        this.observer = new IntersectionObserver(this.handleBottomReached, {
          threshold: 1.0,
        })
        this.observer.observe(this.lastChild)
      })
    },
    /**
     * Verifica si primer elemento cumple con las condiciones dadas
     * @param {Array} entries - elementos del DOM
     * @param {Object} entries[].target
     * @param {String} entries[].target.className
     * @param {String} entries[].isIntersecting - si el elemento es visible en el DOM
     */
    handleBottomReached(entries) {
      const entry = entries[0]
      if (
        entry.target.className.includes('last-list-item') &&
        entry.isIntersecting &&
        this.morePaginate &&
        !this.isLoading
      ) {
        this.$emit('onBottomReached')
      }
    },
    /**
     * Verifica si una notificacion es mas antigua que otra
     * @param {Object} notification
     * @param {Date} notification.updated_at
     *
     */
    isMoreOld(notification) {
      const diffTimeNewNotification = moment(new Date()).diff(
        notification.updated_at
      )
      if (!this.oldNotification) this.oldNotification = notification
      else {
        const diffTimeOldNotification = moment(new Date()).diff(
          this.oldNotification.updated_at
        )
        if (diffTimeNewNotification > diffTimeOldNotification)
          this.oldNotification = notification
      }
    },
    /**
     * Actualiza la notificación como leida
     * @param {Object} notification
     * @param {String} notification._id
     * @param {Boolean} notification.new
     */
    async handleReadNotification(notification) {
      if (notification.new) {
        await this.readNewNotification(notification._id)
      }
    },
    handleExecuteTimeout() {
      setTimeout(() => {
        this.readyToRead = true
      }, 2000)
    },
    handleClearTimeout(notification) {
      this.readyToRead = false
      if (notification.new) {
        this.handleReadNotification(notification)
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.text-align-left
  text-align: left
.section
  background-color: $white_000
  border-radius: 8px
  border: 1px solid $gray_5
  padding: 20px 24px
  height: calc( 100vh - 92px )
  overflow: auto
  h6
    text-align: left
.list-item
  color: $gray_dark_900
  align-items: flex-start
  padding: 12px 8px
  .ant-list-item-meta
    &-content
      .ant-list-item-meta-description
        color: $gray_dark_900
        p
          margin-bottom: 0px
  &__new
    background-color: $volcano_1
.date
  color: $gray_dark_700
</style>
