<template>
  <div class="list-items">
    <span
      v-if="filterDocs.length === 0 && filterImgs.length === 0"
      class="empty"
      >No se ha encontrado archivos</span
    >
    <div v-if="filterDocs.length > 0" class="docs">
      <div class="contain-h6">
        <h6 class="heading-h8">Documentos</h6>
      </div>
      <a-row class="docs-ul" :gutter="[4, 4]">
        <a-col
          v-for="file in filterDocs"
          :key="`file ${file._id}`"
          class="gutter-row"
          :xs="6"
          :sm="6"
          :md="6"
          :lg="8"
          :xl="8"
        >
          <DocumentFile :file="file" :icon="file.icon" :src="file.src" />
        </a-col>
      </a-row>
    </div>
    <div v-if="filterImgs.length > 0" class="docs">
      <div class="contain-h6">
        <h6 class="heading-h8">Imágenes</h6>
      </div>
      <a-row class="docs-ul" :gutter="[4, 4]">
        <a-col
          v-for="file in filterImgs"
          :key="`file ${file._id}`"
          class="gutter-row"
          :xs="6"
          :sm="6"
          :md="6"
          :lg="8"
          :xl="8"
        >
          <ImgModal :file="file" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ImgModal from '@/app/tickets/components/molecules/ImgModal'
import DocumentFile from '@/app/tickets/components/molecules/DocumentFile'
export default {
  name: 'ListItems',
  components: {
    ImgModal,
    DocumentFile,
  },
  computed: {
    ...mapGetters(['multimedias', 'ticketSelected']),

    /**
     * Filtra los documentos del array de multimedias
     * @return {Object[]} filterDocs
     * @return {String} filterDocs[].icon
     * @return {String} filterDocs[].src
     * @return {String} filterDocs[]._id
     */
    filterDocs() {
      const filesAllowed = ['pdfUrl', 'pptUrl', 'wordUrl', 'excelUrl']
      let docs = []
      filesAllowed.forEach((fileAllowed) => {
        const filteredDocs = this.multimedias.filter(
          (obj) => obj.message[fileAllowed]
        )
        const formattedDocs = filteredDocs.map((filterDoc) => {
          filterDoc.icon = this.thumbnailIcon(filterDoc.message)
          filterDoc.src = filterDoc.message[fileAllowed]
          return filterDoc
        })
        docs = [...docs, ...formattedDocs]
      })
      return docs
    },
    /**
     * Filtra solo las imagenes de multimedias
     * @return {Object[]} filterImgs
     * @return {String} filterDocs[].imageUrl
     * @return {String} filterDocs[]._id
     */
    filterImgs() {
      return this.multimedias.filter((obj) => obj.message.imageUrl)
    },
  },
  watch: {
    ticketSelected() {
      this.listMultimedia()
    },
  },
  methods: {
    ...mapActions(['listMultimedia']),
    /**
     * @param {String} url URL a descargar
     */
    downloadDoc(url) {
      window.open(url)
    },
    /**
     * Se usa para saber que icono mostrar de acuerdo al tipo de archivo
     * @returns {String}
     */
    thumbnailIcon(file) {
      if (file.pdfUrl) return 'file-pdf'
      else if (file.wordUrl) return 'file-word'
      else if (file.excelUrl) return 'file-excel'
      else if (file.pptUrl) return 'file-ppt'
      else if (file.fileUrl) return 'file'
      return 'file'
    },
  },
}
</script>

<style lang="sass" scoped>
.contain-h6
  border-bottom: 1px solid $gray_dark_900
  margin-bottom: 8px
  h6
    text-align: left
    margin-bottom: 4px
    color: $gray_dark_900
.list-items
  display: flex
  flex-direction: column
  .empty
    color: $gray_dark_200
    font-size: 14px
    margin: 36px 0 0 0
    position: absolute
    left: 0
    width: 100%
  .docs
    padding: 4px
    display: flex
    flex-direction: column
    .docs-ul
      // padding-left: 0px
      // display: flex
      // flex-direction: row
      // flex-wrap: wrap
</style>
