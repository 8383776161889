import ManageTickets from '@/app/manageTickets/views/ManageTickets'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/tickets',
    name: 'tickets',
    component: ManageTickets,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'supervisor', 'agent'],
      title: `Gestor de Tickets | ${VUE_APP_NAME}`,
    },
  },
]
