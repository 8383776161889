import { render, staticRenderFns } from "./BubbleWebchat.vue?vue&type=template&id=507aa524&scoped=true&"
import script from "./BubbleWebchat.vue?vue&type=script&lang=js&"
export * from "./BubbleWebchat.vue?vue&type=script&lang=js&"
import style0 from "./BubbleWebchat.vue?vue&type=style&index=0&id=507aa524&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "507aa524",
  null
  
)

export default component.exports