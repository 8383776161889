<template>
  <div class="section-time-state">
    <div class="header">
      <p class="title heading-h6">Tiempo promedio de estados de agentes</p>
      <div>
        <a-tooltip placement="bottomRight" :arrowPointAtCenter="true">
          <template slot="title">
            Visualiza el tiempo promedio que transcurren los agentes en los
            diferentes estados dentro de Ticker. También puedes filtrar por
            agente en específico. Se mostrarán según el rango de fecha y hora
            que decidas en el filtro. Este dashboard no es afectado por el
            filtro de colas.
          </template>
          <a-icon
            type="info-circle"
            style="font-size: 20px; color: #595959; margin-right: 8px"
          />
        </a-tooltip>
        <!-- pop confirm con icon-->
        <a-popconfirm
          v-if="visibleConfirm"
          title="¿Estás segur@ de descargar los datos de este dashboard?"
          ok-text="Descargar"
          cancel-text="Cancelar"
          placement="bottomRight"
          @confirm="confirm"
          :arrowPointAtCenter="true"
          :overlayStyle="popStyles"
        >
          <a-tooltip
            placement="bottomRight"
            :arrowPointAtCenter="true"
            overlayClassName="tooltip-dash1"
            trigger="hover"
          >
            <template slot="title"> Descargar en excel </template>
            <a-icon
              type="download"
              style="font-size: 20px; color: #595959"
              class="btn-dowload"
            />
          </a-tooltip>
        </a-popconfirm>
        <!--popover con icon-->
        <a-popover
          v-if="visiblePopOver"
          placement="bottomRight"
          :arrowPointAtCenter="true"
          :visible="visiblePopOver"
          :overlayStyle="popStyles"
        >
          <template slot="content">
            <p style="text-align: center" class="gray-dark-900">
              {{ textProgress }}
            </p>
            <a-progress :percent="percent" :status="status" />
            <div v-if="this.status === 'exception'" class="contain-btns">
              <a-button type="link" size="small" @click="handleCancel"
                >Cancelar</a-button
              >
              <a-button type="link" size="small" @click="handleOk"
                >Volver a intentar</a-button
              >
            </div>
          </template>
          <a-icon
            type="download"
            style="font-size: 20px; color: #595959"
            class="btn-dowload"
            :class="{ 'btn-focus': visiblePopOver }"
          />
        </a-popover>
      </div>
    </div>
    <div class="body">
      <div class="contain-select">
        <a-select
          :value="agentId"
          size="small"
          style="min-width: 140px"
          @change="handleAgent"
          show-search
          option-filter-prop="children"
        >
          <a-select-option
            v-for="agent in arrAgents"
            :key="agent.userId"
            :title="`${agent.names} ${agent.surnames ? agent.surnames : ''}`"
            >{{ agent.names }} {{ agent.surnames }}</a-select-option
          >
        </a-select>
        <a-select
          v-model="statusesSelected"
          mode="multiple"
          size="small"
          @change="handleChangeState"
          class="mrg-left-8 dashboard__select"
          placeholder="Selecciona"
          show-search
          option-filter-prop="children"
        >
          <a-select-option
            v-for="state in statusesLocal"
            :key="state.key"
            :title="state.state"
            >{{ transformWord(state.state, 'capitalize') }}</a-select-option
          >
        </a-select>
      </div>
      <bar-chart :styles="myStyles" :chartdata="chartdata" :options="options" />
    </div>
  </div>
</template>
<script>
import BarChart from '@/app/dashboard/components/organisms/charts/BarChart.vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import transformMixin from '@/app/shared/mixins/transform'
import attemptMixin from '@/app/shared/mixins/attempt'

export default {
  name: 'SectionTimeState',
  components: {
    BarChart,
  },
  props: {
    lineId: { type: String, default: 'allLines' },
    date: { type: Array, required: true },
  },
  data: () => ({
    moment,
    myStyles: {
      height: '290px',
      margin: '20px 30px 0',
      position: 'relative',
    },
    visibleConfirm: true,
    visiblePopOver: false,
    percent: 0, // porcentaje del progress
    status: null, // status del progress
    popStyles: {
      width: '290px',
      zIndex: '999',
    },
    agentId: 'allAgents',
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: (value) => {
                return `${value} min`
              },
            },
            type: 'linear',
          },
        ],
      },
    },
    statusesSelected: [],
    statusesLocal: [],
  }),
  mixins: [transformMixin, attemptMixin],
  mounted() {
    this.intervalAttempt(async () => {
      if (!this.statusesTime.length) throw 'no states'
      this.statusesLocal = [...this.statusesTime]
      this.statusesSelected = [...this.statusesTime].map((state) => state.key)
    })
  },
  watch: {
    lineId() {
      this.agentId = 'allAgents'
      this.statusesSelected = [...this.statusesLocal].map((state) => state.key)
    },
  },
  computed: {
    ...mapGetters(['agentsAccounts', 'statusesTime']),
    /**
     * @return {Object[]} array de agentes
     */
    arrAgents() {
      if (!this.agentsAccounts) return []
      let arrAgents = []
      const allAgent = {
        userId: 'allAgents',
        names: 'Todos los agentes',
        default: true,
      }
      if (this.lineId === 'allLines') {
        arrAgents = this.agentsAccounts.slice()
      } else {
        const filtered = this.agentsAccounts.filter((agent) =>
          agent.company.linesId.includes(this.lineId)
        )
        arrAgents = filtered
      }
      arrAgents.unshift(allAgent)
      return arrAgents
    },
    /**Estados de los agentes
     * @return {Object[]} estados con colores
     */
    states() {
      const arr = []
      const statusesTime = [...this.statusesTime]
      statusesTime.forEach((status) => {
        switch (status.key) {
          case 'bussy':
            status.color = 'rgba(245, 34, 45, 0.5)'
            break
          case 'training':
            status.color = 'rgba(250, 140, 22, 0.5)'
            break
          case 'services':
            status.color = 'rgba(24, 144, 255, 0.5)'
            break
          case 'connected':
            status.color = 'rgba(47, 194, 91, 0.5)'
        }
        arr.push(status)
      })
      return arr
    },
    /**
     * @return {Object} data necesaria para el grafico
     * @return {Object[]} datasets
     * @return {Object[]} labels
     */
    chartdata() {
      const datasets = []
      this.states.forEach((status) => {
        const obj = {}
        obj['label'] = status.state
        obj['backgroundColor'] = status.color
        obj['data'] = status.data
        datasets.push(obj)
      })
      return {
        labels: this.states.map((item) => item.state), // eje x
        datasets: datasets, // eje y
      }
    },
    /**
     * @return {String} texto para simular descarga
     */
    textProgress() {
      switch (this.status) {
        case 'exception':
          return 'Lo sentimos, ocurrió un error'
        case 'active':
          return 'Descargando datos del dashboard...'
        case 'success':
          return 'Datos descargados'
        default:
          return null
      }
    },
  },
  methods: {
    ...mapActions(['exportAverageStatuses', 'setAnalytics']),
    // confirmar pop confirm
    confirm() {
      this.visibleConfirm = false
      this.visiblePopOver = true
      this.exportDashboard()
      this.setAnalytics({
        eventName: 'descarga_excel',
        extraData: { dashboard: 'time_user_status' },
      })
    },
    // cancela la descarga
    handleCancel() {
      this.visiblePopOver = false
      this.visibleConfirm = true
    },
    // vuelve a descargar
    handleOk() {
      this.visiblePopOver = true
      this.exportDashboard()
    },
    //se ejecuta cada vez q se cambia el select de agentes
    handleAgent(value) {
      this.agentId = value
      this.$emit('onChangeAgent', {
        agentId: value,
        statuses: this.statusesSelected,
      })
    },
    exportDashboard() {
      this.percent = 70
      this.status = 'active'
      const date = `${moment(this.date[0]).toISOString()}/${moment(
        this.date[1]
      ).toISOString()}`
      const lineId = this.lineId
      const agentId = this.agentId
      this.exportAverageStatuses({
        date,
        lineId,
        agentId,
        statuses: this.statusesSelected,
      })
      setTimeout(() => {
        this.percent = 100
        this.status = 'success'
      }, 2000)
      setTimeout(() => {
        this.visiblePopOver = false
        this.visibleConfirm = true
      }, 3000)
    },
    // cambia de estado
    handleChangeState(statuses) {
      this.$emit('onChangeState', { agentId: this.agentId, statuses })
      if (!statuses.length)
        this.statusesSelected = [...this.statusesLocal].map(
          (state) => state.key
        )
    },
  },
}
</script>

<style lang="sass" scoped>
.gray-dark-900
  color: $gray_dark_900
.btn-focus
  color: #40A9FF!important
.section-time-state
  background-color: $white_000
  height: 424px
  border: 1px solid #D9D9D9
  border-radius: 2px
  .header
    border-bottom: 1px solid #E8E8E8
    padding: 12px 24px
    display: flex
    justify-content: space-between
    align-items: center
    .title
      color: #262626
      margin-bottom: 0px !important
  .body
    .contain-select
      margin: 20px
      display: flex
      justify-content: flex-start
.contain-btns
  margin-top: 8px
  display: flex
  flex-direction: row
  justify-content: center
.btn-dowload
  &:hover
    color: #1890FF!important
  &:active
    color: #096DD9!important
  &:focus
    color: #40A9FF!important
.dashboard__select
  flex-grow: 1
</style>
