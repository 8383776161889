<template>
  <div>
    <!-- Modal de Acción ante pago rechazado -->
    <div v-if="isRejected" class="payment-rejected">
      <a-modal
        :footer="null"
        :visible="informVisible"
        width="440px"
        :closable="false"
        centered
        class="payment-rejected__content"
        :bodyStyle="{ padding: '36px' }"
      >
        <a-row type="flex" justify="center" class="payment-rejected__icon">
          <svg width="48" height="56" viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.062 45.75c0 1.106.894 2 2 2h35.875c1.107 0 2-.894 2-2V31.187c0-11.012-8.925-19.937-19.937-19.937-11.013 0-19.938 8.925-19.938 19.938V45.75zm4.5-14.563c0-8.524 6.913-15.437 15.438-15.437s15.437 6.913 15.437 15.438V43.25H17.25V32.562a.627.627 0 00-.625-.624h-2.75a.627.627 0 00-.625.625V43.25H8.562V31.187zm-3.006-15.78l2.475-2.476a.502.502 0 000-.706L3.787 7.981a.502.502 0 00-.706 0L.606 10.456a.502.502 0 000 .707l4.244 4.243a.502.502 0 00.706 0zm41.85-4.95L44.931 7.98a.502.502 0 00-.706 0l-4.244 4.244a.502.502 0 000 .706l2.475 2.475a.502.502 0 00.706 0l4.244-4.244a.502.502 0 000-.706zM44 51.75H4c-1.106 0-2 .894-2 2v1.5c0 .275.225.5.5.5h43c.275 0 .5-.225.5-.5v-1.5c0-1.106-.894-2-2-2zM22.25 7.25h3.5c.275 0 .5-.225.5-.5v-6c0-.275-.225-.5-.5-.5h-3.5c-.275 0-.5.225-.5.5v6c0 .275.225.5.5.5z" fill="#F5222D"/></svg>
        </a-row>
        <a-row type="flex" justify="center" class="payment-rejected__info">
          <h2 class="payment-rejected__title heading-h5">
            No hemos podido realizar el cobro de tu plan mensual
          </h2>
          <p class="body-2">
            Recuerda que para seguir con los beneficios de tu plan actual,
            necesitamos procesar el pago de tu licencia.
          </p>
        </a-row>

        <div class="payment-rejected__actions">
          <a-row type="flex" justify="center">
            <a-button type="primary" @click="handleUpgrade"
              >Actualizar método de pago</a-button
            >
          </a-row>
          <a-row type="flex" justify="center">
            <a-button
              type="link"
              class="body-2 payment-rejected__downgrade"
              @click="handleDowngrade"
              >Bajar al plan free</a-button
            ></a-row
          >
        </div>
      </a-modal>
      <payment-rejecte-downgrade
        v-if="downgradeVisible"
        :visible="downgradeVisible"
        @hide="handleHide('downgradeVisible')"
        @updatePayment="handleUpgrade"
      />
      <payment-rejected-upgrade
        v-if="upgradeVisible"
        :visible="upgradeVisible"
        @hide="handleHide('upgradeVisible')"
        @upgrade="doneModalUpgrade"
      />
    </div>
    <!-- Modal de Pago exitoso -->
    <modal-success-payment
      :visible="upgradeSuccess"
      :title="successText.title"
      :subtitle="successText.subtitle"
      @hideModal="upgradeSuccess = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PaymentRejecteDowngrade from './PaymentRejecteDowngrade.vue'
import PaymentRejectedUpgrade from './PaymentRejectedUpgrade.vue'
import ModalSuccessPayment from '@/app/shop/components/market/settingsCreditCard/ModalSuccessPayment.vue'

export default {
  name: 'PaymentRejectedModal',
  components: {
    PaymentRejecteDowngrade,
    PaymentRejectedUpgrade,
    ModalSuccessPayment,
  },
  props: {
    keyword: {
      type: String,
      default: 'Comenzar',
    },
  },
  data: () => ({
    upgradeSuccess: false, // Modal de upgrade exitoso
    successText: {
      title: '¡Mejoraste tu suscripción de Ticker!',
      subtitle:
        'Tu nuevo plan mensual se cargará a tu método de pago predeterminado.',
    },
    informVisible: true, // Modal principal de este componente
    downgradeVisible: false, // Modal de downgrade
    upgradeVisible: false, // Modal de upgrade
  }),
  computed: {
    ...mapGetters(['company', 'profile']),
    /**
     * @return {Boolean}
     */
    isRejected() {
      if (!this.company) return false
      return this.company.status == 'payment-rejected'
    },
  },
  methods: {
    ...mapActions(['getCompanyPricing']),
    /**
     * @async
     * @poram {String} modal
     */
    async handleHide(modal) {
      this[modal] = false
      await this.getCompanyPricing()
    },
    handleDowngrade() {
      this.downgradeVisible = true
    },
    handleUpgrade() {
      this.upgradeVisible = true
    },
    doneModalUpgrade() {
      this.$emit('upgrade')
      this.upgradeSuccess = true
    },
  },
}
</script>

<style lang="sass" scoped>
.payment-rejected
  &__info
    margin: 16px 0
  &__title
    text-align: center
  &__downgrade
    color: #8C8C8C
    margin-top: 10px
</style>
