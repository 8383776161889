var render = function render(){var _vm=this,_c=_vm._self._c;return _c('box-template',[_c('custom-section-scroll',{attrs:{"master-styles":{
      height: `calc(100vh - (${_vm.heightFooter} + ${_vm.margin}))`,
      width: '100%',
      padding: '8px 0px',
    },"content-styles":{
      margin: '3em auto 0px',
      maxWidth: '1000px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}},[_c('a-row',{attrs:{"gutter":[100],"type":"flex","justify":"center"}},[_c('a-col',{attrs:{"xs":10,"sm":8,"md":12,"lg":11,"xl":12,"xxl":12}},[_c('add-strategy-form',{ref:"add_strategy_form",on:{"onChange":_vm.handleChangeForm,"onLoading":_vm.handleLoadingForm,"onHasNewValues":(hasNew) => (_vm.isDisabledOk = hasNew)}})],1),_c('a-col',{attrs:{"xs":4,"sm":6,"md":11,"lg":11,"xl":10,"xxl":10}},[_c('a-row',{staticClass:"full-height",attrs:{"type":"flex","justify":"center","align":"middle"}},[_c('mobile-wsp',{attrs:{"message":_vm.mobileMessage}})],1)],1)],1)],1),_c('basic-footer',{attrs:{"okText":"Crear campaña","cancelText":"Cancelar","okLoading":_vm.isLoadingOk,"okDisabled":_vm.isDisabledOk,"cancelDisabled":_vm.isDisabledCancel},on:{"onOk":_vm.handleSubmit,"onCancel":_vm.handleCancel}},[_c('p',{staticClass:"mrg-bottom-0",attrs:{"slot":"extra"},slot:"extra"},[_c('a-icon',{attrs:{"type":"info-circle"}}),_vm._v(" Las respuestas a estos envíos serán transferidas como tickets directamente a tus agentes, sin pasar por un chatbot. ")],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }