import logo_inbox from '@/app/commons/assets/logo-Inbox.png'
import logo_ticker from '@/app/commons/assets/logo-Ticker.png'

const requestNotificationMessages = {
  title: '¿Deseas habilitar las notificaciones del navegador?',
  description:
    'La llegada de las notificaciones depende del navegador web que uses y su configuración.',
}

const updatePermission = async () => {
  const localPermission = getPermission()
  if (!localPermission) return
  const permission = Notification.permission
  if (permission == 'granted') {
    localStorage.setItem('notificationPermission', 'granted')
  }
}

const requestPermission = async (notification) => {
  const permission = await Notification.requestPermission()
  if (permission != 'granted') {
    notification(
      'No se pudo activar las notificaciones',
      'El navegador que esta usando no permitió que activemos las notificaciones',
      { name: 'Entendido', fun: () => {} },
      () => {}
    )
    localStorage.setItem('notificationPermission', 'blocked')
    return
  }
  localStorage.setItem('notificationPermission', permission)
}
const deniedPermission = () => {
  if (!localStorage.getItem('notificationPermission'))
    localStorage.setItem('notificationPermission', 'blocked')
}

const getPermission = () => {
  return localStorage.getItem('notificationPermission')
}

/**
 * Envia una notificación al dispositivo
 * @param {Object} notification Datos a mostrar en la notificación
 * @param {string} notification.title Titulo de la notificación
 * @param {string} notification.body Mensaje de la notificación
 * @param {Object} notification.events Eventos de la notificación
 */
const buildNotification = ({ title, body, events }) => {
  const logo =
    process.env.VUE_APP_NAME === 'Inbox'
      ? logo_inbox
      : process.env.VUE_APP_NAME === 'Ticker'
      ? logo_ticker
      : null
  const notification = new Notification(title, { body, icon: logo })
  notification.onclick = events.onClick
  return notification
}
/**
 * Envento predeterminado para los tickets
 * @param {string} ticket Ticket que ha lanzado la notificación
 */
const notificationTicketOnClick = (ticket) => {
  const onClick = function () {
    console.log(ticket)
    window.focus()
  }
  return onClick
}

/**
 * Envia una notificación al dispositivo
 * @param {string} title Titulo de la notificación
 * @param {string} body Mensaje de la notificación
 * @param {Object} events Eventos de la notificación
 * @param {Object} events.onClick Callback al hacer click
 */
const notify = (title, body, events) => {
  const permission = getPermission()
  if (permission != 'granted' || document.hasFocus()) return
  return buildNotification({ title, body, events })
}

export default {
  notify,
  notificationTicketOnClick,
  requestNotificationMessages,
  requestPermission,
  getPermission,
  deniedPermission,
  updatePermission,
}
