<template>
  <div
    class="custom-section-scroll"
    :id="`${idMaster}-scroll-master`"
    :style="masterStyles"
  >
    <div
      class="container scrollbar_basic"
      @scroll="
        setShadows(
          `${idMaster}-scroll-master`,
          `${idMaster}-scroll-container`,
          `${idMaster}-scroll-content`
        )
      "
      :id="`${idMaster}-scroll-container`"
      :style="containerStyles"
    >
      <div
        class="content"
        :id="`${idMaster}-scroll-content`"
        :style="contentStyles"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSectionScroll',
  props: {
    masterStyles: {
      type: Object,
      require: false,
      default: () => ({ height: '460px' }),
    },
    containerStyles: {
      type: Object,
      require: false,
    },
    contentStyles: {
      type: Object,
      require: false,
    },
    idMaster: {
      type: String,
      require: true,
      default: 'custom-section-scroll',
    },
  },
  methods: {
    /**
     * Añade y remueve clases dependiendo de la posición del scroll
     * @param {String} idMaster - id del contenedor master
     * @param {String} idContainer - id del contenedor
     * @param {String} idContent - id del contenido
     */
    setShadows(idMaster, idContainer, idContent) {
      // emite el evento scroll
      this.$emit('onScroll')

      const masterContainer = document.getElementById(idMaster)
      const container = document.getElementById(idContainer)
      const content = document.getElementById(idContent)
      // hay scroll o no hay scroll
      if (content.offsetHeight > container.offsetHeight) {
        // altura del contenido restante
        const contentHeightCalculated =
          content.offsetHeight - container.scrollTop
        // si esta al inicio
        if (container.scrollTop === 0) {
          masterContainer.classList.add('shadow-bottom')
          masterContainer.classList.remove('shadow-top')
        }
        // si esta al final
        else if (container.offsetHeight >= contentHeightCalculated) {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.remove('shadow-bottom')
        }
        // si no esta al inicio y al final
        else {
          masterContainer.classList.add('shadow-top')
          masterContainer.classList.add('shadow-bottom')
        }
      } else {
        masterContainer.classList.remove('shadow-top')
        masterContainer.classList.remove('shadow-bottom')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.custom-section-scroll
  position: relative
  padding-top: 4px
  padding-bottom: 4px
  .container
    overflow-y: auto
    height: 100%
    .content
.shadow-top
  &::before
    content: ''
    left: 0
    width: 100%
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    position: absolute
    height: 1px
    top: 0
.shadow-bottom
  &::after
    content: ''
    left: 0
    width: 100%
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    position: absolute
    height: 1px
    bottom: 0
</style>
