<template>
  <a-modal
    :visible="visible"
    v-if="oldTicketSelected"
    @ok="handleOk"
    :bodyStyle="{ ...styles, height: heightBody }"
    :footer="null"
    wrapClassName="previewModal"
    :width="widthModal"
    @cancel="$emit('close')"
    centered
  >
    <!--Titulo del modal-->
    <template slot="title">
      <a-row type="flex" align="middle">
        <span class="mrg-right-8">
          {{
            `Ticket  ${ticketNumberFormatted(oldTicketSelected.ticket)}`
          }}</span
        >
        <span class="mrg-right-8">
          <a-rate
            v-if="oldTicketSelected.settings.starred"
            :default-value="1"
            disabled
            :count="1"
          />
        </span>
        <a-tooltip
          v-if="ticketOrigin"
          placement="right"
          :title="ticketOrigin.title"
        >
          <a-tag :color="ticketOrigin.color">{{ ticketOrigin.origin }}</a-tag>
        </a-tooltip>
      </a-row>
    </template>
    <!--Cuerpo del modal-->
    <a-row justify-content="space-between" style="height: 100%">
      <a-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 8 }"
        :xl="{ span: 8 }"
        :style="!active_breakpoint.is_mobile ? 'height: 100%' : 'height: auto'"
      >
        <a-collapse v-if="active_breakpoint.is_mobile" :bordered="false">
          <a-collapse-panel
            key="1"
            header="Detalles del ticket"
            style="padding: 0px"
          >
            <div class="modal__panel__content">
              <SectionTicketDetails
                :customFields="
                  oldTicketSelected.settings.customFields
                    ? oldTicketSelected.settings.customFields
                    : []
                "
                :tags="oldTicketSelected.settings.tags"
                :integrations="
                  oldTicketSelected.integrations
                    ? oldTicketSelected.integrations
                    : []
                "
                type="history-manage"
              />
            </div>
          </a-collapse-panel>
        </a-collapse>
        <SectionTicketDetails
          v-else
          :customFields="
            oldTicketSelected.settings.customFields
              ? oldTicketSelected.settings.customFields
              : []
          "
          :tags="oldTicketSelected.settings.tags"
          :integrations="
            oldTicketSelected.integrations ? oldTicketSelected.integrations : []
          "
          type="history-manage"
        />
      </a-col>
      <a-col v-if="!active_breakpoint.is_mobile" :span="1" style="height: 100%">
        <a-divider type="vertical" style="height: 100%; margin: 0px"
      /></a-col>
      <a-col
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
        :lg="{ span: 15 }"
        :xl="{ span: 15 }"
        :style="
          !active_breakpoint.is_mobile
            ? 'height: 100%'
            : 'height: calc(100% - 52px)'
        "
      >
        <SectionBoard
          v-if="ticket"
          :data="oldTicketSelected"
          :data-client="ticket.client"
          type="history-manage"
          class="modal__section-board"
        />
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import formatMixin from '@/app/shared/mixins/format'
import SectionBoard from '@/app/tickets/components/organisms/sections/SectionBoard'
import SectionTicketDetails from '@/app/tickets/components/molecules/SectionTicketDetails'
import ticketNumberFormatted from '@/app/shared/mixins/format'
import moment from 'moment'
moment.locale('es')

export default {
  name: 'ModalTicketDetails',
  mixins: [formatMixin, ticketNumberFormatted],
  components: {
    SectionBoard,
    SectionTicketDetails,
  },
  props: {
    ticket: {
      type: Object,
      required: false,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    async ticket() {
      if (!this.ticket) return
      await this.selectOldTicket(this.ticket)
      this.paginateMessages({
        target: 'history-manage',
        ticket: this.ticket,
        fromStart: true,
      })
    },
  },
  data: () => ({
    moment: moment,
    styles: {
      padding: 0,
      overflow: 'hidden',
    },
    stripeSection: null,
  }),
  mounted() {
    this.existStripe()
  },
  computed: {
    ...mapGetters([
      'oldTickets',
      'oldTicketSelected',
      'client',
      'isLoadingOldTickets',
      'active_breakpoint',
    ]),

    /**
     * Origen del ticket
     * @returns {Object | null}
     * @returns {String} origin
     * @returns {String} color - para el tag
     * @returns {String} title - titulo para el tooltip
     * @returns {Boolean} isExternal - si el ticket fue generado externamente o no
     */
    ticketOrigin() {
      let newObj = {
        isExternal: true,
      }
      if (!this.oldTicketSelected || !this.oldTicketSelected.channel)
        return null
      else {
        if (this.oldTicketSelected.channel.urlOrigin) {
          newObj.origin = this.oldTicketSelected.channel.urlOrigin
          newObj.title = 'Página web por donde se originó este ticket'
          newObj.color = 'orange'
        } else if (
          this.oldTicketSelected.channel.whatsappMassive &&
          this.oldTicketSelected.strategy
        ) {
          newObj.origin = 'Campaña'
          newObj.title = `Ticket generado por la campaña “${this.oldTicketSelected.strategy.name}”`
          newObj.color = 'green'
        } else newObj = null
      }
      return newObj
    },

    /**
     * @return {String} ancho del modal
     */
    widthModal() {
      const breakpoint = this.active_breakpoint.breakpoint
      // si es medium
      if (breakpoint === 'md') return '54em'
      else return '64em'
    },
    /**
     * @return {String} altura del cuerpo del modal
     */
    heightBody() {
      const breakpoint = this.active_breakpoint.breakpoint
      // si es extra small
      if (breakpoint === 'xs') return '35em'
      else return '40em'
    },
  },
  methods: {
    ...mapActions(['selectOldTicket', 'paginateMessages', 'listOldTickets']),
    handleOk() {
      this.$emit('close')
    },
    existStripe() {
      const stripe = document.getElementById('stripeSection') ? true : false
      this.stripeSection = stripe
    },
  },
}
</script>
<style lang="sass" scoped>
.modal__section-board
  height: 100%
  @include lg
    margin-left: -6%
  @include xl
    margin-left: -6%
  @include xxl
    margin-left: -6%
.modal__panel
  &__content
    height: 40em
    @include xs
      height: calc(35em - 54px)
      margin-left: -16px
      margin-right: -16px
      background-color: $gray_3
    @include sm
      margin-left: -16px
      margin-right: -16px
      background-color: $gray_3
    @include md
      margin-left: -16px
      margin-right: -16px
      background-color: $gray_3
</style>
