import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const initialState = () => ({
  company_fast_answers: [],
  paginate_fast_answers: { page: 1, total: 0 },
})

const state = initialState

const getters = {
  company_fast_answers: (state) => state.company_fast_answers,
  paginate_fast_answers: (state) => state.paginate_fast_answers,
}

const mutations = {
  /**
   * Añadir una respuesta rapida a la empresa
   * @param {*} state
   * @param {*} fastAnswer
   */
  ADD_COMPANY_FAST_ANSWER(state, fastAnswer) {
    state.company_fast_answers.push(fastAnswer)
  },
  /**
   * Actualiza una respuesta rapida de la empresa
   * @param {*} state
   * @param {*} fastAnswer
   */
  UPDATE_COMPANY_FAST_ANSWER(state, { idFastAnswer, fastAnswer }) {
    let foundFastAnswer = state.company_fast_answers.find(
      (item) => item._id === idFastAnswer
    )
    if (!foundFastAnswer) return
    foundFastAnswer.title = fastAnswer.title
    foundFastAnswer.body = fastAnswer.body
    foundFastAnswer.lines = fastAnswer.lines
  },
  /**
   * Setea las respuestas rapidas
   * @param {*} state
   * @param {Object[]} fastAnswers
   */
  SET_COMPANY_FAST_ANSWERS(state, fastAnswers) {
    state.company_fast_answers = fastAnswers
  },
  /**
   * Setea la paginacion de las respuesta rapidas
   * @param {*} state
   * @param {Object} pagination
   */
  SET_PAGINATE_FAST_ANSWERS(state, pagination) {
    state.paginate_fast_answers = pagination
  },
  /**
   * Actualiza el total de respuestas rapidas
   * @param {*} state
   * @param {Number} total
   */
  UPDATE_TOTAL_FAST_ANSWERS(state, { total, resetPage = false }) {
    state.paginate_fast_answers.total = total
    // si se resetea la pagina
    if (resetPage)
      state.paginate_fast_answers.page = state.paginate_fast_answers.page - 1
  },
  /**
   * Remueve una respuesta rapida
   * @param {*} state
   * @param {*} idFastAnswer
   */
  REMOVE_COMPANY_FAST_ANSWER(state, idFastAnswer) {
    state.company_fast_answers = state.company_fast_answers.filter(
      (fast_answer) => fast_answer._id !== idFastAnswer
    )
  },
}

const actions = {
  /**
   * Agregar una respuesta rapida
   * @param {*} context
   * @param {Object} args.fastAnswer
   * @param {String} args.title
   * @param {String} args.body
   * @returns
   */
  async addFastAnswer(context, fastAnswer) {
    try {
      const response = await axios.post(`${vari.UHR}/fast-answers`, fastAnswer)
      if (response.data.success) {
        context.commit('ADD_COMPANY_FAST_ANSWER', response.data.data)

        const total = context.getters.paginate_fast_answers.total
        context.commit('UPDATE_TOTAL_FAST_ANSWERS', total + 1)
        // si en la pagina ya no hay mas items vuelve a traer datos
        if (!context.getters.company_fast_answers.length) {
          const page = context.getters.paginate_fast_answers.page - 1
          context.dispatch('getFastAnswers', {
            page,
            module: 'general_settings',
          })
        }
      }
      return response.data
    } catch (err) {
      console.error(err)
      return err.response
    }
  },
  /**
   * Agregar una respuesta rapida
   * @param {*} context
   * @param {Object} args.fastAnswer
   * @param {String} args.title
   * @param {String} args.body
   * @returns
   */
  async updateFastAnswer(context, fastAnswer) {
    try {
      const response = await axios.put(
        `${vari.UHR}/fast-answers/${fastAnswer._id}`,
        fastAnswer
      )

      context.commit('UPDATE_COMPANY_FAST_ANSWER', {
        idFastAnswer: fastAnswer._id,
        fastAnswer: response.data.data,
      })
      return response.data
    } catch (err) {
      console.error(err)
      return err
    }
  },
  /**
   * Obtiene las respuestas rapidas
   * @param {*} context
   * @param {Number} args.page
   * @param {Number} args.limit
   * @param {String} args.search
   * @param {String} args.module - workspace, general_settings
   */
  async getFastAnswers(context, { page, limit = 10, search, module }) {
    try {
      const params = {
        page,
        limit,
        search,
        module,
      }
      const response = await axios.get(`${vari.UHR}/fast-answers/`, { params })
      context.commit('SET_COMPANY_FAST_ANSWERS', response.data.data.data)
      const pagination = { ...response.data.data }
      delete pagination.data
      context.commit('SET_PAGINATE_FAST_ANSWERS', pagination)
    } catch (err) {
      console.error(err)
    }
  },
  /**
   * Elimina una respuesta rapida
   * @param {*} context
   * @param {Object} args.idFastAnswer
   * @returns
   */
  async deleteFastAnswer(context, idFastAnswer) {
    try {
      const response = await axios.delete(
        `${vari.UHR}/fast-answers/${idFastAnswer}`
      )
      context.commit('REMOVE_COMPANY_FAST_ANSWER', idFastAnswer)

      const total = context.getters.paginate_fast_answers.total
      context.commit('UPDATE_TOTAL_FAST_ANSWERS', total - 1)
      // si en la pagina ya no hay mas items vuelve a traer datos
      if (!context.getters.company_fast_answers.length) {
        const page = context.getters.paginate_fast_answers.page - 1
        context.dispatch('getFastAnswers', { page, module: 'general_settings' })
      }
      return response.data
    } catch (err) {
      console.error(err)
    }
  },
}

export default { state, getters, mutations, actions }
