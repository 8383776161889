/*global FB*/
import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const messengerMetaConnection = {
  api_version: 'messenger_v8',
  appId: process.env.VUE_APP_META_MESSENGER_CLIENT_ID,
  secret: process.env.VUE_APP_META_MESSENGER_CLIENT_SECRET_ID,
  version: process.env.VUE_APP_META_MESSENGER_VERSION,
}
const instagramMetaConnection = {
  api_version: 'instagram_v11',
  appId: process.env.VUE_APP_META_INSTAGRAM_CLIENT_ID,
  secret: process.env.VUE_APP_META_INSTAGRAM_CLIENT_SECRET_ID,
  version: process.env.VUE_APP_META_INSTAGRAM_VERSION,
}
const actions = {
  /**
   * Iniciar sesion en facebook
   * @returns {Promise<void>}
   */
  async msgrMetaLogin() {
    try {
      return new Promise((resolve, reject) => {
        const msgrPermissions = [
          'public_profile',
          'email',
          'pages_show_list',
          'pages_manage_metadata',
          'pages_messaging',
          'pages_read_engagement',
        ]

        window.FB_msgr.FB.init({
          appId: messengerMetaConnection.appId,
          cookie: true,
          xfbml: true,
          version: messengerMetaConnection.version,
        })
        window.FB_msgr.FB.login(
          (response) => {
            if (response.authResponse) {
              return resolve(response)
            } else {
              console.warn('User cancelled login or did not fully authorize.')
              return reject(response)
            }
          },
          {
            scope: msgrPermissions.join(','),
          }
        )
      })
    } catch (e) {
      console.error('[messengerModule][msgrMetaLogin]', e)
    }
  },

  /**
   * Iniciar sesion para fb solicitando permisos de instagram
   * @returns {Promise<void>}
   */
  async igMetaLogin() {
    try {
      return new Promise((resolve, reject) => {
        const igPermissions = [
          'public_profile',
          'email',
          'pages_show_list',
          'pages_manage_metadata',
          'pages_read_engagement',
          'pages_messaging',
          'instagram_basic',
          'instagram_manage_messages',
        ]
        window.FB_ig.FB.init({
          appId: instagramMetaConnection.appId,
          cookie: true,
          xfbml: true,
          version: instagramMetaConnection.version,
        })
        window.FB_ig.FB.login(
          (response) => {
            if (response.authResponse) {
              return resolve(response)
            } else {
              console.warn('User cancelled login or did not fully authorize.')
              return reject(response)
            }
          },
          {
            scope: igPermissions.join(','),
          }
        )
      })
    } catch (e) {
      console.error('[messengerModule][msgrMetaLogin]', e)
    }
  },

  /**
   * Obtener access token de usuario de larga duración
   * @param {String} context
   * @param {String} accessToken
   * @param {String} channel [messenger,instagram]
   * @returns {Promise<Object>}
   */
  async fbLongUserAccessToken(context, { accessToken, channel }) {
    try {
      let metaConnection = null
      if (channel === 'messenger') metaConnection = messengerMetaConnection
      else if (channel === 'instagram') metaConnection = instagramMetaConnection
      const metaAppId = metaConnection.appId
      const metaClientSecret = metaConnection.secret
      const url = `/oauth/access_token?grant_type=fb_exchange_token&client_id=${metaAppId}&client_secret=${metaClientSecret}&fb_exchange_token=${accessToken}`
      return new Promise((resolve, reject) => {
        FB.api(url, async (response) => {
          if (response.access_token) {
            return resolve(response)
          } else {
            return reject(response)
          }
        })
      })
    } catch (e) {
      console.error('[messengerModule][fbLongUserAccessToken]', e)
    }
  },
  /**
   * Obtener datos del usuario
   * TODO: Actualmente no se usa
   * @returns {Promise<void>}
   */
  async fbMe() {
    try {
      const url = `/me`
      return await FB.api(
        url,
        { fields: 'name,email,first_name,last_name,verified' },
        async (response) => {
          return response
        }
      )
    } catch (e) {
      console.error('[messengerModule][fbMe]', e)
    }
  },
  /**
   * Listar paginas de facebook vinculadas
   * @param {vuex}    context
   * @param {Object}  args
   * @param {String}  args.userId
   * @param {String}  args.userAccessToken
   * @return {Object} Response
   */
  async fbAccounts(context, { userId, userAccessToken }) {
    try {
      const fieldsRequired = ['name', 'access_token', 'picture']
      const url = `${userId}/accounts`
      return new Promise((resolve, reject) => {
        FB.api(
          url,
          {
            access_token: userAccessToken,
            fields: fieldsRequired.join(','),
          },
          async (response) => {
            if (response.data.length) {
              resolve(response)
            } else {
              reject(null)
            }
          }
        )
      })
    } catch (e) {
      console.error('[messengerModule][fbAccounts]', e)
    }
  },

  /**
   * Suscribir páginas de facebook a mi app de facebook
   * @param context
   * @param pageId
   * @param pageAccessToken
   * @returns {Promise<void>}
   */
  async getInstagramBusiness(context, { pageId, pageAccessToken }) {
    try {
      const url = `${pageId}`
      const fields = 'instagram_business_account'
      return new Promise((resolve) => {
        FB.api(
          url,
          'get',
          { access_token: pageAccessToken, fields },
          async (response) => {
            return resolve(response)
          }
        )
      })
    } catch (e) {
      console.error('[messengerModule][fbSubscribeApps]', e)
    }
  },

  /**
   * Suscribir páginas de facebook a mi app de facebook
   * @param context
   * @param pageId
   * @param pageAccessToken
   * @returns {Promise<void>}
   */
  async fbSubscribeApps(context, { pageId, pageAccessToken }) {
    try {
      const fieldsRequired = [
        'name',
        'picture',
        'messages',
        'message_reactions',
        'message_deliveries',
        'messaging_postbacks',
        'message_reads',
        'messaging_optins',
        'phone',
        'email',
      ]
      const url = `${pageId}/subscribed_apps`
      const fields = fieldsRequired.join(',')
      return new Promise((resolve, reject) => {
        FB.api(
          url,
          'post',
          { access_token: pageAccessToken, subscribed_fields: fields },
          async (response) => {
            if (response.success) {
              return resolve(response)
            } else {
              return reject(response)
            }
          }
        )
      })
    } catch (e) {
      console.error('[messengerModule][fbSubscribeApps]', e)
    }
  },
  /**
   * Listar páginas de facebook suscritas a mi app de facebook
   * TODO: Actualmente no se usa
   * @param context
   * @param pageId
   * @param pageAccessToken
   * @returns {Promise<void>}
   */
  async fbSubscribedApps(context, { pageId, pageAccessToken }) {
    try {
      const url = `${pageId}/subscribed_apps?access_token=${pageAccessToken}`
      return await FB.api(url, async (response) => {
        if (response.data.length) {
          return response
        } else {
          return null
        }
      })
    } catch (e) {
      console.error('[messengerModule][fbSubscribedApps]', e)
    }
  },
  /**
   * Desvincular página de facebook
   * @param context
   * @param pageId
   * @param pageAccessToken
   * @returns {Promise<void>}
   */
  async fbUnlinkPage(context, { pageId, pageAccessToken }) {
    try {
      const url = `${pageId}/subscribed_apps?access_token=${pageAccessToken}`
      return new Promise((resolve, reject) => {
        FB.api(url, 'delete', async (response) => {
          if (response.success) {
            resolve(response)
          } else {
            reject(response.error)
          }
        })
      })
    } catch (e) {
      console.error('[messengerModule][fbUnlinkPage]', e)
    }
  },
  /**
   * Desloguear
   * TODO: Actualmente no se usa
   */
  async fbLogout() {
    FB.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        FB.logout(function () {
          // this part just clears the $_SESSION var
          // replace with your own code
          console.log('fbLogout')
        })
      } else console.log('no connected')
    })
  },
  /**
   * Guarda las credenciales de una pagina de messenger
   */
  async saveFacebookPage(
    context,
    { channel, idFacebookPage, idBusinessIg, pageAccessToken, name, picture }
  ) {
    try {
      const response = await axios.post(
        `${vari.UHR}/admin/company/channels/${channel}`,
        {
          channel,
          idFacebookPage,
          pageAccessToken,
          idBusinessIg,
          name,
          picture,
        }
      )
      if (response.data === 'Credentials saved') {
        // para verficar los canales usados
        context.dispatch('getSimpleCompanyChannels')
        context.dispatch('getCompanyPricing', false)
      }
      return response
    } catch (error) {
      console.error(error)
      return error.response.data
    }
  },

  /**
   * Desvincular canal
   * @param {vuex}    context
   * @param {Object}  args
   * @param {String}  [args.channel]
   * @param {String}  args.channelCompanyId Id del canal conectado de la empresa
   * @param {String}  args.idFacebookPage Id de la página de facebook
   */
  async unsuscribeChannel(
    context,
    { channel, idFacebookPage, channelCompanyId }
  ) {
    try {
      const response = await axios.delete(
        `${vari.UHR}/admin/company/channels/instant`,
        {
          data: {
            channelCompanyId,
            idFacebookPage,
          },
        }
      )
      let updatedChannel = null
      if (response.data.success) {
        switch (channel) {
          case 'messenger':
            updatedChannel = {
              active: false,
              apiId: null,
              api_version: 'messenger_v8',
              form: {},
              msgPage: {},
            }
            break
          case 'instagram':
            updatedChannel = {
              active: false,
              apiId: null,
              api_version: 'instagram_v11',
              form: {},
              msgPage: {},
            }
            break

          case 'whatsapp':
            updatedChannel = {
              active: false,
            }
            break
        }
        updatedChannel.channelCompanyId = channelCompanyId
        // actualiza el valor active en los canales paginados
        context.commit('UPDATE_ACTIVE_COMPANY_CHANNEL', {
          channelCompanyId: updatedChannel.channelCompanyId,
          active: updatedChannel.active,
        })

        // Elimina el canal de la lista de canales simples
        context.commit(
          'DELETE_SIMPLE_COMPANY_CHANNEL',
          updatedChannel.channelCompanyId
        )
        // hace la peticion para verificar el permiso del canal
        context.dispatch('getCompanyPricing', false)

        context.dispatch('setAnalytics', {
          eventName: channel + '_unlink',
          extraData: { channel: channel },
        })
      }

      return response.data
    } catch (error) {
      console.error('error', error)
      return error
    }
  },
}

export default actions
