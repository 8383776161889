import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import compare from '@/app/shared/utils/compare'
import router from '@/router'

const initialState = () => ({
  menuItems: [],
  menuItemSelected: null,
  menuCollapse: false,
  isOpenSomePopupFooter: false, // si esta en algun popup del footer o no
})

const state = initialState

const getters = {
  menuItems: (state) => state.menuItems,
  menuItemSelected: (state) => state.menuItemSelected,
  menuCollapse: (state) => state.menuCollapse,
  isOpenSomePopupFooter: (state) => state.isOpenSomePopupFooter,
}

const mutations = {
  /** RESET MODULE */
  RESET_SIDEBAR_MODULE(state) {
    const iste = initialState()
    Object.keys(iste).forEach((key) => {
      state[key] = iste[key]
    })
  },

  /**
   * Setea si el menu está contraido o extendido
   * @param {*} state
   * @param {Boolean} menuCollapse Menu contraido o no
   */
  SET_MENU_COLLAPSE(state, menuCollapse) {
    if (menuCollapse === true) state.menuCollapse = true
    else state.menuCollapse = false
  },

  /**
   * Setea el menu para el sidebar
   * @param {*} state
   * @param {Array} menuItems Arreglo de objetos del menú
   */
  SET_MENU_ITEMS(state, menuItems) {
    state.menuItems = menuItems
  },

  /**
   * Setea el menú que se ha seleccionado
   * @param {*} state
   * @param {Object} menuItemSelected Objeto del mennú
   */
  SET_MENU_SELECTED(state, menuItemSelected) {
    state.menuItemSelected = menuItemSelected
  },

  /**
   * Remover modulos de menu agente
   * @param state
   * @constructor
   */
  REMOVE_MENU_AGENT(state) {
    const notAlloweds = [
      {
        route: 'workspace',
      },
      {
        route: 'calendar',
      },
    ]

    for (let menu of notAlloweds) {
      // Remover modulo que existe en menu
      state.menuItems = state.menuItems.filter(
        (item) => item.route != menu.route
      )
    }
  },
  /**
   * Setea si el usuario esta sobre algun popup del footer
   * @param {*} state
   * @param {Boolean} isOpenSomePopupFooter - valor de verdadero o falso
   */
  SET_IS_OPEN_SOME_POPUP_FOOTER(state, isOpenSomePopupFooter) {
    if (state.isOpenSomePopupFooter === isOpenSomePopupFooter) return

    state.isOpenSomePopupFooter = isOpenSomePopupFooter
  },
}

const actions = {
  /** Listar el menú según su tipo de usuario */
  async listMenu(context) {
    try {
      const response = await axios.get(`${vari.UHR}/menu`)
      const menuItems = response.data.items

      context.commit('SET_MENU_ITEMS', menuItems)
    } catch (error) {
      console.error('[menuModule][listMenu]', error)
    }
  },
  /**
   * Obtiene los detalles de una ruta
   * @param {Object} args
   * @param {String} [args.name] Buscar por nombre de la ruta
   * @param {String} [args.path] Buscar por path de la ruta
   * @returns {Object} { name, path, meta, component }
   */
  async getRouteLocal(context, { name, path }) {
    try {
      if ((!name && !path) || (name && path))
        throw 'only "name" or "path" required'
      let route = null
      if (name) route = router.options.routes.find((el) => el.name === name)
      else if (path)
        route = router.options.routes.find((el) => el.path === path)
      return route
    } catch (error) {
      console.error('[getRouteLocal]', error)
      return null
    }
  },

  /**
   * Setea el objeto del menú seleccionado
   * @param {*} context
   * @param {Object} menuItem Objeto del menu seleccionado
   * @param {String} menuItem.route Ruta del menuItem seleccionado
   */
  async selectMenu(context, menuItem) {
    if (router.history.current.meta.is_monitor) return // Evita cualquier redirección desde el visor

    context.dispatch('patchDisconnection')
    context.commit('SET_MENU_SELECTED', menuItem)

    if (
      !compare.isAllowedFor(context, ['admin', 'supervisor'], { literal: true })
    ) {
      context.dispatch('saveMenuSelected', {
        routeSelected: menuItem.route,
      })
    }
    if (!router.history.current.meta.is_workspace) {
      context.dispatch('selectTicket', null)
      context.dispatch('saveEventTicket', false)
      context.commit('SET_CLIENT', null)
    }
  },

  /**
   * Guarda el menú seleccionado en la bd
   * @param {*} context
   * @param {Object} args // Objeto a recibir
   * @param {String} args.routeSelected Ruta del menuItem seleccionado
   * @param {Object} args.profile // Objeto del perfil del usaurio actual
   */
  async saveMenuSelected(context, { routeSelected }) {
    try {
      let menuItem = context.getters.menuItems.find(
        (item) => item.route === routeSelected
      )
      if (!menuItem) {
        let title = null
        switch (routeSelected) {
          case 'password':
            title = 'cambio de contraseña'
            break
          case 'profile':
            title = 'perfil'
            break
          case 'notifications':
            title = 'notificaciones'
            break
          default:
            return
        }
        menuItem = { route: routeSelected, title }
      }

      const startedInMenu_at = new Date()
      axios.put(`${vari.UHR}/menu/select`, {
        menu: menuItem.title,
        startedInMenu_at,
      })
      // context.dispatch('emitMenuSelected', {
      //   routeSelected,
      //   profile,
      //   startedInMenu_at,
      // })
    } catch (error) {
      console.error('[menuModule][saveMenuSelected]', error)
    }
  },
  /**
   * Emitir el evento del menú seleccionado
   * @param {*} context
   * @param {Object} args // Objeto a recibir
   * @param {String} args.routeSelected Ruta del menuItem seleccionado
   * @param {Object} args.profile // Objeto del perfil del usaurio actual
   * @param {Date} args.startedInMenu_at // Objeto del perfil del usaurio actual
   */
  async emitMenuSelected(
    context,
    { routeSelected, profile, startedInMenu_at = new Date() }
  ) {
    try {
      if (!this._vm.$socket) return
      let menuItem = context.getters.menuItems.find(
        (item) => item.route === routeSelected
      )
      if (!menuItem) {
        if (routeSelected === 'password')
          menuItem = { route: routeSelected, title: 'cambio de contraseña' }
        else return
      }
      this._vm.$socket.emit('server:update:menu:selected', {
        idCompany: profile.company.companyId,
        userId: profile.userId,
        menuSelected: menuItem.title,
        startedInMenu_at,
      })
    } catch (error) {
      console.error('[menuModule][emitMenuSelected]', error)
    }
  },
  /**
   * Setea al menu como comprimido o no y lo guarda en el storage
   * @param {*} context
   * @param {Boolean} menuCollapse comprimido o no
   */
  async setMenuCollapse(context, menuCollapse) {
    try {
      context.commit('SET_MENU_COLLAPSE', menuCollapse)
      localStorage.setItem('collapse', menuCollapse)
    } catch (error) {
      console.error('[menuModule][setMenuCollapse]', error)
    }
  },
  /** Obtiene el valor del storage y lo setea */
  async getMenuCollapse(context) {
    try {
      const menuCollapse =
        localStorage.getItem('collapse') === 'true' ? true : false
      context.commit('SET_MENU_COLLAPSE', menuCollapse)
    } catch (error) {
      console.error('[menuModule][getMenuCollapse]', error)
    }
  },
  /** Elimina el estado del menu como comprimido del storage */
  async revokeMenuCollapse(context) {
    try {
      context.commit('SET_MENU_COLLAPSE', false)
      localStorage.removeItem('collapse')
    } catch (error) {
      console.error('[menuModule][revokeMenuCollapse]', error)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
