<template>
  <section class="section">
    <header-module
      title="Mis productos"
      text-before-link="Crea y administra los productos de tu empresa para asignarlos a tus tickets."
      text-after-link="si deseas conocer más."
      link="https://www.securitec.pe/docs/ticker/productos-ventas/creacion-administracion-productos/"
    />
    <table-filters
      :columns="columns"
      :onMoreFilters="handleShowFilters"
      :onOk="handleShowAddDrawer"
      okText="Agregar producto"
      @onChangeCol="handleChangeColumn"
    >
      <template slot="filters">
        <!--Buscador -->
        <a-input-search
          placeholder="Buscar por ID, nombre o marca"
          class="section__item"
          v-model="filters.search"
          @search="handleFilterProducts"
        />
        <!--Filtro por estado-->
        <a-select
          v-model="filters.status"
          placeholder="Selecciona"
          class="section__item mrg-left-12"
          @change="handleFilterProducts"
        >
          <a-select-option value="all">Todos los estados</a-select-option>
          <a-select-option value="enable">Activos</a-select-option>
          <a-select-option value="disable">Inactivos</a-select-option>
        </a-select>
      </template>
    </table-filters>
    <table-products
      :data-source="company_products"
      :columns="showColumns"
      @onShowUpdate="handleShowUpdateDrawer"
      :page="filters.page"
      :total="paginate_company_products.total"
      :loading="loadingTable"
      @onPaginate="handleSetPaginate"
      @onShowDelete="showDeleteConfirm"
      @onChangeStatus="showStatusConfirm"
    />
    <product-drawer
      :visible="productDrawer.visible"
      :values="{ ...productDrawer.values }"
      :title="productDrawer.title"
      @onClose="productDrawer.visible = false"
      :onSaveChanges="handleSaveChanges"
      @onLoading="handleLoadtable"
      :okText="productDrawer.okText"
      :successMessage="productDrawer.message"
    />
    <filter-drawer
      :visible="filters.visible"
      :filters="{ ...filters }"
      @onClose="filters.visible = false"
      @onSetFilters="handleSetFilters"
      @onLoading="handleLoadtable"
    />
  </section>
</template>

<script>
import TableProducts from '@/app/products/components/organisms/TableProducts'
import ProductDrawer from '@/app/products/components/drawers/ProductDrawer'
import FilterDrawer from '@/app/products/components/drawers/FilterDrawer'
import transformWordGender from '@/app/shared/mixins/transform'
import HeaderModule from '@/app/shared/components/headers/HeaderModule'
import TableFilters from '@/app/shared/components/molecules/TableFilters'
import attemptMixin from '@/app/shared/mixins/attempt'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'SectionProducts',
  components: {
    TableProducts,
    ProductDrawer,
    FilterDrawer,
    HeaderModule,
    TableFilters,
  },
  data: () => ({
    moment,
    productDrawer: {
      visible: false,
      values: {},
      title: 'Agregar producto',
      type: 'add',
      okText: 'Agregar producto',
      message: 'exitoso',
    },
    columns: [
      {
        title: 'ID',
        dataIndex: 'code',
        key: 'code',
        fixed: 'left',
        width: '84px',
        checked: true,
        disabled: true,
        scopedSlots: { customRender: 'code' },
      },
      {
        title: 'Producto',
        dataIndex: 'name',
        key: 'name',
        scopedSlots: { customRender: 'name' },
        width: '20em',
        checked: true,
        disabled: true,
      },
      {
        title: 'Marca',
        dataIndex: 'brand',
        key: 'brand',
        checked: false,
        disabled: false,
        width: '12em',
        ellispsis: true,
      },
      {
        title: 'Precio',
        dataIndex: 'price',
        key: 'price',
        scopedSlots: { customRender: 'price' },
        checked: true,
        disabled: false,
        width: '8em',
      },
      {
        title: 'Descuento máximo',
        dataIndex: 'maximum_discount',
        key: 'maximum_discount',
        scopedSlots: { customRender: 'maximum_discount' },
        checked: false,
        disabled: false,
        width: '11em',
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'created_at',
        key: 'created_at',
        ellipsis: true,
        width: '16em',
        scopedSlots: { customRender: 'created_at' },
        checked: true,
        disabled: false,
      },
      {
        title: 'Estado',
        dataIndex: 'active',
        key: 'active',
        width: '8em',
        scopedSlots: { customRender: 'active' },
        fixed: 'right',
        default: true,
      },
      {
        title: 'Acciones',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        fixed: 'right',
        default: true,
        width: '11em',
      },
    ],
    filters: {
      search: '',
      page: 1,
      status: 'all',
      visible: false,
      customFields: [],
    },
    loadingTable: false,
  }),
  mixins: [transformWordGender, attemptMixin],
  created() {
    this.intervalAttempt(async () => {
      if (!this.company) throw 'Not company found'
      this.handleFilterProducts()
    })
  },
  mounted() {
    if (this.fields_company_products)
      this.setFieldsToColumns(this.fields_company_products)
  },
  watch: {
    fields_company_products: {
      handler(val) {
        if (val) this.setFieldsToColumns(val)
      },
    },
  },
  computed: {
    ...mapGetters([
      'company_products',
      'paginate_company_products',
      'fields_company_products',
      'company',
    ]),
    /**
     * @returns {Array} columns
     */
    showColumns() {
      return this.columns.filter((column) => column.checked || column.default)
    },
    /**
     * Palabra 'segura' segun el genero
     * @returns {String}
     */
    wordSure() {
      return this.profile && this.profile.gender
        ? this.transformWordGender('segura', this.profile.gender)
        : 'segur@'
    },
  },
  methods: {
    ...mapActions([
      'addProduct',
      'updateProduct',
      'getCompanyProducts',
      'deleteProduct',
      'updateStatusProduct',
      'profile',
    ]),
    /** Muestra el drawer de crear producto*/
    handleShowAddDrawer() {
      this.productDrawer.visible = true
      this.productDrawer.title = 'Agregar producto'
      this.productDrawer.type = 'add'
      this.productDrawer.okText = 'Agregar producto'
      this.productDrawer.values = {}
      this.productDrawer.message = `Se agregó un producto`
    },
    /**
     * Ejecuta el cambio en Columnas
     * @param {Boolean} checked
     * @param {String} key - id de la columna
     */
    handleChangeColumn(checked, key) {
      const column = this.columns.find((col) => col.key === key)
      if (column === undefined) return
      column.checked = checked
    },
    /**
     * Remueve las columnas que vienen por defecto
     */
    removeDefault(columns) {
      return columns.filter((column) => !column.default)
    },
    /**
     * Abre el modal de editar y setea valores
     * @param {Object} product - producto a editarse
     * @param {String} product.name
     * @param {String} product.description
     * @param {String} product.price
     * @param {Array} product.pictures
     * @param {Array} product.customFields
     */
    handleShowUpdateDrawer(product) {
      this.productDrawer.visible = true
      this.productDrawer.values = { ...product }
      this.productDrawer.title = 'Editar producto'
      this.productDrawer.type = 'update'
      this.productDrawer.okText = 'Editar producto'
      this.productDrawer.message = `Se editó el producto "${product.name}"`
    },
    /**
     * Retorna la función a ejecutar segun el tipo de drawer
     * @param {Object} product - producto a editarse
     * @param {String} product.name
     * @param {String} product.description
     * @param {String} product.price
     * @param {Array} product.pictures
     * @param {Array} product.customFields
     * @returns {Function}
     */
    handleSaveChanges(product) {
      if (this.productDrawer.type === 'update')
        return this.updateProduct(product)
      else return this.addProduct(product)
    },
    /**
     * Setear paginacion de los productos
     * @param {Number} page
     */
    handleSetPaginate(page) {
      this.filters.page = page
      this.handleFilterProducts()
    },
    /**
     * Filtrar los productos
     */
    async handleFilterProducts() {
      this.loadingTable = true
      await this.getCompanyProducts({
        page: this.filters.page,
        search: this.filters.search,
        status: this.filters.status,
        customFields: this.filters.customFields,
      })
      this.loadingTable = false
    },
    /**
     * Mostrar el drawer de filtros
     */
    handleShowFilters() {
      this.filters.visible = true
    },
    /**
     * Setear valores a los filtros
     * @param {Boolean} status
     */
    handleSetFilters(filters) {
      this.filters.status = filters.status
      this.filters.page = filters.page
      this.filters.search = filters.search
      this.filters.customFields = filters.customFields
    },
    /**
     * Cargar la tabla de productos
     * @param {Boolean} loading
     */
    handleLoadtable(loading) {
      this.loadingTable = loading
    },
    /**
     * Mostrar la confirmacion para eliminar
     * @param {String} idProduct
     */
    showDeleteConfirm(idProduct) {
      const self = this
      this.$confirm({
        title: `¿Estás ${self.wordSure} de eliminar este producto?`,
        content: (
          <div>
            <p>
              Recuerda que al eliminar un producto, pasará lo siguiente:
              <br />
            </p>
            <ul class="confirm__list">
              <li class="mrg-bottom-4">
                Los tickets con ventas y asociaciones que tengan estos productos
                mantendrán ese registro como informativo.
              </li>
              <li>
                No se podrán usar estos productos para ventas y asociaciones a
                partir de ahora.
              </li>
            </ul>
          </div>
        ),
        okText: 'Eliminar',
        okType: 'danger',
        cancelText: 'Cancelar',
        async onOk() {
          self.handleLoadtable(true)
          const response = await self.deleteProduct(idProduct)
          if (response.success) self.$message.success(response.details)
          else self.$message.error(response.details)
          self.handleLoadtable(false)
        },
      })
    },
    /**
     * Mostrar la confirmacion de estado
     * @param {String} idProduct
     * @param {Boolean} status
     */
    async showStatusConfirm(idProduct, status) {
      if (status) return await this.handleUpdateStatus(idProduct, status)
      const self = this
      const wordStatus = status ? 'activar' : 'inactivar'
      this.$confirm({
        title: `¿Estás ${self.wordSure} de ${wordStatus} este producto?`,
        content: (
          <div>
            <p>
              Recuerda que al inactivar un producto, pasará lo siguiente:
              <br />
            </p>
            <ul class="confirm__list">
              <li class="mrg-bottom-4">
                Los tickets con ventas y asociaciones que tengan estos productos
                mantendrán ese registro como informativo.
              </li>
              <li>
                No se podrán usar estos productos para ventas y asociaciones a
                partir de ahora.
              </li>
            </ul>
          </div>
        ),
        okText: self.capitalizeFirstLetter(wordStatus),
        cancelText: 'Cancelar',
        async onOk() {
          await self.handleUpdateStatus(idProduct, status)
        },
      })
    },
    /**
     * Convirtiendo la primera letra a capital
     * @param {String} string
     * @returns {String} si la palabra fuera fresa devolvería Fresa
     */
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    setFieldsToColumns(fields) {
      let lastIndex = 0
      const keyColumns = this.columns.map((column) => column.key)
      fields.forEach((field) => {
        lastIndex = this.columns.length - 2
        const obj = {
          title: field.name,
          dataIndex: 'customFields.' + field._id,
          key: field._id,
          checked: false,
          disabled: false,
          width: '12em',
          ellipsis: true,
          customRender: (value) => {
            if (value === undefined) return ''
            else if (field.type === 'dropdown')
              return value.map((val, index) =>
                value.length - 1 !== index ? `${val.name}, ` : `${val.name}`
              )
            else if (field.type === 'date') return moment(value).format('LLL')
            else return value
          },
        }
        // evitar columnas repetidas
        if (!keyColumns.includes(field._id))
          this.columns.splice(lastIndex, 0, obj)
      })
    },
    /**
     * Actualiza el estatus del producto
     * @param {String} idProduct
     * @param {String} status
     */
    async handleUpdateStatus(idProduct, status) {
      this.handleLoadtable(true)
      const response = await this.updateStatusProduct({ idProduct, status })
      if (response.success) this.$message.success(response.details)
      else this.$message.error(response.details)
      this.handleLoadtable(false)
    },
  },
}
</script>

<style lang="sass" scoped>
.section
  padding: 20px 24px
.section__item
  width: 19em
.confirm__list
  padding-left: 20px
</style>
