var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('a-layout',{style:(_vm.mainStyles)},[(_vm.showMenu)?_c(_vm.currentMenu.component,{tag:"component"}):_vm._e(),_c('a-layout-content',{class:{
        'margin--no-collapsed': _vm.showMenu && _vm.currentMenu.name === 'sidebar',
        'margin--fixed': _vm.hasNavbarFixed,
      },style:(_vm.contentStyles)},[(_vm.visibleNoConnection)?_c('NoConnection',{class:{
          'width--collapsed': _vm.menuCollapse,
          'width--no-collapsed': !_vm.menuCollapse,
        },attrs:{"text":"Se perdió tu conexión a internet","icon":"internet"},on:{"handleClose":_vm.handleCloseConnection}}):_vm._e(),(_vm.visibleNoAPI)?_c('div',_vm._l((_vm.errorChannelsConnection),function(errorChannelConnection){return _c('NoConnection',{key:errorChannelConnection.channel,class:{
            'width-collapsed': _vm.menuCollapse,
            'width-no-collapsed': !_vm.menuCollapse,
          },attrs:{"text":_vm.textErrorChannelConnection(
              errorChannelConnection.channel,
              errorChannelConnection.api_status
            ),"url":errorChannelConnection.api_status,"icon":"api"},on:{"handleClose":_vm.handleCloseAPI}})}),1):_vm._e(),_c('router-view')],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"id":"sounds"}},[_c('audio',{ref:"sound_message",attrs:{"src":require("@/app/commons/assets/sounds/message.ogg")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"id":"sounds_notification"}},[_c('audio',{ref:"sound_notification",attrs:{"src":require("@/app/commons/assets/sounds/notification.ogg")}})]),_c('AppModals')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }