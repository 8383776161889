import Lines from '@/app/lines/views/Lines'
const VUE_APP_NAME = process.env.VUE_APP_NAME

export default [
  {
    path: '/queues',
    name: 'queues',
    component: Lines,
    meta: {
      requiresAuth: true,
      requiresType: ['admin', 'supervisor'],
      title: `Colas | ${VUE_APP_NAME}`,
    },
  },
]
