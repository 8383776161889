const actions = {
  /**
   * Carga los datos iniciales para el usuario segun su tipo de usuario
   * @param {*} context
   * @param {Object} args
   * @param {String} args.typeUser Tipo de usuario
   */
  async getInitialData(context, { typeUser }) {
    try {
      context.dispatch('getMenuCollapse')
      context.dispatch('checkUpdates')
      switch (typeUser) {
        case 'agent': {
          await context.dispatch('getAgentData')
          break
        }
        case 'supervisor': {
          await context.dispatch('getSupervisorData', false)
          break
        }
        case 'admin': {
          await context.dispatch('getAdmintData', false)
          break
        }
        case 'supervisor_agent': {
          await context.dispatch('getSupervisorData', true)
          break
        }
        case 'admin_agent': {
          await context.dispatch('getAdmintData', true)
          break
        }
        case 'securitec': {
          break
        }
      }
    } catch (error) {
      console.error('[loadDataModule][getInitialData]', error)
    }
  },

  /**
   * Carga los datos del agente
   */
  async getAgentData(context) {
    try {
      await context.dispatch('getCompany') // trae los datos de la empresa
      await context.dispatch('getCompanyPricing') // trae los datos del plan actual de company
      context.dispatch('getChannels') //obtiene todos los canales
      context.dispatch('getAssignedTemplates') // trae las plantillas de la cola
      context.dispatch('getAssignedLines') // trae las Colas asignadas
      context.dispatch('listCompanyLines') // trae las Colas de la empresa
      context.dispatch('listTicketsUnreads') // trae las ids de los tickets sin leer
      context.dispatch('listAgentsAsigned') // trae las agentes asignados a la misma cola
      context.dispatch('getListCustomFields') // trae los datos de los campos personalizados
      context.dispatch('getNews', 'feature') // trae los datos de las noticias o novedades
      context.dispatch('getNews', 'info') // trae los datos de las noticias informativas
      context.dispatch('getCountries') // Lista los países con su código de país
      context.dispatch('getNotifications') //obtener las notificaciones
      context.dispatch('getCompanyProducts', {
        page: 1,
        search: '',
        status: '',
        customFields: [],
      })
    } catch (error) {
      console.error('[loadDataModule][getAgentData]', error)
    }
  },

  /**
   * Carga los datos del administrador
   * @param {vuex} context
   * @param {Boolean} agentMode
   */
  async getAdmintData(context, agentMode) {
    try {
      await context.dispatch('getCompany') // trae los datos de la empresa
      await context.dispatch('getCompanyPricing', false) // trae los datos del plan actual de company
      context.dispatch('verifyPlanExpired') // Verifica si está cerda de expirar el plan
      context.dispatch('listSupervisors') // trae los supervisores
      context.dispatch('listRoles') // trae los datos de los roles disponibles
      // context.dispatch('getNotificationsPhones') // trae los datos de la empresa
      // context.dispatch('getListCustomFields') // trae los datos de los campos personalizados
      // context.dispatch('listAccounts', {}) // trae las Cuentas de Usuarios
      // context.dispatch('getAccountsUsers')
      context.dispatch('getChannels') //obtiene todos los canales
      context.dispatch('listCompanyLines') // trae las Colas de la empresa
      context.dispatch('getNews', 'feature') // trae los datos de las noticias o novedades
      context.dispatch('getNews', 'evaluation') // trae los datos de las noticias o novedades
      context.dispatch('getNews', 'welcome') // trae los datos de las noticias o novedades
      context.dispatch('getNews', 'info') // trae los datos de las noticias informativas
      context.dispatch('getCountries') // Lista los países con su código de país

      context.dispatch('getPricings') // trae los datos de los planes
      context.dispatch('getProducts') // trae todas las bolsas
      context.dispatch('getAccountsUsers') // trae los usuarios agentes
      context.dispatch('getAlerts') // trae todas las alertas para mostrarlas en un modal

      if (agentMode) {
        context.dispatch('getAssignedLines') // trae las Colas
        context.dispatch('listTicketsUnreads', true) // trae las ids de los tickets sin leer
        context.dispatch('getAssignedTemplates') // trae las plantillas de la cola
        context.dispatch('listAgentsAsigned') // trae las agentes asignados a la misma cola
        context.dispatch('getNotifications') //obtener las notificaciones
        context.dispatch('getCompanyProducts', {
          page: 1,
          search: '',
          status: '',
          customFields: [],
        })
      }
    } catch (error) {
      console.error('[loadDataModule][getAdmintData]', error)
    }
  },

  /**
   * Carga los datos del supervisor
   * @param {vuex} context
   * @param {Boolean} agentMode
   */
  async getSupervisorData(context, agentMode) {
    try {
      await context.dispatch('getCompany') // trae los datos de la empresa
      await context.dispatch('getCompanyPricing') // trae los datos del plan actual de company
      // context.dispatch('listAccounts', {}) // trae las Cuentas de Usuarios
      // context.dispatch('getAccountsUsers')
      context.dispatch('listCompanyLines') // trae las Colas de la empresa
      context.dispatch('getNews', 'feature') // trae los datos de las noticias o novedades
      context.dispatch('getNews', 'info') // trae los datos de las noticias informativas
      context.dispatch('getCountries') // Lista los países con su código de país

      if (agentMode) {
        context.dispatch('getAssignedLines') // trae las Colas a las que se encuentra asignado
        context.dispatch('listTicketsUnreads') // trae las ids de los tickets sin leer
        context.dispatch('getAssignedTemplates') // trae las plantillas de la cola
        context.dispatch('listAgentsAsigned') // trae las agentes asignados a la misma cola
      }
    } catch (error) {
      console.error('[loadDataModule][getSupervisorData]', error)
    }
  },
}

export default actions
