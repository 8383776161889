<template>
  <a-list
    id="list_my-channels"
    item-layout="horizontal"
    :data-source="dataSource"
    class="list"
    :locale="{
      emptyText: 'No se han encontrado canales de acuerdo a los filtros.',
    }"
    :pagination="pagination"
    :loading="loading"
  >
    <list-item-channel
      slot="renderItem"
      slot-scope="channel"
      :channel="channel"
      @onOpenDetails="showDetails"
      @onLoading="(reason) => $emit('onLoading', reason)"
    >
    </list-item-channel>
    <!-- Modales -->
    <template v-if="dataSource && !!dataSource.length">
      <!-- Conectar WhatsApp -->
      <ModalStepsWBA
        :visible="modalStepsWBA.visible"
        @onClose="setStepsWBAModal(false)"
      />
      <!-- Ver datos solicitados -->
      <ModalDetailsRequest
        :visible="visibleModalRequest"
        :details="channelSelected"
        @onCancel="visibleModalRequest = false"
        @onRestart="handleReturnConnect"
      />
    </template>
  </a-list>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ListItemChannel from '@/app/channels/components/molecules/ListItemChannel'
import ModalStepsWBA from '@/app/channels/components/whatsapp/modals/ModalStepsWBA.vue'
import ModalDetailsRequest from '@/app/channels/components/whatsapp/modals/ModalDetailsRequest.vue'

export default {
  name: 'ListMyChannels',
  props: {
    dataSource: { type: Array, required: true }, // channels[]
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    page: {
      type: Number,
      required: false,
      default: 1,
    },
    status: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    ListItemChannel,
    ModalStepsWBA,
    ModalDetailsRequest,
  },
  data: () => ({
    channelSelected: null,
    visibleModalRequest: false,
  }),
  computed: {
    ...mapGetters(['modalStepsWBA']),
    /**
     * @returns {Object} - paginación para la tabla
     */
    pagination() {
      return {
        hideOnSinglePage: true,
        defaultPageSize: 12,
        size: 'default',
        total: this.total || 0,
        current: this.page,
        onChange: this.handleChangeList,
        showTotal: (total) => (
          <span>
            <span class="heading-h6">{total}</span>{' '}
            {total > 1 ? 'canales ' + this.status : 'canal conectado'}
          </span>
        ),
      }
    },
  },
  methods: {
    ...mapActions(['setAnalytics']),
    ...mapMutations(['SET_STEPS_WBA_MODAL']),
    /**
     * MUestra los detalles de whatsapp
     * @param {Object} channelObj
     */
    showDetails(channelObj) {
      this.channelSelected = channelObj
      this.visibleModalRequest = true
    },
    /**
     * Vuelve a conectar
     */
    handleReturnConnect() {
      this.visibleModalRequest = false
      this.SET_STEPS_WBA_MODAL(true)
    },
    /**
     * @param {Boolean} visibleModal
     */
    setStepsWBAModal(visibleModal) {
      this.SET_STEPS_WBA_MODAL(visibleModal)
      if (!visibleModal) {
        this.setAnalytics({ eventName: 'modal_wab_request_close' })
      }
    },
    /**
     * Cambia la paginacion de la lista
     * @param {Number} pagination
     */
    handleChangeList(pagination) {
      this.$emit('onPaginate', pagination)
    },
  },
}
</script>

<style lang="sass" scoped>
.list
</style>
<style lang="sass">


#list_my-channels
  .list_info
    padding-top: 10px
    padding-bottom: 10px
    font-weight: normal
  .ant-spin-nested-loading
    border: 1px solid $gray_5
    padding: 16px
    border-radius: 4px
    height: calc( 100vh - 18rem )
    overflow: scroll
    &::-webkit-scrollbar
      width: 10px
      height: 12px
    &::-webkit-scrollbar-track
      background: transparent
      border-left: solid 5px transparent
    &::-webkit-scrollbar-thumb
      border-radius: 8px
      box-shadow: inset 0 0 10px 10px #D4D4D6
      border: solid 3px transparent
    &::-webkit-scrollbar-thumb:hover
      box-shadow: inset 0 0 10px 10px #D4D4D6
</style>
