const mutations = {
  /**
   *
   * @param {*} state
   * @param {Object[]} contacts
   */
  SET_CONTACTS(state, contacts) {
    state.contacts = contacts
  },
  /**
   *
   * @param {*} state
   * @param {Number} contacts_page
   */
  SET_CONTACTS_PAGE(state, contacts_page) {
    state.contacts_page = contacts_page
  },
  /**
   *
   * @param {*} state
   * @param {Number} contacts_pages_total
   */
  SET_CONTACTS_PAGES_TOTAL(state, contacts_pages_total) {
    state.contacts_pages_total = contacts_pages_total
  },
  SET_UPDATE_CONTACT(state, contact) {
    const indexContact = state.contacts.findIndex(
      (elem) => elem._id === contact._id
    )

    if (indexContact == -1) return
    const newContacts = state.contacts
    newContacts[indexContact] = contact
    state.contacts = newContacts
  },
  /**
   * Setea los campos personalizados al cliente o contacto
   * @param {*} state
   * @param {Object} args
   * @param {String} args.clientId
   * @param {Object} args.fields
   * @param {String} args.fields[idField] - key del objeto
   * @param {String|Array} args.fields[idField] - value del objeto
   */
  SET_FIELDS_TO_CONTACT(state, { clientId, fields }) {
    const indexContact = state.contacts.findIndex(
      (elem) => elem._id === clientId
    )

    if (indexContact == -1) return
    const newContacts = state.contacts
    newContacts[indexContact].settings.customFields = fields
    state.contacts = newContacts
  },
}
export default mutations
