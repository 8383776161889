<template>
  <div
    id="header_chat"
    class="header-chat box-shadow-bottom align--end"
    :class="{ 'header-chat--loading': loading }"
  >
    <!-- Skeleton -->
    <template v-if="loading && !data">
      <a-skeleton
        :loading="loading"
        active
        :paragraph="{ rows: 1 }"
        class="header-skeleton"
      />
    </template>
    <!-- Con Datos -->
    <template v-else-if="data">
      <!-- Identicador del cliente -->
      <template v-if="!active_breakpoint.is_mobile">
        <a-row
          type="flex"
          align="middle"
          class="header__container-row1 justify--between"
          :class="{
            'mrg-bottom-8': data.status !== 'active',
            'mrg-bottom-2': data.status === 'active',
          }"
        >
          <div class="header__container-names">
            <h6
              class="
                semibold-14
                mrg-bottom-0
                header__names
                text--left
                mrg-right-12
              "
            >
              {{ nameClient }}
            </h6>
          </div>
          <!-- Botones de acción -->
          <div
            class="options"
            v-if="data.status === 'active' && !profileRemote"
          >
            <div class="iconned">
              <!-- favorito -->
              <a-tooltip>
                <!-- tooltip -->
                <template slot="title"> Marcar favorito </template>
                <a-rate
                  v-model="starValue"
                  :count="1"
                  class="option star"
                  :style="`font-size: ${sizeIcons}px; margin-top: -1px;`"
                  @change="markFavorite(data._id, data.settings.starred)"
                >
                </a-rate>
              </a-tooltip>
              <!-- transferencia -->
              <a-dropdown :trigger="['click']" placement="bottomRight">
                <a-tooltip>
                  <template slot="title"> Transferir ticket </template>
                  <a-icon
                    class="icon icon-option"
                    type="swap"
                    :style="`font-size: ${sizeIcons}px; color: #262a31;`"
                    :data-test="DATA_TEST.TICKET.TRANSFER.SHOW_OPTIONS_ICON"
                    @click="(e) => e.preventDefault()"
                  />
                </a-tooltip>
                <!-- menú -->
                <a-menu slot="overlay">
                  <!-- Transferencia única a agente -->
                  <a-menu-item key="0">
                    <a
                      @click="showModalTransfer"
                      :data-test="
                        DATA_TEST.TICKET.TRANSFER.SHOW_AGENT_SEARCH_MODAL
                      "
                      >Transferir a un agente</a
                    >
                  </a-menu-item>
                  <!-- Transferencia única a Cola -->
                  <a-sub-menu key="sub1">
                    <span slot="title"><span>Transferir a cola</span></span>
                    <template v-if="filterCompanyLines.length">
                      <a-menu-item
                        v-for="(line, index) in filterCompanyLines"
                        :key="`item${index}`"
                        @click="confirmTransfer(line)"
                      >
                        {{ line.name }}
                      </a-menu-item>
                    </template>
                    <template v-else>
                      <a-menu-item>
                        <span>No tienes colas disponibles</span>
                      </a-menu-item>
                    </template>
                  </a-sub-menu>
                </a-menu>
              </a-dropdown>
              <!-- calendar-->
              <a-tooltip>
                <!-- tooltip -->
                <template slot="title"> Agendar evento </template>
                <i class="option icon-option" @click="showModalSchedule">
                  <svg
                    :width="sizeIcons"
                    :height="sizeIcons"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#262a31"
                  >
                    <path
                      d="M20.625 4.3125H16.6875V2.8125C16.6875 2.70937 16.6031 2.625 16.5 2.625H15.1875C15.0844 2.625 15 2.70937 15 2.8125V4.3125H9V2.8125C9 2.70937 8.91563 2.625 8.8125 2.625H7.5C7.39687 2.625 7.3125 2.70937 7.3125 2.8125V4.3125H3.375C2.96016 4.3125 2.625 4.64766 2.625 5.0625V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V5.0625C21.375 4.64766 21.0398 4.3125 20.625 4.3125ZM19.6875 19.6875H4.3125V10.7812H19.6875V19.6875ZM4.3125 9.1875V6H7.3125V7.125C7.3125 7.22813 7.39687 7.3125 7.5 7.3125H8.8125C8.91563 7.3125 9 7.22813 9 7.125V6H15V7.125C15 7.22813 15.0844 7.3125 15.1875 7.3125H16.5C16.6031 7.3125 16.6875 7.22813 16.6875 7.125V6H19.6875V9.1875H4.3125Z"
                    />
                  </svg>
                </i>
              </a-tooltip>
              <!-- tooltip -->
              <!-- cambiar de buzon -->
              <a-dropdown :trigger="['click']" placement="bottomRight">
                <a-tooltip>
                  <!-- tooltip -->
                  <template slot="title"> Cambiar de buzón </template>
                  <a-icon
                    class="icon icon-option"
                    type="inbox"
                    :style="`font-size: ${sizeIcons}px; color: #262a31;`"
                    @click="(e) => e.preventDefault()"
                  />
                </a-tooltip>
                <a-menu slot="overlay">
                  <a-menu-item
                    v-for="mailbox in filterMailBoxes"
                    :key="`Mailbox${mailbox.idMailbox}`"
                    @click="changeBox(mailbox, data._id)"
                  >
                    Cambiar a {{ mailbox.title }}
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              <!-- Marcar como no leído -->
              <a-tooltip>
                <!-- tooltip -->
                <template slot="title"> Marcar como no leido </template>
                <i
                  class="option icon-option"
                  @click="markUnread(data._id, data.mailboxId)"
                >
                  <svg
                    :width="sizeIcons"
                    :height="sizeIcons"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#262a31"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.38471 2.99786L2.38113 1L1 2.44003L1.89483 3.3323C1.35841 3.68964 1.00511 4.29898 1.00511 4.99063V18.9401C1.00511 20.0406 1.89967 20.9328 3.00317 20.9328H19.5458L21.6189 23L23 21.56L4.40025 3.01335L4.41578 2.99786H4.38471ZM3.55792 4.99065H3.00366V7.03321L8.17536 9.59489L3.55792 4.99065ZM12.5323 13.9394L12.4944 13.9582L3.00366 9.25712V18.9401H17.5473L12.5323 13.9394ZM20.9862 7.52853L14.2845 10.8477L15.7744 12.3337L20.9862 9.7524V17.5318L22.916 19.4565C22.9602 19.2918 22.9838 19.1187 22.9838 18.9401V8.76305L22.9848 8.76255L22.9838 8.76055V4.99063C22.9838 3.89005 22.0892 2.99786 20.9857 2.99786H6.41376L8.41183 4.99065H20.9862V7.52853Z"
                    />
                  </svg>
                </i>
              </a-tooltip>
            </div>
            <!-- Resolver ticket -->
            <a-button
              type="primary"
              class="btn-resolve"
              :data-test="DATA_TEST.TICKET.RESOLVE.OPEN_MODAL_BUTTON"
              @click="resolveTicketModal = true"
              >Resolver ticket</a-button
            >
          </div>
        </a-row>
        <!--Divider-->
        <a-divider class="header__divider" />
      </template>
      <!-- Siguiente fila de la cabecera -->
      <div class="header__tags display-flex justify--between align--end">
        <!-- Tags no editables -->
        <a-row
          type="flex"
          align="middle"
          class="flex-no-wrap"
          :style="{
            width: active_breakpoint.is_mobile ? 'calc(100% - 44px)' : '100%',
            height: active_breakpoint.is_mobile ? '46px' : 'auto',
          }"
        >
          <!-- Indentificador multicanal -->
          <a-tooltip
            v-for="(origin, index) in ticketOrigin"
            :key="`tag-origin${index}`"
            v-model="tooltips.ticketOrigin"
            placement="right"
            :title="origin.title"
            :trigger="triggerTooltip"
            @click="handleSelectTooltip('ticketOrigin')"
          >
            <!-- Se elimino el color de diferenciacion por canal :color="ticketOrigin.colo" -->
            <a-tag class="ticket-origin ellipsis display-inline">
              <social-icon
                v-if="origin.channel"
                :type="origin.channel"
                :size="12"
                :disabled="true"
              />
              {{ origin.origin }}
            </a-tag>
          </a-tooltip>
          <!-- Número del Ticket -->
          <a-tooltip
            :title="`Ticket: ${numberTicket}`"
            v-model="tooltips.numberTicket"
            placement="right"
            :trigger="triggerTooltip"
            @click="handleSelectTooltip('numberTicket')"
          >
            <a-tag class="ellipsis display-inline"
              >{{
                numberTicket.length > 9
                  ? `${numberTicket.slice(0, 9)}...`
                  : numberTicket
              }}
            </a-tag>
          </a-tooltip>
          <!-- Nombre del Buzón -->
          <a-tooltip
            v-if="data.mailboxName"
            :title="`Buzón ${data.mailboxName}`"
            v-model="tooltips.mailboxName"
            placement="right"
            :trigger="triggerTooltip"
            @click="handleSelectTooltip('mailboxName')"
          >
            <a-tag class="ellipsis display-inline">
              <a-icon type="inbox" />
              {{
                data.mailboxName.length > 12
                  ? `${data.mailboxName.slice(0, 12)}...`
                  : data.mailboxName
              }}
            </a-tag>
          </a-tooltip>

          <!-- Nombre de la Cola -->
          <a-tooltip
            :title="`Cola ${data.company.lineName}`"
            v-model="tooltips.lineName"
            placement="right"
            :trigger="triggerTooltip"
            @click="handleSelectTooltip('lineName')"
          >
            <a-tag class="ellipsis display-inline mrg-0">
              <i class="anticon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:height="'12'" v-bind:width="'12'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 22a3.001 3.001 0 002.83-2h8.67a4.5 4.5 0 100-9h-11a2.5 2.5 0 010-5h3.086L8.293 7.293l1.414 1.414L13.414 5 9.707 1.293 8.293 2.707 9.586 4H6.5a4.5 4.5 0 100 9h11a2.5 2.5 0 010 5H8.83A3.001 3.001 0 106 22zm-1-3a1 1 0 112 0 1 1 0 01-2 0zM18 2a3 3 0 110 6 3 3 0 010-6zm-1 3a1 1 0 112 0 1 1 0 01-2 0z"/></svg>
              </i>
              {{
                data.company.lineName.length > 18
                  ? `${data.company.lineName.slice(0, 18)}...`
                  : data.company.lineName
              }}
            </a-tag>
          </a-tooltip>
        </a-row>
        <dropdown-actions-ticket
          v-if="showDropdownActions"
          :show-menu-agents="true"
          :show-menu-favorite="true"
          :show-menu-schedule="true"
          :is-favorite="data.settings.starred"
          :data-source-mailboxes="filterMailBoxes"
          @onMarkFavorite="markFavorite(data._id, data.settings.starred)"
          @onSchedule="showModalSchedule"
          @onUnread="markUnread(data._id, data.mailboxId)"
          @onTransferToAgent="showModalTransfer"
          @onTransferToLine="modalTransferLine.visible = true"
          @onMoveMailbox="(mailbox) => changeBox(mailbox, data._id)"
        >
          <div class="header__icon-container">
            <i class="anticon header__icon--more">
              <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 5a2 2 0 104 0 2 2 0 00-4 0zm2 9a2 2 0 110-4 2 2 0 010 4zm0 7a2 2 0 110-4 2 2 0 010 4z" fill="currentColor"/></svg>
            </i>
          </div>
        </dropdown-actions-ticket>
      </div>
      <template v-if="showTags">
        <a-divider class="header__divider" />
        <tags-container
          :tags-selected="data.settings.tags"
          :addTags="addTags"
        />
      </template>
      <!--Modal de comentario-->
      <a-modal
        title="Resolver ticket"
        v-model="resolveTicketModal"
        width="480px"
        height="327px"
        centered
        @cancel="cancelResolveTicket"
        class="resolver-ticket-modal"
      >
        <p class="resolver-ticket-modal__description">
          Escribe un breve comentario sobre la gestión de este ticket para poder
          resolverlo.
        </p>
        <label class="resolver-ticket-modal__label">Comentario</label>
        <a-form-model-item>
          <a-textarea
            class="textarea"
            style="max-heigth: 54px"
            placeholder="Escribe aquí, tienes caracteres limitados..."
            v-model="resolveComment"
            auto-size
            :data-test="DATA_TEST.TICKET.RESOLVE.COMMENT_TEXT_AREA"
            :maxLength="maxComment"
          />
          <span class="counter"
            >{{ resolveComment ? resolveComment.length : 0 }}/{{
              maxComment
            }}</span
          >
        </a-form-model-item>
        <template slot="footer">
          <a-button
            style="height: 32px; width: 90px"
            @click="cancelResolveTicket"
            >Cancelar</a-button
          >
          <a-button
            :data-test="DATA_TEST.TICKET.RESOLVE.BUTTON"
            type="primary"
            style="height: 32px; width: 90px"
            @click="resolveTicket(data._id)"
            >Resolver</a-button
          >
        </template>
      </a-modal>
      <!--modal de lista de colas-->
      <modal-transfer-line
        :visible="modalTransferLine.visible"
        :data-source="filterCompanyLines"
        @onClose="modalTransferLine.visible = false"
        @onTransfer="confirmTransfer"
      />
      <!--Modal de transferencia de agentes-->
      <modal-transfer-agent
        :visible="visibleMoTransAgent"
        :ticket="data"
        @onClose="visibleMoTransAgent = false"
        role="agent"
        :user-id-exclude="profile ? profile.userId : null"
      />
      <!--MODAL AGENDAR-->
      <a-modal
        v-model="visible"
        title="Agendar evento"
        width="400px"
        :footer="null"
        centered
        :afterClose="afterClose"
      >
        <a-form layout="vertical" :form="form" @submit="handleSubmit">
          <a-form-item label="Título">
            <a-input
              v-decorator="[
                'title',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor ingresa el titulo',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
              placeholder="Nombre example"
              size="large"
            />
          </a-form-item>
          <a-form-item label="Fecha y hora">
            <a-date-picker
              v-decorator="['range', config]"
              :show-time="showTime"
              format="LLL"
              :locale="localePicker"
              style="width: 100%"
              size="large"
            />
          </a-form-item>
          <a-form-item label="Descripción">
            <a-textarea
              v-decorator="['description']"
              placeholder="Describe el asunto de la reunión"
              :auto-size="{ minRows: 2, maxRows: 3 }"
            />
          </a-form-item>
          <div class="btns">
            <a-form-item>
              <a-button
                type="default"
                size="large"
                class="btn-cancel"
                @click="handleCancel"
              >
                Cancelar
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                html-type="submit"
                size="large"
                class="btn-ok"
              >
                Agendar
              </a-button>
            </a-form-item>
          </div>
        </a-form>
      </a-modal>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import formatMixin from '@/app/shared/mixins/format'
import transformMixin from '@/app/shared/mixins/transform'
import { DATA_TEST } from '@/app/shared/utils/dataTest'
import ModalTransferAgent from '@/app/shared/components/modals/ModalTransferAgent'
import SocialIcon from '@/app/shared/components/icons/SocialIcon'
import TagsContainer from '@/app/tickets/components/molecules/TagsContainer'
import DropdownActionsTicket from '@/app/tickets/components/molecules/DropdownActionsTicket'
import ModalTransferLine from '@/app/shared/components/modals/ModalTransferLine'

export default {
  mixins: [formatMixin, transformMixin],
  name: 'HeaderChat',
  props: {
    data: {
      type: Object,
      default: null,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      require: false,
    },
  },
  components: {
    ModalTransferAgent,
    SocialIcon,
    TagsContainer,
    DropdownActionsTicket,
    ModalTransferLine,
  },
  data: () => ({
    DATA_TEST,
    visible: false,
    visibleMoTransAgent: false, // visibilidad del modal de transferencia de tickets a un agente
    seeker: '',
    showTime: {
      format: 'HH:mm', // formato para la hora
    },
    config: {
      // reglas para datepicker
      rules: [
        {
          type: 'object',
          required: true,
          message: 'Por favor selecciona fecha y hora',
        },
      ],
    },
    resolveTicketModal: false,
    maxComment: 150,
    resolveComment: '',
    sizeIcons: '20',
    modalTransferLine: {
      visible: false,
    },
    tooltips: {
      // tooltips del header
      ticketOrigin: false,
      numberTicket: false,
      mailboxName: false,
      lineName: false,
    },
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal' })
  },
  computed: {
    ...mapGetters([
      'mailboxes',
      'agents',
      'profile',
      'schedules',
      'lines',
      'companyLines',
      'profileRemote',
      'ticketSelected',
      'active_breakpoint',
      'tickets_settings',
      'tags',
    ]),
    /** Filtra los buzones a los que se puede mover */
    filterMailBoxes() {
      if (!this.mailboxes) return []
      return this.mailboxes.filter((box) => !box.end)
    },
    /** Numero formateado del ticket */
    numberTicket() {
      return this.ticketNumberFormatted(this.data.ticket)
    },
    /** Valor de la estrall de favoritos */
    starValue() {
      return this.data.settings.starred === true ? 1 : 0
    },
    /**
     * Filtra las colas con los canales comunes de los tickets seleccionados
     * @return {Object[]}
     */
    filterCompanyLines() {
      if (!this.profileCurrent) return []
      let filterCompanyLines = []

      // Filtrar colas
      filterCompanyLines = this.companyLines.filter((line) => {
        if (!line.channels_companies) return false
        else
          return line.channels_companies.some(
            (channel_company) =>
              channel_company._id === this.ticketSelected.channelCompanyId
          )
      })

      // Ordenar colas
      filterCompanyLines = filterCompanyLines.sort((a, b) =>
        a.default ? -1 : b.default ? 1 : 0
      )

      // Response
      return filterCompanyLines
    },
    /**
     * Retorna el perfil del usuario q gestiona el ticket, puede ser para el visor o de manera propia
     * @returns {Object} Datos dl perfil
     */
    profileCurrent() {
      if (!this.profile) return null
      else if (this.$route.meta.is_workspace) return this.profile
      else if (this.$route.meta.is_monitor) return this.profileRemote
      else return null
    },
    /**
     * Titulo del modal de agentes
     * @returns {String}
     */
    titleModal() {
      return `Agentes disponibles de la cola "${this.data.company.lineName}"`
    },
    /**
     * Origen del ticket
     * @returns {Object | null}
     * @returns {String} origin
     * @returns {String} color - para el tag
     * @returns {String} title - titulo para el tooltip
     */
    ticketOrigin() {
      let tags = []
      let newObj = {}
      if (!this.data || !this.data.channel) return null
      else {
        const channelOrigin = { channel: this.data.channel.type }
        channelOrigin.origin =
          this.data.channel.title ||
          this.data.channel.phoneTo ||
          this.data.channel.apiBusinessId
        channelOrigin.title = this.data.channel.alias
          ? `Canal ${this.data.channel.alias}`
          : `Canal de ${this.data.channel.type} de origen`

        tags.push(channelOrigin)

        if (this.data.channel.urlOrigin) {
          newObj.origin = this.data.channel.urlOrigin
          newObj.title = 'Página web por donde se originó este ticket'
          // Se desabilito el color de diferenciacion de los canales
          // newObj.color = 'orange'
          tags.push(newObj)
        } else if (this.data.channel.whatsappMassive && this.data.strategy) {
          newObj.origin = 'Campaña'
          newObj.title = `Ticket generado por la campaña “${this.data.strategy.name}”`
          // Se desabilito el color de diferenciacion de los canales
          // newObj.color = 'green'
          newObj.isStrategy = true
          tags.push(newObj)
        }
      }
      return tags
    },
    /**
     * @returns {Boolean} mostrar la sección de etiquetas
     */
    showTags() {
      // se muestra, si existe un perfil remoto y el  ticket tiene etiquetas
      if (this.profileRemote)
        return this.data.settings.tags && !!this.data.settings.tags.length
      else {
        // se muestra, si el ticket esta activo y tiene etiquetas o existen etiquetas por agregar
        if (this.data.status === 'active') {
          return (
            (this.data.settings.tags && !!this.data.settings.tags.length) ||
            (this.tags && !!this.tags.length)
          )
        }
        // se muestra, si el ticket tiene etiquetas
        else return this.data.settings.tags && !!this.data.settings.tags.length
      }
    },
    /**
     * @returns {Boolean} muestra el agregar de etiquetas o no
     */
    addTags() {
      // si existe un perfil remoto o si el ticket tiene otro estado diferente al activo
      if (this.profileRemote || this.data.status !== 'active') return false
      else return this.tags && !!this.tags.length
    },
    /**
     * @return {String} evento open del toolitp
     */
    triggerTooltip() {
      return this.active_breakpoint.is_mobile ? 'click' : 'hover'
    },
    /**
     * Muestra el dropdown de opciones para mobiles
     * @return {Boolean}
     */
    showDropdownActions() {
      return (
        this.active_breakpoint.is_mobile &&
        this.data.status === 'active' &&
        !this.profileRemote
      )
    },
    /**
     * @return {String} Nombre del cliente
     */
    nameClient() {
      if (!this.data.client.names) {
        switch (this.data.channel.type) {
          case 'webchat':
            return `${this.data.code} Webchat`
          case 'whatsapp':
            return this.data.client.phones[0].number
          default:
            return ''
        }
      } else return this.data.client.names
    },
  },
  methods: {
    ...mapActions([
      'setTicketMailbox',
      'updateStarred',
      'updateAsUnread',
      'transferTicket',
      'addSchedule',
      'listAgentsAsigned',
    ]),
    showModalSchedule() {
      this.visible = true
    },
    /* muestra el modal de transferencia a un agente */
    showModalTransfer() {
      this.listAgentsAsigned()
      this.visibleMoTransAgent = true
    },
    handleOkModal() {
      this.visibleMoTransAgent = true
    },
    handleOk() {
      this.visible = false
    },
    /**
     * Escucha el cambio del buzon
     * @param {Object} mailbox
     * @param {String} mailbox.title
     * @param {String} mailbox.idMailbox
     * @param {Boolean} mailbox.default
     * @param {Boolean} mailbox.active
     * @param {Boolean} mailbox.editable
     * @param {Number} mailbox.notifications
     * @param {String} idTicket
     */
    changeBox(mailbox, idTicket) {
      this.setTicketMailbox({ mailbox, idTicket })
      this.$message.success(`Tu ticket se cambió al buzón ${mailbox.title}`)
    },
    resolveBox(idTicket) {
      const self = this
      // modal de confirmación
      this.$confirm({
        title: '¿Deseas resolver este ticket?',
        content:
          'Recuerde que una vez resuelto el ticket ya no podrá enviar mensajes',
        onOk() {
          const mailbox = self.mailboxes.find((box) => box.end)
          self.setTicketMailbox({ mailbox, idTicket, end: true })
        },
        okText: 'Resolver',
        cancelText: 'Cancelar',
        class: 'test',
      })
    },
    resolveTicket(idTicket) {
      const mailbox = this.mailboxes.find((box) => box.end)
      this.setTicketMailbox({
        mailbox,
        idTicket,
        end: true,
        comment: this.resolveComment,
      })
    },
    cancelResolveTicket() {
      this.resolveTicketModal = false
      this.resolveComment = ''
    },
    /**
     * Marcar o quitar un ticket como favorito
     * @param {String} idTicket Id del ticket
     * @param {Boolean} starred Valor anterior, estaba como favorito?
     */
    async markFavorite(idTicket, starred) {
      starred = !starred
      await this.updateStarred({ idTicket, starred })
    },
    /**
     * Marcar un ticket como no leído
     * @param {String} idTicket Id del ticket
     * @param {String} mailboxId Id del buzón
     */
    async markUnread(idTicket, mailboxId) {
      this.updateAsUnread({ idTicket, mailboxId })
    },
    /* submit del form del modal */
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          const schedule = new Object() // objeto del evento agendar
          schedule.title = values.title
          ;(schedule.scheduled_for = values.range._d),
            (schedule.description = values.description)
          const idTicket = this.data._id // id del ticket
          this.addSchedule({ idTicket, schedule })
          this.visible = false
          this.$message.success('Agenda registrada correctamente')
        }
      })
    },
    /* evento q desaparece el modal de agendar */
    handleCancel() {
      this.visible = false
    },
    /**
     * Prepara la transferencia de un ticket, puede ser hacia un agente o una cola
     * @param {String} typeTransfer Tipo de transferencia [line, agent]
     * @param {Object} agent Objeto del agente de destino - opcional
     * @param {Object} line Objeto de la cola destino - opcional
     */
    handleTransfer(typeTransfer, { agent, line }) {
      const idTicket = this.data._id
      this.transferTicket({
        typeTransfer,
        idTicket,
        agent,
        line,
        massive: false,
      })
    },
    /**
     * Confirmación de transferencia del ticket a una cola
     * @param {Object} line Objeto de la Cola destino
     */
    confirmTransfer(line) {
      const self = this
      this.$confirm({
        title: `¿Está seguro(a) de transferir el ticket a la cola "${line.name}"?`,
        content:
          'Recuerda que los tickets serán derivados y repartidos en la cola seleccionada ',
        okText: 'Transferir',
        cancelText: 'Cancelar',
        onOk() {
          self.handleTransfer('line', { line })
        },
      })
    },
    afterClose() {
      this.form.resetFields()
    },
    /**
     * Escucha el evento de seleccion de tooltip
     * @param {String} tooltip
     */
    handleSelectTooltip(tooltip) {
      if (this.triggerTooltip === 'hover') return
      // solo si el trigger es click
      this.tooltips[tooltip] = !this.tooltips[tooltip]
    },
  },
}
</script>

<style lang="sass" scoped>


img
  vertical-align: baseline
.header
  &-chat
    padding: 12px 20px 8px 20px
    border-radius: 8px 8px 0px 0px
    &:not(.header-chat--loading)
      @include xs
        padding: 0px
      @include sm
        padding: 0px
      @include md
        padding: 0px

  &__names
    color: $gray_dark_900
  &__divider
    margin: 8px 0px
    @include xs
      margin: 0px
    @include sm
      margin: 0px
    @include md
      margin: 0px
  &__tags
    .ticket-origin
      // margin-left: 5px
    @include xs
      padding: 0px 0px 0px 20px
      align-items: center
    @include sm
      padding: 0px 0px 0px 20px
      align-items: center
    @include md
      padding: 0px 0px 0px 20px
      align-items: center
  &__icon
    &-container
      padding: 8px 10px 8px 8px
    &--more
      font-size: 28px
.options
  width: 50%
  display: flex
  flex-direction: row
  justify-content: flex-end
  align-items: center
  .iconned
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    .star
      display: flex
      justify-content: center
      align-items: center
      >.ant-rate-star
        div
          .ant-rate-star-second
            display: flex
            justify-content: center
            align-items: center
    .option
      margin: 4px
      cursor: pointer
      display: flex
      align-items: center
  .btn-resolve
    margin-left: 12px
.ant-form-item
  margin-bottom: 0px
.agent-contain
  display: flex
  flex-direction: row
  align-items: center
  border-bottom: 1px solid #C4C4C4
  padding: 8px
  position: relative
  p
    margin-bottom: 0px
    margin-left: 12px
    color: $gray_dark_900
  button
    position: absolute
    right: 8px
.btn-ok
  margin-left: 8px
.btns
  margin-top: 20px
  display: flex
  flex-direction: row
  justify-content: flex-end
.ant-form-item
  margin-bottom: 0px
.textarea
  height: 150px
  min-height: 150px
  max-height: 150px
  position: relative
.counter
  position: absolute !important
  bottom: 0px
  height: 25px
  right: 12px
  color: $gray_light_300
  font-size: 10px
.resolver-ticket-modal
  color: #262A31
  font-weight: 600
  font-size: 16px
  line-height: 24px
.resolver-ticket-modal__description
  font-size: 12px
  line-height: 18px
  font-weight: normal
  color: #262A31
.resolver-ticket-modal__label
  font-size: 15px
  color: #262A31
  font-weight: normal
  line-height: 24px
  margin-bottom: 1px

.iconned
  .star
    width: 24px
    height: 24px
    li
      width: 100%
      div
        display: flex
        align-items: center
        justify-content: center
.icon-option
  svg
    &:hover
      fill: #1890FF !important
    // path:hover
    //   fill: #1890FF
    // path:focus
    //   fill: #1890FF
.icon
  &:hover
    color: #1890FF
  &:focus
    color: #1890FF
</style>
<style lang="sass">
.header-skeleton
  .ant-skeleton-content
    .ant-skeleton-title
      margin-top: 0px!important
    .ant-skeleton-paragraph
      margin-top: 16px!important
      margin-bottom: 0px!important
</style>
