<template>
  <div>
    <!--modal ocupado-->
    <a-modal
      :visible="visible"
      :maskClosable="false"
      :closable="false"
      :footer="null"
      width="640px"
      centered
    >
      <template>
        <div class="modal-container">
          <svg width="66" height="74" viewBox="0 0 66 74" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="type === 'bussy'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.532 7.149l1.565-3.13A6.667 6.667 0 0115.06.332h35.88a6.667 6.667 0 015.963 3.685l1.565 3.13a6.668 6.668 0 015.064 4.902l1.666 6.666a6.667 6.667 0 01-5.97 8.265l-6.67 46.686H13.443l-6.67-46.685a6.667 6.667 0 01-5.971-8.266l1.666-6.666a6.668 6.668 0 015.064-4.901zm5.979 19.85h38.98l-5.715 40H19.225l-5.714-40zM8.936 13.668h2.79L15.06 7h35.88l3.333 6.667h2.791l1.667 6.666H7.269l1.667-6.666z" fill="#F5222D"/></svg>
          <svg width="60" height="75" viewBox="0 0 60 75" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="type === 'services'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M32.192 2.156C50.63 18.254 60 32.31 60 44.666c0 17.498-13.657 30-30 30s-30-12.502-30-30c0-12.355 9.371-26.412 27.808-42.51L30 .24l2.192 1.915zM6.667 44.666C6.667 58.344 17.214 68 30 68c12.786 0 23.333-9.656 23.333-23.334 0-9.497-7.688-21.445-23.333-35.544C14.355 23.22 6.667 35.169 6.667 44.666zM30 58v6.667c11.046 0 20-8.955 20-20h-6.667C43.333 52.03 37.363 58 30 58z" fill="#1890FF"/></svg>
          <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="type === 'training'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M64 7.333v33.334a6.667 6.667 0 01-6.667 6.666h-20v4.78l15.049 9.029-3.43 5.716-11.619-6.97v7.445h-6.666v-7.446l-11.619 6.971-3.43-5.716 15.048-9.03v-4.779h-20A6.667 6.667 0 014 40.667V7.333H.667V.667h66.666v6.666H64zm-53.334 0v33.334h46.667V7.333H10.667zm10 26.667h6.667V20.667h-6.666V34zm16.667 0h-6.666V14h6.666v20zm3.334 0h6.666V24h-6.666v10z" fill="#FA8C16"/></svg>
          <h4 :class="`${fontTitle} title-1`">{{ textTitle }}</h4>
          <h4 :class="`${fontTitle}`">¿Deseas volver al trabajo?</h4>
          <p class="extra_info">
            Mientras tu sesión esté suspendida, no podrás recibir tickets
          </p>
          <p class="info_i2">La sesión está suspendida hace {{ timer }}</p>
          <a-button @click="handdleReconnect" type="primary" size="large"
            >Conectarme</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ModalState',
  props: {
    visible: { type: Boolean, required: false, default: false },
    type: { type: String, required: false, default: 'bussy' },
    timer: { type: [String, Number], required: false, default: '0' },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['active_breakpoint']),

    /**
     * @return {String} titulo del modal
     */
    textTitle() {
      switch (this.type) {
        case 'bussy':
          return 'Su estado se cambió a "En Ocupado"'
        case 'services':
          return 'Su estado se cambió a "En servicios"'
        case 'training':
          return 'Su estado se cambió a "En capacitación"'
        default:
          return ''
      }
    },
    /**
     * @return {String} tipografia para el titulo
     */
    fontTitle() {
      if (!this.active_breakpoint) return 'heading-h4'
      else return this.active_breakpoint.components.modal_state.font_title
    },
  },
  methods: {
    /**
     * Emite la reconnecion
     */
    handdleReconnect() {
      this.$emit('onReconnect')
    },
  },
}
</script>

<style lang="sass" scoped>
.modal-container
  min-height: 343px
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center
  text-align: center
  svg
    margin-bottom: 20px
  .title-1
    margin-bottom: 8px !important
  h4
    color: #00173D
  p
    margin-bottom: 40px
    color: $gray_dark_300
  .extra_info
    color: $gray_8
    margin-bottom: 8px
</style>
