<template>
  <a-modal
    :visible="visible"
    @cancel="$emit('onClose')"
    :body-style="bodyStyle"
    width="42.6em"
    :footer="null"
    :afterClose="handleReset"
    centered
  >
    <div class="modal__title" v-if="currentStep !== 4">
      <h6 class="semibold-20">{{ title }}</h6>
    </div>
    <custom-section-scroll
      v-if="currentStep !== 4"
      :master-styles="{ height: 'calc( 88.5% - 58px)' }"
      :content-styles="{ padding: '0px 36px 36px 36px' }"
    >
      <general-form
        v-if="currentStep === 1"
        ref="generalForm"
        :form-values="formValues"
        type="create"
      />
      <schedule-form
        v-else-if="currentStep === 2"
        ref="scheduleForm"
        :form-values="scheduleFormValues(formValues)"
      />
      <template v-else-if="currentStep === 3">
        <p
          class="body-2"
          style="color: #747781; margin-top: -4px; margin-bottom: 8px"
          v-if="!isThereChannelsActive"
        >
          ¡Hola! 👋 Vemos que aún no conectas tu primer canal. Te podemos ayudar
          <a :href="appPhoneNumberWsp" target="_blank">chatea con un experto</a>
        </p>
        <list-picker-channels
          ref="list_picker_channels"
          :default-values="channelsFormValues(formValues.channels)"
          :disabled="!isThereChannelsActive"
        />
      </template>
      <img
        v-if="currentStep === 1"
        src="img/graphics/outlined/chatbot-walking.png"
        class="modal__picture"
      />
    </custom-section-scroll>
    <success-create
      v-if="currentStep === 4"
      @onOther="handleOpenNewCreate"
      @onGoToChatbot="handleGoToChatbot"
      :nameChatbot="formValues.name"
    />
    <div
      v-if="currentStep !== 4"
      class="modal__footer display-flex justify-content--end"
    >
      <a-button v-if="currentStep === 1" @click="$emit('onClose')"
        >Cancelar</a-button
      >
      <a-button
        v-if="currentStep === 2"
        @click="handleChangeStep(currentStep, 'minus')"
        >Volver</a-button
      >
      <a-button
        type="primary"
        @click="handleSubmit"
        class="modal__button--primary"
        >{{ okButton }}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GeneralForm from '@/app/chatbots/components/organisms/forms/GeneralForm'
import ScheduleForm from '@/app/chatbots/components/organisms/forms/ScheduleForm'
import ListPickerChannels from '@/app/shared/components/organisms/ListPickerChannels'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import SuccessCreate from '@/app/chatbots/components/organisms/SuccessCreate'
import moment from 'moment'

export default {
  name: 'ModalCreateChatbot',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    moment,
    currentStep: 1,
    formValues: {},
    bodyStyle: {
      padding: '0px',
      height: '46em',
      position: 'relative',
    },
    idChatbot: null,
    appPhoneNumber: process.env.VUE_APP_PHONE_NUMBER,
  }),
  components: {
    GeneralForm,
    ScheduleForm,
    ListPickerChannels,
    CustomSectionScroll,
    SuccessCreate,
  },
  watch: {
    /**Dependiendo del paso , hara una accion */
    currentStep(val) {
      switch (val) {
        case 1:
          {
            const scheduleForm = this.$refs.scheduleForm
            this.formValues.schedule = {}
            this.formValues.schedule.timezone = scheduleForm.timezoneSelected
            this.formValues.schedule.days = scheduleForm.arrayRangePickers
            this.$nextTick(() => this.handleSetFields('general'))
          }
          break
        case 2:
          this.$nextTick(() => this.handleSetFields('schedule'))
      }
    },
  },
  computed: {
    ...mapGetters(['company']),

    /**
     * Devuelve el nombre del button
     * @return {String} - button's name
     */
    okButton() {
      switch (this.currentStep) {
        case 1:
          return 'Continuar'
        case 2:
          return 'Continuar'
        case 3:
          return 'Crear chatbot'
        default:
          return 'okButton'
      }
    },
    /**
     * Devuelve el titulo del modal dependiendo del paso
     * @return {String} - title
     */
    title() {
      switch (this.currentStep) {
        case 1:
          return 'Crea tu chatbot para automatizar'
        case 2:
          return 'Configura el horario de trabajo de tu chatbot'
        case 3:
          return 'Asigna canales a tu chatbot'
        default:
          return 'title'
      }
    },
    /**
     * @returns {String}
     */
    appPhoneNumberWsp() {
      const formattedNumber = this.appPhoneNumber.replace(/\s+/g, '')
      return `https://wa.me/${formattedNumber}?text=Hola%2C%20quiero%20solicitar%20asesoria%20de%20un%20experto%20en%20Ticker`
    },
    /**
     * Devuelve true or false si es que hay canales activos
     * @return {Boolean}
     */
    isThereChannelsActive() {
      const company = this.company

      if (
        company &&
        company.channels &&
        Object.keys(company.channels).length > 0
      )
        return true
      else return false
    },
  },
  methods: {
    ...mapActions(['addChatbot']),

    handleContinue() {
      if (this.currentStep === 1) {
        const generalForm = this.$refs.generalForm
        const form = generalForm.form
        form.validateFields((err, values) => {
          if (!err) {
            this.formValues = { ...this.formValues, ...values }
            // this.formValues.channels = generalForm.channelsSelected
            this.handleChangeStep(this.currentStep, 'sum')
          }
        })
      } else if (this.currentStep === 2) {
        const scheduleForm = this.$refs.scheduleForm
        const form = scheduleForm.form
        scheduleForm.handleValidateListRanges()
        form.validateFields(async (err, values) => {
          const isFilledDays = scheduleForm.daysSelecteds.length > 0
          const isFilledRanges = scheduleForm.arrayRangePickers.some((day) =>
            day.hours.some((range) => !range.start || !range.end)
          )
          if (isFilledDays && !err && !isFilledRanges) {
            /**
             * El timezone offset se deberá obtener a partir de la zona horaria seleccionada
             */
            scheduleForm.arrayRangePickers.forEach((range) =>
              range.hours.map((hour) => {
                hour.start = this.converToLocal(
                  hour.start,
                  scheduleForm.timezoneOffset
                )
                hour.end = this.converToLocal(
                  hour.end,
                  scheduleForm.timezoneOffset
                )
                return hour
              })
            )
            // console.log('arrayRangePickers', scheduleForm.arrayRangePickers)
            this.formValues.schedule = {
              timezone: values.timezone,
              days: scheduleForm.arrayRangePickers,
            }
            this.handleChangeStep(this.currentStep, 'sum')
          } else {
            scheduleForm.handleVerifiedDays()
          }
        })
      }
    },
    /**
     * Cambia el paso del modal
     * @param {Number} currentStep
     * @param {String} operation - sum | minus
     */
    handleChangeStep(currentStep, operation) {
      if (operation === 'sum') this.currentStep = currentStep + 1
      else if (operation === 'minus') this.currentStep = currentStep - 1
      else this.currentStep
    },
    async handleCreateChatbot() {
      const listPicker = this.$refs.list_picker_channels

      const listChannels = listPicker
        .handleGetList()
        .map((picker) => picker.channelCompanyId)

      this.formValues.channels = listChannels

      const response = await this.addChatbot(this.formValues)
      if (response.type === 'tree') {
        this.idChatbot = response._id
        this.handleChangeStep(this.currentStep, 'sum')
      } else {
        this.$message.error(
          response.details ? response.details : 'Ocurrió un error'
        )
      }
    },
    handleSubmit() {
      if (this.currentStep === 1 || this.currentStep === 2)
        this.handleContinue()
      else if (this.currentStep === 3) this.handleCreateChatbot()
    },
    handleGoToChatbot() {
      this.$emit('onClose')
      this.$router.push({ name: 'id', params: { id: this.idChatbot } })
    },
    handleOpenNewCreate() {
      this.$emit('onClose')
      setTimeout(() => this.$emit('onVisible'), 300)
    },
    handleReset() {
      this.currentStep = 1
      this.formValues = { channels: [] }
      this.$refs.generalForm?.resetFields()
      this.$refs.scheduleForm?.resetFields()
      this.idChatbot = null
    },
    /**
     * Setea los valores dependiendo del keytab
     * @param {String} keyTab - general | schedule
     */
    handleSetFields(keyTab) {
      const generalForm = this.$refs.generalForm
      const scheduleForm = this.$refs.scheduleForm

      if (keyTab === 'general') {
        generalForm.setFieldsValues()
      } else if (keyTab === 'schedule') {
        scheduleForm.setFieldsValues()
      }
    },
    /**
     * Retorna solo el schedule del chatbot
     * @param {Object} chatbot
     * @param {Object} chatbot.schedule
     * @param {String} chatbot.schedule.timezone
     * @param {Array} chatbot.schedule.days
     */
    scheduleFormValues(chatbot) {
      let schedule = {
        timezone: chatbot.schedule?.timezone,
        timezoneoffset: chatbot.schedule?.timezoneoffset,
        days: chatbot.schedule?.days,
      }
      return schedule
    },
    channelsFormValues(channels) {
      if (!channels) return
      return [
        ...channels.map((channel) => {
          const newKey = `${new Date().getTime()}-${channel._id}`
          return {
            channel: channel.channel,
            channelCompanyId: channel._id,
            key: newKey,
          }
        }),
      ]
    },
    /**
     * Convierte la hora a tu zona horaria local
     */
    converToLocal(hour, timezoneOffset) {
      console.log('----------------------------------------')
      console.log(hour, 'HORA')
      // console.log(moment(hour).format('LLL'))
      const formatted = new Date(hour.format())
      formatted.setFullYear(2000, 0, 1)
      const isoDate = formatted.toISOString()
      // console.log(isoDate)
      // console.log(timezoneOffset, 'timezoneOffset')
      const utcZero = moment(isoDate).subtract(timezoneOffset, 'minutes')
      // console.log('UTC 0', utcZero)
      const utcZeroFormatted = utcZero.format('YY-MM-DD HH:mm')
      // console.log('UTC 0 formateado', utcZeroFormatted)

      const timezoneLocal = Intl.DateTimeFormat().resolvedOptions().timeZone
      // console.log('timezoneLocal', timezoneLocal)
      const dateLocal = moment.tz(utcZeroFormatted, timezoneLocal)
      console.log('DATE LOCAL', dateLocal)
      console.log('HORA CONVERTIDA', dateLocal.format('HH:mm'))
      return moment(dateLocal).format()
    },
  },
}
</script>

<style lang="sass" scoped>
.modal__picture
  position: absolute
  right: 24px
  bottom: 0px
.modal__title
  padding: 36px 36px 0px 36px
.modal__footer
  padding: 12px
  border-top: 1px solid $gray_5
.modal__button--primary
  margin-left: 8px
</style>
