<template>
  <div class="container">
    <!--Cargando nodos del chatbot-->
    <div
      v-if="loading"
      class="container--loading display-flex direction-column align--center"
    >
      <a-spin />
      <p class="mrg-top-12">Cargando datos</p>
    </div>
    <!--Si ya termino de cargar-->
    <template v-else>
      <!--Si existe el arbol de nodos-->
      <tree
        v-if="treeNodes"
        :data-source="treeNodes"
        :key="count"
        @onOpenDrawer="handleOpenDrawer"
        :disabled-actions="disabledActions"
      />
      <!--Si no existe el arbol de nodos-->
      <div
        v-else
        class="container--loading display-flex direction-column align--center"
      >
        <img
          src="@/app/shared/assets/whoooa/solid/chatbot-3.png"
          alt="Chatbot saludando"
        />
        <p class="mrg-top-12">Este chatbot aún no tiene un flujo</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import attemptMixin from '@/app/shared/mixins/attempt'
import Tree from '@/app/chatbots/components/organisms/tree/Tree'

export default {
  name: 'ContainerTree',
  props: {
    chatbotId: { type: String, required: true },
    disabledActions: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Tree,
  },
  data: () => ({
    count: 0,
    loading: true,
  }),
  mixins: [attemptMixin],
  mounted() {
    this.$nextTick(() => {
      // si el id del chatbot existe y si aun no hay nodos
      if (this.chatbotId && !this.nodes.length) this.loadNodes(this.chatbotId)
      else this.loading = false
    })
  },
  beforeDestroy() {
    // antes de destruirse el componente resetea
    this.handleReset()
  },
  watch: {
    chatbotId(value) {
      // si el id del chatbot existe y si aun no hay nodos
      if (value && !this.nodes && !this.nodes.length) this.loadNodes(this.value)
      else this.loading = false
    },
    nodes: {
      deep: true,
      handler: function () {
        this.$nextTick(() => this.count++)
      },
    },
  },
  computed: {
    ...mapGetters(['nodes', 'treeNodes', 'company']),
  },
  methods: {
    ...mapActions(['resetTreeNodes', 'getNodes', 'resetNodes']),

    /**
     * Carga los nodos de un chatbot sengun el id
     * @param {String} chatbotId
     */
    loadNodes(chatbotId) {
      if (!chatbotId) return
      this.intervalAttempt(async () => {
        if (!this.company) throw 'Not company found'
        this.loading = true
        await this.getNodes(chatbotId)
        this.loading = false
      })
    },
    /**
     * Emitir evento para abrir el drawer
     * @param {Object} settings
     * @param {Object} settings.drawer - para nodos principales: mensaje u opciones
     * @param {Object} settings.drawer.question
     * @param {String} settings.drawer.question.type
     * @param {String} settings.drawer.action.message
     * @param {String} settings.drawer.action.lineId
     * @param {Boolean} settings.drawer.action.withMessage
     * @param {Object} settings.action - para opciones
     * @param {String} settings.action.nodeType
     * @param {String} settings.action.type
     * @param {String} settings.action.message
     * @param {String} settings.action.lineId
     * @param {Boolean} settings.action.withMessage
     */
    handleOpenDrawer(settings) {
      this.$emit('onOpenDrawer', settings)
    },
    /**
     * Resetea los nodos y el arbol de nodos
     */
    handleReset() {
      this.resetTreeNodes()
      this.resetNodes()
    },
  },
}
</script>

<style lang="sass" scoped>
.container
  width: 100%
  &--loading
    margin: 40px
    color: $gray_6
</style>
