<template>
  <div class="menu-profile">
    <div class="summary" v-if="profile && profile.names && profile.type">
      <a-avatar
        v-if="profile.avatar"
        :src="profile.avatar"
        :size="82"
        alt="Avatar de perfil"
      />
      <DefaultIconLetter v-else :names="profile.names" :size="82" />
      <h6 class="heading-h7 fullname">
        {{ profile.names }} {{ profile.surnames }}
      </h6>
      <p class="body-2 company-name">{{ profile.company.companyName }}</p>
      <a-tag>{{ transformTypeUser(profile.type) }}</a-tag>
    </div>
    <a-divider />
    <a-menu
      mode="inline"
      :inlineIndent="40"
      :default-selected-keys="[defaultSelected]"
    >
      <template v-for="item in menuItems">
        <a-menu-item
          :key="item.key"
          style="text-align: left"
          @click="handleSelectMenu(item.key)"
        >
          {{ item.title }}
        </a-menu-item>
      </template>
    </a-menu>
    <div class="menu-feedback">
      <div class="menu-feedback-item">
        <u>
          <a :href="linkSurvey" target="_blank" rel="noopener noreferrer">
            Tus sugerencias son importantes 🙌
          </a>
        </u>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultIconLetter from '@/app/shared/components/avatars/DefaultIconLetter.vue'
import { mapActions, mapGetters } from 'vuex'
import transformTypeUser from '@/app/shared/mixins/transform'

export default {
  name: 'MenuProfile',
  components: {
    DefaultIconLetter,
  },
  props: {
    itemSelected: {
      type: String,
    },
  },
  data: () => ({}),
  created() {
    this.getCountries()
  },
  mixins: [transformTypeUser],
  computed: {
    ...mapGetters(['profile']),
    /**
     * @return {string}
     */
    linkSurvey() {
      return process.env.VUE_APP_SURVEY
    },
    menuItems() {
      if (
        (this.profile && this.profile.type && this.profile.type === 'agent') ||
        (this.profile &&
          this.profile.type &&
          this.profile.type === 'supervisor')
      )
        return [{ order: 1, title: 'Mi perfil', key: 'menu-user' }]
      else
        return [
          { order: 1, title: 'Mi perfil', key: 'menu-user' },
          { order: 2, title: 'Mi empresa', key: 'menu-company' },
          { order: 3, title: 'Guía de usuario', key: 'menu-guide' },
        ]
    },
    // menu por default seleccionado
    defaultSelected() {
      if (!this.itemSelected) {
        const menuItem = this.menuItems.find((item) => item.order === 1)
        this.handleSelectMenu(menuItem.key)
        return menuItem.key
      }
      return this.itemSelected
    },
  },
  methods: {
    ...mapActions(['logout', 'getCountries']),

    handleSelectMenu(menuItem) {
      this.$router.push({ name: menuItem }).catch((e) => e)
    },
  },
}
</script>

<style lang="sass">
.menu-feedback
  position: absolute
  bottom: 0
  text-align: center
  width: 100%
  margin: 1rem 0 1rem
  .menu-feedback-item
    position: relative
    height: 100%
    width: 100%
.menu-profile
  height: 100%
  background-color: $white_000
  border-radius: 4px
  border: 1px solid $gray_dark_000
  .summary
    padding: 40px 20px 0px
    .fullname
      margin-top: 12px
      margin-bottom: 4px
    .company-name
      margin-bottom: 8px
</style>
