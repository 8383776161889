<template>
  <a class="document-file" :href="src">
    <a-icon :type="icon" style="font-size: 40px; color: #e9e9ea" />
    <div class="overlay">
      <a-icon type="download" class="icon" style="font-size: 20px" />
    </div>
  </a>
</template>

<script>
export default {
  name: 'DocumentFile',
  props: {
    file: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: 'file',
    },
    src: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.document-file
  height: 80px
  // width: 80px
  // margin: 0 0.2em 0.3em 0
  background-color: #C4C4C4
  position: relative
  display: flex
  align-items: center
  justify-content: center
  .overlay
    cursor: pointer
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100%
    width: 100%
    transition: .3s ease
    display: flex
    align-items: center
    justify-content: center
    .icon
      color: transparent
  &:hover
    .overlay
      background: $gray_dark_900
      opacity: 0.7
      .icon
        color: $white_000
</style>
