import axios from 'axios'
import vari from '@/app/shared/utils/variables'

const actions = {
  /**
   * Obtener todo los datos de un cliente
   * @param {*} context
   * @param {Object} args
   * @param {String} args.clientId Id del cliente
   * @param {String} args.channel [whatsapp,messenger] Canal de comunicación
   */
  async getClientById(context, { clientId, channel }) {
    try {
      // TODO: Esta validación se debería manejar desde un utils
      if (!['whatsapp', 'messenger', 'instagram', 'webchat'].includes(channel))
        throw new ReferenceError(`channel '${channel}' not supported`)

      const response = await axios.get(`${vari.UHR}/client/${clientId}`)

      const client = response.data
      if (client.phones && client.phones.length != 0) {
        client.phone = client.phones[client.phones.length - 1].number
      } else client.phone = null

      context.commit('SET_CLIENT', client)
      return client
    } catch (error) {
      console.error('[clientModule][getClientById]', error)
    }
  },
  /**
   *
   * @param {*} context
   * @param {Object} client Objeto de los datos del cliente editado
   */
  async updateClient(context, client) {
    try {
      const response = await axios.put(`${vari.UHR}/client/update`, { client })
      const payload_socket = {
        userId: context.getters.profile.userId,
        ticketId: context.getters.roomSelectedId,
        client: client,
      }
      const clientUpdated = response.data.client
      this._vm.$socket.emit('server:ticket:client', payload_socket)
      context.dispatch('getClientById', {
        clientId: client._id,
        channel: client.channel,
      })
      context.commit('SET_CLIENT_NAME_IN_TICKETS', {
        clientId: client._id,
        clientNames: `${clientUpdated.names || ''} ${
          clientUpdated.surnames || ''
        }`.trim(),
      })

      context.commit('SET_UPDATE_CONTACT', clientUpdated)
      let keepEventTime = true
      context.dispatch('saveEventTicket', keepEventTime)

      return { success: true, data: response.data }
    } catch (error) {
      console.error('[clientModule][updateClient]', error)
      return { success: false }
    }
  },
  /**
   * Actualiza el campo personalizado del cliente
   * @param {*} context
   * @param {Object} args
   * @param {String} args.clientId
   * @param {String} args.clientChannel
   * @param {Object} args.field - campo personalizado del cliente
   */
  async updateCustomFieldClient(context, { clientId, clientChannel, field }) {
    try {
      const response = await axios.put(
        `${vari.UHR}/client/custom-fields/${clientId}`,
        field
      )

      const client = await context.dispatch('getClientById', {
        clientId: clientId,
        channel: clientChannel,
      })
      const payload_socket = {
        userId: context.getters.profile.userId,
        ticketId: context.getters.roomSelectedId,
        client: client,
      }
      this._vm.$socket.emit('server:ticket:client', payload_socket)

      context.commit('SET_FIELDS_TO_CONTACT', {
        clientId: client._id,
        fields: response.data.fields,
      })
      let keepEventTime = true
      context.dispatch('saveEventTicket', keepEventTime)

      return response.data
    } catch (error) {
      console.error('[clientModule][updateCustomFieldClient]', error)
    }
  },
}

export default actions
