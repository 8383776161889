const getters = {
  ticketSelected: (state) => state.ticketSelected,
  tickets: (state) => {
    // return state.tickets
    return state.tickets
      .filter(
        (ticket, i, self) => i === self.findIndex((t) => t._id === ticket._id)
      )
      .sort((a, b) => {
        // Ordena por la fecha del último mensaje
        return new Date(b.lastMessage_at) < new Date(a.lastMessage_at)
          ? -1
          : new Date(a.lastMessage_at) < new Date(b.lastMessage_at)
          ? 1
          : 0
      })
      .sort((a, b) => {
        // Ordena por favoritos
        return a.settings.starred === b.settings.starred
          ? 0
          : a.settings.starred
          ? -1
          : 1
      })
  },
  ticketsIdUnread: (state) => state.ticketsIdUnread,
  ticketsEnded: (state) => {
    return state.ticketsEnded.sort((a, b) => {
      // Ordena por la fecha del último mensaje
      return new Date(b.lastMessage_at) < new Date(a.lastMessage_at)
        ? -1
        : new Date(a.lastMessage_at) < new Date(b.lastMessage_at)
        ? 1
        : 0
    })
  },
  ticketsIdSelected: (state) => state.ticketsIdSelected,
  oldTickets: (state) => state.oldTickets,
  oldTicketSelected: (state) => state.oldTicketSelected,
  agentsAssignedToTicket: (state) => state.agentsAssignedToTicket,
  currentIdMailbox: (state) => state.currentIdMailbox,
}

export default getters
