<template>
  <div class="filter-menu-billing-container">
    <div class="filter-menu-billing-content">
      <div class="inputs">
        <!-- Tipos de mensajes -->
        <a-select
          v-if="type == 'spending'"
          v-model="subType"
          default-value="all"
          style="width: 260px; margin-right: 12px"
          @change="onChange"
        >
          <a-select-option value="all"> Todos los mensajes </a-select-option>
          <a-select-option key="session"> Mensajes de sesión </a-select-option>
          <a-select-option key="template">
            Mensajes de plantilla
          </a-select-option>
          <a-select-option key="conversation">
            Mensajes de conversación
          </a-select-option>
        </a-select>
        <a-input-search
          v-else
          v-model="search"
          placeholder="Buscar por numero de pago"
          style="width: 312px; margin-right: 12px"
          @search="onChange"
        />
        <!-- Rango de fechas -->
        <a-range-picker
          :disabled-date="disabledDate"
          :show-time="{
            format: localePicker.lang.dateTimeFormat,
            defaultValue: [
              moment('00:00:00', 'HH:mm:ss'),
              moment('23:59:59', 'HH:mm:ss'),
            ],
          }"
          :placeholder="['Fecha de inicio', 'Fecha de fin']"
          :format="localePicker.lang.dateTimeFormat"
          :locale="localePicker"
          @change="onChange"
          @ok="onOk"
          style="min-width: 312px; margin-right: 12px"
          :popupStyle="stylesPopup"
          v-model="seletedDate"
          :allowClear="false"
        />
        <!-- ID del canal -->
        <a-select
          v-if="type == 'spending'"
          v-model="simpleChannelSelected"
          default-value="allChannels"
          style="width: 204px"
          @change="onChange"
        >
          <a-select-option
            v-for="simpleChannel in simpleChannelsFiltered"
            :key="simpleChannel._id"
            :title="simpleChannel.title"
          >
            {{ simpleChannel.alias || simpleChannel.title }}
          </a-select-option>
        </a-select>
        <!-- Estado de Recarga -->
        <a-select
          v-if="type != 'spending'"
          v-model="statusSelected"
          default-value="allChannels"
          style="width: 204px"
          @change="onChange"
        >
          <a-select-option value="all"> Todos los estados </a-select-option>
          <a-select-option
            v-for="status in payments_statuses"
            :key="status.key"
          >
            {{ status.value }}
          </a-select-option>
        </a-select>
      </div>
      <!-- Boton de Actualizar datos -->
      <div class="button">
        <a-button
          v-if="type == 'spending'"
          class="btn-dashed"
          type="dashed"
          @click="onChange"
          :disabled="loading"
        >
          <a-icon type="sync" :spin="loading" />Traer datos recientes
        </a-button>
        <!-- COmentado hasta agregar funcionalidad -->
        <!-- <a-button type="primary" class="blue-5" @click="handleDownload">
          Descargar todos los pagos
        </a-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatMixin from '@/app/shared/mixins/format'
import supportMixin from '@/app/shared/mixins/support'
import attemptMixin from '@/app/shared/mixins/attempt'
import moment from 'moment'

export default {
  name: 'FilterMenuSpendings',
  mixins: [formatMixin, supportMixin, attemptMixin],
  props: {
    type: {
      type: String,
      default: 'spending',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    moment,
    search: '',
    statusSelected: 'all',
    seletedDate: [
      moment(`${moment().subtract(7, 'd').format('L')}`, 'D/M/YYYY HH:mm'),
      moment(`${moment().format('L')} 23:59:59`, 'D/M/YYYY HH:mm'),
    ],
    stylesPopup: {
      zIndex: 1,
    },
    subType: 'all',
    simpleChannelSelected: 'all',
    simpleChannelsFiltered: [{ _id: 'all', title: 'Todos los Ids' }],
  }),
  created() {
    this.intervalAttempt(() => {
      this.onChange()
      this.getFilterApiBussineesId()
    })
    window.moment = this.moment
  },
  computed: {
    ...mapGetters(['payments_pagination', 'payments_statuses']),
  },
  methods: {
    onSearch() {
      this.endWaitTyping(() => {
        this.onChange()
      })
    },
    onChange() {
      this.filterPayments()
    },
    onOk() {},
    handleDownload() {},
    /**
     * @param {Date} current
     * @return {Boolean}
     */
    disabledDate(current) {
      // Can not select days after today and today
      return current && current > moment().endOf('day')
    },
    filterPayments() {
      // crear una fecha cualquiera en utc 0
      // setearle a esa fecha la fecha que eligio el usuario
      const startDate = moment.utc().set({
        year: moment(this.seletedDate[0]).get('year'),
        month: moment(this.seletedDate[0]).get('month'),
        date: moment(this.seletedDate[0]).get('date'),
      })
      const endDate = moment.utc().set({
        year: moment(this.seletedDate[1]).get('year'),
        month: moment(this.seletedDate[1]).get('month'),
        date: moment(this.seletedDate[1]).get('date'),
      })
      this.$emit('search', {
        page: 1,
        subType: this.subType,
        search: this.search,
        startDate: moment(startDate).set({
          hour: '00',
          minute: '00',
          second: '00',
        }),
        endDate: moment(endDate).set({ hour: 23, minute: 59, second: 59 }),
        status: this.statusSelected,
        channelCompanyId: this.simpleChannelSelected,
      })
    },
    getFilterApiBussineesId() {
      //supportMixin
      const simpleChannels = this.filterSimpleByChannel({
        channel: 'whatsapp',
        request: true,
        defaultChannel: {
          _id: 'all',
          title: 'Todos los Ids',
        },
      })
      this.simpleChannelsFiltered = simpleChannels
    },
  },
}
</script>

<style lang="sass" scoped>
.filter-menu-billing
  &-container
    text-align: left
    margin-top: 21px
  &-content
    display: flex
    justify-content: space-between
    .blue-5
      background-color: $blue_5
</style>
