<template>
  <div class="contact-template">
    <div class="contact-template-content">
      <!-- Cabecera y filtros -->
      <a-row>
        <div class="head">
          <a-row>
            <header class="template__header">
              <h5 class="heading-h5 mrg-bottom-0">{{ title }}</h5>
              <a-tooltip placement="bottom">
                <template slot="title">
                  Administra todos tus clientes o si eres agente, solo los que
                  has gestionado.
                  <a
                    href="https://www.securitec.pe/docs/ticker/caracteristicas/contactos/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click aquí
                  </a>
                  si deseas conocer más.
                </template>
                <a-icon type="info-circle" class="template__icon" />
              </a-tooltip>
            </header>
          </a-row>
          <a-row>
            <FilterContactModule
              ref="filterContact"
              @onChangeRange="(range) => (drawerDetails.dateRange = range)"
              @onLoading="handleLoadtable"
            />
          </a-row>
        </div>
      </a-row>
      <!-- Cuerpo y tabla -->
      <a-row>
        <div class="body">
          <TableContactModule
            @handleAction="handleAction"
            @onShowProducts="onShowAssignedProducts"
            :loading="loadingTable"
          />
        </div>
      </a-row>
    </div>
    <!-- Modales -->
    <DrawerDetailsContact
      v-if="client"
      :visible="drawerDetails.visible"
      :data-source-client="client"
      @hide="closeDetailsModal"
      @update="onUpdateClient"
      :date-range="drawerDetails.dateRange"
    />
    <!--Drawer para productos asignados-->
    <assigned-products-drawer
      :visible="drawerProducts.visible"
      :data-source="drawerProducts.data"
      @onClose="handleCloseProducts"
      :show-tickets="true"
    />
  </div>
</template>

<script>
import FilterContactModule from '@/app/contacts/components/organisms/FilterContactModule.vue'
import TableContactModule from '@/app/contacts/components/organisms/TableContactModule.vue'
import DrawerDetailsContact from '@/app/contacts/components/organisms/modals/DrawerDetailsContact.vue'
import AssignedProductsDrawer from '@/app/shared/components/drawers/AssignedProductsDrawer'
import { mapActions } from 'vuex'

export default {
  name: 'ContactTemplate',
  components: {
    FilterContactModule,
    TableContactModule,
    DrawerDetailsContact,
    AssignedProductsDrawer,
  },
  data: () => ({
    title: 'CONTACTOS',
    visibleModalInfo: false,
    client: null,
    drawerDetails: {
      visible: false,
      dateRange: null,
    },
    loadingTable: false,
    drawerProducts: {
      visible: false,
      load: false,
      data: [],
    },
  }),
  methods: {
    ...mapActions(['getAssignedProductsClient']),

    /**
     * Click en algún botón de acciones
     * @param {String} typeAction [details]
     * @param {Client} client
     */
    handleAction(typeAction, client) {
      this.client = client
      if (typeAction === 'details') {
        this.openDetailsModal()
      }
    },
    // Abre el drawer de detalles del cliente
    openDetailsModal() {
      this.drawerDetails.visible = true
    },
    onUpdateClient(client) {
      this.client = { ...client }
      this.$refs.filterContact.filter()
    },
    // Cierra el drawer de detalles del cliente
    closeDetailsModal() {
      this.drawerDetails.visible = false
      this.client = null
    },
    /**
     * Mostrar el drawer de productos asignados
     * @param {String} clientId - id del cliente
     */
    async onShowAssignedProducts(clientId) {
      this.drawerProducts.visible = true
      this.drawerProducts.load = true
      const response = await this.getAssignedProductsClient(clientId)
      if (response.success) this.drawerProducts.data = response.result
      else
        this.$message.error(
          response.details ? response.details : 'Ocurrió un error 😥'
        )
      this.drawerProducts.load = false
    },
    /**
     * Cargar la tabla de productos
     * @param {Boolean} loading
     */
    handleLoadtable(loading) {
      this.loadingTable = loading
    },
    // Cierra el drawer de productos
    handleCloseProducts() {
      this.drawerProducts.visible = false
      this.drawerProducts.data = []
    },
  },
}
</script>

<style lang="sass" scoped>
$margin: 12px

.contact-template
  &-content
    .head
      text-align: left
      margin: $margin
    .body
      margin: 0 $margin
.template
  &__header
    display: flex
    align-items: center
    margin-bottom: 10px
  &__icon
    margin-left: 8px
    font-size: 18px
</style>
