import axios from 'axios'
import moment from 'moment'
import format from '@/app/shared/utils/format'
import vari from '@/app/shared/utils/variables'
import compare from '@/app/shared/utils/compare'
import SimpleCrypto from 'simple-crypto-js'
import { message } from 'ant-design-vue'
moment.locale('es')

const crypt_key = process.env.VUE_APP_CRYPT_KEY
const simpleCrypto = new SimpleCrypto(crypt_key)

const actions = {
  /**
   * Lista los datos de los tickets nuevos para mostrarlos en un grafico
   * @param {vuex}          context
   * @param {Object}        args
   * @param {Array[Moment]} args.rangeDate
   * @param {Boolean}       args.isNow los datos a traer son los más actualizados?
   * @param {String}        args.lineId id de la Cola, pero tambien puede ser [allLines]
   * @param {String}        args.nameRange Nombre de rango [hours,days,months,years]
   */
  async graphNewTickets(context, { rangeDate, isNow, lineId, nameRange }) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'supervisor'])))
        return
      let d = new Date()

      const response = await axios.post(
        `${vari.UHR}/admin/dashboard/tickets/new`,
        {
          rangeDate,
          isNow,
          nameRange,
          lineId,
          timezoneOffset: new Date().getTimezoneOffset(),
        }
      )
      let dataNewTickets = response.data.dataNewTickets
      if (response.data.status === 402) {
        message.warning(response.data.details)
        context.commit('SET_MODAL_UPGRADE', { visible: false })
      }
      if (isNow === true) {
        // Completando las horas faltantes con ceros en cantidad
        for (let hour = 0; hour < d.getHours() + 1; hour++) {
          let hourFound = dataNewTickets.find((el) => el.number === hour)
          if (!hourFound) {
            dataNewTickets.push({
              quantity: 0,
              date: `${hour >= 10 ? '' : '0'}${hour}:00`,
              number: hour,
            })
          }
        }
        dataNewTickets.sort((a, b) => a.number - b.number)
      } else {
        switch (nameRange) {
          case 'days': {
            // Completa dias anteriores al último día obtenido del servidor
            let day = moment(rangeDate[0]).format('D')
            let month = moment(rangeDate[0]).format('MMMM')
            let dateFormat = new Date(dataNewTickets[0].dateFormat)
            let tomorrow = null
            if (rangeDate[0] < dateFormat) {
              let emptyNewTicket = []
              let diff = moment(dateFormat).diff(moment(rangeDate[0]), 'days')
              if (!tomorrow) tomorrow = rangeDate[0]
              for (let i = 0; i < diff; i++) {
                emptyNewTicket.push({
                  date: `${day} ${month}`,
                  number: day,
                  quantity: 0,
                  dateFormat: tomorrow,
                })
                tomorrow = moment(tomorrow, 'DD-MM-YYYY').add(1, 'days')
                day = tomorrow.format('D')
                month = tomorrow.format('MMMM')
              }
              dataNewTickets = [...emptyNewTicket, ...dataNewTickets]
            }
            break
          }
        }
      }
      context.commit('SET_GRAPH_NEW_TICKETS', dataNewTickets)
    } catch (error) {
      console.error('[dashboardModule][graphNewTickets]', error)
    }
  },
  /**
   * Lista los datos de los tiempos promedios de respuesta de tickets para mostrarlos en un grafico
   * @param {vuex}          context
   * @param {Object}        args
   * @param {Array[Moment]} args.rangeDate
   * @param {Boolean}       args.isNow los datos a traer son los más actualizados?
   * @param {String}        args.lineId id de la Cola, pero tambien puede ser [allLines]
   * @param {String}        args.nameRange Nombre de rango [hours,days,months,years]
   */
  async graphStatusesTickets(context, { rangeDate, isNow, lineId, nameRange }) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'supervisor'])))
        return
      const ticketStatuses = ['pendings', 'receiveds', 'opens', 'finished'] // Estados a devolver
      let d = new Date()

      const response = await axios.post(
        `${vari.UHR}/admin/dashboard/tickets/status`,
        {
          rangeDate,
          isNow,
          nameRange,
          lineId,
          timezoneOffset: new Date().getTimezoneOffset(),
        }
      )
      let dataStateTickets = response.data.dataStateTickets
      if (isNow === true) {
        // Completando las horas faltantes con ceros en cantidad
        for (let status of ticketStatuses) {
          for (let hour = 0; hour < d.getHours() + 1; hour++) {
            let hourFound = dataStateTickets[`${status}`].find(
              (el) => el.number === hour
            )
            if (!hourFound) {
              dataStateTickets[`${status}`].push({
                quantity: 0,
                date: `${hour >= 10 ? '' : '0'}${hour}:00`,
                number: hour,
              })
            }
          }
          dataStateTickets[`${status}`].sort((a, b) => a.number - b.number)
        }
      } else {
        switch (nameRange) {
          case 'days': {
            // Completa los días faltantes de los estados
            for (let status of ticketStatuses) {
              // Completa dias anteriores al último día obtenido del servidor
              let day = moment(rangeDate[0]).format('D')
              let month = moment(rangeDate[0]).format('MMMM')
              let dateFormat = new Date(
                dataStateTickets[`${status}`][0].dateFormat
              )
              let tomorrow = null
              if (rangeDate[0] < dateFormat) {
                let emptyStatusTicket = []
                let diff = moment(dateFormat).diff(moment(rangeDate[0]), 'days')
                if (!tomorrow) tomorrow = rangeDate[0]
                for (let i = 0; i < diff; i++) {
                  emptyStatusTicket.push({
                    date: `${day} ${month}`,
                    number: day,
                    quantity: 0,
                    dateFormat: tomorrow,
                  })
                  tomorrow = moment(tomorrow, 'DD-MM-YYYY').add(1, 'days')
                  day = tomorrow.format('D')
                  month = tomorrow.format('MMMM')
                }
                dataStateTickets[`${status}`] = [
                  ...emptyStatusTicket,
                  ...dataStateTickets[`${status}`],
                ]
              }
            }
            dataStateTickets = { ...dataStateTickets }
            break
          }
        }
      }
      context.commit('SET_GRAPH_STATUSES_TICKETS', dataStateTickets)
    } catch (error) {
      console.error('[dashboardModule][graphAverageAnswer]', error)
    }
  },
  /**
   * Lista los datos de los tiempos promedios de respuesta de tickets para mostrarlos en un grafico
   * @param {vuex}          context
   * @param {Object}        args
   * @param {Array[Moment]} args.rangeDate
   * @param {Boolean}       args.isNow los datos a traer son los más actualizados?
   * @param {String}        args.lineId id de la Cola, pero tambien puede ser [allLines]
   * @param {String}        args.nameRange Nombre de rango [hours,days,months,years]
   */
  async graphSMS(context, { rangeDate, isNow, lineId, nameRange }) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'supervisor'])))
        return

      const response = await axios.post(`${vari.UHR}/admin/dashboard/sms`, {
        rangeDate,
        isNow,
        nameRange,
        lineId,
        timezoneOffset: new Date().getTimezoneOffset(),
      })

      let dataSMS = response.data.dashboard
      context.commit('SET_GRAPH_SMS', dataSMS)
    } catch (error) {
      console.error('[dashboardModule][graphAverageAnswer]', error)
    }
  },
  /**
   * Lista los datos de los tiempos promedios de respuesta de tickets para mostrarlos en un grafico
   * @param {vuex}          context
   * @param {Object}        args
   * @param {Array[Moment]} args.rangeDate
   * @param {Boolean}       args.isNow los datos a traer son los más actualizados?
   * @param {String}        args.lineId id de la Cola, pero tambien puede ser [allLines]
   * @param {String}        args.nameRange Nombre de rango [hours,days,months,years]
   */
  async graphTemplate(context, { rangeDate, isNow, lineId, nameRange }) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'supervisor'])))
        return
      const response = await axios.post(
        `${vari.UHR}/admin/dashboard/templates`,
        {
          rangeDate,
          isNow,
          nameRange,
          lineId,
          timezoneOffset: new Date().getTimezoneOffset(),
        }
      )
      let dataTemplate = response.data.dashboard
      context.commit('SET_GRAPH_TEMPLATE', dataTemplate)
    } catch (error) {
      console.error('[dashboardModule][graphAverageAnswer]', error)
    }
  },
  /**
   * Lista los datos de las etiquetas para mostrarlos en un grafico
   * @param {vuex}          context
   * @param {Object}        args
   * @param {Array[Moment]} args.rangeDate
   * @param {Boolean}       args.isNow los datos a traer son los más actualizados?
   * @param {String}        args.lineId id de la Cola, pero tambien puede ser [allLines]
   */
  async graphTags(context, { rangeDate, isNow, lineId }) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'supervisor'])))
        return

      const response = await axios.post(`${vari.UHR}/admin/dashboard/tags`, {
        rangeDate,
        isNow,
        lineId,
      })
      let totalTags = response.data.totalTags
      if (lineId === 'allLines') {
        // Completando las colas que no estaban
        if (totalTags.length) {
          totalTags = totalTags.map((ttag) => {
            for (let line of context.getters.lines) {
              let tlineFound = ttag.lines.find(
                (tline) => tline.lineId === line._id
              )
              if (!tlineFound) {
                ttag.lines.push({ nameLine: line.name, quantity: 0 })
              }
            }
            return ttag
          })
        } else {
          for (let tag of context.getters.company.settings.tags) {
            let lines = []
            for (let line of context.getters.lines) {
              lines.push({ nameLine: line.name, quantity: 0 })
            }
            totalTags.push({ color: tag.color, name: tag.title, lines: lines })
          }
        }
      } else {
        // Completando las colas que no estaban
        if (!totalTags.length) {
          let line = context.getters.lines.find((line) => line._id === lineId)
          for (let tag of context.getters.company.settings.tags) {
            totalTags.push({
              color: tag.color,
              name: tag.title,
              lines: [{ nameLine: line.name, quantity: 0 }],
            })
          }
        }
      }
      // console.log('totalTags',totalTags)
      context.commit('SET_GRAPH_TOTAL_TAGS', totalTags)
    } catch (error) {
      console.error('[dashboardModule][graphTags]')
    }
  },
  /**
   * Lista los datos de las etiquetas para mostrarlos en un grafico
   * @param {vuex}          context
   * @param {Object}        args
   * @param {Array[Moment]} args.rangeDate
   * @param {Boolean}       args.isNow los datos a traer son los más actualizados?
   * @param {String}        args.lineId id de la Cola, pero tambien puede ser [allLines]
   * @param {String}        args.agentId id de usuario de un agente
   */
  async graphSchedules(context, { rangeDate, isNow, lineId, agentId }) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'supervisor'])))
        return
      rangeDate = [
        rangeDate[0],
        moment(rangeDate[1]).set({ hour: 23, minute: 59 }),
      ]
      const stateAgends = await axios.post(
        `${vari.UHR}/admin/dashboard/schedules`,
        { rangeDate, isNow, lineId, agentId }
      )
      context.commit('SET_GRAPH_SCHEDULES', stateAgends.data.stateAgends)
    } catch (error) {
      console.error('[dashboardModule][graphTags]')
    }
  },
  /**
   * Lista los datos de los tiempos promedios de los estados nuevos para mostrarlos en un grafico
   * @param {vuex}          context
   * @param {Object}        args
   * @param {Array[Moment]} args.rangeDate
   * @param {Boolean}       args.isNow los datos a traer son los más actualizados?
   * @param {String}        args.lineId id de la Cola, pero tambien puede ser [allLines]
   * @param {String}        args.agentId id de usuario de un agente
   * @param {String[]}      args.statuses [connected, bussy, training, services]
   */
  async graphAverageStatuses(
    context,
    { rangeDate, isNow, lineId, agentId, statuses }
  ) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'supervisor'])))
        return

      const response = await axios.post(
        `${vari.UHR}/admin/dashboard/status/average`,
        { rangeDate, isNow, lineId, agentId, statuses }
      )
      let averageStatuses = response.data.averageStatuses

      averageStatuses = averageStatuses.map((st, index) => {
        const array = [0, 0, 0, 0]
        st.time = `${(st.time / 60).toFixed(2)}` // De segundos a minutos y trunca a 2 decimales
        array[index] = st.time // completar array de acuerdo  a la posicion
        st.data = array
        return st
      })
      context.commit('SET_GRAPH_AVERAGE_STATUSES', averageStatuses)
    } catch (error) {
      console.error('[dashboardModule][graphAverageStatuses]')
      return error.response?.data
    }
  },
  /**
   * Lista los datos de los tiempos promedios de respuesta de tickets para mostrarlos en un grafico
   * @param {vuex}          context
   * @param {Object}        args
   * @param {Array[Moment]} args.rangeDate
   * @param {Boolean}       args.isNow los datos a traer son los más actualizados?
   * @param {String}        args.lineId id de la Cola, pero tambien puede ser [allLines]
   */
  async graphAverageAnswer(context, { rangeDate, isNow, lineId }) {
    try {
      if (!(await compare.isAllowedFor(context, ['admin', 'supervisor'])))
        return

      const response = await axios.post(
        `${vari.UHR}/admin/dashboard/tickets/answer/average`,
        { rangeDate, isNow, lineId }
      )
      let averageAnswerLines = response.data.averageAnswerLines

      averageAnswerLines = averageAnswerLines.map((st) => {
        const minsFormatted = Math.floor(st.time / 60)
        const secondsFormatted = 60 * (st.time % 1)
        st.timeFormatted = `${
          minsFormatted.toString().length <= 1
            ? '0' + minsFormatted
            : minsFormatted
        }:${
          secondsFormatted.toString().length <= 1
            ? '0' + secondsFormatted
            : secondsFormatted.toString().split('.')[0] // toma el número antes del punto , ejemplo: 17.5 -> 17
        }` // A min:seg
        st.time = `${(st.time / 60).toFixed(2)}` // De segundos a minutos y trunca a 2 decimales
        return st
      })

      context.commit('SET_GRAPH_AVERAGE_ANSWERS_LINES', averageAnswerLines)
    } catch (error) {
      console.error('[dashboardModule][graphAverageAnswer]')
    }
  },
  /**
   * Exporta el grafico de los tickets nuevos
   * @param {*}       context
   * @param {String}  date fecha o rango del filtro
   * @param {String}  lineId id de la cola
   */
  exportNewTickets(context, { date, lineId }) {
    let response = null
    const lineIdCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(lineId))
    const dateCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(date))
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${
      vari.UHM
    }/export/tickets/new?lineId=${lineIdCrypto}&token=${token}&date=${dateCrypto}&timezoneOffset=${new Date().getTimezoneOffset()}`
    response = window.open(url)
    return response
  },

  /**
   * Exporta el grafico de los estados de los tickets
   * @param {*}       context
   * @param {String}  date  fecha o rango del filtro
   * @param {String}  companyId  id de la compañia
   * @param {String}  lineId id de la cola
   */
  exportStatusesTickets(context, { date, lineId }) {
    let response = null
    const lineIdCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(lineId))
    const dateCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(date))
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${
      vari.UHM
    }/export/tickets/states?lineId=${lineIdCrypto}&token=${token}&date=${dateCrypto}&timezoneOffset=${new Date().getTimezoneOffset()}`
    response = window.open(url)
    return response
  },
  /**
   * Exporta el grafico de los estados de los tickets
   * @param {*}       context
   * @param {String}  date  fecha o rango del filtro
   * @param {String}  companyId  id de la compañia
   * @param {String}  lineId id de la cola
   */
  exportSMS(context, { date, lineId }) {
    let response = null
    const lineIdCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(lineId))
    const dateCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(date))
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${
      vari.UHM
    }/export/sms?lineId=${lineIdCrypto}&token=${token}&date=${dateCrypto}&timezoneOffset=${new Date().getTimezoneOffset()}`
    response = window.open(url)
    return response
  },
  /**
   * Exporta el grafico de los estados de los tickets
   * @param {*}       context
   * @param {String}  date  fecha o rango del filtro
   * @param {String}  companyId  id de la compañia
   * @param {String}  lineId id de la cola
   */
  exportTemplate(context, { date, lineId }) {
    let response = null
    const lineIdCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(lineId))
    const dateCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(date))
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${
      vari.UHM
    }/export/templates?lineId=${lineIdCrypto}&token=${token}&date=${dateCrypto}&timezoneOffset=${new Date().getTimezoneOffset()}`
    response = window.open(url)
    return response
  },
  /**
   * Exporta el grafico de total de etiquetas
   * @param {*}       context
   * @param {String}  date  fecha o rango del filtro
   * @param {String}  companyId  id de la compañia
   * @param {String}  lineId id de la cola
   */
  exportTotalTags(context, { date, lineId }) {
    let response = null
    const lineIdCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(lineId))
    const dateCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(date))
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${
      vari.UHM
    }/export/tags/lines?lineId=${lineIdCrypto}&token=${token}&date=${dateCrypto}&timezoneOffset=${new Date().getTimezoneOffset()}`
    response = window.open(url)
    return response
  },
  /**
   * Exporta el grafico de resumen de agendas
   * @param {*}       context
   * @param {String}  date  fecha o rango del filtro
   * @param {String}  lineId id de la cola
   * @param {String}  agentId id del agente
   */
  exportSchedules(context, { date, lineId, agentId }) {
    let response = null
    const lineIdCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(lineId))
    const agentIdCrypto = format.fixedEncodeURIComp(
      simpleCrypto.encrypt(agentId)
    )
    const dateCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(date))
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${
      vari.UHM
    }/export/schedule/resume?lineId=${lineIdCrypto}&agentId=${agentIdCrypto}&token=${token}&date=${dateCrypto}&timezoneOffset=${new Date().getTimezoneOffset()}`
    response = window.open(url)
    return response
  },

  /**
   * Exporta el grafico de promedio de estados
   * @param {*}         context
   * @param {String}    date  fecha o rango del filtro
   * @param {String}    lineId id de la cola
   * @param {String}    agentId id del agente
   * @param {String[]}  statuses [connected, bussy, training, services]
   */
  exportAverageStatuses(context, { date, lineId, agentId, statuses }) {
    if (!date || !statuses)
      throw new Error('params required for exportAverageStatuses')
    let response = null
    const lineIdCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(lineId))
    const agentIdCrypto = format.fixedEncodeURIComp(
      simpleCrypto.encrypt(agentId)
    )
    const dateCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(date))
    const token = format.fixedEncodeURIComp(context.getters.token)
    const statusesCryto = format.fixedEncodeURIComp(
      simpleCrypto.encrypt(JSON.stringify(statuses.join(','))) // Separado por comas
    )
    const url = `${
      vari.UHM
    }/export/agents/states?lineId=${lineIdCrypto}&agentId=${agentIdCrypto}&token=${token}&date=${dateCrypto}&statuses=${statusesCryto}&timezoneOffset=${new Date().getTimezoneOffset()}`
    response = window.open(url)
    return response
  },

  /**
   * Exporta el grafico de promedio de respuesta
   * @param {*}       context
   * @param {String}  date  fecha o rango del filtro
   * @param {String}  lineId  id de la cola
   */
  exportAverageAnswer(context, { date, lineId }) {
    let response = null
    const lineIdCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(lineId))
    const dateCrypto = format.fixedEncodeURIComp(simpleCrypto.encrypt(date))
    const token = format.fixedEncodeURIComp(context.getters.token)
    const url = `${
      vari.UHM
    }/export/tickets/answer?lineId=${lineIdCrypto}&token=${token}&date=${dateCrypto}&timezoneOffset=${new Date().getTimezoneOffset()}`
    response = window.open(url)
    return response
  },
}

export default actions
