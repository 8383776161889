<template>
  <div class="card">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CardBase',
}
</script>

<style lang="sass" scoped>
.card
  border: 1px solid $gray_5
  padding: 20px
  border-radius: 8px
</style>
