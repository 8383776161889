<template>
  <div class="section-menu-market">
    <section-market
      @addToCart="addPurchase"
      @downgrade="showDowngrade"
      @cancelDowngrade="startCancelDowngrade"
      @onShowCart="handleShowCart"
    />

    <drawer-cart
      :visible="drawerCart.visible"
      :purchases="purchase.products"
      :loading="loading"
      @onClose="handleCloseCart"
      @onSubmit="handleShowSelectCredit"
    />
    <!--Drawer de selección de creditos-->
    <a-drawer
      title="Escoge tu tarjeta"
      :width="670"
      :visible="drawerSelectCredit.visible"
      :body-style="{ padding: '40px 40px 80px 40px' }"
      @close="handleCloseSelect"
      :maskClosable="false"
    >
      <a-alert
        v-if="drawerSelectCredit.error.visible"
        :type="drawerSelectCredit.error.type"
        show-icon
        style="width: 100%"
        class="drawer__alert"
      >
        >
        <p slot="message" class="mrg-bottom-0">
          {{ drawerSelectCredit.error.message }}. Si necesitas ayuda,
          <router-link
            :to="{ name: 'support' }"
            target="_blank"
            rel="noopener noreferrer"
            >contáctate con nuestro soporte</router-link
          >.
        </p>
      </a-alert>
      <list-cards
        @onSelect="handleSelectCard"
        @onAdd="handleShowSettingCredit"
        :idCardSelected="creditCardSelected.idCard"
      />
      <basic-footer
        okText="Pagar"
        cancelText="Cancelar"
        @onCancel="handleCloseSelect"
        @onOk="handleSubmitSelect"
        :okLoading="drawerSelectCredit.okLoading"
      />
    </a-drawer>
    <!--Drawer de configuración-->
    <a-drawer
      title="Configura tu tarjeta de crédito o débito"
      :width="600"
      :visible="drawerSettingCredit.visible"
      :body-style="{
        padding: '32px 40px',
        height: 'calc(100% - 108px)',
        display: 'flex',
        flexDirection: 'column',
      }"
      @close="handleCloseSetting"
      :maskClosable="false"
    >
      <form-setting-credit
        ref="formSettingCredit"
        class="flex-grow"
        @onSubmit="onPayment"
        :showError="drawerSettingCredit.error.visible"
        :messageError="drawerSettingCredit.error.message"
        :typeError="drawerSettingCredit.error.type"
      />
      <div class="form__extra-container display-flex align--center">
        <a-icon type="info-circle" class="form__icon-info mrg-right-8" />
        <p class="mrg-bottom-0 regular-14">
          Para validar tu tarjeta, Ticker realizará un cobro de $3 que luego
          será reembolsado.
          <span v-if="false"> Más información <a href="">aquí</a></span>
        </p>
      </div>
      <basic-footer
        :okText="`Pagar $ ${purchase.total}`"
        cancelText="Cancelar"
        @onCancel="handleCloseSetting"
        @onOk="handleOkSetting"
        :okLoading="drawerSettingCredit.okLoading"
      />
    </a-drawer>
    <!--Modal CVV-->
    <modal-cvv
      :visible="modalCvv.visible"
      @onClose="modalCvv.visible = false"
      @onOk="handleOkCvv"
      :loading="modalCvv.okLoading"
    />
    <!--Modal de compra exitosa-->
    <ModalSuccessPayment
      :visible="modalSuccessPayment.visible"
      :title="modalSuccessPayment.title"
      @hideModal="() => (modalSuccessPayment.visible = false)"
    />
    <!-- Modal de solicitud de downgrade -->
    <DownLicense
      :visible="modalDowngrade.visibleInput"
      type="info-input"
      :title="`¿Estás segur@ de bajar de plan a “${modalDowngrade.titleSelected}”?`"
      :description="modalDowngrade.description"
      primaryButton="Comenzar con el proceso"
      secondaryButton="Seguir en mi plan"
      @hideModal="hideModalDowngrade"
      @done="doneDownLicense"
      :plan="planToDowngrade"
    />
    <!-- Modal de solicitud de cancelación de downgrade -->
    <DownLicense
      :visible="modalDowngrade.visibleInputCancel"
      type="details-input"
      :title="`¿Estás segur@ de cancelar tu proceso de baja de “${modalDowngrade.titleSelected}”?`"
      description="Recuerda que tu proceso se finalizará y seguirás con tus beneficios actuales."
      detailsText="Ver más detalles"
      :detailsURL="vari.WEB_PRICING"
      primaryButton="Cancelar el proceso"
      secondaryButton="Seguir con mi proceso"
      keyword="CANCELAR"
      @hideModal="hideModalDowngrade"
      @done="doneCancelDowngrade"
      :plan="planToDowngrade"
    />
    <FormChooseDowngrade
      v-if="modalDowngrade.formDowngrade"
      :visible="modalDowngrade.formDowngrade"
      :minimalQuantityDay="minimalQuantityDay"
      :plan="planToDowngrade"
      type="downgrade"
      @hide="handleCloseModal"
    />
  </div>
</template>

<script>
import vari from '@/app/shared/utils/variables'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SectionMarket from '@/app/shop/components/market/SectionMarket'
import DownLicense from '@/app/shop/components/DownLicense.vue'
import FormChooseDowngrade from '@/app/shop/components/market/downgrade/FormChooseDowngrade.vue'
import DrawerCart from '@/app/shop/components/market/drawer/DrawerCart'
import BasicFooter from '@/app/shop/components/market/drawer/BasicFooter'
import ListCards from '@/app/shop/components/paymentMethods/ListCards'
import FormSettingCredit from '@/app/shop/components/market/FormSettingCredit'
import ModalCvv from '@/app/shop/components/market/settingsCreditCard/ModalCvv'
import attemptMixin from '@/app/shared/mixins/attempt'
import ModalSuccessPayment from '@/app/shop/components/market/settingsCreditCard/ModalSuccessPayment'

export default {
  name: 'SectionMenuMarket',
  mixins: [attemptMixin],
  components: {
    SectionMarket,
    DownLicense,
    FormChooseDowngrade,
    DrawerCart,
    BasicFooter,
    ListCards,
    FormSettingCredit,
    ModalCvv,
    ModalSuccessPayment,
  },
  data: () => ({
    vari: vari,
    loading: false, // cargando el carro de compras
    planToDowngrade: {},
    modalDowngrade: {
      titleSelected: '',
      visibleInput: false,
      visibleInputCancel: false,
      formDowngrade: false,
      description: '',
    },
    drawerCart: {
      visible: false,
    },
    drawerSelectCredit: {
      visible: false,
      error: {
        visible: false,
        message: '',
        type: '',
      },
      okLoading: false,
    },
    drawerSettingCredit: {
      visible: false,
      error: {
        visible: false,
        message: '',
        type: '',
      },
      okLoading: false,
    },
    creditCardSelected: {
      idCard: null,
      isNew: false,
      securityCode: null,
    },
    modalCvv: {
      visible: false,
      okLoading: false,
    },
    modalSuccessPayment: {
      visible: false,
    },
  }),
  mounted() {
    this.intervalAttempt(() => {
      if (!this.profile) throw 'Profile not loaded'
      this.creditCardSelected.idCard = this.profile.cards[0].idCard
    })
  },
  computed: {
    ...mapGetters(['profile', 'purchase', 'products', 'minimalQuantityDay']),
  },
  methods: {
    ...mapMutations([
      'ADD_PRODUCT_TO_PURCHASE',
      'REMOVE_PRODUCT_TO_PURCHASE',
      'SET_IS_ALLOW_BUY_PLANS',
      'SET_TOTAL_TO_PURCHASE',
      'RESET_PURCHASE',
    ]),
    ...mapActions([
      'downgrade',
      'checkDowngrade',
      'pricePerUser',
      'cancelDowngrade',
      'getCompanyPricing',
      'paymentPurchase',
    ]),

    handleCloseModal() {
      this.modalDowngrade.formDowngrade = false
    },
    /**
     * Añadir una compra
     * @param {Object} item Plan escogido o bolsa
     */
    async addPurchase(item) {
      let purchase = {}
      this.loading = true
      // si se va agregar un plan
      if (item.type === 'plan') {
        const response = await this.pricePerUser({ pricingId: item._id })
        purchase = {
          _id: `purchase${item._id}${new Date()}`,
          idPlan: item._id,
          totalUsers: response.quantity,
          price: response.pricePlan,
          type: item.type,
          title: item.name,
          description: `Precio por ${response.quantity} usuarios`,
          total: response.total,
          icon: {
            src: '@/app/shared/assets/evericons/various-rocket.svg',
            custom: true,
          },
        }
      } else {
        // si es un paquete
        const pack = this.products.find(
          (product) => product._id === item.idPack
        )
        purchase = {
          _id: `purchase${item.idPack}${new Date()}`,
          idPresentation: item.idPresentation,
          idProduct: item.idPack,
          type: pack.type,
          amount: item.amount,
          permission: pack.permission,
          price: item.price,
          total: item.total,
          title: `${item.amount} ${pack.name}`,
          description: item.country
            ? `SMS para ${item.countryName}`
            : pack.subtitle.es,
          country: item.country,
          icon: pack.icon,
        }
      }

      this.ADD_PRODUCT_TO_PURCHASE(purchase)
      const total = this.purchase.products.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total,
        0
      )
      this.SET_TOTAL_TO_PURCHASE(total)
      this.loading = false
      this.$message.success('Se agregó un producto a tu compra')
      this.drawerCart.visible = true
    },
    removePurchase(idPurchase) {
      this.REMOVE_PRODUCT_TO_PURCHASE(idPurchase)
      const total = this.purchase.products.reduce(
        (accumulator, currentValue) => accumulator + currentValue.total,
        0
      )
      this.SET_TOTAL_TO_PURCHASE(total)
    },
    /**
     * Degradar el plan al plan seleccionado
     * @param {Object} plan Objeto del plan seleccionado
     */
    showDowngrade(plan) {
      this.planToDowngrade = plan
      if (plan.price !== null) {
        this.modalDowngrade.titleSelected = plan.name
        this.modalDowngrade.visibleInput = true
        switch (plan.key) {
          case 'business':
            {
              this.modalDowngrade.description =
                'Para bajar de plan, por favor comunícate con tu Key Account Manager. Él o ella te ayudará con todo el proceso de baja, o también puedes comunicarte con nuestro equipo de soporte.'
            }
            break
          default: {
            this.modalDowngrade.description =
              'Recuerda que 3 días antes del término de tu facturación actual se te solicitará que selecciones los datos que se quedarán contigo en tu nuevo plan acorde a sus restricciones.'
          }
        }
      }
    },
    hideModalDowngrade() {
      this.modalDowngrade.visibleInput = false
      this.modalDowngrade.visibleInputCancel = false
    },
    /**
     * Verificar la solicitud del downgrade
     */
    async doneDownLicense() {
      const response = await this.checkDowngrade()
      if (response.diffDays <= this.minimalQuantityDay) {
        this.modalDowngrade.formDowngrade = true
        window.localStorage.setItem('formDowngrade', 'visible')
      } else {
        await this.downgrade({
          type: 'downgrade',
          pricingId: this.planToDowngrade._id,
        })
        this.getCompanyPricing()
      }
    },
    /**
     * Iniciar Cancelaciión de degradación de plan seleccionado
     * @param {Object} plan Objeto del plan seleccionado
     * @param {String} plan.name Nombre plan seleccionado
     */
    async startCancelDowngrade(plan) {
      this.modalDowngrade.titleSelected = plan.name
      this.modalDowngrade.visibleInputCancel = true
    },
    /**
     * Cancelar degradación de plan seleccionado
     */
    async doneCancelDowngrade() {
      const res = await this.cancelDowngrade()
      if (res.success === false)
        this.$message.error(
          `Hubo un error al solicitar la cancelación del downgrade`
        )
      this.getCompanyPricing()
    },
    handleShowCart() {
      this.drawerCart.visible = true
    },
    handleCloseCart() {
      this.drawerCart.visible = false
    },
    handleShowSelectCredit() {
      this.drawerCart.visible = false
      this.drawerSelectCredit.visible = true
    },
    handleShowSettingCredit() {
      this.drawerSelectCredit.visible = false
      this.drawerSettingCredit.visible = true
    },
    handleCloseSelect() {
      this.drawerSelectCredit = {
        visible: false,
        error: {
          visible: false,
          message: '',
          type: '',
        },
        okLoading: false,
      }
      this.modalCvv = {
        visible: false,
        okLoading: false,
      }
    },
    handleCloseSetting() {
      this.$refs.formSettingCredit?.resetForm()
      this.drawerSettingCredit = {
        visible: false,
        error: {
          visible: false,
          message: '',
          type: '',
        },
        okLoading: false,
      }
      this.modalCvv = {
        visible: false,
        okLoading: false,
      }
    },
    handleSelectCard(card) {
      this.creditCardSelected.idCard = card.idCard
    },
    async onPayment(settings) {
      /**Si es una tarjeta que ya tiene */
      if (!settings.isNew) {
        this.drawerSelectCredit.okLoading = true
      } else {
        /**Si se esta agregando una nueva tarjeta */
        this.drawerSettingCredit.okLoading = true
      }

      const response = await this.paymentPurchase({
        card: { ...settings },
        purchase: this.purchase,
      })
      // si es una compra de productos o de planes
      if (response.presentationsPayment || response.planPayment) {
        const payment = response.presentationsPayment || response.planPayment
        // si el estado es aprobado o pendiente se considerará como exitoso
        const walletPending = response.presentationsPayment?.rechargeResults
          ? response.presentationsPayment?.rechargeResults.find(
              (recharge) => recharge.message == 'general-wallet-insufficient'
            )
          : false
        if (walletPending) {
          this.$message.success(
            'El pago de tu recarga se realizó con éxito, sin embargo está pendiente de efectuarse',
            20
          )
          this.handleCloseSelect()
          this.handleCloseSetting()
          this.RESET_PURCHASE()
        } else {
          const resultPayment = payment.payment || payment
          switch (resultPayment.status) {
            case 'approved': {
              this.$message.success(
                response.presentationsPayment
                  ? 'El pago de tus productos se realizaron con éxito'
                  : 'El pago de tu plan se realizo con éxito'
              )

              this.handleCloseSetting()
              this.handleCloseSelect()
              this.RESET_PURCHASE()
              break
            }
            case 'pending': {
              this.$message.success(
                'Tu pago aún esta pendiente pero igual podras usar todos los beneficios'
              )
              this.handleCloseSetting()
              this.handleCloseSelect()
              this.RESET_PURCHASE()
              break
            }
            case 'rejected': {
              this.$message.error('Pago rechazado')
              if (this.drawerSelectCredit.visible) {
                this.drawerSelectCredit.error.message =
                  resultPayment.rejectionDetail
                    ? `No pudimos procesar su pago 😓. ${resultPayment.rejectionDetail}, por favor revise los datos ingresados o intente con una nueva tarjeta`
                    : 'No pudimos procesar tu pago 😓, por favor revise los datos ingresados o intente con una nueva tarjeta'
                this.drawerSelectCredit.error.visible = true
                this.drawerSelectCredit.error.type = 'error'
              }
              /**Si es el modal de setting */
              if (this.drawerSettingCredit.visible) {
                this.drawerSettingCredit.error.message =
                  resultPayment.rejectionDetail
                    ? `No pudimos procesar su pago 😓. ${resultPayment.rejectionDetail}, por favor revise los datos ingresados o intente con una nueva tarjeta`
                    : 'No pudimos procesar tu pago 😓, por favor revise los datos ingresados o intente con una nueva tarjeta'
                this.drawerSettingCredit.error.type = 'error'
                this.drawerSettingCredit.error.visible = true
              }
              break
            }
          }
        }
        // si esta subiendo desde el plan free
        if (response.planPayment?.success && response.planPayment?.fromFree) {
          this.handleCloseSetting()
          this.handleCloseSelect()
          this.modalSuccessPayment.visible = true
          this.modalSuccessPayment.title =
            '¡Mejoraste tu suscripción de Ticker!'
          this.RESET_PURCHASE()
        }
      } else {
        if (response.result) {
          this.$message.error(response.result + ' 😓')
        } else
          this.$message.error(
            'No pudimos procesar tu pago 😓, por favor revisa tus datos'
          )
      }

      this.drawerSelectCredit.okLoading = false
      this.drawerSettingCredit.okLoading = false
    },
    handleSubmitSelect() {
      const creditCard = this.profile.cards.find(
        (card) => card.idCard === this.creditCardSelected.idCard
      )
      this.creditCardSelected.isFirstTime = creditCard.isFirstTime
      if (!this.creditCardSelected.isFirstTime) {
        this.onPayment(this.creditCardSelected)
        return
      }
      this.modalCvv.visible = true
    },
    async handleOkCvv(securityCode) {
      this.modalCvv.okLoading = true
      this.creditCardSelected.securityCode = securityCode
      await this.onPayment(this.creditCardSelected)
      this.modalCvv.okLoading = false
      this.modalCvv.visible = false
    },
    handleOkSetting() {
      this.$refs.formSettingCredit.handleSubmit()
    },
  },
}
</script>

<style lang="sass" scoped>
.height-100-percent
  height: 100%
  background-color: $white_000
.section-menu-market
  height: 100%
  .row
    padding: 20px
.form__icon-info
  font-size: 17px
  color: $gray_8
.drawer__alert
  margin-bottom: 20px
</style>
