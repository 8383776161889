<template>
  <div class="section-ticket-details scrollbar_basic">
    <h6 class="heading-h7 section__title">Detalles de ticket</h6>
    <!--Campos personalizados-->
    <ul class="group custom-fields">
      <h6 class="heading-h7">Campos personalizados</h6>
      <p v-if="customFields.length === 0" class="empty">
        No hay campos personalizados
      </p>
      <template v-for="field in customFields">
        <li :key="field.customFieldId" class="">
          <p class="body-2">{{ field.name }}:</p>
          <p class="body2">{{ valueCustomField(field) }}</p>
        </li>
      </template>
    </ul>
    <!--Etiquetas-->
    <ul class="group">
      <h6 class="heading-h7">Etiquetas</h6>
      <p v-if="tags.length === 0" class="empty">No hay etiquetas</p>
      <template v-for="tag in tags">
        <li :key="tag.tagId">
          <ColorPick
            :color="tag.color"
            :pickStyles="{ borderRadius: '8px', height: '8px', width: '25px' }"
          />
          <p style="margin-left: 8px" class="body-2">{{ tag.title }}</p>
        </li>
      </template>
    </ul>
    <!--integraciones-->
    <ul class="group">
      <h6 class="heading-h7">Integraciones</h6>
      <p v-if="integrations.length === 0" class="empty">No hay integraciones</p>
      <template v-for="integration in integrations">
        <li :key="integration.integrationId">
          <p class="body-2">{{ integration.platform }}</p>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import ColorPick from '@/app/shared/components/pickers/ColorPick.vue'
import moment from 'moment'

export default {
  name: 'SectionTicketDetails',
  props: {
    customFields: { require: false, type: Array },
    tags: { require: false, type: Array },
    integrations: { require: false, type: Array },
  },
  components: {
    ColorPick,
  },
  data: () => ({}),
  computed: {},
  methods: {
    valueCustomField(customField) {
      switch (customField.type) {
        case 'text':
          return customField.value.text
        case 'dropdown': {
          let options = ''

          customField.value.options.forEach((option) => {
            options.length > 0
              ? (options += `, ${option.name}`)
              : (options += `${option.name}`)
          })
          return options
        }
        case 'date':
          return moment(customField.value.date).format('LLL')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.section-ticket-details
  padding: 20px
  height: 100%
  overflow-y: auto
  @include xs
    padding: 12px 20px
    border-top: 1px solid $gray_6
  @include sm
    padding: 12px 20px
    border-top: 1px solid $gray_6
  @include md
    padding: 12px 20px
    border-top: 1px solid $gray_6
  >h6
    color: $gray_dark_900
    margin-bottom: 0
  .section__title
    @include xs
      display: none
    @include sm
      display: none
    @include md
      display: none
  .group
    margin-top: 16px
    padding: 0
    @include sm
      margin-top: 0px
    @include xs
      margin-top: 0px
    @include md
      margin-top: 0px
    &:first-child
      margin-top: 8px !important
    h6
      color: $gray_dark_800
    li
      list-style: none
      margin-bottom: 4px
      display: flex
      flex-direction: row
      align-items: center
      p
        margin-bottom: 0
        color: $gray_dark_600
        display: inline-block
    .empty
      color: $gray_dark_500
  .custom-fields
    li
      display: block
      p:first-child
        margin-right: 12px
        color: $gray_dark_800
        display: inline-block;
</style>
