<template>
  <div class="preview" :class="{ 'preview--default': !text }">
    {{ text || defaultText }}
  </div>
</template>

<script>
export default {
  name: 'PreviewMessage',
  props: {
    text: {
      type: String,
      required: false,
      default: null,
    },
    defaultText: {
      type: String,
      required: false,
      default:
        'Selecciona una plantilla, para previsualizar el contenido de una plantilla.',
    },
  },
}
</script>

<style lang="sass" scoped>
$bg-blue: #373E4E
.preview
  background-color: $bg-blue
  padding: 12px 16px
  color: $white_000
  border-radius: 4px
  &--default
    color: $gray_dark_400
</style>
