<template>
  <div
    class="card"
    :class="{ 'card--active': active, 'card--inactive': !active }"
  >
    <div class="card__content">
      <div>
        <h6 class="card__title heading-h7">{{ title }}</h6>
        <p class="card__description body-2 mrg-bottom-0">{{ description }}</p>
      </div>
      <div class="card__switch">
        <a-tooltip placement="bottomRight">
          <template slot="title" v-if="showTooltip">
            <span>{{ tooltipText }}</span>
          </template>
          <a-switch
            v-model="activeSwitcher"
            @change="$emit('onChangeActive', activeSwitcher)"
            :loading="loading"
            :disabled="disabled"
          />
        </a-tooltip>
      </div>
    </div>
    <slot name="extra-content"></slot>
  </div>
</template>

<script>
export default {
  name: 'CardSwitch',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Switcher card',
    },
    description: {
      type: String,
      required: false,
      default:
        'Fugiat minim amet ex eiusmod minim minim adipisicing culpa eiusmod ipsum ut tempor Lorem Lorem. Mollit sit ut in id labore proident cillum. Aliquip ullamco magna deserunt adipisicing non.',
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltipText: {
      type: String,
      required: false,
      default: 'This is message example',
    },
    showTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    activeSwitcher: false,
  }),
  mounted() {
    this.activeSwitcher = this.active
  },
  watch: {
    active(newVal) {
      this.activeSwitcher = newVal
    },
    loading() {
      this.activeSwitcher = this.active
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  padding: 12px
  border: 1px solid $gray_5
  border-radius: 8px
  &__content
    display: flex
    text-align: left
    justify-content: space-between
  &__switch
    margin-left: 24px
    height: fit-content
  &--inactive
    color: $gray_dark_400
    .card__title
      color: $gray_dark_400
  &--active
    color: $gray_dark_800
    .card__title
      color: $gray_dark_800
</style>
