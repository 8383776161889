<template>
  <div class="section-drawer" v-if="dataFiltered">
    <div
      v-if="dataFiltered.user.status !== 'disconnected'"
      class="header bg-color-active"
      :class="{
        'bg-color-busy': dataFiltered.user.status === 'bussy',
        'bg-color-services': dataFiltered.user.status === 'services',
        'bg-color-training': dataFiltered.user.status === 'training',
      }"
    >
      <div style="display: flex; align-items: center">
        <a-tooltip placement="bottom">
          <template slot="title"> Cerrar resumen </template>
          <a-icon
            type="close"
            style="color: #ffffff; margin-right: 8px"
            @click="handleClose"
          />
        </a-tooltip>
        <h6 class="heading-h7">{{ statusUser }}</h6>
      </div>
      <p v-if="dataFiltered.profile.subType != 'chatbot'" class="body-2">
        {{ dataFiltered.startTimeMenu }} hrs
      </p>
    </div>
    <div
      v-if="dataFiltered.user.status === 'disconnected'"
      class="header"
      style="padding-top: 9px; padding-bottom: 9px"
    >
      <a-tooltip placement="bottom">
        <template slot="title"> Cerrar resumen </template>
        <a-icon type="close" style="margin-right: 8px" @click="handleClose" />
      </a-tooltip>
    </div>
    <div class="content">
      <div class="first-info">
        <StatusAvatar
          :size="64"
          :status="dataFiltered.user.status ? dataFiltered.user.status : 'null'"
          :component="'Drawer'"
          :info="dataFiltered.profile"
        />
        <div class="info">
          <h5 class="heading-h7 gray-dark-900">
            {{ dataFiltered.profile.names }} {{ dataFiltered.profile.surnames }}
          </h5>
          <span class="body-2 gray-light-900" style="text-align: left">{{
            statusUser
          }}</span>
        </div>
      </div>
      <div class="container-title">
        <p class="heading-h6 title">Resumen del día</p>
        <a @click="handleRefresh">Refrescar datos</a>
      </div>
      <div v-if="dataAbstract" class="summary scrollbar_basic">
        <!--Eventos-->
        <!-- <p class="heading-h7" style="text-align:left; margin-bottom: 4px">Eventos</p>
        <div class="flex-row-space">
          <p style="text-align: left" class="margin-bottom-0">Tiempo en tickets:</p><span style="text-align: right">03:15:54 hrs</span>
        </div>
        <div class="flex-row-space">
          <p style="text-align: left" class="margin-bottom-0">Tiempo en tickets:</p><span style="text-align: right">03:15:54 hrs</span>
        </div> -->
        <!--Módulos-->
        <p
          class="heading-h7 gray-dark-900"
          style="text-align: left; margin-bottom: 4px"
        >
          Módulos
        </p>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Tiempo en Workspace:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2"
            >{{
              transformedToHours(dataAbstract.modules.time_tickets)
            }}
            hrs</span
          >
        </div>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Tiempo en Agenda:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2"
            >{{
              transformedToHours(dataAbstract.modules.time_schedules)
            }}
            hrs</span
          >
        </div>
        <!-- <div class="flex-row-space">
          <p style="text-align: left" class="margin-bottom-0">Tiempo en tickets:</p><span style="text-align: right">03:15:54 hrs</span>
        </div> -->
        <!--Status-->
        <p
          class="heading-h7 gray-dark-900"
          style="text-align: left; margin-bottom: 4px; margin-top: 12px"
        >
          Estados
        </p>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Tiempo conectado:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2"
            >{{
              transformedToHours(dataAbstract.statuses.time_connected)
            }}
            hrs</span
          >
        </div>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Tiempo en servicios:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2"
            >{{
              transformedToHours(dataAbstract.statuses.time_services)
            }}
            hrs</span
          >
        </div>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Tiempo en capacitación:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2"
            >{{
              transformedToHours(dataAbstract.statuses.time_training)
            }}
            hrs</span
          >
        </div>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Tiempo en ocupado:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2"
            >{{
              transformedToHours(dataAbstract.statuses.time_bussy)
            }}
            hrs</span
          >
        </div>
        <!--Tickets-->
        <p
          class="heading-h7 gray-dark-900"
          style="text-align: left; margin-bottom: 4px; margin-top: 12px"
        >
          Tickets
        </p>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Tickets sin leer:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2">{{
            dataAbstract.tickets.quantity_unread
          }}</span>
        </div>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Tickets pendientes:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2">{{
            dataAbstract.tickets.quantity_actives
          }}</span>
        </div>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Tickets transferidos:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2">{{
            dataAbstract.tickets.quantity_transfered
          }}</span>
        </div>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Tickets finalizados:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2">{{
            dataAbstract.tickets.quantity_ended
          }}</span>
        </div>
        <!--Agenda-->
        <p
          class="heading-h7 gray-dark-900"
          style="text-align: left; margin-bottom: 4px; margin-top: 12px"
        >
          Agendas
        </p>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Agendas completadas:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2">{{
            dataAbstract.schedules.quantity_completed
          }}</span>
        </div>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Agendas vencidas:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2">{{
            dataAbstract.schedules.quantity_expired
          }}</span>
        </div>
        <div class="flex-row-space">
          <p
            style="text-align: left"
            class="margin-bottom-0 gray-dark-900 body-2"
          >
            Agendas pendientes:
          </p>
          <span style="text-align: right" class="gray-dark-800 body-2">{{
            dataAbstract.schedules.quantity_pendings
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StatusAvatar from '@/app/supervision/components/organisms/avatars/StatusAvatar.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SectionDrawer',
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  components: {
    StatusAvatar,
  },
  mounted() {
    this.getAbstractSupervision()
  },
  data: () => ({
    dataAbstract: null,
  }),
  watch: {
    userId() {
      this.getAbstractSupervision()
    },
  },
  computed: {
    ...mapGetters(['getListSupervision']),
    /**
     * @returns {Object}
     */
    dataFiltered() {
      const arrFiltered = this.getListSupervision.filter(
        (obj) => obj.userId === this.userId
      )
      return arrFiltered[0]
    },
    /**
     * @returns {String}
     */
    statusUser() {
      return this.dataFiltered.user.status === 'connected'
        ? `En ${this.dataFiltered.menu}`
        : this.transformStatus(this.dataFiltered.user.status)
    },
  },
  methods: {
    ...mapActions(['abstractSupervision']),
    handleClose() {
      this.$emit('handleCloseDrawer')
    },
    handleRefresh() {
      this.getAbstractSupervision()
    },
    transformStatus(state) {
      switch (state) {
        case 'connected':
          return 'conectado'
        case 'disconnected':
          return 'Sesión cerrada'
        case 'bussy':
          return 'Ocupado'
        case 'services':
          return 'Servicios'
        case 'training':
          return 'Capacitación'
        default:
          return 'conectado'
      }
    },
    async getAbstractSupervision() {
      const response = await this.abstractSupervision(this.userId)
      this.dataAbstract = response.data
    },
    transformedToHours(value) {
      let sec_num = parseInt(value, 10)
      let hh = Math.floor(sec_num / 3600)
      let mm = Math.floor((sec_num - hh * 3600) / 60)
      let ss = sec_num - hh * 3600 - mm * 60
      if (hh < 10) hh = '0' + hh
      if (mm < 10) mm = '0' + mm
      if (ss < 10) ss = '0' + ss
      const total_time = `${hh}:${mm}:${ss}`
      return total_time
    },
  },
}
</script>

<style lang="sass" scoped>
.bg-color-active
  background-color: #24D34B
.bg-color-busy
  background-color: #F5222D
.bg-color-services
  background-color: #1890FF
.bg-color-training
  background-color: #FA8C16
.flex-row-space
  display: flex
  flex-direction: row
  justify-content: space-between
.margin-bottom-0
  margin-bottom: 0px!important
.gray-dark-900
  color: $gray_dark_900
.gray-dark-800
  color: $gray_dark_800
.gray-light-900
  color: $gray_light_900

.section-drawer
  background-color: $white_000
  border-radius: 8px
  height: calc(100vh - 120px)
  .header
    border-radius: 8px 8px 0px 0px
    padding: 6px 20px
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    h6
      color: $white_000
      margin-bottom: 0px!important
    p
      color: $white_000
      margin-bottom: 0px!important
  .content
    height: 95%
    .first-info
      padding: 12px 20px
      display: flex
      flex-direction: row
      align-items: center
      .info
        margin-left: 10%
        display: flex
        flex-direction: column
        h5
          margin-bottom: 0px!important
          text-align: left
    .container-title
      border-bottom: 1px solid $gray_light_300
      margin: 0px 20px 8px 20px
      display: flex
      flex-direction: row
      justify-content: space-between
      .title
        text-align: left
        color: $gray_dark_900
        margin-bottom: 4px
    .summary
      padding-top: 0px!important
      padding-bottom: 0px !important
      padding: 12px 20px
      height: 72%
      overflow-y: auto
</style>
