<template>
  <div class="chatbots-template">
    <SectionChatbots />
  </div>
</template>
<script>
import SectionChatbots from '@/app/chatbots/components/organisms/SectionChatbots.vue'
// import { mapGetters } from 'vuex'
export default {
  name: 'ChatbotsTemplate',
  components: {
    SectionChatbots,
  },
  computed: {},
}
</script>

<style lang="sass" scoped>
.chatbots-template
  padding: $padding-heigth-inner
</style>
