import fastAnswersModule from './fastAnswersModule'
import customFieldsModule from './customFieldsModule'

const modules = {
  fastAnswersModule,
  customFieldsModule,
}

export default {
  modules,
}
