<template>
  <div class="drawer-settings">
    <div class="drawer-settings-header">
      <h6 class="heading-h7 title">{{ title }}</h6>
      <a-icon type="close" style="margin-right: 8px" @click="handleClose" />
    </div>

    <a-form
      :form="form"
      layout="vertical"
      @submit.prevent="handleSubmit"
      class="drawer-settings-body"
    >
      <custom-section-scroll
        :master-styles="{
          height: 'calc( 100% - 57px)',
          padding: '12px 0px 12px 12px',
        }"
        :content-styles="{ paddingRight: '12px', position: 'relative' }"
      >
        <p class="body-2" style="text-align: left; margin-bottom: 8px">
          Completa los campos requeridos
        </p>
        <!--Drawer para opciones multiples-->
        <template v-if="type === 'options'">
          <a-form-item
            label="Mensaje de opción múltiple"
            class="section-form-item"
          >
            <p class="help" style="margin-bottom: 8px; text-align: left">
              Recuerda que sera un mensaje de introducción para tus usuarios.
            </p>
            <counter-text
              :maxLength="200"
              :text="
                form.getFieldValue('optionsContent')
                  ? form.getFieldValue('optionsContent')
                  : ''
              "
            >
              <a-textarea
                v-decorator="[
                  'optionsContent',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor rellene el campo',
                      },
                      {
                        whitespace: true,
                        message: 'No se admiten espacios en blanco.',
                      },
                    ],
                  },
                ]"
                placeholder="Escribe aquí"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                :maxLength="200"
                @input="onInput"
              />
            </counter-text>
          </a-form-item>
          <a-form-item
            label="Mensaje por respuesta incorrecta"
            class="section-form-item"
          >
            <p class="help" style="margin-bottom: 8px; text-align: left">
              Recuerda este mensaje se mostrará al cliente cuando se ingrese una
              opción diferente a las mostradas
            </p>
            <counter-text
              :maxLength="350"
              :text="
                form.getFieldValue('errorMessage')
                  ? form.getFieldValue('errorMessage')
                  : ''
              "
            >
              <a-textarea
                v-decorator="[
                  'errorMessage',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor rellene el campo',
                      },
                      {
                        whitespace: true,
                        message: 'No se admiten espacios en blanco.',
                      },
                    ],
                  },
                ]"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                placeholder="Escribe aquí"
                :maxLength="350"
                @input="onInput"
              />
            </counter-text>
          </a-form-item>
        </template>
        <!--Drawer para mensajes-->
        <template v-else-if="type === 'message'">
          <!-- Campo de texto para mostrar -->
          <a-form-item
            label="Mensaje"
            class="section-form-item section-form-item--message"
          >
            <p class="help" style="margin-bottom: 8px; text-align: left">
              Recuerda que será un mensaje de tipo texto para tus usuarios.
            </p>
            <counter-text
              :maxLength="350"
              :text="
                form.getFieldValue('text') ? form.getFieldValue('text') : ''
              "
            >
              <a-textarea
                v-decorator="[
                  'text',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor rellene el campo',
                      },
                      {
                        whitespace: true,
                        message: 'No se admiten espacios en blanco.',
                      },
                    ],
                  },
                ]"
                placeholder="Escribe aquí"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                @input="onInput"
              />
            </counter-text>
          </a-form-item>
          <!-- Check de esperar una respuesta -->
          <a-form-item>
            <a-row type="flex">
              <a-checkbox
                v-decorator="['wait', { valuePropName: 'checked' }]"
                @change="handleChangeWait"
                @input="onInput"
                :disabled="action === 'none'"
              >
                Esperar una respuesta
              </a-checkbox>
              <a-tooltip>
                <template slot="title">
                  Tu chatbot esperará una respuesta de tu cliente para
                  continuar.
                </template>
                <a-icon
                  type="info-circle"
                  class="radio-message__icon"
                  style="height: fit-content; margin-top: 2px"
                />
              </a-tooltip>
            </a-row>
          </a-form-item>
          <!-- Lista de radiobutton de acciones -->
          <a-form-item
            label="Selecciona una de las acciones"
            class="section-form-item"
          >
            <a-radio-group
              v-decorator="[
                'action',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellene el campo',
                    },
                  ],
                },
              ]"
              @change="handleChangeAction"
              style="width: 100%"
            >
              <div class="radio-message">
                <a-radio
                  value="continue_message"
                  :style="radioStyle"
                  :disabled="disabledAddMessage"
                >
                  Agregar un nuevo mensaje
                </a-radio>
                <a-tooltip>
                  <template slot="title">
                    Recuerda que solo puedes tener 2 mensajes seguidos sin
                    esperar respuesta.
                  </template>
                  <a-icon
                    type="info-circle"
                    class="radio-message__icon"
                    style="height: fit-content; margin-left: -8px"
                  />
                </a-tooltip>
              </div>
              <a-radio
                :data-test="
                  DATA_TEST.CHATBOT.EDIT.ADD_MULTIPLE_OPTIONS_RADIO_BUTTON
                "
                value="continue_options"
                :style="radioStyle"
              >
                Continuar a opciones múltiples
              </a-radio>
              <a-radio value="transfer" :style="radioStyle">
                Transferir a cola
              </a-radio>
              <a-radio
                value="none"
                :style="radioStyle"
                @click="uncheckWaitAnswer"
              >
                Finalizar chatbot
              </a-radio>
              <a-radio
                v-if="allowedRollback"
                value="rollback"
                :style="radioStyle"
              >
                Regresar al nodo anterior
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </template>
        <!--Drawer para una opcion-->
        <template v-else>
          <a-form-item
            v-if="type === 'option'"
            :label="`Opción ${settings.order}`"
            class="section-form-item"
          >
            <a-input
              v-decorator="[
                'nameOption',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellene el campo',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    },
                  ],
                },
              ]"
              @input="onInput"
              :data-test="DATA_TEST.CHATBOT.EDIT.OPTION_CONTENT_INPUT"
              placeholder="Escribir aquí"
              :maxLength="40"
            />
          </a-form-item>
          <a-form-item
            label="Selecciona una de las acciones"
            class="section-form-item"
          >
            <a-radio-group
              v-decorator="[
                'action',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellene el campo',
                    },
                  ],
                },
              ]"
              @change="handleChangeAction"
              style="width: 100%"
            >
              <a-radio value="continue_message" :style="radioStyle"
                >Agregar mensaje</a-radio
              >
              <a-radio
                :data-test="
                  DATA_TEST.CHATBOT.EDIT.ADD_MULTIPLE_OPTIONS_RADIO_BUTTON
                "
                value="continue_options"
                :style="radioStyle"
              >
                Agregar opciones múltiples
              </a-radio>
              <a-radio value="transfer" :style="radioStyle">
                Transferir a cola
              </a-radio>
              <a-radio value="none" :style="radioStyle">
                Finalizar chatbot
              </a-radio>
              <a-radio
                v-if="allowedRollback"
                value="rollback"
                :style="radioStyle"
              >
                Regresar al menú anterior
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </template>
        <!--Si la accion es transferir--->
        <template v-if="action === 'transfer'">
          <a-form-item label="Selecciona una cola" class="section-form-item">
            <a-select
              v-decorator="[
                'lineId',
                {
                  rules: [{ required: true, message: 'Completa el campo' }],
                },
              ]"
              placeholder="Selecciona"
              @change="handleChangeLines"
            >
              <a-select-option
                v-for="line in chatbotLines"
                :key="line.key"
                :value="line.key"
              >
                {{ line.title }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <template v-if="type === 'option'">
            <a-switch
              class="switch-form"
              size="small"
              v-model="activeMessageTransfer"
              @change="setIsSavedItem(false)"
            />
            <a-form-item
              label="Mensaje de transferencia"
              class="section-form-item"
            >
              <counter-text
                :maxLength="350"
                :text="
                  form.getFieldValue('message')
                    ? form.getFieldValue('message')
                    : ''
                "
              >
                <a-textarea
                  v-decorator="[
                    'message',
                    {
                      rules: [
                        {
                          required: activeMessageTransfer,
                          message: 'Por favor rellene el campo',
                        },
                        {
                          whitespace: true,
                          message: 'No se admiten espacios en blanco.',
                        },
                      ],
                    },
                  ]"
                  placeholder="Escribe aquí"
                  @input="onInput"
                  :disabled="!activeMessageTransfer"
                  :maxLength="350"
                />
              </counter-text>
            </a-form-item>
          </template>
        </template>
        <template v-else-if="action === 'none' && type === 'option'">
          <a-form-item label="Mensaje final" class="section-form-item">
            <p class="help mrg-bottom-8 text--left" style="margin-top: 4px">
              Recuerda que este mensaje se mostrará al cliente cuando elija esta
              opción.
            </p>
            <counter-text
              :maxLength="350"
              :text="
                form.getFieldValue('message')
                  ? form.getFieldValue('message')
                  : ''
              "
            >
              <a-textarea
                v-decorator="[
                  'message',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Por favor rellene el campo',
                      },
                      {
                        whitespace: false,
                        message: 'No se admiten espacios en blanco.',
                      },
                    ],
                  },
                ]"
                placeholder="Escribe aquí"
                @input="onInput"
              />
            </counter-text>
          </a-form-item>
        </template>
        <template v-if="action === 'rollback' && type === 'option'">
          <p class="body-2 text-align-left">
            Recuerda que se volverán a mostrar las opciones del menú anterior.
          </p>
        </template>
        <template v-else-if="description">
          <p class="body-2 text-align-left">
            {{ description }}
          </p>
        </template>
      </custom-section-scroll>
      <div class="drawer-settings-footer">
        <a-button
          style="width: 50%"
          :disabled="isSavedNode"
          @click="handleCancel"
          >Cancelar</a-button
        >
        <a-button
          type="primary"
          style="margin-left: 12px; width: 50%"
          html-type="submit"
          :disabled="isSavedNode"
          :loading="loading"
          :data-test="DATA_TEST.CHATBOT.EDIT.SAVE_OPTION_CHANGES_BUTTON"
          >Guardar</a-button
        >
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { DATA_TEST } from '@/app/shared/utils/dataTest'
import CustomSectionScroll from '@/app/shared/components/sections/CustomSectionScroll'
import transformMixin from '@/app/shared/mixins/transform'
import CounterText from '@/app/shared/components/molecules/CounterText'

export default {
  name: 'DrawerSettings',
  mixins: [transformMixin],
  props: {
    settings: {
      type: Object,
      required: true,
      default: () => ({ type: 'options' }),
    },
    chatbotId: {
      type: String,
      required: true,
    },
    channels: {
      type: Array,
      required: true,
    },
  },
  components: {
    CustomSectionScroll,
    CounterText,
  },
  data: () => ({
    DATA_TEST,
    action: '',
    radioStyle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2px',
    },
    isSavedNode: true, // si no hay cambios estara en false, si hay cambios en true
    isChangeAction: false,
    loading: false, // loading de boton de guardar
    option: {},
    // settings: {}, // copia de settings
    activeMessageTransfer: true,
    nodeIdAction: null,
    waitAnswer: false,
  }),
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form-chatbot' })
  },
  created() {
    this.form.resetFields()
    this.setValues()
  },
  beforeDestroy() {
    this.handleClose()
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        // espera a que se termine de renderizar el componente
        this.$nextTick(() => {
          // resetea los valores del formulario
          this.form.resetFields()
          // setea los nuevos valores
          this.setValues()
        })
      },
    },
  },
  computed: {
    ...mapGetters(['treeNodes', 'nodes', 'profile', 'companyLines']),
    /**
     * @returns {String}
     */
    title() {
      let title = 'Configurar opción'
      if (!this.settings.drawer) return title
      if (this.settings.drawer.question.type === 'options') {
        title = 'Configurar opciones múltiples'
      } else if (this.settings.drawer.question.type === 'message') {
        title = 'Configurar mensaje'
      }
      return title
    },
    /**
     * @returns {String}
     */
    type() {
      return this.settings.drawer
        ? this.settings.drawer.question.type
        : 'option'
    },
    /**
     * FIltra las colas disponibles para mostrar según su canal asignado
     * @returns {Object[]}
     */
    chatbotLines() {
      const linesFilter = []
      this.companyLines.forEach((companyLine) => {
        let isHere = false
        isHere = this.channels.every((channel) => {
          return companyLine.channels_companies?.some(
            (channel_company) => channel_company._id === channel._id
          )
        })
        if (isHere || companyLine.default) {
          linesFilter.push({ key: companyLine._id, title: companyLine.name })
        }
      })
      return linesFilter
    },
    /**
     * @returns {Boolean}
     */
    allowedRollback() {
      if (this.type === 'message') return false
      if (
        (this.settings &&
          this.treeNodes &&
          this.settings.parentNodeId === this.treeNodes._id) ||
        this.settings._id === this.treeNodes._id
      )
        return false
      else return true
    },
    /**
     * @returns {String}
     */
    typeAction() {
      if (this.settings.action) {
        if (this.settings.action.nodeType === 'options')
          return 'continue_options'
        else if (this.settings.action.nodeType === 'message')
          return 'continue_message'
        else return this.settings.action.type
      } else {
        if (this.settings.drawer.question.type === 'options') return null
        if (this.settings.drawer.action.nodeType === 'options')
          return 'continue_options'
        else if (this.settings.drawer.action.nodeType === 'message')
          return 'continue_message'
        else return this.settings.drawer.action.type
      }
    },
    /**
     * @returns {Boolean}
     */
    disabledAddMessage() {
      if (this.type === 'message') {
        const parentNode = this.nodes.find(
          (node) => node._id === this.settings.parentNodeId
        )
        if (
          parentNode !== undefined &&
          parentNode.drawer.question.type === 'message'
        ) {
          if (parentNode.drawer.action.wait === false && !this.waitAnswer)
            return true
          else return false
        }
        return false
      } else return false
    },
    /**
     * @returns {String}
     */
    description() {
      switch (this.action) {
        case 'continue_options':
          return 'Añadiremos nuevas opciones múltiples para continuar con el flujo de tu chatbot. Recuerda guardar tus cambios.'
        case 'continue_message':
          return 'Añadiremos un nuevo mensaje para continuar el flujo.'
        case 'transfer':
          return 'El ticket será transferido a la cola seleccionada.'
        case 'none':
          return ' El ticket será finalizado y cerrado.'
        default:
          return null
      }
    },
  },
  methods: {
    ...mapActions([
      'addNodeOptions',
      'updateOption',
      'addOptionToNode',
      'generateTreeNodes',
      'updateNodeOptions',
      'addNodeMessage',
      'updateNodeMessage',
    ]),
    ...mapMutations(['SET_IS_SAVED_NODE', 'SUM_CHANGES']),

    /**
     * Cambia de accion
     * @param {String} value - transfer, contine_message, continue_options, none, rollback
     */
    handleChangeAction(value) {
      this.action = value.target.value
      this.setIsSavedItem(false)
      this.isChangeAction = true
    },
    // setea los valores al formulario
    setValues() {
      this.action = this.typeAction // poner esto antes de setear mensaje y el tipo
      setTimeout(() => {
        if (this.type === 'option') {
          this.form.setFieldsValue({
            nameOption: this.settings.text,
            action: this.typeAction,
            message: this.settings.action?.message,
            lineId: this.settings.action?.lineId,
          })
          this.activeMessageTransfer =
            this.settings.action.type === 'transfer'
              ? this.settings.action.withMessage
              : false
        } else if (this.type == 'message') {
          this.form.setFieldsValue({
            text: this.settings.drawer.question.content,
            action: this.typeAction,
            message: this.settings.drawer.action?.message,
            lineId: this.settings.drawer.action?.lineId,
            wait: this.settings.drawer.action?.wait,
          })
          this.waitAnswer = this.settings.drawer.action?.wait
          this.activeMessageTransfer =
            this.settings.drawer.action.type === 'transfer'
              ? this.settings.drawer.action.withMessage
              : false
        } else {
          this.form.setFieldsValue({
            optionsContent: this.settings.drawer.question.content,
            errorMessage: this.settings.drawer.question.errorMessage,
          })
        }
      }, 100)
    },
    /**
     * Modal de confirmacion
     * @param {String} title
     * @param {String} content
     * @param {String} okText
     * @param {String} cancelText
     * @param {Function} onOkey
     * @param {Function} onCancel
     */
    showConfirm(title, content, okText, fnOk, fnCancel) {
      this.$confirm({
        title: title,
        content: content,
        okText: okText,
        cancelText: 'Cancelar',
        onOk() {
          fnOk()
        },
        onCancel() {
          fnCancel()
        },
      })
    },
    // submit de formulario
    handleSubmit() {
      const self = this
      if (this.isChangeAction && this.settings.child) {
        const textSure = this.transformWordGender('seguro', this.profile.gender)
        this.$confirm({
          title: `¿Estás ${textSure} de cambiar la acción del mensaje`,
          content:
            'Recuerda que al cambiar la acción, los mensajes siguientes serán eliminados.',
          okText: 'Guardar',
          cancelText: 'Cancelar',
          centered: true,
          onOk() {
            self.updateNode()
          },
        })
        return
      }

      this.updateNode()
    },
    updateNode() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          console.error({ err })
          return
        }
        this.loading = true
        if (this.type === 'option') {
          this.handleUpdateOption(values)
        } else if (this.type === 'options') {
          this.handleUpdateOptions(values)
        } else this.handleUpdateNodeMessage(values)

        this.$emit('update')
        this.loading = false
        this.setIsSavedItem(true)
        this.isChangeAction = false
      })
    },
    onInput() {
      this.setIsSavedItem(false)
    },
    handleChangeLines() {
      this.setIsSavedItem(false)
    },
    /**
     * Cancela todos los cambios realizados
     */
    handleCancel() {
      this.setValues()
      this.setIsSavedItem(true)
      this.isChangeAction = false
    },
    setIsSavedItem(isSavedNode) {
      this.isSavedNode = isSavedNode
      this.SET_IS_SAVED_NODE(this.isSavedNode)
    },
    /**
     * Cierra el drawer de edicion del nodo
     */
    handleClose() {
      this.handleCancel()
      this.$emit('handleCloseDrawer')
    },
    /**
     * Subir cambios a una opcion
     * @param {Object} values - valores del formulario
     * @param {String} values.nameOption - nombre de la opcion
     * @param {String} values.action - transfer, continue_message, continue_options, none
     * @param {String} values.lineId - la cola de transferencia
     * @param {String} values.message - el mensaje de la accion
     */
    async handleUpdateOption(values) {
      const settings = this.settings
      const newOption = {
        order: this.settings.order,
        text: values.nameOption,
        type_action: values.action,
        message_action: null,
      }
      const nodeId = settings.action?.nodeId
      const node = !nodeId
        ? null
        : this.nodes.find((node) => node._id === nodeId)
      if (values.action.includes('continue')) {
        newOption.type_action = 'continue'
        let response = null
        if (values.action === 'continue_options') {
          newOption.nodeType = 'options'
          if (!node || !values.action.includes(settings.action.nodeType)) {
            response = await this.handleAddNodeOptions(
              this.settings.parentNodeId,
              this.settings.idOption
            )
          }
        } else {
          newOption.nodeType = 'message'
          if (!node || !values.action.includes(settings.action.nodeType)) {
            response = await this.handleAddNodeMessage(
              this.settings.parentNodeId,
              this.settings.idOption
            )
          }
        }
        newOption.nodeId_action = response ? response._id : nodeId
        if (response && !response.chatbotId)
          return this.$message.error('No se pudo ejecutar la acción')
      } else if (values.action === 'transfer') {
        newOption.lineId_action = values.lineId
        newOption.message_action =
          values.message !== undefined ? values.message : ''
        newOption.withMessage = this.activeMessageTransfer
      } else newOption.message_action = values.message

      const responseOption = await this.updateOption({
        chatbotId: this.chatbotId,
        nodeId: this.settings.parentNodeId,
        idOption: this.settings.idOption,
        optionEdited: newOption,
        nodeType: newOption.nodeType,
      })
      if (responseOption.success) {
        this.$emit('onChangeSettings', responseOption)
        this.$message.success('Se actualizó correctamente')
      } else {
        this.$message.error('No se pudo ejecutar la acción')
      }
    },
    /**
     * Crear un nodo de opciones
     * @param {String} parentNodeId - id del nodo padre que lo contendra
     * @param {String} optionNodeId - si su padre fuera una opcion, manda el id de esa opcion
     */
    async handleAddNodeOptions(parentNodeId, optionNodeId) {
      const newNodeOptions = {
        message: 'Hola, bienvenid@ a tu primer chatbot',
        parentNodeId: parentNodeId,
        optionNodeId: optionNodeId,
        options: [
          {
            order: 1,
            text: 'Una opción',
            action: {
              type: 'none',
              message: 'Actualmente no atendemos por este medio, gracias.',
            },
          },
        ],
        errorMessage:
          'Disculpa, no he entendido tu respuesta. Escribe nuevamente',
      }
      const response = await this.addNodeOptions({
        chatbotId: this.$route.params.id,
        nodeOptions: newNodeOptions,
      })
      if (response.chatbotId) {
        this.$message.success('Se añadio correctamente')
      } else {
        this.$message.error('No pudimos agregar las opciones múltiples 😓')
      }
      return response
    },
    /**
     * Crear un nodo de mensajes
     * @param {String} parentNodeId - id del nodo padre que lo contendra
     * @param {String} optionNodeId - si su padre fuera una opcion, manda el id de esa opcion
     */
    async handleAddNodeMessage(parentNodeId, optionNodeId) {
      const newNodeMessage = {
        message: 'Hola que onda',
        action: 'none',
        wait: false,
        parentNodeId: parentNodeId,
        optionNodeId: optionNodeId,
      }
      const response = await this.addNodeMessage({
        chatbotId: this.$route.params.id,
        nodeMessage: newNodeMessage,
      })
      if (response.chatbotId) {
        this.$message.success('Se añadio correctamente')
      } else {
        this.$message.error('No pudimos agregar el mensaje 😓')
      }
      return response
    },
    /**
     * Subir cambios al nodo de opciones
     * @param {Object} values - valores del formulario
     * @param {String} values.optionsContent - el texto de opciones
     * @param {String} values.errorMessage - el mensaje de error
     */
    async handleUpdateOptions(values) {
      const nodeOptionsEdited = {
        message: values.optionsContent,
        errorMessage: values.errorMessage,
      }
      await this.updateNodeOptions({
        chatbotId: this.chatbotId,
        nodeId: this.settings._id,
        nodeOptionsEdited,
      })
    },
    /**
     * Subir cambios al nodo de mensaje
     * @param {Object} values - valores del formulario
     * @param {String} values.text - texto del mensaje
     * @param {Boolean} values.wait - si el mensaje esperará una respuesta o no
     * @param {String} values.lineId - la cola de transferencia
     * @param {String} values.message - el mensaje de la accion
     * @param {String} values.action - transfer, continue, none
     */
    async handleUpdateNodeMessage(values) {
      const message = {
        message: values.text,
        wait: values.wait,
        type_action: values.action,
        nodeId_action: this.settings._id,
        lineId_action: values.lineId ? values.lineId : null,
        message_action: values.message ? values.message : null,
        withMessage: this.activeMessageTransfer,
      }

      const settings = this.settings
      if (values.action.includes('continue')) {
        message.type_action = 'continue'
        let response = null
        const nodeId = settings.drawer.action?.nodeId
        const node = !nodeId
          ? null
          : this.nodes.find((node) => node._id === nodeId)
        if (values.action === 'continue_options') {
          message.nodeType = 'options'

          if (
            !node ||
            !values.action.includes(settings.drawer.action.nodeType)
          ) {
            response = await this.handleAddNodeOptions(settings._id, null)
          }
        } else {
          message.nodeType = 'message'
          if (
            !node ||
            !values.action.includes(settings.drawer.action.nodeType)
          ) {
            response = await this.handleAddNodeMessage(settings._id, null)
          }
        }
        message.nodeId_action = response ? response._id : nodeId
        if (response && !response.chatbotId)
          return this.$message.error('No se pudo ejecutar la acción')
      }
      const responseMessage = await this.updateNodeMessage({
        chatbotId: this.chatbotId,
        nodeId: this.settings._id,
        nodeMessage: message,
      })
      if (responseMessage._id) this.$emit('onChangeSettings', responseMessage)
      else {
        if (responseMessage.result.includes('down'))
          this.$message.error(
            'Este mensaje tiene 2 mensajes seguidos sin espera de respuesta 😪'
          )
        else if (responseMessage.result.includes('up'))
          this.$message.error(
            'Existen 2 mensajes seguidos arriba de este nodo 😪'
          )
        else this.$message.error('Ocurrió un error 😪')
      }
    },
    /**
     * Cambia el valor de wait
     * @param {Boolean} wait - espera respuesta o no
     */
    handleChangeWait(e) {
      this.waitAnswer = e.target.checked
    },
    /**
     * Quita el check del 'Esperar respuesta'
     */
    uncheckWaitAnswer() {
      this.form.setFieldsValue({
        wait: false,
      })
      this.waitAnswer = false
    },
  },
}
</script>

<style lang="sass" scoped>
.text-align-left
  text-align: left
.drawer-settings
  height: 100%
  background-color: $white_000
  border: 1px solid $gray_dark_100
  border-radius: 8px
  position: relative
  &-header
    padding: 12px
    border-bottom: 1px solid $gray_dark_100
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    .title
      color: $gray_dark_900
      text-align: left
      margin-bottom: 0px
  &-body
    height: calc( 100% - 44px)
    .help
      color: $gray_dark_700
      margin-top: -8px
    .switch-form
      position: absolute
      right: 12px
      transform: translateY(3px)
      z-index: 1
  &-footer
    padding: 12px
    border-top: 1px solid $gray_dark_100
    width: 100%
    display: flex
    flex-direction: row
    justify-content: flex-end
    position: absolute
    bottom: 0
.section-form-item--message
  margin-bottom: 0px
.radio-message
  display: flex
  flex-direction: row
  align-items: center
  &__icon
    font-size: 17px
</style>
